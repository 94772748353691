// @flow
import { convertToTag } from './tags';
import type { Organization } from './types';

export function convertToOrganization(orgResponseObject: any): Organization {
    const availableTags = orgResponseObject.available_tags || [];

    return {
        id: orgResponseObject.organization_id,
        code: orgResponseObject.organization_code,
        name: orgResponseObject.organization_name,
        templatePreferences: orgResponseObject.template_preferences,
        availableTags: availableTags.map((tagObj) => convertToTag(tagObj)),
    };
}
