// @flow
import type { ResetError } from './ApplicationActions';
import { resetErrorState, getReducerError } from './ApplicationActions';
import createUuid from '../Utils/createUuid';

type HelpState = {
    videos: Array<any>,
    reducerErrors: Array<any>,
};

const initialState: HelpState = {
    videos: [],
    reducerErrors: [],
};

export type GetHelpVideos = {
    type: 'Help/GET_HELP_VIDEOS',
};

export type SetHelpVideos = {
    type: 'Help/SET_HELP_VIDEOS',
    videos: Array<any>,
};

type Action =
    | GetHelpVideos
    | SetHelpVideos
    | ResetError;

export const getHelpVideos = (): GetHelpVideos => ({
    type: 'Help/GET_HELP_VIDEOS',
});

export const setHelpVideos = (videos: Array<any>): SetHelpVideos => ({
    type: 'Help/SET_HELP_VIDEOS',
    videos,
});

const reducer = (state: HelpState = initialState, action: Action): HelpState => {
    // wrap the whole reducer to catch any unexpected exceptions and record them
    // Another component will need to act upon these and potentially send them to Sentry
    try {
        return innerReducer(state, action);
    } catch (err) {
        const reducerErrors = state.reducerErrors.slice();
        const uuid = createUuid(0);
        reducerErrors.push(getReducerError(uuid, 'help', action, err));
        return {
            ...state,

            reducerErrors,
        };
    }
};

const innerReducer = (state: HelpState = initialState, action: Action): HelpState => {
    switch (action.type) {
        case 'Application/RESET_ERROR':
            return resetErrorState(state, action);

        case 'Help/SET_HELP_VIDEOS':
            return {
                ...state,

                videos: action.videos,
            };

        default:
            return state;
    }
};

export default reducer;
