/* eslint-disable object-curly-newline */
// @flow
import type { FormDefData } from '../../Utils/types';
import { BOOLEAN, UI_TYPE_CHECK } from './FormTypes';

const formDef: FormDefData = {
    formId: 'TransportDropoffComplete',
    behaviors: {
        lockWhenFinalized: true,
        saveOnComplete: true,
        clearOnIncomplete: true,
        clearOnNotApplicable: true,
    },
    sections: [
        {
            id: 1,
            title: '',
            fields: [
                { id: 1, title: 'Transport Log Image Added', type: BOOLEAN, uiType: UI_TYPE_CHECK, mandatory: true, tags: [], }
            ],
        }
    ],
};

export default formDef;
