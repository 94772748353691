// @flow
const TeamAddCreateStyles = {
    actionButton: {
        marginLeft: '1em',
        textTransform: 'none',
    },
    mainContainer: {
        width: '100%',
        paddingTop: 25,
        transform: 'translate(0, 0)',
    },
    formInput: {
        paddingBottom: '1em',
    },
    centeredContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
};

export default TeamAddCreateStyles;
