// @flow
import type { Saga } from 'redux-saga';
import {
    select, put, delay, call,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { selectUserId } from 'txp-core';
import csvToJson from 'convert-csv-to-json';

import api from '../Services/Api';
import { finishLoading, startLoading } from '../Redux/LoadingActions';
import {
    apiFetch,
    apiPost,
} from './ApiSaga';
import { setSagaMessage } from '../Redux/ApplicationActions';
import type {
    AddOrgMembersToChatroom, SetCandidateFile, UploadOfferFile, SetROOFile,
} from '../Redux/OfferActions';
import {
    addOrgMembersToChatroom,
    receiveOrgMembers,
    setFileName,
    setSelectedOrgMembers,
    uploadFailed,
    uploadSuccess,
} from '../Redux/OfferActions';
import parseProfile from '../Utils/profile';
import { sendCommandAndGetResult } from './Socket';
import { loadChatrooms } from '../Redux/ChatListActions';
import type { AttachmentData } from '../Utils/types';

export function* uploadCDSOfferSaga(action: UploadOfferFile): Saga<void> {
    const {
        offerFile,
        readOfferFile,
        selectedOrgMembers,
        orgMembers,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('CDSUpload'));

    try {
        const offerJSON = JSON.parse(readOfferFile);
        const fileName = yield select((state) => state.offer.fileName);

        yield call(uploadToS3, '/cds/files/offer/json', offerFile, 'offer');

        if (offerJSON && offerJSON.offer) {
            const offerObject = offerJSON.offer;

            const matches = [];
            for (let i = 0; i < offerObject.match.length; i += 1) {
                const currentMatch = offerObject.match[i];
                if (currentMatch.candidate_id) {
                    matches.push({
                        unos_seq_num: currentMatch.unos_seq_num,
                        priority: currentMatch.priority,
                        rank: currentMatch.rank,
                        local_candidate_id: currentMatch.candidate_id,
                        match_meld: currentMatch.match_meld,
                    });
                } else {
                    yield put(uploadFailed('Missing local candidate ID'));
                    yield put(finishLoading('CDSUpload'));
                    return;
                }
            }
            const offer = {
                local_offer_id: offerObject.id,
                unos_match_organ: offerObject.unos_match_organ,
                unos_donor_id: offerObject.unos_donor_id,
                unos_match_id: offerObject.unos_match_id,
                opo_region: offerObject.opo_region,
                location: offerObject.donor.location,
                donor_criteria: offerObject.donor.dcd === 'Y' ? 'DCD' : 'DBD',
                date_of_birth: offerObject.donor.date_of_birth,
                clinical_data: offerObject.donor,
            };

            const { result, error, } = yield apiPost(api.txp.addOffer, null, { offer, match: matches, users: selectedOrgMembers, });

            if (error) {
                if (error.isValidationError) {
                    const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
                    yield put(uploadFailed(`${errorMessage}`));
                } else if (error.isInvalidResponseCode && error.statusCode === 409) {
                    yield put(uploadFailed('Offer with this ID has already been uploaded'));
                } else if (error.isNetworkError) {
                    yield put(uploadFailed('Uploading offer failed, are you online?'));
                } else {
                    yield put(uploadFailed('Uploading offer failed, try again later'));
                }
                yield put(finishLoading('CDSUpload'));
            } else {
                if (result.chatroom_id) {
                    yield put(addOrgMembersToChatroom(result.chatroom_id, selectedOrgMembers, orgMembers));
                }
                yield put(setSagaMessage(`Successfully uploaded ${fileName}`, '', ''));

                yield put(uploadSuccess());

                yield put(finishLoading('CDSUpload'));
            }
        } else {
            yield put(uploadFailed('Selected file did not contain an offer'));
            yield put(finishLoading('CDSUpload'));
        }
    } catch (err) {
        yield put(uploadFailed('Invalid .json file'));
        yield put(finishLoading('CDSUpload'));
    }

    yield put(setFileName(''));
}

export function* uploadCDSCandidateSaga(action: SetCandidateFile): Saga<void> {
    const {
        candidateFile,
        readCandidateFile,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('CDSUpload'));

    try {
        const fileName = yield select((state) => state.offer.fileName);

        let candidatesArray = [];
        const candidates = [];
        if (candidateFile.mime === 'application/json') {
            yield call(uploadToS3, '/cds/files/candidate/json', candidateFile, 'candidate');

            const candidateJSON = JSON.parse(readCandidateFile);

            if (candidateJSON && candidateJSON.candidates) {
                candidatesArray = candidateJSON.candidates;

                for (let i = 0; i < candidatesArray.length; i += 1) {
                    const currentCandidate = candidatesArray[i];
                    candidates.push({
                        local_candidate_id: currentCandidate.candidate_id,
                        first_name: currentCandidate.first_name,
                        last_name: currentCandidate.last_name,
                        organ_type: currentCandidate.organ,
                        date_of_birth: currentCandidate.date_of_birth,
                        clinical_data: { ...currentCandidate, },
                    });
                }
            } else {
                yield put(uploadFailed('Selected file did not contain any candidates'));
                yield put(finishLoading('CDSUpload'));
            }
        } else if (candidateFile.mime === 'text/csv') {
            yield call(uploadToS3, '/cds/files/candidate/csv', candidateFile, 'candidate');

            candidatesArray = csvToJson.fieldDelimiter(',').formatValueByType().csvStringToJson(readCandidateFile);

            for (let i = 0; i < candidatesArray.length; i += 1) {
                const currentCandidate = candidatesArray[i];
                candidates.push({
                    local_candidate_id: currentCandidate.PatientKey,
                    first_name: currentCandidate.FirstName,
                    last_name: currentCandidate.LastName,
                    organ_type: currentCandidate.OrganType,
                    date_of_birth: currentCandidate.BirthDate,
                    clinical_data: {
                        activated_date: currentCandidate.ActivatedDate,
                        age: currentCandidate.age,
                        albumin: currentCandidate.ALBUMIN,
                        bilirubin: currentCandidate.TBILI,
                        blood_type: currentCandidate.BloodType,
                        candidate_id: currentCandidate.PatientKey,
                        creatinine: currentCandidate.CREAT,
                        date_of_birth: currentCandidate.BirthDate,
                        diabetes: currentCandidate.Diabetes,
                        dx_description: currentCandidate.DXDescription,
                        dx_remarks: currentCandidate.DXRemarks,
                        first_name: currentCandidate.FirstName,
                        height: currentCandidate.HEIGHT,
                        history_of_malignancy: currentCandidate.PreviousMalignancy,
                        inactivated_date: currentCandidate.InactivatedDate,
                        inr: currentCandidate.INR,
                        kdpi: currentCandidate.KDPI,
                        last_name: currentCandidate.LastName,
                        meld_score: currentCandidate.MeldScore,
                        on_dialysis: currentCandidate.Dialysis === 'Dialysis' ? 'Y' : 'N',
                        opo_region: currentCandidate.OPORegion,
                        organ: currentCandidate.OrganType,
                        platelets: currentCandidate.PLATLETS,
                        portal_vein_thrombosis: currentCandidate.PortalVeinThrombosis,
                        previous_abdominal_surg: currentCandidate.PreviousAbdominalSurgery,
                        previous_liver_tx: currentCandidate.PreviousLiverTransplant,
                        sodium: currentCandidate.SODIUM,
                        weight: currentCandidate.WEIGHT,
                    },
                });
            }
        }

        const { error, } = yield apiPost(api.txp.addCandidates, null, { candidates, });

        if (error) {
            if (error.isValidationError) {
                const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
                yield put(uploadFailed(`${errorMessage}`));
            } else if (error.isNetworkError) {
                yield put(uploadFailed('Uploading candidates failed, are you online?'));
            } else {
                yield put(uploadFailed('Uploading candidates failed, try again later'));
            }
            yield put(finishLoading('CDSUpload'));
        } else {
            yield put(finishLoading('CDSUpload'));

            yield put(setSagaMessage(`Successfully uploaded ${fileName}`, '', ''));

            yield put(uploadSuccess());
        }
    } catch (err) {
        yield put(uploadFailed('Invalid .json or .csv file'));
        yield put(finishLoading('CDSUpload'));
    }

    yield put(setFileName(''));
}

export function* uploadROOSaga(action: SetROOFile): Saga<void> {
    const {
        rooFile,
    } = action;

    yield put(startLoading('CDSUpload'));

    const fileName = yield select((state) => state.offer.fileName);

    const success = yield call(uploadToS3, '/cds/files/roo/csv', rooFile, 'roo');

    if (success) {
        yield put(finishLoading('CDSUpload'));

        yield put(setSagaMessage(`Successfully uploaded ${fileName}`, '', ''));

        yield put(uploadSuccess());
    } else {
        yield put(uploadFailed('Invalid .csv file'));
        yield put(finishLoading('CDSUpload'));
    }

    yield put(setFileName(''));
}

export function* uploadToS3(path: string, file: AttachmentData, contentField: string): Saga<boolean> {
    const token = yield select((state) => state.auth.accessToken);

    const root = (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, '');
    const apiPath = `${root}${path}`;

    const form = new FormData();
    form.append(contentField, file.file);
    const headers = {
        Apikey: process.env.REACT_APP_API_KEY || '',
        Authorization: `Bearer ${token}`,
    };

    try {
        const result = yield fetch(apiPath, {
            method: 'POST',
            headers,
            body: form,
        }).then((response) => response.json());

        return !!(result && result.user_id);
    } catch (err) {
        return false;
    }
}

export function* getOrganizationMembersSaga(): Saga<void> {
    const orgId = yield select((state) => state.auth.profile.organizationId);

    yield put(startLoading('orgMembers'));

    const { result, error, } = yield apiFetch(api.txp.getOrganizationMembers, {
        orgId,
    }, {
        organizational_role: 'Surgeon',
    });

    if (error) {
        yield put(finishLoading('orgMembers'));
    } else {
        const members = result.organization_members;

        const orgMembers = [];
        for (let i = 0; i < members.length; i += 1) {
            orgMembers.push(parseProfile(members[i].user_information));
        }

        yield put(receiveOrgMembers(orgMembers));
        yield put(finishLoading('orgMembers'));
    }
}

export function* addOrgMembersToChatroomSaga(action: AddOrgMembersToChatroom): Saga<void> {
    const {
        chatId,
        selectedOrgMembers,
        orgMembers,
    } = action;

    const memberId = yield select((state) => selectUserId(state.auth));

    for (let i = 0; i < selectedOrgMembers.length; i += 1) {
        const userId = selectedOrgMembers[i];
        const index = orgMembers.findIndex((x) => x.userId === userId);
        if (index !== -1) {
            const { email, } = orgMembers[index];

            const ack = yield call(
                sendCommandAndGetResult,
                chatId,
                memberId,
                'add-user-v2',
                [email]
            );

            if (!ack || (ack && ack.error)) {
                Sentry.captureException(`addOrgMembersToChatroomSaga Failed: ${JSON.stringify(ack)}`);
            }
        }
    }

    // Reload chatrooms
    yield put(loadChatrooms());
    // Reset selected org members
    yield put(setSelectedOrgMembers([]));
}
