/* eslint-disable react/destructuring-assignment */
// @flow
import React from 'react';

import styles from './Styles/BannerStyles';
import Colors from '../Themes/Colors';

type Props = {
    entity: 'case' | 'task';
    message?: string,
};

const HighRiskBanner = (props: Props) => (
    <div
        style={{ ...styles.donorClosedOverlayMessageWrapper, ...{ backgroundColor: Colors.red, }, }}
    >
        {props.message
            ? (
                <span
                    style={styles.overlayMessageText}
                >
                    {props.message}
                </span>
            ) : (
                <span
                    style={styles.overlayMessageText}
                >
                    This
                    {` ${props.entity} `}
                    has risk criteria.
                </span>
            )}
    </div>
);

HighRiskBanner.defaultProps = {
    message: undefined,
};

export default HighRiskBanner;
