// @flow

let commandChannel;
let resultChannel;

export const getCommandChannel = () => commandChannel;
export const getResultChannel = () => resultChannel;

export const setCommandChannel = (channel: *) => {
    commandChannel = channel;
};

export const setResultChannel = (channel: *) => {
    resultChannel = channel;
};
