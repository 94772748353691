import { QueryClient } from '@tanstack/react-query';

const TEN_SECONDS_IN_MS = 10000;

// While we're using apiCachedFetch in sagas, we are adding
// a default stale time so that we don't have to refetch
// data just fetched by useQuery in a saga.
// This may no longer be as useful if more data fetching is
// moved out of sagas and apiCachedFetch is no longer used.
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: TEN_SECONDS_IN_MS,
        },
    },
});

export default queryClient;
