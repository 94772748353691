// @flow

const roles = [
    {
        label: 'Administrator',
        value: 'Administrator',
    },
    {
        label: 'Coordinator',
        value: 'Coordinator',
    },
    {
        label: 'Medicine',
        value: 'Medicine',
    },
    {
        label: 'Surgeon',
        value: 'Surgeon',
    },
    {
        label: 'Specialist',
        value: 'Specialist',
    },
    {
        label: 'Technologist',
        value: 'Technologist',
    },
    {
        label: 'Other',
        value: 'Other',
    }
];

export default roles;
