// @flow
import React from 'react';
import {
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import styles from './Styles/GenericFormStyles';
import type { FormDefField } from '../Utils/types';
import FormLabel from './FormLabel';
import Checkbox from './Checkbox';

type Props = {
    fieldDisabled: boolean,
    itemDef: FormDefField,
    itemData: any,
    onChange: (item: FormDefField, value: any) => *,
};

const FormCheckbox = (props: Props) => {
    const {
        fieldDisabled,
        itemDef,
        itemData,
        onChange,
    } = props;

    if (!itemDef) return null;

    const checked: boolean = itemData && itemData.value ? itemData.value : false;

    let iconName = faCircle;
    if (checked) {
        iconName = faCheckCircle;
    }
    const className = fieldDisabled ? 'iconDisabled24' : 'iconBlue24';

    return (
        <div
            style={styles.formSwitchCheck}
        >
            <FormLabel item={itemDef} />
            <Checkbox
                disabled={fieldDisabled}
                className={className}
                iconName={iconName}
                label=""
                onClick={() => onChange(itemDef, !checked)}
            />
        </div>
    );
};

export default FormCheckbox;
