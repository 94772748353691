import Colors from '../../Themes/Colors';
import { mediumFontSize } from './ChatroomDetailsStyles';

const FollowerGroupStyles = {
    expandedCardTask: {
        backgroundColor: Colors.white,
        shadowColor: Colors.lightGray,
        padding: 15,
        shadowRadius: 4,
        shadowOpacity: 0.8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.chatListItemBorder,
        marginBottom: 15,
    },

    cardTitleLarge: {
        color: Colors.black,
        fontSize: 18,
        fontWeight: 'bold',
    },

    commandArea: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        color: Colors.blue,
        outline: 'none',
        backgroundColor: Colors.white,
        cursor: 'pointer',
        borderTopWidth: '2px',
        borderTopColor: Colors.chatListItemBorder,
        borderTopStyle: 'solid',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
    },

    commandAreaDisabled: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        outline: 'none',
        backgroundColor: Colors.white,
        color: Colors.linkTextDisabled,
        borderTopWidth: '2px',
        borderTopColor: Colors.chatListItemBorder,
        borderTopStyle: 'solid',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
    },

    detailsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
    },

    detailsHeaderButton: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 16,
    },

    detailsHeaderButtonDisabled: {
        backgroundColor: Colors.white,
        color: Colors.linkTextDisabled,
        textTransform: 'none',
        fontSize: 16,
    },

    allTasksCheckbox: {
        paddingBottom: 10,
    },

    taskName: {
        fontSize: 18,
        paddingLeft: 20,
        color: Colors.black,
    },

    taskNameDisabled: {
        fontSize: 18,
        paddingLeft: 20,
        color: Colors.lightGray,
    },

    listItem: {
        paddingHorizontal: 19,
        paddingVertical: 14,

        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: Colors.chatListItemBorder,
    },

    listItemTitle: {
        color: Colors.black,
        fontSize: 16,
        textAlign: 'left',
    },

    listItemTitleDisabled: {
        color: Colors.lightGray,
    },

    listItemArrowCell: {
        flex: 1,
        alignItems: 'flex-end',
    },

    listItemArrow: {
        transform: [
            {
                scaleX: -0.5,
            },
            {
                scaleY: 0.5,
            },
            {
                translateX: -1,
            }
        ],

    },

    followerMemberWrapper: {
        flex: 1,
        flexDirection: 'row',
        paddingVertical: 8,
    },

    iconAlignCenter: {
        alignSelf: 'center',
    },

    sectionContainer: {
        padding: '0.75em 1em',
    },

    emptyList: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: Colors.formLabel,
        marginTop: 10,
        marginBottom: 30,
    },

    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },

    sectionHeader: {
        fontSize: mediumFontSize,
        color: Colors.formLabel,
        paddingBottom: 15,
    },

    sectionText: {
        paddingBottom: 15,
    },

    listItemButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
};

export default FollowerGroupStyles;
