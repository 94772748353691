// @flow
import React, { PureComponent } from 'react';
import FontIcon from 'react-toolbox/lib/font_icon';
import Icon from '@mdi/react';
import { mdiPin, mdiPinOutline, mdiEmailOpenOutline } from '@mdi/js';
import ReactTooltip from 'react-tooltip';
import { formatDateTime } from 'txp-core';

import { CASE_TAB } from '../Redux/ApplicationActions';
import ChatListStyles from './Styles/ChatListStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import Colors from '../Themes/Colors';
import truncate, { displayRoomType, transformCaseName } from '../Utils/text';
import type { ChatroomInfo } from '../Utils/types';
import hasValue from '../Utils/hasValue';
import CaseTagDrawer from './CaseTagDrawer';
import { getBadgeCount } from '../Utils/sortChatrooms';

type Props = {
    room: ChatroomInfo,
    buttonVisibility: boolean,
    currentChatId: number,
    chatSelected: boolean,
    isConnected: boolean,
    currentTab: string,

    clearChatroomNotifications: (chatId: number) => *,
    openChatroom: (chatId: number) => *,
    pinChatroom: (chatId: number) => *,
    unpinChatroom: (chatId: number) => *,
};

class ChatListItem extends PureComponent<Props> {
    onOpenChatroom = () => {
        const {
            room,
            openChatroom,
        } = this.props;

        openChatroom(room.id);
    };

    onKeyDownOpenChatroom = (e: { which: number }) => {
        const {
            room,
            openChatroom,
        } = this.props;

        // Enter key pressed
        if (e.which === 13) {
            openChatroom(room.id);
        }
    };

    onPinChatroom = (event: any) => {
        const {
            room,
            pinChatroom,
            unpinChatroom,
        } = this.props;

        event.stopPropagation();

        if (room) {
            if (hasValue(room.pinNumber)) {
                unpinChatroom(room.id);
            } else {
                pinChatroom(room.id);
            }
        }
    };

    onKeyDownClearNotification = (e: { which: number }) => {
        const {
            room,
            clearChatroomNotifications,
        } = this.props;

        // Enter key pressed
        if (e.which === 13) {
            clearChatroomNotifications(room.id);
        }
    };

    onClearPress = () => {
        const {
            room,
            clearChatroomNotifications,
        } = this.props;

        clearChatroomNotifications(room.id);
    };

    render() {
        const {
            currentChatId,
            chatSelected,
            buttonVisibility,
            isConnected,
            currentTab,
            room,
        } = this.props;
        const {
            id,
            description,
            memberCount,
            name,
            lastMessage,
            doNotDisturb,
            pinNumber,
            chatroomType,
            organType,
        } = room;

        const countOnBadge = getBadgeCount(room);
        const showCountBadge = countOnBadge && countOnBadge > 0;
        const showClearButton = buttonVisibility && showCountBadge;
        const className = showClearButton ? showClearButton.toString() : 'false';
        const isCurrentChatSelected = currentChatId === id && chatSelected;
        const highlightMainDonor = chatroomType === 'Main Donor' && currentTab === CASE_TAB;

        let lastMessageText;
        if (lastMessage && lastMessage.textContent) {
            lastMessageText = lastMessage.textContent;
        } else if (lastMessage && lastMessage.sentTime) {
            lastMessageText = 'File uploaded';
        } else {
            lastMessageText = description;
        }

        return (
            <div
                className="chatlistRow"
                style={isCurrentChatSelected ? ChatListStyles.rowSelected : ChatListStyles.row}
                role="button"
                tabIndex={0}
                onKeyPress={!showClearButton ? this.onKeyDownOpenChatroom : null}
                onClick={!showClearButton ? this.onOpenChatroom : null}
            >
                <ReactTooltip />
                <div
                    id="clearButton"
                    className={className}
                    style={{ ...ChatListStyles.clearButton, ...{ width: showClearButton ? '80px' : '0px', }, }}
                    role="button"
                    tabIndex={0}
                    onKeyPress={this.onKeyDownClearNotification}
                    onClick={this.onClearPress}
                    data-tip="Clear notifications"
                    data-delay-show="500"
                    data-effect="solid"
                    data-place="right"
                >
                    <div
                        style={ChatListStyles.clearButtonIconWrapper}
                    >
                        <Icon
                            path={mdiEmailOpenOutline}
                            size="18px"
                            color={Colors.white}
                        />
                        <span style={ChatListStyles.clearButtonText}>
                            Clear
                        </span>
                    </div>
                </div>
                <div
                    id="pinContainer"
                    className={className}
                >
                    <button
                        style={ChatListStyles.pinButton}
                        type="button"
                        tabIndex={0}
                        disabled={!isConnected}
                        onClick={(event) => this.onPinChatroom(event)}
                    >
                        <Icon
                            id="pin"
                            path={hasValue(pinNumber) ? mdiPin : mdiPinOutline}
                            size="18px"
                            color={isConnected ? Colors.blue : Colors.linkTextDisabled}
                        />
                    </button>
                </div>
                <div
                    id="paddingLeft"
                    className={className}
                    style={ChatListStyles.padding}
                >
                    <div style={{ ...ChatListStyles.innerRow, ...highlightMainDonor ? ChatListStyles.highlightWrapper : null, }}>
                        {doNotDisturb
                            ? (
                                <FontIcon
                                    style={ChatListStyles.mute}
                                    value="notifications_off"
                                    alt=""
                                />
                            ) : <div />}
                        <div
                            style={{
                                ...ChatListStyles.rowTitle,
                                ...showCountBadge ? ChatListStyles.rowTitleBadge : null,
                                ...ChatListStyles.truncateText,
                            }}
                        >
                            {transformCaseName(name)}
                        </div>
                        {showCountBadge ? (
                            <div style={ChatListStyles.countBadge}>
                                <span style={ChatListStyles.countBadgeText}>{countOnBadge}</span>
                            </div>
                        ) : null}
                    </div>
                    <div style={ChatListStyles.innerRowWithTag}>
                        <div>
                            <div
                                style={{
                                    ...ChatListStyles.timestamp,
                                    ...showCountBadge ? ChatListStyles.timestampBadge : null,
                                }}
                            >
                                {lastMessage ? formatDateTime(lastMessage.sentTime) : ''}
                            </div>
                            <span
                                className="rowDescription"
                                style={showCountBadge ? ChatListStyles.rowDescriptionBadge : null}
                            >
                                {truncate(lastMessageText, 35)}
                            </span>
                            <div style={ApplicationStyles.dot} />
                            <span style={ChatListStyles.personCount}>
                                {memberCount}
                            </span>
                            <FontIcon
                                className="iconPerson"
                                value="people"
                                alt=""
                            />
                        </div>
                        <CaseTagDrawer
                            organType={organType}
                            entity="chat"
                            highRisk={false}
                        />
                    </div>
                    <div style={ChatListStyles.secondInnerRow}>
                        <span className="rowDescription">
                            {displayRoomType(chatroomType)}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatListItem;
