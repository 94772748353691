// @flow
import type { UserProfile } from './types';

export function alphaSortUserProfiles(userProfiles: UserProfile[]): UserProfile[] {
    return [...userProfiles].sort((a, b) => {
        const aName = `${a.firstName ?? ''} ${a.lastName ?? ''}`;
        const bName = `${b.firstName ?? ''} ${b.lastName ?? ''}`;

        return aName.localeCompare(bName);
    });
}
