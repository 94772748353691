// @flow
import Colors from '../../Themes/Colors';
import { mediumFontSize, smallFontSize } from './ChatroomDetailsStyles';

const CasePermissionsStyles = {
    entityItem: {
        padding: '1em 0em 1em 1em',
    },
    memberButton: {
        outline: 'none',
        background: 'none',
        border: 'none',
        float: 'right',
        cursor: 'pointer',
    },
    memberButtonDisabled: {
        outline: 'none',
        background: 'none',
        border: 'none',
        float: 'right',
        cursor: 'default',
    },
    entityName: {
        color: Colors.black,
        fontSize: mediumFontSize,
        fontWeight: '700',
        width: 'fit-content',
        paddingBottom: 3,
    },
    emptyList: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: Colors.formLabel,
        marginTop: 10,
        marginBottom: 30,
    },
    smallDetails: {
        fontSize: smallFontSize,
        marginBottom: 1,
    },
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuTitle: {
        fontSize: smallFontSize,
        color: Colors.gray,
        padding: '0.8rem',
    },
    followerItem: {
        padding: '1em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        fontWeight: 700,
        cursor: 'pointer',
    },
    followerItemDisabled: {
        padding: '1em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 700,
        justifyContent: 'space-between',
    },
    deleteConfirmTitleText: {
        fontSize: 20,
        color: Colors.black,
        paddingBottom: '1em',
    },
    cancelButton: {
        background: 'none',
        outline: 'none',
        border: 'none',
        color: Colors.black,
        fontSize: 16,
        cursor: 'pointer',
    },
    confirmButton: {
        background: 'none',
        outline: 'none',
        border: 'none',
        color: Colors.red,
        fontSize: 16,
        cursor: 'pointer',
        marginLeft: 10,
    },
    center: {
        textAlign: 'center',
    },
    modalActionsWrapper: {
        paddingTop: '2em',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    sectionHeader: {
        fontSize: mediumFontSize,
        color: Colors.formLabel,
        paddingBottom: '1em',
        paddingLeft: '0.7em',
    },
    section: {
        textAlign: 'left',
        paddingTop: '1em',
        paddingBottom: '1em',
    },
    fieldContainerWithButton: {
        display: 'flex',
        lineHeight: 2,
        color: 'Gray',
        fontSize: '14px',
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    sectionHeaderWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingLeft: '0.7em',
        paddingRight: '1em',
    },
    permissionsImageWrapper: {
        marginRight: '1.5em',
        height: 50,
        width: 50,
        borderRadius: 50 / 1.5,
    },
    permissionsImageContainer: {
        marginRight: 35,
        height: 50,
        width: 50,
        padding: '3px',
        borderRadius: 50 / 1.5,
        cursor: 'pointer',
    },
    permissionsIconWrapper: {
        marginRight: '1.5em',
        height: 50,
        width: 50,
        borderRadius: 50 / 1.5,
        padding: 3,
    },
    permissionsRowItem: {
        display: 'flex',
        alignItems: 'center',
    },
    groupJoinButtonText: {
        color: Colors.brandPrimary,
        fontSize: 16,
        outline: 'none',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    groupJoinButtonTextHover: {
        color: Colors.brandPrimary,
        fontSize: 16,
        outline: 'none',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    groupJoinButtonTextDisabled: {
        outline: 'none',
        background: 'none',
        border: 'none',
        float: 'right',
        cursor: 'default',
        color: Colors.linkTextDisabled,
        fontSize: 16,
    },
};

export default CasePermissionsStyles;
