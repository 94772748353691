// @flow
import * as Sentry from '@sentry/browser';
import type { Saga } from 'redux-saga';
import {
    put, select, take, fork, call, cancelled, cancel, flush, delay,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import io from 'socket.io-client';
import {
    refreshAccessToken, verifyAccessToken, selectUserId, verifyEmail,
} from 'txp-core';

import {
    loadMessages,
    loadChatMembers,
    addedToChatroom,
    removedFromChatroom,
    updateChatroomInfo,
    memberRemoved,
    receiveNewMessage,
    setSocketState,
    triggerChatSocket,
    updateDonorId,
    updateOrganId,
    receiveAcknowledge,
    memberStartedTyping,
    memberStoppedTyping,
    setClearedNotificationCount,
    pinnedChatroom,
    unpinnedChatroom,
    updatePinnedChatrooms,
    removeMemberFromRoom,
    removeChatroom,
    redactMessageSuccess,
    unredactMessageSuccess,
    receiveChatroomInfo,
    deleteFileMessageSuccess,
    // closeChatroom,
    // deletedChatroom,
} from '../../Redux/ChatListActions';
import {
    getApplicationData,
    setSagaMessage,
} from '../../Redux/ApplicationActions';
import {
    getDonor,
    getDonorTaskData,
} from '../../Redux/DonorActions';
import {
    offerCreated,
} from '../../Redux/ChatEditActions';
import emitWithHandlers from './EmitWithHandlers';
import { getCommandChannel, getResultChannel } from './Channels';
import type { SendCommand } from './Commands';
import { commandResult, getCommandMethod } from './Commands';
import { parseMessageStatuses } from '../../Utils/parseMessageStatuses';
import { singleErrorMessage } from '../../Utils/flattenError';
import hasValue from '../../Utils/hasValue';
import devLog from '../../Utils/logger';
import { getPermissions } from '../../Redux/PermissionActions';

const authenticate = (socket: io, token: string) => emitWithHandlers(
    socket,
    'authenticate',
    { token, },
    'authorized',
    'unauthorized',
    true
);

const connect = () => {
    const socket = io(process.env.REACT_APP_SOCKET_URL || '', {
        // Disabled because reconnection is handled in sagas
        reconnection: false,
    });

    return new Promise((resolve, reject) => {
        socket.once('connect', () => resolve(socket));
        socket.once('connect_error', (e) => reject(e));
        socket.once('connect_timeout', (e) => reject(e));
    });
};

// Define socket signals that we will be listening for
const subscribe = (socket: io, memberId: number) => eventChannel((emit) => {
    socket.on('error', (error) => {
        devLog('socket', ['error', error]);
        if (error === 'Expired Token') {
            emit(verifyAccessToken('TXP_CHAT_WEB'));
        }
    });

    socket.on('application-data', (data) => {
        devLog('socket', ['application-data', data]);
        if (data && data.transaction_uuid) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event application-data with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event application-data with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('get-application-data-error', (error) => {
        devLog('socket', ['get-application-data-error', error]);
        if (error && error.transaction_uuid) {
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event get-application-data-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Socket event get-application-data-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('permissions-updated', () => {
        emit(getPermissions());
    });

    socket.on('donor-updated', (data) => {
        if (data && data.sender_id) {
            // don't take action if this is from my own update
            if (data.sender_id !== memberId) {
                emit(getDonor(data.donor_id));
                emit(getDonorTaskData(data.donor_id));
            }
        }
    });

    socket.on('added-to-chatroom', (data) => {
        devLog('socket', ['added-to-chatroom', data]);
        if (data && data.chatroom_type && (data.chatroom_type === 'Donor Only' || data.chatroom_type === 'Follower Group')) {
            emit(getPermissions());
        }
        if (data && data.chatroom_id) {
            emit(addedToChatroom(
                data.chatroom_id,
                false,
                data.chatroom_name,
                data.chatroom_description,
                data.chatroom_type,
                data.managed || false,
                data.creator_id,
                data.member_count,
                data.total_message_count,
                {
                    textContent: data.last_instant_message_content ? data.last_instant_message_content : 'File uploaded',
                    sentTime: data.last_instant_message_time,
                },
                data.last_update_time,
                data.last_active_time,
                data.donor_id,
                data.organ_id,
                data.follower_id,
                data.create_date
            ));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event added-to-chatroom with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event added-to-chatroom with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('message-sent', (data) => {
        devLog('socket', ['message-sent', data]);
        if (data && data.transaction_uuid && data.instant_messages) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event message-sent with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event message-sent with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('send-message-error', (error) => {
        devLog('socket', ['send-message-error', error]);
        if (error && error.transaction_uuid) {
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event send-message-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Socket event send-message-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('added-user-v2', (data) => {
        devLog('socket', ['added-user-v2', data]);
        if (data && data.transaction_uuid && data.chatroom_id) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event added-user with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event added-user with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('added-users', (data) => {
        if (data && data.transaction_uuid && data.chatroom_id && data.member_ids) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event added-users with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event added-users with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('not-txp-user', (error) => {
        devLog('socket', ['not-txp-user', error]);
        if (error && error.transaction_uuid) {
            error.type = 'not-txp-user';
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event not-txp-user with unexpected data:', error);
        } else {
            Sentry.captureException(`Socket event not-txp-user with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('removed-member-v2', (data) => {
        devLog('socket', ['removed-member-v2', data]);
        if (data && data.chatroom_id && data.member_id) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(removeMemberFromRoom(data.chatroom_id, data.member_id));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event removed-member with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event removed-member with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('remove-member-error', (error) => {
        devLog('socket', ['remove-member-error', error]);
        if (error && error.transaction_uuid) {
            emit(setSagaMessage('Could not remove member', singleErrorMessage(error), ''));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event remove-member-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Socket event remove-member-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('add-user-error', (error) => {
        devLog('socket', ['add-user-error', error]);
        if (error && error.transaction_uuid) {
            error.type = 'add-user-error';
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event add-user-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Socket event add-user-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('removed-from-chatroom', (data) => {
        devLog('socket', ['removed-from-chatroom', data]);
        if (data && data.chatroom_id) {
            // If the event contains a transaction_uuid, it is a response to 'remove-member' where member is self
            if (data.transaction_uuid) {
                emit(removeChatroom(data.chatroom_id));
            } else {
                // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
                emit(removedFromChatroom(data.chatroom_id));
            }
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event removed-from-chatroom with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event removed-from-chatroom with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('deleted-chatroom', (data) => {
        devLog('socket', ['deleted-chatroom', data]);

        if (data && data.chatroom_id) {
            emit(removeChatroom(data.chatroom_id));
        }
    });

    socket.on('user-added-v2', (data) => {
        devLog('socket', ['user-added-v2', data]);
        if (data && data.chatroom_id && data.member_id) {
            // Update member count for chatroom
            emit(loadChatMembers(data.chatroom_id));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event user-added-v2 with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event user-added-v2 with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('users-added', (data) => {
        devLog('socket', ['Found a users-added event']);
        if (data && data.chatroom_id && data.member_ids) {
            emit(loadChatMembers(data.chatroom_id));
        } else if (process.env.NODE_ENV === 'Development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event users-added with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event users-added with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('member-removed-v2', (data) => {
        devLog('socket', ['member-removed-v2', data]);
        if (data && data.chatroom_id && data.member_id) {
            // Update member count for chatroom
            emit(memberRemoved(data.chatroom_id, data.member_id, data.member_count, false));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event member-removed-v2 with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event member-removed-v2 with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('new-message', (data) => {
        devLog('socket', ['new-message', data]);
        if (data && data.chatroom_id && data.instant_message_id && data.sender_id) {
            // store the message
            emit(receiveNewMessage(
                data.chatroom_id,
                memberId,
                {
                    id: data.instant_message_id,
                    senderId: data.sender_id,
                    senderName: data.sender_name,
                    sentTime: data.sent_time,
                    messageType: data.chat_type_id,
                    textContent: data.text_content,
                    dataContent: data.data_content,
                    displayName: data.display_name,
                    fileName: data.file_name,
                    seenByCount: data.read_count || 0,
                    seenByCurrentUser: data.is_seen_by_current_user || false,

                    localPath: null,
                    status: null,
                    ack: {},
                    redactTime: null,
                    replyingMessageId: data.replying_to,
                    replyingTextContent: data.replying_to_text_content,
                    replyingMessageType: data.replying_to_chat_type_id,
                    replyingDataContent: data.replying_to_data_content,
                    replyingFilePath: data.replying_to_file_path,
                    replyingSenderId: data.replying_to_sender_id,
                    parentMessageId: data.parent_message_id,
                },
                data.instant_message_id,
                'end',
                {
                    textContent: data.text_content ? data.text_content : 'File uploaded',
                    sentTime: data.sent_time,
                },
                data.sender_id === memberId ? data.sent_time : null,
                data.sender_id === memberId ? 1 : 0
            ));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event new-message with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event new-message with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('created-donor', (data) => {
        devLog('socket', ['created-donor', data]);
        if (data && data.donor_id && data.organ_id) {
            emit(offerCreated(data.donor_id, data.organ_id));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event created-donor with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event created-donor with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('new-donor', (data) => {
        devLog('socket', ['new-donor', data]);
        if (data && data.chatroom_id && data.instant_message_id && data.data_content) {
            // store the message
            emit(receiveNewMessage(
                data.chatroom_id,
                memberId,
                {
                    id: data.instant_message_id,
                    senderId: data.sender_id,
                    senderName: data.sender_name,
                    sentTime: data.sent_time,
                    messageType: data.chat_type_id,
                    textContent: data.text_content,
                    dataContent: data.data_content,
                    displayName: data.display_name,
                    fileName: data.file_name,
                    seenByCount: data.read_count || 0,
                    seenByCurrentUser: data.is_seen_by_current_user || false,

                    localPath: null,
                    status: null,
                    ack: {},
                    redactTime: null,
                    replyingMessageId: null,
                    replyingTextContent: null,
                    replyingMessageType: null,
                    replyingDataContent: null,
                    replyingFilePath: null,
                    replyingSenderId: null,
                    parentMessageId: null,
                },
                data.instant_message_id,
                'end',
                {
                    textContent: data.text_content,
                    sentTime: data.sent_time,
                },
                data.sender_id === memberId ? data.sent_time : null,
                data.sender_id === memberId ? 1 : 0
            ));

            // update donor id
            emit(updateDonorId(data.chatroom_id, data.donor_id));

            // update organ id
            emit(updateOrganId(data.chatroom_id, data.organ_id));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event new-donor with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event new-donor with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('updated-donor', (data) => {
        devLog('socket', ['updated-donor', data]);
        if (data && data.chatroom_id && data.instant_message_id && data.data_content) {
            // store the message
            emit(receiveNewMessage(
                data.chatroom_id,
                memberId,
                {
                    id: data.instant_message_id,
                    senderId: data.sender_id,
                    senderName: data.sender_name,
                    sentTime: data.sent_time,
                    messageType: data.chat_type_id,
                    textContent: data.text_content,
                    dataContent: data.data_content,
                    displayName: data.display_name,
                    fileName: data.file_name,
                    seenByCount: data.read_count || 0,
                    seenByCurrentUser: data.is_seen_by_current_user || false,

                    localPath: null,
                    status: null,
                    ack: {},
                    redactTime: null,
                    replyingMessageId: null,
                    replyingTextContent: null,
                    replyingMessageType: null,
                    replyingDataContent: null,
                    replyingFilePath: null,
                    replyingSenderId: null,
                    parentMessageId: null,
                },
                data.instant_message_id,
                'end',
                {
                    textContent: data.text_content,
                    sentTime: data.sent_time,
                },
                data.sender_id === memberId ? data.sent_time : null,
                data.sender_id === memberId ? 1 : 0
            ));
            emit(loadMessages(data.chatroom_id, memberId, true, 0));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event updated-donor with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event updated-donor with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('new-recipient', (data) => {
        devLog('socket', ['new-recipient', data]);
        if (data && data.chatroom_id && data.instant_message_id && data.data_content) {
            // store the message
            emit(receiveNewMessage(
                data.chatroom_id,
                memberId,
                {
                    id: data.instant_message_id,
                    senderId: data.sender_id,
                    senderName: data.sender_name,
                    sentTime: data.sent_time,
                    messageType: data.chat_type_id,
                    textContent: data.text_content,
                    dataContent: data.data_content,
                    displayName: data.display_name,
                    fileName: data.file_name,
                    seenByCount: data.read_count || 0,
                    seenByCurrentUser: data.is_seen_by_current_user || false,

                    localPath: null,
                    status: null,
                    ack: {},
                    redactTime: null,
                    replyingMessageId: null,
                    replyingTextContent: null,
                    replyingMessageType: null,
                    replyingDataContent: null,
                    replyingFilePath: null,
                    replyingSenderId: null,
                    parentMessageId: null,
                },
                data.instant_message_id,
                'end',
                {
                    textContent: data.text_content,
                    sentTime: data.sent_time,
                },
                data.sender_id === memberId ? data.sent_time : null,
                data.sender_id === memberId ? 1 : 0
            ));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event new-recipient with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event new-recipient with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('updated-recipient', (data) => {
        devLog('socket', ['updated-recipient', data]);
        if (data && data.chatroom_id && data.instant_message_id && data.data_content) {
            // store the message
            emit(receiveNewMessage(
                data.chatroom_id,
                memberId,
                {
                    id: data.instant_message_id,
                    senderId: data.sender_id,
                    senderName: data.sender_name,
                    sentTime: data.sent_time,
                    messageType: data.chat_type_id,
                    textContent: data.text_content,
                    dataContent: data.data_content,
                    displayName: data.display_name,
                    fileName: data.file_name,
                    seenByCount: data.read_count || 0,
                    seenByCurrentUser: data.is_seen_by_current_user || false,

                    localPath: null,
                    status: null,
                    ack: {},
                    redactTime: null,
                    replyingMessageId: null,
                    replyingTextContent: null,
                    replyingMessageType: null,
                    replyingDataContent: null,
                    replyingFilePath: null,
                    replyingSenderId: null,
                    parentMessageId: null,
                },
                data.instant_message_id,
                'end',
                {
                    textContent: data.text_content,
                    sentTime: data.sent_time,
                },
                data.sender_id === memberId ? data.sent_time : null,
                data.sender_id === memberId ? 1 : 0
            ));
            emit(loadMessages(data.chatroom_id, memberId, true, 0));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event updated-recipient with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event updated-recipient with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('new-candidate', (data) => {
        devLog('socket', ['new-candidate', data]);
        if (data && data.chatroom_id && data.instant_message_id && data.data_content) {
            // store the message
            emit(receiveNewMessage(
                data.chatroom_id,
                memberId,
                {
                    id: data.instant_message_id,
                    senderId: data.sender_id,
                    senderName: data.sender_name,
                    sentTime: data.sent_time,
                    messageType: data.chat_type_id,
                    textContent: data.text_content,
                    dataContent: data.data_content,
                    displayName: data.display_name,
                    fileName: data.file_name,
                    seenByCount: data.read_count || 0,
                    seenByCurrentUser: data.is_seen_by_current_user || false,

                    localPath: null,
                    status: null,
                    ack: {},
                    redactTime: null,
                    replyingMessageId: null,
                    replyingTextContent: null,
                    replyingMessageType: null,
                    replyingDataContent: null,
                    replyingFilePath: null,
                    replyingSenderId: null,
                    parentMessageId: null,
                },
                data.instant_message_id,
                'end',
                {
                    textContent: data.text_content,
                    sentTime: data.sent_time,
                },
                data.sender_id === memberId ? data.sent_time : null,
                data.sender_id === memberId ? 1 : 0
            ));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event new-candidate with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event new-candidate with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('updated-candidate', (data) => {
        devLog('socket', ['updated-candidate', data]);
        if (data && data.chatroom_id && data.instant_message_id && data.data_content) {
            // store the message
            emit(receiveNewMessage(
                data.chatroom_id,
                memberId,
                {
                    id: data.instant_message_id,
                    senderId: data.sender_id,
                    senderName: data.sender_name,
                    sentTime: data.sent_time,
                    messageType: data.chat_type_id,
                    textContent: data.text_content,
                    dataContent: data.data_content,
                    displayName: data.display_name,
                    fileName: data.file_name,
                    seenByCount: data.read_count || 0,
                    seenByCurrentUser: data.is_seen_by_current_user || false,

                    localPath: null,
                    status: null,
                    ack: {},
                    redactTime: null,
                    replyingMessageId: null,
                    replyingTextContent: null,
                    replyingMessageType: null,
                    replyingDataContent: null,
                    replyingFilePath: null,
                    replyingSenderId: null,
                    parentMessageId: null,
                },
                data.instant_message_id,
                'end',
                {
                    textContent: data.text_content,
                    sentTime: data.sent_time,
                },
                data.sender_id === memberId ? data.sent_time : null,
                data.sender_id === memberId ? 1 : 0
            ));
            emit(loadMessages(data.chatroom_id, memberId, true, 0));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Socket event updated-candidate with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event updated-candidate with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('new-message-status', (data) => {
        devLog('socket', ['new-message-status', data]);
        // chatId should really be in the response header, but we have to extract it from message details
        let chatId = 0;
        let readerId = 0;
        if (data.instant_message_status_list && data.instant_message_status_list[0]) {
            chatId = data.instant_message_status_list[0].chatroom_id;
            readerId = data.instant_message_status_list[0].member_id;
        }

        const statusAction = parseMessageStatuses(memberId, chatId, data, false);

        if (statusAction) {
            // If this has come from current user on another device then update counts
            if (readerId === memberId) {
                statusAction.newStatusCount = data.new_status_count;
                statusAction.updatedStatusCount = data.updated_status_count;
            }
            emit(statusAction);
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event new-message-status with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event new-message-status with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('message-status-sent', (data) => {
        devLog('socket', ['message-status-sent', data]);
        if (data && data.transaction_uuid) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event message-status-sent with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event message-status-sent with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('message-status-error', (error) => {
        devLog('socket', ['message-status-error', error]);
        if (error && error.transaction_uuid) {
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event message-status-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Socket event message-status-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('message-ack-sent', (data) => {
        devLog('socket', ['message-ack-sent', data]);
        if (data && data.transaction_uuid) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event message-ack-sent with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event message-ack-sent with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('message-ack-error', (error) => {
        devLog('socket', ['messages-ack-error', error]);
        if (error && error.transaction_uuid) {
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event ack-message-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Got Socket event ack-message-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('new-message-ack', (data) => {
        devLog('socket', ['new-message-ack', data]);
        if (data && data.chatroom_id && data.instant_message_id) {
            emit(receiveAcknowledge(data.chatroom_id, data.instant_message_id, data.chatroom_member_id, `0x${data.symbol.substring(2)}`));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event new-message-ack with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event new-message-ack with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('message-redacted', (data) => {
        devLog('socket', ['message-redacted', data]);
        if (data && data.chatroom_id && data.instant_message_id && data.redact_time) {
            emit(redactMessageSuccess(data.chatroom_id, data.instant_message_id, data.redact_time));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event message-redacted with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event message-redacted with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('file-message-deleted', (data) => {
        devLog('socket', ['file-message-deleted', data]);
        if (data && data.chatroom_id && data.instant_message_id) {
            emit(deleteFileMessageSuccess(data.chatroom_id, data.instant_message_id));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event file-message-deleted with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event file-message-deleted with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('message-redact-error', (error) => {
        devLog('socket', ['message-redact-error', error]);
        if (error && error.transaction_uuid) {
            emit(setSagaMessage('Failed to redact message', '', ''));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket even message-redact-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Got socket event message-redact-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('message-unredacted', (data) => {
        devLog('socket', ['message-unredacted', data]);
        if (data && data.chatroom_id && data.instant_message_id) {
            emit(unredactMessageSuccess(data.chatroom_id, data.instant_message_id));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event message-unredacted with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event message-unredacted with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('message-unredact-error', (error) => {
        devLog('socket', ['message-unredact-error', error]);
        if (error && error.transaction_uuid) {
            emit(setSagaMessage('Failed to unredact message', '', ''));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket even message-unredact-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Got Socket event message-unredact-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('started-typing', (data) => {
        devLog('socket', ['started-typing', data]);
        if (data && data.transaction_uuid) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event started-typing with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event started-typing with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('start-typing-error', (error) => {
        devLog('socket', ['start-typing-error', error]);
        if (error && error.transaction_uuid) {
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket even start-typing-error with unexpected data:', error);
        } else {
            Sentry.captureException('Got socket event start-typing-error with unexpected data:', +JSON.stringify(error));
        }
    });

    socket.on('stopped-typing', (data) => {
        devLog('socket', ['stopped-typing', data]);
        if (data && data.transaction_uuid) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event stopped-typing with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event stopped-typing with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('stop-typing-error', (error) => {
        devLog('socket', ['stopped-typing-error', error]);
        if (error && error.transaction_uuid) {
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket even stop-typing-error with unexpected data:', error);
        } else {
            Sentry.captureException('Got socket event stop-typing-error with unexpected data:', +JSON.stringify(error));
        }
    });

    socket.on('user-started-typing', (data) => {
        devLog('socket', ['user-started-typing', data]);
        if (data && data.chatroom_id && data.user_id) {
            emit(memberStartedTyping(data.chatroom_id, data.user_id));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event user-started-typing with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event user-started-typing with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('user-stopped-typing', (data) => {
        devLog('socket', ['user-stopped-typing', data]);
        if (data && data.chatroom_id && data.user_id) {
            emit(memberStoppedTyping(data.chatroom_id, data.user_id));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event user-stopped-typing with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event user-stopped-typing with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('pinned-chatroom', (data) => {
        devLog('socket', ['pinned-chatroom', data]);
        if (data && data.chatroom_id && data.pin_number) {
            emit(pinnedChatroom(data.chatroom_id, data.pin_number));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event pinned-chatroom with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event pinned-chatroom with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('pin-chatroom-error', (error) => {
        devLog('socket', ['pin-chatroom-error', error]);
        if (error && error.transaction_uuid) {
            emit(setSagaMessage('Failed to pin chatroom', '', ''));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket even pin-chatroom-error with unexpected data:', error);
        } else {
            Sentry.captureException('Got socket event pin-chatroom-error with unexpected data:', +JSON.stringify(error));
        }
    });

    socket.on('unpinned-chatroom', (data) => {
        devLog('socket', ['unpinned-chatroom', data]);
        if (data && data.chatroom_id) {
            if (data.pinned_chatrooms) {
                emit(updatePinnedChatrooms(data.pinned_chatrooms));
            }
            emit(unpinnedChatroom(data.chatroom_id));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event unpinned-chatroom with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event unpinned-chatroom with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('unpin-chatroom-error', (error) => {
        devLog('socket', ['unpin-chatroom-error', error]);
        if (error && error.transaction_uuid) {
            emit(setSagaMessage('Failed to unpin chatroom', '', ''));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket even unpin-chatroom-error with unexpected data:', error);
        } else {
            Sentry.captureException('Got socket event unpin-chatroom-error with unexpected data:', +JSON.stringify(error));
        }
    });

    socket.on('created-chatroom', (data) => {
        devLog('socket', ['created-chatroom', data]);
        if (data && data.chatroom_id) {
            if (data.transaction_uuid) {
                // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
                emit(commandResult(data.transaction_uuid, data, undefined));
            } else {
                emit(receiveChatroomInfo(
                    data.chatroom_id,
                    data.chatroom_name,
                    data.chatroom_description,
                    data.chatroom_type,
                    data.managed || false,
                    data.instant_message.sender_id,
                    1,
                    0,
                    1,
                    data.instant_message.sender_id === memberId ? 1 : 0,
                    0,
                    0,
                    {
                        textContent: data.instant_message.text_content,
                        sentTime: data.instant_message.sent_time,
                    },
                    data.last_update_time,
                    data.last_update_time,
                    data.donor_id,
                    data.organ_id,
                    null,
                    data.follower_id,
                    data.create_date
                ));
            }
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event created-chatroom with unexpected data:', data);
        } else {
            Sentry.captureException(`Socket event created-chatroom with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('create-chatroom-error', (error) => {
        devLog('socket', ['create-chatroom-error', error]);
        if (error && error.transaction_uuid) {
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event create-chatroom-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Got Socket event create-chatroom-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('update-chatroom-error', (error) => {
        devLog('socket', ['update-chatroom-error', error]);
        if (error && error.transaction_uuid) {
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event update-chatroom-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Got Socket event update-chatroom-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('chatroom-updated', (data) => {
        devLog('socket', ['chatroom-updated', data]);
        if (data && data.chatroom_id && data.chatroom_name) {
            emit(updateChatroomInfo(
                data.chatroom_id,
                data.chatroom_name,
                data.chatroom_description,
                data.managed,
                data.last_update_time
            ));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event chatroom-updated with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event chatroom-updated with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('updated-chatroom', (data) => {
        devLog('socket', ['updated-chatroom', data]);
        if (data && data.chatroom_id && Number.isInteger(data.chatroom_id) && data.transaction_uuid) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event updated-chatroom with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event updated-chatroom with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('cleared-notifications', (data) => {
        devLog('socket', ['cleared-notifications', data]);
        if (data && data.chatroom_id && data.cleared_count) {
            emit(setClearedNotificationCount(data.chatroom_id, data.cleared_count));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event cleared-notifications with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event cleared-notifications with unexpected data:${JSON.stringify(data)}`);
        }
    });

    socket.on('clear-notifications-error', (error) => {
        devLog('socket', ['clear-notifications-error', error]);
        if (error) {
            emit(setSagaMessage('Failed to clear notifications', singleErrorMessage(error), ''));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event clear-notifications-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Got Socket event clear-notifications-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('notification-resent', (data) => {
        devLog('socket', ['notification-resent', data]);
        if (data && data.transaction_uuid) {
            // $FlowFixMe: Since we return an object literal once the typer thinks we always will do that
            emit(commandResult(data.transaction_uuid, data, undefined));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event notification-resent with unexpected data:', data);
        } else {
            Sentry.captureException(`Got Socket event notification-resent with unexpected data:${JSON.stringify(data)}`);
        }
    });
    socket.on('resend-notification-error', (error) => {
        devLog('socket', ['resend-notification-error', error]);
        if (error && error.transaction_uuid) {
            emit(commandResult(error.transaction_uuid, undefined, error));
        } else if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Got socket event notification-resent-error with unexpected data:', error);
        } else {
            Sentry.captureException(`Got Socket event notification-resent-error with unexpected data:${JSON.stringify(error)}`);
        }
    });

    socket.on('verified-email', () => {
        devLog('socket', ['verified-email']);
        emit(verifyEmail());
    });

    socket.on('disconnect', () => {
        devLog('socket', ['disconnect']);
        // Set state to error and trigger reconnect
        emit(setSocketState('error'));
        emit(triggerChatSocket());
    });

    function offlineHandler() {
        devLog('socket', ['Network status changed to offline. Disconnecting websocket.']);
        socket.disconnect();
    }

    // listen for the "offline" event and immediately disconnect socket
    global.addEventListener('offline', offlineHandler);

    return () => {
        // Clear event handlers
        socket.off('application-data');
        socket.off('get-application-data-error');
        socket.off('donor-updated');
        socket.off('permissions-updated');
        socket.off('added-to-chatroom');
        socket.off('removed-from-chatroom');
        socket.off('add-user-error');
        socket.off('new-message');
        socket.off('new-message-status');
        socket.off('user-added-v2');
        socket.off('member-removed-v2');
        socket.off('chatroom-updated');
        socket.off('updated-chatroom');
        socket.off('update-chatroom-error');
        socket.off('disconnect');
        socket.off('message-sent');
        socket.off('send-message-error');
        socket.off('added-user');
        socket.off('not-txp-member');
        socket.off('removed-member-v2');
        socket.off('remove-member-error');
        socket.off('created-chatroom');
        socket.off('create-chatroom-error');
        socket.off('cleared-notificatons');
        socket.off('clear-notifications-error');
        socket.off('notification-resent');
        socket.off('resend-notification-error');
        socket.off('created-donor');
        socket.off('new-donor');
        socket.off('updated-donor');
        socket.off('new-recipient');
        socket.off('updated-recipient');
        socket.off('new-candidate');
        socket.off('updated-candidate');
        socket.off('message-status-sent');
        socket.off('message-status-error');
        socket.off('message-ack-sent');
        socket.off('message-ack-error');
        socket.off('new-message-ack');
        socket.off('verified-email');
        socket.off('start-typing-error');
        socket.off('stop-typing-error');
        socket.off('started-typing');
        socket.off('start-typing-error');
        socket.off('stopped-typing');
        socket.off('stop-typing-error');
        socket.off('user-started-typing');
        socket.off('user-stopped-typing');
        socket.off('pinned-chatroom');
        socket.off('pin-chatroom-error');
        socket.off('unpinned-chatroom');
        socket.off('unpin-chatroom-error');
        socket.off('users-added');
        socket.off('added-users');

        global.removeEventListener(offlineHandler);
    };
});

function* read(socket: io, memberId: number): Saga<void> {
    // Subscribe to all events\
    const channel = yield call(subscribe, socket, memberId);
    while (true) {
        const action = yield take(channel);
        yield put(action);
    }
}

function* write(socket: io, memberId: number): Saga<void> {
    while (true) {
        const cmdChannel = getCommandChannel();

        if (cmdChannel) {
            const action: SendCommand = yield take(cmdChannel);
            const method = getCommandMethod(action.command || '');
            yield call(method, socket, action.chatId, memberId, action.transactionId, ...(action.params || []));
        } else {
            yield delay(300);
        }
    }
}

function* handleIO(socket: io, memberId: number): Saga<void> {
    const readTask = yield fork(read, socket, memberId);
    const writeTask = yield fork(write, socket, memberId);
    try {
        while (true) {
            // Keep this task active by delaying for a long time so we can cascade the cancel
            yield delay(1000000);
        }
    } finally {
        if (yield cancelled()) {
            yield cancel(readTask);
            yield cancel(writeTask);
        }
    }
}

function* protocolHandler(
    token: string,
    memberId: number
): Saga<void> {
    let socket;
    let ioTask;

    try {
        yield put(setSocketState('connecting'));

        // Attempt to connect
        try {
            socket = yield call(connect);
        } catch (firstError) {
            try {
                Sentry.captureException(`First Connect Failed:${JSON.stringify(firstError)}`);
                // Retry once - for genuine network jitters and to allow for network changed errors (occur after reconnecting too quickly)
                // 3000ms is empirically derived through testing - may need to be revised
                yield put(setSagaMessage('Network unavailable - Retrying', '', '', true));
                yield delay(3000);
                socket = yield call(connect);
            } catch (secondError) {
                Sentry.captureException(`Second Connect Failed:${JSON.stringify(secondError)}`);
                yield put(setSocketState('error', 'generic'));
                yield put(setSagaMessage('Network unavailable', '', 'OK', true));
                return;
            }
        }

        // Authenticate socket connection
        try {
            yield call(authenticate, socket, token);
        } catch (error) {
            Sentry.captureException(`Auth Failed:${JSON.stringify(error)}`);
            yield put(setSocketState('error', 'auth_failed'));
            yield put(refreshAccessToken('TXP_CHAT_WEB'));
            return;
        }

        // If the network error is still active then clear it
        const sagaHeading = yield select((state) => state.application.sagaHeading);
        if (sagaHeading && (sagaHeading === 'Network unavailable' || sagaHeading === 'Network unavailable - Retrying')) {
            yield put(setSagaMessage('', '', ''));
        }

        // Start io handler in background
        ioTask = yield fork(handleIO, socket, memberId);

        yield put(setSocketState('connected'));

        const activeChatId = yield select((state) => state.chatList.activeChatId);
        const chatIds = hasValue(activeChatId) ? [activeChatId] : [];
        yield put(getApplicationData(true, true, hasValue(activeChatId), hasValue(activeChatId), chatIds));

        // Wait for events that should trigger socket disconnect
        const action = yield take([
            'Auth/LOGOUT',
            'Application/RESET_DATA',
            'Auth/TOKEN_EXPIRED'
        ]);

        // Cancel IO to terminate emitting/listening
        yield cancel(ioTask);

        if (action && (action.type === 'Auth/LOGOUT' || action.type === 'Application/RESET_DATA')) {
            // If logging out, flush all buffers
            const commandChannel = getCommandChannel();
            const resultChannel = getResultChannel();

            // Flush outgoing buffer
            if (commandChannel) {
                yield flush(commandChannel);
            }

            // Flush incoming buffer
            if (resultChannel) {
                yield flush(resultChannel);
            }
        }

        // Update Socket state
        yield put(setSocketState('unknown'));
        socket.removeAllListeners();
        socket.close();
        socket = undefined;
    } finally {
        if (yield cancelled()) {
            // If socket object exists
            if (socket) {
                // If successfully set, cancel the dependent ioTasks
                if (ioTask) {
                    yield cancel(ioTask);
                }

                // Remove all listeners and close socket
                socket.removeAllListeners();
                socket.close();
                socket = undefined;
            }
        }
    }
}

export default function* initProtocol(): Saga<void> {
    const token = yield select((state) => state.auth.accessToken);
    const memberId = yield select((state) => selectUserId(state.auth));
    yield call(protocolHandler, token, memberId);
}
