// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from 'react-loading-indicator';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { DateTimePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import PanelNavigation from './PanelNavigation';

import {
    DONOR_STATUS_VIEW,
    selectDonorView,
} from '../Redux/ApplicationActions';
import { updateTaskDueDate } from '../Redux/DonorActions';

import styles from './Styles/DonorStyles';

function TaskDueDateView() {
    const dispatch = useDispatch();

    const donorTaskLoading = useSelector((state) => state.loading.donorTask);
    const donorTask = useSelector((state) => state.donor.donorTask);
    const {
        donorId,
        taskId,
    } = donorTask;

    const donor = useSelector((state) => ((state.donor.donors && state.donor.donors[donorId]) ? state.donor.donors[donorId] : {}));

    let workflowTasks = [];
    const stateWorkflowTasks = useSelector((state) => state.donor.workflowTasks);
    const workflow = (donor && donor.workflow) ? donor.workflow : '';
    if (stateWorkflowTasks && stateWorkflowTasks[workflow] && stateWorkflowTasks[workflow].tasks) {
        workflowTasks = stateWorkflowTasks[workflow].tasks;
    }

    const taskIndex = workflowTasks && workflowTasks.length > 0 ? workflowTasks.findIndex((x) => x.taskId === taskId) : -1;
    const task = workflowTasks && workflowTasks.length > 0 && taskIndex !== -1 ? workflowTasks[taskIndex] : null;
    const taskName = task ? task.details.description : 'Could not find task';

    const [dueDate, setDueDate] = useState(null);
    const [previousTaskId, setPreviousTaskId] = useState(null);
    const [daysInput, setDaysInput] = useState('');
    const [hoursInput, setHoursInput] = useState('');
    const [minutesInput, setMinutesInput] = useState('');
    const [secondsInput, setSecondsInput] = useState('');
    const [calculatedInterval, setCalculatedInterval] = useState(null);
    const [calculatedPreviousTask, setCalculatedPreviousTask] = useState(null);
    const [canSaveDate, setCanSaveDate] = useState(false);

    const isDueDateSet = donorTask && donorTask.dueDateInterval && donorTask.dueDateInterval > 0;

    useEffect(() => {
        // Re-initialize the values when the donor task changes
        setIntervalFromSeconds(donorTask.dueDateInterval);
        setPreviousTaskId(donorTask.previousTaskId || 0);
        setCalculatedPreviousTask(donorTask.previousTaskId || null);
        setDueDate(donorTask.dueDate ? new Date(donorTask.dueDate) : null);
        setCanSaveDate(false);
    }, [donorTask]);

    const onClearDueDate = () => {
        if (isDueDateSet) {
            dispatch(updateTaskDueDate(donorId, taskId, null, null, donorTask.lastModified));
        }
    };

    const onConfirmDueDate = (newDueDate: ?Date) => {
        const donorCreateDate = new Date(donor.createDate);
        let secondsInterval = null;

        if (newDueDate) {
            const msInterval = newDueDate.getTime() - donorCreateDate.getTime();
            secondsInterval = Math.round(msInterval / 1000);
        }

        // Reset duration values
        const daysValue = '';
        const hoursValue = '';
        const minutesValue = '';
        const secondsValue = '';
        const previousTask = null;

        setPreviousTaskId(previousTask);
        setDaysInput(daysValue);
        setHoursInput(hoursValue);
        setMinutesInput(minutesValue);
        setSecondsInput(secondsValue);
        setDueDate(newDueDate);
        setCalculatedInterval(secondsInterval);
        setCalculatedPreviousTask(null);

        setCanSave(newDueDate, previousTask, daysValue, hoursValue, minutesValue, secondsValue);
    };

    const setCanSave = (
        dueDateValue: ?Date,
        previousTask: ?number,
        daysValue: string,
        hoursValue: string,
        minutesValue: string,
        secondsValue: string
    ) => {
        const durationSet = (daysValue || hoursValue || minutesValue || secondsValue) && previousTask !== '';
        const dateSet = dueDateValue != null;

        setCanSaveDate((durationSet || dateSet) && !(durationSet && dateSet) && !donorTaskLoading);
    };

    const setIntervalFromSeconds = (totalSeconds: ?number) => {
        let daysValue = '';
        let hoursValue = '';
        let minutesValue = '';
        let secondsValue = '';

        if (totalSeconds) {
            const days = Math.floor(totalSeconds / 86400);
            const hours = Math.floor((totalSeconds - (days * 86400)) / 3600);
            const minutes = Math.floor((totalSeconds - (days * 86400) - (hours * 3600)) / 60);
            const seconds = totalSeconds - (days * 86400) - (hours * 3600) - (minutes * 60);

            daysValue = days.toString(10);
            hoursValue = hours.toString(10);
            minutesValue = minutes.toString(10);
            secondsValue = seconds.toString(10);
        }

        setDaysInput(daysValue);
        setHoursInput(hoursValue);
        setMinutesInput(minutesValue);
        setSecondsInput(secondsValue);
        setCalculatedInterval(totalSeconds);
    };

    const setDuration = (daysValue: string, hoursValue: string, minutesValue: string, secondsValue: string) => {
        // Remove anything from the strings that isn't a number.
        const intDaysInput = daysValue.replace(/[^0-9]/g, '');
        const intHoursInput = hoursValue.replace(/[^0-9]/g, '');
        const intMinutesInput = minutesValue.replace(/[^0-9]/g, '');
        const intSecondsInput = secondsValue.replace(/[^0-9]/g, '');

        const days = intDaysInput ? parseInt(intDaysInput, 10) : 0;
        const hours = intHoursInput ? parseInt(intHoursInput, 10) : 0;
        const minutes = intMinutesInput ? parseInt(intMinutesInput, 10) : 0;
        const seconds = intSecondsInput ? parseInt(intSecondsInput, 10) : 0;
        const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;

        const newDueDate = null;

        setDaysInput(intDaysInput);
        setHoursInput(intHoursInput);
        setMinutesInput(intMinutesInput);
        setSecondsInput(intSecondsInput);
        setCalculatedInterval(totalSeconds);
        setDueDate(newDueDate);

        setCanSave(newDueDate, previousTaskId, intDaysInput, intHoursInput, intMinutesInput, intSecondsInput);
    };

    const setPreviousTask = (newPreviousTask: number) => {
        const newDueDate = null;

        setPreviousTaskId(newPreviousTask);
        setCalculatedPreviousTask(newPreviousTask);
        setDueDate(newDueDate);

        setCanSave(newDueDate, newPreviousTask, daysInput, hoursInput, minutesInput, secondsInput);
    };

    const saveDueDate = () => {
        dispatch(updateTaskDueDate(donorId, taskId, calculatedPreviousTask, calculatedInterval, donorTask.lastModified));
    };

    const onBackClick = () => {
        dispatch(selectDonorView(DONOR_STATUS_VIEW));
    };

    const taskOptions = workflowTasks
        .filter((workflowTask) => workflowTask.taskId !== taskId)
        .map((workflowTask) => ({
            label: workflowTask.details.description,
            value: workflowTask.taskId,
        }));
    taskOptions.unshift({ label: 'Case Start', value: 0, });

    const renderTaskOptions = () => (taskOptions.map((taskOption) => (
        <MenuItem key={`task-${taskOption.value}`} value={taskOption.value}>{taskOption.label}</MenuItem>
    )));

    return (
        <>
            <PanelNavigation
                title="Set Task Due Date"
                onBackClick={onBackClick}
                onSaveClick={saveDueDate}
                hideSave={false}
                saveDisabled={!canSaveDate}
            />
            <div style={styles.dueDate.taskTitle}>
                {taskName}
            </div>
            <div
                style={(isDueDateSet && !donorTaskLoading) ? styles.dueDate.clearDueDateBtn : styles.dueDate.disabledClearDueDateBtn}
                className="lineButtons"
                role="button"
                tabIndex="0"
                onKeyDown={onClearDueDate}
                onClick={onClearDueDate}
                data-tip="Unset the due date for this task"
                data-delay-show="500"
                data-effect="solid"
            >
                Clear due date
            </div>
            {(donorTaskLoading) ? (
                <div style={styles.loadingIndicator}>
                    <LoadingIndicator />
                </div>
            ) : (
                <div
                    style={styles.dueDate.mainContainer}
                >
                    <div style={styles.dueDate.selectionSection}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                            <DateTimePicker
                                label="Select Due Date"
                                // minDateTime={new Date()}
                                disablePast
                                value={dueDate}
                                onChange={(date) => onConfirmDueDate(date)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        color="primary"
                                        fullWidth
                                        // placeholder="Select a due date"
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div style={styles.dueDate.dividerContainer}>
                        OR
                    </div>
                    <div style={styles.dueDate.selectionSection}>
                        <div style={styles.dueDate.durationSelectContainer}>
                            <div style={styles.dueDate.durationInput}>
                                <TextField
                                    label="Days"
                                    type="number"
                                    onChange={(event) => setDuration(event.target.value, hoursInput, minutesInput, secondsInput)}
                                    value={daysInput}

                                />
                            </div>
                            <div style={styles.dueDate.durationInput}>
                                <TextField
                                    label="Hours"
                                    type="number"
                                    onChange={(event) => setDuration(daysInput, event.target.value, minutesInput, secondsInput)}
                                    value={hoursInput}

                                />
                            </div>
                            <div style={styles.dueDate.durationInput}>
                                <TextField
                                    label="Minutes"
                                    type="number"
                                    onChange={(event) => setDuration(daysInput, hoursInput, event.target.value, secondsInput)}
                                    value={minutesInput}

                                />
                            </div>
                            <div style={styles.dueDate.lastDurationInput}>
                                <TextField
                                    label="Seconds"
                                    type="number"
                                    onChange={(event) => setDuration(daysInput, hoursInput, minutesInput, event.target.value)}
                                    value={secondsInput}
                                />
                            </div>
                        </div>
                        <div>
                            <FormControl
                                variant="standard"
                                fullWidth
                            >
                                <InputLabel
                                    id="tasks-select-label"
                                    shrink
                                >
                                    After
                                </InputLabel>
                                <Select
                                    labelId="tasks-select-label"
                                    value={previousTaskId !== null ? previousTaskId : ''}
                                    onChange={(event) => setPreviousTask(event.target.value)}
                                >
                                    {renderTaskOptions()}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TaskDueDateView;
