// @flow
import Fonts from '../../Themes/Fonts';
import Colors from '../../Themes/Colors';

// This file is for a reusable grouping of Theme items.
// Similar to an XML fragment layout in Android
const ErrorStyles = {
    verticalCenter: {
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        width: 80,
        height: 80,
    },
    navButton: {
        flex: 1,
        gap: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: Colors.white,
        color: Colors.buttonBackground,
        textTransform: 'none',
        textDecoration: 'none',
        margin: 0,
        padding: 0,
        fontSize: 14,
    },
    buttonText: {
        margin: '0.5rem',
    },
    errorHeader: {
        ...Fonts.style.h2,
        display: 'flex',
        gap: '1rem',
        color: Colors.white,
        backgroundColor: Colors.brandSecondary,
        padding: 20,
    },
    errorHeaderImg: {
        display: 'inline-block',
        width: 38,
        height: 38,
    },
    errorTitle: {
        ...Fonts.style.h3,
        color: Colors.gray,
    },
    errorMessage: {
        ...Fonts.style.normal,
        color: Colors.gray,
        lineHeight: 1.6,
    },
    errorMessageFirstLine: {
        marginTop: 0,
    },
    outerWrapper: {
        padding: 20,
        backgroundColor: Colors.lighterGray,
    },
    innerWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        margin: 25,
        padding: 40,
        backgroundColor: Colors.white,
    },
};

export default ErrorStyles;
