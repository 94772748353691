// @flow
import Colors from '../../Themes/Colors';

const UploadCDSStyles = {
    whiteBackground: {
        backgroundColor: Colors.white,
        paddingBottom: 65,
        overFlow: 'hidden',
    },
    closeButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    fileIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    uploadArea: {
        backgroundColor: Colors.lighterGray,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: Colors.chatItemBorder,
        margin: 20,
        borderRadius: 8,
        height: 200,
    },
    uploadButton: {
        backgroundColor: Colors.lighterGray,
        color: Colors.blue,
        fontSize: 20,
        margin: 20,
        textTransform: 'none',
    },
    uploadButtonDisabled: {
        backgroundColor: Colors.lighterGray,
        color: Colors.linkTextDisabled,
        fontSize: 20,
        margin: 20,
        textTransform: 'none',
    },
    uploadText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectUserWrapper: {
        padding: 20,
    },
    selectUserText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: Colors.formLabel,
        fontSize: 14,
    },
    cancelButton: {
        marginTop: 10,
        color: Colors.red,
        fontSize: 16,
        background: 'none',
        textTransform: 'none',
    },
};

export default UploadCDSStyles;
