// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from 'react-loading-indicator';
import { selectUserId } from 'txp-core';
import {
    Button,
    TextField,
    Switch,
} from '@material-ui/core';

import hasPermissions, {
    ENTITY_TYPE_DONOR, NONE, UPDATE,
} from '../Utils/hasPermissions';
import AddPeople from './AddPeople';
import FollowerTaskList from './FollowerTaskList';
import TabSelector from './TabSelector';
import BackButton from './BackButton';
import { getTeams } from '../Redux/TeamActions';
import { setSagaMessage } from '../Redux/ApplicationActions';
import {
    setFollowerName,
    setFollowerCanUpdate,
    createFollowerGroup,
    updateFollowerGroup,
    resetFollowerEditData,
    setPendingFollowerUsers,
    setFollowerHasChanged,
    setFollowerTasks,
    inviteNewUser as _inviteNewUser,
} from '../Redux/FollowerEditActions';
import type { FollowerEditState } from '../Redux/FollowerEditActions';
import {
    resetAddPeople,
    setAddPeopleError,
} from '../Redux/AddPeopleActions';
import { getContacts, resetChatroomDetails } from '../Redux/ChatroomActions';
import { filterPeople } from '../Redux/ChatListActions';
import FollowerGroupStyles from './Styles/FollowerGroupStyles';
import ChatListStyles from './Styles/ChatListStyles';

import type { UserProfile, Donor } from '../Utils/types';
import FollowerMemberList from './FollowerMemberList';

type Props = {
    onGoBackClick: () => *,
}

const TASKS_TAB = 'tasks';
const PEOPLE_TAB = 'people';

function FollowerGroupDetails(props: Props) {
    const {
        onGoBackClick,
    } = props;

    const dispatch = useDispatch();
    const avatars = useSelector((state) => state.chatList.avatars);
    const permissions = useSelector((state) => state.permission.permissions || []);
    const followerLoading = useSelector((state) => state.loading.follower);
    const openFollowerGroupId = useSelector((state) => state.donor.openFollowerGroupId);
    const followerGroupChatroom = useSelector((state) => Object.values(state.chatList.chats)
        // $FlowFixMe
        .find((chat) => chat.followerId === openFollowerGroupId) || {});
    const donorId = useSelector((state) => state.donor.openDonorId);
    const currentDonor: Donor = useSelector((state) => state.donor.donors[`${donorId}`]);

    const donorFollowerGroups = currentDonor && currentDonor.followerGroups ? currentDonor.followerGroups : {};
    const followerGroup = donorFollowerGroups[openFollowerGroupId] || null;
    const followerEdit: FollowerEditState = useSelector((state) => state.followerEdit);
    const {
        pendingUsers,
        pendingRemovedUsers,
        followerHasChanged,
        taskIds,
    } = followerEdit;
    const followerGroupName = followerEdit.followerName;
    const addPeopleError = useSelector((state) => state.addPeople.error);

    // Needed for Add People page
    const teams = useSelector((state) => state.team.teams);
    const teamsLoading = useSelector((state) => state.loading.teams);
    const memberId = useSelector((state) => selectUserId(state.auth));
    const profile = useSelector((state) => state.auth.profile);
    const isConnected = useSelector((state) => state.chatList.socketStatus === 'connected');
    const contactsOrder = useSelector((state) => state.chatroom.contactsOrder);
    const contactsAreLoaded = useSelector((state) => state.chatroom.contactsAreLoaded);
    const contactsMap = useSelector((state) => state.chatroom.contactsMap);
    const peopleFilter = useSelector((state) => state.chatList.peopleFilter);
    const singleUserAdded = useSelector((state) => state.chatroom.singleUserAdded);
    const newUserInvited = useSelector((state) => state.chatroom.newUserInvited);
    const addSingleUserError = useSelector((state) => state.chatroom.addSingleUserError);
    const inviteNewUserError = useSelector((state) => state.chatroom.inviteNewUserError);

    const [selectedTab, setSelectedTab] = useState(TASKS_TAB);
    const [showAddPeople, setShowAddPeople] = useState(false);

    const memberUsers = followerGroup && followerGroup.memberUsers ? followerGroup.memberUsers : [];
    const followerMemberMap = {};
    for (let i = 0; i < memberUsers.length; i += 1) {
        followerMemberMap[memberUsers[i].userId] = memberUsers[i];
    }
    for (let i = 0; i < pendingUsers.length; i += 1) {
        followerMemberMap[pendingUsers[i].userId] = pendingUsers[i];
    }

    const canUpdateDonor = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorId, NONE, NONE, UPDATE);

    const showTasks = selectedTab === TASKS_TAB;
    const showPeople = selectedTab === PEOPLE_TAB;

    // check for changes when followerEdit changes
    useEffect(() => {
        const hasPendingUsers = (pendingUsers.length > 0 || pendingRemovedUsers.length > 0);
        const isEditing = followerGroup && followerGroup.followerGroupId;
        let hasUpdated = false;
        if (isEditing) {
            // Check differences between followerGroup and followerEdit
            hasUpdated = (followerEdit.followerName !== followerGroup.name
                || followerEdit.canUpdate !== followerGroup.canUpdate
                || tasksHaveChanged(followerGroup.tasks || [])
                || hasPendingUsers);
        } else {
            hasUpdated = followerEdit.followerName !== '';
        }

        if (hasUpdated && !followerHasChanged) {
            dispatch(setFollowerHasChanged(true));
        } else if (!hasUpdated && followerHasChanged) {
            dispatch(setFollowerHasChanged(false));
        }
    }, [followerEdit]);

    // When component unmounts, reset follower edit data
    useEffect(() => () => {
        dispatch(resetFollowerEditData());
    }, []);

    const tasksHaveChanged = (prevTasks: number[]) => {
        if (prevTasks.length !== taskIds.length) {
            return true;
        }

        taskIds.sort();
        prevTasks.sort();
        for (let i = 0; i < taskIds.length; i += 1) {
            if (taskIds[i] !== prevTasks[i]) {
                return true;
            }
        }
        return false;
    };

    const onSelectTasksTab = () => {
        setSelectedTab(TASKS_TAB);
    };

    const onSelectPeopleTab = () => {
        setSelectedTab(PEOPLE_TAB);
    };

    const inviteNewUser = (email: string, phone: string) => {
        if (!openFollowerGroupId || !followerGroupChatroom.id) {
            dispatch(setSagaMessage(
                'User Invite Error',
                'Please create and save the follower group before inviting users outside your organization.',
                'OK',
                true,
                true
            ));
            return;
        }

        dispatch(_inviteNewUser(openFollowerGroupId, followerGroupChatroom.id, memberId, email, phone));
    };

    const onShowAddPeople = () => {
        dispatch(getContacts());
        dispatch(getTeams());

        // Toggle the show add people flag
        setShowAddPeople(true);
    };

    // NOTE: As of now this only adds members that are OmniLife users and
    //  cannot add external users yet.
    const onAddUsers = (selectedUsers: Array<UserProfile>) => {
        const newPendingUsers = pendingUsers.concat(selectedUsers);

        dispatch(setPendingFollowerUsers(newPendingUsers));
        setShowAddPeople(false);
        dispatch(resetAddPeople());
    };

    const onClearMessage = () => {
        dispatch(setAddPeopleError());
        dispatch(resetChatroomDetails());
    };

    const onJoinGroup = () => {
        if (!followerGroup || !followerGroup.followerGroupId) return;

        const newPendingUsers = pendingUsers.concat(profile);
        dispatch(setPendingFollowerUsers(newPendingUsers));
    };

    const onSaveFollower = () => {
        if (followerGroup) {
            dispatch(updateFollowerGroup(donorId, followerGroup.followerGroupId, followerEdit));
        } else {
            dispatch(createFollowerGroup(donorId, followerEdit));
        }
    };

    const onSetFollowerTasks = (updatedTasks) => {
        dispatch(setFollowerTasks(updatedTasks));
    };

    const renderTasksList = () => (
        <FollowerTaskList
            donorId={donorId}
            followerEdit={followerEdit}
            onSetFollowerTasks={onSetFollowerTasks}
        />
    );

    const renderPeopleList = () => (
        <FollowerMemberList
            memberUsers={followerGroup && followerGroup.memberUsers ? followerGroup.memberUsers : []}
            externalUsers={followerGroup && followerGroup.externalUsers ? followerGroup.externalUsers : []}
            avatars={avatars}
            canUpdateDonor={canUpdateDonor}
            onShowAddPeopleClick={onShowAddPeople}
            onJoinGroupClick={onJoinGroup}
        />
    );

    const renderTabData = () => (
        showPeople ? renderPeopleList() : renderTasksList()
    );

    if (followerLoading) {
        return (
            <div style={ChatListStyles.loadingIndicator}>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        showAddPeople ? (
            <AddPeople
                memberId={memberId}
                contactsOrder={contactsOrder}
                contactsMap={contactsMap}
                teams={teams}
                teamsLoading={teamsLoading}
                contactsAreLoaded={contactsAreLoaded}
                singleUserAdded={singleUserAdded}
                inviteNewUser={inviteNewUser}
                newUserInvited={newUserInvited}
                addSingleUserError={addSingleUserError}
                inviteNewUserError={inviteNewUserError}
                avatars={avatars}
                members={followerMemberMap}
                peopleFilter={peopleFilter}
                createChatroom={false}
                isConnected={isConnected}
                onHideAddPeople={() => setShowAddPeople(false)}
                onAddUsers={onAddUsers} // Add the selected user to the follower group
                clearMessage={onClearMessage}
                filterPeople={(filter: string) => dispatch(filterPeople(filter))}
                addPeopleError={addPeopleError}
            />
        ) : (
            <>
                <div
                    style={FollowerGroupStyles.detailsHeader}
                >
                    <BackButton
                        label="Back"
                        goBack={onGoBackClick}
                    />
                    Follower Details
                    <Button
                        style={followerHasChanged ? FollowerGroupStyles.detailsHeaderButton : FollowerGroupStyles.detailsHeaderButtonDisabled}
                        component="button"
                        disabled={!followerHasChanged}
                        disableRipple
                        onClick={onSaveFollower}
                    >
                        {followerGroup ? 'Save' : 'Create'}
                    </Button>
                </div>
                <div
                    style={FollowerGroupStyles.sectionContainer}
                >
                    <TextField
                        disabled={false}
                        fullWidth
                        color="primary"
                        label="Follower Group Name:"
                        type="text"
                        InputLabelProps={{ shrink: true, }}
                        value={followerGroupName}
                        onChange={(event) => dispatch(setFollowerName(event.target.value))}
                    />
                </div>
                <div>
                    <Switch
                        color="primary"
                        checked={followerEdit.canUpdate}
                        onChange={(event) => dispatch(setFollowerCanUpdate(event.target.checked))}
                    />
                    <span>Group can update tasks</span>
                </div>
                <TabSelector
                    labels={['Tasks', 'People']}
                    selected={[showTasks, showPeople]}
                    width={100}
                    borderRadius={false}
                    clickEvents={[onSelectTasksTab, onSelectPeopleTab]}
                />
                {renderTabData()}
            </>
        )
    );
}

export default FollowerGroupDetails;
