// @flow
import {
    cancel, fork, join, take,
} from 'redux-saga/effects';

export const CANCEL_SAGAS_HMR = '@@saga_manager/CANCEL_SAGAS_HMR';

// Borrowed from https://gist.github.com/hoschi/6538249ad079116840825e20c48f1690
// Note that reloading sagas has several issues/caveats to be aware of.
// See https://github.com/yelouafi/redux-saga/issues/22#issuecomment-218737951 for discussion.
function createAbortAbleSaga(saga, hot) {
    return function* main() {
        while (true) {
            try {
                const sagaTask = yield fork(saga, hot);

                if (process.env.NODE_ENV === 'development') {
                    yield take(CANCEL_SAGAS_HMR);
                    yield cancel(sagaTask);
                    break; // cancelled, break out of the loop
                } else {
                    yield join(sagaTask);
                }
            } catch (err) {
                // Log the error and trap it, allowing the saga to be restarted
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.warn('Saga crashed', saga.name, err);
                }
            }
        }
    };
}

const SagaManager = {
    run(sagaMiddleware: *, sagas: Array<*>, hot: boolean = false) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('DEBUG: Starting sagas');
        }

        sagas.map((saga) => createAbortAbleSaga(saga, hot)).forEach((saga) => {
            sagaMiddleware.run(saga);
        });
    },

    cancel(store: *) {
        store.dispatch({
            type: CANCEL_SAGAS_HMR,
        });
    },
};

export default SagaManager;
