// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon';
import Fade from 'react-reveal/Fade';
import sift from 'sift';
import ReactTooltip from 'react-tooltip';
import {
    selectProfileCommunicationPref,
    selectProfileEmail,
    selectProfileName,
    selectProfilePhone,
} from 'txp-core';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import Search from './Search';
import OfferItem from './OfferItem';
import OrganizationItem from './OrganizationItem';
import SurgeonItem from './SurgeonItem';
import AddPeople from './AddPeople';
import OfferData from './OfferData';
import BackButton from './BackButton';
import TabSelector from './TabSelector';
import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import AuthStyles from '../Containers/Styles/AuthStyles';
import type { ChatEditState, ResearchOffer } from '../Redux/ChatEditActions';
import type { ChatroomState } from '../Redux/ChatroomActions';
import type {
    AvatarMap,
    ChatroomType,
    EventName,
    Offer,
    Organization,
    UserProfile,
    DonorRestrictions,
    Team,
} from '../Utils/types';
import {
    setManaged as _setManaged,
    setUNOSId as _setUNOSId,
    setMatchId as _setMatchId,
    setOrganType as _setOrganType,
    getOffer as _getOffer,
    getOffers as _getOffers,
    getOrganizations as _getOrganizations,
    getSurgeons as _getSurgeons,
    selectOffer as _selectOffer,
    selectOrganization as _selectOrganization,
    selectSurgeon as _selectSurgeon,
    setChatroomType as _setChatroomType,
    resetChatEditState as _resetChatEditState,
    setDonorCreateChat as _setDonorCreateChat,
    setOPODonorId as _setOPODonorId,
    setResearchOffer as _setResearchOffer,
    setDonorId as _setDonorId,
    setRestrictions as _setRestrictions,
    setPendingUsers as _setPendingUsers,
} from '../Redux/ChatEditActions';
import {
    filterOffers as _filterOffers,
    filterOrganizations as _filterOrganizations,
    resetFilters as _resetFilters,
} from '../Redux/ChatListActions';
import {
    getContacts as _getContacts,
    resetContactsStatuses as _resetContactsStatuses,
} from '../Redux/ChatroomActions';
import {
    CASE_TAB,
    logFirebaseEvent as _logFirebaseEvent,
} from '../Redux/ApplicationActions';
import hasPermissions, {
    ENTITY_TYPE_UI, TEMPLATED_CASE_FEATURE, NONE, READ,
} from '../Utils/hasPermissions';
import Checkbox from './Checkbox';

type Props = {
    chatInputHeight: number,
    memberId: number,
    editData: ChatEditState,
    avatars: AvatarMap,
    chatroomState: ChatroomState,
    offerFilter: string,
    organizationFilter: string,
    peopleFilter: string,
    verifiedOrgMember: boolean,
    isConnected: boolean,
    offerCreated: boolean,
    donorCreatingChat: boolean,
    selectedTab: string,
    canUseTemplate: boolean,
    currentDonorId: number,
    teamsLoading: boolean,
    teams: Array<Team>,

    getContacts: () => *,
    resetContactsStatuses: () => *,
    resetFilters: () => *,
    resetChatEditState: () => *,
    filterOffers: (filter: string) => *,
    getOffers: () => *,
    getOffer: () => *,
    filterOrganizations: (filter: string) => *,
    getOrganizations: () => *,
    filterPeople: (filter: string) => *,
    getSurgeons: () => *,
    onCreateChatroom: () => *,
    selectOffer: (offer: Offer) => *,
    selectOrganization: (organization: Organization) => *,
    selectSurgeon: (surgeon: UserProfile) => *,
    setManaged: (managed: boolean) => *,
    setName: (name: string) => *,
    setDonorCreateChat: (donorCreating: boolean) => *,
    setDescription: (description: string) => *,
    setUNOSId: (unosId: string) => *,
    setOPODonorId: (opoDonorId: string) => *,
    setResearchOffer: (section: string | null, key: string, value: any) => *,
    setRestrictions: (restrictions: DonorRestrictions) => *,
    setPendingUsers: (pendingUsers: Array<UserProfile>) => *,
    setDonorId: (donorId: number) => *,
    setMatchId: (matchId: string) => *,
    setOrganType: (organType: string) => *,
    addUsersToChatroom: (users: Array<UserProfile>) => *,
    onCancelButtonClick: () => *,
    onTriggerAskAlan: () => *,
    selectV2Option: (option: string) => *,
    setChatroomType: (chatroomType: ChatroomType) => *,
    logFirebaseEvent: (name: EventName) => *,
};

type State = {
    selectingOffer: boolean,
    selectingTransplantCenter: boolean,
    selectingSurgeon: boolean,
    viewSurgeon: boolean,
};

class CreateChatroom extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            selectingOffer: false,
            selectingTransplantCenter: false,
            selectingSurgeon: false,
            viewSurgeon: false,
        };
    }

    componentDidMount() {
        const {
            selectedTab,
            currentDonorId,
            getContacts,
            setChatroomType,
            setDonorId,
        } = this.props;
        getContacts();

        // NOTE: If we're in the case tab, this will be a 'Donor Only'
        // chatroom, so we'll preset some info for the 'Donor Only' chatroom
        if (selectedTab === CASE_TAB) {
            setChatroomType('Donor Only');
            setDonorId(currentDonorId);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {
            editData,
            offerCreated,
            resetChatEditState,
            resetContactsStatuses,
            setChatroomType,
            getOffers,
        } = this.props;

        // We don't want to reset the chatEdit state if we go from Standard to Case (or vise versa),
        // but we do want to reset the chatEdit state if we go from Templated Case to Standard/Case.
        if (prevProps.editData.chatroomType === 'Templated Case'
            && (editData.chatroomType === 'Standard' || editData.chatroomType === 'Case')) {
            const currentType = editData.chatroomType;

            resetChatEditState();
            resetContactsStatuses();
            setChatroomType(currentType);
        }

        if (!prevProps.offerCreated && offerCreated && editData.chatroomType === 'Templated Case') {
            getOffers();
        }
    }

    componentWillUnmount() {
        const {
            setDonorCreateChat,
        } = this.props;

        setDonorCreateChat(false);

        this.setState({
            selectingOffer: false,
            selectingTransplantCenter: false,
            selectingSurgeon: false,
            viewSurgeon: false,
        });
    }

    onSelectOffer = (offer: Offer) => {
        const {
            selectOffer,
            getOffer,
        } = this.props;

        // store donorId, organId, etc. in state
        selectOffer(offer);
        // load full offer to use in surgeon template
        getOffer();

        this.setState({
            selectingOffer: false,
            selectingTransplantCenter: false,
            selectingSurgeon: true,
            viewSurgeon: false,
        });
    };

    onSelectOrganization = (organization: Organization) => {
        const {
            selectOrganization,
        } = this.props;

        selectOrganization(organization);

        this.setState({
            selectingOffer: true,
            selectingTransplantCenter: false,
            selectingSurgeon: false,
            viewSurgeon: false,
        });
    };

    onSelectSurgeon = (surgeon: UserProfile) => {
        const {
            selectSurgeon,
        } = this.props;

        selectSurgeon(surgeon);

        this.setState({
            selectingOffer: false,
            selectingTransplantCenter: false,
            selectingSurgeon: false,
            viewSurgeon: true,
        });
    };

    onSearchOffers = (filter: string) => {
        const {
            filterOffers,
        } = this.props;

        filterOffers(filter);
    };

    onSearchOrganizations = (filter: string) => {
        const {
            filterOrganizations,
        } = this.props;

        filterOrganizations(filter);
    };

    onSetUNOSId = (event: any) => {
        const {
            setUNOSId,
        } = this.props;

        setUNOSId(event.target.value);
    };

    onSetName = (event: any) => {
        const {
            setName,
        } = this.props;

        setName(event.target.value);
    }

    onSetOPODonorId = (opoDonorId: any) => {
        const {
            setOPODonorId,
        } = this.props;

        setOPODonorId(opoDonorId.target.value);
    };

    onSetResearchOffer = (section: string | null, key: string, event: any) => {
        const {
            setResearchOffer,
        } = this.props;

        setResearchOffer(section, key, event.target.value);
    };

    onSetVisual = (researchOffer: ResearchOffer, value: Array<string>) => {
        const {
            setResearchOffer,
        } = this.props;
        researchOffer.liverVisual = value;

        setResearchOffer(null, 'liverVisual', value);
    }

    onSetMatchId = (matchId: any) => {
        const {
            setMatchId,
        } = this.props;

        setMatchId(matchId.target.value);
    };

    onSetOrganType = (organType: any) => {
        const {
            setOrganType,
        } = this.props;

        setOrganType(organType.target.value);
    };

    onSetRestrictions = (key: string) => {
        const {
            editData,
            setRestrictions,
        } = this.props;

        const currentValue = editData.restrictions[key];
        let tempRestrictions = editData.restrictions;

        tempRestrictions = {
            ...tempRestrictions,
            [key]: !currentValue,
        };

        setRestrictions(tempRestrictions);
    };

    onCloseClick = () => {
        const {
            onCancelButtonClick,
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('close-create-chatroom-click');

        onCancelButtonClick();
    };

    updateTab = (option: 'chatroom' | 'case' | 'templated' | 'offer') => {
        const {
            chatroomState,
            setChatroomType,
            selectV2Option,
            getContacts,
            getOffers,
            getOrganizations,
            resetChatEditState,
            resetFilters,
            resetContactsStatuses,
        } = this.props;

        if (option === 'chatroom') {
            this.setState({
                selectingOffer: false,
                selectingTransplantCenter: false,
                selectingSurgeon: false,
                viewSurgeon: false,
            });

            if (chatroomState.contactsOrder.length === 0) {
                getContacts();
            }

            selectV2Option('chatroom');

            setChatroomType('Standard');
        } else if (option === 'case') {
            this.setState({
                selectingOffer: false,
                selectingTransplantCenter: false,
                selectingSurgeon: false,
                viewSurgeon: false,
            });

            if (chatroomState.contactsOrder.length === 0) {
                getContacts();
            }

            selectV2Option('chatroom');

            setChatroomType('Case');
        } else if (option === 'templated') {
            getOffers();
            getOrganizations();

            this.setState({
                selectingOffer: false,
                selectingTransplantCenter: true,
                selectingSurgeon: false,
                viewSurgeon: false,
            });

            resetChatEditState();
            resetFilters();
            resetContactsStatuses();

            selectV2Option('offer');

            setChatroomType('Templated Case');
        } else if (option === 'offer') {
            this.setState({
                selectingOffer: false,
                selectingTransplantCenter: false,
                selectingSurgeon: false,
                viewSurgeon: false,
            });

            if (chatroomState.contactsOrder.length === 0) {
                getContacts();
            }

            selectV2Option('chatroom');

            setChatroomType('Research Offer');
        }
    };

    goBack = () => {
        const {
            editData,
            onCancelButtonClick,
        } = this.props;

        const {
            selectingOffer,
            selectingSurgeon,
            viewSurgeon,
        } = this.state;

        if (selectingOffer) {
            this.setState({
                selectingOffer: false,
                selectingTransplantCenter: true,
                selectingSurgeon: false,
                viewSurgeon: false,
            });
        } else if (selectingSurgeon) {
            this.onSelectOrganization(editData.organization);
        } else if (viewSurgeon) {
            this.onSelectOffer(editData.offer);
        } else {
            onCancelButtonClick();
        }
    };

    renderFieldError(fieldName: string, allowNonField: boolean) {
        const { editData, } = this.props;
        const { errors, } = editData;

        if (!errors) {
            return null;
        }

        let error = errors[fieldName];

        if (!error && allowNonField) {
            error = errors.nonFieldError;
        }

        if (!error) {
            return null;
        }

        return (
            <span style={{ ...AuthStyles.formError, ...{ display: 'block', }, }}>
                {error}
            </span>
        );
    }

    renderCloseButton() {
        return (
            <div>
                <ReactTooltip />
                <Button
                    style={CreateChatroomStyles.closeButton}
                    type="button"
                    ripple={false}
                    data-tip="Cancel"
                    data-delay-show="250"
                    data-effect="solid"
                    data-place="bottom"
                    onClick={this.onCloseClick}
                >
                    <FontIcon
                        value="close"
                        alt=""
                    />
                </Button>
            </div>
        );
    }

    renderTabs() {
        const {
            editData,
            canUseTemplate,
            verifiedOrgMember,
        } = this.props;

        const {
            chatroomType,
        } = editData;

        const isStandard = chatroomType === 'Standard';
        const isCase = chatroomType === 'Case' || chatroomType === 'Donor';
        const isTemplatedCase = chatroomType === 'Templated Case';

        let labelsArray = [];
        let selectedArray = [];
        let eventsArray = [];
        if (!verifiedOrgMember) {
            labelsArray = ['Standard'];
            selectedArray = [isStandard];
            eventsArray = [() => this.updateTab('chatroom')];
        } else if (canUseTemplate) {
            labelsArray = ['Standard', 'Case', 'Templated Case'];
            selectedArray = [isStandard, isCase, isTemplatedCase];
            eventsArray = [() => this.updateTab('chatroom'), () => this.updateTab('case'), () => this.updateTab('templated')];
        } else {
            labelsArray = ['Standard', 'Case'];
            selectedArray = [isStandard, isCase];
            eventsArray = [() => this.updateTab('chatroom'), () => this.updateTab('case')];
        }

        return (
            <div style={CreateChatroomStyles.centerTabs}>
                <TabSelector
                    labels={labelsArray}
                    selected={selectedArray}
                    width={42}
                    borderRadius
                    clickEvents={eventsArray}
                />
            </div>
        );
    }

    renderCreateCaseOnlyChatroom() {
        const {
            editData,
            avatars,
            memberId,
            chatroomState,
            peopleFilter,
            isConnected,
            chatInputHeight,
            teams,
            teamsLoading,
            setDescription,
            addUsersToChatroom,
            filterPeople,
            setManaged,
            setPendingUsers,
        } = this.props;

        const {
            contactsOrder,
            contactsMap,
            contactsAreLoaded,
            singleUserAdded,
            newUserInvited,
            addSingleUserError,
            inviteNewUserError,
        } = chatroomState;

        const {
            name,
            description,
            managed,
            restrictions,
        } = editData;

        return (
            <div style={{ paddingTop: 65, paddingBottom: 30, }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                    {this.renderCloseButton()}
                </div>
                <div style={CreateChatroomStyles.mainContainer}>
                    <div style={CreateChatroomStyles.title}>
                        Create Case Chatroom
                    </div>
                    <div style={CreateChatroomStyles.textInputWrapper}>
                        <input
                            style={CreateChatroomStyles.textInput}
                            type="text"
                            placeholder="Room Name"
                            maxLength={255}
                            value={name}
                            onChange={(event) => this.onSetName(event)}
                        />
                        <input
                            style={CreateChatroomStyles.textInput}
                            type="text"
                            placeholder="Room Description"
                            maxLength={255}
                            value={description}
                            onChange={(text) => setDescription(text)}
                        />
                    </div>
                    <div style={CreateChatroomStyles.permissionsContainer}>
                        <div style={{ ...CreateChatroomStyles.subtitle, ...{ justifyContent: 'center', }, }}>
                            Permissions
                        </div>
                        <div style={CreateChatroomStyles.subText}>
                            Members of this chatroom will be able to view
                        </div>
                        <div style={CreateChatroomStyles.permissionCheckboxContainer}>
                            <Checkbox
                                disabled={false}
                                className={!restrictions.DONOR_DETAILS ? 'iconBlue24' : 'iconDisabled24'}
                                iconName={!restrictions.DONOR_DETAILS ? faCheckCircle : faCircle}
                                label="Details about the donor"
                                onClick={() => this.onSetRestrictions('DONOR_DETAILS')}
                            />
                        </div>
                        <div style={CreateChatroomStyles.permissionCheckboxContainer}>
                            <Checkbox
                                disabled={false}
                                className={!restrictions.DONOR_FILES ? 'iconBlue24' : 'iconDisabled24'}
                                iconName={!restrictions.DONOR_FILES ? faCheckCircle : faCircle}
                                label="Files uploaded for the donor"
                                onClick={() => this.onSetRestrictions('DONOR_FILES')}
                            />
                        </div>
                    </div>
                    <span style={CreateChatroomStyles.managedHelpText}>If checked, room can only be managed by managers</span>
                    <Checkbox
                        disabled={false}
                        className={managed ? 'iconBlue24' : 'iconDisabled24'}
                        iconName={managed ? faCheckCircle : faCircle}
                        label="Set as a managed room"
                        onClick={() => setManaged(!managed)}
                    />
                    <div style={CreateChatroomStyles.addPeople}>
                        <AddPeople
                            members={{}}
                            avatars={avatars}
                            memberId={memberId}
                            contactsOrder={contactsOrder}
                            contactsMap={contactsMap}
                            contactsAreLoaded={contactsAreLoaded}
                            onUpdateSelectedUsers={setPendingUsers}
                            singleUserAdded={singleUserAdded}
                            newUserInvited={newUserInvited}
                            addSingleUserError={addSingleUserError}
                            inviteNewUserError={inviteNewUserError}
                            peopleFilter={peopleFilter}
                            createChatroom
                            isConnected={isConnected}
                            chatInputHeight={chatInputHeight || 0}
                            onAddUsers={addUsersToChatroom}
                            onHideAddPeople={() => {}} // noop
                            clearMessage={() => {}}
                            inviteExistingUser={() => {}}
                            inviteNewUser={() => {}}
                            filterPeople={filterPeople}
                            teams={teams}
                            teamsLoading={teamsLoading}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderCreateChatroom() {
        const {
            editData,
            chatInputHeight,
            memberId,
            avatars,
            chatroomState,
            isConnected,
            peopleFilter,
            teams,
            teamsLoading,
            setDescription,
            addUsersToChatroom,
            filterPeople,
            setPendingUsers,
            donorCreatingChat,
            setManaged,
        } = this.props;

        const {
            contactsOrder,
            contactsMap,
            contactsAreLoaded,
            singleUserAdded,
            newUserInvited,
            addSingleUserError,
            inviteNewUserError,
        } = chatroomState;

        const {
            name,
            description,
            managed,
            unosId,
            matchId,
            chatroomType,
            organType,
            opoDonorId,
        } = editData;

        const isCase = chatroomType === 'Case' || chatroomType === 'Donor';

        return (
            <div style={CreateChatroomStyles.whiteBackground}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                    {this.renderCloseButton()}
                </div>
                {donorCreatingChat ? null : this.renderTabs()}
                <div style={CreateChatroomStyles.mainContainer}>
                    <div style={CreateChatroomStyles.title}>
                        {isCase && !donorCreatingChat ? 'Create Case' : 'Create Room'}
                    </div>
                    {this.renderFieldError('name', true)}
                    {this.renderFieldError('unosId', false)}
                    {this.renderFieldError('matchId', false)}
                    {this.renderFieldError('organType', false)}
                    <div style={CreateChatroomStyles.textInputWrapper}>
                        <input
                            style={CreateChatroomStyles.textInput}
                            type="text"
                            placeholder="Room Name"
                            maxLength={255}
                            value={name}
                            onChange={(event) => this.onSetName(event)}
                        />
                        <input
                            style={CreateChatroomStyles.textInput}
                            type="text"
                            placeholder="Room Description"
                            maxLength={255}
                            value={description}
                            onChange={(text) => setDescription(text)}
                        />
                    </div>
                    <span style={CreateChatroomStyles.managedHelpText}>If checked, room can only be managed by managers</span>
                    <Checkbox
                        disabled={false}
                        className={managed ? 'iconBlue24' : 'iconDisabled24'}
                        iconName={managed ? faCheckCircle : faCircle}
                        label="Set as a managed room"
                        onClick={() => setManaged(!managed)}
                    />
                    <Fade collapse when={isCase}>
                        <div style={CreateChatroomStyles.textInputWrapper}>
                            <input
                                style={CreateChatroomStyles.textInputCase}
                                type="text"
                                placeholder="UNOS ID"
                                maxLength={255}
                                value={unosId}
                                readOnly={donorCreatingChat}
                                onChange={(event) => this.onSetUNOSId(event)}
                            />
                            <input
                                style={CreateChatroomStyles.textInputCase}
                                type="text"
                                placeholder={donorCreatingChat ? 'OPO Donor Id' : 'Match ID'}
                                maxLength={255}
                                value={donorCreatingChat ? opoDonorId : matchId}
                                readOnly={donorCreatingChat}
                                onChange={donorCreatingChat ? (text) => this.onSetOPODonorId(text) : (text) => this.onSetMatchId(text)}
                            />
                            {donorCreatingChat
                                ? (
                                    <input
                                        style={CreateChatroomStyles.textInputCase}
                                        type="text"
                                        value={organType}
                                        readOnly
                                    />
                                )
                                : (
                                    <select
                                        style={CreateChatroomStyles.dropdown}
                                        name="Organ Type"
                                        onChange={(value) => this.onSetOrganType(value)}
                                    >
                                        <option value="Kidney">Kidney</option>
                                        <option value="Liver">Liver</option>
                                        <option value="Pancreas">Pancreas</option>
                                    </select>
                                )}
                        </div>
                    </Fade>
                    <AddPeople
                        members={{}}
                        avatars={avatars}
                        memberId={memberId}
                        contactsOrder={contactsOrder}
                        contactsMap={contactsMap}
                        contactsAreLoaded={contactsAreLoaded}
                        singleUserAdded={singleUserAdded}
                        newUserInvited={newUserInvited}
                        addSingleUserError={addSingleUserError}
                        inviteNewUserError={inviteNewUserError}
                        peopleFilter={peopleFilter}
                        createChatroom
                        isConnected={isConnected}
                        chatInputHeight={chatInputHeight || 0}
                        onAddUsers={addUsersToChatroom}
                        onUpdateSelectedUsers={setPendingUsers}
                        onHideAddPeople={() => {}} // noop
                        clearMessage={() => {}}
                        inviteExistingUser={() => {}}
                        inviteNewUser={() => {}}
                        filterPeople={filterPeople}
                        teams={teams}
                        teamsLoading={teamsLoading}
                    />
                </div>
            </div>
        );
    }

    renderSelectOrganization() {
        const {
            canUseTemplate,
            chatInputHeight,
            editData,
            organizationFilter,
            getSurgeons,
        } = this.props;

        const {
            organizations,
        } = editData;

        const items = organizationFilter ? organizations.filter(
            sift({
                $where() {
                    return this.name.toUpperCase().includes(organizationFilter.toUpperCase())
                        || this.code.toUpperCase().includes(organizationFilter.toUpperCase());
                },
            })
        ) : organizations;

        return (
            <div
                style={
                    {
                        ...CreateChatroomStyles.whiteBackground,
                        ...{ height: `calc(90vh - ${chatInputHeight}px)`, bottom: chatInputHeight, overflow: 'scroll', },
                    }
                }
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                    {this.renderCloseButton()}
                </div>
                {canUseTemplate ? this.renderTabs() : null}
                <div style={CreateChatroomStyles.mainContainer}>
                    <div style={CreateChatroomStyles.centerContainer}>
                        <div style={CreateChatroomStyles.title}>
                            <span>Select Organization</span>
                        </div>
                        <Search
                            placeholder="Search organizations..."
                            value={organizationFilter}
                            filterContent={this.onSearchOrganizations}
                        />
                        <div style={items.length > 0 ? CreateChatroomStyles.scrollableContainer : CreateChatroomStyles.noItemsContainer}>
                            {
                                items.length > 0 ? items.map((organization: Organization) => (
                                    <OrganizationItem
                                        key={organization.id}
                                        organization={organization}
                                        selectOrganization={this.onSelectOrganization}
                                        getSurgeons={getSurgeons}
                                    />
                                )) : <div>No organizations to display</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSelectOffer() {
        const {
            canUseTemplate,
            chatInputHeight,
            editData,
            offerFilter,
            onTriggerAskAlan,
        } = this.props;

        const {
            offers,
        } = editData;

        const items = offerFilter ? offers.filter(
            sift({
                $where() {
                    return this.unosId.toUpperCase().includes(offerFilter.toUpperCase())
                        || this.organType.toUpperCase().includes(offerFilter.toUpperCase());
                },
            })
        ) : offers;

        return (
            <div
                style={
                    {
                        ...CreateChatroomStyles.whiteBackground,
                        ...{ height: `calc(90vh - ${chatInputHeight}px)`, bottom: chatInputHeight, overflow: 'scroll', },
                    }
                }
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <BackButton
                        label="Back"
                        goBack={this.goBack}
                    />
                    {this.renderCloseButton()}
                </div>
                {canUseTemplate ? this.renderTabs() : null}
                <div style={CreateChatroomStyles.mainContainer}>
                    <div style={CreateChatroomStyles.centerContainer}>
                        <div style={CreateChatroomStyles.title}>
                            <span>Select Case Snapshot</span>
                        </div>
                        <Search
                            placeholder="Search case snapshots within your organization..."
                            value={offerFilter}
                            filterContent={this.onSearchOffers}
                        />
                        <div style={items.length > 0 ? CreateChatroomStyles.scrollableContainer : CreateChatroomStyles.noItemsContainer}>
                            {
                                items.length > 0 ? items.map((offer: Offer) => (
                                    <OfferItem
                                        key={offer.organId}
                                        offer={offer}
                                        selectOffer={this.onSelectOffer}
                                    />
                                )) : <div>No offers to display</div>
                            }
                        </div>
                        <Button
                            style={CreateChatroomStyles.createOfferButton}
                            type="button"
                            ripple={false}
                            onClick={onTriggerAskAlan}
                        >
                            + Create a new templated case snapshot
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    renderSelectSurgeon() {
        const {
            canUseTemplate,
            chatInputHeight,
            editData,
        } = this.props;

        const {
            surgeons,
        } = editData;

        return (
            <div
                style={
                    {
                        ...CreateChatroomStyles.whiteBackground,
                        ...{ height: `calc(90vh - ${chatInputHeight}px)`, bottom: chatInputHeight, },
                    }
                }
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <BackButton
                        label="Back"
                        goBack={this.goBack}
                    />
                    {this.renderCloseButton()}
                </div>
                {canUseTemplate ? this.renderTabs() : null}
                <div style={CreateChatroomStyles.mainContainer}>
                    <div style={CreateChatroomStyles.centerContainer}>
                        <div style={CreateChatroomStyles.title}>
                            <span>Select Surgeon</span>
                        </div>
                        <div
                            style={
                                surgeons.length > 0
                                    ? CreateChatroomStyles.scrollableContainer : CreateChatroomStyles.noItemsContainer
                            }
                        >
                            {
                                surgeons.length > 0 ? surgeons.map((surgeon: UserProfile) => (
                                    <SurgeonItem
                                        key={surgeon.userId}
                                        surgeon={surgeon}
                                        selectSurgeon={this.onSelectSurgeon}
                                    />
                                )) : <div>No surgeons to display</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderViewSurgeon() {
        const {
            canUseTemplate,
            editData,
            chatInputHeight,
            isConnected,
            onCreateChatroom,
        } = this.props;

        const {
            surgeon,
            fullOffer,
            organization,
        } = editData;

        return (
            <div
                style={
                    {
                        ...CreateChatroomStyles.whiteBackground,
                        ...{ height: `calc(90vh - ${chatInputHeight}px)`, bottom: chatInputHeight, overflow: 'scroll', },
                    }
                }
            >
                <ReactTooltip />
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <BackButton
                        label="Back"
                        goBack={this.goBack}
                    />
                    {this.renderCloseButton()}
                </div>
                {canUseTemplate ? this.renderTabs() : null}
                <div style={CreateChatroomStyles.mainContainer}>
                    <div style={CreateChatroomStyles.centerContainer}>
                        <div style={CreateChatroomStyles.title}>
                            <span>{selectProfileName(surgeon)}</span>
                        </div>
                        <div style={CreateChatroomStyles.outerContainer}>
                            <div style={CreateChatroomStyles.leftContainer}>
                                <span style={CreateChatroomStyles.label}>Email</span>
                            </div>
                            <div style={CreateChatroomStyles.rightContainer}>
                                <span>{selectProfileEmail(surgeon)}</span>
                            </div>
                        </div>
                        <div style={CreateChatroomStyles.outerContainer}>
                            <div style={CreateChatroomStyles.leftContainer}>
                                <span style={CreateChatroomStyles.label}>Phone</span>
                            </div>
                            <div style={CreateChatroomStyles.rightContainer}>
                                <span>{selectProfilePhone(surgeon)}</span>
                            </div>
                        </div>
                        <div style={CreateChatroomStyles.outerContainer}>
                            <div style={CreateChatroomStyles.leftContainer}>
                                <span style={CreateChatroomStyles.label}>Communication Preferences</span>
                            </div>
                            <div style={CreateChatroomStyles.rightContainer}>
                                <span>{selectProfileCommunicationPref(surgeon)}</span>
                            </div>
                        </div>
                        <div style={CreateChatroomStyles.offerView}>
                            <OfferData
                                offerContent={fullOffer}
                                user={organization}
                                isMine={false}
                                width={null}
                            />
                        </div>
                        <div style={CreateChatroomStyles.center}>
                            <Button
                                style={isConnected ? CreateChatroomStyles.createButton : CreateChatroomStyles.createButtonDisabled}
                                type="button"
                                ripple={false}
                                disabled={!isConnected}
                                onClick={onCreateChatroom}
                                data-tip="Create a room with this offer"
                                data-delay-show="250"
                                data-effect="solid"
                                data-place="right"
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            canUseTemplate,
            editData,
            selectedTab,
        } = this.props;

        const {
            selectingOffer,
            selectingTransplantCenter,
            selectingSurgeon,
            viewSurgeon,
        } = this.state;

        const {
            chatroomType,
        } = editData;

        if (selectedTab === CASE_TAB) {
            return this.renderCreateCaseOnlyChatroom();
        }

        if (chatroomType === 'Templated Case' && canUseTemplate) {
            if (selectingOffer) {
                return this.renderSelectOffer();
            }
            if (selectingTransplantCenter) {
                return this.renderSelectOrganization();
            }
            if (selectingSurgeon) {
                return this.renderSelectSurgeon();
            }
            if (viewSurgeon) {
                return this.renderViewSurgeon();
            }
        }

        return this.renderCreateChatroom();
    }
}

const mapStateToProps = (state) => {
    const permissions = state.permission.permissions || [];

    const canUseTemplate = hasPermissions(permissions, ENTITY_TYPE_UI, TEMPLATED_CASE_FEATURE, NONE, NONE, READ);

    return {
        offerFilter: state.chatList.offerFilter,
        organizationFilter: state.chatList.organizationFilter,
        verifiedOrgMember: state.auth.profile.verifiedOrgMember || false,
        isConnected: state.chatList.socketStatus === 'connected',
        offerCreated: state.chatEdit.offerCreated,
        donorCreatingChat: state.chatEdit.donorCreatingChat,
        selectedTab: state.application.selectedTab,
        teams: state.team.teams,
        teamsLoading: state.team.teamsLoading,
        canUseTemplate,
        currentDonorId: state.donor.openDonorId,
    };
};

export default connect(mapStateToProps, {
    getContacts: _getContacts,
    resetContactsStatuses: _resetContactsStatuses,
    resetFilters: _resetFilters,
    resetChatEditState: _resetChatEditState,
    setManaged: _setManaged,
    setUNOSId: _setUNOSId,
    setMatchId: _setMatchId,
    setOrganType: _setOrganType,
    getOffers: _getOffers,
    getOrganizations: _getOrganizations,
    getSurgeons: _getSurgeons,
    filterOffers: _filterOffers,
    filterOrganizations: _filterOrganizations,
    selectOffer: _selectOffer,
    selectOrganization: _selectOrganization,
    selectSurgeon: _selectSurgeon,
    getOffer: _getOffer,
    setChatroomType: _setChatroomType,
    logFirebaseEvent: _logFirebaseEvent,
    setDonorCreateChat: _setDonorCreateChat,
    setOPODonorId: _setOPODonorId,
    setResearchOffer: _setResearchOffer,
    setDonorId: _setDonorId,
    setRestrictions: _setRestrictions,
    setPendingUsers: _setPendingUsers,
})(CreateChatroom);
