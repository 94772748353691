// @flow
import Colors from '../../Themes/Colors';
import Fonts from '../../Themes/Fonts';

export const chatProfileImageSize = 50;

export const detailsProfileImageSize = 32;

export const senderNameFontSize = 12;
export const senderNameFontWeight = '600';
export const senderNamePaddingBottom = 7;

export const messageFontSize = 14;
export const messagePadding = 10;

export const messageMediaPaddingBottom = 8;

export const applicationMediaWidth = 240;
export const applicationMediaHeight = 300;
export const mediaThumbnailHeight = 432;

export const replyAlertVerticalPadding = 4;
export const replyAlertHeight = 20;
export const replyAlertMarginTop = 15;

const ChatMessageStyles = {
    chatItemWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    replyMessageWrapper: {
        backgroundColor: Colors.chatItemBorder,
        borderRadius: 8,
        padding: messagePadding,
    },
    replyMargin: { marginTop: 5, },
    senderInfoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10,
        cursor: 'pointer',
    },
    replySenderName: {
        color: Colors.black,
        fontSize: senderNameFontSize,
        fontWeight: senderNameFontWeight,
        paddingBottom: senderNamePaddingBottom,
    },
    senderName: {
        color: Colors.black,
        fontSize: senderNameFontSize,
        fontWeight: senderNameFontWeight,
        paddingBottom: senderNamePaddingBottom,
        paddingRight: 10,
    },
    sentTime: {
        color: Colors.black,
        fontSize: 12,
    },
    seenByText: {
        fontSize: 12,
        color: Colors.formLabel,
    },
    sendError: {
        color: Colors.red,
        fontSize: senderNameFontSize,
        fontWeight: senderNameFontWeight,
        paddingLeft: 10,
    },
    avatarClickWrapper: {
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
    },
    profileImageWrapper: {
        marginRight: 35,
        zIndex: -1,
        height: chatProfileImageSize,
        width: chatProfileImageSize,
        padding: '3px',
        borderRadius: chatProfileImageSize / 1.5,
        background: 'linear-gradient(to right, #2E3192, #00A79D)',
    },
    profileImageWrapperNoBorder: {
        marginRight: 25,
        zIndex: -1,
        height: chatProfileImageSize,
        width: chatProfileImageSize,
        padding: '3px',
    },
    profileImage: {
        backgroundColor: Colors.white,
        borderRadius: chatProfileImageSize / 1.5,
        height: chatProfileImageSize,
        width: chatProfileImageSize,
    },
    failedMediaWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 200,
        height: 200,
        backgroundColor: Colors.chatItemBackground,
        borderColor: Colors.chatItemBorder,
        borderRadius: 8,
        borderWidth: 1,
        marginBottom: messageMediaPaddingBottom,
        flex: 1,
    },
    loadingMediaWrapper: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 200,
        height: 200,
        backgroundColor: Colors.chatItemBackground,
        borderColor: Colors.chatItemBorder,
        borderRadius: 8,
        borderWidth: 1,
        marginBottom: messageMediaPaddingBottom,
        flex: 1,
    },
    chatMessageWrapperReply: {
        display: 'inline-block',
        padding: messagePadding,
        color: Colors.black,
        fontSize: messageFontSize,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
    chatMessageWrapperSender: {
        marginTop: -25,
        marginBottom: 10,
        marginLeft: 80,
    },
    chatMessageWrapperText: {
        display: 'inline-block',
        padding: messagePadding,
        backgroundColor: Colors.chatItemBackground,
        borderColor: Colors.chatItemBorder,
        borderRadius: 8,
        borderWidth: 2,
        borderStyle: 'solid',
        color: Colors.black,
        fontSize: messageFontSize,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
    chatMessageWrapperNoSender: {
        marginBottom: 10,
        marginLeft: 80,
    },
    chatMessageWrapperTextSelf: {
        display: 'inline-block',
        color: Colors.white,
        fontSize: messageFontSize,
        backgroundColor: Colors.chatItemBackgroundSelf,
        borderColor: Colors.chatItemBorderSelf,
        borderStyle: 'solid',
        borderRadius: 8,
        borderWidth: 2,
        padding: messagePadding,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
    chatMessageMedia: {
        maxHeight: mediaThumbnailHeight,
        marginBottom: messageMediaPaddingBottom,
        borderWidth: 1,
        borderRadius: 8,
        outline: 'none',
    },
    chatMessageApplicationMedia: {
        height: applicationMediaHeight,
        width: applicationMediaWidth,
    },
    chatMessageApplicationClick: {
        height: applicationMediaHeight,
        width: applicationMediaWidth,
        position: 'absolute',
    },
    chatMessageHiddenMedia: {
        marginBottom: messageMediaPaddingBottom,
        borderWidth: 1,
        borderRadius: 8,
        display: 'none',
    },
    chatHistoryWrapper: {
        backgroundColor: Colors.chatAreaBackground,
        flexDirection: 'column',
        paddingTop: '65px',
        alignSelf: 'center',
        position: 'fixed',
        overflowY: 'scroll',
    },
    chatHistoryPadding: {
        paddingLeft: 20,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 65,
    },
    uploadIcon: {
        width: '100px',
        height: '100px',
    },
    fileDropWrapper: {
        position: 'absolute',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 65,
        zIndex: 1,
    },
    fileDropForm: {
        fontFamily: Fonts.style.base,
        fontSize: 18,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fileDropFormButton: {
        backgroundColor: Colors.buttonBackground,
        color: Colors.white,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
        marginBottom: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 4,
        cursor: 'pointer',
    },
    fileEscapeMessage: {
        fontSize: 14,
        color: Colors.gray,
        marginTop: 20,
    },
    loadMoreMessagesButton: {
        color: Colors.linkText,
        backgroundColor: Colors.chatAreaBackground,
        textTransform: 'none',
        textAlign: 'center',
        fontFamily: Fonts.style.base,
        fontSize: Fonts.size.regular,
    },
    modalContentWrapper: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        overflow: 'scroll',
    },
    modalDialogContentWrapper: {
        maxHeight: '30vh',
        padding: 15,
        overflow: 'auto',
    },
    modalCloseButton: {
        background: 'none',
        border: 'none',
        float: 'right',
        marginBottom: 10,
        cursor: 'pointer',
    },
    tabs: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: Colors.blue,
    },
    selectedTab: {
        backgroundColor: Colors.white,
        textTransform: 'none',
        color: Colors.blue,
        margin: 4,
        width: '50%',
        borderRadius: 4,
    },
    unselectedTab: {
        backgroundColor: Colors.blue,
        textTransform: 'none',
        color: Colors.white,
        margin: 4,
        width: '50%',
        borderRadius: 4,
    },
    detailsProfileImage: {
        backgroundColor: Colors.white,
        height: detailsProfileImageSize,
        width: detailsProfileImageSize,
        borderRadius: detailsProfileImageSize / 1.5,
        marginRight: 10,
    },
    detailsProfileImageWrapper: {
        paddingBottom: 8,
    },
    chatMemberWrapper: {
        flexDirection: 'row',
        paddingBottom: 8,
    },
    chatMemberName: {
        color: Colors.black,
        fontSize: 14,
        textAlign: 'left',
    },
    chatMemberOrganization: {
        color: Colors.gray,
        fontSize: 12,
        marginTop: 4,
    },
    chatSeenTimestampWrapper: {
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        marginLeft: 'auto',
        paddingBottom: 8,
    },
    chatSeenTimestampText: {
        color: Colors.gray,
        fontSize: 12,
        textAlign: 'right',
    },
    defaultMessage: {
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center',
    },
    loadingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    systemMessage: {
        fontSize: 14,
        color: Colors.gray,
    },
    systemMessageSentTime: {
        fontSize: 12,
        color: Colors.gray,
    },
    systemMessageWrapper: {
        marginBottom: 10,
        width: '100%',
        paddingLeft: 80,
        position: 'relative',
    },
    donorTaskExpirationMessageRow: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        display: 'flex',
    },
    donorTaskExpirationMessageWrapper: {
        marginBottom: 10,
        width: '100%',
        position: 'relative',
    },
    donorTaskExpiraitonList: {
        margin: '0.5em 1em 0.5em 1em',
        paddingLeft: 0,
    },
    offerTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 10,
    },
    offerTitleText: {
        fontSize: 20,
    },
    offerIdHeader: {
        whiteSpace: 'nowrap',
    },
    offerDisclaimer: {
        fontSize: 12,
        fontStyle: 'italic',
    },
    offerSubtitle: {
        display: 'flex',
        paddingTop: 14,
        width: '100%',
        textAlign: 'center',
        fontStyle: 'italic',
    },
    offerSubtitleLineSelf: {
        flexGrow: 1,
        borderBottom: '1px solid rgb(255, 255, 255)',
        lineHeight: '0.1em',
        marginBottom: 15,
        display: 'inline-block',
    },
    offerSubtitleLine: {
        flexGrow: 1,
        borderBottom: '1px solid rgb(0, 0, 0)',
        lineHeight: '0.1em',
        marginBottom: 15,
        display: 'inline-block',
    },
    offerSubtitleText: {
        lineHeight: 2,
        fontSize: 16,
        display: 'inline-block',
        marginRight: 10,
        marginLeft: 10,
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 10,
    },
    offerInfo: {
        lineHeight: 2,
    },
    clear: {
        clear: 'both',
    },
    askAlanButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    askAlanButton: {
        borderRadius: 8,
        backgroundColor: Colors.chatListItemBorder,
        color: Colors.blue,
        marginTop: 15,
        width: '100%',
        textTransform: 'none',
        fontSize: 20,
    },
    textCenter: {
        textAlign: 'center',
    },
    relativePosition: {
        position: 'relative',
    },
    openMessageMenuButton: {
        display: 'inline',
        position: 'absolute',
        bottom: '5%',
        marginLeft: 5,
        justifyContent: 'center',
    },
    addAckButton: {
        backgroundColor: Colors.white,
        borderRadius: 10,
        border: '2px solid',
        borderColor: Colors.chatListItemBorder,
        outline: 'none',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
    resendNotificationButton: {
        outline: 'none',
        backgroundColor: Colors.white,
        border: 'none',
        cursor: 'pointer',
    },
    messageMenuContainer: {
        display: 'inline',
        position: 'absolute',
        bottom: '5%',
        backgroundColor: Colors.white,
        border: '2px solid',
        borderColor: Colors.chatListItemBorder,
        borderRadius: 12,
        padding: 7,
        marginLeft: 5,
    },
    messageMenuOptionContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
    },
    messageMenuOptionContainerDisabled: {
        cursor: 'default',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
    },
    messageMenuOptionText: {
        fontSize: 16,
        color: Colors.blue,
    },
    messageMenuOptionTextDisabled: {
        fontSize: 16,
        color: Colors.linkTextDisabled,
    },
    emojiContainer: {
        margin: 4,
    },
    emojiButton: {
        outline: 'none',
        textAlign: 'center',
        border: 0,
        fontSize: 16,
        cursor: 'pointer',
    },
    emojiButtonDisabled: {
        outline: 'none',
        textAlign: 'center',
        border: 0,
        fontSize: 16,
        cursor: 'default',
    },
    emoji: {
        fontSize: 12,
        cursor: 'default',
    },
    divider: {
        marginLeft: 5,
        color: Colors.chatListItemBorder,
    },
    seenByAck: {
        fontSize: 12,
        marginLeft: 10,
    },
    underMsgAck: {
        marginLeft: 10,
    },
    selectChatroom: {
        position: 'absolute',
        bottom: '75%',
        left: '35%',
    },
    whatsNewCol: {
        position: 'absolute',
        bottom: 100,
        left: '25%',
    },
    whatsNewHeader: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    grayText: {
        color: Colors.gray,
    },
    linkPreviewWrap: {
        marginTop: 5,
    },
    redactedText: {
        textAlign: 'center',
        fontSize: 12,
        color: Colors.formLabel,
        paddingBottom: 10,
    },
    replyAlertContainer: {
        marginTop: replyAlertMarginTop,
        marginLeft: 15,
        backgroundColor: Colors.transparentBlue,
        borderRadius: 8,
        paddingTop: replyAlertVerticalPadding,
        paddingBottom: replyAlertVerticalPadding,
        paddingLeft: 8,
        paddingRight: 8,
        width: 'fit-content',
        height: replyAlertHeight,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    replyAlertText: {
        fontSize: 12,
        textAlign: 'left',
    },
    chatWrapper: {
        display: 'inline-block',
        color: Colors.black,
        fontSize: messageFontSize,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
    chatDonorStatusUpdateText: {
        color: Colors.gray,
        fontSize: 14,
    },
    chatDonorStatusActionLine: {
        fontSize: '0.75rem',
        marginTop: '0.5em',
        color: Colors.lightGray,
    },
    chatDonorStatusUpdateFieldText: {
        color: Colors.darkGray,
        fontSize: 14,
        paddingLeft: '3px',
    },
    chatDonorStatusUpdateHeaderText: {
        color: Colors.black,
        fontSize: 15,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '0.5em',
    },
};

export default ChatMessageStyles;
