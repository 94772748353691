// @flow
import { Button, Menu, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import Colors from '../Themes/Colors';
import styles from './Styles/DonorStyles';
import {
    updateTaskStatus as _updateTaskStatus,
    donorChanged as _donorChanged,
    saveTaskNote as _saveTaskNote,
} from '../Redux/DonorActions';
import { setSagaMessage as _setSagaMessage } from '../Redux/ApplicationActions';
import type {
    FormValueMap,
    FormDefData,
    DonorTask,
    UserPermission,
} from '../Utils/types';
import { TaskStatusButton } from './TaskItem';
import { getDonorFormAttributes } from '../Utils/TaskDetailsUtils';
import hasPermissions, {
    ENTITY_TYPE_DONOR,
    SUB_ENTITY_TYPE_TASK,
    NONE,
    UPDATE,
} from '../Utils/hasPermissions';

type Props = {
    closed: boolean,
    donorTask: DonorTask,
    description: string,
    formDef: FormDefData,
    formData: FormValueMap,
    editNoteContent: string,
    formId: string,
    formUpdated: boolean,
    isConnected: boolean,
    permissions: Array<UserPermission>,
    onSaveClick: (formId: string, notify: boolean, notifyFullTask: boolean) => *,

    updateTaskStatus: (
        donorId: number,
        taskId: number,
        lastModified: string,
        fieldValueMap: ?FormValueMap,
        completed: ?boolean,
        notApplicable: ?boolean,
        fromList: boolean,
    ) => *,
    setSagaMessage: (heading: string, message: string, label: string, isDialog?: boolean) => *,
    saveTaskNote: (text: string) => *,
    donorChanged: (donorUpdated: boolean) => *,
};

type State = {
    anchorEl: null | HTMLElement,
};

class TaskDetailsHeader extends PureComponent<Props, State> {
    constructor() {
        super();
        this.state = {
            anchorEl: null,
        };
    }

    componentDidMount() {
        this.updateEventListener();
    }

    componentDidUpdate(prevProps: Props) {
        const {
            formUpdated,
        } = this.props;

        if (prevProps.formUpdated !== formUpdated) {
            this.updateEventListener();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.promptOnUnload);
    }

    onMarkNA = () => {
        const {
            donorTask,
            updateTaskStatus,
            formDef,
            setSagaMessage,
            saveTaskNote,
            editNoteContent,
            donorChanged,
        } = this.props;

        if (donorTask.completed) {
            setSagaMessage('', 'To update a task as N/A, first mark the task as incomplete', '');
            return;
        }
        let updatedForm = null;
        if (formDef.behaviors && formDef.behaviors.clearOnNotApplicable && !donorTask.notApplicable) {
            updatedForm = {};
        }

        if (!donorTask.notApplicable && donorTask.noteContent !== editNoteContent) {
            // Reset note value in case the user has typed in the note field and then marked
            // the task as N/A without pressing Save first
            saveTaskNote(donorTask.noteContent);
            donorChanged(false);
        }
        updateTaskStatus(donorTask.donorId, donorTask.taskId, donorTask.lastModified, updatedForm, null, !donorTask.notApplicable, false);
    };

    onMarkCommplete = () => {
        const {
            donorTask,
            updateTaskStatus,
            formDef,
            formData,
            setSagaMessage,
        } = this.props;

        if (donorTask.notApplicable) {
            setSagaMessage('', 'To update a task as complete, first mark the task as applicable', '');
            return;
        }
        let updatedForm = null;
        if (formDef.behaviors && formDef.behaviors.clearOnIncomplete && donorTask.completed) {
            updatedForm = {};
        }
        if (formDef.behaviors && formDef.behaviors.saveOnComplete && !donorTask.completed) {
            updatedForm = formData;
        }
        updateTaskStatus(donorTask.donorId, donorTask.taskId, donorTask.lastModified, updatedForm, !donorTask.completed, null, false);
    };

    handleMenuClick = (target: HTMLButtonElement) => {
        this.setState({
            anchorEl: target,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleNotifyMenuClick = (fullTask: boolean) => {
        const {
            formId,
            onSaveClick,
        } = this.props;

        this.handleClose();

        onSaveClick(formId, true, fullTask);
    }

    promptOnUnload = (event) => {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
    };

    updateEventListener = () => {
        const { formUpdated, } = this.props;

        if (formUpdated) {
            window.addEventListener('beforeunload', this.promptOnUnload);
        } else {
            window.removeEventListener('beforeunload', this.promptOnUnload);
        }
    }

    render() {
        const {
            donorTask,
            formDef,
            closed,
            description,
            formId,
            formUpdated,
            isConnected,
            permissions,
            onSaveClick,
        } = this.props;

        const { anchorEl, } = this.state;
        const open = Boolean(anchorEl);
        const updateDonorAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorTask.donorId, NONE, NONE, UPDATE);
        const updateTaskAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorTask.donorId, SUB_ENTITY_TYPE_TASK, donorTask.taskId, UPDATE);
        const updateAllowed = updateDonorAllowed || updateTaskAllowed;

        return (
            <div>
                {(updateAllowed)
                    ? (
                        isConnected ? (
                            <div style={styles.headerButtonsWrapper}>
                                <div style={styles.buttonsWrapper}>
                                    <Button
                                        style={formUpdated ? styles.notifyButton : styles.notifyButtonDisabled}
                                        disabled={!formUpdated}
                                        onClick={() => onSaveClick(formId, false, false)}
                                        startIcon={<SaveIcon />}
                                    >
                                        Save
                                    </Button>
                                </div>
                                <div style={styles.buttonsWrapper}>
                                    <Button
                                        id="notify-button"
                                        style={styles.notifyButton}
                                        aria-controls={open ? 'notify-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={(event) => this.handleMenuClick(event.currentTarget)}
                                        startIcon={<SendIcon />}
                                    >
                                        Notify
                                    </Button>
                                    <Menu
                                        id="notify-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={this.handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'notify-button',
                                        }}
                                    >
                                        <MenuItem onClick={() => this.handleNotifyMenuClick(false)}>
                                            Latest Change
                                        </MenuItem>
                                        <MenuItem onClick={() => this.handleNotifyMenuClick(true)}>
                                            All Content
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        ) : (
                            <p style={{ textAlign: 'center', }}>
                                <FontAwesomeIcon
                                    color={Colors.pendingYellow}
                                    icon={faExclamationTriangle}
                                    size="lg"
                                />
                                &nbsp; Editing temporarily disabled while offline.
                            </p>
                        )
                    ) : null}
                {(formDef.behaviors && !formDef.behaviors.alwaysApplicable && isConnected)
                    ? (
                        <div style={styles.buttonsWrapper}>
                            <Button
                                style={styles.saveButton}
                                component="button"
                                disabled={closed}
                                disableRipple
                                onClick={this.onMarkNA}
                            >
                                {donorTask.notApplicable ? 'Mark as applicable' : 'Mark as not applicable'}
                            </Button>
                        </div>
                    ) : null}
                <div style={styles.detailsTitleWrapper}>
                    <TaskStatusButton
                        disabled={closed || !updateAllowed || !isConnected}
                        completed={donorTask.completed}
                        clickable={false}
                        notApplicable={donorTask.notApplicable}
                        taskId={donorTask.taskId}
                        description={description}
                        onTaskPress={() => {}}
                        onStatusClick={this.onMarkCommplete}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        formId, donor, donorTask, staticTask,
    } = getDonorFormAttributes(state);

    return {
        editNoteContent: state.donor.editNoteContent,
        closed: donor.closed || false,
        description: staticTask && staticTask.details ? staticTask.details.description : '',
        donorTask,
        formUpdated: state.forms.formUpdated,
        formId,
        permissions: state.permission.permissions,
    };
};
export default connect(mapStateToProps, {
    updateTaskStatus: _updateTaskStatus,
    saveTaskNote: _saveTaskNote,
    setSagaMessage: _setSagaMessage,
    donorChanged: _donorChanged,

})(TaskDetailsHeader);
