// @flow
import Fonts from './Fonts';
import Colors from './Colors';

// This file is for a reusable grouping of Theme items.
// Similar to an XML fragment layout in Android
const ApplicationStyles = {
    horizontalCenter: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    textInputError: {
        color: Colors.formError,
    },
    button: {
        backgroundColor: Colors.buttonBackground,
        color: Colors.white,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    toastContainer: {
        display: 'flex',
        alignItems: 'center',
        borderLeft: '4px solid transparent',
        borderLeftWidth: 4,
        borderLeftColor: Colors.toastBlue,
        paddingLeft: 12,
        borderRadius: 3,
    },
    toastText: {
        fontWeight: '600',
        color: '#4f4f4f',
    },
    margin20: {
        marginTop: 20,
    },
    buttonInverse: {
        backgroundColor: Colors.white,
        color: Colors.buttonBackground,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    buttonInverseRed: {
        backgroundColor: Colors.white,
        color: Colors.red,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    buttonInverseDisabled: {
        backgroundColor: Colors.white,
        color: Colors.buttonTextDisabled,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    buttonDisabled: {
        backgroundColor: Colors.buttonBackgroundDisabled,
        color: Colors.buttonTextDisabled,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    buttonLink: {
        textTransform: 'none',
        color: Colors.blue,
        backgroundColor: Colors.white,
        padding: 0,
    },
    dot: {
        alignSelf: 'center',
        backgroundColor: Colors.lightGray,
        borderRadius: 2,
        height: 4,
        marginHorizontal: 4,
        transform: [
            {
                translateY: 1,
            }
        ],
        width: 4,
        display: 'inline-block',
    },
    loginLabel: {
        ...Fonts.style.h1,
        color: Colors.black,
        textAlign: 'center',
    },
    menuItemPadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
};

export default ApplicationStyles;
