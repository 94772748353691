import firebase from 'firebase/app';
import 'firebase/messaging';
import * as Sentry from '@sentry/browser';

import { storeDeviceToken } from '../Redux/ApplicationActions';

export const initializeFirebase = () => {
    firebase.initializeApp({
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    });
};

export const areNotificationsEnabled = () => Notification.permission === 'granted';

export const presentPermissionPrompt = () => {
    if (!firebase.messaging.isSupported()) {
        return false;
    }

    // If user has accepted or denied push notifications, no need to present permission prompt
    return Notification.permission !== 'granted' && Notification.permission !== 'denied';
};

export const requestNotificationPermission = async () => {
    if (!firebase.messaging.isSupported()) {
        return null;
    }
    try {
        await firebase.messaging().requestPermission();
        firebase.messaging().onTokenRefresh(() => {
            try {
                const token = firebase.messaging().getToken();
                storeDeviceToken(token);
            } catch (error) {
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.warn('Failed to refresh fcm token', error);
                } else {
                    Sentry.captureException(`Failed to refresh fcm token: ${JSON.stringify(error)}`);
                }
            }
        });
        return await firebase.messaging().getToken();
        // FIXME if no update, try again with new uuid
    } catch (error) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Failed to request notification permission', error);
        } else {
            Sentry.captureException(`Failed to request notification permission: ${JSON.stringify(error)}`);
        }
        return null;
    }
};

export const getDeviceToken = async () => {
    if (!firebase.messaging.isSupported()) {
        return null;
    }
    try {
        firebase.messaging().onTokenRefresh(() => {
            try {
                const token = firebase.messaging().getToken();
                storeDeviceToken(token);
            } catch (error) {
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.warn('Failed to refresh fcm token', error);
                } else {
                    Sentry.captureException(`Failed to refresh fcm token: ${JSON.stringify(error)}`);
                }
            }
        });
        if (Notification.permission === 'granted') {
            return await firebase.messaging().getToken();
        }
        return null;
    } catch (error) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Failed to get device token', error);
        } else {
            Sentry.captureException(`Failed to get device token: ${JSON.stringify(error)}`);
        }
        return null;
    }
};
