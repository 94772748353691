// @flow
import type { Logout } from 'txp-core';
import type { ResetData } from './ApplicationActions';
import type { UserProfile, Team } from '../Utils/types';

export type TeamErrors = {
    teamName?: string,
    nonFieldErrors?: string,
};

export type TeamState = {
    teams: Array<Team>,
    teamFilter: string,
    openTeamId: ?number,

    teamName: string,
    teamMembers: Array<UserProfile>,
    shared: boolean,

    teamStateHasChanged: boolean,
    teamCreateInProgress: boolean,

    errors: ?TeamErrors,
};

const initialState: TeamState = {
    teams: [],
    teamFilter: '',
    openTeamId: null,

    teamName: '',
    teamMembers: [],
    shared: false,

    teamStateHasChanged: false,
    teamCreateInProgress: false,

    errors: null,
};

export type GetTeams = { type: 'Team/GET_TEAMS' };
export type ReceiveTeams = { type: 'Team/RECEIVE_TEAMS', teams: Array<Team> };
export type OpenTeam = { type: 'Team/OPEN', openTeamId: number };
export type CloseTeam = { type: 'Team/CLOSE', teamId: number };

export type FilterTeams = { type: 'Team/FILTER', teamFilter: string };

export type SetTeamName = { type: 'Team/SET_TEAM_NAME', teamName: string };
export type SetTeamMembers = { type: 'Team/SET_TEAM_MEMBERS', teamMembers: Array<UserProfile> };
export type SetShared = { type: 'Team/SET_SHARED', shared: boolean };
export type OpenTeamCreate = { type: 'Team/OPEN_CREATE' };
export type CloseTeamCreate = { type: 'Team/CLOSE_CREATE' };
export type CreateTeam = { type: 'Team/CREATE_TEAM', teamName: string, teamMembers: Array<UserProfile>, shared: boolean };
export type CreateTeamFailed = { type: 'Team/CREATE_TEAM_FAILED', errors: ?TeamErrors };
export type TeamChanged = { type: 'Team/TEAM_CHANGED', teamStateHasChanged: boolean };

export type UpdateTeam = { type: 'Team/UPDATE', teamId: number, teamName: string, teamMembers: Array<UserProfile>, shared: boolean };
export type DeleteTeam = { type: 'Team/DELETE_TEAM', teamId: number };

export type ResetTeamEditState = { type: 'Team/RESET' };

type Action =
    | GetTeams
    | ReceiveTeams
    | OpenTeam
    | CloseTeam
    | FilterTeams
    | SetTeamName
    | SetTeamMembers
    | SetShared
    | OpenTeamCreate
    | CloseTeamCreate
    | CreateTeam
    | CreateTeamFailed
    | TeamChanged
    | UpdateTeam
    | DeleteTeam
    | ResetTeamEditState
    | ResetData
    | Logout;

export const getTeams = (): GetTeams => ({
    type: 'Team/GET_TEAMS',
});

export const receiveTeams = (teams: Array<Team>): ReceiveTeams => ({
    type: 'Team/RECEIVE_TEAMS',
    teams,
});

export const openTeam = (openTeamId: number): OpenTeam => ({
    type: 'Team/OPEN',
    openTeamId,
});

export const closeTeam = (teamId: number): CloseTeam => ({
    type: 'Team/CLOSE',
    teamId,
});

export const openTeamCreate = (): OpenTeamCreate => ({
    type: 'Team/OPEN_CREATE',
});

export const closeTeamCreate = (): CloseTeamCreate => ({
    type: 'Team/CLOSE_CREATE',
});

export const filterTeams = (teamFilter: string): FilterTeams => ({
    type: 'Team/FILTER',
    teamFilter,
});

export const setTeamName = (teamName: string): SetTeamName => ({
    type: 'Team/SET_TEAM_NAME',
    teamName,
});

export const setTeamMembers = (teamMembers: Array<UserProfile>): SetTeamMembers => ({
    type: 'Team/SET_TEAM_MEMBERS',
    teamMembers,
});

export const setShared = (shared: boolean): SetShared => ({
    type: 'Team/SET_SHARED',
    shared,
});

export const createTeam = (teamName: string, teamMembers: Array<UserProfile>, shared: boolean): CreateTeam => ({
    type: 'Team/CREATE_TEAM',
    teamName,
    teamMembers,
    shared,
});

export const createTeamFailed = (errors: ?TeamErrors): CreateTeamFailed => ({
    type: 'Team/CREATE_TEAM_FAILED',
    errors,
});

export const teamChanged = (teamStateHasChanged: boolean): TeamChanged => ({
    type: 'Team/TEAM_CHANGED',
    teamStateHasChanged,
});

export const updateTeam = (teamId: number, teamName: string, teamMembers: Array<UserProfile>, shared: boolean): UpdateTeam => ({
    type: 'Team/UPDATE',
    teamId,
    teamName,
    teamMembers,
    shared,
});

export const deleteTeam = (teamId: number): DeleteTeam => ({
    type: 'Team/DELETE_TEAM',
    teamId,
});

export const resetTeamEditState = (): ResetTeamEditState => ({
    type: 'Team/RESET',
});

const reducer = (state: TeamState = initialState, action: Action): TeamState => {
    switch (action.type) {
        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            // Reset the store when logging out
            return {
                ...initialState,
            };

        case 'Team/RECEIVE_TEAMS':
            return {
                ...state,

                teams: action.teams.slice(),
            };

        case 'Team/OPEN':
            return {
                ...state,

                openTeamId: action.openTeamId,
            };

        case 'Team/CLOSE':
            return {
                ...state,

                openTeamId: action.teamId === state.openTeamId ? null : state.openTeamId,
            };

        case 'Team/OPEN_CREATE':
            return {
                ...state,

                teamCreateInProgress: true,
            };

        case 'Team/CLOSE_CREATE':
            return {
                ...state,

                teamCreateInProgress: false,
            };

        case 'Team/FILTER':
            return {
                ...state,

                teamFilter: action.teamFilter,
            };

        case 'Team/SET_TEAM_NAME':
            return {
                ...state,

                teamName: action.teamName,
            };

        case 'Team/SET_TEAM_MEMBERS':
            return {
                ...state,

                teamMembers: action.teamMembers.slice(),
            };

        case 'Team/SET_SHARED':
            return {
                ...state,

                shared: action.shared,
            };

        case 'Team/CREATE_TEAM_FAILED':
            return {
                ...state,

                errors: action.errors,
            };

        case 'Team/TEAM_CHANGED':
            return {
                ...state,

                teamStateHasChanged: action.teamStateHasChanged,
            };

        case 'Team/RESET':
            return {
                ...state,

                teamName: initialState.teamName,
                teamMembers: initialState.teamMembers,
                shared: initialState.shared,
                errors: null,
            };

        default:
            return state;
    }
};

export default reducer;
