// @flow
import { buffers } from 'redux-saga';
import type { Saga } from 'redux-saga';
import {
    actionChannel, cancel, fork, take,
} from 'redux-saga/effects';

import initProtocol from './Protocol';
import {
    getCommandChannel, getResultChannel, setCommandChannel, setResultChannel,
} from './Channels';

// Using globals for these so we support hot reloading in development
let socketTask;

/**
 *  This task waits for `ChatList/SOCKET` action and handles connection to the chat socket.
 *  If the socket task is already running (but the socketStatus is not error), the task will ignore the action.
 */
export default function* socketManager(): Saga<void> {
    // Create buffer channel for commands
    if (!getCommandChannel()) {
        const commandChannel = yield actionChannel('SOCKET/COMMAND', buffers.expanding());

        setCommandChannel(commandChannel);
    }

    // Create buffer channel for command response
    if (!getResultChannel()) {
        const resultChannel = yield actionChannel('SOCKET/RESPONSE', buffers.expanding());

        setResultChannel(resultChannel);
    }

    // Simplified to always stop (if running) and restart. In conjunction with deep socket reset
    // this makes this a simple reset action
    // This should not be invoked if resetting the socket is not required.
    while (true) {
        yield take('ChatList/SOCKET');
        // $FlowFixMe
        if (socketTask && socketTask.isRunning && socketTask.isRunning()) {
            // Task is already running, cancel it. This is now a deep reset
            yield cancel(socketTask);
        }
        socketTask = yield fork(initProtocol);
    }
}
