// @flow
import Colors from '../../Themes/Colors';

const ChatInputStyles = {
    uploadedFilesWrapper: {
        position: 'relative',
        marginLeft: 20,
        marginTop: 20,
        display: 'inline-flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    uploadedFile: {
        maxHeight: 80,
        height: 80,
        maxWidth: 160,
        width: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        display: 'inline-block',
        borderRadius: 4,
    },
    uploadedApplicationWrapper: {
        height: 80,
        width: 100,
        padding: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
        borderRadius: 4,
        border: `1px solid ${Colors.lightGray}`,
        backgroundColor: Colors.lighterGray,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    uploadedApplicationMedia: {
        height: '100%',
        width: '100%',
        border: 'none',
    },
    deleteFileButton: {
        borderSize: '2px',
        borderColor: Colors.chatListItemBorder,
        borderStyle: 'style',
        backgroundColor: Colors.white,
        textAlign: 'center',
        width: 30,
        height: 30,
        borderRadius: 30,
        position: 'absolute',
        top: 5,
        left: 5,
        cursor: 'pointer',
    },
    deleteFileImg: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 15,
        height: 15,
    },
    chatInputButton: {
        color: Colors.white,
        textTransform: 'none',
        backgroundColor: Colors.buttonBackground,
    },
    chatInputButtonDisabled: {
        color: Colors.buttonTextDisabled,
        textTransform: 'none',
        backgroundColor: Colors.buttonBackgroundDisabled,
    },

    emojiPicker: {
        display: 'inline-block',
    },
    chatInputFileButton: {
        cursor: 'pointer',
        backgroundColor: Colors.white,
        border: 0,
        paddingTop: 5,
        bottom: 0,
        outline: 'none',
    },
    chatInputFileButtonDisabled: {
        backgroundColor: Colors.white,
        border: 0,
        paddingTop: 5,
        bottom: 0,
    },
    fontIconColor: {
        color: Colors.blue,
    },
    fontIconColorDisabled: {
        color: Colors.linkTextDisabled,
    },
    isTypingContainer: {
        marginLeft: 15,
        height: 15,
        marginBottom: 10,
    },
    isTypingText: {
        fontSize: 12,
        color: Colors.buttonTextDisabled,
    },
    createCaseButtonsContainer: {
        display: 'flex',
        gap: '1em',
    },
    importFileNameContainer: {
        display: 'flex',
        gap: '0.5em',
    },
    importClearButton: {
        fontSize: 12,
    },
    importPreviewButton: {
        fontSize: 12,
    },
    importFileName: {
        fontSize: 12,
        color: Colors.darkGreen,
        fontStyle: 'italic',
    },
    importReviewWarning: {
        border: '1px solid #13A89E',
        borderRadius: 5,
        color: '#05403C',
        padding: 10,
        fontSize: '0.9rem',
        backgroundColor: '#13A89E26',
        marginTop: '1em',
    },
    importError: {
        backgroundColor: Colors.lighterRed,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.darkerRed,
        borderRadius: 5,
        color: Colors.darkerRed,
        display: 'flex',
        alignItems: 'center',
        padding: 10,
        fontSize: '0.9rem',
        marginTop: '1em',
        columnGap: 5,
    },
};

export default ChatInputStyles;
