// @flow
const departments = [
    {
        label: 'Tissue',
        value: 'Tissue',
    },
    {
        label: 'Organ',
        value: 'Organ',
    },
    {
        label: 'Quality',
        value: 'Quality',
    },
    {
        label: 'Family Support',
        value: 'Family Support',
    },
    {
        label: 'Management',
        value: 'Management',
    },
    {
        label: 'Medical Examiner/Funeral Home Liaison',
        value: 'Medical Examiner/Funeral Home Liaison',
    },
    {
        label: 'Hospital Development',
        value: 'Hospital Development',
    },
    {
        label: 'Community Outreach',
        value: 'Community Outreach',
    },
    {
        label: 'Legal',
        value: 'Legal',
    },
    {
        label: 'Preservation',
        value: 'Preservation',
    },
    {
        label: 'Eye',
        value: 'Eye',
    },
    {
        label: 'Other',
        value: 'Other',
    }
];

export default departments;
