// @flow
import React, { PureComponent } from 'react';
import {
    selectProfileName, selectProfileEmail, selectProfileOrganizationName, selectProfilePhone,
} from 'txp-core';

import MentionStyles from './Styles/MentionStyles';
import type { ChatroomMemberMap } from '../Utils/types';

type Props = {
    memberId: number,
    members: ChatroomMemberMap,
    avatar: string,

    addMentionedUser: (memberId: number, members: ChatroomMemberMap) => *,
}

class MentionUser extends PureComponent<Props> {
    selectUser = () => {
        const {
            memberId,
            members,
            addMentionedUser,
        } = this.props;

        addMentionedUser(memberId, members);
    };

    render() {
        const {
            members,
            memberId,
            avatar,
        } = this.props;

        const fullName = selectProfileName(members[memberId.toString()].profile, selectProfileEmail(members[memberId.toString()].profile));
        let lineTwo = selectProfileOrganizationName(members[memberId.toString()].profile);
        // if getting members from contacts then we don't have their organization and that messes up the positioning of elements, so
        // we'll display their phone number instead.
        if (lineTwo === ' ') {
            lineTwo = selectProfilePhone(members[memberId.toString()].profile);
        }

        return (
            <div className="mentionUser" style={{ outline: 'none', cursor: 'pointer', }} role="button" onClick={this.selectUser}>{/*eslint-disable-line*/}
                <div style={MentionStyles.profileImageWrapper}>
                    <img
                        style={MentionStyles.profileImage}
                        src={avatar}
                        alt=""
                    />
                </div>
                <div style={MentionStyles.profileWrapper}>
                    <span style={MentionStyles.memberNames}>
                        {fullName}
                    </span>
                    <div style={MentionStyles.orgWrapper}>
                        <span style={MentionStyles.memberOrg}>
                            {lineTwo}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default MentionUser;
