/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
// @flow
import type { FormDefData } from '../../Utils/types';
import {
    STRING, UI_TYPE_BINARY, UI_TYPE_CHECK,
} from './FormTypes';

const formDef: FormDefData = {
    formId: 'BloodWorkResult',
    behaviors: {
        lockWhenFinalized: true,
        saveOnComplete: true,
        clearOnIncomplete: true,
        clearOnNotApplicable: true,
    },
    sections: [
        {
            id: 1,
            title: '',
            fields: [
                { id: 1, title: 'Result', type: STRING, uiType: UI_TYPE_BINARY, responseOptions: ['Positive', 'Negative'], mandatory: true, tags: ['REVEAL-B'], },
                { id: 2, title: 'Hep B', type: STRING, uiType: UI_TYPE_CHECK, mandatory: false, tags: ['B'], },
                { id: 3, title: 'Hep C', type: STRING, uiType: UI_TYPE_CHECK, mandatory: false, tags: ['B'], },
                { id: 4, title: 'HIV', type: STRING, uiType: UI_TYPE_CHECK, mandatory: false, tags: ['B'], }
            ],
        }
    ],
    disableBehavior: {
        'REVEAL-B': 1,
    },
};

export default formDef;
