// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FontIcon from 'react-toolbox/lib/font_icon';

import {
    setMessageReply as _setMessageReply,
} from '../Redux/ChatMessageActions';
import type { ChatroomInfo, ChatTemporaryMessage } from '../Utils/types';
import ChatMessageStyles from './Styles/ChatMessageStyles';
import truncate from '../Utils/text';

type Props = {
    chatId: number,
    chat: ChatroomInfo,
    tempMessage: ChatTemporaryMessage,

    setMessageReply: (chatId: number, replyMessageId: ?number) => *,
};

class ChatInputReply extends PureComponent<Props> {
    render() {
        const {
            chatId,
            chat,
            tempMessage,
            setMessageReply,
        } = this.props;

        let replyMessageId;
        if (tempMessage && tempMessage.replyMessageId) {
            // eslint-disable-next-line prefer-destructuring
            replyMessageId = tempMessage.replyMessageId;
        }

        if (!replyMessageId) return null;

        if (!chat || chatId === 0) {
            return null;
        }

        const messages = chat && chat.messages ? chat.messages : null;
        const messageText = messages && messages[`${replyMessageId}`] && messages[`${replyMessageId}`].textContent
            ? messages[`${replyMessageId}`].textContent : 'You are replying to a message';

        return (
            <div
                style={ChatMessageStyles.replyAlertContainer}
                role="button"
                tabIndex={0}
                onClick={() => setMessageReply(chatId, null)}
                onKeyPress={() => {}}
            >
                <span style={ChatMessageStyles.replyAlertText}>
                    {truncate(messageText, 100)}
                </span>
                <FontIcon
                    className="replyAlertClose"
                    value="close"
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const chatId = props.chatId ? props.chatId : 0;
    const chat = state.chatList.chats ? state.chatList.chats[`${chatId}`] : null;

    return {
        chatId,
        chat,
        tempMessage: state.chatMessage[chatId],
    };
};

export default connect(mapStateToProps, {
    setMessageReply: _setMessageReply,
})(ChatInputReply);
