// @flow
import Colors from '../../Themes/Colors';

const CaseListStyles = {
    caseRow: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.caseListItemBorder,
        borderBottomStyle: 'solid',
        outline: 'none',
        cursor: 'pointer',
        zIndex: '-10',
        // height: 'auto',
        // minHeight: '100px',
        maxHeight: '150px',
        minHeight: '60px',
    },
    caseRowSelected: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.caseListItemBorder,
        borderBottomStyle: 'solid',
        backgroundColor: Colors.lighterBlue,
        outline: 'none',
        cursor: 'pointer',
        zIndex: '-10',
        minHeight: '60px',
        maxHeight: '150',
    },
    paginationFlex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingTop: 15,
        paddingLeft: 15,
        gap: 30,
    },
    selectFormControl: {
        minWidth: 150,
        maxWidth: 300,
    },
    selectChipBox: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5,
    },
};

export default CaseListStyles;
