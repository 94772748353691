// @flow
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    DONOR_STATUS_VIEW,
    DONOR_TASK_VIEW,
    selectDonorView as _selectDonorView,
} from '../Redux/ApplicationActions';
import {
    getDonorTask as _getDonorTask,
} from '../Redux/DonorActions';
import { getDonorFormAttributes } from '../Utils/TaskDetailsUtils';
import styles from './Styles/DonorStyles';
import type { StaticTask } from '../Utils/types';
import Colors from '../Themes/Colors';
import PanelNavigation from './PanelNavigation';
import { useWorkflowDefinition } from '../Utils/hooks';

// eslint disables due to https://github.com/facebook/flow/issues/6644

type Props = {
    title: string,
    formId: string,
    tasks: Array<StaticTask>,
    currentTask: StaticTask,
    // eslint-disable-next-line react/require-default-props
    navigateBackTo: string,
    donorId: number,
    donorView: string,

    savingDonor: boolean,
    donorUpdated: boolean,
    formUpdated: boolean,
    hideBackSaveButtons: boolean,
    hideSave: boolean,
    workflowKey: string,

    selectDonorView: (viewName: string) => *,
    getDonorTask: (donorId: number, taskId: number) => *,
    onSaveClick: (formId: string) => *,
};

const DonorPanelNavigation = (props: Props) => {
    const {
        title,
        savingDonor,
        formUpdated,
        donorUpdated,
        onSaveClick,
        formId,
        currentTask,
        tasks,
        donorView,
        hideBackSaveButtons,
        hideSave,
        selectDonorView,
        getDonorTask,
        donorId,
        navigateBackTo = DONOR_STATUS_VIEW,
        workflowKey,
    } = props;

    const { data, } = useWorkflowDefinition(workflowKey);
    const workflow = data?.workflow;
    const useTaskNavigation = workflow ? workflow.useTaskNavigation : false;
    const warnOnUnload = formUpdated || donorUpdated;

    useEffect(() => {
        if (warnOnUnload) {
            window.addEventListener('beforeunload', promptOnUnload);
        } else {
            window.removeEventListener('beforeunload', promptOnUnload);
        }
        return () => {
            window.removeEventListener('beforeunload', promptOnUnload);
        };
    }, [warnOnUnload]);

    const promptOnUnload = (event) => {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
    };

    const onBackClick = () => {
        if (formUpdated || donorUpdated) {
            const response = window.confirm('Unsaved changes\nAre you sure you want to discard your changes to this case?');
            if (response) {
                selectDonorView(navigateBackTo);
            }
        } else {
            selectDonorView(navigateBackTo);
        }
    };

    const onTaskNavClick = (taskId: number) => {
        getDonorTask(donorId, taskId);
        selectDonorView(DONOR_TASK_VIEW);
    };

    const saveDisabled = (!formUpdated && !donorUpdated) || savingDonor;
    const currentLocation = currentTask.details && currentTask.details.sectionPosition ? currentTask.details.sectionPosition - 1 : 1;
    let nextTask;
    let prevTask;
    let nextTaskId = 0;
    let prevTaskId = 0;
    if (tasks) {
        nextTask = tasks[currentLocation + 1] ? tasks[currentLocation + 1] : null;
        prevTask = tasks[currentLocation - 1] ? tasks[currentLocation - 1] : null;
        nextTaskId = nextTask ? nextTask.taskId : 0;
        prevTaskId = prevTask ? prevTask.taskId : 0;
    } else {
        nextTask = null;
        prevTask = null;
    }
    return (
        <>
            {!hideBackSaveButtons ? (
                <PanelNavigation
                    onBackClick={onBackClick}
                    onSaveClick={() => onSaveClick(formId)}
                    hideSave={hideSave}
                    saveDisabled={saveDisabled}
                    title={title}
                />
            ) : <div style={styles.donorCreatedWrapper}>{title}</div> }
            { useTaskNavigation && (donorView === DONOR_TASK_VIEW)
                ? (
                    <div style={styles.buttonsWrapper}>
                        { prevTask
                            ? (
                                <Button
                                    disabled={!saveDisabled}
                                    onClick={() => onTaskNavClick(prevTaskId)}
                                >
                                    <FontAwesomeIcon
                                        fixedWidth
                                        color={saveDisabled ? Colors.blue : Colors.lightGray}
                                        icon={faChevronLeft}
                                    />
                                    {prevTask.details.description}
                                </Button>
                            ) : <div />}
                        { nextTask
                            ? (
                                <Button
                                    disabled={!saveDisabled}
                                    onClick={() => onTaskNavClick(nextTaskId)}
                                >
                                    {nextTask.details.description}
                                    <FontAwesomeIcon
                                        fixedWidth
                                        color={saveDisabled ? Colors.blue : Colors.lightGray}
                                        icon={faChevronRight}
                                    />
                                </Button>
                            ) : <div />}
                    </div>
                ) : null}
        </>
    );
};

const mapStateToProps = (state) => {
    const {
        formId, staticTask, workflowId,
    } = getDonorFormAttributes(state);
    const workflowTasks = state.donor.workflowTasks[workflowId] ? state.donor.workflowTasks[workflowId] : null;

    return {
        formId,
        tasks: workflowTasks ? workflowTasks.tasks : null,
        currentTask: staticTask,
        savingDonor: state.loading.updateDonor,
        formUpdated: state.forms.formUpdated,
        donorUpdated: state.donor.donorUpdated,
        donorId: state.donor.openDonorId,
        donorView: state.application.donorView,
        workflowKey: workflowId,
    };
};

export default connect(mapStateToProps, {
    selectDonorView: _selectDonorView,
    getDonorTask: _getDonorTask,
})(DonorPanelNavigation);
