// @flow
import {
    compareDesc as compareDescRemote,
    isBefore as isBeforeRemote,
    isAfter,
    formatDistance,
    isSameMinute,
    isToday,
    startOfDay,
    differenceInMilliseconds,
} from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInYears from 'date-fns/differenceInYears';
import format from 'date-fns/format';
import en from 'date-fns/locale/en-US';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import { formatToTimeZone, parseFromTimeZone } from 'date-fns-timezone';

const timeFormat = 'h:mm a';
const dateFormat = 'P';
const dateFormatTZ = 'MM/DD/YYYY';
const timeFormatTZ = 'HH:mm z';

const cfg = { locale: en, };

export const INVALID_DATE = 'INVALID_DATE';

// This is used in the Settings.js file as a sort of "infinite" date
// Note: This is a constant that is used to set the global do not disturb to an infinite duration
// Ensure changes to this value are also made in the mobile app
export const INFINITE_DND_CONSTANT = '2300-12-31T06:00:00.000Z';

export const parse = parseISO;

export const calculateAgeFromDOB = (dob: string): number => differenceInYears(new Date(), new Date(dob));

export const formatTime = (date: Date): string => {
    if (date === null) {
        return 'N/A';
    }
    if (isValid(date)) {
        return format(date, timeFormat, cfg);
    }
    return 'Invalid Date';
};

export const formatDate = (date: string | Date, utc?: boolean = false): ?string => {
    const inputDate = typeof date === 'string' ? parse(date) : date;

    if (isValid(inputDate)) {
        if (utc) {
            return format(inputDate, dateFormat);
        }
        return format(inputDate, dateFormat, cfg);
    }

    return null;
};

// Note: format will translate the incoming UTC timestamp to device time zone
export const formatDateTime = (ts: string, separator: string = ' '): string => {
    if (ts === null || ts === '') {
        return 'N/A';
    }
    const date = parse(ts);
    if (isValid(date)) {
        const tpl = isToday(date) ? timeFormat : `${dateFormat}${separator}${timeFormat}`;

        return format(date, tpl, cfg);
    }
    return 'Invalid Date';
};

// Note: format will translate the incoming UTC timestamp to device time zone
export const formatFullDateTime = (ts: string, separator: string = ' '): string => {
    if (ts === null || ts === '') {
        return 'date is unavailable';
    }
    const date = parse(ts);
    if (isValid(date)) {
        return format(date, `${dateFormat}${separator}${timeFormat}`, cfg);
    }

    return 'date is unavailable';
};

// Note: format will translate the incoming UTC timestamp to device time zone
export const formatFullDateTimeWithTZ = (ts: string): string => {
    if (ts === null || ts === '') {
        return 'date is unavailable';
    }
    const date = parse(ts);
    if (isValid(date)) {
        const { timeZone, } = Intl.DateTimeFormat().resolvedOptions();
        const tpl = `${dateFormatTZ} ${timeFormatTZ}`;
        return formatToTimeZone(date, tpl, { timeZone, });
    }
    return 'date is unavailable';
};

export const formatDateTimeToTZ = (ts: string, tz: string, dst: boolean): string => {
    const timezone = getCanonicalTimeZone(tz, dst);

    if (ts === '' || timezone === '') {
        return '';
    }

    const date = parseFromTimeZone(ts, { timeZone: getCanonicalTimeZone(tz, dst), });

    if (isValid(date)) {
        return date.toISOString();
    }

    return '';
};

function getCanonicalTimeZone(tz: string, dstObserved: boolean) {
    switch (tz) {
        case 'Eastern':
            return dstObserved ? 'America/New_York' : 'America/Jamaica'; // just using Jamaica because DST is not observed
        case 'Central':
            return dstObserved ? 'America/Chicago' : 'America/Belize'; // just using Belize because DST is not observed
        case 'Mountain':
            return dstObserved ? 'America/Denver' : 'America/Phoenix';
        case 'Pacific':
            return 'America/Los_Angeles'; // Looks like all of pacific time observes dst
        default:
            return '';
    }
}

export const parseDate = (ts: string): Date | null => {
    if (ts === null || ts === '') {
        return null;
    }

    const date = parse(ts);
    if (isValid(date)) {
        return date;
    }

    return null;
};

export const isEqualMinute = (ts1: string, ts2: string) => {
    const date1 = parse(ts1);
    const date2 = parse(ts2);

    return isSameMinute(date1, date2);
};

export const distanceToNow = (ts: string): string => formatDistance(parse(ts), Date.now());

export const isoNow = (): string => format(Date.now(), timeFormat, cfg);
export const isoNowUTC = (): string => new Date(Date.now()).toISOString();

// Note: Offset is in milliseconds
export const offsetToNow = (offset: number): Date => new Date(Date.now() + offset);
export const isoOffset = (offset: number) => format(offsetToNow(offset), timeFormat, cfg);

// naive implementation of formatRelative coming in date-fns@2
//  see: https://date-fns.org/v2.0.0-alpha.10/docs/formatRelative
export const formatRelative = (ts1: Date): string => {
    const diffDays = differenceInDays(startOfDay(ts1), startOfDay(new Date()));

    if (Math.abs(diffDays) <= 6) {
        if (diffDays === 0) {
            return `Today at ${formatTime(ts1)}`;
        } if (diffDays === 1) {
            return `Tomorrow at ${formatTime(ts1)}`;
        } if (diffDays === -1) {
            return `Yesterday at ${formatTime(ts1)}`;
        }

        const dayName = format(ts1, 'EEEE', cfg);

        return `${diffDays < 0 ? 'Last ' : ''}${dayName} at ${formatTime(ts1)}`;
    }

    return format(ts1, `${dateFormat} ${timeFormat}`, cfg);
};

// Return a date string in the form of 'yyyy-mm-dd' based on the given date
export const getLocalISODateString = (ts: Date) => {
    const tsLocal = new Date(ts.getTime());
    tsLocal.setMinutes(ts.getMinutes() - ts.getTimezoneOffset());
    return tsLocal.toISOString().slice(0, -14);
};

// Return a datetime string in the format 'yyyy-mm-ddTHH:mm'
export const getLocalISODateTimeString = (ts: Date) => {
    const tsLocal = new Date(ts.getTime());
    tsLocal.setMinutes(ts.getMinutes() - ts.getTimezoneOffset());
    return tsLocal.toISOString().slice(0, -8);
};

export const compareDesc = (ts1: string, ts2: string) => compareDescRemote(parse(ts1), parse(ts2));

export const isFutureDatetime = (ts: string) => isAfter(parse(ts), new Date());

export const isBefore = (ts1: string, ts2: string) => isBeforeRemote(parse(ts1), parse(ts2));

export const millisecondsUntil = (ts: string) => differenceInMilliseconds(parse(ts), new Date());
