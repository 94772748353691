// @flow
import { headerHeight } from '../../Components/Styles/HeaderStyles';
import Colors from '../../Themes/Colors';

// Note: Pixel values are scaled based on dimension resize constants
const ChatStyles = {
    chatContainerStyle: {
        paddingTop: `${headerHeight}`,
    },
    center: {
        textAlign: 'center',
    },
    titleText: {
        fontSize: 16,
        color: Colors.black,
        textAlign: 'center',
        lineHeight: 2,
    },
    stickyHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: Colors.white,
        zIndex: 1000,
        borderBottom: '1px solid #D3CECE',
        marginBottom: 10,
    },
};

export default ChatStyles;
