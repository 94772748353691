// @flow
import Colors from '../../Themes/Colors';
import { mediumFontSize, smallFontSize } from './ChatroomDetailsStyles';

const UserDetailsStyles = {
    userRow: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    userRowContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    userRowText: {
        paddingBottom: 2,
        display: 'flex',
        flexDirection: 'column',
    },
    memberButton: {
        outline: 'none',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    memberButtonDisabled: {
        outline: 'none',
        background: 'none',
        border: 'none',
        float: 'right',
        cursor: 'default',
    },
    profileImageWrapper: {
        marginRight: 20,
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        float: 'left',
        cursor: 'pointer',
    },
    userName: {
        color: Colors.black,
        fontSize: mediumFontSize,
        fontWeight: '500',
        float: 'left',
        display: 'inline',
        width: 'fit-content',
        marginBottom: 2,
    },
    userEmail: {
        color: Colors.formLabel,
        fontSize: mediumFontSize,
        width: '15vw',
        marginBottom: 2,
    },
    doNotDisturbIcon: {
        fontSize: 18,
        float: 'left',
        display: 'inline',
    },
    managerIcon: {
        fontSize: 14,
        float: 'left',
        display: 'inline',
        color: Colors.blue,
    },
    smallDetails: {
        fontSize: smallFontSize,
        marginBottom: 1,
    },
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
    },
};

export default UserDetailsStyles;
