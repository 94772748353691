// @flow
import type { Saga } from 'redux-saga';
import {
    select,
    put,
    delay,
} from 'redux-saga/effects';
import {
    selectUserId,
} from 'txp-core';

import api from '../Services/Api';
import { finishLoading, startLoading } from '../Redux/LoadingActions';
import {
    apiFetch,
    apiPost,
    apiDelete,
    apiPut,
} from './ApiSaga';
import { setSagaMessage } from '../Redux/ApplicationActions';
import {
    addPermission,
    getPermissions,
    receiveResourcePermissions,
    storePermissions,
} from '../Redux/PermissionActions';
import type { Target, Rights } from '../Utils/types';
import { addDefaultClientPermissions, ENTITY_TYPE_CHATROOM } from '../Utils/hasPermissions';
import type {
    AddPermission,
    AddPermissionToAuthorizedEntities,
    GetResourcePermissions,
    RemovePermission,
    UpdatePermission,
} from '../Redux/PermissionActions';
import { parseResponseTextError } from '../Utils/flattenError';

export function* getPermissionsSaga(): Saga<void> {
    yield put(startLoading('permissions'));

    const { result, error, } = yield apiFetch(api.txp.permissions);

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', errorMessage, ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Loading permissions failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Loading permissions failed', 'Try again later', ''));
        }
        yield put(finishLoading('permissions'));
    } else {
        const permissionsResult = result.permissions;

        // AP has no way to add BasicUser permissions yet so add default permissions here
        const { accessLevel, } = yield select((state) => state.auth || {});
        const permissions = addDefaultClientPermissions(accessLevel);

        for (let i = 0; i < permissionsResult.length; i += 1) {
            const perm = permissionsResult[i];
            const target: Target = {
                entityType: perm.target.target_type,
                entityId: perm.target.target_id,
                subEntityType: perm.target.subtarget_type,
                subEntityId: perm.target.subtarget_id,
                cascade: perm.target.cascade,
            };
            const rights: Rights = {
                read: perm.rights.read,
                update: perm.rights.update,
                delete: perm.rights.delete,
            };
            permissions.push({
                target,
                rights,
            });
        }

        yield put(storePermissions(permissions));

        yield put(finishLoading('permissions'));
    }
}

export function* getResourcePermissionsSaga(action: GetResourcePermissions): Saga<void> {
    const {
        entityType,
        entityId,
    } = action;

    yield put(startLoading('resourcePermissions'));

    const { result, error, } = yield apiFetch(api.txp.resourcePermissions, {
        entityType,
        entityId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', errorMessage, ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Loading resource permissions failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Loading resource permissions failed', 'Try again later', ''));
        }
        yield put(finishLoading('resourcePermissions'));
    } else {
        const { permissions, } = result;
        const resourcePermissions = [];
        for (let i = 0; i < permissions.length; i += 1) {
            resourcePermissions.push({
                permissionId: permissions[i].permission_id,
                target: {
                    entityType: permissions[i].target.target_type,
                    entityId: permissions[i].target.target_id,
                    subEntityType: permissions[i].target.subtarget_type || null,
                    subEntityId: permissions[i].target.subtarget_id || null,
                    cascade: permissions[i].target.cascade,
                },
                rights: {
                    read: permissions[i].rights.read,
                    update: permissions[i].rights.update,
                    delete: permissions[i].rights.delete,
                },
                authorized: {
                    authorizedType: permissions[i].authorized.authorized_type,
                    authorizedId: permissions[i].authorized.authorized_id,
                    displayName: permissions[i].authorized.display_name,
                },
            });
        }

        yield put(receiveResourcePermissions(entityType, entityId, resourcePermissions));

        yield put(finishLoading('resourcePermissions'));
    }
}

export function* addPermissionSaga(action: AddPermission): Saga<void> {
    const {
        entityType,
        entityId,
        subEntityType,
        subEntityId,
        cascade,
        read,
        update,
        canDelete,
        authorizedType,
        authorizedId,
        displayName,
        displayToast,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { result, error, } = yield apiPost(api.txp.addPermission, null, {
        target_type: entityType,
        target_id: entityId,
        subtarget_type: subEntityType,
        subtarget_id: subEntityId,
        cascade,
        read,
        update,
        delete: canDelete,
        authorized_type: authorizedType,
        authorized_id: authorizedId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', errorMessage, ''));
        } else if (error.isInvalidResponseCode) {
            yield put(setSagaMessage('', parseResponseTextError(error.responseText), ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Adding permission failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Adding permission failed', 'Try again later', ''));
        }
    } else {
        const permissionId = result.permission_id;

        const resourcePermissions = yield select((state) => state.permission.resourcePermissions) || {};
        const entityTypeResourcePermissions = resourcePermissions && resourcePermissions[entityType] ? resourcePermissions[entityType] : {};
        const currentPermissions = entityTypeResourcePermissions && entityTypeResourcePermissions[entityId]
            ? entityTypeResourcePermissions[entityId] : [];
        if (currentPermissions.length === 0) {
            currentPermissions.push({
                permissionId,
                target: {
                    entityType,
                    entityId,
                    subEntityType,
                    subEntityId,
                    cascade,
                },
                rights: {
                    read,
                    update,
                    delete: canDelete,
                },
                authorized: {
                    authorizedType,
                    authorizedId,
                    displayName,
                },
            });
        } else {
            currentPermissions.unshift({
                permissionId,
                target: {
                    entityType,
                    entityId,
                    subEntityType,
                    subEntityId,
                    cascade,
                },
                rights: {
                    read,
                    update,
                    delete: canDelete,
                },
                authorized: {
                    authorizedType,
                    authorizedId,
                    displayName,
                },
            });
        }

        yield put(receiveResourcePermissions(entityType, entityId, currentPermissions));

        // Delay so the update dialog can close, otherwise the toast goes away too quickly
        yield delay(500);

        if (displayToast) {
            if (entityType === ENTITY_TYPE_CHATROOM) {
                yield put(setSagaMessage('', 'Manager added', ''));
            } else {
                yield put(setSagaMessage('', 'Permission added', ''));
            }
        }
    }
}

export function* addPermissionToAuthorizedEntitiesSaga(action: AddPermissionToAuthorizedEntities): Saga<void> {
    const {
        authorizedEntities,
        entityType,
        entityId,
        subEntityType,
        subEntityId,
        cascade,
        read,
        update,
        canDelete,
        displayToast,
    } = action;

    if (!authorizedEntities) {
        return;
    }

    for (let i = 0; i < authorizedEntities.length; i += 1) {
        const {
            authorizedType,
            authorizedId,
            displayName,
        } = authorizedEntities[i];
        yield put(
            addPermission(
                entityType,
                entityId,
                subEntityType,
                subEntityId,
                cascade,
                read,
                update,
                canDelete,
                authorizedType,
                authorizedId,
                displayName,
                false
            )
        );

        if (displayToast) {
            if (entityType === ENTITY_TYPE_CHATROOM) {
                yield put(setSagaMessage('', 'Managers added', ''));
            } else {
                yield put(setSagaMessage('', 'Permissions added', ''));
            }
        }
    }
}

export function* updatePermissionSaga(action: UpdatePermission): Saga<void> {
    const {
        entityType,
        entityId,
        permissionId,
        read,
        update,
        canDelete,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { error, } = yield apiPut(api.txp.updatePermission, {
        permissionId,
    }, {
        read,
        update,
        delete: canDelete,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', errorMessage, ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Updating permission failed', 'are you online?', error));
        } else {
            yield put(setSagaMessage('Updating permission failed', 'try again later', error));
        }
    } else {
        const currentPermissions = yield select((state) => state.permission.resourcePermissions[entityType][entityId]) || [];
        const memberId = yield select((state) => selectUserId(state.auth));

        const index = currentPermissions.findIndex((x) => x.permissionId === permissionId);
        if (index !== -1) {
            currentPermissions[index] = {
                ...currentPermissions[index],

                rights: {
                    read,
                    update,
                    delete: canDelete,
                },
            };
            if (currentPermissions[index].authorized.authorizedType === 'User'
                && currentPermissions[index].authorized.authorizedId === memberId) {
                yield put(getPermissions());
            }
        }

        yield put(receiveResourcePermissions(entityType, entityId, currentPermissions));

        put(setSagaMessage('', 'Permission Updated', ''));
    }
}

export function* removePermissionSaga(action: RemovePermission): Saga<void> {
    const {
        entityType,
        entityId,
        permissionId,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { error, } = yield apiDelete(api.txp.updatePermission, {
        permissionId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', errorMessage, ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Removing permission failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Removing permission failed', 'Try again later', ''));
        }
    } else {
        const currentPermissions = yield select((state) => state.permission.resourcePermissions[entityType][entityId]) || [];
        const memberId = yield select((state) => selectUserId(state.auth));

        const index = currentPermissions.findIndex((x) => x.permissionId === permissionId);
        if (index !== -1) {
            if (currentPermissions[index].authorized.authorizedType === 'User'
                && currentPermissions[index].authorized.authorizedId === memberId) {
                yield put(getPermissions());
            }
            currentPermissions.splice(index, 1);
        }

        yield put(receiveResourcePermissions(entityType, entityId, currentPermissions));

        if (entityType === ENTITY_TYPE_CHATROOM) {
            yield put(setSagaMessage('', 'Manager removed', ''));
        } else {
            yield put(setSagaMessage('', 'Permission removed', ''));
        }
    }
}
