// @flow
import type { ResetError } from './ApplicationActions';
import { resetErrorState, getReducerError } from './ApplicationActions';
import createUuid from '../Utils/createUuid';

export type LoadingState = {
    login: boolean,
    register: boolean,
    activateSMS: boolean,
    applicationData: boolean,
    chatrooms: boolean,
    contacts: boolean,
    search: boolean,
    media: boolean,
    profilePicture: boolean,
    updateEmail: boolean,
    cancelUpdateEmail: boolean,
    updatePhone: boolean,
    offers: boolean,
    organizations: boolean,
    surgeons: boolean,
    joinOrganization: boolean,
    videos: boolean,
    cases: boolean,
    donor: boolean,
    followers: boolean,
    follower: boolean,
    followerTasks: boolean,
    followerPeople: boolean,
    checkContact: boolean,
    tasks: boolean,
    donorTask: boolean,
    taskData: boolean,
    orgMembers: boolean,
    teams: boolean,
    updateDonor: boolean,
    saveDonor: boolean,
    permissions: boolean,
    resourcePermissions: boolean,
    CDSUpload: boolean,
    notifiableEventGroup: boolean,
    users: boolean,
    caseNotes: boolean,

    taskStatus: { [string]: boolean },

    // Loading of these are room specific
    chatFiles: { [string]: boolean },
    donorFiles: {[string]: boolean },
    chatMembers: { [string]: boolean },
    chatMessages: { [string]: boolean },

    // Each key is `${chatId}-${msgId}`
    chatMessageStatuses: { [string]: boolean },
    reducerErrors: Array<any>,
};

export type Identifier =
    | 'login'
    | 'register'
    | 'activateSMS'
    | 'applicationData'
    | 'chatrooms'
    | 'contacts'
    | 'search'
    | 'media'
    | 'saveChat'
    | 'profilePicture'
    | 'updateEmail'
    | 'cancelUpdateEmail'
    | 'updatePhone'
    | 'offers'
    | 'organizations'
    | 'surgeons'
    | 'joinOrganization'
    | 'videos'
    | 'cases'
    | 'donor'
    | 'followers'
    | 'follower'
    | 'followerTasks'
    | 'followerPeople'
    | 'checkContact'
    | 'tasks'
    | 'donorTask'
    | 'taskData'
    | 'orgMembers'
    | 'teams'
    | 'donorFiles'
    | 'updateDonor'
    | 'saveDonor'
    | 'permissions'
    | 'resourcePermissions'
    | 'CDSUpload'
    | 'notifiableEventGroup'
    | 'users'
    | 'saveTeam'
    | 'deleteTeam'
    | 'caseNotes';

export type EntityIdentifier = 'chatFiles' | 'chatMembers' | 'chatMessages' | 'chatMessageStatuses' | 'donorFiles' | 'taskStatus';

const initialState: LoadingState = {
    login: false,
    register: false,
    activateSMS: false,
    applicationData: false,
    chatrooms: false,
    contacts: false,
    search: false,
    media: false,
    profilePicture: false,
    updateEmail: false,
    cancelUpdateEmail: false,
    updatePhone: false,
    offers: false,
    cases: false,
    donor: false,
    followers: false,
    follower: false,
    followerTasks: false,
    followerPeople: false,
    checkContact: false,
    tasks: false,
    donorTask: false,
    taskData: false,
    orgMembers: false,
    teams: false,
    updateDonor: false,
    saveDonor: false,
    organizations: false,
    surgeons: false,
    joinOrganization: false,
    videos: false,
    permissions: false,
    resourcePermissions: false,
    CDSUpload: false,
    notifiableEventGroup: false,
    users: false,
    caseNotes: false,
    reducerErrors: [],
    taskStatus: {},
    chatFiles: {},
    donorFiles: {},
    chatMembers: {},
    chatMessages: {},
    chatMessageStatuses: {},
};

export type StartLoading = { type: 'Loading/START_LOADING', identifier: Identifier };
export type FinishLoading = { type: 'Loading/FINISH_LOADING', identifier: Identifier };
export type StartLoadingEntity = { type: 'Loading/START_LOADING_ENTITY', identifier: EntityIdentifier, id: string };
export type FinishLoadingEntity = { type: 'Loading/FINISH_LOADING_ENTITY', identifier: EntityIdentifier, id: string };

type Action =
    | StartLoading
    | FinishLoading
    | StartLoadingEntity
    | FinishLoadingEntity
    | ResetError;

export const startLoading = (identifier: Identifier): StartLoading => ({
    type: 'Loading/START_LOADING',
    identifier,
});

export const finishLoading = (identifier: Identifier): FinishLoading => ({
    type: 'Loading/FINISH_LOADING',
    identifier,
});

export const startLoadingEntity = (identifier: EntityIdentifier, id: number | string): StartLoadingEntity => ({
    type: 'Loading/START_LOADING_ENTITY',
    identifier,
    id: `${id}`,
});

export const finishLoadingEntity = (identifier: EntityIdentifier, id: number | string): FinishLoadingEntity => ({
    type: 'Loading/FINISH_LOADING_ENTITY',
    identifier,
    id: `${id}`,
});

const reducer = (state: LoadingState = initialState, action: Action): LoadingState => {
    // wrap the whole reducer to catch any unexpected exceptions and record them
    // Another component will need to act upon these and potentially send them to Sentry
    try {
        return innerReducer(state, action);
    } catch (err) {
        const reducerErrors = state.reducerErrors.slice();
        const uuid = createUuid(0);
        reducerErrors.push(getReducerError(uuid, 'loading', action, err));
        return {
            ...state,

            reducerErrors,
        };
    }
};

const innerReducer = (state: LoadingState = initialState, action: Action): LoadingState => {
    switch (action.type) {
        case 'Application/RESET_ERROR':
            return resetErrorState(state, action);

        case 'Loading/START_LOADING':
            return {
                ...state,

                // $FlowFixMe
                [action.identifier]: true,
            };

        case 'Loading/FINISH_LOADING':
            return {
                ...state,

                // $FlowFixMe
                [action.identifier]: false,
            };
        case 'Loading/START_LOADING_ENTITY':
            return {
                ...state,

                // $FlowFixMe
                [action.identifier]: {
                    ...state[action.identifier],

                    [action.id]: true,
                },
            };

        case 'Loading/FINISH_LOADING_ENTITY':
            return {
                ...state,

                // $FlowFixMe
                [action.identifier]: {
                    ...state[action.identifier],

                    [action.id]: false,
                },
            };
        default:
            return state;
    }
};

export default reducer;
