// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { ssoLogin } from 'txp-core';
import Images from '../Themes/Images';

function SSOLoginHandler() {
    const { authState, oktaAuth, } = useOktaAuth();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.authenticated);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            dispatch(
                ssoLogin(
                    authState.accessToken.claims.groups[0],
                    authState.accessToken.accessToken,
                    authState.refreshToken.refreshToken,
                    authState.accessToken.expiresAt - Math.floor(Date.now() / 1000),
                    'TXP_CHAT_WEB',
                    'Standard',
                    'BasicUser'
                )
            );
        }
    }, [authState, oktaAuth]);

    return (
        <>
            {isAuthenticated
                ? (
                    <Redirect to="/login" />
                ) : (
                    <div className="verticalCenter">
                        <div>
                            <img height={120} width={400} src={Images.logoHeader} alt="OmniLife Health Logo" />
                        </div>
                        <div>
                            Logging in...
                        </div>
                    </div>
                )}
        </>
    );
}

export default SSOLoginHandler;
