// @flow
import Colors from '../../Themes/Colors';

// Note: Pixel values are scaled based on dimension resize constants
const RegistrationStyles = {
    formError: {
        color: Colors.formError,
    },
    formLabel: {
        color: Colors.formLabel,
        fontSize: 14,
        lineHeight: 2,
    },
    box: {
        margin: 10,
    },
    boxText: {
        color: Colors.text,
        fontSize: 28,
        textAlign: 'center',
    },
    passcode: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loading: {
        marginTop: 20,
        width: 60,
        height: 60,
    },
    backButton: {
        display: 'inline-block',
        float: 'left',
    },
};

export default RegistrationStyles;
