// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import sift from 'sift';
import IconMenu from 'react-toolbox/lib/menu/IconMenu';
import MenuItem from 'react-toolbox/lib/menu/MenuItem';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import LoadingIndicator from 'react-loading-indicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import Fade from 'react-reveal';
import ReactTooltip from 'react-tooltip';
import {
    emailActivationDialog as _emailActivationDialog,
    updateProfile as _updateProfile,
} from 'txp-core';

import ChatListItem from './ChatListItem';
import Search from './Search';
import ChatListStyles from './Styles/ChatListStyles';
import type {
    ChatroomInfo,
    ChatroomInfoMap,
    ChatroomSort,
    ChatroomFilter,
    Donor,
    EventName,
    SearchResult,
    SearchResultMap,
    UserProfile,
} from '../Utils/types';
import {
    logFirebaseEvent as _logFirebaseEvent,
} from '../Redux/ApplicationActions';
import {
    pinChatroom as _pinChatroom,
    unpinChatroom as _unpinChatroom,
} from '../Redux/ChatListActions';
import {
    getDonor as _getDonor,
} from '../Redux/DonorActions';
import hasValue from '../Utils/hasValue';
import SearchResultItem from './SearchResultItem';
import { keys } from '../Utils/Object';
import type { GetDonor } from '../Redux/DonorActions';

const INITIAL_CHATLIST_SIZE = 50;
const CHATLIST_SIZE_INCREMENT = 20;

type Props = {
    rooms: Array<ChatroomInfo>,
    profile: UserProfile,
    chatroomSort: string,
    chatroomFilter: ChatroomFilter,
    buttonVisible: boolean,
    currentChatId: number,
    chatSelected: boolean,
    filter: string,
    deepSearchFilter: string,
    isEmailVerified: boolean,
    emailUpdate: ?string,
    loadingApplicationData: boolean,
    loadingSearch: boolean,
    searchResults: ?SearchResultMap,
    roomObj: ChatroomInfoMap,
    isConnected: boolean,
    selectedTab: string,
    donors: Array<Donor>,

    pinChatroom: (chatId: number) => *,
    unpinChatroom: (chatId: number) => *,
    openChatroom: (chatId: number) => void,
    clearChatroomNotifications: (chatId: number) => *,
    filterChatrooms: (input: string) => *,
    emailActivationDialog: (visible: boolean) => *,
    updateProfile: (user: UserProfile) => *,
    goToMessage: (chatId: number, selectedResult: SearchResult) => *,
    logFirebaseEvent: (name: EventName) => *,
    getDonor: (donorId: number) => GetDonor,
};

type State = {
    sortValue: string,
    currentDisplaySize: number,
    filterValue: ChatroomFilter
};

class ChatList extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            sortValue: this.props.chatroomSort || 'DEFAULT',
            currentDisplaySize: INITIAL_CHATLIST_SIZE,
            filterValue: this.props.chatroomFilter || { status: 'Closed', chatType: 'All', },
        };
    }

    onOpenChatroom = (chatId: number) => {
        const {
            openChatroom,
            getDonor,
            isEmailVerified,
            emailUpdate,
            emailActivationDialog,
            roomObj,
            donors,
        } = this.props;

        if (!isEmailVerified || emailUpdate !== null) {
            emailActivationDialog(true);
            return;
        }

        const chat = roomObj[`${chatId}`];
        if (chat && (chat.chatroomType === 'Main Donor' || chat.chatroomType === 'Follower Group') && chat.donorId && !donors[chat.donorId]) {
            getDonor(chat.donorId);
        }
        openChatroom(chatId);
    };

    onSelectSort = (sort: ChatroomSort) => {
        const {
            profile,
            updateProfile,
        } = this.props;

        const {
            sortValue,
        } = this.state;

        // don't make updateProfile call if they chose the same sort
        if (sort !== sortValue) {
            const newProfile = profile;
            profile.chatroomSort = sort;
            updateProfile(newProfile);

            this.setState({
                sortValue: sort,
            });
        }
    };

    onSelectStatusFilter = (statusFilter: string) => {
        const {
            profile,
            updateProfile,
        } = this.props;

        const {
            filterValue,
        } = this.state;

        // don't make updateProfile call if they chose the same sort
        if (statusFilter !== filterValue.status) {
            const newChatFilter = { status: statusFilter, chatType: filterValue.chatType, };
            const newProfile = profile;
            profile.chatroomFilter = newChatFilter;
            updateProfile(newProfile);

            this.setState({
                filterValue: newChatFilter,
            });
        }
    };

    onPinChatroom = (chatId: number) => {
        const {
            pinChatroom,
        } = this.props;

        pinChatroom(chatId);
    };

    onUnpinChatroom = (chatId: number) => {
        const {
            unpinChatroom,
        } = this.props;

        unpinChatroom(chatId);
    };

    onSortMenuClick = (event: any) => {
        const {
            logFirebaseEvent,
        } = this.props;

        event.stopPropagation();

        ReactTooltip.hide();

        if (event.target.innerText === 'sort') {
            logFirebaseEvent('sort-menu-click');
        }
    };

    onFilterMenuClick = (event: any) => {
        const {
            logFirebaseEvent,
        } = this.props;

        event.stopPropagation();

        ReactTooltip.hide();

        if (event.target.innerText === 'filter_list') {
            logFirebaseEvent('filter-menu-click');
        }
    };

    getFilteredChatItems = () => {
        const {
            filter,
            rooms,
        } = this.props;

        const {
            filterValue,
        } = this.state;

        const pinnedRooms = [];
        const unPinnedRooms = [];

        rooms.forEach((room: ChatroomInfo) => {
            if (room) {
                if (hasValue(room.pinNumber)) {
                    pinnedRooms.push(room);
                } else if (
                    (filterValue.status === 'Closed' && room.donorClosed !== true)
                    || filterValue.status === 'All'
                    || (filterValue.status === 'Case' && ['Templated Case', 'Case'].includes(room.chatroomType))) {
                    unPinnedRooms.push(room);
                }
            }
        });

        if (pinnedRooms.length > 0) {
            pinnedRooms.sort((a, b) => (a.pinNumber && b.pinNumber ? a.pinNumber - b.pinNumber : 0));
        }

        const pinnedItems = filter ? pinnedRooms.filter(
            sift({
                $where() {
                    return this.name.toUpperCase().includes(filter.toUpperCase())
                        || this.description.toUpperCase().includes(filter.toUpperCase());
                },
            })
        ) : pinnedRooms;

        const unpinnedItems = filter ? unPinnedRooms.filter(
            sift({
                $where() {
                    return this.name.toUpperCase().includes(filter.toUpperCase())
                        || this.description.toUpperCase().includes(filter.toUpperCase());
                },
            })
        ) : unPinnedRooms;

        const allFilteredItems = [...pinnedItems, ...unpinnedItems];

        return allFilteredItems;
    }

    increaseChatItemDisplayCount = () => {
        const {
            currentDisplaySize,
        } = this.state;

        this.setState({
            currentDisplaySize: currentDisplaySize + CHATLIST_SIZE_INCREMENT,
        });
    }

    renderChatSearch() {
        const {
            searchResults,
            filter,
            filterChatrooms,
        } = this.props;

        const {
            sortValue,
            filterValue,
        } = this.state;

        let resultItems: ?SearchResultMap = {};

        if (searchResults === null) {
            resultItems = null;
        } else if (searchResults && keys(searchResults).length > 0 && filter !== '') {
            resultItems = searchResults;
        }

        const resultItemsLength = resultItems ? keys(resultItems).length : 0;

        const showSearchGuide = filter !== '';
        const enterToSearchMessage = filter !== '' && (resultItemsLength === 0 && resultItems !== null);
        const clearSearchMessage = filter !== '' && ((resultItemsLength === 0 && resultItems === null) || resultItemsLength > 0);

        let searchMessage = '';
        if (enterToSearchMessage && filter.length < 3) {
            searchMessage = 'Filtering by room name.';
        } else if (enterToSearchMessage && filter.length > 2) {
            searchMessage = 'Filtering by room name. Press enter to search the message content.';
        } else if (clearSearchMessage) {
            searchMessage = 'Clear the search field to return to the room list.';
        }

        const sortOptions = [
            {
                value: 'DEFAULT',
                label: 'Most recently active',
            },
            {
                value: 'ALPHABETICAL',
                label: 'Alphabetical',
            },
            {
                value: 'BADGE_COUNT',
                label: 'Most unread messages',
            },
            {
                value: 'MY_ACTIVE',
                label: 'My recent rooms',
            },
            {
                value: 'CREATE_DATE',
                label: 'Most recently created',
            }
        ];

        const statusFilter = [
            {
                value: 'All',
                label: 'Show All Rooms',
            },
            {
                value: 'Case',
                label: 'Show Case Rooms',
            },
            {
                value: 'Closed',
                label: 'Hide Closed Cases',
            }
        ];

        return (
            <div style={ChatListStyles.searchBlock}>
                <div style={ChatListStyles.searchRowWithSort}>
                    <Search
                        placeholder="Search rooms..."
                        sort
                        sortOptons={sortOptions}
                        sortValue={sortValue}
                        value={filter}
                        deepSearch
                        filterContent={filterChatrooms}
                    />
                    <div style={ChatListStyles.sortContainer}>
                        <IconMenu
                            icon="sort"
                            position="topRight"
                            menuRipple={false}
                            onClick={(event) => this.onSortMenuClick(event)}
                            data-tip="Sort rooms"
                            data-delay-show="250"
                            data-effect="solid"
                            data-place="left"
                        >
                            <MenuItem
                                caption="Sort rooms"
                                disabled
                            />
                            <MenuDivider />
                            {
                                sortOptions.map((option: any) => (
                                    <MenuItem
                                        key={option.value}
                                        style={sortValue === option.value ? ChatListStyles.selectedSort : null}
                                        caption={option.label}
                                        ripple={false}
                                        onClick={() => this.onSelectSort(option.value)}
                                    />
                                ))
                            }
                        </IconMenu>
                        <IconMenu
                            icon="filter_list"
                            position="topRight"
                            menuRipple={false}
                            onClick={(event) => this.onFilterMenuClick(event)}
                            data-tip="Filter rooms"
                            data-delay-show="250"
                            data-effect="solid"
                            data-place="left"
                        >
                            <MenuItem
                                caption="Filter rooms"
                                disabled
                            />
                            <MenuDivider />
                            {
                                statusFilter.map((option: any) => (
                                    <MenuItem
                                        key={option.value}
                                        style={filterValue.status === option.value ? ChatListStyles.selectedSort : null}
                                        caption={option.label}
                                        ripple={false}
                                        onClick={() => this.onSelectStatusFilter(option.value)}
                                    />
                                ))
                            }
                        </IconMenu>
                    </div>
                </div>
                <Fade collapse when={showSearchGuide}>
                    <span
                        style={ChatListStyles.searchGuideText}
                    >
                        {searchMessage}
                    </span>
                </Fade>
            </div>
        );
    }

    renderDeepSearchResults() {
        const {
            searchResults,
            roomObj,
            filter,
            deepSearchFilter,
            goToMessage,
        } = this.props;

        let resultItems: SearchResultMap = {};

        if (searchResults && keys(searchResults).length > 0 && filter !== '') {
            resultItems = searchResults;
        }
        return (
            <div>
                {this.renderChatSearch()}
                {
                    keys(resultItems).map((roomId: string) => (
                        <SearchResultItem
                            key={roomId}
                            room={roomObj[roomId]}
                            result={resultItems ? resultItems[roomId] : []}
                            deepSearchFilter={deepSearchFilter}
                            goToMessage={goToMessage}
                        />
                    ))
                }
            </div>
        );
    }

    renderChats() {
        const {
            rooms,
            filter,
            currentChatId,
            chatSelected,
            buttonVisible,
            searchResults,
            isConnected,
            selectedTab,
            clearChatroomNotifications,
        } = this.props;

        const {
            currentDisplaySize,
        } = this.state;

        const filteredItems = this.getFilteredChatItems();

        let resultItems: ?SearchResultMap = {};

        if (searchResults === null) {
            resultItems = null;
        } else if (searchResults && keys(searchResults).length > 0 && filter !== '') {
            resultItems = searchResults;
        }

        const resultItemsLength = resultItems ? keys(resultItems).length : 0;
        const deepSearchResults = resultItems && resultItemsLength > 0;
        const noSiftResults = filteredItems.length === 0 && rooms.length !== 0 && resultItems && resultItemsLength === 0;

        if (deepSearchResults && resultItems) {
            return this.renderDeepSearchResults();
        }

        return (
            <div>
                {this.renderChatSearch()}
                <InfiniteScroll
                    loader={<LoadingIndicator />}
                    next={this.increaseChatItemDisplayCount}
                    hasMore={filteredItems.length > currentDisplaySize}
                    dataLength={Math.min(currentDisplaySize, filteredItems.length)}
                    scrollableTarget="chatlist-container"
                    style={{ overflowY: 'hidden', }}
                >
                    {filteredItems.slice(0, currentDisplaySize).map((room: ChatroomInfo) => (room && room.id
                        ? (
                            <ChatListItem
                                room={room}
                                key={room.id}
                                currentChatId={currentChatId}
                                chatSelected={chatSelected}
                                buttonVisibility={buttonVisible}
                                clearChatroomNotifications={clearChatroomNotifications}
                                isConnected={isConnected}
                                currentTab={selectedTab}
                                openChatroom={this.onOpenChatroom}
                                pinChatroom={this.onPinChatroom}
                                unpinChatroom={this.onUnpinChatroom}
                            />
                        ) : null))}
                    {noSiftResults
                        ? (
                            <span style={ChatListStyles.noChatroomsFound}>
                                No rooms found matching the name or description.
                                <br />
                                <br />
                                Press enter to search the message content.
                            </span>
                        ) : <div />}
                </InfiniteScroll>
            </div>
        );
    }

    render() {
        const {
            loadingApplicationData,
            loadingSearch,
        } = this.props;

        return (
            <div id="chatlist-container" style={{ ...ChatListStyles.wrapper, ...ChatListStyles.borderRight, }}>
                {loadingApplicationData
                    ? (
                        <div style={ChatListStyles.loadingIndicator}>
                            <LoadingIndicator />
                        </div>
                    ) : (
                        <div>
                            <ReactTooltip />
                            <div>
                                {loadingSearch
                                    ? (
                                        <div style={ChatListStyles.loadingIndicator}>
                                            <LoadingIndicator />
                                        </div>
                                    ) : this.renderChats()}
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile, } = state.auth;

    return {
        profile,
        isEmailVerified: profile.isEmailVerified,
        emailUpdate: (profile || {}).emailUpdate || null,
        chatroomSort: profile.chatroomSort,
        loadingApplicationData: state.loading.applicationData,
        loadingSearch: state.loading.search,
        searchResults: state.chatList.searchResults,
        roomObj: state.chatList.chats,
        isConnected: state.chatList.socketStatus === 'connected',
        selectedTab: state.application.selectedTab,
        donors: state.donor.donors,
    };
};
export default connect(mapStateToProps, {
    emailActivationDialog: _emailActivationDialog,
    updateProfile: _updateProfile,
    pinChatroom: _pinChatroom,
    unpinChatroom: _unpinChatroom,
    logFirebaseEvent: _logFirebaseEvent,
    getDonor: _getDonor,
})(ChatList);
