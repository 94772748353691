// @flow
import runes from 'runes';

import isString from './isString';

// import { ChatroomInfo } from './types';

const truncate = (value: *, limit: number, stripNewLines: boolean = true): string => {
    let innerValue = value;

    if (innerValue && stripNewLines) {
        innerValue = innerValue.replace(/\r\n/g, '\n');

        const newLineIndex = innerValue.indexOf('\n');
        if (newLineIndex !== -1) {
            innerValue = innerValue.substring(0, newLineIndex);
        }
    }

    if (!innerValue || innerValue.length < limit) {
        return `${innerValue}`;
    }

    const truncatedString = runes.substr((!isString(innerValue) ? `${innerValue}` : innerValue), 0, limit - 3);
    return `${truncatedString}...`;
};

export const basePath = (text: string) => text.split('\\').pop().split('/').pop();

export const displayRoomType = (roomType: string) => {
    if (roomType === 'Main Donor') return 'Case Room';
    return roomType;
};

// export const transformRoomName = (room: any) => {
//     let newName = room.name;

//     if (room.chatroomType === 'Main Donor') {
//         newName = room.name.replace('Donor: ', 'Case: ');
//     }

//     // TODO: Find a way to grab case name for donorId in FollowerGroup - donor state isn't available initially
//     // if (room.chatroomType === 'Follower Group') {
//     //     const groupName = room.name.replace('Follower: ', '');
//     // }

//     return newName;
// };

export const transformCaseName = (name: string) => {
    if (name) {
        return name.replace(/^Donor: /, 'Case: ');
    }
    return name;
};

export default truncate;
