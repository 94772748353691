// @flow
import Colors from '../../Themes/Colors';

export const mentionProfileImageSize = 32;

const MentionStyles = {
    wrapper: {
        backgroundColor: Colors.white,
        zIndex: 100,
        width: '25vw',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderTopColor: Colors.chatListItemBorder,
        borderTopWidth: '2px',
        borderTopStyle: 'solid',
        borderLeftColor: Colors.chatListItemBorder,
        borderLeftWidth: '2px',
        borderLeftStyle: 'solid',
        borderRightColor: Colors.chatListItemBorder,
        borderRightWidth: '2px',
        borderRightStyle: 'solid',
        bottom: 0,
        left: '25vw',
        marginLeft: '15px',
        overflowY: 'scroll',
        maxHeight: '300px',
        outline: 'none',
    },
    padding: {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 10,
        paddingRight: 10,
    },
    memberNames: {
        fontSize: 14,
        color: Colors.blue,
        paddingTop: 2,
    },
    orgWrapper: {
        display: 'block',
    },
    memberOrg: {
        fontSize: 14,
        color: Colors.lightGray,
        paddingBottom: 2,
    },
    profileImageWrapper: {
        display: 'inline-block',
        marginLeft: 5,
        marginRight: 10,
        zIndex: -1,
        height: mentionProfileImageSize,
        width: mentionProfileImageSize,
        padding: '2px',
        borderRadius: mentionProfileImageSize / 1.5,
        background: 'linear-gradient(to right, #2E3192, #00A79D)',
    },
    profileImage: {
        backgroundColor: Colors.white,
        height: mentionProfileImageSize,
        width: mentionProfileImageSize,
        borderRadius: mentionProfileImageSize / 2,
    },
    profileWrapper: {
        display: 'inline-block',
    },
    mentionedNamesContainer: {
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,
        borderTopColor: Colors.chatListItemBorder,
        borderTopWidth: '2px',
        borderTopStyle: 'solid',
        borderLeftColor: Colors.chatListItemBorder,
        borderLeftWidth: '2px',
        borderLeftStyle: 'solid',
        borderRightColor: Colors.chatListItemBorder,
        borderRightWidth: '2px',
        borderRightStyle: 'solid',
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        marginRight: 15,
        padding: 10,
        maxHeight: '300px',
        overflowY: 'scroll',
        position: 'absolute',
    },
    mentionedNames: {
        color: Colors.blue,
        fontSize: 14,
    },
    mentionedNamesLabel: {
        color: Colors.lightGray,
        fontSize: 14,
    },
};

export default MentionStyles;
