// @flow
import Colors from '../../Themes/Colors';

const AddPeopleStyles = {
    rightButton: {
        outline: 'none',
        background: 'none',
        border: 'none',
        height: 'auto',
        textAlign: 'center',
        display: 'inline-block',
        float: 'right',
        cursor: 'pointer',
        fontSize: 16,
        color: Colors.blue,
    },
    rightButtonDisabled: {
        outline: 'none',
        background: 'none',
        border: 'none',
        height: 'auto',
        textAlign: 'center',
        display: 'inline-block',
        float: 'right',
        cursor: 'default',
        fontSize: 16,
        color: Colors.blue,
    },
    leftButton: {
        outline: 'none',
        background: 'none',
        border: 'none',
        height: 'auto',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'left',
        cursor: 'pointer',
        color: Colors.blue,
    },
    addExternalButton: {
        outline: 'none',
        background: 'none',
        border: 'none',
        height: 'auto',
        textAlign: 'center',
        display: 'flex',
        float: 'right',
        cursor: 'pointer',
        fontSize: 16,
        color: Colors.blue,
    },
    addExternalButtonDisabled: {
        outline: 'none',
        background: 'none',
        border: 'none',
        height: 'auto',
        textAlign: 'center',
        display: 'flex',
        float: 'right',
        cursor: 'default',
        fontSize: 16,
        color: Colors.blue,
    },
    cancelButton: {
        background: 'none',
        outline: 'none',
        border: 'none',
        color: Colors.red,
        fontSize: 16,
        cursor: 'pointer',
        marginBottom: 10,
    },
    confirmButton: {
        background: 'none',
        outline: 'none',
        border: 'none',
        color: Colors.blue,
        fontSize: 16,
        cursor: 'pointer',
        marginLeft: 10,
        marginBottom: 10,
    },
    confirmButtonDisabled: {
        background: 'none',
        outline: 'none',
        border: 'none',
        color: Colors.buttonTextDisabled,
        fontSize: 16,
        cursor: 'pointer',
        marginLeft: 10,
        marginBottom: 10,
    },
    informationRight: {
        color: Colors.lightGray,
        fontStyle: 'italic',
        marginRight: 20,
    },
    informationLeft: {
        textAlign: 'left',
        color: Colors.lightGray,
        fontStyle: 'italic',
    },
    titleText: {
        fontSize: 16,
        color: Colors.black,
        textAlign: 'center',
    },
    infoText: {
        fontSize: 14,
        textAlign: 'center',
        marginTop: 20,
    },
    inputWrapper: {
        paddingBottom: 10,
        textAlign: 'center',
    },
    textInput: {
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.lightGray,
        borderBottomStyle: 'solid',
        fontSize: 16,
        width: '85%',
        paddingBottom: 3,
        lineHeight: 2,
    },
    textInputEmail: {
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.lightGray,
        borderBottomStyle: 'solid',
        fontSize: 16,
        width: '85%',
        paddingBottom: 3,
        lineHeight: 2,
        marginLeft: 20,
    },
    textInputPhone: {
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        // borderBottomWidth: '2px',
        // borderBottomColor: Colors.lightGray,
        // borderBottomStyle: 'solid',
        fontSize: 16,
        width: '90%',
        paddingBottom: 3,
        lineHeight: 2,
        marginLeft: 20,
    },
    phoneCode: {
        color: Colors.black,
        fontSize: 16,
        paddingBottom: 3,
    },
    informationRightWrapper: {
        bottom: 10,
        right: 0,
        position: 'sticky',
        backgroundColor: Colors.white,
    },
    addNumPeople: {
        marginRight: 10,
        float: 'right',
        outline: 'none',
        background: 'none',
        height: 'auto',
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: 16,
        color: Colors.blue,

        alignItems: 'center',
        display: 'flex',
        opacity: 0.8,
        backgroundColor: Colors.white,
        borderRadius: 8,
        padding: 6,
        border: '2px solid #e6e6e6',
    },
    addNumPeopleDisabled: {
        marginRight: 10,
        float: 'right',
        outline: 'none',
        background: 'none',
        height: 'auto',
        textAlign: 'center',
        cursor: 'default',
        fontSize: 16,
        color: Colors.linkTextDisabled,

        alignItems: 'center',
        display: 'flex',
        opacity: 0.8,
        backgroundColor: Colors.white,
        borderRadius: 8,
        padding: 6,
        border: '2px solid #e6e6e6',
    },
    addNumPeopleContainer: {
        position: 'absolute',
        right: 0,
        bottom: 10,
    },
    addBy: {
        color: Colors.blue,
        fontSize: 18,
        textTransform: 'none',
        marginRight: 10,
        marginTop: 10,
    },
    invite: {
        color: Colors.blue,
        backgroundColor: Colors.white,
        fontSize: 16,
        textTransform: 'none',
    },
    inviteInverse: {
        color: Colors.white,
        backgroundColor: Colors.blue,
        fontSize: 16,
        textTransform: 'none',
    },
    whatButton: {
        color: Colors.blue,
        backgroundColor: Colors.white,
        fontSize: 12,
        outline: 'none',
        textTransform: 'none',
        marginTop: 15,
    },
    headerTextContainer: {
        width: '100%',
    },
    headerTextContainerShortWidth: {
        width: '75%',
    },
    headerText: {
        fontSize: 24,
        color: Colors.black,
        textAlign: 'center',
    },
    header: {
        backgroundColor: Colors.white,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
    headerBorders: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
    },
    fixedSearchCreateChatroom: {
        position: 'sticky',
        top: 0,
    },
    fixedSearchAddPeople: {
        position: 'sticky',
        top: '100px',
    },
    marginFiveTop: {
        marginTop: 5,
    },
    differenctFontSize: {
        fontSize: 12,
    },
    differenceLabelContainer: {
        marginTop: 5,
    },
    differenceBulletContainer: {
        marginTop: 4,
    },
    differenceBulletText: {
        marginLeft: 5,
    },
    addButton: {
        marginBottom: 15,
    },
    createChatroomPeopleListContainer: {
        overflowY: 'scroll',
        width: '75%',
        display: 'inline-flex',
        flexDirection: 'column',
        height: '45vh',
    },
    selectedUsersArea: {
        color: Colors.gray,
        backgroundColor: Colors.white,
        textAlign: 'left',
        padding: 10,
        position: 'sticky',
        bottom: 0,
        left: 2, // don't cover up 2px border
        right: 0,
        minHeight: '65px',
        borderTopWidth: '2px',
        borderTopColor: Colors.chatListItemBorder,
        borderTopStyle: 'solid',
    },
    createChatroomSelectedUsersArea: {
        color: Colors.gray,
        backgroundColor: Colors.white,
        textAlign: 'left',
        padding: 10,
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'block',
        minHeight: '25px',
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderTopWidth: '2px',
        borderTopColor: Colors.chatListItemBorder,
        borderTopStyle: 'solid',
        flex: 2,
    },
    userArea: {
        color: Colors.gray,
        backgroundColor: Colors.lighterGray,
        textAlign: 'left',
        padding: 20,
        paddingBottom: 20,
        overflow: 'visible',
    },
    createChatroomUserAreaFlex: {
        flex: 8,
    },
};

export default AddPeopleStyles;
