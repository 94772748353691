// @flow
import React, { PureComponent } from 'react';

import type { ChatroomMessage } from '../Utils/types';
import ChatMessageStyles from './Styles/ChatMessageStyles';
import Images from '../Themes/Images';
import {
    AskAlanMessageTypes,
    ImageMessageTypes,
    MessageTypes,
    SystemMessageTypes,
} from '../Utils/types';

type Props = {
    memberId: number,
    message: ChatroomMessage,
    disabled: boolean,

    sendAck: (emoji: any) => *,
    showMessageDetails: () => *,
    reply: () => *,
    redact: () => *,
    unredact: () => *,
    hideMessageMenu: () => *,
    deleteFile: () => *,
};

class MessageMenu extends PureComponent<Props> {
    nop = () => {};

    render() {
        const {
            memberId,
            message,
            disabled,
            sendAck,
            showMessageDetails,
            reply,
            redact,
            unredact,
            deleteFile,
            hideMessageMenu,
        } = this.props;

        let canSendAck = true;
        const currentAck = message && message.ack && message.ack[memberId.toString()] ? message.ack[memberId.toString()].symbol : null;
        const options = ['0x1F44D', '0x1F44E', '0x1F601', '0x1F499', '0x2753', '0x2757'];
        const sender = message && message.senderId ? message.senderId === memberId : false;
        const messageType = message && message.messageType ? message.messageType : 1;

        let renderType = 'text';
        if (ImageMessageTypes.indexOf(messageType) !== -1) renderType = 'image';
        if (messageType === MessageTypes.mp4 || messageType === MessageTypes.mov) renderType = 'video';
        if (messageType === MessageTypes.pdf) renderType = 'pdf';
        if (message.dataContent && AskAlanMessageTypes.includes(message.messageType)) renderType = 'donor';
        if (SystemMessageTypes.indexOf(messageType) !== -1) renderType = 'system';
        if (AskAlanMessageTypes.indexOf(messageType) !== -1) renderType = 'askAlan';
        if (messageType === MessageTypes.donorTask) renderType = 'donorTask';
        if (messageType === MessageTypes.donorTaskExpiration) renderType = 'donorTaskExpiration';
        if (messageType === MessageTypes.openCloseDonor) renderType = 'openCloseDonor';

        const canRedact = renderType === 'text' || renderType === 'image' || renderType === 'video' || renderType === 'pdf';

        const messageTimestamp = new Date(message.sentTime);
        const currentTime = new Date();
        const timeDifferenceInMinutes = (currentTime.getTime() - messageTimestamp.getTime()) / (1000 * 60);

        const canDeleteFile = (renderType === 'image' || renderType === 'video' || renderType === 'pdf') && timeDifferenceInMinutes <= 15 && sender;
        const canReply = !message.redactTime;

        const menuOptionContainerStyle = disabled
            ? ChatMessageStyles.messageMenuOptionContainerDisabled : ChatMessageStyles.messageMenuOptionContainer;
        const menuOptionTextStyle = disabled
            ? ChatMessageStyles.messageMenuOptionTextDisabled : ChatMessageStyles.messageMenuOptionText;
        const emojiButtonStyle = disabled
            ? ChatMessageStyles.emojiButtonDisabled : ChatMessageStyles.emojiButton;

        let redactOption = null;
        if (canRedact) {
            if (sender) {
                if (message.redactTime) {
                    canSendAck = false;
                    redactOption = (
                        <div
                            className="messageMenuOption"
                            role="button"
                            tabIndex={0}
                            style={menuOptionContainerStyle}
                            onClick={!disabled ? unredact : this.nop}
                            onKeyPress={this.nop}
                        >
                            <span style={menuOptionTextStyle}>Unredact Message</span>
                        </div>
                    );
                } else {
                    redactOption = (
                        <div
                            className="messageMenuOption"
                            role="button"
                            tabIndex={0}
                            style={menuOptionContainerStyle}
                            onClick={!disabled ? redact : this.nop}
                            onKeyPress={this.nop}
                        >
                            <span style={menuOptionTextStyle}>Redact Message</span>
                        </div>
                    );
                }
            }
        }

        const replyOption = canReply ? (
            <div
                className="messageMenuOption"
                role="button"
                tabIndex={0}
                style={menuOptionContainerStyle}
                onClick={!disabled ? reply : this.nop}
                onKeyPress={this.nop}
            >
                <span style={menuOptionTextStyle}>Reply</span>
            </div>
        ) : null;

        const messageDetailsOption = (
            <div
                className="messageMenuOption"
                role="button"
                tabIndex={0}
                style={menuOptionContainerStyle}
                onClick={!disabled ? showMessageDetails : this.nop}
                onKeyPress={this.nop}
            >
                <span style={menuOptionTextStyle}>Message Details</span>
            </div>
        );

        const ackOptions = canSendAck ? (
            <div style={ChatMessageStyles.emojiContainer}>
                <button
                    className={currentAck === options[0] ? 'emojiButtonSelected' : 'emojiButton'}
                    style={emojiButtonStyle}
                    type="button"
                    value="&#128077;"
                    onClick={!disabled ? sendAck : () => {}}
                >
                    &#128077;
                </button>
                <button
                    className={currentAck === options[1] ? 'emojiButtonSelected' : 'emojiButton'}
                    style={emojiButtonStyle}
                    type="button"
                    value="&#128078;"
                    onClick={!disabled ? sendAck : () => {}}
                >
                    &#128078;
                </button>
                <button
                    className={currentAck === options[2] ? 'emojiButtonSelected' : 'emojiButton'}
                    style={emojiButtonStyle}
                    type="button"
                    value="&#128513;"
                    onClick={!disabled ? sendAck : () => {}}
                >
                    &#128513;
                </button>
                <button
                    className={currentAck === options[3] ? 'emojiButtonSelected' : 'emojiButton'}
                    style={emojiButtonStyle}
                    type="button"
                    value="&#128153;"
                    onClick={!disabled ? sendAck : () => {}}
                >
                    &#128153;
                </button>
                <button
                    className={currentAck === options[4] ? 'emojiButtonSelected' : 'emojiButton'}
                    style={emojiButtonStyle}
                    type="button"
                    value="&#10067;"
                    onClick={!disabled ? sendAck : () => {}}
                >
                    &#10067;
                </button>
                <button
                    className={currentAck === options[5] ? 'emojiButtonSelected' : 'emojiButton'}
                    style={emojiButtonStyle}
                    type="button"
                    value="&#10071;"
                    onClick={!disabled ? sendAck : () => {}}
                >
                    &#10071;
                </button>
                <span style={ChatMessageStyles.divider}>|</span>
                <button
                    className={currentAck === options[6] ? 'emojiButtonSelected' : 'emojiButton'}
                    style={{ ...ChatMessageStyles.emojiButton, ...{ marginLeft: 5, }, }}
                    type="button"
                    onClick={hideMessageMenu}
                >
                    <img style={{ width: 16, height: 16, }} src={Images.close} alt="" />
                </button>
            </div>
        ) : null;

        let deleteFileOption = null;
        if (canDeleteFile) {
            if (!message.redactTime) {
                deleteFileOption = (
                    <div
                        className="messageMenuOption"
                        role="button"
                        tabIndex={0}
                        style={menuOptionContainerStyle}
                        onClick={!disabled ? deleteFile : this.nop}
                        onKeyPress={this.nop}
                    >
                        <span style={menuOptionTextStyle}>Delete File</span>
                    </div>
                );
            }
        }

        return (
            <div style={ChatMessageStyles.messageMenuContainer}>
                {ackOptions}
                {messageDetailsOption}
                {redactOption}
                {deleteFileOption}
                {replyOption}
            </div>
        );
    }
}

export default MessageMenu;
