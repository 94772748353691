// @flow
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment as faCommentSolid, faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';

import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import Colors from '../Themes/Colors';

type Props = {
    organ: {
        id: string,
        value: string,
    },
    selected: boolean,
    saved: boolean,
    hasChat: boolean,
    disabled: boolean,
    noChat: boolean,

    selectOrgan: (organName: string) => *,
    createChat: (organName: string) => *,
};

class OrganItem extends PureComponent<Props> {
    render() {
        const {
            organ: {
                value,
            },
            selected,
            saved,
            hasChat,
            disabled,
            selectOrgan,
            createChat,
            noChat,
        } = this.props;

        return (
            <div>
                <div style={ChatroomDetailsStyles.organItemRow}>
                    <button
                        className="refreshButton"
                        type="button"
                        tabIndex="0"
                        style={{ float: 'left', paddingLeft: 5, }}
                        onClick={() => selectOrgan(value)}
                    >
                        <FontAwesomeIcon
                            color={disabled ? Colors.linkTextDisabled : Colors.blue}
                            icon={selected ? faCheckCircle : faPlus}
                            size="2x"
                        />
                    </button>
                    <span>
                        {value}
                    </span>
                    { !noChat ? (
                        <button
                            className="refreshButton"
                            type="button"
                            tabIndex="0"
                            style={{ float: 'right', paddingRight: 25, }}
                            onClick={() => createChat(value)}
                        >
                            <FontAwesomeIcon
                                color={saved && !disabled ? Colors.blue : Colors.linkTextDisabled}
                                icon={hasChat ? faCommentSolid : faComment}
                                size="2x"
                            />
                        </button>
                    ) : null}
                </div>
                <div style={ChatroomDetailsStyles.border} />
            </div>
        );
    }
}

export default OrganItem;
