// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import UserDetailsStyles from './Styles/UserDetailsStyles';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import CasePermissionsStyles from './Styles/CasePermissionsStyles';
import FollowerGroupStyles from './Styles/FollowerGroupStyles';
import Colors from '../Themes/Colors';

type Props = {
    canUpdate: boolean,
    isDisabled: boolean,
    followerGroup: any,
    onFollowerGroupClick: (followerGroupId: number) => *,
    onDeleteFollowerGroup: (followerGroupId: number) => *,
    onJoinFollowerGroup: (followerGroupId: number) => *,
};

export default function FollowerExternalMemberItem(props: Props) {
    const {
        canUpdate,
        isDisabled,
        followerGroup,
        onFollowerGroupClick,
        onDeleteFollowerGroup,
        onJoinFollowerGroup,
    } = props;

    const [joinTextHovered, setJoinTextHovered] = React.useState(false);
    const profile = useSelector((state) => state.auth.profile);

    const isUserInFollowerGroup = followerGroup.memberUsers.some((user) => user.userId === profile.userId)
        || followerGroup.externalUsers.some((user) => user.userId === profile.userId);

    const deleteFollowerGroupHandler = (event: any) => {
        onDeleteFollowerGroup(followerGroup.followerGroupId);
        event.stopPropagation();
    };

    const joinFollowerGroupHandler = (event: any) => {
        onJoinFollowerGroup(followerGroup.followerGroupId);
        event.stopPropagation();
    };

    const followerGroupClickHandler = () => {
        if (canUpdate) {
            onFollowerGroupClick(followerGroup.followerGroupId);
        }
    };

    const renderDeleteButton = () => (
        <button
            style={isDisabled ? UserDetailsStyles.memberButtonDisabled : UserDetailsStyles.memberButton}
            type="button"
            disabled={isDisabled}
            onClick={deleteFollowerGroupHandler}
            data-tip="Remove follower"
            data-delay-show="500"
            data-effect="solid"
        >
            <FontAwesomeIcon
                fixedWidth
                color={Colors.lightGray}
                icon={faTimesCircle}
                size="lg"
            />
        </button>
    );

    const renderJoinButton = () => (
        <button
            style={joinTextHovered ? CasePermissionsStyles.groupJoinButtonTextHover : CasePermissionsStyles.groupJoinButtonText}
            type="button"
            onClick={joinFollowerGroupHandler}
            data-tip={isUserInFollowerGroup ? 'Leave follower group' : 'Join follower group'}
            data-delay-show="500"
            data-effect="solid"
            disabled={isDisabled}
            onMouseEnter={setButtonHovered}
            onMouseLeave={setButtonHovered}
        >
            {isUserInFollowerGroup ? 'Leave' : 'Join'}
        </button>
    );

    const setButtonHovered = () => {
        setJoinTextHovered(!joinTextHovered);
    };

    return (
        <>
            <div
                style={canUpdate ? CasePermissionsStyles.followerItem : CasePermissionsStyles.followerItemDisabled}
                onClick={followerGroupClickHandler}
                onKeyDown={followerGroupClickHandler}
                role="button"
                tabIndex="0"
            >
                {followerGroup.name}

                {(canUpdate) ? (
                    <span style={FollowerGroupStyles.listItemButtonContainer}>
                        <span style={FollowerGroupStyles.alignCenter}>
                            {renderJoinButton()}
                        </span>
                        <span style={FollowerGroupStyles.alignCenter}>
                            {renderDeleteButton()}
                            <FontAwesomeIcon
                                style={FollowerGroupStyles.iconAlignCenter}
                                color={Colors.blue}
                                icon={faChevronRight}
                                size="xs"
                            />
                        </span>
                    </span>
                ) : null}
            </div>
            <div style={ChatroomDetailsStyles.border} />
        </>
    );
}
