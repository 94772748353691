// @flow
import formIndexMap from '../Redux/Forms/FormIndexMap';
import type {
    Donor,
    DonorTask,
    StaticTask,
    FormValueMap,
    ReferenceWorkflow,
    ReferenceForm,
    Workflow,
    WorkflowAndForms,
} from './types';

export type DonorFormAttributes = {
    donorId: number,
    donor: Donor,
    taskId: number,
    donorTask: DonorTask,
    formId: string,
    workflowId: string,
    staticTask: StaticTask,
    formData: FormValueMap,
};

const defaultEmptyObject = {};

// this reusable code navigates the complexities of forms and workflows to return a commonly used set of data from config
// If donor Id/task Id is provided (TaskItem) then it is used. Otherwise it is presumed the donor panel is open and uses donorTask from state
export const getDonorFormAttributes = (state: any, donorIdArg?: number, taskIdArg?: number = -1): DonorFormAttributes => {
    let donorId;
    let taskId = -1;
    let donorTask = defaultEmptyObject;
    if (donorIdArg) {
        donorId = donorIdArg;
        taskId = taskIdArg;
    } else {
        donorTask = state.donor.donorTask ? state.donor.donorTask : defaultEmptyObject;
        ({ donorId, taskId, } = donorTask);
    }
    const donor = (donorId && donorId !== -1 ? state.donor.donors[donorId] : defaultEmptyObject) || defaultEmptyObject;
    const workflowId = (donor ? donor.workflow : null) || 'LEGACY';

    const staticTasks: Array<StaticTask> = state.donor.workflowTasks && state.donor.workflowTasks[workflowId] && state.donor.workflowTasks[workflowId].tasks
        ? state.donor.workflowTasks[workflowId].tasks : [];

    let staticTask: StaticTask = defaultEmptyObject;
    if (staticTasks && staticTasks.length > 0) {
        staticTask = staticTasks.find((obj) => obj.taskId === taskId) || defaultEmptyObject;
    }

    let formId = '';
    if (staticTask && staticTask.details) {
        formId = staticTask.formId || formIndexMap[staticTask.details.description];
    }

    const taskData = donor && donor.taskData && donor.taskData[taskId] ? donor.taskData[taskId] : {};

    const attributes: DonorFormAttributes = {
        donorId,
        donor,
        taskId,
        donorTask,
        formId,
        workflowId,
        staticTask,
        formData: taskData,
    };

    return attributes;
};

/**
 * Transform a workflow object pulled from the database into a form more usable in the app
 */
export function transformWorkflowReference(workflowRef: ReferenceWorkflow): Workflow {
    const rd = workflowRef.reference_data;
    const { tasks, trackers, } = rd;
    const sections: string[] = tasks.filter((task) => typeof task.section === 'string').map((task) => ((task.section: any): string));
    const sectionOrder = sections.length > 0 ? [...new Set(sections)] : [];

    return {
        key: workflowRef.key,
        name: rd.name || '',
        type: rd.type,
        ownerId: rd.ownerId,
        ownerType: rd.ownerType,
        organizationType: rd.organizationType,
        flat: rd.flat || false,
        progressBar: rd.progressBar || false,
        tasks: tasks.map((task) => ({
            taskId: task.id,
            formId: task.formId,
            details: {
                sectionPosition: null,
                section: task.section || '',
                description: task.description,
                highRisk: task.highRisk ?? false,
            },
        })),
        sectionOrder,
        trackers: trackers.map((tracker) => ({
            id: parseInt(tracker.id, 10),
            title: tracker.title,
        })),
        lastModifiedDate: workflowRef.last_modified_date,
    };
}

/**
 * Transform an object contain a workflow and forms from the database
 * into a more appropriate form for the app
 */
export function transformWorkflowDef(
    { workflow, forms, }: { workflow: ReferenceWorkflow, forms: Array<ReferenceForm>, }
): WorkflowAndForms {
    return {
        workflow: transformWorkflowReference(workflow),
        forms: forms.map((f) => f.reference_data),
    };
}

export const transformFormData = (forms: ReferenceForm[]) => (
    forms.reduce((acc, form) => {
        const { key, reference_data: referenceData, } = form;
        acc[key] = {
            formId: key,
            ...referenceData,
        };
        return acc;
    }, {})
);
