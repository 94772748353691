// @flow
import { Component } from 'react';
import { connect } from 'react-redux';
import devLog, { RECON_STATE } from './logger';

type Props = {
    chatList: any,
    donor: any,
};

class RTStateChecker extends Component<Props> {
    componentDidMount() {
        // Don't kill the app if the reconciliation fails
        try {
            this.reconcileChatListState();
            this.reconcileDonorState();
        } catch (e) {
            devLog(RECON_STATE, ['Reconciliation crashed in CDM', e]);
        }
    }

    componentDidUpdate(prevProps: Props) {
        // Don't kill the app if the reconciliation fails
        const { chatList, donor, } = this.props;
        try {
            if (prevProps.chatList !== chatList) this.reconcileChatListState();
            if (prevProps.donor !== donor) this.reconcileDonorState();
        } catch (e) {
            devLog(RECON_STATE, ['Reconciliation crashed in CDU', e]);
        }
    }

    reconcileDonorState() {
        const { donor, } = this.props;

        // This needs to be very defensive
        if (!donor) {
            devLog(RECON_STATE, ['state.donor is not an object']);
            return;
        }

        // reconcile chat details with chat summary
        const dlKeys = Object.keys(donor.donors);
        for (let idx = 0; idx < dlKeys.length; idx += 1) {
            const thisDonor = donor.donors[dlKeys[idx]];

            if (!thisDonor.organs) {
                devLog(RECON_STATE, ['donor has no organs', { donor, }]);
            }
        }
    }

    reconcileChatListState() {
        const { chatList, } = this.props;
        // devLog(RECON_STATE, ['Checking ChatList State']);

        // This needs to be very defensive
        if (!chatList) {
            devLog(RECON_STATE, ['state.chatList is not an object']);
            return;
        }

        // activeChatId Id must exist in chats and order
        if (chatList.activeChatId
            && (!chatList.chats[chatList.activeChatId])) {
            devLog(RECON_STATE, ['Open Chatroom not in chats object', chatList]);
        }
        if (chatList.activeChatId
            && (!chatList.order.includes(chatList.activeChatId))) {
            devLog(RECON_STATE, ['Open Chatroom not in order array', chatList]);
        }

        // chats and order should be the same size
        if (Object.keys(chatList.chats).length !== chatList.order.length) {
            devLog(RECON_STATE, ['Chats and order arrays are different', chatList]);
        }

        // reconcile chat details with chat summary
        const clKeys = Object.keys(chatList.chats);
        for (let idx = 0; idx < clKeys.length; idx += 1) {
            const chat = chatList.chats[clKeys[idx]];

            // Can only verify once the CR has been loaded (members > 0)
            const memberIds = Object.keys(chat.members);
            if (memberIds.length > 0) {
                // actual members in room match member counts from chatroom details
                const newMemberCount = Object.keys(chat.members).filter((memberId) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    chat.members[memberId].membershipStatus === 'Present').length;

                if (newMemberCount !== chat.memberCount) {
                    devLog(RECON_STATE, ['Member count and # members are different', { chat, }]);
                }
            }

            // Message counts not easy to verify as may not be loaded at all or may not be complete record sets
            // To be revisited
        }
    }

    render() {
        return (
            null
        );
    }
}

const mapStateToProps = (state) => ({
    chatList: state.chatList,
    donor: state.donor,
});

export default connect(mapStateToProps, null)(RTStateChecker);
