// @flow
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { reportError as _reportError } from '../Redux/ApplicationActions';
import type { ReducerError } from '../Redux/ApplicationActions';

type Props = {
    applicationErrors: Array<any>,
    authErrors: Array<any>,
    chatEditErrors: Array<any>,
    chatListErrors: Array<any>,
    chatMessageErrors: Array<any>,
    chatroomErrors: Array<any>,
    helpErrors: Array<any>,
    loadingErrors: Array<any>,

    reportError: (error: ReducerError) => *,
};

class ErrorLogger extends PureComponent<Props> {
    sendFirstError = (errors: Array<any>) => {
        const {
            reportError,
        } = this.props;

        if (errors && errors.length > 0) {
            const thisError = errors[0];
            reportError(thisError);
        }
    };

    render() {
        const {
            applicationErrors,
            authErrors,
            chatEditErrors,
            chatListErrors,
            chatMessageErrors,
            chatroomErrors,
            helpErrors,
            loadingErrors,
        } = this.props;

        // Just send the first error from each stack to the Saga and then to be cleared.
        // Considered doing all but concerned that the clear will update the state causing this to rerun
        // which will then try to clear some of errors being cleared in another render
        this.sendFirstError(applicationErrors);
        this.sendFirstError(authErrors);
        this.sendFirstError(chatEditErrors);
        this.sendFirstError(chatListErrors);
        this.sendFirstError(chatMessageErrors);
        this.sendFirstError(chatroomErrors);
        this.sendFirstError(helpErrors);
        this.sendFirstError(loadingErrors);

        return ('');
    }
}

const mapStateToProps = (state) => ({
    applicationErrors: state.application.reducerErrors,
    authErrors: state.auth.reducerErrors,
    chatEditErrors: state.chatEdit.reducerErrors,
    chatListErrors: state.chatList.reducerErrors,
    chatMessageErrors: state.chatMessage.reducerErrors,
    chatroomErrors: state.chatroom.reducerErrors,
    helpErrors: state.help.reducerErrors,
    loadingErrors: state.loading.reducerErrors,
});
export default connect(mapStateToProps, {
    reportError: _reportError,
})(ErrorLogger);
