/* eslint-disable no-console */
// @flow
import type { SendCommand } from '../Saga/Socket/Commands';

// DEVS: Suppress any logs which are too much by including the type in this array.
// Tried putting this in .env but then it cannot be changed without uninstall/reinstall
// Default is that all logs are written
const excludeTypes = ['#lifecycle', '#socket', '#socket-stress', '#auth',
    '#queue', '#network', '#recon-state', '#recon-msg', '#recon-param'];
export const LIFECYCLE = 'lifecycle';
export const SOCKET = 'socket';
export const SOCKET_STRESS = 'socket-stress';
export const AUTH = 'auth';
export const QUEUE = 'queue';
export const NETWORK = 'network';
export const RECON_STATE = 'recon-state';
export const RECON_MSG = 'recon-msg';
export const RECON_PARM = 'recon-param';

export default function devLog(
    type: 'lifecycle' | 'socket' | 'socket-stress' | 'auth' | 'queue' | 'network' | 'recon-state' | 'recon-msg' | 'recon-param',
    messages: Array<any>
) {
    if (!messages || messages.length === 0) return;
    if (excludeTypes.includes(type)) return;

    if (process.env.NODE_ENV === 'development') {
        let color = 'Black';
        let title = '';
        switch (type) {
            case 'lifecycle': {
                title = 'Application Lifecycle event:';
                color = 'DarkCyan';
                break;
            }
            case 'socket': {
                title = 'Socket event:';
                color = 'DarkGoldenRod';
                break;
            }
            case 'socket-stress': {
                title = 'Socket Stress Event:';
                color = 'Red';
                break;
            }
            case 'auth': {
                title = 'Authentication event:';
                color = 'DarkOrange';
                break;
            }
            case 'queue': {
                title = 'Command Queue event:';
                color = 'Green';
                break;
            }
            case 'network': {
                title = 'Network event:';
                color = 'Blue';
                break;
            }
            case 'recon-state': {
                title = 'STATE RECONCILIATION ERROR:';
                color = 'Red';
                break;
            }
            case 'recon-msg': {
                title = 'MESSAGE RECONCILIATION ERROR:';
                color = 'Red';
                break;
            }
            case 'recon-param': {
                title = 'PARAMETER RECONCILIATION ERROR:';
                color = 'Red';
                break;
            }
            default: {
                //
            }
        }
        if (messages && messages.length === 1) {
            console.log(`%c${title}`, `color: ${color}; font-weight: bold`, messages[0]);
        } else {
            console.group(`%c${title}`, `color: ${color}; font-weight: bold`);
            for (let idx = 0; idx < messages.length; idx += 1) {
                console.log(`%c${idx}`, `color: ${color}; font-weight: bold`, messages[idx]);
            }
            console.groupEnd();
        }
    }
}
export function devLogSocketMessage(action: SendCommand) {
    if (process.env.NODE_ENV === 'development') {
        console.group('Socket Command:', action.command);
        console.log('%cTransaction ID:', 'color: DarkViolet; font-weight: bold', { transactionId: action.transactionId, });
        // TODO - Is there something else we should log?
        // console.log('%cPayload:', 'color:DarkViolet; font-weight: bold', JSON.parse(action.payload));
        console.groupEnd();
    }
}
export function devLogAPICall(resource: any, method: any, requestConfig: any, ...args: any[]) {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-underscore-dangle
        console.group('API CALL:', resource._apiEndpoint);
        console.log('%cRequest:', 'color: DarkViolet; font-weight: bold', { resource, method, });
        console.log('%cArguments:', 'color: DarkViolet; font-weight: bold', ...args);
        console.log('%cRequest Headers:', 'color:DarkViolet; font-weight: bold', requestConfig);
        console.groupEnd();
    }
}

export function devLogAPIResult(resource: any, result: any) {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-underscore-dangle
        console.group('API CALL RESULT:', resource._apiEndpoint);
        console.log('%cResult:', 'color:DarkViolet; font-weight: bold', result);
        console.groupEnd();
    }
}

export function devLogAPIError(resource: any, error: any) {
    if (process.env.NODE_ENV === 'production') {
        // eslint-disable-next-line no-underscore-dangle
        console.group('%cAPI CALL ERROR:', 'color:Red; font-weight: bold', resource._apiEndpoint);
        console.log('%cError:', 'color:DarkViolet; font-weight: bold', error);
        console.groupEnd();
    }
}
