// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Button from 'react-toolbox/lib/button/Button';
import TextArea from 'react-textarea-autosize';
import LoadingIndicator from 'react-loading-indicator';
import { selectProfileName } from 'txp-core';

import { saveCaseNote } from '../Redux/DonorActions';
import { getContacts } from '../Redux/ChatroomActions';
import type {
    CaseNote,
} from '../Utils/types';
import hasPermissions, {
    ENTITY_TYPE_DONOR,
    NONE,
    UPDATE,
} from '../Utils/hasPermissions';
import { formatDateTime } from '../Utils/time';
import { values } from '../Utils/Object';
import styles from './Styles/CaseNotesTimelineStyles';

type Props = {
    caseId: number,
    notes: CaseNote[],
}

function CaseNoteTimeline(props: Props) {
    const { notes, caseId, } = props;
    const dispatch = useDispatch();

    const userPermissions = useSelector((state) => state.permission.permissions) || [];
    const isLoadingNotes = useSelector((state) => state.loading.caseNotes);
    const contactsMap = useSelector((state) => state.chatroom.contactsMap);
    const memberProfile = useSelector((state) => state.auth.profile);

    // values Util returns the values from an Object/Map as an array
    const contactsArray = values(contactsMap);

    // NOTE: we should get contacts when DonorPanel is opened, but in case that stops happening,
    // let's make sure we getContacts there aren't any in the contactsMap
    if (contactsArray.length === 0) {
        dispatch(getContacts());
    }

    const canUpdateDonor = hasPermissions(userPermissions, ENTITY_TYPE_DONOR, caseId, NONE, NONE, UPDATE);

    const [showCreateNote, setShowCreateNote] = useState(false);
    const [noteContent, setNoteContent] = useState('');

    const getNoteAuthor = (userId: number) => {
        let noteUserProfile;
        // Check if the author is the current user
        if (memberProfile.userId === userId) {
            noteUserProfile = memberProfile;
        } else {
            // If note current user, check contacts to the author
            const noteContact = contactsArray.find((contact) => (
                contact.profile.userId === userId
            ));
            if (noteContact) {
                noteUserProfile = noteContact.profile;
            }
        }
        const noteAuthor = noteUserProfile ? selectProfileName(noteUserProfile) : 'OmniLife User';
        return noteAuthor;
    };

    const onCreateNote = () => {
        setShowCreateNote(true);
    };

    const onSaveNote = () => {
        dispatch(saveCaseNote(caseId, noteContent));
        setShowCreateNote(false);
        setNoteContent('');
    };

    const onCancelNote = () => {
        setShowCreateNote(false);
        setNoteContent('');
    };

    return (
        <>
            {canUpdateDonor
                ? (
                    <>
                        <Button
                            icon="add"
                            label="New Note"
                            style={showCreateNote || isLoadingNotes ? styles.disabledButton : styles.addNoteButton}
                            ripple={false}
                            onClick={onCreateNote}
                            disabled={showCreateNote || isLoadingNotes}
                        />
                        {showCreateNote
                            ? (
                                <>
                                    <div style={styles.noteInputWrapper}>
                                        <TextArea
                                            className="noteInput"
                                            placeholder="Type your note..."
                                            autoFocus
                                            onChange={(event) => setNoteContent(event.target.value)}
                                        />
                                    </div>
                                    <div style={styles.caseNoteInfoText}>
                                        Case Notes are visible to all case users. Use caution when entering information in Case Notes.
                                    </div>
                                    <div style={styles.buttonsWrapper}>
                                        <Button
                                            label="Save Note"
                                            style={noteContent === '' ? styles.disabledButton : styles.saveNoteButton}
                                            ripple={false}
                                            disabled={noteContent === ''}
                                            onClick={onSaveNote}
                                        />
                                        <Button
                                            label="Cancel"
                                            ripple={false}
                                            style={styles.cancelNoteButton}
                                            onClick={onCancelNote}
                                        />
                                    </div>
                                </>
                            ) : null}
                        {(isLoadingNotes) ? (
                            <div style={styles.loadingIndicator}>
                                <LoadingIndicator />
                            </div>
                        ) : null}
                    </>
                ) : null}
            {notes.length > 0
                ? (
                    <Timeline style={styles.timelineWrapper}>
                        {notes.map((note: CaseNote, index: number) => (
                            <TimelineItem key={note.noteId}>
                                <TimelineOppositeContent style={styles.noteAuthorDate}>
                                    <div>
                                        {getNoteAuthor(note.userId)}
                                    </div>
                                    <div>
                                        {formatDateTime(note.createDate)}
                                    </div>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    {/* Don't add the connector for the last note */}
                                    {index === (notes.length - 1) ? null : <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent style={styles.noteContent}>
                                    {note.note}
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                ) : (
                    <div style={{ ...styles.centerContent, ...styles.verticalMargins, }}>
                        There are no notes added for this case.
                    </div>
                )}
        </>
    );
}

export default CaseNoteTimeline;
