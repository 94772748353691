// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Sidebar from 'react-sidebar';
import { selectUserId } from 'txp-core';
import MenuBar from './MenuBar';
import CreateEditTeam from './CreateEditTeam';
import SagaMessage from './SagaMessage';
import {
    setSagaMessage as _setSagaMessage,
} from '../Redux/ApplicationActions';
import {
    getContacts as _getContacts,
} from '../Redux/ChatroomActions';
import {
    openTeam as _openTeam,
    closeTeam as _closeTeam,
    createTeam as _createTeam,
    deleteTeam as _deleteTeam,
    closeTeamCreate as _closeTeamCreate,
    updateTeam as _updateTeam,
} from '../Redux/TeamActions';
import hasPermissions, {
    UPDATE,
    DELETE,
    NONE,
    ENTITY_TYPE_TEAM,
} from '../Utils/hasPermissions';
import type {
    AvatarMap,
    ChatroomMemberMap,
    Team,
    UserProfile,
    UserPermission,
} from '../Utils/types';

import TeamList from './TeamList';
import TeamAddCreateStyles from './Styles/TeamAddCreateStyles';

type Props = {
    createTeamOpen: boolean,
    memberId: number,
    memberAccessLevel: string,
    isConnected: boolean,
    avatars: AvatarMap,
    contactsMap: ChatroomMemberMap,
    contactsOrder: Array<number>,
    contactsAreLoaded: boolean,
    teams: Array<Team>,
    teamsLoading: boolean,
    openTeamId: ?number,
    deviceType: string,
    isTeamsListOpen: boolean,
    singleUserAdded: boolean,
    newUserInvited: boolean,
    permissions: Array<UserPermission>,

    getContacts: () => *,
    openTeam: (teamId: number) => *,
    closeTeam: (teamId: number) => *,
    deleteTeam: (teamId: number) => *,
    closeTeamCreate: () => *,
    createTeam: (teamName: string, teamMembers: Array<UserProfile>, shared: boolean) => *,
    updateTeam: (teamId: number, teamName: string, teamMembers: Array<UserProfile>, shared: boolean) => *,
    setSagaMessage: (heading: string, message: string, label: string, isDialog?: boolean) => *,
    openMenu: () => *,
    showProfile: () => *,
    showHelp: () => *,
    showSettings: () => *,
    showUpload: () => *,
}

const genericSagaToast: number = 10007;

class TeamsTab extends PureComponent<Props> {
    componentDidMount() {
        const {
            contactsOrder,
            getContacts,
        } = this.props;

        if (contactsOrder.length === 0) {
            getContacts();
        }
    }

    onCloseCreateTeam = () => {
        const {
            closeTeamCreate,
        } = this.props;

        closeTeamCreate();
    }

    onOpenTeam = (teamId: number) => {
        const {
            openTeam,
        } = this.props;

        openTeam(teamId);
    }

    onCloseTeam = (teamId: number) => {
        const {
            closeTeam,
        } = this.props;

        closeTeam(teamId);
    }

    onDeleteTeam = (teamId: number) => {
        const {
            deleteTeam,
            setSagaMessage,
        } = this.props;

        const team = this.getTeamById(teamId);

        if (!team) {
            setSagaMessage('Delete Failed', 'If the problem continues, please contact support', '', false);
            return;
        }

        const delPrefix = team.shared
            ? 'This team is shared across the Organization and deleting it will remove the team for all Organization members.\n\n' : '';
        const response = window.confirm(`${delPrefix}Are you sure you wish to delete the team '${team.teamName}'. You cannot undo this?`);
        if (response) {
            deleteTeam(teamId);
        }
    }

    onCreateTeam = (teamName: string, teamMembers: Array<UserProfile>, shared: boolean) => {
        const {
            createTeam,
            closeTeamCreate,
        } = this.props;

        createTeam(teamName, teamMembers, shared);
        closeTeamCreate();
    }

    onUpdateTeam = (teamId: number, teamName: string, teamMembers: Array<UserProfile>, shared: boolean) => {
        const { updateTeam, } = this.props;
        updateTeam(teamId, teamName, teamMembers, shared);
    }

    getTeamById = (teamId: number): Team | null => {
        const {
            teams,
        } = this.props;

        for (let i = 0; i < teams.length; i += 1) {
            if (teams[i].teamId === teamId) {
                return teams[i];
            }
        }
        return null;
    }

    renderTeamsListPanel = () => {
        const {
            teams,
            teamsLoading,
            openTeamId,
            deviceType,
            isTeamsListOpen,
            openMenu,
            showProfile,
            showHelp,
            showSettings,
            showUpload,
        } = this.props;

        if (!isTeamsListOpen) { return null; }

        return (
            <div>
                <TeamList
                    teamsLoading={teamsLoading}
                    teams={teams}
                    selectedTeamId={openTeamId}
                    onTeamSelect={this.onOpenTeam}
                />
                {(deviceType === 'mobile')
                    ? (
                        // TODO: Move this outside sidepanel if we can
                        //  It doesn't make sense to have to pass these
                        //  methods in when it could exist outside the component.
                        <div className="menuBarWrapper">
                            <MenuBar
                                footer
                                openMenu={openMenu}
                                showProfile={showProfile}
                                showHelp={showHelp}
                                showSettings={showSettings}
                                showUpload={showUpload}
                            />
                        </div>
                    ) : <div />}
            </div>
        );
    }

    render() {
        const {
            memberId,
            memberAccessLevel,
            isConnected,
            avatars,
            contactsMap,
            contactsOrder,
            contactsAreLoaded,
            permissions,
            teams,
            teamsLoading,
            openTeamId,
            createTeamOpen,
            deviceType,
            isTeamsListOpen,
            singleUserAdded,
            newUserInvited,
        } = this.props;

        let sidebarClassName = 'sidebar';
        if (deviceType === 'tablet') {
            sidebarClassName = 'sidebarTablet';
        }

        let openTeam;

        if (!createTeamOpen) {
            for (let i = 0; i < teams.length; i += 1) {
                if (teams[i].teamId === openTeamId) {
                    openTeam = teams[i];
                }
            }
        }
        let canEditTeam = (openTeamId) ? hasPermissions(permissions, ENTITY_TYPE_TEAM, openTeamId, NONE, NONE, UPDATE) : false;
        let canDeleteTeam = (openTeamId) ? hasPermissions(permissions, ENTITY_TYPE_TEAM, openTeamId, NONE, NONE, DELETE) : false;

        // Teams that are accessible to view are teams that the user has permission to access, those are either teams the user
        // has created themselves or those are teams that have been shared to their organization. In those instances, the
        // 'update' and 'delete' permissions will always be true. In order to combat users accidentally removed shared teams that
        // have been created, we will add a constraint that if the team is shared you must also be an org manager or creator to edit/delete it
        if (openTeam && openTeam.shared) {
            canEditTeam = (memberId === openTeam.creatorId) || (memberAccessLevel === 'OrgManager');
            canDeleteTeam = (memberId === openTeam.creatorId) || (memberAccessLevel === 'OrgManager');
        }

        return (
            <Sidebar
                sidebar={(
                    <div>
                        {this.renderTeamsListPanel()}
                    </div>
                )}
                shadow={false}
                open={isTeamsListOpen}
                docked={isTeamsListOpen}
                sidebarClassName={sidebarClassName}
            >
                <SagaMessage toastId={genericSagaToast} />
                {/* Display if either createChatroom is open OR there is a selected team. */}
                {(createTeamOpen || openTeam) ? (
                    <CreateEditTeam
                        isConnected={isConnected}
                        teams={teams}
                        teamsLoading={teamsLoading}
                        contactsMap={contactsMap}
                        contactsOrder={contactsOrder}
                        contactsAreLoaded={contactsAreLoaded}
                        memberId={memberId}
                        team={openTeam}
                        canEdit={canEditTeam}
                        canDelete={canDeleteTeam}
                        avatars={avatars}
                        singleUserAdded={singleUserAdded}
                        newUserInvited={newUserInvited}
                        onClose={(createTeamOpen) ? this.onCloseCreateTeam : undefined}
                        onCreate={this.onCreateTeam}
                        onUpdate={this.onUpdateTeam}
                        onDelete={this.onDeleteTeam}
                    />
                ) : (
                    <div
                        style={TeamAddCreateStyles.centeredContainer}
                    >
                        Select a team to view the details.
                    </div>
                )}
            </Sidebar>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile, } = state.auth;

    return {
        profile,
        memberId: selectUserId(state.auth),
        memberAccessLevel: profile.accessLevel || '',
        isConnected: state.chatList.socketStatus === 'connected',
        avatars: state.chatList.avatars || {},
        teams: state.team.teams,
        teamsLoading: state.team.teamsLoading,
        openTeamId: state.team.openTeamId,
        createTeamOpen: state.team.teamCreateInProgress,
        contactsOrder: state.chatroom.contactsOrder,
        contactsAreLoaded: state.chatroom.contactsAreLoaded,
        contactsMap: state.chatroom.contactsMap,
        singleUserAdded: state.chatroom.singleUserAdded,
        newUserInvited: state.chatroom.newUserInvited,
        permissions: state.permission.permissions || [],
    };
};

export default connect(mapStateToProps, {
    getContacts: _getContacts,
    openTeam: _openTeam,
    closeTeam: _closeTeam,
    createTeam: _createTeam,
    deleteTeam: _deleteTeam,
    closeTeamCreate: _closeTeamCreate,
    updateTeam: _updateTeam,
    setSagaMessage: _setSagaMessage,
})(TeamsTab);
