// @flow
import React from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';

import styles from './Styles/GenericFormStyles';
import type { FormDefField } from '../Utils/types';
import { getFormLabel } from './FormLabel';

type Props = {
    fieldDisabled: boolean,
    item: FormDefField,
    label: string,
    value: string,
    error: boolean,

    onTextChange: (fieldId: number, text: string, label?: string) => *,
    onTrackFlight: (value: string) => *,
};

// Note: flightTracker and adornment attributes are mutually exclusive
const getInputProps = (item: any, onTrackFlight, value) => {
    let inputProps = {};
    if (item.adornment) inputProps.endAdornment = item.adornment;
    if (!item.flightTracker || !value) return inputProps;

    inputProps = {
        endAdornment:
    <InputAdornment position="end">
        <Button
            onClick={() => onTrackFlight(value.toString())}
            size="small"
        >
            {'Track Flight >'}
        </Button>
    </InputAdornment>,
    };
    return inputProps;
};

const FormTextInput = (props: Props) => {
    const {
        fieldDisabled,
        item,
        label,
        value,
        error,

        onTextChange,
        onTrackFlight,
    } = props;

    if (!item) return null;

    // const multiline = item.maxLines && item.maxLines > 1;
    const inputProps = getInputProps(item, onTrackFlight, value);
    let inputType = 'text';
    if (item.kbType === 'number-pad') inputType = 'number';

    return (
        <div>
            <div style={styles.padding10}>
                <TextField
                    type={inputType}
                    variant="outlined"
                    autoCapitalize="sentences"
                    placeholder={`Enter ${item.title} here...`}
                    disabled={fieldDisabled}
                    fullWidth
                    color="primary"
                    label={getFormLabel(item.title, item.mandatory)}
                    // multiline={multiline}
                    multiline
                    maxRows={8}
                    InputLabelProps={{ shrink: true, }}
                    value={value}
                    error={error}
                    helperText={item.helperText}
                    onChange={(event) => onTextChange(item.id, event.target.value, label)}
                    InputProps={inputProps}
                />
            </div>
        </div>
    );
};

export default FormTextInput;
