// @flow
import type { Saga } from 'redux-saga';
import { put } from 'redux-saga/effects';

import { apiFetch } from './ApiSaga';
import { setSagaMessage } from '../Redux/ApplicationActions';
import api from '../Services/Api';
import {
    finishLoading, startLoading,
} from '../Redux/LoadingActions';
import {
    receiveNotifiableEventGroup,
} from '../Redux/NotificationActions';
import type {
    GetNotifiableEventGroup,
} from '../Redux/NotificationActions';

export function* getNotifiableEventGroupSaga(action: GetNotifiableEventGroup): Saga<void> {
    const {
        notifiableGroupId,
    } = action;

    yield put(startLoading('notifiableEventGroup'));

    const { result, error, } = yield apiFetch(api.txp.notifiableEventGroup, {
        notifiableGroupId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Loading events failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Loading events failed', 'Try again later', ''));
        }
    } else {
        const events = result.notifiable_events;

        const notifiableEvents = [];
        for (let i = 0; i < events.length; i += 1) {
            notifiableEvents.push({
                notifiableEventId: events[i].notifiable_event_id,
                eventDescription: events[i].event_description,
                sequence: events[i].sequence_position,
                eventType: events[i].event_type,
                targetType: events[i].targetType,
                subtargetType: events[i].subtarget_type,
                subtargetId: events[i].subtarget_id,
            });
        }
        notifiableEvents.sort((a, b) => a.sequence - b.sequence);

        yield put(receiveNotifiableEventGroup(notifiableEvents));

        yield put(finishLoading('notifiableEventGroup'));
    }
}
