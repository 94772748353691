// @flow

// A simple in-memory storage mechanism that conforms to
// the browser's sessionStorage API
function InMemoryStorage() {
    let storage = {};

    this.setItem = function setItem(key: string, value: string) {
        storage[key] = String(value);
    };

    this.getItem = function getItem(key: string) {
        return Object.prototype.hasOwnProperty.call(storage, key) ? storage[key] : null;
    };

    this.removeItem = function removeItem(key: string) {
        delete storage[key];
    };

    this.clear = function clear() {
        storage = {};
    };

    this.key = function key(index: number) {
        const keys = Object.keys(storage);
        return index < keys.length ? keys[index] : null;
    };

    Object.defineProperty(this, 'length', {
        get() {
            return Object.keys(storage).length;
        },
    });
}

// The desired storage mechanism is sessionStorage so
// that there is some persistance
const storage = (function getStorage() {
    try {
        // Test if sessionStorage is available
        sessionStorage.setItem('test', 'test');
        sessionStorage.removeItem('test');
        return sessionStorage;
    } catch (e) {
        // If sessionStorage is not available, use InMemoryStorage
        return new InMemoryStorage();
    }
}());

export default storage;
