// @flow
import { v1 } from 'uuid';

export default function createUuid(userId: number) {
    const hexString = userId.toString(16).padStart(12, '0');
    const byteArray = [];

    for (let i = 0; i < hexString.length; i += 2) {
        byteArray.push(parseInt(hexString.substr(i, 2), 16));
    }

    return v1({ node: new Uint8Array(byteArray), }).replace(/-/gi, '');
}
