// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import FollowerTaskItem from './FollowerTaskItem';
import type { FollowerEditState } from '../Redux/FollowerEditActions';
import hasPermissions, {
    ENTITY_TYPE_DONOR,
    NONE,
    UPDATE,
} from '../Utils/hasPermissions';
import type {
    StaticTask,
} from '../Utils/types';
import Colors from '../Themes/Colors';
import ApplicationStyles from '../Themes/ApplicationStyles';
import FollowerGroupStyles from './Styles/FollowerGroupStyles';

type Props = {
    donorId: number,
    followerEdit: FollowerEditState,
    onSetFollowerTasks: (updatedTasks: number[]) => *,
};

export default function FollowerTaskList(props: Props) {
    const {
        donorId,
        followerEdit,
        onSetFollowerTasks,
    } = props;

    const isTaskChecked = (taskId: number) => (followerGroupTasks && followerGroupTasks.includes(taskId));

    // TODO: Also disable checkboxes if the donor is closed
    const donorState = useSelector((state) => state.donor || {});
    const permissions = useSelector((state) => state.permission.permissions || []);
    const followerGroupTasks = followerEdit.taskIds;
    const openDonor = (donorState.donors && donorState.donors[donorId]) ? donorState.donors[donorId] : {};

    let allTasks = [];
    let sectionOrder = [];
    const { workflow, } = openDonor;

    if (donorState.workflowTasks && donorState.workflowTasks[workflow] && donorState.workflowTasks[workflow].tasks) {
        allTasks = donorState.workflowTasks[workflow].tasks;
    }
    if (donorState.workflowTasks && donorState.workflowTasks[workflow] && donorState.workflowTasks[workflow].sectionOrder) {
        // eslint-disable-next-line prefer-destructuring
        sectionOrder = donorState.workflowTasks[workflow].sectionOrder;
    }

    const allTasksChecked = (!allTasks.some((task) => !isTaskChecked(task.taskId)));
    const canUpdateDonor = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorId, NONE, NONE, UPDATE);

    // If any task is not included in the follower group then return false
    // const allTasksSelected = () => (!allTasks.some((task) => !isTaskChecked(task.taskId)));

    const onTaskPress = (taskId: number) => {
        // Make a copy of the current follower group tasks.
        const updatedFollowerGroupTasks = followerGroupTasks.slice();

        // Either add the task to the array of follower group tasks or remove
        // it depending on if it is already included or not
        if (isTaskChecked(taskId)) {
            const taskIndex = updatedFollowerGroupTasks.indexOf(taskId);
            updatedFollowerGroupTasks.splice(taskIndex, 1);
        } else {
            updatedFollowerGroupTasks.push(taskId);
        }

        onSetFollowerTasks(updatedFollowerGroupTasks);
    };

    const onAllTaskPress = () => {
        let updatedFollowerGroupTasks = [];
        if (!allTasksChecked) {
            updatedFollowerGroupTasks = allTasks.map((task) => task.taskId);
        }

        onSetFollowerTasks(updatedFollowerGroupTasks);
    };

    const renderEmptyList = () => (
        <div
            style={{ ...ApplicationStyles.horizontalCenter, ...FollowerGroupStyles.emptyList, }}
        >
            No task data available
        </div>
    );

    const renderTaskItem = (task: StaticTask) => (
        <FollowerTaskItem
            key={task.taskId}
            donorId={donorId}
            task={task}
            isSelected={isTaskChecked(task.taskId)}
            isDisabled={!canUpdateDonor}
            onTaskPress={() => onTaskPress(task.taskId)}
        />
    );

    const renderTaskList = () => {
        const sectionHeaders = [...new Set(sectionOrder)];
        const sectionedTasks = {};

        for (let i = 0; i < sectionHeaders.length; i += 1) {
            sectionedTasks[sectionHeaders[i]] = [];
        }

        // Add all tasks to appropriate section
        for (let i = 0; i < allTasks.length; i += 1) {
            if (allTasks[i] && allTasks[i].details && allTasks[i].details.section) {
                sectionedTasks[allTasks[i].details.section].push(allTasks[i]);
            }
        }

        // Sort each section by sectionPosition
        for (let i = 0; i < sectionHeaders.length; i += 1) {
            sectionedTasks[sectionHeaders[i]].sort((a, b) => ((a.details.sectionPosition > b.details.sectionPosition) ? 1 : -1));
        }

        const singleList = sectionOrder.length === 1 && sectionHeaders.length === 1;

        return (
            singleList ? (
                sectionedTasks[sectionHeaders[0]].map((task) => (renderTaskItem(task)))
            ) : (
                sectionHeaders.map((headerName: string) => (
                    <div
                        style={FollowerGroupStyles.expandedCardTask}
                        key={headerName}
                    >
                        <div
                            style={FollowerGroupStyles.cardTitleLarge}
                        >
                            {headerName}
                        </div>
                        {sectionedTasks[headerName].map((task) => (renderTaskItem(task)))}
                    </div>
                ))
            )
        );
    };

    const renderAllTasksCheckboxIcon = () => (
        <FontAwesomeIcon
            fixedWidth
            icon={allTasksChecked ? faCheckCircle : faCircle}
            color={Colors.blue}
        />
    );

    const renderAllTasksCheckbox = () => (
        canUpdateDonor ? (
            <FormControlLabel
                label="Add all tasks"
                style={FollowerGroupStyles.allTasksCheckbox}
                control={(
                    <Checkbox
                        disabled={!canUpdateDonor}
                        checked={allTasksChecked}
                        onChange={onAllTaskPress}
                        icon={renderAllTasksCheckboxIcon()}
                        checkedIcon={renderAllTasksCheckboxIcon()}
                    />
                )}
            />
        ) : null
    );

    return (
        <div style={FollowerGroupStyles.sectionContainer}>
            {allTasks.length === 0 ? renderEmptyList() : (
                <>
                    {renderAllTasksCheckbox()}
                    {renderTaskList()}
                </>
            )}
        </div>
    );
}
