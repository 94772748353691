// @flow
import * as Sentry from '@sentry/browser';
import type { Saga } from 'redux-saga';
import { put } from 'redux-saga/effects';

import type { ReportError } from '../Redux/ApplicationActions';
import { resetError } from '../Redux/ApplicationActions';

export default function* reportErrorSaga(action: ReportError): Saga<void> {
    const { reducerError, } = action;
    const logEvent = {
        uuid: reducerError.uuid,
        source: reducerError.source,
        action: reducerError.action,
    };
    Sentry.addBreadcrumb({
        category: 'reducer',
        level: 'error',
        message: JSON.stringify(logEvent),
    });
    Sentry.addBreadcrumb({
        category: 'reducer',
        level: 'error',
        message: reducerError.error.stack,
    });
    Sentry.captureException(new Error(reducerError.error.message));
    yield put(resetError(reducerError.uuid));
}
