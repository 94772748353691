// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Sidebar from 'react-sidebar';
import Button from 'react-toolbox/lib/button/Button';
import MuiButton from '@material-ui/core/Button';
import Checkbox from 'react-toolbox/lib/checkbox';
import FontIcon from 'react-toolbox/lib/font_icon';
import ReactTooltip from 'react-tooltip';
import {
    TextField,
} from '@material-ui/core';
import AddPeople from './AddPeople';
import UserDetails from './UserDetails';

import Images from '../Themes/Images';
import type {
    AvatarMap,
    ChatroomMemberMap,
    Team,
    UserProfile,
} from '../Utils/types';
import { setAddPeopleError as _setAddPeopleError } from '../Redux/AddPeopleActions';
import { resetChatroomDetails as _resetChatroomDetails } from '../Redux/ChatroomActions';

import { alphaSortUserProfiles } from '../Utils/sort';
import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import ChatListStyles from './Styles/ChatListStyles';
import ChatInputStyles from './Styles/ChatInputStyles';
import TeamAddCreateStyles from './Styles/TeamAddCreateStyles';

type FormAction = 'Create' | 'Edit';

type Props = {
    profile: UserProfile,
    isConnected: boolean,
    teams: Array<Team>,
    teamsLoading: boolean,
    contactsMap: ChatroomMemberMap,
    contactsOrder: Array<number>,
    contactsAreLoaded: boolean,
    singleUserAdded: boolean,
    newUserInvited: boolean,

    memberId: number,
    team?: Team,
    canEdit: boolean,
    canDelete: boolean,

    avatars: AvatarMap,

    onClose: ?() => *,
    onDelete: (teamId: number) => *,
    onCreate: (teamName: string, teamMembers: Array<UserProfile>, shared: boolean) => *,
    onUpdate: (teamId: number, teamName: string, teamMembers: Array<UserProfile>, shared: boolean) => *,

    setAddPeopleError: typeof _setAddPeopleError,
    resetChatroomDetails: typeof _resetChatroomDetails,
};

type State = {
    formAction: FormAction,
    teamName: ?string,
    sharedWithOrg: ?boolean,
    addedTeamMembers: Array<UserProfile>,
    sidePanelOpen: boolean,
    addUserFilter: string,
    editModeEnabled: boolean,
    teamSorted: ?Team,
};

class CreateEditTeam extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const {
            team,
        } = props;

        const formAction = (team) ? 'Edit' : 'Create';

        this.state = {
            formAction,
            sidePanelOpen: false,
            addUserFilter: '',
            sharedWithOrg: undefined,
            teamName: undefined,
            addedTeamMembers: [],
            editModeEnabled: false,
            teamSorted: undefined,
        };
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: Props) {
        if (nextProps.team !== prevState.team) {
            // sort teamMembers alphabetically for use when displaying team member list
            return {
                teamSorted: {
                    ...nextProps.team,
                    teamMembers: alphaSortUserProfiles(nextProps.team?.teamMembers ?? []),
                },
            };
        }
        return null;
    }

    componentDidUpdate(prevProps: Props) {
        const {
            team,
        } = this.props;
        if (prevProps.team !== team) {
            this.resetState();
        }
    }

    onCloseClick = () => {
        const {
            onClose,
        } = this.props;

        if (onClose) {
            onClose();
        }
    }

    onSubmitClick = () => {
        const {
            team,
            onCreate,
            onUpdate,
        } = this.props;
        const {
            formAction,
            teamName,
            addedTeamMembers,
            sharedWithOrg,
        } = this.state;

        if (formAction === 'Create') {
            onCreate(teamName || '', addedTeamMembers, !!sharedWithOrg);
        } else if (formAction === 'Edit') {
            if (!team) { return; }

            const updatedTeamName = (teamName != null) ? teamName : team.teamName;
            const updatedShared = (sharedWithOrg != null) ? sharedWithOrg : team.shared;
            onUpdate(team.teamId, updatedTeamName, team.teamMembers, updatedShared);
            this.setEditModeEnabled(false);
        }
    };

    onUpdateSelectedMembers = (pendingUsers: Array<UserProfile>) => {
        this.setState({
            addedTeamMembers: pendingUsers,
        });
    }

    onAddMembers = (users: Array<UserProfile>) => {
        const {
            onUpdate,
            team,
        } = this.props;

        // Early return if team is undefined
        if (!team) { return; }

        const {
            teamId,
            teamMembers,
            teamName,
            shared,
        } = team;

        // Make sure that they are arrays before spreading.
        const newTeamMembers = [...(teamMembers || []), ...(users || [])];

        onUpdate(teamId, teamName, newTeamMembers, shared);
        this.setSidePanelOpen(false);
        this.setEditModeEnabled(false);
    }

    onToggleAddSelf = () => {
        const {
            profile,
            onUpdate,
            team,
        } = this.props;

        // Early return if team is undefined
        if (!team) { return; }

        const {
            teamId,
            teamMembers,
            teamName,
            shared,
        } = team;

        if (teamMembers.some((member) => member.userId === profile.userId)) {
            const filteredTeamMembers = teamMembers.filter((member) => member.userId !== profile.userId);
            const newTeamMembers = [...filteredTeamMembers || []];
            onUpdate(teamId, teamName, newTeamMembers, shared);
        } else {
            const newTeamMembers = [...(teamMembers || []), profile];
            onUpdate(teamId, teamName, newTeamMembers, shared);
        }
    }

    onClearMessage = () => {
        const {
            setAddPeopleError,
            resetChatroomDetails,
        } = this.props;

        setAddPeopleError();
        resetChatroomDetails();
    }

    onRemoveMember = (user: UserProfile) => {
        const {
            onUpdate,
            team,
        } = this.props;

        // Early return if team is undefined
        if (!team) { return; }

        const {
            teamId,
            teamMembers,
            teamName,
            shared,
        } = team;

        const newTeamMembers = teamMembers.filter((member) => member.userId !== user.userId);

        onUpdate(teamId, teamName, newTeamMembers, shared);
    }

    onFilterUsers = (addUserFilter: string) => {
        this.setState({
            addUserFilter,
        });
    }

    setTeamName = (teamName: string) => {
        this.setState({
            teamName,
        });
    }

    setSharedWithOrg = (sharedWithOrg: boolean) => {
        this.setState({
            sharedWithOrg,
        });
    }

    setSidePanelOpen = (sidePanelOpen: boolean) => {
        this.setState({
            sidePanelOpen,
        });
    }

    setEditModeEnabled = (editModeEnabled: boolean) => {
        this.setState({
            editModeEnabled,
        });
    }

    resetState() {
        const {
            team,
        } = this.props;
        const formAction = (team) ? 'Edit' : 'Create';

        this.setState({
            formAction,
            teamName: undefined,
            sharedWithOrg: undefined,
            addedTeamMembers: [],
            sidePanelOpen: false,
            addUserFilter: '',
            editModeEnabled: false,
        });
    }

    isSubmitDisabled = () => {
        const {
            teamName,
            sharedWithOrg,
            formAction,
        } = this.state;

        const isCreate = formAction === 'Create';
        let isDisabled = (isCreate && (teamName == null || !teamName.trim()));
        if (isCreate && (teamName == null || !teamName.trim())) {
            isDisabled = (teamName == null || !teamName.trim());
        } else {
            isDisabled = ((teamName == null || !teamName.trim()) && (sharedWithOrg == null));
        }

        return isDisabled;
    }

    renderActionButtons() {
        const {
            team,
            onDelete,
            canEdit,
            canDelete,
        } = this.props;
        const {
            editModeEnabled,
        } = this.state;

        if (!team) { return null; }

        const addSelfLabel = (team.teamMembers.some((member) => member.userId === this.props.profile.userId)) ? 'Leave Team' : 'Join Team';

        if (editModeEnabled) {
            return (
                <>
                    {canDelete ? (
                        <MuiButton
                            variant="outlined"
                            color="secondary"
                            onClick={() => onDelete(team.teamId)}
                            style={TeamAddCreateStyles.actionButton}
                        >
                            Delete
                        </MuiButton>
                    ) : null}
                    <MuiButton
                        variant="outlined"
                        color="primary"
                        onClick={() => this.onToggleAddSelf()}
                        style={TeamAddCreateStyles.actionButton}
                    >
                        { addSelfLabel }
                    </MuiButton>
                    <MuiButton
                        variant="outlined"
                        color="primary"
                        onClick={() => this.setSidePanelOpen(true)}
                        style={TeamAddCreateStyles.actionButton}
                    >
                        Add Members
                    </MuiButton>
                    <MuiButton
                        variant="outlined"
                        onClick={() => this.resetState()}
                        style={TeamAddCreateStyles.actionButton}
                    >
                        Cancel
                    </MuiButton>
                </>
            );
        }

        return (
            <>
                {canEdit ? (
                    <>
                        <MuiButton
                            variant="outlined"
                            onClick={() => this.setEditModeEnabled(true)}
                            style={TeamAddCreateStyles.actionButton}
                            color="primary"
                        >
                            Edit
                        </MuiButton>
                    </>
                ) : null}
            </>
        );
    }

    renderCloseButton() {
        const {
            onClose,
        } = this.props;

        const buttonStyle = (onClose) ? CreateChatroomStyles.closeButton : CreateChatroomStyles.hidden;

        return (
            <div>
                <ReactTooltip />
                <Button
                    style={buttonStyle}
                    type="button"
                    ripple={false}
                    data-tip="Cancel"
                    data-delay-show="250"
                    data-effect="solid"
                    data-place="bottom"
                    onClick={this.onCloseClick}
                >
                    <FontIcon
                        value="close"
                        alt=""
                    />
                </Button>
            </div>
        );
    }

    renderAddPeople() {
        const {
            isConnected,
            memberId,
            avatars,
            teams,
            teamsLoading,
            contactsMap,
            contactsOrder,
            contactsAreLoaded,
            singleUserAdded,
            newUserInvited,
            team,
        } = this.props;

        const {
            formAction,
            addUserFilter,
        } = this.state;

        const isCreate = (formAction === 'Create');

        const teamMembersMap = {};
        if (team && !isCreate) {
            for (let i = 0; i < team.teamMembers.length; i += 1) {
                const teamMember = team.teamMembers[i];
                teamMembersMap[teamMember.userId.toString()] = {
                    profile: teamMember,
                    email: teamMember.email,
                    phone: teamMember.phone,
                    membershipStatus: null,
                    startDate: '',
                    endDate: '',
                    doNotDisturb: false,
                    notificationSound: '',
                };
            }
        }

        return (
            <AddPeople
                memberId={memberId}
                members={teamMembersMap} // TODO: expects ChatroomMemberMap but given teamMembersMap
                avatars={avatars}
                teams={teams}
                teamsLoading={teamsLoading}
                contactsOrder={contactsOrder}
                contactsMap={contactsMap}
                contactsAreLoaded={contactsAreLoaded}
                singleUserAdded={singleUserAdded}
                newUserInvited={newUserInvited}
                addSingleUserError=""
                inviteNewUserError=""
                peopleFilter={addUserFilter}
                createChatroom={isCreate}
                isConnected={isConnected}
                onUpdateSelectedUsers={this.onUpdateSelectedMembers}
                onHideAddPeople={() => this.setSidePanelOpen(false)}
                onAddUsers={this.onAddMembers}
                clearMessage={this.onClearMessage}
                filterPeople={this.onFilterUsers}
            />
        );
    }

    renderMemberList() {
        const {
            avatars,
            isConnected,
        } = this.props;
        const {
            editModeEnabled,
            teamSorted,
        } = this.state;

        const teamMembers = (teamSorted && teamSorted.teamMembers) ? teamSorted.teamMembers : [];

        return (
            <>
                <div style={CreateChatroomStyles.titleMedium}>Member List</div>
                <div style={{ textAlign: 'left', }}>
                    {teamMembers.map((member) => {
                        const { userId, } = member;
                        const avatar = (avatars[userId] || Images.circledDefaultProfile);
                        return (
                            <UserDetails
                                key={member.userId}
                                membershipStatus="Present"
                                startDate=""
                                endDate=""
                                userProfile={member}
                                avatar={avatar}
                                displayButton={editModeEnabled}
                                doNotDisturb={false} // we don't have doNotDisturb status from contacts, nor is it relevant to Add People
                                displayEmail
                                wrapEmail={false}
                                isConnected={isConnected}
                                onMemberClick={this.onRemoveMember}
                                addManager={() => {}}
                                removeManager={() => {}}
                                showMemberProfile={() => {}}
                                isTeamList
                            />
                        );
                    })}
                </div>
            </>
        );
    }

    render() {
        const {
            team,
        } = this.props;

        const {
            formAction,
            teamName,
            sharedWithOrg,
            sidePanelOpen,
            editModeEnabled,
        } = this.state;

        const isCreate = formAction === 'Create';
        const submitButtonText = (isCreate) ? 'Create' : 'Update';
        let formTitle = 'Create Team';
        if (!isCreate) {
            formTitle = (team) ? team.teamName : '';
        }

        //
        // Get the value either from state or from the team
        //  itself if not set in state.
        let teamNameValue = teamName;
        if (!isCreate && team && teamName == null) {
            teamNameValue = team.teamName;
        }
        teamNameValue = (teamNameValue == null) ? '' : teamNameValue;

        let sharedWithOrgValue = sharedWithOrg;
        if (!isCreate && team && sharedWithOrgValue == null) {
            sharedWithOrgValue = team.shared;
        }
        sharedWithOrgValue = (sharedWithOrgValue == null) ? false : sharedWithOrgValue;

        const submitButtonDisabled = this.isSubmitDisabled();

        return (
            <Sidebar
                sidebar={(
                    <div style={{ ...ChatListStyles.wrapper, ...ChatListStyles.borderLeft, }}>
                        {/* FIXMME
                        Not ideal but returning null is a quick way way to clear the selected users.
                        Ideally we really should be having the owner of AddPeople controlling the selected users
                        and teams list so we can reset without this hack. */}
                        {(sidePanelOpen) ? this.renderAddPeople() : null}
                    </div>
                )}
                shadow={false}
                open={sidePanelOpen}
                docked={sidePanelOpen}
                pullRight
                onSetOpen={() => {}}
                sidebarClassName="sidebar"
            >
                <div style={{ paddingTop: 65, paddingBottom: 30, }}>
                    <div style={TeamAddCreateStyles.mainContainer}>
                        <div style={{ margin: 'auto', width: '80%', }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                {this.renderCloseButton()}
                                {(!isCreate) ? this.renderActionButtons() : null}
                            </div>
                            <div style={CreateChatroomStyles.title}>
                                {formTitle}
                            </div>
                            <TextField
                                color="primary"
                                style={TeamAddCreateStyles.formInput}
                                label="Team Name"
                                type="text"
                                value={teamNameValue}
                                onChange={(event) => this.setTeamName(event.target.value)}
                                disabled={(!isCreate && !editModeEnabled)}
                            />
                            <Checkbox
                                style={TeamAddCreateStyles.formInput}
                                checked={sharedWithOrgValue}
                                onChange={this.setSharedWithOrg}
                                disabled={(!isCreate && !editModeEnabled)}
                                label="Share with your organization"
                            />
                            {(isCreate) ? (
                                <>
                                    <div style={CreateChatroomStyles.titleMedium}>Add Members</div>
                                    {this.renderAddPeople()}
                                </>
                            ) : (this.renderMemberList())}
                            <div style={{ height: 50, }} />
                            {(isCreate || editModeEnabled) ? (
                                <div className="createDonorWrapper">
                                    <Button
                                        style={submitButtonDisabled ? ChatInputStyles.chatInputButtonDisabled : ChatInputStyles.chatInputButton}
                                        label={submitButtonText}
                                        ripple={false}
                                        disabled={submitButtonDisabled}
                                        data-for="chatInput"
                                        data-delay-show="250"
                                        data-effect="solid"
                                        onClick={this.onSubmitClick}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Sidebar>
        );
    }
}

// $FlowFixMe
CreateEditTeam.defaultProps = {
    team: undefined,
};

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
});

export default connect(mapStateToProps, {
    setAddPeopleError: _setAddPeopleError,
    resetChatroomDetails: _resetChatroomDetails,
})(CreateEditTeam);
