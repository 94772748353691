/* eslint-disable camelcase */
// @flow
import React, { PureComponent } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { formatDateTime } from 'txp-core';

import ChatMessageStyles from './Styles/ChatMessageStyles';

type Props = {
    candidateContent: any,
    width: number,
};

class CandidateData extends PureComponent<Props> {
    render() {
        const {
            width,
        } = this.props;

        const {
            mrn,
            full_name,
            sex,
            age,
            race,
            ethnicity,
            height,
            weight,
            bmi,
            blood_type,
            primary_diagnosis,
            prior_transplant,
            esrd_time,
            cpra,
            diabetes,
            cancer,
            pvd,
            last_seen,
        } = this.props.candidateContent;

        // FIXME shouldn't have to explicity set width in order to prevent wrapping text
        return (
            <div style={{ width: width / 1.5, }}>
                <div style={ChatMessageStyles.offerTitle}>
                    <span style={ChatMessageStyles.offerTitleText}>Candidate Information</span>
                </div>
                <Row>
                    <Col xs={12}>
                        <span style={ChatMessageStyles.offerInfo}><b>Full Name: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{full_name}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <span style={ChatMessageStyles.offerInfo}><b>Medical Record No.: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{mrn}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <span style={ChatMessageStyles.offerInfo}><b>Age: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{age}</span>
                        <span style={ChatMessageStyles.offerInfo}> years</span>
                    </Col>
                    <Col xs={3}>
                        <span style={ChatMessageStyles.offerInfo}><b>Sex: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{sex}</span>
                    </Col>
                    <Col xs={3}>
                        <span style={ChatMessageStyles.offerInfo}><b>Race: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{race}</span>
                    </Col>
                    <Col xs={3}>
                        <span style={ChatMessageStyles.offerInfo}><b>Ethnicity: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{ethnicity}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Height: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{height}</span>
                        <span style={ChatMessageStyles.offerInfo}> cm</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Weight: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{weight}</span>
                        <span style={ChatMessageStyles.offerInfo}> kg</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>BMI: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{bmi}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Blood Type: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{blood_type}</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Primary Diagnosis: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{primary_diagnosis}</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Prior Transplant? </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{prior_transplant ? 'Yes' : 'No'}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>ESRD Time: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{esrd_time}</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>cPRA: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{cpra}</span>
                        <span style={ChatMessageStyles.offerInfo}>%</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Diabetes: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{diabetes}</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Cancer? </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{cancer ? 'Yes' : 'No'}</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>PVD? </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{pvd ? 'Yes' : 'No'}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <span style={ChatMessageStyles.offerInfo}><b>Last seen by TC: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{formatDateTime(last_seen)}</span>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CandidateData;
