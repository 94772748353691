// Note: This is not a real uuid (it uses pseudorandom)
export default function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise, eqeqeq, one-var, one-var-declaration-per-line, no-mixed-operators
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function createTempID() {
    return Math.floor(Math.random() * 10000000) + 1000000000;
}
