// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import { toast } from 'react-toastify';

import ApplicationStyles from '../Themes/ApplicationStyles';
import Images from '../Themes/Images';

import {
    setSagaMessage as _setSagaMessage,
} from '../Redux/ApplicationActions';

type Props = {
    toastId: number,
    sagaHeading: string,
    sagaMessage: string,
    sagaLabel: string,
    isDialog: boolean,
    isPriority: boolean,
    setSagaMessage: (heading: string, message: string, label: string, isDialog?: boolean) => *,
};

class SagaMessage extends PureComponent<Props> {
    onClose = () => {
        const {
            setSagaMessage,
        } = this.props;

        setSagaMessage('', '', '');
    };

    render() {
        const {
            sagaHeading,
            sagaMessage,
            sagaLabel,
            isDialog,
            isPriority,
            toastId,
        } = this.props;

        const active: boolean = sagaHeading.length > 0 || sagaMessage.length > 0;

        if (active && isPriority) {
            toast.dismiss();
        }

        if (active && !isDialog) {
            // If no button is required then just create a toast
            toast(
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <div style={{ float: 'left', }}>
                        <img src={Images.logo} alt="HTS Logo" style={{ marginRight: 20, }} />
                    </div>
                    <div style={{ display: 'inline-block', }}>
                        <p style={{ fontWeight: '600', }}>{sagaHeading}</p>
                        <p>{sagaMessage}</p>
                    </div>
                </div>,
                { toastId, autoClose: true, onClose: this.onClose, }
            );

            return '';
        }
        return (
            <Dialog
                active={active}
                type="small"
                onEscKeyDown={this.onClose}
            >
                <div style={ApplicationStyles.horizontalCenter}>
                    <img src={Images.logo} alt="HTS Logo" />
                    <h4>{sagaHeading}</h4>
                    <p>{sagaMessage}</p>
                    {
                        sagaLabel ? (
                            <Button
                                onClick={this.onClose}
                                style={{ ...ApplicationStyles.margin20, ...ApplicationStyles.button, }}
                            >
                                {sagaLabel}
                            </Button>
                        ) : null
                    }
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    sagaHeading: state.application.sagaHeading || '',
    sagaMessage: state.application.sagaMessage,
    sagaLabel: state.application.sagaLabel,
    isDialog: state.application.isDialog,
    isPriority: state.application.isPriority,
});
export default connect(mapStateToProps, {
    setSagaMessage: _setSagaMessage,
})(SagaMessage);
