// @flow
import { compareDesc } from 'txp-core';
import type { ChatroomInfo } from './types';

const getLastMessageSentTime = (chatInfo: ?ChatroomInfo) => {
    if (chatInfo && chatInfo.lastMessage) {
        return chatInfo.lastMessage.sentTime;
    }

    return null;
};

const getAlpha = (chatInfo: ?ChatroomInfo) => {
    if (chatInfo && chatInfo.name) {
        return chatInfo.name;
    }

    return null;
};

export const getBadgeCount = (chatInfo: ?{ totalMessages: number, clearedCount: number, readCount: number, sentCount: number, }) => {
    if (chatInfo
        && typeof chatInfo.totalMessages === 'number'
        && typeof chatInfo.sentCount === 'number'
        && typeof chatInfo.readCount === 'number'
        && typeof chatInfo.clearedCount === 'number'
    ) {
        return chatInfo.totalMessages - chatInfo.sentCount - chatInfo.readCount - chatInfo.clearedCount;
    }

    return null;
};

const getLastActiveTime = (chatInfo: ?ChatroomInfo) => {
    if (chatInfo && chatInfo.lastActiveTime) {
        return chatInfo.lastActiveTime;
    }

    return null;
};

const getCreateDate = (chatInfo: ?ChatroomInfo) => {
    if (chatInfo && chatInfo.createDate) {
        return chatInfo.createDate;
    }

    return null;
};

const sortChatrooms = (chats: *, chatAId: number, chatBId: number, sortType: string): number => {
    switch (sortType) {
        case 'ALPHABETICAL': {
            const alphaA = (chats ? getAlpha(chats[chatAId]) : null) || '';
            const alphaB = (chats ? getAlpha(chats[chatBId]) : null) || '';

            return alphaA.localeCompare(alphaB);
        }

        case 'BADGE_COUNT': {
            const badgeA = (chats ? getBadgeCount(chats[chatAId]) : null) || 0;
            const badgeB = (chats ? getBadgeCount(chats[chatBId]) : null) || 0;

            return badgeB - badgeA;
        }

        case 'MY_ACTIVE': {
            const myActiveA = (chats ? getLastActiveTime(chats[chatAId]) : null) || '1970-01-01';
            const myActiveB = (chats ? getLastActiveTime(chats[chatBId]) : null) || '1970-01-01';

            return compareDesc(myActiveA, myActiveB);
        }

        case 'CREATE_DATE': {
            const createDateA = (chats ? getCreateDate(chats[chatAId]) : null) || '1970-01-01';
            const createDateB = (chats ? getCreateDate(chats[chatBId]) : null) || '1970-01-01';

            return compareDesc(createDateA, createDateB);
        }

        default: {
            // DEFAULT
            const sentTimeA = (chats ? getLastMessageSentTime(chats[chatAId]) : null) || '1970-01-01';
            const sentTimeB = (chats ? getLastMessageSentTime(chats[chatBId]) : null) || '1970-01-01';

            return compareDesc(sentTimeA, sentTimeB);
        }
    }
};

export default sortChatrooms;
