// @flow
/* eslint-disable quote-props */
import type { Logout } from 'txp-core';
import OAHeart from './Forms/OAHeart';
import OAIntestine from './Forms/OAIntestine';
import OAKidneys from './Forms/OAKidneys';
import OALiver from './Forms/OALiver';
import OALungs from './Forms/OALungs';
import OAPancreas from './Forms/OAPancreas';
import ORHeart from './Forms/ORHeart';
import ORIntestine from './Forms/ORIntestine';
import ORKidneys from './Forms/ORKidneys';
import ORLiver from './Forms/ORLiver';
import ORLungs from './Forms/ORLungs';
import ORPancreas from './Forms/ORPancreas';
import ORComplete from './Forms/ORComplete';
import AllocationComplete from './Forms/AllocationComplete';
import EnterOR from './Forms/EnterOR';
import HeparinAdministered from './Forms/HeparinAdministered';
import Incision from './Forms/Incision';
import FlushCrossclamp from './Forms/FlushCrossclamp';
import TransportDropoffComplete from './Forms/TransportDropoffComplete';
import BloodWorkSent from './Forms/BloodWorkSent';
import BloodWorkResult from './Forms/BloodWorkResult';
import CovidTestResult from './Forms/CovidTestResult';
import PreScreen from './Forms/PreScreen';
import Embalming from './Forms/Embalming';
import LocationNotification from './Forms/LocationNotification';
import type {
    FormData, FormDefDataMap, FormValueMap, FormErrorMap,
} from '../Utils/types';
import type { ResetData } from './ApplicationActions';

// Usage notes:
// formDef is the form definition object for LEGACY forms - those defined before the reference API's were in place
// origFormData is the original (database) task dta
// formUpdated is a flag indicating that data has changed
export type FormState = {
    formDef: FormDefDataMap,
    origFormData: FormData,
    formUpdated: boolean,
    formErrors: FormErrorMap,
};

const initialState: FormState = {
    formDef: {
        'OAHeart': OAHeart,
        'OAIntestine': OAIntestine,
        'OAKidneys': OAKidneys,
        'OALiver': OALiver,
        'OALungs': OALungs,
        'OAPancreas': OAPancreas,
        'ORHeart': ORHeart,
        'ORIntestine': ORIntestine,
        'ORKidneys': ORKidneys,
        'ORLiver': ORLiver,
        'ORLungs': ORLungs,
        'ORPancreas': ORPancreas,
        'AllocationComplete': AllocationComplete,
        'EnterOR': EnterOR,
        'HeparinAdministered': HeparinAdministered,
        'Incision': Incision,
        'FlushCrossclamp': FlushCrossclamp,
        'ORComplete': ORComplete,
        'TransportDropoffComplete': TransportDropoffComplete,
        'BloodWorkSent': BloodWorkSent,
        'BloodWorkResult': BloodWorkResult,
        'CovidTestResult': CovidTestResult,
        'PreScreen': PreScreen,
        'Embalming': Embalming,
        'LocationNotification': LocationNotification,
    },

    origFormData: {},

    formUpdated: false,

    formErrors: {},
};

export type BulkReceiveFormData = { type: 'Form/BULK_RECEIVE_FORM_DATA', formId: string, formData: Array<any> };

export type FormChanged = { type: 'Form/CHANGED', formUpdated: boolean };

export type UpdateDonorForm = {
    type: 'Form/UPDATE_DONOR_FORM',
    donorId: number,
    taskId: number,
    formValueMap: FormValueMap,
    lastModified: string,
};

export type SetFormErrors = {
    type: 'Form/SET_FORM_ERRORS',
    formId: string,
    formErrorMap: FormErrorMap,
};

export type Reset = { type: 'Form/RESET' };

type Action =
    | SetFormErrors
    | BulkReceiveFormData
    | FormChanged
    | UpdateDonorForm
    | Reset
    | ResetData
    | Logout;

export const bulkReceiveFormData = (formId: string, formData: Array<any>): BulkReceiveFormData => ({
    type: 'Form/BULK_RECEIVE_FORM_DATA',
    formId,
    formData,
});

export const formChanged = (formUpdated: boolean): FormChanged => ({
    type: 'Form/CHANGED',
    formUpdated,
});

export const updateDonorForm = (
    donorId: number,
    taskId: number,
    formValueMap: FormValueMap,
    lastModified: string
): UpdateDonorForm => ({
    type: 'Form/UPDATE_DONOR_FORM',
    donorId,
    taskId,
    formValueMap,
    lastModified,
});

export const setFormErrors = (
    formId: string,
    formErrorMap: FormErrorMap
): SetFormErrors => ({
    type: 'Form/SET_FORM_ERRORS',
    formId,
    formErrorMap,
});

export const reset = (): Reset => ({
    type: 'Form/RESET',
});

const reducer = (state: FormState = initialState, action: Action): FormState => {
    switch (action.type) {
        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
        case 'Form/RESET':
            return {
                ...initialState,
            };

        case 'Form/SET_FORM_ERRORS': {
            return {
                ...state,

                formErrors: {
                    ...state.formErrors,
                    [action.formId]: action.formErrorMap,
                },
            };
        }

        case 'Form/BULK_RECEIVE_FORM_DATA': {
            const newFormData = {};

            action.formData.forEach((entry) => {
                newFormData[action.formId] = {
                    ...newFormData[action.formId],

                    [entry.fieldId]: entry.data,
                };
            });

            const allFormData = {
                ...newFormData,
            };

            return {
                ...state,

                origFormData: allFormData,
            };
        }

        case 'Form/CHANGED':
            return {
                ...state,

                formUpdated: action.formUpdated,
            };

        default:
            return state;
    }
};

export default reducer;
