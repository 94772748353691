// @flow
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
// NOTE: currently using a legacy build as the current version (3.7.107) is not compatible with our version of react-scripts.
// TODO: update to most-current version when react-scripts is updated.
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf.min';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import { TextField, Divider } from '@material-ui/core';
import debounce from 'lodash.debounce';
import type { DonorDataEntry } from '../Utils/types';
import OfferImportStyles from './Styles/OfferImportStyles';
import { causesOfDeathMatchers } from '../Utils/CausesOfDeath';

type Props = {
    // acceptedFileTypes: string,
    importFile?: File,
    onImportError: (message: string) => *,
    onToggleImportPreview: () => *,
    onSetImportedDonorData: (donorData: DonorDataEntry[], filename?: string) => *,
};
// set default props
OfferImport.defaultProps = {
    importFile: undefined,
};

// PDF Preview Constants
const PAGE_SCALE = 1.5;

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

async function parsePdf(data, donorInformationMatchers) {
    // Loading document and page text content
    const loadingTask = pdfjs.getDocument({ data, });
    const pdfDocument = await loadingTask.promise;

    // PDF Preview
    const pdfPages = Array.from({ length: pdfDocument.numPages, }, (_, index) => index + 1);
    const pages = await Promise.all(
        pdfPages.map(async (pageNum) => {
            const page = await pdfDocument.getPage(pageNum);
            const viewport = page.getViewport({ scale: PAGE_SCALE, });
            const textContent = await page.getTextContent();
            // Release page resources.
            page.cleanup();

            return {
                viewport,
                textContent,
                key: pageNum,
                index: pageNum,
            };
        })
    );

    const donorInfo = extractDonorInfo(await getPdfText(pdfDocument), donorInformationMatchers);
    return { pages, donorInfo, };
}

function PdfPage({ viewport, textContent, page, }: any) {
    return (
        <div style={OfferImportStyles.pdfPage} key={page}>
            <svg width={`${viewport.width}px`} height={`${viewport.height}px`} fontSize={1}>
                {textContent.items.map((textItem, i) => {
                    const tx = pdfjs.Util.transform(
                        pdfjs.Util.transform(viewport.transform, textItem.transform),
                        [1, 0, 0, -1, 0, 0]
                    );
                    const style = textContent.styles[textItem.fontName];

                    // eslint-disable-next-line react/no-array-index-key
                    return <text key={`${page}-${i}`} transform={`matrix(${tx.join(' ')})`} fontFamily={style.fontFamily}>{textItem.str}</text>;
                })}
            </svg>
        </div>
    );
}

function withinDelta(num1, num2, delta) {
    return Math.abs(num1 - num2) <= delta;
}

async function getPdfText(pdfDocument) {
    const getPagePromises = Array.from({ length: pdfDocument.numPages, }, async (_, index) => {
        const page = await pdfDocument.getPage(index + 1);
        const textContent = await page.getTextContent();
        page.cleanup();
        return textContent.items;
    });

    const pages = await Promise.all(getPagePromises);
    return pages.flat();
}

function combineText(textItems) {
    let currentY = -1;
    let currentLineText = '';

    return textItems.reduce((textLines, item) => {
        // $FlowFixMe: the typer cannot imply the expected properties of what is returned from pdfjs
        if (withinDelta(item.transform[5], currentY, 5)) {
            // append to current line if on the same line (Y-value) as previous text
            // $FlowFixMe: the typer cannot imply the expected properties of what is returned from pdfjs
            currentLineText += item.str;
        } else {
            // start a new line
            // $FlowFixMe: the typer cannot imply the expected properties of what is returned from pdfjs
            currentY = item.transform[5];
            textLines.push(currentLineText);
            // $FlowFixMe: the typer cannot imply the expected properties of what is returned from pdfjs
            currentLineText = item.str;
        }
        return textLines;
    }, []);
}

function computeSocialMedicalSummary(donorInfo: DonorDataEntry[], donorInformationMatchers) {
    const socialMedicalFieldIds = ['diabetes', 'cancer', 'hypertension',
        'cad', 'gastrointestinal', 'chest_trauma', 'heavy_alcohol_use', 'iv_drug_use', 'smoking'];
    let value = '';
    socialMedicalFieldIds.forEach((id) => {
        const matchInfo = donorInformationMatchers.find((matcher) => matcher.id === id);
        const matchedDonorInfo = donorInfo.find((info) => info.key === id);
        if (matchedDonorInfo && matchedDonorInfo.value.startsWith('YES')) {
            value = value ? `${value}, ${matchInfo.label}: ${matchedDonorInfo.value}` : `${matchInfo.label}: ${matchedDonorInfo.value}`;
            if (id === 'hypertension') {
                const hypertensionComplianceInfo = donorInformationMatchers.find((matcher) => matcher.id === 'hypertension_compliance');
                const hypertensionComplianceDonorEntry = donorInfo.find((info) => info.key === 'hypertension_compliance');
                // value = `${value}, ${hypertensionComplianceInfo.label}: ${hypertensionComplianceDonorEntry.value}`;
                value = `${value}, ${hypertensionComplianceInfo.label}: ${hypertensionComplianceDonorEntry !== undefined
                    ? hypertensionComplianceDonorEntry.value : ''}`;
            }
            if (id === 'smoking') {
                const continuedCigaretteUseInfo = donorInformationMatchers.find((matcher) => matcher.id === 'continued_cigarette_use');
                const continuedCigaretteUseDonorEntry = donorInfo.find((info) => info.key === 'continued_cigarette_use');
                // value = `${value}, ${continuedCigaretteUseInfo.label}: ${continuedCigaretteUseDonorEntry.value}`;
                value = `${value}, ${continuedCigaretteUseInfo.label}: ${continuedCigaretteUseDonorEntry !== undefined
                    ? continuedCigaretteUseDonorEntry.value : ''}`;
            }
        }
    });
    return value;
}

function computePositiveSerologies(donorInfo: DonorDataEntry[], donorInformationMatchers) {
    // NOTE: might be a better idea to have a flag for these fields, in case we add/remove some
    const serologyIds = ['anti_hbc', 'hbv_nat', 'hbsag', 'hbsab', 'anti_hcv', 'hcv_nat',
        'anti_hiv_i_ii', 'hiv_agab_combo', 'hiv_nat', 'anti_htlv_i_ii', 'htlv_nat', 'anti_cmv',
        'syphilis', 'ebv_vca_igg', 'ebv_vca_igm', 'ebna', 'toxoplasma', 'chagas', 'chagas_nat',
        'west_nile', 'west_nile_nat', 'strongyloides'];

    let value = '';
    serologyIds.forEach((id) => {
        const matchInfo = donorInformationMatchers.find((matcher) => matcher.id === id);
        const matchedDonorInfo = donorInfo.find((info) => info.key === id);
        if (matchedDonorInfo && matchedDonorInfo.value === 'Positive') {
            value = value ? `${value}, ${matchInfo.label}` : `${matchInfo.label}`;
        }
    });

    return value;
}

function computeCurrentPFRatio(donorInfo: DonorDataEntry[]) {
    const pao2Current = donorInfo.find((info) => info.key === 'pao2_current');
    const fio2Current = donorInfo.find((info) => info.key === 'fio2_current');
    if (!pao2Current || !fio2Current) return '';

    const pao2CurrentValue = pao2Current.value ? parseFloat(pao2Current.value) : 0;
    const fio2CurrentValue = fio2Current.value ? parseFloat(fio2Current.value) : 0;

    // Formula for PF Ratio: PF Ratio = PaO2 / FiO2
    // Note: Since FiO2 is a percentage, we need to divide by 100
    const currentPFRatio = (fio2CurrentValue !== 0) ? pao2CurrentValue / (fio2CurrentValue / 100) : 0;
    return currentPFRatio.toFixed(2);
}

const cleanUpTextLines = (lines) => {
    const headerDonorRegex = /\d{1,2}\/\d{1,2}\/\d\d, \d{1,2}:\d\d (A|P)M Donor Summary for/;
    const headerUrlRegex = /portal\.unos\.org\/DonorNet\/PrintFormatedDonorSummary\.aspx/;

    return lines.filter((line) => !(
        line === ''
        || headerDonorRegex.test(line)
        || headerUrlRegex.test(line)
    ));
};

function processVital(info, currentDonorDataEntry, allDonorDataEntries: DonorDataEntry[]) {
    let values = info.processRegex ? currentDonorDataEntry.value.match(new RegExp(info.processRegex, 'g')) : currentDonorDataEntry.value.split(info.joiner);
    // let values = currentDonorDataEntry.value.split(info.joiner);

    // handle cases where vitals results were not entered
    if (values && (values.length < 1 || (values.length === 1 && values[0] === ''))) {
        values = [];
        allDonorDataEntries.push({
            key: `${info.id}_current`,
            value: '',
            label: `${info.label} Current`,
        });
        allDonorDataEntries.push({
            key: `${info.id}_count`,
            value: '0',
            label: `${info.label} Count`,
        });
    } else {
        allDonorDataEntries.push({
            key: `${info.id}_current`,
            // value: `${values[values?.length - 1]}`,
            value: `${values != null && values.length > 0 ? values[values.length - 1] : ''}`,
            label: `${info.label} Current`,
        });
        allDonorDataEntries.push({
            key: `${info.id}_count`,
            // value: `${values?.length}`,
            value: `${values != null ? values.length : ''}`,
            label: `${info.label} Count`,
        });
    }

    currentDonorDataEntry.value = '';
}

function processLab(info, currentDonorDataEntry, allDonorDataEntries: DonorDataEntry[]) {
    let admitLab = '';
    let currentLab = '';
    let maxLab = '';
    let labCount = 0;

    // Only set our admit, peak, current and max lab values if we have a lab reading
    if (currentDonorDataEntry.value && currentDonorDataEntry.value.trim()) {
        const values = currentDonorDataEntry.value.trim().split(info.joiner).map((num) => parseFloat(num));
        admitLab = values[0];
        currentLab = values[values.length - 1];
        maxLab = Math.max(...values);
        labCount = values.length;
    }

    // Each lab is turned into five data entries
    allDonorDataEntries.push({
        key: `${info.id}`,
        value: admitLab || maxLab || currentLab ? `${admitLab}/${maxLab}/${currentLab}` : '',
        label: `${info.label} Admit/Peak/Current`,
    });
    allDonorDataEntries.push({
        key: `${info.id}_admit`,
        value: `${admitLab}`,
        label: `${info.label} Admit`,
    });
    allDonorDataEntries.push({
        key: `${info.id}_peak`,
        value: `${maxLab}`,
        label: `${info.label} Peak`,
    });
    allDonorDataEntries.push({
        key: `${info.id}_current`,
        value: `${currentLab}`,
        label: `${info.label} Current`,
    });
    allDonorDataEntries.push({
        key: `${info.id}_count`,
        value: `${labCount}`,
        label: `${info.label} Count`,
    });

    currentDonorDataEntry.value = '';
}

function extractDonorInfo(textItems, donorInformationMatchers): DonorDataEntry[] {
    const textLines = cleanUpTextLines(combineText(textItems));
    const donorDataEntries: DonorDataEntry[] = [];

    donorInformationMatchers.filter((info) => !info.mappingOnly).forEach((info) => {
        const currentDonorDataEntry = {
            key: info.id,
            value: '',
            label: info.label,
        };

        const linesToProcess = info.startMatch || info.stopMatch ? getLinesBetween(textLines, info.startMatch, info.stopMatch) : textLines;

        if (!info.computed) {
            if (info.multipleLines && info.subMatch) {
                currentDonorDataEntry.value = getMultipleLinesOfSubMatches(linesToProcess, info);
            } else if (info.multipleLines) {
                currentDonorDataEntry.value = getMultipleLines(linesToProcess, info);
            } else if (info.subMatch) {
                currentDonorDataEntry.value = getSubMatch(linesToProcess, info);
            } else {
                currentDonorDataEntry.value = getMatch(linesToProcess, info);
            }

            if (info.lab) {
                processLab(info, currentDonorDataEntry, donorDataEntries);
            }

            if (info.vital) {
                processVital(info, currentDonorDataEntry, donorDataEntries);
            }
        } else {
            // We encountered a case where the exported PDF contained the NULL unicode character, which is not supported to
            // be inserted into psql. Address this by removing all instances of the unicode
            // eslint-disable-next-line no-control-regex
            currentDonorDataEntry.value = currentDonorDataEntry.value.normalize().replace(/\u0000/g, ' ');
            donorDataEntries.push(currentDonorDataEntry);
        }

        if (currentDonorDataEntry.value !== '') {
            // We encountered a case where the exported PDF contained the NULL unicode character, which is not supported to
            // be inserted into psql. Address this by removing all instances of the unicode
            // eslint-disable-next-line no-control-regex
            currentDonorDataEntry.value = currentDonorDataEntry.value.normalize().replace(/\u0000/g, ' ');
            donorDataEntries.push(currentDonorDataEntry);
        }
    });

    return donorDataEntries.map((entry, _index, entries) => ({
        ...entry,
        // Additional data processing of specific entries
        value: getNormalizedDonorImportValue(entry, entries, donorInformationMatchers),
    }));
}

function getLinesBetween(textLines: string[], start?: string, stop?: string): string[] {
    const lines: string[] = [];
    const startMatcher = start ? new RegExp(start) : undefined;
    const stopMatcher = stop ? new RegExp(stop) : undefined;

    // Set to true if no stopMatcher is used, otherwise set to false
    let startMatched = !stop;

    for (let index = 0; index < textLines.length; index += 1) {
        const line = textLines[index];

        // Look for startMatch line
        if (startMatcher && !startMatched) {
            const startMatch = line.match(startMatcher);

            if (startMatch !== null) {
                startMatched = true;
            }

            // eslint-disable-next-line no-continue
            continue;
        }

        // Stop adding lines if we've already matched previously and encounter the stopMatch line
        if (stopMatcher && (start && startMatched)) {
            const stopMatch = line.match(stopMatcher);

            if (stopMatch !== null) {
                break;
            }
        }

        lines.push(line);
    }

    return lines;
}

function getMatch(textLines: string[], info: any) {
    const matcher = new RegExp(info.matcher);

    for (let index = 0; index < textLines.length; index += 1) {
        const line = textLines[index];
        const match = line.match(matcher);

        if (match !== null) {
            return match[info.matchIndex].trim();
        }
    }

    return '';
}

function getSubMatch(textLines: string[], info: any) {
    const matcher = new RegExp(info.matcher);
    const subMatcher = new RegExp(info.subMatch.matcher);

    let value = '';

    for (let index = 0; index < textLines.length; index += 1) {
        const line = textLines[index];
        const match = line.match(matcher);

        if (match !== null) {
            const {
                linesAway, matchIndex, untilMatch,
            } = info.subMatch;
            const subMatch = textLines[index + linesAway].match(subMatcher);

            if (subMatch !== null) {
                value = subMatch[matchIndex].trim();

                if (untilMatch) {
                    let additionalText = '';
                    const subStopMatcher = new RegExp(untilMatch);

                    for (let subIndex = index + linesAway + 1; subIndex < textLines.length; subIndex += 1) {
                        const subLine = textLines[subIndex];
                        const subLineMatch = subLine.match(subStopMatcher);

                        if (subLineMatch !== null) {
                            value += additionalText;
                            break;
                        }
                        additionalText = `${additionalText} ${subLine}`;
                    }
                }
                break;
            }
        }
    }

    return value;
}

function getMultipleLines(textLines: string[], info: any) {
    const matcher = new RegExp(info.matcher);

    let value = '';

    for (let index = 0; index < textLines.length; index += 1) {
        const line = textLines[index];
        const match = line.match(matcher);

        if (match !== null) {
            const matchData = match[info.matchIndex].trim();

            // // check if donorEntry of same key already exists, if so append to value, else create new entry
            // const donorEntryIndex = donorDataEntries.findIndex((entry) => entry.key === info.id);
            if (value !== '') {
                value += `${info.joiner}${matchData}`;
            } else {
                value = matchData;
            }
        }
    }

    return value;
}

function getMultipleLinesOfSubMatches(textLines: string[], info: any) {
    const matcher = new RegExp(info.matcher);

    let value = '';

    for (let index = 0; index < textLines.length; index += 1) {
        const line = textLines[index];
        const match = line.match(matcher);

        if (match !== null) {
            const subMatchData = getSubMatch(textLines.slice(index), info);
            if (subMatchData !== '') {
                value += `${info.joiner}${subMatchData}`;
            }
        }
    }

    return value;
}

const unknownRaceRegExp = /Not Specified\/Unknown$/;

function getNormalizedDonorImportValue(entry: DonorDataEntry, donorDataEntries: DonorDataEntry[], donorInformationMatchers): string {
    const { value, } = entry;

    switch (entry.key) {
        case 'positive_serologies':
            return computePositiveSerologies(donorDataEntries, donorInformationMatchers);
        case 'medical_social_summary':
            return computeSocialMedicalSummary(donorDataEntries, donorInformationMatchers);
        case 'race':
            return unknownRaceRegExp.test(value) ? value.replace(unknownRaceRegExp, 'Not specified / Unknown') : value;
        case 'cause_of_death': {
            const matchIndex = causesOfDeathMatchers.findIndex((matcher) => matcher.regex.test(value));

            if (matchIndex !== -1) {
                return causesOfDeathMatchers[matchIndex].value;
            }

            break;
        }
        case 'pf_ratio_current':
            return computeCurrentPFRatio(donorDataEntries);
        default:
            return value;
    }

    return value;
}

export default function OfferImport(props: Props) {
    const {
        onImportError,
        onToggleImportPreview,
        onSetImportedDonorData,
        importFile,
    } = props;

    const [donorData, setDonorData] = useState<DonorDataEntry[]>([]);
    const [pdfPages, setPdfPages] = useState([]);
    const prevImportFileRef = useRef<File | void>(undefined);
    const donorInformationMatchers = useSelector((state) => state.donor.pdfImportFields);

    useEffect(() => {
        // check if importFile has changed
        if (importFile && (importFile !== prevImportFileRef.current)) {
            // If file has changed, reset donor data
            setDonorData([]);
            handleFileInput(importFile);
            prevImportFileRef.current = importFile;
        }
    }, [importFile]);

    function handleFileInput(pdf) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(pdf);
        reader.onload = async () => {
            try {
                // $FlowFixMe: At this point, we have read the array and know result will be present, but Flow cannot infer that
                const { pages, donorInfo, } = await parsePdf(new Uint8Array(reader.result), donorInformationMatchers);

                // This is a very simple check to see if we parsed anything, at a minimum we should have UNOS ID
                if (donorInfo.findIndex((entry) => entry.key === 'unos_id') === -1) {
                    onImportError('We could not find any importable information in the PDF, please try another PDF');
                    return;
                }

                setDonorData(donorInfo);
                setPdfPages(pages);
                onSetImportedDonorData(donorInfo, pdf.name);
            } catch (exception) {
                onImportError('There was an error reading the PDF you selected, please check that this is a valid PDF or select a new PDF to import');

                // eslint-disable-next-line no-console
                console.error(exception);
            }
        };
    }

    const onSaveHandler = () => {
        onSetImportedDonorData(donorData);
        onToggleImportPreview();
    };

    const dataEntryChangeHandler = (event) => {
        const { id, value, } = event.target;
        // update donorData with new value, and update state
        const updatedDonorData = donorData.map((entry) => {
            if (entry.key === id) {
                entry.value = value;
            }
            return entry;
        });

        setDonorData(updatedDonorData);
    };

    return (
        <div style={OfferImportStyles.dialogBackdrop}>
            <div style={OfferImportStyles.dataPreviewContainer}>
                <div id="parsedData" style={OfferImportStyles.dataFieldsColumn}>
                    <div style={OfferImportStyles.dataFieldsHeader}>
                        <h2>Donor Information Fields</h2>
                        <Divider variant="middle" />
                    </div>
                    <div style={OfferImportStyles.importPreviewTable}>
                        {donorData.map((entry: DonorDataEntry) => (
                            <div key={entry.key} style={OfferImportStyles.dataEntryField}>
                                <TextField
                                    id={entry.key}
                                    label={entry.label}
                                    defaultValue={entry.value}
                                    fullWidth
                                    onChange={debounce(dataEntryChangeHandler, 500)}
                                />
                            </div>
                        ))}
                        <div style={OfferImportStyles.buttonContainer}>
                            <Button
                                style={OfferImportStyles.cancelButton}
                                type="button"
                                flat
                                ripple={false}
                                onClick={onToggleImportPreview}
                            >
                                Close
                            </Button>
                            <Button
                                style={OfferImportStyles.confirmButton}
                                type="button"
                                flat
                                ripple={false}
                                onClick={onSaveHandler}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
                <div id="pageContainer" style={OfferImportStyles.pdfPreviewColumn}>
                    {pdfPages ? pdfPages.map(({ viewport, textContent, index, }) => (
                        <PdfPage
                            viewport={viewport}
                            textContent={textContent}
                            page={index + 1}
                            key={index}
                        />
                    )) : null}
                </div>
            </div>
        </div>
    );
}
