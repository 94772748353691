// @flow
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from 'react-loading-indicator';
import { donorChanged, updateTaskNote, updateDonorForm } from '../Redux/DonorActions';
import { setSagaMessage } from '../Redux/ApplicationActions';
import { setFormErrors } from '../Redux/FormActions';
import type {
    FormDefData,
    FormDefSection,
    FormDefField,
    FormValueMap,
} from '../Utils/types';
import {
    UI_TYPE_TIME,
    UI_TYPE_DATE,
} from '../Redux/Forms/FormTypes';
import { INVALID_DATE } from '../Utils/time';
import { expandField } from '../Utils/form';
import { useWorkflowForms } from '../Utils/hooks';

import DonorPanelNavigation from './DonorPanelNavigation';
import TaskDetailsBody from './TaskDetailsBody';
import TaskDetailsHeader from './TaskDetailsHeader';
import TaskDetailsHistory from './TaskDetailsHistory';

import styles from './Styles/DonorStyles';
import ChatStyles from '../Containers/Styles/ChatStyles';

type Props = {
    taskId: number,
};

export default function TaskDetails({ taskId, }: Props) {
    const dispatch = useDispatch();
    const isConnected = useSelector((state) => state.chatList.socketStatus === 'connected');
    const dt = useSelector((state) => state.donor.donorTask);
    const donorTaskLoading = useSelector((state) => state.loading.donorTask);
    const taskDataLoading = useSelector((state) => state.loading.taskData);
    const editNoteContent = useSelector((state) => state.donor.editNoteContent);
    const openDonorId = useSelector((state) => state.donor.openDonorId);
    const donor = useSelector((state) => state.donor.donors[openDonorId]);
    const workflowKey = donor ? donor.workflow : '';
    const workflowTasks = useSelector((state) => state.donor.workflowTasks?.[workflowKey]?.tasks);
    const { data: formDefinitions, } = useWorkflowForms(workflowKey);
    const formDef = useMemo(() => {
        if (!workflowTasks) return {};

        const task = workflowTasks.find((t) => t.taskId === taskId);

        return formDefinitions[task?.formId] ?? {};
    }, [workflowTasks, formDefinitions, taskId]);

    const taskData = donor.taskData?.[taskId] ?? {};

    const [workingFormData, setWorkingFormData] = useState<FormValueMap>({ ...taskData, });

    useEffect(() => {
        if (Object.keys(taskData).length > 0) {
            setWorkingFormData({ ...taskData, });
        }
    }, [taskData]);

    //
    // Validates the parameters that are being saved.
    //   Only checks the values that have been set since the user
    //   can always come back and input more values later.  It is
    //   up to the task completion handler to determine that all inputs
    //   that are mandatory have values.
    const validateTask = (taskDefinition: FormDefData): boolean => {
        const {
            formId,
        } = taskDefinition;
        const formErrors = {};
        const sections: Array<FormDefSection> = taskDefinition.sections ? taskDefinition.sections : [];
        sections.forEach((section: FormDefSection) => {
            const fields: Array<FormDefField> = section.fields ? section.fields : [];
            fields.forEach((field: FormDefField) => {
                const fieldTaskData = workingFormData[field.id];
                // Make sure duration uiTypes match the pattern number:number for hours:minutes
                if (field.pattern && fieldTaskData && fieldTaskData.value) {
                    if (!fieldTaskData.value.match(new RegExp(field.pattern))) {
                        formErrors[field.id] = `${field.title}'s value should match the specified pattern`;
                    }
                }

                // Make sure date types have valid dates
                if (field.uiType === UI_TYPE_TIME && fieldTaskData && fieldTaskData.value) {
                    if (fieldTaskData.value === INVALID_DATE) {
                        formErrors[field.id] = `${field.title}'s value should be a valid date and time`;
                    }
                }
                if (field.uiType === UI_TYPE_DATE && fieldTaskData && fieldTaskData.value) {
                    if (fieldTaskData.value === INVALID_DATE) {
                        formErrors[field.id] = `${field.title}'s value should be a valid date`;
                    }
                }
            });
        });

        dispatch(setFormErrors(formId, formErrors));
        return Object.keys(formErrors).length === 0;
    };

    const saveTask = (formId: string, notify: boolean, notifyFullTask: boolean) => {
        // Save the note if it has changed
        if (editNoteContent !== dt.noteContent) {
            dispatch(updateTaskNote(dt.donorId, dt.taskId, editNoteContent, dt.lastModified));
            // Now clear the donorChanged flag
            dispatch(donorChanged(false));
        }

        if (validateTask(formDefinitions[formId])) {
            dispatch(updateDonorForm(dt.donorId, dt.taskId, workingFormData, dt.lastModified, notify, notifyFullTask));
        } else {
            dispatch(setSagaMessage('Save Failed', 'Some values could not be saved.  Please review the errors and try again.', ''));
        }
    };

    const updateWorkingFormData = (formId: string, fieldId: number, data: any) => {
        const formDefData = formDefinitions[formId] || {};
        const field = expandField(formDefData, fieldId, data);
        const updatedFormData = {
            ...workingFormData,
            [fieldId]: field,
        };
        setWorkingFormData(updatedFormData);
    };

    return (
        <div style={{ marginLeft: 10, }}>
            <div style={ChatStyles.stickyHeader}>
                <DonorPanelNavigation title="Task Details" hideSave onSaveClick={() => {}} />
                {donorTaskLoading || taskDataLoading ? (
                    <div style={styles.loadingIndicator}>
                        <LoadingIndicator />
                    </div>
                ) : null}
                <TaskDetailsHeader
                    formDef={formDef}
                    formData={workingFormData}
                    onSaveClick={(formId, notify, notifyFullTask) => saveTask(formId, notify, notifyFullTask)}
                    isConnected={isConnected}
                />
            </div>
            <TaskDetailsBody
                onFieldUpdate={updateWorkingFormData}
                workingFormData={workingFormData}
                taskId={taskId}
                formDef={formDef}
                isConnected={isConnected}
            />
            <TaskDetailsHistory />
        </div>
    );
}
