// @flow
import type { Logout } from 'txp-core';

import type { ResetData } from './ApplicationActions';
import type { FollowerEditState } from './FollowerEditActions';
import type {
    Donor,
    DonorSortBy,
    SortDirection,
    DonorMap,
    FileMap,
    MessageId,
    AttachmentData,
    FollowerGroupMap,
    UserProfile,
    StaticTask,
    WorkflowTaskMap,
    DonorTask,
    FormValueMap, TaskDataMap, DonorType, Tag, DonorDataEntry, DonorImportField,
    CaseNote,
    FormDefData,
    LoadCasesParams,
} from '../Utils/types';

export const NOTIFIABLE_GROUP_ID_1 = 1; // standard donor tasks
export const NOTIFIABLE_GROUP_ID_2 = 2; // OmniLife release tasks
export const NOTIFIABLE_GROUP_ID_3 = 3; // Experience Anatomy tasks

export type DonorErrors = {
    nonFieldErrors?: string,
};

export type DonorState = {
    donors: DonorMap,
    openDonorId: ?number,
    openFollowerGroupId: ?number,
    navigateTaskId: ?number,

    workflowTasks: WorkflowTaskMap,
    donorTask: DonorTask,
    editNoteContent: string,

    opoDonorId: string,
    donorType: ?DonorType,
    workflowKey: string,
    hospital: string,
    sex: ?string,
    dateOfBirth: ?string,
    unosId: string,
    organs: Array<string>,
    newRace: string,
    highRisk: boolean,
    tags: Array<Tag>,
    orgMembers: Array<UserProfile>,
    caseAdminUsers: Array<UserProfile>,

    donorUpdated: boolean,

    createDonorSuccess: boolean,
    errors: ?DonorErrors,

    files: Array<FileMap>,
    fileIds: Array<MessageId>,
    filter: string,

    availableTags: Array<Tag>,
    pdfImportFields: Array<DonorImportField>,
    casePreferences: Array<FollowerEditState>, // NOTE: we may add more than just follower info to this in the future

    sort: {
        column: DonorSortBy,
        direction: SortDirection,
    },

    totalFilteredCases: number,
    caseDisplayOrder: number[],
};

export const donorSortDefault = {
    column: 'CREATE_DATE',
    direction: 'desc',
};

const initialState: DonorState = {
    donors: {},
    openDonorId: null,
    openFollowerGroupId: null,
    navigateTaskId: null,

    workflowTasks: {},
    donorTask: {},
    editNoteContent: '',

    opoDonorId: '',
    donorType: null,
    workflowKey: '',
    hospital: '',
    sex: null,
    dateOfBirth: null,
    unosId: '',
    organs: [],
    newRace: '',
    highRisk: false,
    tags: [],
    orgMembers: [],
    caseAdminUsers: [],

    donorUpdated: false,

    createDonorSuccess: false,
    errors: null,

    files: [],
    fileIds: [],
    filter: '',

    availableTags: [],
    caseTagFilter: [],
    pdfImportFields: [],

    workflowTypes: [],
    casePreferences: [],

    sort: donorSortDefault,
    totalFilteredCases: 0,
    caseDisplayOrder: [],
};

export type LoadCases = {
    type: 'Donor/LOAD_CASES',
    params: LoadCasesParams,
};
export type ReceiveDonors = { type: 'Donor/RECEIVE_DONORS', donors: DonorMap };
export type GetDonor = { type: 'Donor/GET_DONOR', donorId: number };
export type ReceiveSingleDonor = { type: 'Donor/RECEIVE_SINGLE_DONOR', donorId: number, donor: Donor };
export type OpenDonor = { type: 'Donor/OPEN_DONOR', openDonorId: number };
export type CloseDonor = { type: 'Donor/CLOSE_DONOR', donorId: number };
export type UpdateDonorStatus = { type: 'Donor/UPDATE_DONOR_STATUS', donorId: number, closed: boolean, lastModified: string };

export type SetOPODonorId = { type: 'Donor/SET_OPO_ID', opoDonorId: string };
export type SetUnosId = { type: 'Donor/SET_UNOS_ID', unosId: string };
export type SetDonorType = { type: 'Donor/SET_DONOR_TYPE', donorType: ?DonorType };
export type SetWorkflowKey = { type: 'Donor/SET_WORKFLOW_KEY', workflowKey: string };
export type SetHospital = { type: 'Donor/SET_HOSPITAL', hospital: string };
export type SetSex = { type: 'Donor/SET_SEX', sex: ?string };
export type SetDateOfBirth = { type: 'Donor/SET_DOB', dateOfBirth: string };
export type SetSelectedOrgans = { type: 'Donor/SET_SELECTED_ORGANS', organs: Array<string> };
export type SetNewRace = { type: 'Donor/SET_RACE', newRace: string };
export type SetHighRisk = { type: 'Donor/SET_HIGH_RISK', highRisk: boolean };
export type SetTags = { type: 'Donor/SET_TAGS', tags: Array<Tag> };
export type SetSort = { type: 'Donor/SET_SORT', column: DonorSortBy, direction: SortDirection };

export type GetOrganizationMembers = { type: 'Donor/GET_ORG_MEMBERS' };
export type ReceiveOrganizationMembers = { type: 'Donor/RECEIVE_ORG_MEMBERS', orgMembers: Array<UserProfile> };
export type GetCaseAdminUsers = { type: 'Donor/GET_CASE_ADMIN_USERS', donorId: number };
export type ReceiveCaseAdminUsers = { type: 'Donor/RECEIVE_CASE_ADMIN_USERS', caseAdminUsers: Array<UserProfile> };

export type ReceiveAvailableTags = { type: 'Donor/RECEIVE_AVAILABLE_TAGS', availableTags: Array<Tag> };
export type ReceivePdfFields = { type: 'Donor/RECEIVE_PDF_FIELDS', pdfFields: Array<DonorImportField> };
export type LoadPdfFields = { type: 'Donor/LOAD_PDF_FIELDS', pdfFields: Array<DonorImportField> };

export type CreateDonor = {
    type: 'Donor/CREATE',
    opoDonorId: string,
    donorType: ?DonorType,
    workflowKey: string,
    highRisk: boolean,
    tags: Array<Tag>,
    hospital: string,
    sex: ?string,
    dateOfBirth: string,
    unosId: string,
    organs: Array<string>,
    importedDonorData?: Array<DonorDataEntry>,
    donorNetPdf?: File,
};
export type CreateDonorFailed = { type: 'Donor/CREATE_FAILED', errors: ?DonorErrors };
export type CreateDonorSuccess = { type: 'Donor/CREATE_SUCCESS', openDonorId: number };
export type ResetCreateDonorSuccess = { type: 'Donor/RESET_CREATE_SUCCESS' };

export type UpdateDonor = {
    type: 'Donor/UPDATE',
    donorId: number,
    lastModified: string,
    opoDonorId: string,
    highRisk: boolean,
    tags: Array<Tag>,
    unosId: string | null,
    currentLocation: string,
    dob: string,
    typeOfDeath: string | null,
    causeOfDeath: string | null,
    race: string | null,
    sex: string | null,
    weight: string,
    height: string,
    organs: Array<string>,
};
export type UpdateDonorSuccess = { type: 'Donor/UPDATE_SUCCESS', donorId: number, donor: Donor };

export type DonorChanged = { type: 'Donor/CHANGED', donorUpdated: boolean };

export type FilterDonors = { type: 'Donor/FILTER_DONORS', filter: string };

export type LoadDonorFiles = { type: 'Donor/LOAD_FILES', donorId: number };
export type ReceiveDonorFiles = {
    type: 'Donor/RECEIVE_FILES',
    donorId: number,
    fileMap: FileMap,
    fileIds: Array<MessageId>,
};
export type SetDonorSelectedFile = {
    type: 'Donor/SET_SELECTED_FILE',
    id: number,
    selectedFile: File,
};
export type SetDonorLocalPath = { type: 'Donor/SET_LOCAL_PATH', donorId: number, messageId: number, localPath: string };
export type UploadDonorFile = { type: 'Donor/UPLOAD_DONOR_FILE', donorId: number, attachments: Array<AttachmentData>, isCaseAttachment?: boolean };
export type UploadDonorFileSuccess = { type: 'Donor/UPLOAD_DONOR_FILE_SUCCESS' };
export type UploadDonorFileFailed = { type: 'Donor/UPLOAD_DONOR_FILE_FAILED', message: string };

export type DeleteDonorFile = { type: 'Donor/DELETE_DONOR_FILE', mediaFileId: number, donorId: number};
export type DeleteDonorFileSuccess = { type: 'Donor/DELETE_DONOR_FILE_SUCCESS' };
export type DeleteDonorFileFailed = { type: 'Donor/DELETE_DONOR_FILE_FAILED', message: string };

export type LoadTasks = { type: 'Donor/LOAD_TASKS', workflow: ?string, donorId: ?number };
export type ReceiveTasks = { type: 'Donor/RECEIVE_TASKS', workflow: string, tasks: Array<StaticTask>, sectionOrder: Array<string> };
export type GetDonorTask = { type: 'Donor/GET_DONOR_TASK', donorId: number, taskId: number };
export type ReceiveDonorTask = { type: 'Donor/RECEIVE_DONOR_TASK', donorTask: DonorTask };
export type GetDonorTaskData = { type: 'Donor/GET_DONOR_TASK_DATA', donorId: number };
export type ReceiveDonorTaskData = { type: 'Donor/RECEIVE_DONOR_TASK_DATA', donorId: number, taskData: TaskDataMap };

export type OpenFollowerGroup = { type: 'Donor/OPEN_FOLLOWER_GROUP', followerGroupId: ?number };
export type CloseFollowerGroup = { type: 'Donor/CLOSE_FOLLOWER_GROUP', followerGroupId: number };
export type GetDonorFollowerGroups = { type: 'Donor/GET_DONOR_FOLLOWER_GROUPS', donorId: number };
export type GetFollowerGroup = { type: 'Donor/GET_FOLLOWER_GROUP', donorId: number, followerGroupId: number };
export type ReceiveDonorFollowerGroups = { type: 'Donor/RECEIVE_DONOR_FOLLOWER_GROUPS', donorId: number, followerGroupMap: FollowerGroupMap };
export type UpdateFollowerGroup = { type: 'Donor/UPDATE_FOLLOWER_GROUP', donorId: number, followerGroup: any };
export type UpdateFollowerGroupTasks = {type: 'Donor/UPDATE_FOLLOWER_GROUP_TASKS', donorId: number, followerGroupId: number, taskIds: number[]};
export type UpdateFollowerGroupMembers = {
    type: 'Donor/UPDATE_FOLLOWER_GROUP_MEMBERS',
    donorId: number,
    followerGroupId: number,
    memberUserIds: Array<number>,
    externalUserIds: Array<number>
};
export type UpdateFollowerGroupPermissions = {
    type: 'Donor/UPDATE_FOLLOWER_GROUP_PERMISSIONS',
    donorId: number,
    followerGroupId: number,
    canUpdate: boolean,
};
export type DeleteFollowerGroup = { type: 'Donor/DELETE_FOLLOWER_GROUP', donorId: number, followerGroupId: number };

export type UpdateTaskStatus = {
    type: 'Donor/UPDATE_TASK_STATUS',
    donorId: number,
    taskId: number,
    lastModified: string,
    fieldValueMap: FormValueMap,
    completed: ?boolean,
    notApplicable: ?boolean,
    fromList: boolean,
    formDef: FormDefData,
};
export type UpdateTaskDueDate = {
    type: 'Donor/UPDATE_TASK_DUE_DATE',
    donorId: number,
    taskId: number,
    previousTaskId: ?number,
    interval: ?number,
    lastModified: string,
};
export type UpdateTaskNote = {
    type: 'Donor/UPDATE_TASK_NOTE',
    donorId: number,
    taskId: number,
    noteContent: string,
    lastModified: string
};
export type SaveTaskNote = {
    type: 'Donor/SAVE_TASK_NOTE',
    noteContent: string
};
export type UpdateDonorForm = {
    type: 'Form/UPDATE_DONOR_FORM',
    donorId: number,
    taskId: number,
    formValueMap: FormValueMap,
    lastModified: string,
    notify: boolean,
    notifyFullTask: boolean,
};

export type ResetDonorEditState = { type: 'Donor/RESET' };

export type DonorTagFilters = {
     type: 'Donor/RECEIVE_TAG_FILTER',
     tags: string[],
};

export type WorkflowTypeFilters = {
    type: 'Donor/RECEIVE_WORKFLOW_FILTER',
    workflowTypes: string[],
}
export type SaveCaseNote = { type: 'Donor/SAVE_CASE_NOTE', caseId: number, noteContent: string };
export type ReceiveCaseNotes = { type: 'Donor/RECEIVE_CASE_NOTES', caseId: number, notes: CaseNote[] };
export type SetCasePreferences = { type: 'Donor/SET_CASE_PREFERENCES', casePreferences: FollowerEditState[] };
export type ResetCasePreferences = { type: 'Donor/RESET_CASE_PREFERENCES' };
// NOTE: Eventually we shouldn't need to the donorId for loading case preferences if they are just tied to a workflow
// and now the last case
export type LoadCasePreferences = { type: 'Donor/LOAD_CASE_PREFERENCES', workflow: string, caseId: number };
export type NavigateToTask = { type: 'Donor/NAVIGATE_TO_TASK', taskId: number, donorId: number };
export type ResetNavigateToTask = { type: 'Donor/RESET_NAVIGATE_TO_TASK' };
export type ReceiveTotalFilteredCases = { type: 'Donor/RECEIVE_TOTAL_FILTERED_CASES', totalFilteredCases: number };
export type SetCaseDisplayOrder = { type: 'Donor/SET_CASE_DISPLAY_ORDER', caseDisplayOrder: number[] };

type Action =
    | LoadCases
    | ReceiveDonors
    | GetDonor
    | ReceiveSingleDonor
    | OpenDonor
    | CloseDonor
    | UpdateDonorStatus
    | SetOPODonorId
    | SetUnosId
    | SetDonorType
    | SetWorkflowKey
    | SetHospital
    | SetSex
    | SetDateOfBirth
    | SetSelectedOrgans
    | SetNewRace
    | SetHighRisk
    | SetTags
    | SetSort
    | GetOrganizationMembers
    | ReceiveOrganizationMembers
    | GetCaseAdminUsers
    | ReceiveCaseAdminUsers
    | ReceiveAvailableTags
    | ReceivePdfFields
    | LoadPdfFields
    | CreateDonor
    | CreateDonorFailed
    | CreateDonorSuccess
    | ResetCreateDonorSuccess
    | UpdateDonor
    | UpdateDonorSuccess
    | DonorChanged
    | FilterDonors
    | LoadDonorFiles
    | ReceiveDonorFiles
    | SetDonorLocalPath
    | SetDonorSelectedFile
    | UploadDonorFile
    | UploadDonorFileSuccess
    | UploadDonorFileFailed
    | DeleteDonorFile
    | UpdateTaskDueDate
    | UpdateTaskNote
    | SaveTaskNote
    | UpdateDonorForm
    | LoadTasks
    | ReceiveTasks
    | GetDonorTask
    | ReceiveDonorTask
    | GetDonorTaskData
    | ReceiveDonorTaskData
    | ReceiveDonorFollowerGroups
    | OpenFollowerGroup
    | CloseFollowerGroup
    | UpdateTaskStatus
    | ResetDonorEditState
    | Logout
    | ResetData
    | DonorTagFilters
    | WorkflowTypeFilters
    | SaveCaseNote
    | ReceiveCaseNotes
    | SetCasePreferences
    | ResetCasePreferences
    | LoadCasePreferences
    | NavigateToTask
    | ResetNavigateToTask
    | ReceiveTotalFilteredCases
    | SetCaseDisplayOrder;

export const loadCases = (
    params: LoadCasesParams
): LoadCases => ({
    type: 'Donor/LOAD_CASES',
    params,
});

export const receiveDonors = (donors: DonorMap): ReceiveDonors => ({
    type: 'Donor/RECEIVE_DONORS',
    donors,
});

export const getDonor = (donorId: number): GetDonor => ({
    type: 'Donor/GET_DONOR',
    donorId,
});

export const receiveSingleDonor = (donorId: number, donor: Donor): ReceiveSingleDonor => ({
    type: 'Donor/RECEIVE_SINGLE_DONOR',
    donorId,
    donor,
});

export const openDonor = (openDonorId: number): OpenDonor => ({
    type: 'Donor/OPEN_DONOR',
    openDonorId,
});

export const closeDonor = (donorId: number): CloseDonor => ({
    type: 'Donor/CLOSE_DONOR',
    donorId,
});

export const updateDonorStatus = (donorId: number, closed: boolean, lastModified: string): UpdateDonorStatus => ({
    type: 'Donor/UPDATE_DONOR_STATUS',
    donorId,
    closed,
    lastModified,
});

export const updateTaskDueDate = (
    donorId: number,
    taskId: number,
    previousTaskId: ?number,
    interval: ?number,
    lastModified: string
): UpdateTaskDueDate => ({
    type: 'Donor/UPDATE_TASK_DUE_DATE',
    donorId,
    taskId,
    previousTaskId,
    interval,
    lastModified,
});

export const updateTaskNote = (
    donorId: number,
    taskId: number,
    noteContent: string,
    lastModified: string
): UpdateTaskNote => ({
    type: 'Donor/UPDATE_TASK_NOTE',
    donorId,
    taskId,
    noteContent,
    lastModified,
});

export const saveTaskNote = (
    noteContent: string
): SaveTaskNote => ({
    type: 'Donor/SAVE_TASK_NOTE',
    noteContent,
});

export const setOPODonorId = (opoDonorId: string): SetOPODonorId => ({
    type: 'Donor/SET_OPO_ID',
    opoDonorId,
});

export const setUnosId = (unosId: string): SetUnosId => ({
    type: 'Donor/SET_UNOS_ID',
    unosId,
});

export const setDonorType = (donorType: ?DonorType): SetDonorType => ({
    type: 'Donor/SET_DONOR_TYPE',
    donorType,
});

export const setWorkflowKey = (workflowKey: string): SetWorkflowKey => ({
    type: 'Donor/SET_WORKFLOW_KEY',
    workflowKey,
});

export const setHospital = (hospital: string): SetHospital => ({
    type: 'Donor/SET_HOSPITAL',
    hospital,
});

export const setSex = (sex: ?string): SetSex => ({
    type: 'Donor/SET_SEX',
    sex,
});

export const setDateOfBirth = (dateOfBirth: string): SetDateOfBirth => ({
    type: 'Donor/SET_DOB',
    dateOfBirth,
});

export const setSelectedOrgans = (organs: Array<string>): SetSelectedOrgans => ({
    type: 'Donor/SET_SELECTED_ORGANS',
    organs,
});

export const setNewRace = (newRace: string): SetNewRace => ({
    type: 'Donor/SET_RACE',
    newRace,
});

export const setHighRisk = (highRisk: boolean): SetHighRisk => ({
    type: 'Donor/SET_HIGH_RISK',
    highRisk,
});

export const setTags = (tags: Array<Tag>): SetTags => ({
    type: 'Donor/SET_TAGS',
    tags,
});

export const setSort = (column: DonorSortBy, direction: SortDirection): SetSort => ({
    type: 'Donor/SET_SORT',
    column,
    direction,
});

export const getOrganizationMembers = (): GetOrganizationMembers => ({
    type: 'Donor/GET_ORG_MEMBERS',
});

export const receiveOrganizationMembers = (orgMembers: Array<UserProfile>): ReceiveOrganizationMembers => ({
    type: 'Donor/RECEIVE_ORG_MEMBERS',
    orgMembers,
});

export const getCaseAdminUsers = (donorId: number): GetCaseAdminUsers => ({
    type: 'Donor/GET_CASE_ADMIN_USERS',
    donorId,
});

export const receiveCaseAdminUsers = (caseAdminUsers: Array<UserProfile>): ReceiveCaseAdminUsers => ({
    type: 'Donor/RECEIVE_CASE_ADMIN_USERS',
    caseAdminUsers,
});

export const receiveAvailableTags = (availableTags: Array<Tag>): ReceiveAvailableTags => ({
    type: 'Donor/RECEIVE_AVAILABLE_TAGS',
    availableTags,
});

export const receivePdfFields = (pdfFields: Array<DonorImportField>): ReceivePdfFields => ({
    type: 'Donor/RECEIVE_PDF_FIELDS',
    pdfFields,
});

export const loadPdfFields = (pdfFields: Array<DonorImportField>): LoadPdfFields => ({
    type: 'Donor/LOAD_PDF_FIELDS',
    pdfFields,
});

export const createDonor = (
    opoDonorId: string,
    donorType: ?DonorType,
    highRisk: boolean,
    tags: Array<Tag>,
    workflowKey: string,
    hospital: string,
    sex: ?string,
    dateOfBirth: string,
    unosId: string,
    organs: Array<string>,
    importedDonorData?: Array<DonorDataEntry>,
    donorNetPdf?: File
): CreateDonor => ({
    type: 'Donor/CREATE',
    opoDonorId,
    donorType,
    highRisk,
    tags,
    workflowKey,
    hospital,
    sex,
    dateOfBirth,
    unosId,
    organs,
    importedDonorData,
    donorNetPdf,
});

export const createDonorFailed = (errors: ?DonorErrors): CreateDonorFailed => ({
    type: 'Donor/CREATE_FAILED',
    errors,
});

export const createDonorSuccess = (openDonorId: number): CreateDonorSuccess => ({
    type: 'Donor/CREATE_SUCCESS',
    openDonorId,
});

export const resetCreateDonorSuccess = (): ResetCreateDonorSuccess => ({
    type: 'Donor/RESET_CREATE_SUCCESS',
});

export const updateDonor = (
    donorId: number,
    lastModified: string,
    opoDonorId: string,
    highRisk: boolean,
    tags: Array<Tag>,
    unosId: string | null,
    currentLocation: string,
    dob: string,
    typeOfDeath: string | null,
    causeOfDeath: string | null,
    race: string | null,
    sex: string | null,
    weight: string,
    height: string,
    organs: Array<string>
): UpdateDonor => ({
    type: 'Donor/UPDATE',
    donorId,
    lastModified,
    opoDonorId,
    highRisk,
    tags,
    unosId,
    currentLocation,
    dob,
    typeOfDeath,
    causeOfDeath,
    race,
    sex,
    weight,
    height,
    organs,
});

export const updateDonorSuccess = (donorId: number, donor: Donor): UpdateDonorSuccess => ({
    type: 'Donor/UPDATE_SUCCESS',
    donorId,
    donor,
});

export const donorChanged = (donorUpdated: boolean): DonorChanged => ({
    type: 'Donor/CHANGED',
    donorUpdated,
});

export const filterDonors = (filter: string): FilterDonors => ({
    type: 'Donor/FILTER_DONORS',
    filter,
});

export const loadDonorFiles = (donorId: number): LoadDonorFiles => ({
    type: 'Donor/LOAD_FILES',
    donorId,
});

export const uploadDonorFile = (donorId: number, attachments: Array<AttachmentData>, isCaseAttachment?: boolean): UploadDonorFile => ({
    type: 'Donor/UPLOAD_DONOR_FILE',
    donorId,
    attachments: attachments || [],
    isCaseAttachment,
});

export const deleteDonorFile = (mediaFileId: number, donorId: number): DeleteDonorFile => ({
    type: 'Donor/DELETE_DONOR_FILE',
    mediaFileId,
    donorId,
});

export const deleteDonorFileSuccess = (): DeleteDonorFileSuccess => ({
    type: 'Donor/DELETE_DONOR_FILE_SUCCESS',
});

export const deleteDonorFileFailed = (message: string): DeleteDonorFileFailed => ({
    type: 'Donor/DELETE_DONOR_FILE_FAILED',
    message,
});

export const receiveDonorFiles = (
    donorId: number,
    fileMap: FileMap,
    fileIds: Array<MessageId>
): ReceiveDonorFiles => ({
    type: 'Donor/RECEIVE_FILES',
    donorId,
    fileMap,
    fileIds,
});

export const setDonorLocalPath = (donorId: number, messageId: number, localPath: string): SetDonorLocalPath => ({
    type: 'Donor/SET_LOCAL_PATH',
    donorId,
    messageId,
    localPath,
});

export const setDonorSelectedFile = (id: number, selectedFile: File): SetDonorSelectedFile => ({
    type: 'Donor/SET_SELECTED_FILE',
    id,
    selectedFile,
});

export const uploadDonorFileSuccess = (): UploadDonorFileSuccess => ({
    type: 'Donor/UPLOAD_DONOR_FILE_SUCCESS',
});

export const uploadDonorFileFailed = (message: string): UploadDonorFileFailed => ({
    type: 'Donor/UPLOAD_DONOR_FILE_FAILED',
    message,
});

export const loadTasks = (workflow: ?string, donorId: number): LoadTasks => ({
    type: 'Donor/LOAD_TASKS',
    workflow,
    donorId,
});

export const receiveTasks = (workflow: string, tasks: Array<StaticTask>, sectionOrder: Array<string>): ReceiveTasks => ({
    type: 'Donor/RECEIVE_TASKS',
    workflow,
    tasks,
    sectionOrder,
});

export const getDonorTask = (donorId: number, taskId: number): GetDonorTask => ({
    type: 'Donor/GET_DONOR_TASK',
    donorId,
    taskId,
});

export const receiveDonorTask = (donorTask: DonorTask): ReceiveDonorTask => ({
    type: 'Donor/RECEIVE_DONOR_TASK',
    donorTask,
});

export const getDonorTaskData = (donorId: number): GetDonorTaskData => ({
    type: 'Donor/GET_DONOR_TASK_DATA',
    donorId,
});

export const receiveDonorTaskData = (donorId: number, taskData: TaskDataMap): ReceiveDonorTaskData => ({
    type: 'Donor/RECEIVE_DONOR_TASK_DATA',
    donorId,
    taskData,
});

export const updateTaskStatus = (
    donorId: number,
    taskId: number,
    lastModified: string,
    fieldValueMap: FormValueMap,
    completed: ?boolean,
    notApplicable: ?boolean,
    fromList: boolean,
    formDef: FormDefData
): UpdateTaskStatus => ({
    type: 'Donor/UPDATE_TASK_STATUS',
    donorId,
    taskId,
    lastModified,
    fieldValueMap,
    completed,
    notApplicable,
    fromList,
    formDef,
});

export const openFollowerGroup = (followerGroupId: ?number): OpenFollowerGroup => ({
    type: 'Donor/OPEN_FOLLOWER_GROUP',
    followerGroupId,
});

export const closeFollowerGroup = (followerGroupId: number): CloseFollowerGroup => ({
    type: 'Donor/CLOSE_FOLLOWER_GROUP',
    followerGroupId,
});

export const getDonorFollowerGroups = (donorId: number): GetDonorFollowerGroups => ({
    type: 'Donor/GET_DONOR_FOLLOWER_GROUPS',
    donorId,
});

export const getFollowerGroup = (donorId: number, followerGroupId: number): GetFollowerGroup => ({
    type: 'Donor/GET_FOLLOWER_GROUP',
    donorId,
    followerGroupId,
});

export const receiveDonorFollowerGroups = (donorId: number, followerGroupMap: FollowerGroupMap): ReceiveDonorFollowerGroups => ({
    type: 'Donor/RECEIVE_DONOR_FOLLOWER_GROUPS',
    donorId,
    followerGroupMap,
});

export const updateFollowerGroup = (donorId: number, followerGroup: any): UpdateFollowerGroup => ({
    type: 'Donor/UPDATE_FOLLOWER_GROUP',
    donorId,
    followerGroup,
});

export const updateFollowerGroupTasks = (donorId: number, followerGroupId: number, taskIds: number[]): UpdateFollowerGroupTasks => ({
    type: 'Donor/UPDATE_FOLLOWER_GROUP_TASKS',
    donorId,
    followerGroupId,
    taskIds,
});

export const updateFollowerGroupPermissions = (
    donorId: number,
    followerGroupId: number,
    canUpdate: boolean
): UpdateFollowerGroupPermissions => ({
    type: 'Donor/UPDATE_FOLLOWER_GROUP_PERMISSIONS',
    donorId,
    followerGroupId,
    canUpdate,
});

export const deleteFollowerGroup = (donorId: number, followerGroupId: number): DeleteFollowerGroup => ({
    type: 'Donor/DELETE_FOLLOWER_GROUP',
    donorId,
    followerGroupId,
});

export const updateTagFilter = (tags: string[]): DonorTagFilters => ({
    type: 'Donor/RECEIVE_TAG_FILTER',
    tags,
});

export const updateWorkflowTypeFilter = (workflowTypes: string[]): WorkflowTypeFilters => ({
    type: 'Donor/RECEIVE_WORKFLOW_FILTER',
    workflowTypes,
});

export const updateFollowerGroupMembers = (
    donorId: number,
    followerGroupId: number,
    memberUserIds: Array<number>,
    externalUserIds: Array<number>
): UpdateFollowerGroupMembers => ({
    type: 'Donor/UPDATE_FOLLOWER_GROUP_MEMBERS',
    donorId,
    followerGroupId,
    memberUserIds,
    externalUserIds,
});

export const updateDonorForm = (
    donorId: number,
    taskId: number,
    formValueMap: FormValueMap,
    lastModified: string,
    notify: boolean,
    notifyFullTask: boolean
): UpdateDonorForm => ({
    type: 'Form/UPDATE_DONOR_FORM',
    donorId,
    taskId,
    formValueMap,
    lastModified,
    notify,
    notifyFullTask,
});

export const saveCaseNote = (
    caseId: number,
    noteContent: string
): SaveCaseNote => ({
    type: 'Donor/SAVE_CASE_NOTE',
    caseId,
    noteContent,
});

export const receiveCaseNotes = (
    caseId: number,
    notes: CaseNote[]
): ReceiveCaseNotes => ({
    type: 'Donor/RECEIVE_CASE_NOTES',
    caseId,
    notes,
});

export const setCasePreferences = (
    casePreferences: FollowerEditState[]
): SetCasePreferences => ({
    type: 'Donor/SET_CASE_PREFERENCES',
    casePreferences,
});

export const resetCasePreferences = (): ResetCasePreferences => ({ type: 'Donor/RESET_CASE_PREFERENCES', });
export const loadCasePreferences = (
    workflow: string,
    caseId: number
): LoadCasePreferences => ({
    type: 'Donor/LOAD_CASE_PREFERENCES',
    workflow,
    caseId,
});

export const resetDonorEditState = (): ResetDonorEditState => ({
    type: 'Donor/RESET',
});

export const navigateToTask = (donorId: number, taskId: number): NavigateToTask => ({
    type: 'Donor/NAVIGATE_TO_TASK',
    donorId,
    taskId,
});

export const resetNavigateToTask = (): ResetNavigateToTask => ({
    type: 'Donor/RESET_NAVIGATE_TO_TASK',
});

export const receiveTotalFilteredCases = (totalFilteredCases: number): ReceiveTotalFilteredCases => ({
    type: 'Donor/RECEIVE_TOTAL_FILTERED_CASES',
    totalFilteredCases,
});

export const setCaseDisplayOrder = (caseDisplayOrder: number[]): SetCaseDisplayOrder => ({
    type: 'Donor/SET_CASE_DISPLAY_ORDER',
    caseDisplayOrder,
});

const setDonorLocalPathReducer = (state: DonorState, action: SetDonorLocalPath): DonorState => {
    const {
        donorId,
        messageId,
        localPath,
    } = action;

    let resState = {
        ...state,
    };

    const donor = { ...state.donors[donorId], };

    if (donor.files && donor.files[messageId] && !donor.files[messageId].localPath) {
        resState = {
            ...state,

            donors: {
                ...state.donors,

                [donorId]: {
                    ...state.donors[donorId],

                    files: {
                        ...state.donors[donorId].files,

                        [messageId]: {
                            ...state.donors[donorId].files[messageId],

                            localPath,
                        },
                    },
                },
            },
        };
    }

    return resState;
};

const reducer = (state: DonorState = initialState, action: Action): DonorState => {
    switch (action.type) {
        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            // Reset the store when logging out
            return {
                ...initialState,
            };

        case 'Donor/RECEIVE_DONORS':
            return {
                ...state,

                donors: action.donors,
            };

        case 'Donor/RECEIVE_SINGLE_DONOR':
            return {
                ...state,

                donors: {
                    ...state.donors,

                    [action.donorId]: {
                        ...state.donors[action.donorId],

                        ...action.donor,
                    },
                },
            };

        case 'Donor/RECEIVE_PDF_FIELDS':
            return {
                ...state,
                // $FlowFixMe - pdfFields does not contain a fields property and yet it seems required by the reducer
                pdfImportFields: action.pdfFields.fields,
            };

        case 'Donor/CREATE_SUCCESS':
            return {
                ...state,

                openDonorId: action.openDonorId,
                createDonorSuccess: true,
            };

        case 'Donor/RESET_CREATE_SUCCESS':
            return {
                ...state,

                createDonorSuccess: false,
            };

        case 'Donor/OPEN_DONOR':
            return {
                ...state,

                openDonorId: action.openDonorId,
            };

        case 'Donor/CLOSE_DONOR':
            return {
                ...state,

                openDonorId: action.donorId === state.openDonorId ? null : state.openDonorId,
            };

        case 'Donor/SET_OPO_ID':
            return {
                ...state,

                opoDonorId: action.opoDonorId,
            };

        case 'Donor/SET_UNOS_ID':
            return {
                ...state,

                unosId: action.unosId,
            };

        case 'Donor/SET_DONOR_TYPE':
            return {
                ...state,

                donorType: action.donorType,
            };

        case 'Donor/SET_WORKFLOW_KEY':
            return {
                ...state,

                workflowKey: action.workflowKey,
            };

        case 'Donor/SET_HOSPITAL':
            return {
                ...state,

                hospital: action.hospital,
            };

        case 'Donor/SET_SEX':
            return {
                ...state,

                sex: action.sex,
            };

        case 'Donor/SET_HIGH_RISK':
            return {
                ...state,

                highRisk: action.highRisk,
            };

        case 'Donor/SET_TAGS':
            return {
                ...state,

                tags: action.tags,
            };

        case 'Donor/SET_SORT':
            return {
                ...state,

                sort: {
                    column: action.column,
                    direction: action.direction,
                },
            };

        case 'Donor/SET_DOB':
            return {
                ...state,

                dateOfBirth: action.dateOfBirth,
            };

        case 'Donor/SET_SELECTED_ORGANS':
            return {
                ...state,

                organs: [...action.organs],
            };

        case 'Donor/RECEIVE_ORG_MEMBERS':
            return {
                ...state,

                orgMembers: action.orgMembers,
            };

        case 'Donor/RECEIVE_CASE_ADMIN_USERS':
            return {
                ...state,

                caseAdminUsers: action.caseAdminUsers,
            };

        case 'Donor/RECEIVE_AVAILABLE_TAGS':
            return {
                ...state,

                availableTags: action.availableTags,
            };

        case 'Donor/RECEIVE_WORKFLOW_FILTER':
            return {
                ...state,

                workflowTypes: action.workflowTypes,
            };

        case 'Donor/RECEIVE_TAG_FILTER':
            return {
                ...state,

                caseTagFilter: action.tags,
            };

        case 'Donor/CREATE_FAILED':
            return {
                ...state,

                errors: action.errors,
            };

        case 'Donor/UPDATE_SUCCESS':
            return {
                ...state,

                donors: {
                    ...state.donors,

                    [action.donorId]: {
                        ...state.donors[action.donorId],

                        ...action.donor,
                    },
                },

                donorUpdated: false,
            };

        case 'Donor/SET_SELECTED_FILE':
            return {
                ...state,

                donors: {
                    ...state.donors,
                    [action.id]: {
                        ...state.donors[action.id],

                        selectedFile: action.selectedFile,
                    },
                },
            };

        case 'Donor/CHANGED':
            return {
                ...state,

                donorUpdated: action.donorUpdated,
            };

        case 'Donor/SET_LOCAL_PATH':
            return setDonorLocalPathReducer(state, action);

        case 'Donor/FILTER_DONORS':
            return {
                ...state,

                filter: action.filter,
            };
        case 'Donor/RECEIVE_FILES':
            return {
                ...state,
                donors: {
                    ...state.donors,
                    [action.donorId]: {
                        ...state.donors[action.donorId],

                        files: action.fileMap,
                        fileIds: action.fileIds,
                    },
                },
            };

        case 'Donor/RECEIVE_TASKS':
            return {
                ...state,

                workflowTasks: {
                    ...state.workflowTasks,

                    [action.workflow]: {
                        ...state.workflowTasks[action.workflow],

                        tasks: action.tasks,
                        sectionOrder: action.sectionOrder,
                    },
                },
            };

        case 'Donor/RECEIVE_DONOR_TASK':
            return {
                ...state,

                donorTask: action.donorTask,
                editNoteContent: action.donorTask.noteContent,
            };

        case 'Donor/SAVE_TASK_NOTE':
            return {
                ...state,

                editNoteContent: action.noteContent,
            };

        case 'Donor/RECEIVE_DONOR_TASK_DATA':
            return {
                ...state,

                donors: {
                    ...state.donors,

                    [action.donorId]: {
                        ...state.donors[action.donorId],

                        taskData: {
                            ...state.donors[action.donorId]?.taskData,

                            ...action.taskData,
                        },
                    },
                },
            };

        case 'Donor/OPEN_FOLLOWER_GROUP':
            return {
                ...state,
                openFollowerGroupId: action.followerGroupId,
            };

        case 'Donor/CLOSE_FOLLOWER_GROUP':
            return {
                ...state,
                openFollowerGroupId: action.followerGroupId === state.openFollowerGroupId ? null : state.openFollowerGroupId,
            };

        case 'Donor/RECEIVE_DONOR_FOLLOWER_GROUPS':
            return {
                ...state,
                donors: {
                    ...state.donors,

                    [action.donorId]: {
                        ...state.donors[action.donorId],

                        followerGroups: {
                            ...action.followerGroupMap,
                        },
                    },
                },
            };

        case 'Donor/RECEIVE_CASE_NOTES':
            return {
                ...state,
                donors: {
                    ...state.donors,

                    [action.caseId]: {
                        ...state.donors[action.caseId],

                        notes: [...action.notes],
                    },
                },
            };

        case 'Donor/SET_CASE_PREFERENCES':
            return {
                ...state,
                casePreferences: action.casePreferences,
            };

        case 'Donor/RESET_CASE_PREFERENCES':
            return {
                ...state,
                casePreferences: [],
            };

        case 'Donor/RESET':
            return {
                ...state,

                opoDonorId: '',
                navigateTaskId: null,
                donorType: null,
                workflowKey: '',
                highRisk: false,
                tags: [],
                hospital: '',
                sex: null,
                dateOfBirth: null,
                unosId: '',
                organs: [],
                filter: '',
                errors: null,
                selectedSubscribers: null,
            };

        case 'Donor/NAVIGATE_TO_TASK': {
            return {
                ...state,
                openDonorId: action.donorId,
                navigateTaskId: action.taskId,
            };
        }

        case 'Donor/RESET_NAVIGATE_TO_TASK': {
            return {
                ...state,
                navigateTaskId: null,
            };
        }

        case 'Donor/RECEIVE_TOTAL_FILTERED_CASES': {
            return {
                ...state,
                totalFilteredCases: action.totalFilteredCases,
            };
        }

        case 'Donor/SET_CASE_DISPLAY_ORDER': {
            return {
                ...state,
                caseDisplayOrder: action.caseDisplayOrder,
            };
        }

        default:
            return state;
    }
};

export default reducer;
