// @flow
import type { Logout } from 'txp-core';

import type {
    UserProfile,
    FollowerGroup,
    PendingRemovedUser,
} from '../Utils/types';
import type {
    ResetData,
} from './ApplicationActions';

export type FollowerEditState = {
    followerGroupId: number | null,
    followerName: string,
    canUpdate: boolean,
    taskIds: number[],
    pendingUsers: UserProfile[],
    pendingRemovedUsers: PendingRemovedUser[],
    followerHasChanged: boolean,
};

const initialState: FollowerEditState = {
    followerGroupId: null,
    followerName: '',
    canUpdate: false,
    taskIds: [],
    pendingUsers: [],
    pendingRemovedUsers: [],
    followerHasChanged: false,
};

export type ResetFollowerEditData = { type: 'FollowerEdit/RESET_FOLLOWER_EDIT_DATA' };
export type InitFollowerEditData = { type: 'FollowerEdit/INIT_FOLLOWER_EDIT_DATA', followerGroup: FollowerGroup };
export type SetName = { type: 'FollowerEdit/SET_FOLLOWER_NAME', name: string };
export type SetCanUpdate = { type: 'FollowerEdit/SET_FOLLOWER_CAN_UPDATE', canUpdate: boolean };
export type SetFollowerTasks = { type: 'FollowerEdit/SET_FOLLOWER_TASKS', tasks: number[] };
export type SetPendingFollowerUsers = { type: 'FollowerEdit/SET_PENDING_FOLLOWER_USERS', contacts: UserProfile[] };
export type SetPendingRemovedFollowers = { type: 'FollowerEdit/SET_PENDING_REMOVED_FOLLOWERS', pendingRemovedUsers: PendingRemovedUser[] };
export type CreateFollowerGroup = { type: 'FollowerEdit/CREATE_FOLLOWER_GROUP', donorId: number, followerGroup: FollowerEditState };
export type CreateMultipleFollowerGroups = { type: 'FollowerEdit/CREATE_MULTIPLE_FOLLOWER_GROUPS', donorId: number, followerGroups: FollowerEditState[] };
export type UpdateFollowerGroup = {
    type: 'FollowerEdit/UPDATE_FOLLOWER_GROUP',
    donorId: number,
    followerGroupId: number,
    followerGroup: FollowerEditState,
};
export type JoinFollowerGroup = { type: 'FollowerEdit/JOIN_FOLLOWER_GROUP', donorId: number, followerGroupId: number };
export type SetFollowerHasChanged = { type: 'FollowerEdit/SET_FOLLOWER_HAS_CHANGED', followerHasChanged: boolean };
export type RemoveUserFromFollowers = { type: 'FollowerEdit/REMOVE_USER_FROM_FOLLOWERS', donorId: number, followerId: number, memberId: number };
export type InviteNewUser = { type: 'FollowerEdit/INVITE_NEW_USER', followerId: number, chatId: number, memberId: number, email: string, phone: string };

type Action =
    | ResetData
    | Logout
    | ResetFollowerEditData
    | InitFollowerEditData
    | SetName
    | SetCanUpdate
    | SetFollowerTasks
    | SetPendingFollowerUsers
    | SetPendingRemovedFollowers
    | CreateFollowerGroup
    | UpdateFollowerGroup
    | SetFollowerHasChanged
    | RemoveUserFromFollowers
    | InviteNewUser
    ;

export const resetFollowerEditData = (): ResetFollowerEditData => ({ type: 'FollowerEdit/RESET_FOLLOWER_EDIT_DATA', });
export const initFollowerEditData = (followerGroup: FollowerGroup): InitFollowerEditData => ({
    type: 'FollowerEdit/INIT_FOLLOWER_EDIT_DATA',
    followerGroup,
});
export const setFollowerName = (name: string) => ({
    type: 'FollowerEdit/SET_FOLLOWER_NAME',
    name,
});
export const setFollowerCanUpdate = (canUpdate: boolean) => ({
    type: 'FollowerEdit/SET_FOLLOWER_CAN_UPDATE',
    canUpdate,
});
export const setFollowerTasks = (tasks: number[]) => ({
    type: 'FollowerEdit/SET_FOLLOWER_TASKS',
    tasks,
});
export const setPendingFollowerUsers = (contacts: UserProfile[]) => ({
    type: 'FollowerEdit/SET_PENDING_FOLLOWER_USERS',
    contacts,
});
export const setPendingRemovedFollowers = (pendingRemovedUsers: PendingRemovedUser[]) => ({
    type: 'FollowerEdit/SET_PENDING_REMOVED_FOLLOWERS',
    pendingRemovedUsers,
});
export const createFollowerGroup = (donorId: number, followerGroup: FollowerEditState): CreateFollowerGroup => ({
    type: 'FollowerEdit/CREATE_FOLLOWER_GROUP',
    donorId,
    followerGroup,
});
export const createMultipleFollowerGroups = (donorId: number, followerGroups: FollowerEditState[]): CreateMultipleFollowerGroups => ({
    type: 'FollowerEdit/CREATE_MULTIPLE_FOLLOWER_GROUPS',
    donorId,
    followerGroups,
});
export const updateFollowerGroup = (donorId: number, followerGroupId: number, followerGroup: FollowerEditState): UpdateFollowerGroup => ({
    type: 'FollowerEdit/UPDATE_FOLLOWER_GROUP',
    donorId,
    followerGroupId,
    followerGroup,
});
export const joinFollowerGroup = (donorId: number, followerGroupId: number): JoinFollowerGroup => ({
    type: 'FollowerEdit/JOIN_FOLLOWER_GROUP',
    donorId,
    followerGroupId,
});
export const setFollowerHasChanged = (followerHasChanged: boolean): SetFollowerHasChanged => ({
    type: 'FollowerEdit/SET_FOLLOWER_HAS_CHANGED',
    followerHasChanged,
});
export const removeUserFromFollowerGroup = (donorId: number, followerId: number, memberId: number): RemoveUserFromFollowers => ({
    type: 'FollowerEdit/REMOVE_USER_FROM_FOLLOWERS',
    donorId,
    followerId,
    memberId,
});

export const inviteNewUser = (followerId: number, chatId: number, memberId: number, email: string, phone: string): InviteNewUser => ({
    type: 'FollowerEdit/INVITE_NEW_USER',
    followerId,
    chatId,
    memberId,
    email,
    phone,
});

const reducer = (state: FollowerEditState = initialState, action: Action): FollowerEditState => {
    switch (action.type) {
        case 'Application/RESET_DATA':
        case 'FollowerEdit/RESET_FOLLOWER_EDIT_DATA':
        case 'Auth/LOGOUT':
            // Reset to initial state when logging out or resetting
            return {
                ...initialState,
            };

        case 'FollowerEdit/INIT_FOLLOWER_EDIT_DATA':
            return {
                ...state,
                followerGroupId: action.followerGroup.followerGroupId,
                followerName: action.followerGroup.name,
                canUpdate: action.followerGroup.canUpdate,
                taskIds: action.followerGroup.tasks,
                pendingUsers: [],
                pendingRemovedUsers: [],
                followerHasChanged: false,
            };

        case 'FollowerEdit/SET_FOLLOWER_NAME':
            return {
                ...state,
                followerName: action.name,
            };

        case 'FollowerEdit/SET_FOLLOWER_CAN_UPDATE':
            return {
                ...state,
                canUpdate: action.canUpdate,
            };

        case 'FollowerEdit/SET_FOLLOWER_TASKS':
            return {
                ...state,
                taskIds: action.tasks,
            };

        case 'FollowerEdit/SET_PENDING_FOLLOWER_USERS':
            return {
                ...state,
                pendingUsers: action.contacts,
            };

        case 'FollowerEdit/SET_PENDING_REMOVED_FOLLOWERS':
            return {
                ...state,
                pendingRemovedUsers: action.pendingRemovedUsers,
            };

        case 'FollowerEdit/SET_FOLLOWER_HAS_CHANGED':
            return {
                ...state,
                followerHasChanged: action.followerHasChanged,
            };

        default:
            return state;
    }
};

export default reducer;
