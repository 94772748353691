// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FontIcon from 'react-toolbox/lib/font_icon';
import { Row, Col } from 'react-flexbox-grid';
import Button from 'react-toolbox/lib/button/Button';
import ReactTooltip from 'react-tooltip';

import type { AttachmentData, ChatSocketStatus, UserProfile } from '../Utils/types';
import {
    setOfferFile as _setOfferFile,
    uploadOfferFile as _uploadOfferFile,
    setCandidateFile as _setCandidateFile,
    setROOFile as _setROOFile,
    setFileName as _setFileName,
    getOrganizationMembers as _getOrganizationMembers,
    uploadFailed as _uploadFailed,
    resetOfferError as _resetOfferError,
} from '../Redux/OfferActions';
import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import UploadCDSStyles from './Styles/UploadCDSStyles';
import AuthStyles from '../Containers/Styles/AuthStyles';
import SelectUsers from './SelectUsers';
import BackButton from './BackButton';
import Images from '../Themes/Images';
import ChatMessageStyles from './Styles/ChatMessageStyles';

type Props = {
    socketStatus: ChatSocketStatus,
    isLoading: boolean,
    error: string,
    chatInputHeight: number,
    offerFile: AttachmentData,
    readOfferFile: string,

    closeCDSUpload: () => *,
    setOfferFile: (offerFile: any, readOfferFile: string) => *,
    uploadOfferFile: (offerFile: any, readOfferFile: string, selectedOrgMembers: Array<number>, orgMembers: Array<UserProfile>) => *,
    setCandidateFile: (candidateFile: any, readCandidateFile: string) => *,
    setROOFile: (rooFile: any) => *,
    setFileName: (fileName: string) => *,
    getOrganizationMembers: () => *,
    uploadFailed: (error: string) => *,
    resetOfferError: () => *,
};

type State = {
    selectUsers: boolean,
    draggingOffer: boolean,
    draggingCandidate: boolean,
    draggingROO: boolean,
};

class UploadCDS extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            selectUsers: false,
            draggingOffer: false,
            draggingCandidate: false,
            draggingROO: false,
        };
    }

    componentDidMount() {
        const {
            resetOfferError,
            getOrganizationMembers,
        } = this.props;

        resetOfferError();

        getOrganizationMembers();

        if (this.dropRef.current) {
            const dropDiv = this.dropRef.current;
            dropDiv.addEventListener('dragenter', this.handleDragIn);
            dropDiv.addEventListener('dragleave', this.handleDragOut);
            dropDiv.addEventListener('dragover', this.handleDrag);
            dropDiv.addEventListener('drop', this.handleDrop);
        }
    }

    componentWillUnmount() {
        if (this.dropRef.current) {
            const dropDiv = this.dropRef.current;
            dropDiv.removeEventListener('dragenter', this.handleDragIn);
            dropDiv.removeEventListener('dragleave', this.handleDragOut);
            dropDiv.removeEventListener('dragover', this.handleDrag);
            dropDiv.removeEventListener('drop', this.handleDrop);
        }
    }

    onOpenOfferUpload = () => {
        this.setState({
            draggingOffer: true,
        });
    };

    onOpenCandidateUpload = () => {
        this.setState({
            draggingCandidate: true,
        });
    };

    onOpenROOUpload = () => {
        this.setState({
            draggingROO: true,
        });
    };

    onSetOfferFile = (event: any) => {
        event.preventDefault();
        const {
            setOfferFile,
            setFileName,
        } = this.props;

        this.setState({
            selectUsers: true,
            draggingOffer: false,
        });

        if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const { files, } = event.dataTransfer;
            const file = files[0];

            setFileName(file.name);

            const offerFile = {
                file,
                fileName: file.name,
                path: URL.createObjectURL(file),
                mime: file.type,
                size: file.size,
                displayName: null,
            };

            const fileReader = new FileReader();
            fileReader.readAsText(file, 'UTF-8');
            fileReader.onload = (e: any) => setOfferFile(offerFile, e.target.result);

            event.target.value = null;
        } else if (this.offerFileRef.current && this.offerFileRef.current.files && this.offerFileRef.current.files.length > 0) {
            const { files, } = this.offerFileRef.current;
            const file = files[0];

            setFileName(file.name);

            const offerFile = {
                file,
                fileName: file.name,
                path: URL.createObjectURL(file),
                mime: file.type,
                size: file.size,
                displayName: null,
            };

            const fileReader = new FileReader();
            fileReader.readAsText(file, 'UTF-8');
            fileReader.onload = (e: any) => setOfferFile(offerFile, e.target.result);

            event.target.value = null;
        }
    };

    onUploadOffer = (selectedOrgMembers: Array<number>, orgMembers: Array<UserProfile>) => {
        const {
            offerFile,
            readOfferFile,
            uploadOfferFile,
        } = this.props;

        uploadOfferFile(offerFile, readOfferFile, selectedOrgMembers, orgMembers);

        this.setState({
            selectUsers: false,
        });
    };

    onUploadCandidate = (event: any) => {
        event.preventDefault();
        const {
            setCandidateFile,
            setFileName,
        } = this.props;

        this.setState({
            draggingCandidate: false,
        });

        if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const { files, } = event.dataTransfer;
            const file = files[0];

            setFileName(file.name);

            const candidateFile = {
                file,
                fileName: file.name,
                path: URL.createObjectURL(file),
                mime: file.type,
                size: file.size,
                displayName: null,
            };

            const fileReader = new FileReader();
            fileReader.readAsText(file, 'UTF-8');
            fileReader.onload = (e: any) => setCandidateFile(candidateFile, e.target.result);

            event.target.value = null;
        } else if (this.candidateFileRef.current && this.candidateFileRef.current.files && this.candidateFileRef.current.files.length > 0) {
            const { files, } = this.candidateFileRef.current;
            const file = files[0];

            setFileName(file.name);

            const candidateFile = {
                file,
                fileName: file.name,
                path: URL.createObjectURL(file),
                mime: file.type,
                size: file.size,
                displayName: null,
            };

            const fileReader = new FileReader();
            fileReader.readAsText(file, 'UTF-8');
            fileReader.onload = (e: any) => setCandidateFile(candidateFile, e.target.result);

            event.target.value = null;
        }
    };

    onUploadROO = (event: any) => {
        event.preventDefault();
        const {
            setROOFile,
            setFileName,
        } = this.props;

        this.setState({
            draggingROO: false,
        });

        if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const { files, } = event.dataTransfer;
            const file = files[0];

            setFileName(file.name);

            const rooFile = {
                file,
                fileName: file.name,
                path: URL.createObjectURL(file),
                mime: file.type,
                size: file.size,
                displayName: null,
            };

            setROOFile(rooFile);

            event.target.value = null;
        } else if (this.rooFileRef.current && this.rooFileRef.current.files && this.rooFileRef.current.files.length > 0) {
            const { files, } = this.rooFileRef.current;
            const file = files[0];

            setFileName(file.name);

            const rooFile = {
                file,
                fileName: file.name,
                path: URL.createObjectURL(file),
                mime: file.type,
                size: file.size,
                displayName: null,
            };

            setROOFile(rooFile);

            event.target.value = null;
        }
    };

    onBack = () => {
        this.setState({
            selectUsers: false,
        });
    };

    onExitMediaUpload = () => {
        this.setState({
            draggingOffer: false,
            draggingCandidate: false,
            draggingROO: false,
        });
    };

    handleDrag = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    handleDragIn = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        this.dragCounter += 1;
    };

    handleDragOut = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        this.dragCounter -= 1;
    };

    // Event called when file is dropped in drag-and-drop zone
    handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const {
            uploadFailed,
        } = this.props;

        const {
            draggingOffer,
            draggingCandidate,
            draggingROO,
        } = this.state;

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0];

            if (draggingOffer) {
                if (file.type === 'application/json') {
                    this.onSetOfferFile(event);
                } else {
                    uploadFailed('Select a json file to upload');
                }
            } else if (draggingCandidate) {
                if (file.type === 'application/json' || file.type === 'text/csv') {
                    this.onUploadCandidate(event);
                } else {
                    uploadFailed('Select a json or csv file to upload');
                }
            } else if (draggingROO) {
                if (file.type === 'text/csv') {
                    this.onUploadROO(event);
                } else {
                    uploadFailed('Select a csv file to upload');
                }
            }
        }

        this.setState({
            draggingOffer: false,
            draggingCandidate: false,
            draggingROO: false,
        });

        event.dataTransfer.clearData();
    };

    dragCounter = 0;

    dropRef = React.createRef();

    offerFileRef: {|current: null | HTMLInputElement|} = React.createRef();

    candidateFileRef: {|current: null | HTMLInputElement|} = React.createRef();

    rooFileRef: {|current: null | HTMLInputElement|} = React.createRef();

    renderUsers() {
        const {
            chatInputHeight,
        } = this.props;

        return (
            <div>
                <BackButton
                    goBack={this.onBack}
                    label="Back"
                />
                <div style={UploadCDSStyles.selectUserWrapper}>
                    <span style={UploadCDSStyles.selectUserText}>
                        Select at least one user to notify about this offer
                    </span>
                </div>
                <SelectUsers
                    chatInputHeight={chatInputHeight}
                    uploadOffer={this.onUploadOffer}
                />
            </div>
        );
    }

    render() {
        const {
            socketStatus,
            isLoading,
            error,
            chatInputHeight,
            closeCDSUpload,
        } = this.props;

        const {
            selectUsers,
            draggingOffer,
            draggingCandidate,
            draggingROO,
        } = this.state;

        const dragging = draggingOffer || draggingCandidate || draggingROO;
        const disabled = socketStatus !== 'connected' || isLoading || selectUsers || dragging;
        const inputDisabled = socketStatus !== 'connected' || isLoading;
        const opacityStyle = (dragging) ? { opacity: 0.3, } : { opacity: 1.0, };

        return (
            <div
                ref={this.dropRef}
                style={
                    {
                        ...UploadCDSStyles.whiteBackground,
                        ...{ height: `calc(90vh - ${chatInputHeight}px)`, bottom: chatInputHeight, overflow: 'scroll', },
                    }
                }
            >
                <div style={opacityStyle}>
                    <div style={UploadCDSStyles.closeButtonWrapper}>
                        <ReactTooltip />
                        <Button
                            style={CreateChatroomStyles.closeButton}
                            type="button"
                            ripple={false}
                            data-tip="Cancel"
                            data-delay-show="250"
                            data-effect="solid"
                            data-place="bottom"
                            onClick={closeCDSUpload}
                        >
                            <FontIcon
                                value="close"
                                alt=""
                            />
                        </Button>
                    </div>
                    {selectUsers ? this.renderUsers() : (
                        <div>
                            <Row style={UploadCDSStyles.row}>
                                <Col xs={4}>
                                    <div style={UploadCDSStyles.uploadArea}>
                                        <div>
                                            <div style={UploadCDSStyles.fileIcon}>
                                                <FontIcon
                                                    className="offer"
                                                    value="contact_page"
                                                    alt=""
                                                />
                                            </div>
                                            <div style={UploadCDSStyles.uploadText}>
                                                <Button
                                                    style={disabled ? UploadCDSStyles.uploadButtonDisabled : UploadCDSStyles.uploadButton}
                                                    disabled={disabled}
                                                    type="button"
                                                    flat
                                                    ripple={false}
                                                    onClick={this.onOpenOfferUpload}
                                                >
                                                    <div style={UploadCDSStyles.uploadText}>
                                                        <FontIcon
                                                            value="add"
                                                            alt=""
                                                        />
                                                        <span>Upload Offer</span>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <div style={UploadCDSStyles.uploadArea}>
                                        <div>
                                            <div style={UploadCDSStyles.fileIcon}>
                                                <FontIcon
                                                    className="offer"
                                                    value="contact_page"
                                                    alt=""
                                                />
                                            </div>
                                            <div style={UploadCDSStyles.uploadText}>
                                                <Button
                                                    style={disabled ? UploadCDSStyles.uploadButtonDisabled : UploadCDSStyles.uploadButton}
                                                    disabled={disabled}
                                                    type="button"
                                                    flat
                                                    ripple={false}
                                                    onClick={this.onOpenCandidateUpload}
                                                >
                                                    <div style={UploadCDSStyles.uploadText}>
                                                        <FontIcon
                                                            value="add"
                                                            alt=""
                                                        />
                                                        <span>Upload Candidate</span>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <div style={UploadCDSStyles.uploadArea}>
                                        <div>
                                            <div style={UploadCDSStyles.fileIcon}>
                                                <FontIcon
                                                    className="offer"
                                                    value="contact_page"
                                                    alt=""
                                                />
                                            </div>
                                            <div style={UploadCDSStyles.uploadText}>
                                                <Button
                                                    style={disabled ? UploadCDSStyles.uploadButtonDisabled : UploadCDSStyles.uploadButton}
                                                    disabled={disabled}
                                                    type="button"
                                                    flat
                                                    ripple={false}
                                                    onClick={this.onOpenROOUpload}
                                                >
                                                    <div style={UploadCDSStyles.uploadText}>
                                                        <FontIcon
                                                            value="add"
                                                            alt=""
                                                        />
                                                        <span>Upload ROO</span>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div style={UploadCDSStyles.uploadText}>
                                <span style={AuthStyles.formError}>{error}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {draggingOffer
                        ? (
                            <div style={ChatMessageStyles.fileDropForm}>
                                <img
                                    style={ChatMessageStyles.uploadIcon}
                                    src={Images.fileUpload}
                                    alt="File Upload"
                                />
                                <label
                                    style={ChatMessageStyles.fileDropFormButton}
                                    htmlFor="offerFile"
                                    className="btn"
                                >
                                    Choose file from computer
                                    <input
                                        type="file"
                                        id="offerFile"
                                        accept=".json"
                                        multiple={false}
                                        disabled={inputDisabled}
                                        ref={this.offerFileRef}
                                        onChange={this.onSetOfferFile}
                                        style={{ display: 'none', }}
                                    />
                                </label>
                                or drag and drop a file to upload
                                <div>
                                    <Button
                                        style={UploadCDSStyles.cancelButton}
                                        type="button"
                                        flat
                                        ripple={false}
                                        onClick={this.onExitMediaUpload}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    {draggingCandidate
                        ? (
                            <div style={ChatMessageStyles.fileDropForm}>
                                <img
                                    style={ChatMessageStyles.uploadIcon}
                                    src={Images.fileUpload}
                                    alt="File Upload"
                                />
                                <label
                                    style={ChatMessageStyles.fileDropFormButton}
                                    htmlFor="candidateFile"
                                    className="btn"
                                >
                                    Choose file from computer
                                    <input
                                        type="file"
                                        id="candidateFile"
                                        accept=".json, .csv"
                                        multiple={false}
                                        disabled={inputDisabled}
                                        ref={this.candidateFileRef}
                                        onChange={this.onUploadCandidate}
                                        style={{ display: 'none', }}
                                    />
                                </label>
                                or drag and drop a file to upload
                                <div>
                                    <Button
                                        style={UploadCDSStyles.cancelButton}
                                        type="button"
                                        flat
                                        ripple={false}
                                        onClick={this.onExitMediaUpload}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    {draggingROO
                        ? (
                            <div style={ChatMessageStyles.fileDropForm}>
                                <img
                                    style={ChatMessageStyles.uploadIcon}
                                    src={Images.fileUpload}
                                    alt="File Upload"
                                />
                                <label
                                    style={ChatMessageStyles.fileDropFormButton}
                                    htmlFor="rooFile"
                                    className="btn"
                                >
                                    Choose file from computer
                                    <input
                                        type="file"
                                        id="rooFile"
                                        accept=".csv"
                                        multiple={false}
                                        disabled={inputDisabled}
                                        ref={this.rooFileRef}
                                        onChange={this.onUploadROO}
                                        style={{ display: 'none', }}
                                    />
                                </label>
                                or drag and drop a file to upload
                                <div>
                                    <Button
                                        style={UploadCDSStyles.cancelButton}
                                        type="button"
                                        flat
                                        ripple={false}
                                        onClick={this.onExitMediaUpload}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    error: state.offer.error,
    socketStatus: state.chatList.socketStatus,
    isLoading: state.loading.CDSUpload,
    offerFile: state.offer.offerFile,
    readOfferFile: state.offer.readOfferFile,
});

export default connect(mapStateToProps, {
    setOfferFile: _setOfferFile,
    uploadOfferFile: _uploadOfferFile,
    setCandidateFile: _setCandidateFile,
    setROOFile: _setROOFile,
    setFileName: _setFileName,
    getOrganizationMembers: _getOrganizationMembers,
    uploadFailed: _uploadFailed,
    resetOfferError: _resetOfferError,
})(UploadCDS);
