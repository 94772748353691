// @flow
import React, { PureComponent } from 'react';
import ChatListStyles from './Styles/ChatListStyles';
import type {
    Team,
} from '../Utils/types';

type Props = {
    team: Team,
    selected: boolean,
    onClick: (teamId: number) => *,
};

class TeamListItem extends PureComponent<Props> {
    render() {
        const {
            team,
            selected,
            onClick,
        } = this.props;

        const {
            teamId,
            teamMembers,
            teamName,
        } = team;

        return (
            <div
                className="chatlistRow"
                style={selected ? ChatListStyles.donorRowSelected : ChatListStyles.donorRow}
                role="button"
                tabIndex={0}
                onClick={() => { onClick(teamId); }}
                onKeyDown={() => { onClick(teamId); }}
            >
                <div
                    id="paddingLeft"
                    className="false"
                    style={ChatListStyles.padding}
                >

                    <div style={ChatListStyles.donorTopRow}>
                        <span style={ChatListStyles.boldLabel}>
                            {teamName || ''}
                        </span>
                        <div style={ChatListStyles.innerRow}>
                            <span style={ChatListStyles.personCount}>
                                {teamMembers.length !== 1 ? `${teamMembers.length} members` : `${teamMembers.length} member` }
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default TeamListItem;
