/* eslint-disable class-methods-use-this */
// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import * as Sentry from '@sentry/browser';
import PhoneInput from 'react-phone-number-input/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
    TextField,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Radio,
} from '@material-ui/core';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { DateTimePicker, DatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import sift from 'sift';
import isValidDate from 'date-fns/isValid';
import { findTimeZone, getUTCOffset } from 'timezone-support';

import {
    UI_TYPE_CHECK,
    UI_TYPE_SWITCH,
    UI_TYPE_TEXT,
    UI_TYPE_TXC_LIST,
    UI_TYPE_SURGEON_LIST,
    UI_TYPE_TIME,
    UI_TYPE_BINARY,
    UI_TYPE_MULT_OPTION,
    UI_TYPE_PICKER_OPTION,
    UI_TYPE_SET_INPUT,
    UI_TYPE_DATE,
    UI_TYPE_SELECT_OPTION,
    UI_TYPE_TELEPHONE,
    UI_TYPE_RADIO,
    UI_TYPE_REFERRING,
} from '../Redux/Forms/FormTypes';
import {
    formChanged as _formChanged,
} from '../Redux/FormActions';
import styles from './Styles/GenericFormStyles';
import type {
    FormDefData,
    FormDefSection,
    FormDefField,
    FormErrorMap,
    FormValueMap,
    Organization,
    StaticTask,
} from '../Utils/types';
import { isFieldDisabled, isFieldHidden } from '../Utils/form';
import {
    INVALID_DATE,
    formatDate,
    formatFullDateTimeWithTZ,
} from '../Utils/time';
import FormCheckbox from './FormCheckbox';
import FormSwitch from './FormSwitch';
import FormTextInput from './FormTextInput';
import Colors from '../Themes/Colors';
import FormPicker from './FormPicker';
import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import Search from './Search';
import type { ChatEditState } from '../Redux/ChatEditActions';
import OrganizationItem from './OrganizationItem';
import FormLabel, { getFormLabel } from './FormLabel';
import PhoneInputTextField from './PhoneInputTextField';

type Props = {
    formId: string,
    formDefData: FormDefData,
    formErrors: FormErrorMap,
    origFormData: FormValueMap,
    formUpdated: boolean,
    disabled: boolean,
    editData: ChatEditState,
    workingFormData: any,
    donorTaskData: any,
    workflowTaskDefs: Array<StaticTask>,

    formChanged: (formUpdated: boolean) => *,
    onFieldUpdate: (formId: string, fieldId: number, data: any) => *,
};

const defaultEmptyObject = {};

const GenericForm = (props: Props) => {
    const {
        formId,
        formDefData,
        formErrors,
        origFormData,
        formUpdated,
        disabled,
        editData,
        workingFormData,
        donorTaskData,
        workflowTaskDefs,
        formChanged,
        onFieldUpdate,
    } = props;

    const [txcFilters, setTxcFilters] = useState({});
    const [visibleSections, setVisibleSections] = useState({});

    useEffect(() => {
        formChanged(false);
        initializeVisibleSections();
    }, []);

    useEffect(() => {
        if (JSON.stringify(workingFormData) !== JSON.stringify(origFormData) && !formUpdated) {
            formChanged(true);
        } else if (JSON.stringify(workingFormData) === JSON.stringify(origFormData) && formUpdated) {
            formChanged(false);
        }
    }, [workingFormData, origFormData, formUpdated, formChanged]);

    useEffect(() => {
        initializeVisibleSections();
    }, [formDefData.sections]);

    const onToggleVisibility = (sectionId: number) => {
        const newVisibility = {
            ...visibleSections,
            [sectionId]: !visibleSections[sectionId],
        };

        if (!newVisibility[sectionId]) {
            const { fields, } = formDefData.sections[sectionId - 1];

            fields.forEach((field) => {
                fieldDimensions[field.id] = 0;
            });
        }
        setVisibleSections(newVisibility);
    };

    const onBooleanChange = (item: any, value: boolean) => {
        onFieldUpdate(formId, item.id, value);
    };

    const onPickerChange = (item: any, value: string) => {
        onFieldUpdate(formId, item.id, value);
    };

    const onDateChange = (itemId: number, value: Date) => {
        if (!value) {
            onFieldUpdate(formId, itemId, null);
        } else if (isValidDate(value)) {
            onFieldUpdate(formId, itemId, value.toISOString());
        } else {
            onFieldUpdate(formId, itemId, INVALID_DATE);
        }
    };

    const onDateTimeChange = (itemId: number, value: Date) => {
        if (!value) {
            onFieldUpdate(formId, itemId, null);
        } else if (isValidDate(value)) {
            onFieldUpdate(formId, itemId, value.toISOString());
        } else {
            onFieldUpdate(formId, itemId, INVALID_DATE);
        }
    };

    const onTextChange = (itemId: number, text: string, label?: string) => {
        // Labels are required to support sets of information and is not provided for normal text fields
        if (label) {
            const currentFormData = workingFormData[itemId] && workingFormData[itemId].value ? workingFormData[itemId].value : {};
            const newFormData = {
                ...currentFormData,

                [label]: text,
            };
            onFieldUpdate(formId, itemId, newFormData);
        } else {
            onFieldUpdate(formId, itemId, text);
        }
    };

    const onRadioChange = (itemId: number, value: string) => {
        onFieldUpdate(formId, itemId, value);
    };

    const onSelectChange = (itemId: number, value: Array<string>, option: string) => {
        const newValue = [...value];
        if (newValue.includes(option)) {
            const index = newValue.indexOf(option);
            newValue.splice(index, 1);
        } else {
            newValue.push(option);
        }
        onFieldUpdate(formId, itemId, newValue);
    };

    const onTxcFilterChange = (fieldId: number, text: string) => {
        setTxcFilters({
            ...txcFilters,
            [fieldId]: text,
        });
        if (!text) {
            onTextChange(fieldId, '');
        }
    };

    const onSelectTXC = (fieldId: number, text: string) => {
        onTextChange(fieldId, text);
    };

    const onTrackFlight = (tailNumber: string) => {
        window.open(`https://flightaware.com/live/flight/${tailNumber}`, 'FlightAware', `width=550,height=${window.innerHeight},left=0,menubar=0,status=0`);
    };

    const getNameFromCode = (code: string) => {
        const {
            organizations,
        } = editData;

        for (let i = 0; i < organizations.length; i += 1) {
            if (organizations[i].code === code) {
                return organizations[i].name;
            }
        }

        return '';
    };

    const initializeVisibleSections = () => {
        const sections = formDefData && formDefData.sections ? formDefData.sections : [];

        setVisibleSections(sections.reduce((visiblity, section) => ({
            ...visiblity,
            [section.id]: section.title === '' || section.open,
        }), {}));
    };

    // const sectionDimensions = {};

    const fieldDimensions = {};

    // const keyExtractor = (item: any) => `${item.id}`;

    // const fieldKeyExtractor = (item: any) => `${item.id}`;

    const renderTypeDate = (item: any, fieldDisabled: boolean) => {
        let value = '';
        if (!workingFormData[item.id] || !workingFormData[item.id].value) {
            // Create a default value if the form value is not set since we both:
            //  1. Don't need to since a value is already set
            //  2. More importantly, calling the onTextChange method so that the user can save the date.
            if (item.defaultValue && !fieldDisabled) {
                if (item.defaultValue === 'CURRENT_TIMESTAMP') {
                    value = new Date();
                } else {
                    value = new Date(item.defaultValue);
                }
                onDateChange(item.id, value);
            } else {
                value = null;
            }
        } else {
            // eslint-disable-next-line prefer-destructuring
            value = workingFormData[item.id].value || null;
        }

        return (
            <div style={styles.padding10}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                        label={getFormLabel(item.title, item.mandatory)}
                        disabled={fieldDisabled}
                        value={value}
                        onChange={(newVal) => onDateChange(item.id, newVal)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                InputLabelProps={{ shrink: true, }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>
        );
    };

    const renderTypeDateTime = (item: any, fieldDisabled: boolean) => {
        let value = '';
        if (!workingFormData[item.id] || !workingFormData[item.id].value) {
            // Create a default value if the form value is not set since we both:
            //  1. Don't need to since a value is already set
            //  2. More importantly, calling the onTextChange method so that the user can save the date.
            if (item.defaultValue && !fieldDisabled) {
                if (item.defaultValue === 'CURRENT_TIMESTAMP') {
                    value = new Date();
                } else {
                    value = new Date(item.defaultValue);
                }
                onDateTimeChange(item.id, value);
            } else {
                value = null;
            }
        } else {
            // eslint-disable-next-line prefer-destructuring
            value = workingFormData[item.id].value || null;
        }

        const options = Intl.DateTimeFormat().resolvedOptions();
        const timeZoneOptions = findTimeZone(options.timeZone);
        const timeZoneOffset = value ? getUTCOffset(new Date(value), timeZoneOptions) : {};
        const timeZoneString = timeZoneOffset.abbreviation ?? '';

        return (
            <div style={styles.padding10}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DateTimePicker
                        label={getFormLabel(item.title, item.mandatory)}
                        disabled={fieldDisabled}
                        value={value}
                        onChange={(newVal) => onDateTimeChange(item.id, newVal)}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    InputLabelProps={{ shrink: true, }}
                                    InputProps={{
                                        endAdornment:
                                            // eslint-disable-next-line react/jsx-indent
                                            <>
                                                <span>{timeZoneString}</span>
                                                {params.InputProps.endAdornment}
                                            </>,
                                    }}
                                />
                            </>
                        )}
                    />
                </LocalizationProvider>
            </div>
        );
    };

    // Binary is not a great name for this field type, but it is now entrenched in legacy data at least
    // This is actual a horizontal exclusive set of checkboxes.
    // UI alternative to "picker" (popup list) or "multiple" (vertical exclusive checkbox list)
    const renderTypeBinary = (item: any, fieldDisabled: boolean) => {
        const value = workingFormData[item.id] && workingFormData[item.id].value ? workingFormData[item.id].value : '';
        const responseOptions = item.responseOptions || [];
        const className = fieldDisabled ? 'iconDisabled24' : 'iconBlue24';

        return (
            <div style={{ paddingBottom: 10, }}>
                <span style={styles.padding10}>
                    {getFormLabel(item.title, item.mandatory)}
                </span>
                {responseOptions.map((responseOption: string) => (
                    <span
                        role="presentation"
                        key={responseOption}
                        onClick={() => {
                            if (fieldDisabled) return;
                            onTextChange(item.id, value === responseOption ? '' : responseOption);
                        }}
                    >
                        <FontAwesomeIcon
                            className={className}
                            icon={value === responseOption ? faCheckCircle : faCircle}
                            size="lg"
                        />
                        <span
                            style={{ ...styles.label, ...styles.padding10, }}
                        >
                            {responseOption}
                        </span>
                    </span>
                ))}
            </div>
        );
    };

    // DEPRECATED
    // Only used by Experience Anatomy. Name is misleading
    // This generates a vertical, exclusive selection list. A better UI choice would be PICKER
    // Actually multi-select capability is in the new SELECT option, which is being ported from the Mobile Survey input field
    // At time of writing this port is NOT complete (8/24)
    const renderTypeMultOption = (item: any, fieldDisabled: boolean) => {
        const value = workingFormData[item.id] && workingFormData[item.id].value ? workingFormData[item.id].value : '';
        const responseOptions = item.responseOptions || [];
        const className = fieldDisabled ? 'iconDisabled24' : 'iconBlue24';

        return (
            <div style={styles.marginVertical10}>
                <FormLabel item={item} />
                {responseOptions.map((responseOption: string) => (
                    <div
                        role="presentation"
                        style={styles.padding10}
                        key={responseOption}
                        onClick={() => {
                            if (fieldDisabled) return;
                            onTextChange(item.id, value === responseOption ? '' : responseOption);
                        }}
                    >
                        <FontAwesomeIcon
                            className={className}
                            icon={value === responseOption ? faCheckCircle : faCircle}
                            size="lg"
                        />
                        <span
                            style={{ ...styles.label, ...styles.padding10, }}
                        >
                            {responseOption}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    const renderTypeRadio = (item: any, fieldDisabled: boolean) => {
        const value = workingFormData[item.id] && workingFormData[item.id].value ? workingFormData[item.id].value : [];
        const responseOptions = item.responseOptions || [];

        return (
            <div>
                <FormControl disabled={fieldDisabled}>
                    <FormLabel item={item} />
                    <RadioGroup
                        name="radio-buttons-group"
                        value={value}
                        onChange={(event) => onRadioChange(item.id, event.target.value)}
                    >
                        {responseOptions.map((responseOption: string) => (
                            <FormControlLabel
                                key={responseOption}
                                value={responseOption}
                                control={<Radio color="primary" />}
                                label={responseOption}
                            />
                        ))}
                    </RadioGroup>
                    <div style={styles.helperText}>{item.helperText}</div>
                </FormControl>
            </div>
        );
    };

    // UI TYPE "SELECT"
    // This generates a list of radio buttons, modelled on the Survey SELECT feature (currently only on Mobile)
    // Actually multi-select capability is in the new SELECT option, which is being ported from the Mobile Survey input field
    // At time of writing this port is NOT complete (8/24) (orientation not respected - always vertical, multiple not respected - always true)
    const renderTypeSelectOption = (item: any, fieldDisabled: boolean) => {
        const value = workingFormData[item.id] && workingFormData[item.id].value ? workingFormData[item.id].value : [];
        const responseOptions = item.responseOptions || [];
        const className = fieldDisabled ? 'iconDisabled24' : 'iconBlue24';

        return (
            <div style={styles.marginVertical10}>
                <FormLabel item={item} />
                {responseOptions.map((responseOption: string) => (
                    <div
                        role="presentation"
                        style={styles.padding10}
                        key={responseOption}
                        onClick={() => {
                            if (fieldDisabled) return;
                            onSelectChange(item.id, value, responseOption);
                        }}
                    >
                        <FontAwesomeIcon
                            className={className}
                            icon={value.includes(responseOption) ? faCheckCircle : faCircle}
                            size="lg"
                        />
                        <span
                            style={{ ...styles.label, ...styles.padding10, }}
                        >
                            {responseOption}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    const renderTypeUnsupported = (sectionId: number, item: any) => {
        let value = '';
        if (workingFormData[item.id] && workingFormData[item.id].value) {
            if (typeof workingFormData[item.id].value === 'object' && !Array.isArray(workingFormData[item.id].value)) {
                value = 'Unable to display value';
            } else {
                value = Array.isArray(workingFormData[item.id].value) ? workingFormData[item.id].value.join(', ') : workingFormData[item.id].value.toString();
            }
        }

        return (
            <div style={styles.marginVertical10}>
                <div style={styles.noteText}>Editing for this field is not currently supported on this platform.</div>
                <FormTextInput
                    fieldDisabled
                    label=""
                    item={item}
                    value={value}
                    error={false}
                    onTextChange={() => {}}
                    onTrackFlight={() => {}}
                    multiline /* Make sure everything is multiline so full value is always displayed */
                />
            </div>
        );
    };

    const renderTypeText = (sectionId: number, item: any, fieldDisabled: boolean) => {
        const value = workingFormData[item.id] && workingFormData[item.id].value ? workingFormData[item.id].value : '';

        return (
            <div style={styles.marginVertical10}>
                <FormTextInput
                    fieldDisabled={fieldDisabled}
                    label=""
                    item={item}
                    value={value}
                    multiline
                    error={!!formErrors[item.id]}
                    onTextChange={onTextChange}
                    onTrackFlight={onTrackFlight}
                />
            </div>
        );
    };

    const renderTypeTel = (sectionId: number, item: any, fieldDisabled: boolean) => {
        const value = workingFormData[item.id] && workingFormData[item.id].value ? workingFormData[item.id].value : '';

        return (
            <div style={styles.marginVertical10}>
                <div style={styles.padding10}>
                    <PhoneInput
                        value={value}
                        onChange={(text) => onTextChange(item.id, text)}
                        international
                        withCountryCallingCode
                        defaultCountry="US"
                        inputComponent={PhoneInputTextField}
                        // TextInput props
                        disabled={fieldDisabled}
                        variant="outlined"
                        label={getFormLabel(item.title, item.mandatory)}
                        fullWidth
                    />
                </div>
            </div>
        );
    };

    const renderTypeSwitch = (item: any, fieldDisabled: boolean) => (
        <FormSwitch
            fieldDisabled={fieldDisabled}
            itemDef={item}
            itemData={workingFormData[item.id]}
            onChange={onBooleanChange}
        />
    );

    const renderTypeCheck = (item: any, fieldDisabled: boolean) => (
        <FormCheckbox
            fieldDisabled={fieldDisabled}
            itemDef={item}
            itemData={workingFormData[item.id]}
            onChange={onBooleanChange}
        />
    );

    const renderTypePicker = (item: any, fieldDisabled: boolean) => (
        <FormPicker
            fieldDisabled={fieldDisabled}
            itemDef={item}
            itemData={workingFormData[item.id]}
            onChange={onPickerChange}
        />
    );

    const renderTypeTxcList = (sectionId: number, item: any, fieldDisabled: boolean) => {
        const value = workingFormData[item.id] && workingFormData[item.id].value ? workingFormData[item.id].value : '';
        const {
            organizations,
        } = editData;

        const organizationFilter = txcFilters[item.id];
        const items = !value && organizationFilter && organizationFilter.length >= 3 ? organizations.filter(
            sift({
                $where(org) {
                    return org.name.toUpperCase().includes(organizationFilter.toUpperCase())
                        || org.code.toUpperCase().includes(organizationFilter.toUpperCase());
                },
            })
        ) : [];

        return (
            <div>
                <FormLabel item={item} />
                <Search
                    disabled={fieldDisabled}
                    placeholder="Search organizations..."
                    value={value ? getNameFromCode(value) : organizationFilter}
                    filterContent={(text) => onTxcFilterChange(item.id, text)}
                />
                <div style={items.length > 0 ? CreateChatroomStyles.scrollableContainer : CreateChatroomStyles.noItemsContainer}>
                    {
                        items.length > 0 ? items.map((organization: Organization) => (
                            <OrganizationItem
                                key={organization.id}
                                organization={organization}
                                selectOrganization={(org) => onSelectTXC(item.id, org.code)}
                                getSurgeons={() => {}}
                            />
                        )) : <div>No organizations to display</div>
                    }
                </div>
            </div>
        );
    };

    const renderTypeSurgeonList = (sectionId: any, item: any, fieldDisabled: boolean) => {
        // eslint-disable-next-line no-console
        console.log('SURGEON', sectionId, item, fieldDisabled);
        return <div>Surgeon List Not Yet Implemented</div>;
    };

    const renderTypeReferring = (item: any) => {
        let referredValue = '';

        if (donorTaskData && item.referToFieldId && item.referToTaskId
            && donorTaskData[item.referToTaskId] && donorTaskData[item.referToTaskId][item.referToFieldId]) {
            referredValue = donorTaskData[item.referToTaskId][item.referToFieldId].value;
        }
        let taskTitle = '';
        let fieldTitle = '';
        let noteText = '';
        let title = '';
        let referToUiType = '';
        let referToFieldDef: ?FormDefField;
        const referToFormDef = workflowTaskDefs.find((taskDef) => taskDef.taskId === item.referToTaskId);
        // Get the appropriate task and field title if they exist
        if (referToFormDef) {
            taskTitle = referToFormDef.details.description;
            const taskFieldDefs = formDefData.sections.flatMap((section) => section.fields);
            referToFieldDef = taskFieldDefs.find((fieldDef) => fieldDef.id === item.referToFieldId);
            if (referToFieldDef) {
                fieldTitle = referToFieldDef.title;
                noteText = `This field is controlled by ${taskTitle} - ${fieldTitle}`;
                title = fieldTitle;
                referToUiType = referToFieldDef.uiType;
            } else {
                title = 'Field Not Found';
                noteText = `Field not found in task ${taskTitle}`;
                referredValue = 'Error: Reference not found';
            }
        } else {
            title = 'Task Not Found';
            noteText = 'Task Not Found';
            referredValue = 'Error: Reference not found';
        }

        // Format special UI types
        if (referredValue && referToUiType === UI_TYPE_DATE) {
            referredValue = formatDate(referredValue);
        } else if (referredValue && referToUiType === UI_TYPE_TIME) {
            referredValue = formatFullDateTimeWithTZ(referredValue);
        } else if (referToUiType === UI_TYPE_SELECT_OPTION && Array.isArray(referredValue)) {
            referredValue = referredValue.join(', ');
        } else if (referredValue !== '' && referToFieldDef && referToFieldDef.type === 'boolean') {
            referredValue = referredValue ? 'Yes' : 'No';
        }

        const valueStyle = referredValue ? styles.valueText : styles.valueTextDisabled;
        return (
            <div style={styles.padding10}>
                <div style={styles.labelText}>{title}</div>
                <div style={valueStyle}>{referredValue || 'No value entered yet'}</div>
                <div style={styles.noteText}>{noteText}</div>
            </div>
        );
    };

    const renderFields = (item: any, sectionId: number) => {
        if (isFieldHidden(item, formDefData, workingFormData)) return null;

        const fieldDisabled = disabled || isFieldDisabled(item, sectionId, formDefData, workingFormData);

        let field = renderTypeUnsupported(sectionId, item);
        if (item.uiType.toLowerCase() === UI_TYPE_SWITCH) field = renderTypeSwitch(item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_CHECK) field = renderTypeCheck(item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_TXC_LIST) field = renderTypeTxcList(sectionId, item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_SURGEON_LIST) field = renderTypeSurgeonList(sectionId, item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_TIME) field = renderTypeDateTime(item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_DATE) field = renderTypeDate(item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_BINARY) field = renderTypeBinary(item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_MULT_OPTION) field = renderTypeMultOption(item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_SELECT_OPTION) field = renderTypeSelectOption(item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_PICKER_OPTION) field = renderTypePicker(item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_SET_INPUT) field = renderTypeUnsupported(sectionId, item);
        if (item.uiType.toLowerCase() === UI_TYPE_TEXT) field = renderTypeText(sectionId, item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_TELEPHONE) field = renderTypeTel(sectionId, item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_RADIO) field = renderTypeRadio(item, fieldDisabled);
        if (item.uiType.toLowerCase() === UI_TYPE_REFERRING) field = renderTypeReferring(item);

        const key = (sectionId * 1000) + item.id;
        return (
            <div key={key}>{field}</div>
        );
    };

    const renderSection = (section: any) => {
        if (!visibleSections[section.id] && section.fields.length <= 0) return null; // TEMP

        return (

            <div key={section.id}>
                <div
                    style={styles.donorCard}
                    role="button"
                    tabIndex={0}
                    onClick={() => onToggleVisibility(section.id)}
                    onKeyPress={() => {}}
                >
                    <div style={styles.formTitle}>
                        {section.title}
                        {section.title !== ''
                            ? (
                                <span style={styles.padding10}>
                                    <FontAwesomeIcon
                                        color={Colors.blue}
                                        icon={visibleSections[section.id] ? faCaretDown : faCaretRight}
                                        size="lg"
                                    />
                                </span>
                            ) : null}
                    </div>
                </div>
                {visibleSections[section.id]
                    ? (
                        section.fields.map((field: any) => renderFields(field, section.id))
                    ) : null}
            </div>
        );
    };

    const renderMandatoryFieldsNotice = (formDef: FormDefData) => {
        const sections: Array<FormDefSection> = formDef.sections ? formDef.sections : [];

        // Iterate over sections and then fields to see if any fields are mandatory.
        const containsMandatoryFields = sections.some((section: FormDefSection) => section.fields.some((field: FormDefField) => field.mandatory));
        return containsMandatoryFields
            ? (
                <div style={styles.noteText}>Any field marked with * is mandatory</div>
            ) : null;
    };

    const sections = formDefData.sections ? formDefData.sections : [];

    return (
        <div>
            {sections.map((section: any) => renderSection(section))}
            {renderMandatoryFieldsNotice(formDefData)}
        </div>
    );
};

const mapStateToProps = (state, props: Props) => ({
    editData: state.chatEdit,
    formErrors: state.forms.formErrors && state.forms.formErrors[props.formId] ? state.forms.formErrors[props.formId] : defaultEmptyObject,
    origFormData: state.forms.origFormData && state.forms.origFormData[props.formId]
        ? state.forms.origFormData[props.formId] : {},
    formUpdated: state.forms.formUpdated,
});

export default connect(mapStateToProps, {
    formChanged: _formChanged,
})(GenericForm);
