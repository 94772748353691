// @flow
import React, { PureComponent } from 'react';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon';

import CreateChatroomStyles from './Styles/CreateChatroomStyles';

type Props = {
    light?: boolean,
    label: string,
    value?: string,

    goBack: () => *,
};

class BackButton extends PureComponent<Props> {
    render() {
        const {
            light,
            label,
            goBack,
            value,
        } = this.props;

        const style = light ? CreateChatroomStyles.backButtonLight : CreateChatroomStyles.backButton;

        return (
            <Button
                style={style}
                type="button"
                ripple={false}
                onClick={goBack}
            >
                <FontIcon
                    className={light ? 'backLight' : 'back'}
                    value={value}
                    alt=""
                />
                {label}
            </Button>
        );
    }
}

// $FlowFixMe
BackButton.defaultProps = {
    light: false,
    value: 'arrow_back_ios',
};

export default BackButton;
