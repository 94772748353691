// @flow
import React, { PureComponent } from 'react';

import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import type { Offer } from '../Utils/types';

type Props = {
    offer: Offer,

    selectOffer: (offer: Offer) => *,
};

class OfferItem extends PureComponent<Props> {
    chooseOffer = () => {
        const {
            offer,
            selectOffer,
        } = this.props;

        selectOffer(offer);
    };

    render() {
        const {
            offer,
        } = this.props;

        return (
            <div // eslint-disable-line
                style={CreateChatroomStyles.offerContainer}
                role="button"
                tabIndex={0}
                onClick={this.chooseOffer}
            >
                <span style={CreateChatroomStyles.mainText}>
                    {offer.unosId}
                </span>
                <span style={CreateChatroomStyles.subText}>
                    {offer.organType}
                </span>
            </div>
        );
    }
}

export default OfferItem;
