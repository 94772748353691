// @flow
import React from 'react';
import { connect, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes, faCheckCircle, faNotesMedical, faBullseye, faList, faChevronRight, faCalendar, faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import {
    formatDateTime,
} from 'txp-core';

import {
    getDonorTask as _getDonorTask,
    updateTaskStatus as _updateTaskStatus,
} from '../Redux/DonorActions';
import {
    setSagaMessage as _setSagaMessage,
    selectDonorView as _selectDonorView,
    DONOR_TASK_DUE_VIEW,
} from '../Redux/ApplicationActions';
import Colors from '../Themes/Colors';
import truncate from '../Utils/text';
import type {
    ChatSocketStatus,
    StaticTask,
    Task,
    UserPermission,
    FormValueMap,
    NotifiableEvent,
    FormDefData,
} from '../Utils/types';
import Checkbox from './Checkbox';
import hasPermissions, {
    ENTITY_TYPE_DONOR,
    NONE,
    SUB_ENTITY_TYPE_TASK,
    UPDATE,
} from '../Utils/hasPermissions';
import uuidv4 from '../Utils/fakeUuid';
import styles from './Styles/DonorStyles';
import { getItemsToDisplay } from '../Utils/form';
import { useWorkflowForms } from '../Utils/hooks';
import { getDonorFormAttributes } from '../Utils/TaskDetailsUtils';

type Props = {
    task: {
        taskId: number,
        formId: string,
        details: {
            sectionPosition: number,
            description: string,
            section: string,
        },
    },
    tasks: Array<Task>,
    allTasks: Array<StaticTask>,
    canViewTracker: boolean,
    notifiableEvents: Array<NotifiableEvent>,
    donorId: number,
    closed: boolean,
    donorLoading: boolean,
    socketStatus: ChatSocketStatus,
    permissions: Array<UserPermission>,
    updatingTaskStatus: boolean,
    formData: FormValueMap,

    updateTaskStatus: (
        donorId: number,
        taskId: number,
        lastModified: string,
        fieldValueMap: ?FormValueMap,
        completed: ?boolean,
        notApplicable: ?boolean,
        fromList: boolean,
    ) => *,
    setSagaMessage: (heading: string, message: string, label: string, isDialog?: boolean) => *,
    selectDonorView: (viewName: string) => *,
    getDonorTask: (donorId: number, taskId: number) => *,
    onTaskPress: (taskId: number) => *,
};

const defaultEmptyArray = [];

type TSBProps = {
    disabled: boolean, clickable: boolean, notApplicable: boolean, completed: boolean, taskId: number, description: string,
    onStatusClick: (taskId: number) => *,
    onTaskPress: (taskId: number) => *,
}
export function TaskStatusButton(props: TSBProps) {
    let className = 'iconDisabled24';
    let iconName = faCircle;
    if (props.notApplicable) {
        iconName = faTimes;
    } else if (props.completed) {
        iconName = faCheckCircle;
        className = 'iconBlue24';
    }

    return (
        <div style={props.clickable ? styles.taskItemButton : styles.taskItemInnerRow}>
            <Checkbox
                disabled={props.disabled}
                className={className}
                iconName={iconName}
                label=""
                onClick={() => props.onStatusClick(props.taskId)}
            />
            <div
                role="button"
                tabIndex={0}
                onClick={() => props.onTaskPress(props.taskId)}
                onKeyPress={() => { }}
            >
                <span>
                    {truncate(props.description, 32)}
                </span>
                {props.clickable
                    ? (
                        <FontAwesomeIcon
                            fixedWidth={!false}
                            color={Colors.blue}
                            icon={faChevronRight}
                        />
                    ) : null}
            </div>
        </div>
    );
}

function TaskItem({
    task,
    tasks,
    allTasks,
    canViewTracker,
    notifiableEvents,
    donorId,
    closed,
    donorLoading,
    socketStatus,
    permissions,
    updatingTaskStatus,
    formData,
    updateTaskStatus,
    setSagaMessage,
    selectDonorView,
    getDonorTask,
    onTaskPress,
}: Props) {
    const donor = useSelector((state) => state.donor.donors[donorId]);
    const workflowKey = donor ? donor.workflow : '';
    const { data: formDefs, }: { [formId: string]: FormDefData } = useWorkflowForms(workflowKey);
    const formDef: FormDefData | void = formDefs[task.formId];

    const onStatusUpdate = (taskId: number) => {
        const notApplicable = isTaskNotApplicable(taskId);
        if (notApplicable) {
            setSagaMessage('', 'To update a task mark the task as applicable', '');
            return;
        }

        const completed = isTaskCompleted(taskId);
        const lastModified = getTaskLastModified(taskId);

        let updatedForm = null;
        if (formDef?.behaviors && formDef.behaviors.clearOnIncomplete && completed) {
            updatedForm = {};
        }
        // If completing the task, pass in the form data for the mandatory check
        if (!completed) {
            updatedForm = formData;
        }

        updateTaskStatus(donorId, taskId, lastModified, updatedForm, !completed, null, true);
    };

    const onSetDueDatePress = (taskId: number) => {
        getDonorTask(donorId, taskId);
        selectDonorView(DONOR_TASK_DUE_VIEW);
    };

    const getTaskLastModified = (taskId: number) => {
        if (!donorLoading) {
            if (tasks && tasks.length > 0) {
                const index = tasks.findIndex((x) => x.taskId === taskId);
                if (index !== -1) {
                    return tasks[index].lastModified;
                }
            }
        }

        return '';
    };

    const isTaskCompleted = (taskId: number) => {
        if (!donorLoading) {
            if (tasks && tasks.length > 0) {
                const index = tasks.findIndex((x) => x.taskId === taskId);
                if (index !== -1) {
                    return tasks[index].completed;
                }
            }
        }

        return false;
    };

    const isTaskNotApplicable = (taskId: number) => {
        if (!donorLoading) {
            if (tasks && tasks.length > 0) {
                const index = tasks.findIndex((x) => x.taskId === taskId);
                if (index !== -1) {
                    return tasks[index].notApplicable;
                }
            }
        }

        return false;
    };

    const isTaskTrackerItem = (taskId) => {
        const index = notifiableEvents.findIndex((x) => x.subtargetId === taskId);

        return index !== -1;
    };

    const doesTaskHaveNotes = (taskId: number) => {
        if (!donorLoading) {
            if (tasks && tasks.length > 0) {
                const index = tasks.findIndex((x) => x.taskId === taskId);
                if (index !== -1) {
                    return tasks[index].hasNoteContent;
                }
            }
        }

        return false;
    };

    const doesTaskHaveFormData = (taskId: number) => {
        if (!donorLoading) {
            if (tasks && tasks.length > 0) {
                const index = tasks.findIndex((x) => x.taskId === taskId);
                if (index !== -1) {
                    return tasks[index].hasTaskData;
                }
            }
        }

        return false;
    };

    const doesTaskHaveDateInterval = (taskId: number) => {
        if (!donorLoading) {
            if (tasks && tasks.length > 0) {
                const index = tasks.findIndex((x) => x.taskId === taskId);
                if (index !== -1) {
                    return tasks[index].dueDateInterval;
                }
            }
        }

        return false;
    };

    const taskPreviousTaskId = (taskId: number) => {
        if (!donorLoading) {
            if (tasks && tasks.length > 0) {
                const index = tasks.findIndex((x) => x.taskId === taskId);
                if (index !== -1) {
                    return tasks[index].previousTaskId;
                }
            }
        }

        return 0;
    };

    const taskDueDate = (taskId: number) => {
        if (!donorLoading) {
            if (tasks && tasks.length > 0) {
                const index = tasks.findIndex((x) => x.taskId === taskId);
                if (index !== -1) {
                    return tasks[index].dueDate;
                }
            }
        }
        return '';
    };

    const renderSetDueDateButton = () => {
        const canUpdateDonor = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorId, NONE, NONE, UPDATE);
        const completed = isTaskCompleted(task.taskId);
        const notApplicable = isTaskNotApplicable(task.taskId);
        const hasSetDateInterval = doesTaskHaveDateInterval(task.taskId);
        const previousTaskId = taskPreviousTaskId(task.taskId);
        const currentTaskDueDate = taskDueDate(task.taskId);
        const previousTask = allTasks.find((thisTask) => thisTask.taskId === previousTaskId);
        let previousTaskDescription = previousTask ? previousTask.details.description : 'Previous Task';
        if (previousTaskDescription.length > 25) {
            previousTaskDescription = previousTaskDescription.slice(0, 22);
            previousTaskDescription += '...';
        }

        let pastDue = false;
        if (currentTaskDueDate) {
            const dueDate = new Date(currentTaskDueDate);
            const currentDate = new Date();
            pastDue = dueDate.getTime() < currentDate.getTime();
        }

        const buttonColor = pastDue ? Colors.red : Colors.gray;

        if (!canUpdateDonor || completed || notApplicable || closed) { return null; }

        return (
            <div
                role="button"
                tabIndex={0}
                onClick={() => onSetDueDatePress(task.taskId)}
                onKeyPress={() => onSetDueDatePress(task.taskId)}
                style={styles.taskItemDueDate}
            >
                {currentTaskDueDate ? (
                    <>
                        <span style={{ color: buttonColor, paddingRight: 5, }}>
                            {`Due: ${formatDateTime(currentTaskDueDate)}`}
                        </span>
                        <FontAwesomeIcon color={Colors.blue} icon={faPencilAlt} />
                    </>
                ) : (hasSetDateInterval ? (
                    <>
                        <span style={{ color: Colors.gray, paddingRight: 5, }}>
                            {`Due: After ${previousTaskDescription}`}
                        </span>
                        <FontAwesomeIcon color={Colors.blue} icon={faPencilAlt} />
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon
                            color={Colors.blue}
                            icon={faCalendar}
                            size="lg"
                        />
                        <span role="link" style={{ textDecoration: 'underline', paddingLeft: 5, }}>
                            Set Due Date
                        </span>
                    </>
                ))}
            </div>
        );
    };

    const donorUpdateAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorId, NONE, NONE, UPDATE);
    const taskOnlyUpdateAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorId, SUB_ENTITY_TYPE_TASK, task.taskId, UPDATE);
    const updateAllowed = donorUpdateAllowed || taskOnlyUpdateAllowed;

    const completed = isTaskCompleted(task.taskId);
    const hasNoteContent = doesTaskHaveNotes(task.taskId);
    const hasTaskData = doesTaskHaveFormData(task.taskId);
    const notApplicable = isTaskNotApplicable(task.taskId);
    const isTrackerItem = isTaskTrackerItem(task.taskId);

    const disabled = updatingTaskStatus || !updateAllowed || closed || socketStatus !== 'connected';

    const displayTaskData = getItemsToDisplay(donor, task.taskId, formDef);

    return (
        <div style={styles.taskItemWrapper}>
            <div style={styles.taskItemRow}>
                {isTrackerItem && canViewTracker ? (
                    <div
                        style={styles.bullseyeWrapper}
                    >
                        <FontAwesomeIcon
                            color={Colors.blue}
                            icon={faBullseye}
                            size="xs"
                        />
                    </div>
                ) : (
                    null
                )}
                {renderSetDueDateButton()}
            </div>
            <div>
                <div style={styles.taskItemRow}>
                    <TaskStatusButton
                        disabled={disabled}
                        completed={completed}
                        clickable
                        notApplicable={notApplicable}
                        taskId={task.taskId}
                        description={task.details.description}
                        onTaskPress={onTaskPress}
                        onStatusClick={() => onStatusUpdate(task.taskId)}
                    />
                    <div style={styles.taskItemInnerRow}>
                        {hasTaskData
                            ? (
                                <div>
                                    <FontAwesomeIcon
                                        color={Colors.blue}
                                        icon={faList}
                                        size="lg"
                                    />
                                </div>
                            ) : null}
                        {hasNoteContent
                            ? (
                                <div style={styles.iconPadding}>
                                    <FontAwesomeIcon
                                        color={Colors.blue}
                                        icon={faNotesMedical}
                                        size="lg"
                                    />
                                </div>
                            ) : null}
                        {(taskOnlyUpdateAllowed && !donorUpdateAllowed)
                            ? (
                                <div style={styles.iconPadding}>
                                    <FontAwesomeIcon
                                        color={Colors.blue}
                                        icon={faPencilAlt}
                                        size="sm"
                                    />
                                </div>
                            ) : null}
                    </div>
                </div>
                <div style={{ paddingBottom: 5, }}>
                    {displayTaskData.map((taskData: string) => (
                        <div key={uuidv4()} style={styles.taskDataWrapper}>
                            <div
                                role="button"
                                tabIndex={0}
                                onClick={() => onTaskPress(task.taskId)}
                                onKeyPress={() => { }}
                            >
                                <span style={styles.taskDataText}>{taskData}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => {
    const chatIds = state.chatList.order || defaultEmptyArray;
    const donorId = props.donorId || -1;

    const {
        donor, workflowId, formData,
    } = getDonorFormAttributes(state, donorId, props.task.taskId);

    return {
        chatIds,
        closed: donor ? donor.closed : false,
        donorId,
        donorLoading: state.loading.donor,
        allTasks: state.donor.workflowTasks && state.donor.workflowTasks[workflowId] && state.donor.workflowTasks[workflowId].tasks
            ? state.donor.workflowTasks[workflowId].tasks : defaultEmptyArray,
        tasks: donor ? donor.tasks : defaultEmptyArray,
        notifiableEvents: state.notification.notifiableEvents || defaultEmptyArray,
        socketStatus: state.chatList.socketStatus,
        permissions: state.permission.permissions,
        updatingTaskStatus: state.loading.taskStatus[`${props.task.taskId}`],
        formData,
    };
};

export default connect(mapStateToProps, {
    updateTaskStatus: _updateTaskStatus,
    setSagaMessage: _setSagaMessage,
    selectDonorView: _selectDonorView,
    getDonorTask: _getDonorTask,
})(TaskItem);
