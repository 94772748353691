import { call, put, select } from 'redux-saga/effects';
import { accessTokenExpired } from 'txp-core';
import queryClient from '../queryClient';
import { buildResourceFetch } from '../Services/Api';

function* apiCall(resource, method, ...args) {
    let result = null;

    const token = yield select((state) => state.auth.accessToken);

    // Create new headers config because we might need to set token
    const headers = {
        Accept: 'application/json',
    };

    // Add Authorization header unless withCredentials is false for the resource

    if (token && resource.config().withCredentials) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        result = yield call([resource, method], ...args, {
            headers,
        });
    } catch (error) {
        return {
            error,
        };
    }

    return {
        result,
    };
}

export function* apiFetch(resource, kwargs, query) {
    const { result, error, } = yield call(apiCall, resource, 'fetch', kwargs, query);

    if (error && error.statusCode === 401) {
        yield put(accessTokenExpired());
    }

    return { result, error, };
}

export function* apiPost(resource, kwargs, data, query, attachments) {
    const { result, error, } = yield call(apiCall, resource, 'post', kwargs, data, query, attachments);

    if (error && error.statusCode === 401) {
        yield put(accessTokenExpired());
    }

    return { result, error, };
}

export function* apiDelete(resource, kwargs, data, query, attachments) {
    const { result, error, } = yield call(apiCall, resource, 'del', kwargs, data, query, attachments);

    if (error && error.statusCode === 401) {
        yield put(accessTokenExpired());
    }

    return { result, error, };
}

export function* apiPut(resource, kwargs, data, query, attachments) {
    const { result, error, } = yield call(apiCall, resource, 'put', kwargs, data, query, attachments);

    if (error && error.statusCode === 401) {
        yield put(accessTokenExpired());
    }

    return { result, error, };
}

/**
 * Use React Query Client's caching mechanism to reuse data
 * from existing useQuery hooks and minimize refetching data
 */
export function* apiCachedFetch(queryKey, resource, kwargs, query) {
    const token = yield select((state) => state.auth.accessToken);
    const fetchResource = buildResourceFetch(token);

    try {
        // Use fetchQuery instead of getQueryData/setQueryData to ensure that
        // duplicate requests for this query are not created with useQuery instances
        const data = yield call([queryClient, 'fetchQuery'], {
            queryKey,
            queryFn: () => fetchResource(resource, { kwargs, query, }),
        });

        return { result: data, error: undefined, };
    } catch (error) {
        return { result: undefined, error, };
    }
}
