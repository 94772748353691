// @flow
import React, { PureComponent } from 'react';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import { selectDonorMedia, selectDonorMediaVideo, selectDonorMediaApplication } from '../Redux/ChatMediaActions';
import bytesToSize from '../Utils/bytes';
import isString from '../Utils/isString';
import { formatRelative, parse } from '../Utils/time';
import {
    ApplicationMessageTypes, ImageMessageTypes, MessageTypes, VideoMessageTypes,
} from '../Utils/types';
import type { File, MediaType, MessageType } from '../Utils/types';
import DownloadingMedia from './DownloadingMedia';
import Images from '../Themes/Images';

type Props = {
    id: number,
    accessToken: string,
    file: File,
    memberName: string,
    fullSize: boolean,

    onMediaClick: (file: File) => *,
};

class FileListItem extends PureComponent<Props> {
    onMediaClick= () => {
        const { file, } = this.props;

        if (!isString(file.id)) {
            const { onMediaClick, } = this.props;

            if (onMediaClick) {
                onMediaClick(file);
            }
        }
    };

    getFileType = (msgType: MessageType): MediaType => {
        const isImageMessage = ImageMessageTypes.includes(msgType);
        const isVideoMessage = VideoMessageTypes.includes(msgType);
        const isApplicationMessage = ApplicationMessageTypes.includes(msgType);

        const mediaType: MediaType = (isImageMessage) ? 'image'
            : (isVideoMessage) ? 'video'
                : (isApplicationMessage) ? 'application' : 'unknown';

        return mediaType;
    };

    getFileTypeLabel = (msgType: MessageType): string => {
        switch (msgType) {
            case MessageTypes.png:
                return 'PNG';

            case MessageTypes.jpeg:
            case MessageTypes.jpg:
                return 'JPG';

            case MessageTypes.pdf:
                return 'PDF';

            case MessageTypes.gif:
                return 'GIF';

            case MessageTypes.mp4:
                return 'MP4';

            case MessageTypes.mov:
                return 'MOV';

            default:
                return 'UNKNOWN';
        }
    };

    getFileImage = (id: number, accessToken: string, uri: string, msgType: MessageType, localPath: ?string): * => {
        const {
            fullSize,
        } = this.props;
        switch (msgType) {
            case MessageTypes.gif:
            case MessageTypes.jpeg:
            case MessageTypes.jpg:
            case MessageTypes.png:
                return localPath || selectDonorMedia(id, {
                    messageType: msgType,
                    fileName: uri,
                }, accessToken);

            case MessageTypes.mp4:
            case MessageTypes.mov:
                if (fullSize) {
                    return localPath || selectDonorMediaVideo(id, {
                        messageType: msgType,
                        fileName: uri,
                    }, accessToken);
                }
                return Images.videoIcon;

            case MessageTypes.pdf:
                if (fullSize) {
                    return localPath || selectDonorMediaApplication(id, {
                        messageType: msgType,
                        fileName: uri,
                    }, accessToken);
                }
                return Images.fileIcon;
            default:
                return Images.fileIcon;
        }
    };

    render() {
        const {
            id,
            accessToken,
            file,
            memberName,
            fullSize,
        } = this.props;

        const source = this.getFileImage(id, accessToken, file.fileName, file.messageType, file.localPath);

        const imgNode = (source && source.headers) || (source && fullSize) ? (
            <DownloadingMedia
                source={source}
                type={this.getFileType(file.messageType)}
                display={fullSize ? 'fileListFullSize' : 'fileList'}
                textContent={null}
                chatId={id}
                isLocalSource={!!file.localPath}
                preLoad
                donor
                messageId={file.id}
                onMediaClick={() => {}}
            />
        )
            : (
                <img
                    style={ChatroomDetailsStyles.fileImage}
                    src={source}
                    alt=""
                />
            );

        if (fullSize) {
            return (
                <div>
                    {imgNode}
                </div>
            );
        }

        return (
            <div // eslint-disable-line
                style={ChatroomDetailsStyles.fileContainer}
                onClick={this.onMediaClick}
            >
                <div style={ChatroomDetailsStyles.chatFileWrapper}>
                    <div style={ChatroomDetailsStyles.chatFileImageWrapper}>
                        {imgNode}
                    </div>
                    <div style={ChatroomDetailsStyles.chatFileInfoRow}>
                        <div style={ChatroomDetailsStyles.chatFileTitleRow}>
                            <span style={ChatroomDetailsStyles.chatFileName}>{file.displayName}</span>
                        </div>
                        <div>
                            <span
                                style={ChatroomDetailsStyles.chatFileTimeInfo}
                            >
                                {formatRelative(parse(file.sentTime))}
                                {' '}
                                by
                                {' '}
                                {memberName || `ID:${file.memberId}`}
                            </span>
                        </div>
                    </div>
                    <div style={ChatroomDetailsStyles.chatFileSizeRow}>
                        <span style={ChatroomDetailsStyles.chatFileExtraInfo}>{bytesToSize(file.size, 1)}</span>
                        <span style={ChatroomDetailsStyles.chatFileExtraInfo}>{this.getFileTypeLabel(file.messageType)}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default FileListItem;
