// @flow
import React from 'react';

import styles from './Styles/GenericFormStyles';
import type { FormDefField } from '../Utils/types';

type Props = {
    item: FormDefField,
};

export function getFormLabel(title: string, mandatory?: boolean = false): string {
    return mandatory ? `*${title}` : title;
}

const FormLabel = (props: Props) => {
    const {
        item,
    } = props;

    if (!item) return null;

    return (
        <div style={styles.labelText}>
            {getFormLabel(item.title, item.mandatory)}
        </div>
    );
};

export default FormLabel;
