// @flow
import Colors from '../../Themes/Colors';

const SidebarStyles = {
    mainContainer: {
        marginTop: 10,
        backgroundColor: Colors.white,
    },

    titleText: {
        display: 'flex',
        justifyContent: 'center',
        lineHeight: 2.5,
        color: Colors.formLabel,
    },

    loadingIndicator: {
        marginLeft: '50%',
        marginTop: 60,
    },

    buttonContainer: {
        cursor: 'pointer',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        lineHeight: 2.5,
        display: 'flex',
        justifyContent: 'space-between',
    },

    borderTop: {
        borderTopWidth: '2px',
        borderTopColor: Colors.chatListItemBorder,
        borderTopStyle: 'solid',
    },

    lineButton: {
        color: Colors.blue,
        cursor: 'pointer',
        fontSize: 16,
        paddingLeft: 30,
    },

    helpTextContainer: {
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: 5,
        marginTop: 15,
    },

    helpText: {
        fontSize: 14,
        color: Colors.formLabel,
    },

    noVideos: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 14,
        color: Colors.formLabel,
        lineHeight: 2,
        marginTop: 60,
    },

    centerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },

    muteNotice: {
        fontSize: 16,
        lineHeight: 2,
        textAlign: 'center',
        color: Colors.black,
        marginTop: 10,
    },

    suspendNotice: {
        fontSize: 20,
        lineHeight: 2,
        textAlign: 'center',
        color: Colors.black,
    },

    muteTimeButton: {
        marginHorizontal: 5,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 16,
    },

    turnOffMuteButton: {
        marginHorizontal: 5,
        marginVertical: 5,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 14,
    },

    infoText: {
        fontSize: 12,
        textAlign: 'center',
        color: Colors.formLabel,
    },
};

export default SidebarStyles;
