// @flow
import React, { PureComponent } from 'react';
import { selectProfileName } from 'txp-core';

import MentionUser from './MentionUser';
import MentionStyles from './Styles/MentionStyles';
import Images from '../Themes/Images';
import type { AvatarMap, ChatroomMemberMap } from '../Utils/types';

type Props = {
    members: ChatroomMemberMap,
    memberIds: Array<number>,
    filteredNames: Array<string>,
    avatars: AvatarMap,
    createChatroom: boolean,

    closeMention: () => *,
    addMentionedUser: (memberId: number, members: ChatroomMemberMap) => *,
}

class Mention extends PureComponent<Props> {
    escapeMention = (e: {which: number, }) => {
        const {
            closeMention,
        } = this.props;

        if (e.which === 27) {
            closeMention();
        }
    };

    checkMemberId = (memberId: number) => {
        const {
            members,
            filteredNames,
            createChatroom,
        } = this.props;

        if (createChatroom) {
            return members[memberId.toString()].membershipStatus === 'Pending'
                && filteredNames.includes(selectProfileName(members[memberId.toString()].profile));
        }
        return (members[memberId.toString()].membershipStatus === 'Present')
                && filteredNames.includes(selectProfileName(members[memberId.toString()].profile));
    };

    render() {
        const {
            members,
            memberIds,
            avatars,
            addMentionedUser,
        } = this.props;

        return (
            <div style={MentionStyles.wrapper} role="button" tabIndex={0} onKeyDown={this.escapeMention}>
                <div style={MentionStyles.padding}>
                    {
                        memberIds.map((memberId: number) => (
                            <div key={memberId}>
                                {
                                    this.checkMemberId(memberId)
                                        ? (
                                            <MentionUser
                                                memberId={memberId}
                                                members={members}
                                                avatar={(avatars[memberId] || Images.defaultProfile)}
                                                addMentionedUser={addMentionedUser}
                                            />
                                        ) : <div />
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Mention;
