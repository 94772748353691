const TeamDetailsStyles = {
    teamTitle: {
        fontWeight: 'bold',
        color: 'black',
        paddingBottom: '2px',
    },
    teamCount: {
        color: 'light gray',
        fontSize: 12,
    },
    teamContents: {
        paddingBottom: '10px',

    },
};

export default TeamDetailsStyles;
