// @flow
import type { Logout } from 'txp-core';

import type { ResetData } from './ApplicationActions';
import type { MemberProfile } from '../Utils/types';

export type NetworkState = {
    organizationUsers: Array<MemberProfile>,
    otherUsers: Array<MemberProfile>,
};

const initialState: NetworkState = {
    organizationUsers: [],
    otherUsers: [],
};

export type GetAllUsers = { type: 'Network/GET_ALL_USERS' };
export type ReceiveAllUsers = {
    type: 'Network/RECEIVE_ALL_USERS',
    organizationUsers: Array<MemberProfile>,
    otherUsers: Array<MemberProfile>,
};

type Action =
    | GetAllUsers
    | ReceiveAllUsers
    | ResetData
    | Logout;

export const getAllUsers = (): GetAllUsers => ({
    type: 'Network/GET_ALL_USERS',
});

export const receiveAllUsers = (organizationUsers: Array<MemberProfile>, otherUsers: Array<MemberProfile>): ReceiveAllUsers => ({
    type: 'Network/RECEIVE_ALL_USERS',
    organizationUsers,
    otherUsers,
});

const reducer = (state: NetworkState = initialState, action: Action): NetworkState => {
    switch (action.type) {
        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            return {
                ...initialState,
            };

        case 'Network/RECEIVE_ALL_USERS':
            return {
                ...state,

                organizationUsers: action.organizationUsers.slice(),
                otherUsers: action.otherUsers.slice(),
            };

        default:
            return state;
    }
};

export default reducer;
