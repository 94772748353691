// @flow
import React from 'react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Colors from '../Themes/Colors';

type Props = {
    task: {
        taskId: number,
        details: {
            description: string,
            section: string,
            sectionPosition: ?number,
            highRisk: boolean,
        },
    },
    isSelected: boolean,
    isDisabled: boolean,
    donorId: number,

    onTaskPress: (donorId: number, taskId: number) => *,
};

export default function FollowerTaskItem(props: Props) {
    const {
        donorId,
        task,
        isSelected,
        isDisabled,
        onTaskPress,
    } = props;

    const renderCheckboxIcon = () => (
        <FontAwesomeIcon
            fixedWidth
            icon={isSelected ? faCheckCircle : faCircle}
            color={isDisabled ? Colors.lightGray : Colors.blue}
        />
    );

    return (
        <div>
            <FormControlLabel
                label={task.details.description}
                control={(
                    <Checkbox
                        disabled={isDisabled}
                        checked={isSelected}
                        onChange={() => onTaskPress(donorId, task.taskId)}
                        icon={renderCheckboxIcon()}
                        checkedIcon={renderCheckboxIcon()}
                    />
                )}
            />
        </div>
    );
}
