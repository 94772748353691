// @flow
import React, { useEffect, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { selectUserId, selectProfileName } from 'txp-core';
import Button from '@mui/material/Button';
// TODO: switch to Mui v5 icons when removing Mui v4
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import DeleteIcon from '@material-ui/icons/Delete';
import TabSelector from './TabSelector';
import FileListItem from './FileListItem';
import CasePermissions from './CasePermissions';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import {
    filterChatrooms as _filterChatrooms,
    loadMemberAvatars as _loadMemberAvatars,
} from '../Redux/ChatListActions';
import {
    getDonor as _getDonor,
    getDonorTaskData as _getDonorTaskData,
    getDonorTask as _getDonorTask,
    updateDonorStatus as _updateDonorStatus,
    loadTasks as _loadTasks,
    loadDonorFiles as _loadDonorFiles,
    uploadDonorFile as _uploadDonorFile,
    deleteDonorFile as _deleteDonorFile,
    setDonorSelectedFile as _setDonorSelectedFile,
    resetNavigateToTask as _resetNavigateToTask,
    NOTIFIABLE_GROUP_ID_1,
    NOTIFIABLE_GROUP_ID_2,
    NOTIFIABLE_GROUP_ID_3,
} from '../Redux/DonorActions';
import { getAllUsers as _getAllUsers } from '../Redux/NetworkActions';
import type {
    AttachmentData,
    AuthorizedEntity,
    AvatarMap,
    ChatroomInfo,
    ChatroomMember,
    ChatroomMemberMap,
    Donor,
    File,
    ResourcePermission,
    SearchResult,
    UserPermission,
    UserProfile,
    Team,
    WorkflowTaskMap,
} from '../Utils/types';
import {
    type ChatroomState,
    getContacts as _getContacts,
    muteCase as _muteCase,
} from '../Redux/ChatroomActions';
import BackButton from './BackButton';
import {
    getNotifiableEventGroup as _getNotifiableEventGroup,
} from '../Redux/NotificationActions';
import ChatList from './ChatList';
import DonorHeader from './DonorHeader';
import DonorTaskCardView from './DonorTaskCardView';
import DonorDetails from './DonorDetails';
import hasPermissions, {
    ENTITY_TYPE_DONOR,
    NONE,
    READ,
    SUB_ENTITY_TYPE_TRACKER,
    UPDATE,
    SUB_ENTITY_TYPE_TASK,
    ANY,
} from '../Utils/hasPermissions';
import AddPeople from './AddPeople';
import FollowerGroupDetails from './FollowerGroupDetails';
import FileDropForm from './FileDropForm';
import ReadOnlyBanner from './ReadOnlyBanner';
import HighRiskBanner from './HighRiskBanner';
import ProfileStyles from './Styles/ProfileStyles';
import TaskDetails from './TaskDetails';
import TaskDueDate from './TaskDueDateView';
import {
    selectDonorView as _selectDonorView,
    selectTab as _selectTab,
    CHAT_TAB,
    DONOR_TASK_VIEW,
    DONOR_TASK_DUE_VIEW,
    DONOR_DETAILS_VIEW,
    DONOR_ADD_PEOPLE_VIEW,
    DONOR_ROOMS_VIEW,
    DONOR_STATUS_VIEW,
    DONOR_FOLLOWER_GROUP_VIEW,
    DONOR_CASE_ADMIN_INFO_VIEW,
} from '../Redux/ApplicationActions';
import MemberProfile from './MemberProfile';
import { selectDonorMediaApplication } from '../Redux/ChatMediaActions';
import { downloadFileBlobStart, downloadFileAsAttachment } from '../Utils/downloadFile';
import { MessageTypes } from '../Utils/types';
import type { ResetNavigateToTask } from '../Redux/DonorActions';
import { useWorkflowDefinition } from '../Utils/hooks';
import DonorPanelErrorBoundary from '../Containers/DonorPanelErrorBoundary';

type Props = {
    profile: UserProfile,
    avatars: AvatarMap,
    currentDonor: Donor,
    accessToken: string,
    donorFiles: Array<File>,
    memberId: number,
    selectedFile: File,
    allUsers: Array<MemberProfile>,
    permissions: Array<UserPermission>,
    donorResourcePermissions: Array<ResourcePermission>,
    peopleFilter: string,
    workflowKey: string,
    donorView: string,
    filter: string,
    deepSearchFilter: string,
    isConnected: boolean,
    rooms: Array<ChatroomInfo>,
    clearButtonVisible: boolean,
    teams: Array<Team>,
    teamsLoading: boolean,
    followerHasChanged: boolean,
    requestedTaskId: ?number,
    currentWorkflowTasks: WorkflowTaskMap,

    // NOTE: We need this because this is where
    //   contacts are stored...  Used for the
    //   AddPeople passed in through the CasePermissions
    //   component.
    chatroomState: ChatroomState,

    addPermissionToAuthorizedEntities: (
        authorizedEntities: Array<AuthorizedEntity>,
        entityType: string,
        entityId: number,
        subEntityType: ?string,
        subEntityId: ?number,
        cascade: boolean,
        read: boolean,
        update: boolean,
        canDelete: boolean,
        displayToast: boolean
    ) => *,
    clearMessage: () => *,
    filterPeople: (filter: string) => *,
    goToMessage: (chadId: number, selectedResult: SearchResult) => *,
    getContacts: () => *,
    getTeams: () => *,
    clearChatroomNotifications: (chatId: number) => *,
    filterChatrooms: (filter: string) => *,
    getDonor: (donorId: number) => *,
    getDonorTaskData: (donorId: number) => *,
    getDonorTask: (donorId: number, taskId: number) => *,
    muteCase: (caseId: number, mute: boolean) => *,
    getAllUsers: () => *,
    loadTasks: (workflow: string, donorId: number) => *,
    loadDonorFiles: (donorId: number) => *,
    uploadDonorFile: (donorId: number, attachments: Array<AttachmentData>) => *,
    deleteDonorFile: (fileId: number, donorId: number) => *,
    onCloseDonorPanel: () => *,
    getNotifiableEventGroup: (notifiableGroupId: number) => *,
    setDonorSelectedFile: (id: number, selectedFile: File) => *,
    selectDonorView: (viewName: string) => *,
    updateDonorStatus: (donorId: number, closed: boolean, lastModified: string) => *,
    openChatroom: (chatroomId: number) => *,
    selectTab: (tabName: string) => *,
    loadMemberAvatars: (members: ChatroomMemberMap, memberOrder: Array<number>) => *,
    setSelectedChatroomMember: (selectedChatroomMember: ChatroomMember) => *,
    onOpenCasePreferences: () => *,
    resetNavigateToTask: () => ResetNavigateToTask,
};

function DonorPanel({
    profile,
    avatars,
    currentDonor,
    accessToken,
    donorFiles,
    memberId,
    selectedFile,
    allUsers,
    permissions,
    donorResourcePermissions,
    peopleFilter,
    workflowKey,
    donorView,
    filter,
    deepSearchFilter,
    isConnected,
    rooms,
    clearButtonVisible,
    teams,
    teamsLoading,
    followerHasChanged,
    requestedTaskId,
    currentWorkflowTasks,
    chatroomState,
    addPermissionToAuthorizedEntities,
    clearMessage,
    filterPeople,
    goToMessage,
    getContacts,
    getTeams,
    clearChatroomNotifications,
    filterChatrooms,
    getDonor,
    getDonorTaskData,
    getDonorTask,
    muteCase,
    getAllUsers,
    loadTasks,
    loadDonorFiles,
    uploadDonorFile,
    deleteDonorFile,
    onCloseDonorPanel,
    getNotifiableEventGroup,
    setDonorSelectedFile,
    selectDonorView,
    updateDonorStatus,
    openChatroom,
    selectTab,
    loadMemberAvatars,
    setSelectedChatroomMember,
    onOpenCasePreferences,
    resetNavigateToTask,
}: Props) {
    const [showFiles, setShowFiles] = useState(false);
    const [showFileUpload, setShowFileUpload] = useState(true);
    const [showStatus, setShowStatus] = useState(true);
    const [showPermissions, setShowPermissions] = useState(false);
    const [fullMediaView, setFullMediaView] = useState(false);
    const [openTaskId, setOpenTaskId] = useState(requestedTaskId || -1);

    const { data, } = useWorkflowDefinition(workflowKey);
    const workflow = data?.workflow;

    const trackers = workflow?.trackers ?? [];
    const isReleaseWorkflow = workflowKey === 'RELEASE';
    const notifiableEventGroup = getNotifiableEventGroupInfo(workflowKey ?? '', trackers, isReleaseWorkflow);

    const canViewTracker = hasPermissions(
        permissions || [], ENTITY_TYPE_DONOR, currentDonor.donorId, SUB_ENTITY_TYPE_TRACKER, NONE, READ
    ) && notifiableEventGroup.isNotifiable;

    useEffect(() => {
        if (donorView !== DONOR_TASK_VIEW) {
            setOpenTaskId(-1);
        }
    }, [donorView]);

    useEffect(() => {
        getDonor(currentDonor.donorId);
        getDonorTaskData(currentDonor.donorId);
        getAllUsers();
        getContacts();
        loadTasks(workflowKey, currentDonor.donorId);
        loadDonorFiles(currentDonor.donorId);
        getNotifiableEventGroup(notifiableEventGroup.id);
        loadMemberAvatar();

        if (requestedTaskId && requestedTaskId > 0 && currentWorkflowTasks[workflowKey]) {
            getDonorTask(currentDonor.donorId, requestedTaskId);
            resetNavigateToTask();
        } else if (donorView === DONOR_TASK_VIEW && !requestedTaskId) {
            selectDonorView(DONOR_STATUS_VIEW);
        }
    }, []);

    useEffect(() => {
        getDonor(currentDonor.donorId);
        getDonorTaskData(currentDonor.donorId);
        loadDonorFiles(currentDonor.donorId);
        getNotifiableEventGroup(notifiableEventGroup.id);
    }, [currentDonor.donorId, notifiableEventGroup.id]);

    useEffect(() => {
        loadTasks(workflowKey, currentDonor.donorId);
    }, [currentDonor.donorId, workflowKey]);

    useEffect(() => {
        if (requestedTaskId && requestedTaskId > 0 && currentWorkflowTasks[workflowKey]) {
            getDonorTask(currentDonor.donorId, requestedTaskId);
            resetNavigateToTask();
        }
    }, [currentDonor.donorId, requestedTaskId, currentWorkflowTasks, workflowKey]);

    const onFilesClick = () => {
        setShowFiles(true);
        setShowStatus(false);
        setShowPermissions(false);
        setShowFileUpload(false);
    };

    const onStatusClick = () => {
        setShowFiles(false);
        setShowStatus(true);
        setShowPermissions(false);
        setShowFileUpload(false);
    };

    const onPermissionsClick = () => {
        setShowFiles(false);
        setShowStatus(false);
        setShowPermissions(true);
        setShowFileUpload(false);
    };

    const onAddPeopleClick = () => {
        getContacts();
        getTeams();
        selectDonorView(DONOR_ADD_PEOPLE_VIEW);
    };

    const onCloseAddPeopleClick = () => {
        selectDonorView(DONOR_STATUS_VIEW);
    };

    const onDetailsClick = () => {
        selectDonorView(DONOR_DETAILS_VIEW);
    };

    const onCloseFollowerGroupClick = () => {
        if (followerHasChanged) {
            const response = window.confirm('Unsaved changes\nAre you sure you want to discard your changes to this case?');
            if (response) {
                selectDonorView(DONOR_STATUS_VIEW);
            }
        } else {
            selectDonorView(DONOR_STATUS_VIEW);
        }
    };

    const onChatroomsClick = () => {
        selectDonorView(DONOR_ROOMS_VIEW);
    };

    const onAdminProfileClick = (adminId: number) => {
        let selectedProfile: UserProfile;
        const isCurrentUser = profile.userId === adminId;
        if (isCurrentUser) {
            selectedProfile = profile;
        } else {
            selectedProfile = chatroomState.contactsMap[`${adminId}`].profile;

            // need to get Organization name and role from allUsers
            const selectedProfileDetails = allUsers[getProfileIndex(adminId)].profile;
            selectedProfile.organizationName = selectedProfileDetails.organizationName;
            selectedProfile.organizationalRole = selectedProfileDetails.organizationalRole;
        }

        const adminProfile: ChatroomMember = {
            profile: selectedProfile,
            membershipStatus: 'Present',
            startDate: '',
            endDate: '',
            doNotDisturb: false,
            notificationSound: '',
        };

        setSelectedChatroomMember(adminProfile);
        selectDonorView(DONOR_CASE_ADMIN_INFO_VIEW);
    };

    const onCloseAdminProfileClick = () => {
        setSelectedChatroomMember({});
        selectDonorView(DONOR_STATUS_VIEW);
    };

    const onSearchChatrooms = (currentFilter: string) => {
        filterChatrooms(currentFilter);
    };

    const onDeleteMediaUploadClick = () => {
        closeFullSizeMedia();
        deleteDonorFile(Number(selectedFile.id), currentDonor.donorId);
        // Close the full media view
        selectDonorView(DONOR_STATUS_VIEW);
    };

    const onGoToMessage = (chatId: number, selectedResult: SearchResult) => {
        selectTab(CHAT_TAB);
        goToMessage(chatId, selectedResult);
    };

    const onOpenChatroom = (roomId: number) => {
        selectTab(CHAT_TAB);
        openChatroom(roomId);
    };

    const onMuteAll = () => muteCase(currentDonor.donorId, true);
    const onUnmuteAll = () => muteCase(currentDonor.donorId, false);

    const onChatListGoBack = () => selectDonorView('');

    const onTaskPress = (taskId: number) => {
        setOpenTaskId(taskId);
        getDonorTask(currentDonor.donorId, taskId);
        selectDonorView(DONOR_TASK_VIEW);
    };

    const onRetryDonor = () => {
        if (currentDonor && workflowKey) {
            getDonor(currentDonor.donorId);
            getDonorTaskData(currentDonor.donorId);
            loadDonorFiles(currentDonor.donorId);
            loadTasks(workflowKey, currentDonor.donorId);
        }
    };

    const getProfileIndex = (userId: number) => {
        const myUser = allUsers.findIndex((user) => user.profile.userId === userId);
        return myUser;
    };

    const getOrganizationPermission = () => {
        const orgPermissions = donorResourcePermissions.find((obj) => (obj.authorized.authorizedType === 'Organization'
        && obj.authorized.authorizedId === currentDonor.orgId));

        return orgPermissions;
    };

    const navigateToFollowerGroupPage = () => {
        selectDonorView(DONOR_FOLLOWER_GROUP_VIEW);
    };

    const addMainDonorPermissions = (pendingUsers: Array<UserProfile>) => {
        const authorizedEntities: Array<AuthorizedEntity> = [];

        // Iterate through the pendingUsers to create the permissions request.
        for (let i = 0; i < pendingUsers.length; i += 1) {
            const pendingUser = pendingUsers[i];
            authorizedEntities.push({
                displayName: selectProfileName(pendingUser),
                authorizedType: 'User',
                authorizedId: pendingUser.userId,
            });
        }

        addPermissionToAuthorizedEntities(
            authorizedEntities,
            ENTITY_TYPE_DONOR,
            currentDonor.donorId,
            NONE,
            NONE,
            true,
            true,
            true,
            false,
            true
        );

        // Close the Add People page
        onCloseAddPeopleClick();
    };

    const goToFile = (file: File) => {
        setDonorSelectedFile(currentDonor.donorId, file);

        setFullMediaView(true);
    };

    const downloadFile = async (file: File, donorId: number, curAccessToken: string) => {
        const { fileName, messageType, } = file;
        const source = selectDonorMediaApplication(donorId, {
            messageType,
            fileName,
        }, curAccessToken);

        const fileNameParts = file.fileName.split('/');
        const downloadFileName = fileNameParts[fileNameParts.length - 1];

        if (source) {
            const blob = await downloadFileBlobStart({ fromUrl: source.uri, headers: source.headers, });
            downloadFileAsAttachment(blob, downloadFileName);
        }
    };

    const closeFullSizeMedia = () => {
        setFullMediaView(false);
    };

    const toggleFileUpload = () => {
        setShowFileUpload(!showFileUpload);
    };

    const loadMemberAvatar = () => {
        // Load this users avatar, if not already loaded
        const contactsMap = {};
        contactsMap[profile.userId] = {
            profile,
            membershipStatus: null,
        };
        loadMemberAvatars(contactsMap, [profile.userId]);
    };

    const uploadFile = (file: any) => {
        const mediaArray = [{
            file,
            fileName: file.name,
            path: URL.createObjectURL(file),
            mime: file.type,
            size: file.size,
            displayName: null,
        }];

        uploadDonorFile(currentDonor.donorId, mediaArray);
        setShowFileUpload(false);
    };

    const renderStatusView = () => (
        <DonorTaskCardView donorId={currentDonor.donorId} canViewTracker={canViewTracker} onTaskPress={onTaskPress} onRetryDonor={onRetryDonor} />
    );

    const renderFileView = () => {
        const {
            donorId,
            closed,
        } = currentDonor;

        const donorUpdateAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorId, NONE, NONE, UPDATE);

        return (
            <>
                <FileDropForm
                    visible={showFileUpload}
                    disabled={!donorUpdateAllowed}
                    onUploadFile={uploadFile}
                    onCancelFileUpload={toggleFileUpload}
                    acceptedFileTypes=".png, .jpg, .jpeg, .mp4, .mov, .pdf, .gif"
                />
                <div style={ChatroomDetailsStyles.border} />
                <div
                    style={donorUpdateAllowed && !closed ? ChatroomDetailsStyles.commandArea : ChatroomDetailsStyles.commandAreaDisabled}
                    className="lineButtons"
                    role="button"
                    tabIndex="0"
                    onKeyDown={donorUpdateAllowed && !closed ? toggleFileUpload : null}
                    onClick={donorUpdateAllowed && !closed ? toggleFileUpload : null}
                    data-tip="Add files to the case"
                    data-delay-show="500"
                    data-effect="solid"
                >
                    Add File
                </div>
                <div style={ChatroomDetailsStyles.border} />
                {(donorFiles && donorFiles.length !== 0) ? (
                    <div>
                        {donorFiles.map((file: File) => {
                            const member = allUsers.find((obj) => obj.profile.userId === file.memberId);

                            return (
                                <FileListItem
                                    key={file.id}
                                    file={file}
                                    memberName={member && member.profile ? selectProfileName(member.profile) : 'OmniLife User'}
                                    id={donorId}
                                    userId={memberId}
                                    accessToken={accessToken}
                                    fullSize={false}
                                    onMediaClick={
                                        file.messageType === MessageTypes.pdf
                                            ? (mFile) => downloadFile(mFile, donorId, accessToken)
                                            : () => goToFile(file)
                                    }
                                />
                            );
                        })}
                    </div>
                ) : (
                    <div style={ChatroomDetailsStyles.noFiles}>
                        No files have been uploaded for this case
                    </div>
                )}
            </>
        );
    };

    const renderAddPeople = () => {
        const {
            contactsOrder,
            contactsMap,
            contactsAreLoaded,
            singleUserAdded,
            newUserInvited,
            addSingleUserError,
            inviteNewUserError,
        } = chatroomState;

        // Use the resourcePermissions for the current
        //   donor to populate a simple members object
        const members = {};
        for (let i = 0; i < donorResourcePermissions.length; i += 1) {
            const { authorized: { authorizedType, authorizedId, }, target: { cascade, subEntityType, }, } = donorResourcePermissions[i];
            // Case admins have cascading permissions with no subEntity target
            const hasCaseAdminPermissions = authorizedType === 'User' && cascade && subEntityType === null;

            if (hasCaseAdminPermissions) {
                members[authorizedId] = true;
            }
        }

        return (
            <div style={ChatroomDetailsStyles.mainContainer}>
                <AddPeople
                    memberId={memberId}
                    contactsOrder={contactsOrder}
                    contactsMap={contactsMap}
                    teams={teams}
                    teamsLoading={teamsLoading}
                    contactsAreLoaded={contactsAreLoaded}
                    singleUserAdded={singleUserAdded}
                    newUserInvited={newUserInvited}
                    addSingleUserError={addSingleUserError}
                    inviteNewUserError={inviteNewUserError}
                    avatars={avatars}
                    members={members}
                    peopleFilter={peopleFilter}
                    createChatroom={false}
                    isConnected={isConnected}
                    onHideAddPeople={onCloseAddPeopleClick}
                    onAddUsers={addMainDonorPermissions}
                    clearMessage={clearMessage}
                    filterPeople={filterPeople}
                />
            </div>
        );
    };

    const renderFollowerGroup = () => (
        <FollowerGroupDetails
            onGoBackClick={onCloseFollowerGroupClick}
        />
    );

    const renderAdminProfile = () => (
        <MemberProfile
            buttonLabel="Back"
            closeMemberProfile={onCloseAdminProfileClick}
        />
    );

    const renderChatroomList = () => {
        const donorRooms = rooms.filter((room) => room.donorId === currentDonor.donorId);

        // NOTE: currentChatId set to 0, no chat will be "selected" in donor panel view
        return (
            <>
                <div style={ProfileStyles.marginTop10}>
                    <BackButton
                        label="Back"
                        goBack={onChatListGoBack}
                    />
                </div>
                <div style={ProfileStyles.muteButtons}>
                    <Button onClick={onMuteAll} startIcon={<NotificationsOffIcon />} style={ProfileStyles.muteButton}>
                        Mute All
                    </Button>
                    <Button onClick={onUnmuteAll} startIcon={<NotificationsIcon />} style={ProfileStyles.muteButton}>
                        Unmute All
                    </Button>
                </div>
                <ChatList
                    rooms={donorRooms}
                    currentChatId={0}
                    chatSelected={false}
                    buttonVisible={clearButtonVisible}
                    filter={filter}
                    deepSearchFilter={deepSearchFilter}
                    filterChatrooms={onSearchChatrooms}
                    openChatroom={onOpenChatroom}
                    clearChatroomNotifications={clearChatroomNotifications}
                    goToMessage={onGoToMessage}
                />
            </>
        );
    };

    const renderPermissionsView = () => {
        const orgPermission: ?ResourcePermission = getOrganizationPermission();

        if (showPermissions) {
            return (
                <CasePermissions
                    avatars={avatars}
                    donorId={currentDonor.donorId}
                    workflowKey={workflowKey}
                    onShowAddPeopleClicked={onAddPeopleClick}
                    onShowAdminProfile={onAdminProfileClick}
                    navigateToFollowerGroup={navigateToFollowerGroupPage}
                    orgPermissions={orgPermission}
                    donorCreatorId={currentDonor.userId}
                    orgId={currentDonor.orgId}
                    onOpenCasePreferences={onOpenCasePreferences}
                />
            );
        }

        return null;
    };

    const renderTabs = () => {
        const canUpdateDonor = hasPermissions(permissions, ENTITY_TYPE_DONOR, currentDonor.donorId, NONE, NONE, UPDATE);

        return (
            <div>
                <ReactTooltip />
                <div>
                    <div>
                        <div style={{ marginTop: 10, }}>
                            <BackButton
                                label="Close"
                                goBack={onCloseDonorPanel}
                            />
                        </div>
                        <DonorHeader
                            donor={currentDonor}
                            showDetailsButton
                            showChatroomButton
                            showTrackerButton={canViewTracker}
                            showCloseDonorButton={canUpdateDonor}
                            detailsClick={onDetailsClick}
                            updateDonorStatus={updateDonorStatus}
                            chatroomClick={onChatroomsClick}
                        />
                    </div>
                </div>
                <TabSelector
                    labels={['Status', 'Files', 'Permissions']}
                    selected={[showStatus, showFiles, showPermissions]}
                    width={100}
                    borderRadius={false}
                    clickEvents={[onStatusClick, onFilesClick, onPermissionsClick]}
                />
                {showFiles ? renderFileView() : null}
                {showStatus ? renderStatusView() : null}
                {showPermissions ? renderPermissionsView() : null}
            </div>
        );
    };

    const renderFullMediaView = () => {
        const isUploadedByCurrentUser = selectedFile.memberId === profile.userId;
        // Only allow deletion of files that were uploaded by the current user, and only within 15 minutes of upload.
        const uploadTime = new Date(selectedFile.sentTime);
        const currentTime = new Date();
        const timeDifferenceInMinutes = (currentTime - uploadTime) / (1000 * 60);

        const isRecentlyUploaded = timeDifferenceInMinutes <= 15;
        const canDeleteFile = isUploadedByCurrentUser && isRecentlyUploaded;

        if (fullMediaView && selectedFile) {
            const member = allUsers.find((obj) => obj.profile.userId === selectedFile.memberId);
            return (
                <div>
                    <div style={ChatroomDetailsStyles.mediaUploadHeader}>
                        <div style={ChatroomDetailsStyles.fullMediaViewControlsContainer}>
                            <BackButton
                                label="Back"
                                goBack={closeFullSizeMedia}
                            />
                            {canDeleteFile ? (
                                <Button
                                    onClick={onDeleteMediaUploadClick}
                                    style={ChatroomDetailsStyles.deleteFileButton}
                                    title="You may delete files within 15 minutes of uploading."
                                    endIcon={<DeleteIcon />}
                                >
                                    Delete file
                                </Button>
                            ) : null}
                        </div>
                        <div style={ChatroomDetailsStyles.headerPadding}>
                            <div style={ChatroomDetailsStyles.textWrapper}>
                                <div style={ChatroomDetailsStyles.centeredTitleLarge}>
                                    {selectedFile.displayName}
                                </div>
                            </div>
                        </div>
                    </div>
                    <FileListItem
                        key={currentDonor.donorId}
                        file={selectedFile}
                        memberName={member && member.profile ? selectProfileName(member.profile) : 'OmniLife User'}
                        id={currentDonor.donorId}
                        accessToken={accessToken}
                        fullSize
                        onMediaClick={() => {}}
                    />
                </div>
            );
        }

        return null;
    };

    const renderDonorPanel = () => {
        const donorUpdateAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, currentDonor.donorId, NONE, NONE, UPDATE);

        if (donorView === DONOR_TASK_VIEW) {
            return <TaskDetails taskId={openTaskId} />;
        }

        if (donorView === DONOR_TASK_DUE_VIEW) {
            return <TaskDueDate />;
        }

        if (donorView === DONOR_DETAILS_VIEW) {
            return <DonorDetails canUpdate={donorUpdateAllowed && !currentDonor.closed} />;
        }

        if (donorView === DONOR_ADD_PEOPLE_VIEW) {
            return renderAddPeople();
        }

        if (donorView === DONOR_ROOMS_VIEW) {
            return renderChatroomList();
        }

        if (donorView === DONOR_FOLLOWER_GROUP_VIEW) {
            return renderFollowerGroup();
        }

        if (donorView === DONOR_CASE_ADMIN_INFO_VIEW) {
            return renderAdminProfile();
        }

        if (fullMediaView) {
            return renderFullMediaView();
        }

        return renderTabs();
    };

    const donorUpdateAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, currentDonor.donorId, NONE, NONE, UPDATE);
    const taskOnlyUpdateAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, currentDonor.donorId, SUB_ENTITY_TYPE_TASK, ANY, UPDATE);

    let banner = null;
    if (currentDonor.closed) {
        banner = <ReadOnlyBanner entity="case" message="This case has been closed and is now view only." />;
    } else if (!donorUpdateAllowed && taskOnlyUpdateAllowed) {
        banner = <ReadOnlyBanner entity="case" message="You can edit select tasks in this case." />;
    } else if (!donorUpdateAllowed) {
        banner = <ReadOnlyBanner entity="case" />;
    }

    let highRiskBanner = null;
    if (currentDonor.highRisk) {
        highRiskBanner = <HighRiskBanner entity="case" />;
    }

    return (
        <DonorPanelErrorBoundary onRetryDonor={onRetryDonor}>
            <div>
                {highRiskBanner}
                {banner}
                {renderDonorPanel()}
            </div>
        </DonorPanelErrorBoundary>
    );
}

const mapStateToProps = (state) => {
    const currentDonorId = state.donor.openDonorId;
    const currentDonor = state.donor.donors[`${currentDonorId}`];
    const donorFiles = currentDonor && currentDonor.fileIds && currentDonor.files
        ? (currentDonor.fileIds || []).map((fileId) => currentDonor.files[`${fileId}`] || null) : undefined;
    const permissions = state.permission.permissions || [];

    let donorResourcePermissions = [];
    const stateResourcePermissions = state.permission.resourcePermissions;
    if (stateResourcePermissions && stateResourcePermissions[ENTITY_TYPE_DONOR] && stateResourcePermissions[ENTITY_TYPE_DONOR][currentDonorId]) {
        donorResourcePermissions = stateResourcePermissions[ENTITY_TYPE_DONOR][currentDonorId];
    }

    const opoDonorId = currentDonor ? currentDonor.opoDonorId : '';
    const isReleaseWorkflow = (opoDonorId && opoDonorId.toUpperCase().includes('RELEASE'));
    const workflowKey = isReleaseWorkflow ? 'RELEASE' : (currentDonor?.workflow ?? null);

    const { followerHasChanged, } = state.followerEdit;
    return {
        memberId: selectUserId(state.auth),
        mediaLoading: state.loading.media,
        accessToken: state.auth.accessToken,
        allUsers: (state.network.organizationUsers || []).concat((state.network.otherUsers || [])),
        avatars: state.chatList.avatars || {},
        currentDonor,
        donorFiles,
        selectedFile: state.donor.donors[`${currentDonorId}`] ? state.donor.donors[`${currentDonorId}`].selectedFile : null,
        permissions,
        donorResourcePermissions,
        workflowKey,
        donorView: state.application.donorView,
        filter: state.chatList.filter || '',
        deepSearchFilter: state.chatList.deepSearchFilter || '',
        followerHasChanged,
        requestedTaskId: state.donor.navigateTaskId,
        currentWorkflowTasks: state.donor.workflowTasks,
    };
};

function getNotifiableEventGroupInfo(workflowKey: string, trackers: { id: number }[], isReleaseWorkflow: boolean) {
    if (trackers.length > 0 && trackers[0].id) {
        return { id: trackers[0].id, isNotifiable: true, };
    }

    if (workflowKey === 'ANATOMY') {
        return { id: NOTIFIABLE_GROUP_ID_3, isNotifiable: true, };
    }

    if (isReleaseWorkflow) {
        return { id: NOTIFIABLE_GROUP_ID_2, isNotifiable: true, };
    }

    // NOTIFIABLE_GROUP_ID_1 is the default if there is no tracker id in reference data, but in the case that the workflow is OPODEFAULT
    // then having id === NOTIFIABLE_GROUP_ID_1 is okay.
    return { id: NOTIFIABLE_GROUP_ID_1, isNotifiable: workflowKey === 'OPODEFAULT', };
}

export default connect(mapStateToProps, {
    filterChatrooms: _filterChatrooms,
    getDonor: _getDonor,
    getDonorTaskData: _getDonorTaskData,
    getDonorTask: _getDonorTask,
    updateDonorStatus: _updateDonorStatus,
    getAllUsers: _getAllUsers,
    loadTasks: _loadTasks,
    loadDonorFiles: _loadDonorFiles,
    uploadDonorFile: _uploadDonorFile,
    deleteDonorFile: _deleteDonorFile,
    setDonorSelectedFile: _setDonorSelectedFile,
    getNotifiableEventGroup: _getNotifiableEventGroup,
    selectDonorView: _selectDonorView,
    selectTab: _selectTab,
    loadMemberAvatars: _loadMemberAvatars,
    getContacts: _getContacts,
    resetNavigateToTask: _resetNavigateToTask,
    muteCase: _muteCase,
})(DonorPanel);
