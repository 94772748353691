// @flow
import type { Logout } from 'txp-core';
import type {
    UserProfile,
} from '../Utils/types';
import type {
    ResetData,
} from './ApplicationActions';

export type AddPeopleResult = { user: UserProfile };
export type AddPeopleError = { error: string };
export type EmptyObject = $Shape<{||}>;
export type AddPeopleType = AddPeopleResult | AddPeopleError | EmptyObject;

export type AddPeopleState = {
    selectedUsers: UserProfile[],
    error?: string,
};

const initialState: AddPeopleState = {
    selectedUsers: [],
    error: undefined,
};

export type SetSelectedUsers = { type: 'AddPeople/SET_SELECTED_USERS', users: UserProfile[] };
export type SetAddPeopleError = { type: 'AddPeople/SET_ERROR', error?: string };
export type ResetAddPeople = { type: 'AddPeople/RESET' };
export type AddPeopleInvite = { type: 'AddPeople/INVITE_NEW_USER', email?: string, phone?: string };
export type AddPeopleByEmail = { type: 'AddPeople/ADD_PEOPLE_BY_EMAIL', email: string };
export type AddPeopleByPhone = { type: 'AddPeople/ADD_PEOPLE_BY_PHONE', phone: string };

type Action =
    | ResetData
    | Logout
    | SetSelectedUsers
    | SetAddPeopleError
    | ResetAddPeople
    | AddPeopleInvite
    | AddPeopleByEmail
    | AddPeopleByPhone;

export const setSelectedUsers = (users: UserProfile[]): SetSelectedUsers => ({
    type: 'AddPeople/SET_SELECTED_USERS',
    users,
});
export const setAddPeopleError = (error?: string): SetAddPeopleError => ({
    type: 'AddPeople/SET_ERROR',
    error,
});
export const resetAddPeople = (): ResetAddPeople => ({
    type: 'AddPeople/RESET',
});
export const inviteNewUser = (email?: string, phone?: string): AddPeopleInvite => ({
    type: 'AddPeople/INVITE_NEW_USER',
    email,
    phone,
});
export const addPeopleByEmail = (email: string): AddPeopleByEmail => ({
    type: 'AddPeople/ADD_PEOPLE_BY_EMAIL',
    email,
});
export const addPeopleByPhone = (phone: string): AddPeopleByPhone => ({
    type: 'AddPeople/ADD_PEOPLE_BY_PHONE',
    phone,
});

const reducer = (state: AddPeopleState = initialState, action: Action): AddPeopleState => {
    switch (action.type) {
        case 'Application/RESET_DATA':
        case 'AddPeople/RESET':
        case 'Auth/LOGOUT':
            // Reset to initial state when logging out or resetting
            return {
                ...initialState,
            };

        case 'AddPeople/SET_SELECTED_USERS':
            return {
                ...state,
                selectedUsers: action.users,
            };

        case 'AddPeople/SET_ERROR':
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
};

export default reducer;
