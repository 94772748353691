// @flow
import type { Saga } from 'redux-saga';
import {
    call,
    delay,
    put,
    select,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import api from '../Services/Api';
import { finishLoading, startLoading } from '../Redux/LoadingActions';
import {
    createTeamFailed,
    openTeam,
    receiveTeams,
    resetTeamEditState,
} from '../Redux/TeamActions';
import {
    apiFetch,
    apiDelete,
    apiPost,
    apiPut,
} from './ApiSaga';
import { pushError, setSagaMessage } from '../Redux/ApplicationActions';
import parseProfile from '../Utils/profile';
import type { CreateTeam, DeleteTeam, UpdateTeam } from '../Redux/TeamActions';
import { getPermissionsSaga } from './PermissionSaga';

export function* getTeamsSaga(): Saga<void> {
    yield put(startLoading('teams'));

    const { result, error, } = yield apiFetch(api.txp.teams);

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading teams failed, are you online?', error));
        } else {
            Sentry.captureException(`GetTeamsSaga Failed - Other Error: ${JSON.stringify(error)}`);
        }
    } else {
        const teamsResult = result.teams;

        const teams = [];
        for (let i = 0; i < teamsResult.length; i += 1) {
            const tempTeam = teamsResult[i];
            const teamMembers = [];
            for (let j = 0; j < tempTeam.team_members.length; j += 1) {
                const parsedMember = parseProfile(tempTeam.team_members[j].user_information);
                teamMembers.push(parsedMember);
            }
            teams.push({
                teamId: tempTeam.team_id,
                creatorId: tempTeam.creator_id || -1,
                teamName: tempTeam.team_name,
                shared: tempTeam.shared,
                teamMembers,
            });
        }

        yield put(receiveTeams(teams));
    }
    yield put(finishLoading('teams'));
}

export function* createTeamSaga(action: CreateTeam): Saga<void> {
    const {
        teamName,
        teamMembers,
        shared,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    //  https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('saveTeam'));

    const userIds = [];
    for (let i = 0; i < teamMembers.length; i += 1) {
        if (teamMembers[i].userId != null) {
            userIds.push(teamMembers[i].userId);
        }
    }

    const { result, error, } = yield apiPost(api.txp.createTeam, null, {
        team_name: teamName,
        team_member_ids: userIds,
        shared,
    });

    if (error) {
        const errors = {};
        if (error.isInvalidResponseCode) {
            errors.teamName = 'Team with given name already exists';
            yield put(setSagaMessage('', errors.teamName, ''));
            yield put(createTeamFailed(errors));
        } else if (error.isNetworkError) {
            const errorMessage = 'Creating a team failed, are you online?';
            yield put(pushError(errorMessage, error));
            yield put(setSagaMessage('', errorMessage, ''));
        } else {
            yield put(pushError('Creating a team failed, try again later', error));
            yield put(setSagaMessage('', 'Team creation failed!', ''));
        }
        yield put(finishLoading('saveTeam'));
    } else {
        const teams = yield select((state) => state.team.teams || []);

        const members = [];
        for (let i = 0; i < result.team_members.length; i += 1) {
            const parsedMember = parseProfile(result.team_members[i].user_information);
            members.push(parsedMember);
        }
        teams.push({
            teamId: result.team_id,
            creatorId: result.creator_id || -1,
            teamName: result.team_name,
            teamMembers: members,
            shared: result.shared,
        });

        yield put(receiveTeams(teams));

        // refresh permissions
        yield call(getPermissionsSaga);

        yield put(resetTeamEditState());
        yield put(finishLoading('saveTeam'));
        yield put(openTeam(result.team_id));
    }
}

export function* deleteTeamSaga(action: DeleteTeam): Saga<void> {
    const {
        teamId,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    //  https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('deleteTeam'));

    const { error, } = yield apiDelete(api.txp.team, {
        teamId,
    });

    if (error) {
        const toastMessage = 'Deleting team failed!';
        yield put(setSagaMessage('', toastMessage, ''));
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Deleting team failed, are you online?', error));
        } else {
            yield put(pushError('Deleting team failed, try again later', error));
        }
        yield put(finishLoading('deleteTeam'));
    } else {
        const toastMessage = 'Team deletion successful';
        yield put(setSagaMessage('', toastMessage, ''));
        const teams = yield select((state) => state.team.teams);

        const index = teams.findIndex((x) => x.teamId === teamId);

        teams.splice(index, 1);

        yield put(receiveTeams(teams));

        yield put(finishLoading('deleteTeam'));
    }
}

export function* updateTeamSaga(action: UpdateTeam): Saga<void> {
    const {
        teamId,
        teamName,
        teamMembers,
        shared,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    //  https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const teamMemberIds = teamMembers.map((member) => member.userId);

    const { result, error, } = yield apiPut(api.txp.updateTeam, {
        teamId,
    }, {
        team_name: teamName,
        team_member_ids: teamMemberIds,
        shared,
    });

    if (error) {
        let toastMessage = 'Team update failed!';
        if (error.statusCode === 404) {
            yield put(pushError('Team with given ID does not exist'));
        } else if (error.statusCode === 409) {
            toastMessage = 'Team name already exists';
            yield put(pushError('Team with given name already exists'));
        } else if (error.statusCode === 403) {
            yield put(pushError('User does not have permission to update team with given ID'));
        } else if (error.statusCode === 400) {
            yield put(pushError('Invalid request'));
        } else if (error.isNetworkError) {
            toastMessage = 'Updating team failed, are you online?';
            yield put(pushError('Updating team failed, are you online?', error));
        } else {
            yield put(pushError('Updating team failed, try again later', error));
        }
        yield put(setSagaMessage('', toastMessage, ''));
    } else {
        const toastMessage = 'Team update successful';
        yield put(setSagaMessage('', toastMessage, ''));
        const members = [];
        for (let i = 0; i < result.team_members.length; i += 1) {
            const parsedMember = parseProfile(result.team_members[i].user_information);
            members.push(parsedMember);
        }
        const teams = yield select((state) => state.team.teams);

        const index = teams.findIndex((x) => x.teamId === teamId);

        teams[index].teamMembers = members;
        teams[index].teamName = result.team_name;
        teams[index].shared = result.shared;
        // creator id should be the same as the team in state, but update from API to make sure
        teams[index].creatorId = result.creator_id;

        yield put(receiveTeams(teams));
        yield put(resetTeamEditState());
    }
}
