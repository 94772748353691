// @flow
import type { Logout } from 'txp-core';

import type { ResetData } from './ApplicationActions';
import type {
    AllResourcePermissionMap,
    ResourcePermission,
    UserPermission,
    AuthorizedEntity,
} from '../Utils/types';

export type PermissionState = {
    permissions: Array<UserPermission>,

    resourcePermissions: AllResourcePermissionMap,
};

const initialState: PermissionState = {
    permissions: [],

    resourcePermissions: {},
};

export type GetPermissions = { type: 'Permission/GET_PERMISSIONS' };
export type StorePermissions = { type: 'Permission/STORE_PERMISSIONS', permissions: Array<UserPermission> };

export type GetResourcePermissions = { type: 'Permission/GET_RESOURCE_PERMISSIONS', entityType: string, entityId: number };
export type ReceiveResourcePermissions = {
    type: 'Permission/RECEIVE_RESOURCE_PERMISSIONS',
    entityType: string,
    entityId: number,
    resourcePermissions: Array<ResourcePermission>,
};

export type AddPermission = {
    type: 'Permission/ADD',
    entityType: string,
    entityId: number,
    subEntityType: ?string,
    subEntityId: ?number,
    cascade: boolean,
    read: boolean,
    update: boolean,
    canDelete: boolean,
    authorizedType: string,
    authorizedId: number,
    displayName: string,
    displayToast: boolean,
};

export type UpdatePermission = {
    type: 'Permission/UPDATE',
    entityType: string,
    entityId: number,
    permissionId: number,
    read: boolean,
    update: boolean,
    canDelete: boolean,
};

export type AddPermissionToAuthorizedEntities = {
    type: 'Permission/ADD_TO_AUTHORIZED_ENTITIES',
    authorizedEntities: Array<AuthorizedEntity>,
    entityType: string,
    entityId: number,
    subEntityType: ?string,
    subEntityId: ?number,
    cascade: boolean,
    read: boolean,
    update: boolean,
    canDelete: boolean,
    displayToast: boolean
};
export type RemovePermission = { type: 'Permission/REMOVE', entityType: string, entityId: number, permissionId: number };

type Action =
    | GetPermissions
    | StorePermissions
    | GetResourcePermissions
    | ReceiveResourcePermissions
    | AddPermission
    | RemovePermission
    | UpdatePermission
    | AddPermissionToAuthorizedEntities
    | Logout
    | ResetData;

export const getPermissions = (): GetPermissions => ({ type: 'Permission/GET_PERMISSIONS', });
export const storePermissions = (permissions: Array<UserPermission>): StorePermissions => ({
    type: 'Permission/STORE_PERMISSIONS',
    permissions,
});

export const getResourcePermissions = (entityType: string, entityId: number): GetResourcePermissions => ({
    type: 'Permission/GET_RESOURCE_PERMISSIONS',
    entityType,
    entityId,
});

export const receiveResourcePermissions = (
    entityType: string,
    entityId: number,
    resourcePermissions: Array<ResourcePermission>
): ReceiveResourcePermissions => ({
    type: 'Permission/RECEIVE_RESOURCE_PERMISSIONS',
    entityType,
    entityId,
    resourcePermissions,
});

export const addPermissionToAuthorizedEntities = (
    authorizedEntities: Array<AuthorizedEntity>,
    entityType: string,
    entityId: number,
    subEntityType: ?string,
    subEntityId: ?number,
    cascade: boolean,
    read: boolean,
    update: boolean,
    canDelete: boolean,
    displayToast: boolean
): AddPermissionToAuthorizedEntities => ({
    type: 'Permission/ADD_TO_AUTHORIZED_ENTITIES',
    authorizedEntities,
    entityType,
    entityId,
    subEntityType,
    subEntityId,
    cascade,
    read,
    update,
    canDelete,
    displayToast,
});

export const addPermission = (
    entityType: string,
    entityId: number,
    subEntityType: ?string,
    subEntityId: ?number,
    cascade: boolean,
    read: boolean,
    update: boolean,
    canDelete: boolean,
    authorizedType: string,
    authorizedId: number,
    displayName: string,
    displayToast: boolean
): AddPermission => ({
    type: 'Permission/ADD',
    entityType,
    entityId,
    subEntityType,
    subEntityId,
    cascade,
    read,
    update,
    canDelete,
    authorizedType,
    authorizedId,
    displayName,
    displayToast,
});

export const updatePermission = (
    entityType: string,
    entityId: number,
    permissionId: number,
    read: boolean,
    update: boolean,
    canDelete: boolean
): UpdatePermission => ({
    type: 'Permission/UPDATE',
    entityType,
    permissionId,
    entityId,
    read,
    update,
    canDelete,
});

export const removePermission = (entityType: string, entityId: number, permissionId: number): RemovePermission => ({
    type: 'Permission/REMOVE',
    entityType,
    entityId,
    permissionId,
});

const reducer = (state: PermissionState = initialState, action: Action): PermissionState => {
    switch (action.type) {
        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            // Reset the store when logging out
            return {
                ...initialState,
            };

        case 'Permission/STORE_PERMISSIONS':
            return {
                ...state,

                permissions: action.permissions.slice(),
            };

        case 'Permission/RECEIVE_RESOURCE_PERMISSIONS':
            return {
                ...state,

                resourcePermissions: {
                    ...state.resourcePermissions,

                    [action.entityType]: {
                        ...state.resourcePermissions[action.entityType],

                        [action.entityId]: action.resourcePermissions.slice(),
                    },
                },
            };

        default:
            return state;
    }
};

export default reducer;
