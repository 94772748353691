import {
    createStore,
    applyMiddleware,
    compose,
    combineReducers,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import arrayMiddleware from './Middleware/ArrayMiddleware';

export default function configureStore(rootReducer) {
    const middleware = [
        arrayMiddleware
    ];

    // Create Saga Middleware
    const sagaMiddleware = createSagaMiddleware({
        // rethrow the error to trigger the application ErrorBoundary
        onError: (error) => {
            throw error;
        },
    });
    middleware.push(sagaMiddleware);

    // Add logger if in development
    // Note: the output is not pretty (in the terminal)
    // using the following packages as alternatives might provide better DX
    // - https://github.com/zalmoxisus/remote-redux-devtools
    // - https://github.com/jhen0409/react-native-debugger
    if (process.env.NODE_ENV === 'development') {
        const logger = createLogger({
            duration: true,
        });
        middleware.push(logger);
    }

    // Create reducer from rootReducer
    const combinedReducer = combineReducers(rootReducer);

    // Create the store
    const store = createStore(combinedReducer, compose(applyMiddleware(...middleware)));

    return {
        store,
        sagaMiddleware,
        replaceReducer: store.replaceReducer,
    };
}
