import React from 'react';
import ReactDOM from 'react-dom';
import ThemeProvider from 'react-toolbox/lib/ThemeProvider';
import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import { initializeSentry } from 'txp-core';

import App from './App';
import { register } from './registerServiceWorker';
import theme from './toolbox/theme';
import './toolbox/theme.css';
import { appConfiguration } from './AppConfiguration';
import { initializeFirebase } from './Services/PushNotification';
import Redirect from './Navigation/Redirect';

const { version, } = appConfiguration();
Sentry.init({
    dsn: 'https://e6d47462b48f4d229ad981a54a64d725@sentry.io/1372354',
    environment: process.env.NODE_ENV,
    release: version,
    integrations: [new Sentry.Integrations.Breadcrumbs({
        console: false,
    })],
    beforeSend(event) {
        return process.env.NODE_ENV === 'development' ? null : event;
    },
});

const loadActivationId = () => {
    const values = queryString.parse(window.location.search);
    return values.activation_id ? values.activation_id : '';
};

const loadEmail = () => {
    const values = queryString.parse(window.location.search);
    return values.email ? values.email : '';
};

const activationId = loadActivationId();
const email = loadEmail();
const root = document.getElementById('root');

register();
initializeFirebase();
initializeSentry('https://e6d47462b48f4d229ad981a54a64d725@sentry.io/1372354', process.env.NODE_ENV, version);

if (root !== null) {
    ReactDOM.render(
        <Redirect>
            <ThemeProvider theme={theme}>
                <App activationId={activationId} email={email} />
            </ThemeProvider>
        </Redirect>,
        root
    );
}
