/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
// @flow
import type { FormDefData } from '../../Utils/types';
import {
    STRING, UI_TYPE_BINARY,
} from './FormTypes';

const formDef: FormDefData = {
    formId: 'PreScreen',
    behaviors: {
        lockWhenFinalized: true,
        saveOnComplete: true,
        clearOnIncomplete: true,
        clearOnNotApplicable: true,
    },
    sections: [
        {
            id: 1,
            title: '',
            fields: [
                { id: 1, title: 'Blood Taken', type: STRING, uiType: UI_TYPE_BINARY, responseOptions: ['Yes', 'No'], mandatory: true, tags: [], },
                { id: 2, title: 'Covid Test Taken', type: STRING, uiType: UI_TYPE_BINARY, responseOptions: ['Yes', 'No'], mandatory: false, tags: [], }
            ],
        }
    ],
};

export default formDef;
