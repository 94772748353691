// @flow
import React, { PureComponent } from 'react';
import Button from 'react-toolbox/lib/button/Button';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import ApplicationStyles from '../Themes/ApplicationStyles';

type Props = {
    text: string,
    dialogTitle: string,
    dialogText: string,
    onConfirm: () => *,
};

type State = {
  showDialog: boolean,
};

class SkipButton extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            showDialog: false,
        };
    }

    onConfirm = () => {
        const {
            onConfirm,
        } = this.props;

        this.setState({
            showDialog: false,
        });

        onConfirm();
    }

    closeConfirmationDialog = () => {
        this.setState({
            showDialog: false,
        });
    }

    showConfirmationDialog = () => {
        this.setState({
            showDialog: true,
        });
    }

    render() {
        const {
            text,
            dialogTitle,
            dialogText,
        } = this.props;

        const {
            showDialog,
        } = this.state;

        const dialogActions = [
            { label: 'No', onClick: this.closeConfirmationDialog, },
            { label: 'Yes', onClick: this.onConfirm, }
        ];

        return (
            <>
                <Button
                    type="button"
                    style={ApplicationStyles.buttonLink}
                    onClick={this.showConfirmationDialog}
                    ripple={false}
                    label={text}
                />

                <Dialog
                    active={showDialog}
                    actions={dialogActions}
                    onEscKeyDown={this.closeConfirmationDialog}
                    onOverlayClick={this.closeConfirmationDialog}
                    title={dialogTitle}
                >
                    <p>{dialogText}</p>
                </Dialog>
            </>
        );
    }
}

export default SkipButton;
