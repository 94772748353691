// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faIdCard, faBullseye, faDoorClosed, faDoorOpen, faComments,
} from '@fortawesome/free-solid-svg-icons';

import type { Donor } from '../Utils/types';
import { calculateAgeFromDOB, distanceToNow } from '../Utils/time';
import styles from './Styles/DonorStyles';
import CaseTagDrawer from './CaseTagDrawer';
import Colors from '../Themes/Colors';

type Props = {
    donor: Donor,
    showDetailsButton: boolean,
    showTrackerButton: boolean,
    showCloseDonorButton: boolean,
    showChatroomButton: boolean,

    detailsClick: () => *,
    chatroomClick: () => *,
    updateDonorStatus: (donorId: number, closed: boolean, lastModified: string) => *,
};

class DonorHeader extends PureComponent<Props> {
    onTrackerClick = () => {
        // go to tracker
    };

    closeDonorHandler = () => {
        const {
            donor,
            updateDonorStatus,
        } = this.props;

        updateDonorStatus(donor.donorId, true, donor.lastModified);
    };

    openDonorHandler = () => {
        const {
            donor,
            updateDonorStatus,
        } = this.props;

        updateDonorStatus(donor.donorId, false, donor.lastModified);
    };

    renderCloseDonorButton = () => {
        const {
            donor,
        } = this.props;
        return !donor.closed
            ? (
                <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => {}}
                    onClick={() => this.closeDonorHandler()}
                    style={styles.donorHeaderButton}
                >
                    <FontAwesomeIcon
                        color={Colors.blue}
                        icon={faDoorClosed}
                        size="lg"
                    />
                    <span style={styles.donorHeaderButtonText}>Close</span>
                </div>
            )
            : (
                <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => {}}
                    onClick={() => this.openDonorHandler()}
                    style={styles.donorHeaderButton}
                >
                    <FontAwesomeIcon
                        color={Colors.blue}
                        icon={faDoorOpen}
                        size="lg"
                    />
                    <span style={styles.donorHeaderButtonText}>Open</span>
                </div>
            );
    };

    render() {
        const {
            donor,
            showDetailsButton,
            showTrackerButton,
            showCloseDonorButton,
            showChatroomButton,
            chatroomClick,
            detailsClick,
        } = this.props;

        const {
            opoDonorId,
            unosId,
            currentLocation,
            dob,
            sex,
            createDate,
            tags,
        } = donor;

        let shownID = '';
        if (opoDonorId) {
            shownID = opoDonorId;
        } else if (unosId) {
            shownID = unosId;
        }

        let sexPlaceholder;
        if (sex === 'Male') {
            sexPlaceholder = 'M';
        } else if (sex === 'Female') {
            sexPlaceholder = 'F';
        } else {
            sexPlaceholder = 'U';
        }

        const unosIdLabel = 'UNOS ID: ';
        const sexLabel = 'Sex: ';
        const hospitalLabel = 'Hospital: ';
        const ageLabel = 'Age: ';

        return (
            <div style={styles.donorHeaderWrapper}>
                <div style={styles.donorHeaderRow}>
                    <span style={styles.donorHeaderId}>
                        {shownID}
                    </span>
                </div>
                {unosId
                    ? (
                        <div style={styles.donorHeaderRow}>
                            <span style={styles.donorHeaderValue}>
                                <span style={styles.donorHeaderLabel}>{unosIdLabel}</span>
                                {unosId}
                            </span>
                        </div>
                    ) : null}
                {sex || dob
                    ? (
                        <div style={styles.donorHeaderRow}>
                            {sex
                                ? (
                                    <span style={styles.donorHeaderValue}>
                                        <span style={styles.donorHeaderLabel}>{sexLabel}</span>
                                        {sexPlaceholder}
                                    </span>
                                ) : null}
                            {sex && dob ? (<span style={{ whiteSpace: 'pre', }}> / </span>) : null}
                            {dob
                                ? (
                                    <span style={styles.donorHeaderValue}>
                                        <span style={styles.donorHeaderLabel}>{ageLabel}</span>
                                        {calculateAgeFromDOB(dob)}
                                    </span>
                                ) : null}
                        </div>
                    ) : null}
                {currentLocation
                    ? (
                        <div style={styles.donorHeaderRow}>
                            <span style={styles.donorHeaderValue}>
                                <span style={styles.donorHeaderLabel}>{hospitalLabel}</span>
                                {currentLocation}
                            </span>
                        </div>
                    ) : null}
                {tags
                    ? (
                        <div style={styles.tags.wrapper}>
                            <CaseTagDrawer
                                highRisk={false}
                                tags={tags}
                                entity="case"
                            />
                        </div>
                    ) : null}
                <div style={styles.donorCreatedWrapper}>
                    <span style={styles.donorCreatedText}>
                        {`Case created ${distanceToNow(createDate)} ago`}
                    </span>
                </div>
                <div style={styles.donorHeaderButtonsWrapper}>
                    {showDetailsButton
                        ? (
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => {}}
                                onClick={detailsClick}
                                style={styles.donorHeaderButton}
                            >
                                <FontAwesomeIcon
                                    color={Colors.blue}
                                    icon={faIdCard}
                                    size="lg"
                                />
                                <span style={styles.donorHeaderButtonText}>Details</span>
                            </div>
                        ) : null}
                    { showChatroomButton
                        ? (
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => {}}
                                onClick={chatroomClick}
                                style={styles.donorHeaderButton}
                            >
                                <FontAwesomeIcon
                                    color={Colors.blue}
                                    icon={faComments}
                                    size="lg"
                                />
                                <span style={styles.donorHeaderButtonText}>Rooms</span>
                            </div>
                        )
                        : null }
                    {showTrackerButton
                        ? (
                            <div
                                role="button"
                                tabIndex={0}
                                onClick={this.onTrackerClick}
                                onKeyPress={() => {}}
                                style={styles.donorHeaderButton}
                            >
                                <FontAwesomeIcon
                                    color={Colors.blue}
                                    icon={faBullseye}
                                    size="lg"
                                />
                                <span style={styles.donorHeaderButtonText}>Tracker</span>
                            </div>
                        ) : null}
                    { showCloseDonorButton ? this.renderCloseDonorButton() : null }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    formUpdated: state.forms.formUpdated,
    donorUpdated: state.donor.donorUpdated,
    currentDonorId: state.donor.openDonorId,
});

export default connect(mapStateToProps)(DonorHeader);
