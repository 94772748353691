// @flow
export const isObject = (value: *): boolean => Object.prototype.toString.call(value) === '[object Object]';

// Flow compatible alternative for Object.keys()
// https://github.com/facebook/flow/issues/2221#issuecomment-372112477
export const keys = <T>(obj: { [string]: T }): Array<string> => Object.keys(obj);
export const values = <T>(obj: { [string]: T }): Array<T> => Object.keys(obj).map(k => obj[k]); // eslint-disable-line arrow-parens

export const numberKeys = <T>(obj: { [string]: T }): Array<number> => { // eslint-disable-line arrow-parens
    const tempKeys = keys(obj);
    const numKeys = [];
    for (let i = 0; i < tempKeys.length; i += 1) {
        numKeys[i] = parseInt(tempKeys[i], 10);
    }
    return numKeys;
};
