// @flow
import type { Logout } from 'txp-core';

import type {
    AttachmentData, ChatroomMemberMap, ChatroomType, Offer, Organization, UserProfile, DonorRestrictions,
} from '../Utils/types';
import type { ResetData, ResetError } from './ApplicationActions';
import { resetErrorState, getReducerError } from './ApplicationActions';
import createUuid from '../Utils/createUuid';

export type ChatSaveError = {
    nonFieldError?: string,
    name?: string,
    unosId?: string,
    matchId?: string,
    organType?: string,
};

export type ChatEditState = {
    id: ?number,
    name: string,
    description: string,
    people: ChatroomMemberMap,
    pendingUsers: Array<UserProfile>,
    managed: boolean,

    mentionedPendingUsers: Array<number>,
    mentionedPendingNames: Array<string>,
    filteredPending: Array<string>,

    // Note: These are only used when creating a chat
    message: string,
    attachments: Array<AttachmentData>,
    researchOffer: ResearchOffer,

    // Note: These are only used when creating a case
    unosId: string,
    matchId: string,
    organType: string,

    // Note: These are only used when creating a detailed case
    donorId: number,
    organId: number,

    // Note: these are only used when creating a chatroom from a donor organ
    donorCreatingChat: boolean,
    opoDonorId: string,

    // NOTE: only used when creating a 'Donor Only' chatroom
    restrictions: DonorRestrictions,

    organizations: Array<Organization>,
    organization: Organization,
    isGetOrganizationsInFailState: boolean,

    offerCreated: boolean,
    offers: Array<Offer>,
    offer: Offer,

    surgeons: Array<UserProfile>,
    surgeon: UserProfile,

    fullOffer: any,

    chatroomType: ChatroomType,

    errors: ?ChatSaveError,

    createChatroomSuccess: boolean,
    reducerErrors: Array<any>,

};

export type ResearchOfferField = {
    value: string,
    displayName: string,
    helpText?: string,
};

export type ResearchOffer = {
    details: {
        age: ResearchOfferField,
        ageUnit: ResearchOfferField,
        bmi: ResearchOfferField,
        chemo: ResearchOfferField,
        cit: ResearchOfferField,
        consent: ResearchOfferField,
        donorType: ResearchOfferField,
        radiation: ResearchOfferField,
        wit: ResearchOfferField,
    },
    // NOTE We mnay add more flush types for different parts of the body.
    flush: {
        flush: ResearchOfferField,
    },
    serologies: {
        antiHTLV: ResearchOfferField,
        chagas: ResearchOfferField,
        chagasNat: ResearchOfferField,
        CMVIgM: ResearchOfferField,
        CMVIgG: ResearchOfferField,
        CMVTotal: ResearchOfferField,
        EBNA: ResearchOfferField,
        EBVIgM: ResearchOfferField,
        EBVIgG: ResearchOfferField,
        covid: ResearchOfferField,
        hcv: ResearchOfferField,
        hcvNat: ResearchOfferField,
        hiv: ResearchOfferField,
        hivAgAbCombo: ResearchOfferField,
        hivNat: ResearchOfferField,
        HBcAb: ResearchOfferField,
        HBsAb: ResearchOfferField,
        HBsAg: ResearchOfferField,
        hbvNat: ResearchOfferField,
        htlvNat: ResearchOfferField,
        leidenFactorV: ResearchOfferField,
        rpr: ResearchOfferField,
        strongyloides: ResearchOfferField,
        toxoIgG: ResearchOfferField,
        toxoIgM: ResearchOfferField,
        wnv: ResearchOfferField,
        wnvNat: ResearchOfferField,
    },
    liver: {
        alt: ResearchOfferField,
        ast: ResearchOfferField,
        cirrhosis: ResearchOfferField,
        fibrosis: ResearchOfferField,
        tbili: ResearchOfferField,
        trauma: ResearchOfferField,
    },
    heart: {
        cad: ResearchOfferField,
        qt_qtc: ResearchOfferField,
    },
    kidney: {
        creatinine: ResearchOfferField,
    },
    liverVisual: Array<string>,
    organs: Array<string>,
    backupOrgans: Array<string>,
}

const initialResearchOffer: ResearchOffer = {
    details: {
        consent: { value: '', displayName: 'Do you have consent to use the organ(s) for research?', },
        age: { value: '', displayName: 'Age', },
        ageUnit: { value: 'years', displayName: '', },
        bmi: { value: '', displayName: 'BMI', },
        donorType: { value: '', displayName: 'Donor Type', },
        wit: { value: '', displayName: 'WIT', },
        cit: { value: '', displayName: 'CIT', },
        chemo: { value: '', displayName: 'Chemotherapy', },
        radiation: { value: '', displayName: 'Radiation', },
    },
    flush: {
        flush: { value: '', displayName: 'Flush Type', },
    },
    serologies: {
        antiHTLV: { value: '', displayName: 'Anti-HTLV I/II', },
        chagas: { value: '', displayName: 'Chagas', },
        chagasNat: { value: '', displayName: 'Chagas NAT', },
        CMVTotal: { value: '', displayName: 'CMV Total', },
        CMVIgM: { value: '', displayName: 'CMV IgM', helpText: 'Select "No" if unknown and CMV Total is "No", otherwise answer as shown in the chart', },
        CMVIgG: { value: '', displayName: 'CMV IgG', helpText: 'Select "No" if unknown and CMV Total is "No", otherwise answer as shown in the chart', },
        EBNA: { value: '', displayName: 'EBNA', },
        EBVIgM: { value: '', displayName: 'EBV (VCA) IgM', },
        EBVIgG: { value: '', displayName: 'EBV (VCA) IgG', },
        covid: { value: '', displayName: 'COVID', },
        hcv: { value: '', displayName: 'Anti-HCV', },
        hcvNat: { value: '', displayName: 'HCV NAT', },
        hiv: { value: '', displayName: 'Anti-HIV I/II', },
        hivAgAbCombo: { value: '', displayName: 'HIV Ag/Ab Combo', },
        hivNat: { value: '', displayName: 'HIV NAT', },
        HBcAb: { value: '', displayName: 'Anti-HBc', },
        HBsAb: { value: '', displayName: 'HBsAb', helpText: 'Select "No" if unknown and Anti-HBc is "No", otherwise answer as shown in the chart', },
        HBsAg: { value: '', displayName: 'HBsAg', helpText: 'Select "No" if unknown and Anti-HBc is "No", otherwise answer as shown in the chart', },
        hbvNat: { value: '', displayName: 'HBV NAT', },
        htlvNat: { value: '', displayName: 'HTLV NAT', },
        leidenFactorV: { value: '', displayName: 'Leiden Factor V', },
        rpr: { value: '', displayName: 'Syphilis (RPR)', },
        strongyloides: { value: '', displayName: 'Strongyloides', },
        toxoIgG: { value: '', displayName: 'Toxo Ab IgG', },
        toxoIgM: { value: '', displayName: 'Toxo Ab IgM', },
        wnv: { value: '', displayName: 'WNV', },
        wnvNat: { value: '', displayName: 'WNV NAT', },
    },
    kidney: {
        creatinine: { value: '', displayName: 'Creatinine', },
    },
    liver: {
        ast: { value: '', displayName: 'AST', },
        alt: { value: '', displayName: 'ALT', },
        tbili: { value: '', displayName: 'TBILI', },
        fibrosis: { value: '', displayName: 'Fibrosis', },
        cirrhosis: { value: '', displayName: 'Cirrhosis', },
        trauma: { value: '', displayName: 'Trauma', },
    },
    heart: {
        qt_qtc: { value: '', displayName: 'QT/QTC', },
        cad: { value: '', displayName: 'CAD', },
    },
    liverVisual: [],
    organs: [],
    backupOrgans: [],
};

const initialState: ChatEditState = {
    id: null,
    name: '',
    people: {},
    pendingUsers: [],
    managed: false,

    message: '',
    attachments: [],
    researchOffer: JSON.parse(JSON.stringify(initialResearchOffer)),

    description: '',

    mentionedPendingUsers: [],
    mentionedPendingNames: [],
    filteredPending: [],

    unosId: '',
    matchId: '',
    organType: 'Kidney',

    donorId: 0,
    organId: 0,

    donorCreatingChat: false,
    opoDonorId: '',

    restrictions: {},

    organizations: [],
    organization: {
        id: 0,
        code: '',
        name: '',
        templatePreferences: null,
        availableTags: [],
    },
    isGetOrganizationsInFailState: false,

    offerCreated: false,
    offers: [],
    offer: {
        donorId: 0,
        unosId: '',
        organId: 0,
        matchId: '',
        organType: '',
    },

    surgeons: [],
    surgeon: {
        userId: 0,
        phone: '',
        firstName: null,
        lastName: null,
        email: '',
        isEmailVerified: false,
        emailUpdate: null,
        profilePicture: null,
        organizationalRole: null,
        department: null,
        organizationId: null,
        organizationName: '',
        verifiedOrgMember: false,
        templatePreferences: null,
        clientPermissions: '',
        communicationPreferences: null,
        emailNotification: false,
        chatroomSort: 'DEFAULT',
        chatroomFilter: { status: 'All', chatType: 'All', },
        donorSort: 'DEFAULT',
        donorFilter: { status: 'All', donorType: 'All', },
        globalDoNotDisturbEnd: '',
        accessLevel: null,
        license: null,
    },

    fullOffer: {},

    chatroomType: 'Standard',

    errors: null,

    createChatroomSuccess: false,
    reducerErrors: [],
};

export type StartEdit = {
    type: 'ChatEdit/START',
    id: ?number,
    name: string,
    people: ChatroomMemberMap,
    managed: boolean,
    message: string,
    description: ?string,
};

export type SaveChat = {
    type: 'ChatEdit/SAVE',
    id: ?number,
    name: string,
    people: Array<UserProfile>,
    managed: boolean,
    message: string,
    mentioned: Array<number>,
    description: string,
    attachments: Array<AttachmentData>,
    donorId: ?number,
    organId: ?number,
    transplantCenter: ?number,
    surgeon: ?UserProfile,
    chatroomType: ChatroomType,
    restrictions: ?DonorRestrictions,
};

export type SaveChatFailed = { type: 'ChatEdit/FAILED', errors: ChatSaveError };
export type SaveChatSuccess = { type: 'ChatEdit/SUCCESS', chatId: number };
export type SetName = { type: 'ChatEdit/NAME', name: string };
export type SetDescription = { type: 'ChatEdit/DESCRIPTION', description: string };
export type SetManaged = { type: 'ChatEdit/MANAGED', managed: boolean };
export type SetOPODonorId = { type: 'ChatEdit/OPO_DONOR_ID', opoDonorId: string };
export type SetDonorId = { type: 'ChatEdit/DONOR_ID', donorId: number };
export type SetOrganId = { type: 'ChatEdit/ORGAN_ID', organId: number };
export type SetDonorCreateChat = { type: 'ChatEdit/DONOR_CREATE_CHAT', donorCreating: boolean };
export type SetInitialMessage = { type: 'ChatEdit/INITIAL_MESSAGE', message: string, attachments: Array<AttachmentData> };
export type SetMentionedPendingUsers = { type: 'ChatEdit/MENTIONED_PENDING_USERS', mentionedPendingUsers: Array<number> };
export type SetMentionedPendingNames = { type: 'ChatEdit/MENTIONED_PENDING_NAMES', mentionedPendingNames: Array<string> };
export type SetFilteredPending = { type: 'ChatEdit/FILTER_PENDING', filteredPending: Array<string> };
export type SetUNOSId = { type: 'ChatEdit/UNOS_ID', unosId: string };
export type SetMatchId = { type: 'ChatEdit/MATCH_ID', matchId: string };
export type SetOrganType = { type: 'ChatEdit/ORGAN_TYPE', organType: string };
export type ResetChatEditState = { type: 'ChatEdit/RESET_EDIT_STATE' };
export type SetPendingUsers = { type: 'ChatEdit/SET_PENDING_USERS', pendingUsers: Array<UserProfile> };

export type CreateOffer = { type: 'ChatEdit/CREATE_OFFER', unosId: string, matchId: string, organType: string };
export type OfferCreated = { type: 'ChatEdit/OFFER_CREATED', donorId: number, organId: number };
export type GetOffers = { type: 'ChatEdit/GET_OFFERS' };
export type ReceiveOffers = { type: 'ChatEdit/RECEIVE_OFFERS', offers: Array<Offer> };
export type SelectOffer = { type: 'ChatEdit/SELECT_OFFER', offer: Offer };
export type GetOrganizations = { type: 'ChatEdit/GET_ORGANIZATIONS' };
export type ReceiveOrganizations = { type: 'ChatEdit/RECEIVE_ORGANIZATIONS', organizations: Array<Organization> };
export type GetOrganizationsFailed = { type: 'ChatEdit/GET_ORGANIZATIONS_FAILED' };
export type SelectOrganization = { type: 'ChatEdit/SELECT_ORGANIZATION', organization: Organization };
export type GetSurgeons = { type: 'ChatEdit/GET_SURGEONS' };
export type ReceiveSurgeons = { type: 'ChatEdit/RECEIVE_SURGEONS', surgeons: Array<UserProfile> };
export type SelectSurgeon = { type: 'ChatEdit/SELECT_SURGEON', surgeon: UserProfile };
export type GetOffer = { type: 'ChatEdit/GET_OFFER' };
export type ReceiveFullOffer = { type: 'ChatEdit/RECEIVE_FULL_OFFER', fullOffer: any };
export type SetChatroomType = { type: 'ChatEdit/SET_CHATROOM_TYPE', chatroomType: ChatroomType };
export type SetResearchOffer = { type: 'ChatEdit/SET_RESEARCH_OFFER', section: string | null, key: string, value: string};
export type SetRestrictions = { type: 'ChatEdit/SET_RESTRICTIONS', restrictions: DonorRestrictions };

type Action =
    | StartEdit
    | SaveChat
    | SaveChatFailed
    | SaveChatSuccess
    | SetName
    | SetDescription
    | SetManaged
    | SetInitialMessage
    | SetPendingUsers
    | SetMentionedPendingUsers
    | SetMentionedPendingNames
    | SetFilteredPending
    | SetUNOSId
    | SetMatchId
    | SetOrganType
    | ResetChatEditState
    | CreateOffer
    | OfferCreated
    | GetOffers
    | ReceiveOffers
    | SelectOffer
    | GetOrganizations
    | ReceiveOrganizations
    | GetOrganizationsFailed
    | SelectOrganization
    | GetSurgeons
    | ReceiveSurgeons
    | SelectSurgeon
    | GetOffer
    | ReceiveFullOffer
    | SetChatroomType
    | SetResearchOffer
    | ResetData
    | ResetError
    | Logout
    | SetOPODonorId
    | SetDonorCreateChat
    | SetDonorId
    | SetOrganId
    | SetRestrictions;

export const startEdit = (
    id: ?number,
    name: string,
    people: ChatroomMemberMap,
    managed: boolean,
    message: string,
    description: ?string
): StartEdit => ({
    type: 'ChatEdit/START',
    id,
    name,
    people,
    managed,
    message,
    description,
});

export const saveChat = (
    id: ?number,
    name: string,
    people: Array<UserProfile>,
    managed: boolean,
    message: string,
    mentioned: Array<number>,
    description: string,
    attachments: Array<AttachmentData>,
    donorId: ?number,
    organId: ?number,
    transplantCenter: ?number,
    surgeon: ?UserProfile,
    chatroomType: ChatroomType,
    restrictions: ?DonorRestrictions
): SaveChat => ({
    type: 'ChatEdit/SAVE',
    id,
    name,
    people,
    managed,
    message,
    mentioned,
    attachments,
    description,
    donorId,
    organId,
    transplantCenter,
    surgeon,
    chatroomType,
    restrictions,
});

export const saveChatFailed = (errors: ChatSaveError): SaveChatFailed => ({
    type: 'ChatEdit/FAILED',
    errors,
});

export const saveChatSuccess = (chatId: number): SaveChatSuccess => ({
    type: 'ChatEdit/SUCCESS',
    chatId,
});

export const setName = (name: string): SetName => ({
    type: 'ChatEdit/NAME',
    name,
});

export const setDescription = (description: string): SetDescription => ({
    type: 'ChatEdit/DESCRIPTION',
    description,
});

export const setManaged = (managed: boolean): SetManaged => ({
    type: 'ChatEdit/MANAGED',
    managed,
});

export const setDonorId = (donorId: number): SetDonorId => ({
    type: 'ChatEdit/DONOR_ID',
    donorId,
});

export const setOPODonorId = (opoDonorId: string): SetOPODonorId => ({
    type: 'ChatEdit/OPO_DONOR_ID',
    opoDonorId,
});

export const setDonorCreateChat = (donorCreating: boolean): SetDonorCreateChat => ({
    type: 'ChatEdit/DONOR_CREATE_CHAT',
    donorCreating,
});

export const setInitialMessage = (message: string, attachments: Array<AttachmentData> = []): SetInitialMessage => ({
    type: 'ChatEdit/INITIAL_MESSAGE',
    message,
    attachments,
});

export const setPendingUsers = (pendingUsers: Array<UserProfile>): SetPendingUsers => ({
    type: 'ChatEdit/SET_PENDING_USERS',
    pendingUsers,
});

export const setMentionedPendingUsers = (mentionedPendingUsers: Array<number>): SetMentionedPendingUsers => ({
    type: 'ChatEdit/MENTIONED_PENDING_USERS',
    mentionedPendingUsers,
});

export const setMentionedPendingNames = (mentionedPendingNames: Array<string>): SetMentionedPendingNames => ({
    type: 'ChatEdit/MENTIONED_PENDING_NAMES',
    mentionedPendingNames,
});

export const setFilteredPending = (filteredPending: Array<string>): SetFilteredPending => ({
    type: 'ChatEdit/FILTER_PENDING',
    filteredPending,
});

export const setUNOSId = (unosId: string): SetUNOSId => ({
    type: 'ChatEdit/UNOS_ID',
    unosId,
});

export const setMatchId = (matchId: string): SetMatchId => ({
    type: 'ChatEdit/MATCH_ID',
    matchId,
});

export const setOrganType = (organType: string): SetOrganType => ({
    type: 'ChatEdit/ORGAN_TYPE',
    organType,
});

export const setOrganId = (organId: number): SetOrganId => ({
    type: 'ChatEdit/ORGAN_ID',
    organId,
});

export const resetChatEditState = () => ({
    type: 'ChatEdit/RESET_EDIT_STATE',
});

export const createOffer = (unosId: string, matchId: string, organType: string): CreateOffer => ({
    type: 'ChatEdit/CREATE_OFFER',
    unosId,
    matchId,
    organType,
});

export const offerCreated = (donorId: number, organId: number): OfferCreated => ({
    type: 'ChatEdit/OFFER_CREATED',
    donorId,
    organId,
});

export const getOffers = (): GetOffers => ({
    type: 'ChatEdit/GET_OFFERS',
});

export const receiveOffers = (offers: Array<Offer>): ReceiveOffers => ({
    type: 'ChatEdit/RECEIVE_OFFERS',
    offers,
});

export const selectOffer = (offer: Offer): SelectOffer => ({
    type: 'ChatEdit/SELECT_OFFER',
    offer,
});

export const getOrganizations = (): GetOrganizations => ({
    type: 'ChatEdit/GET_ORGANIZATIONS',
});

export const receiveOrganizations = (organizations: Array<Organization>): ReceiveOrganizations => ({
    type: 'ChatEdit/RECEIVE_ORGANIZATIONS',
    organizations,
});

export const getOrganizationsFailed = (): GetOrganizationsFailed => ({
    type: 'ChatEdit/GET_ORGANIZATIONS_FAILED',
});

export const selectOrganization = (organization: Organization): SelectOrganization => ({
    type: 'ChatEdit/SELECT_ORGANIZATION',
    organization,
});

export const getSurgeons = (): GetSurgeons => ({
    type: 'ChatEdit/GET_SURGEONS',
});

export const receiveSurgeons = (surgeons: Array<UserProfile>): ReceiveSurgeons => ({
    type: 'ChatEdit/RECEIVE_SURGEONS',
    surgeons,
});

export const selectSurgeon = (surgeon: UserProfile): SelectSurgeon => ({
    type: 'ChatEdit/SELECT_SURGEON',
    surgeon,
});

export const getOffer = (): GetOffer => ({
    type: 'ChatEdit/GET_OFFER',
});

export const receiveFullOffer = (fullOffer: any): ReceiveFullOffer => ({
    type: 'ChatEdit/RECEIVE_FULL_OFFER',
    fullOffer,
});

export const setChatroomType = (chatroomType: ChatroomType): SetChatroomType => ({
    type: 'ChatEdit/SET_CHATROOM_TYPE',
    chatroomType,
});

export const setResearchOffer = (section: string | null, key: string, value: any): SetResearchOffer => ({
    type: 'ChatEdit/SET_RESEARCH_OFFER',
    section,
    key,
    value,
});

export const setRestrictions = (restrictions: DonorRestrictions): SetRestrictions => ({
    type: 'ChatEdit/SET_RESTRICTIONS',
    restrictions,
});

const reducer = (state: ChatEditState = initialState, action: Action): ChatEditState => {
    // wrap the whole reducer to catch any unexpected exceptions and record them
    // Another component will need to act upon these and potentially send them to Sentry
    try {
        return innerReducer(state, action);
    } catch (err) {
        const reducerErrors = state.reducerErrors.slice();
        const uuid = createUuid(0);
        reducerErrors.push(getReducerError(uuid, 'chatEdit', action, err));
        return {
            ...state,

            reducerErrors,
        };
    }
};

const innerReducer = (state: ChatEditState = initialState, action: Action): ChatEditState => {
    switch (action.type) {
        case 'Application/RESET_ERROR':
            return resetErrorState(state, action);

        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            // Reset the store when logging out
            return {
                ...initialState,
            };

        case 'ChatEdit/START':
            return {
                ...state,

                id: action.id,
                name: action.name,
                description: action.description || '',
                people: action.people,
                message: action.message,
                errors: null,
                attachments: action.id === null ? [] : state.attachments,
            };

        case 'ChatEdit/NAME':
            return {
                ...state,

                name: action.name,
            };

        case 'ChatEdit/DESCRIPTION':
            return {
                ...state,

                description: action.description,
            };

        case 'ChatEdit/MANAGED':
            return {
                ...state,

                managed: action.managed,
            };

        case 'ChatEdit/OPO_DONOR_ID':
            return {
                ...state,

                opoDonorId: action.opoDonorId,
            };

        case 'ChatEdit/DONOR_CREATE_CHAT':
            return {
                ...state,

                donorCreatingChat: action.donorCreating,
            };

        case 'ChatEdit/INITIAL_MESSAGE':
            return {
                ...state,

                message: action.message,
                attachments: action.attachments || [],
            };

        case 'ChatEdit/SET_PENDING_USERS':
            return {
                ...state,
                pendingUsers: action.pendingUsers.slice(),
            };

        case 'ChatEdit/MENTIONED_PENDING_USERS':
            return {
                ...state,

                mentionedPendingUsers: action.mentionedPendingUsers,
            };

        case 'ChatEdit/MENTIONED_PENDING_NAMES':
            return {
                ...state,

                mentionedPendingNames: action.mentionedPendingNames,
            };

        case 'ChatEdit/FILTER_PENDING':
            return {
                ...state,

                filteredPending: action.filteredPending,
            };

        case 'ChatEdit/UNOS_ID':
            return {
                ...state,

                unosId: action.unosId,
            };

        case 'ChatEdit/ORGAN_ID':
            return {
                ...state,

                organId: action.organId,
            };

        case 'ChatEdit/DONOR_ID':
            return {
                ...state,

                donorId: action.donorId,
            };

        case 'ChatEdit/MATCH_ID':
            return {
                ...state,

                matchId: action.matchId,
            };

        case 'ChatEdit/ORGAN_TYPE':
            return {
                ...state,

                organType: action.organType,
            };

        case 'ChatEdit/RECEIVE_OFFERS':
            return {
                ...state,

                offers: action.offers,
            };

        case 'ChatEdit/RECEIVE_ORGANIZATIONS':
            return {
                ...state,

                organizations: action.organizations,
                isGetOrganizationsInFailState: false,
            };

        case 'ChatEdit/GET_ORGANIZATIONS_FAILED':
            return {
                ...state,

                isGetOrganizationsInFailState: true,
            };

        case 'ChatEdit/RECEIVE_SURGEONS':
            return {
                ...state,

                surgeons: action.surgeons,
            };

        case 'ChatEdit/OFFER_CREATED':
            return {
                ...state,

                offerCreated: state.chatroomType === 'Templated Case',
                donorId: state.chatroomType === 'Case' ? action.donorId : state.donorId,
                organId: state.chatroomType === 'Case' ? action.organId : state.organId,
            };

        case 'ChatEdit/GET_OFFERS':
            return {
                ...state,

                offerCreated: false,
            };

        case 'ChatEdit/SELECT_OFFER':
            return {
                ...state,

                offer: action.offer,
            };

        case 'ChatEdit/SELECT_ORGANIZATION':
            return {
                ...state,

                organization: action.organization,
            };

        case 'ChatEdit/SELECT_SURGEON':
            return {
                ...state,

                surgeon: action.surgeon,
            };

        case 'ChatEdit/RECEIVE_FULL_OFFER':
            return {
                ...state,

                fullOffer: action.fullOffer,
            };

        case 'ChatEdit/FAILED':
            return {
                ...state,

                errors: action.errors,
            };

        case 'ChatEdit/SUCCESS':
            return {
                ...state,

                errors: null,
                createChatroomSuccess: true,
            };

        case 'ChatEdit/SET_CHATROOM_TYPE':
            return {
                ...state,

                chatroomType: action.chatroomType,
            };

        case 'ChatEdit/SET_RESEARCH_OFFER':
            // If there's a section to the action, update that section
            if (action.section) {
                return {
                    ...state,

                    researchOffer: {
                        ...state.researchOffer,

                        [action.section]: {
                            ...state.researchOffer[action.section],

                            [action.key]: {
                                ...state.researchOffer[action.section][action.key],

                                value: action.value,
                            },
                        },
                    },
                };
            }
            // If there's no section, the property should be in the first level of the researchOffer
            return {
                ...state,

                researchOffer: {
                    ...state.researchOffer,

                    [action.key]: action.value,
                },
            };

        case 'ChatEdit/SET_RESTRICTIONS':
            return {
                ...state,

                restrictions: action.restrictions,
            };

        case 'ChatEdit/RESET_EDIT_STATE': {
            // Returning ...initialState wasn't resetting attachments, mentionedPendingUsers, and mentionedPendingNames for some reason.
            // Not ideal to explicitly set everything, but it works!
            const resetResearchOffer = JSON.parse(JSON.stringify(initialResearchOffer));
            return {
                id: null,
                name: '',
                people: {},
                pendingUsers: [],
                managed: false,

                message: '',
                attachments: [],
                researchOffer: resetResearchOffer,

                description: '',

                mentionedPendingUsers: [],
                mentionedPendingNames: [],
                filteredPending: [],

                unosId: '',
                matchId: '',
                organType: 'Kidney',

                donorId: 0,
                organId: 0,

                opoDonorId: '',
                donorCreatingChat: false,

                restrictions: {},

                organizations: [],
                organization: {
                    id: 0,
                    code: '',
                    name: '',
                    templatePreferences: null,
                    availableTags: [],
                },
                isGetOrganizationsInFailState: false,

                offerCreated: false,
                offers: [],
                offer: {
                    donorId: 0,
                    unosId: '',
                    organId: 0,
                    matchId: '',
                    organType: '',
                },

                surgeons: [],
                surgeon: {
                    userId: 0,
                    phone: '',
                    firstName: null,
                    lastName: null,
                    email: '',
                    isEmailVerified: false,
                    emailUpdate: null,
                    profilePicture: null,
                    organizationalRole: null,
                    department: null,
                    organizationId: null,
                    organizationName: '',
                    verifiedOrgMember: false,
                    templatePreferences: null,
                    clientPermissions: '',
                    communicationPreferences: null,
                    emailNotification: false,
                    chatroomSort: 'DEFAULT',
                    chatroomFilter: { status: 'All', chatType: 'All', },
                    donorSort: 'DEFAULT',
                    donorFilter: { status: 'All', donorType: 'All', },
                    globalDoNotDisturbEnd: '',
                    accessLevel: null,
                    license: null,
                },

                fullOffer: {},

                chatroomType: 'Standard',

                errors: null,

                createChatroomSuccess: false,
                createDonorSuccess: false,
                reducerErrors: [],
            };
        }

        default:
            return state;
    }
};

export default reducer;
