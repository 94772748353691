// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { Redirect } from 'react-router-dom';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import Divider from '@mui/material/Divider';
import { faLightbulb, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getApplicationData as _getApplicationData } from '../Redux/ApplicationActions';
import Images from '../Themes/Images';
import Colors from '../Themes/Colors';
import ErrorStyles from './Styles/ErrorStyles';

type Props = {
    children: any,
    activeChatId: number,

    getApplicationData: (
        getOrganizations: boolean,
        getChatrooms: boolean,
        getChatroomMembers: boolean,
        getInstantMessages: boolean,
        chatroomIds: Array<number>,
    ) => *,
};

type State = {
    hasError: boolean,
    toChat: boolean,
};

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            toChat: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true, };
    }

    componentDidCatch(error: any, info: any) {
        Sentry.captureException(JSON.stringify({
            error,
            info,
        }));
    }

    render() {
        const {
            getApplicationData,
            activeChatId,
        } = this.props;

        const {
            hasError,
            toChat,
        } = this.state;

        if (hasError) {
            if (toChat) {
                this.setState({
                    hasError: false,
                    toChat: false,
                });

                if (activeChatId) {
                    getApplicationData(true, true, true, true, [activeChatId]);
                } else {
                    getApplicationData(true, true, false, false, []);
                }

                return <Redirect exact to="/login" />;
            }

            // NOTE: Any changes here need to be inline with official OmniLife support policies
            // and should be mirrored in the ErrorBoundary handler for both TXP-Admin-Portal and TXPChat
            return (
                <div style={ErrorStyles.verticalCenter}>
                    <div className="error-page-container">
                        <div style={ErrorStyles.errorHeader}>
                            <img style={ErrorStyles.errorHeaderImg} src={Images.logoWhite} alt="" />
                            Something unexpected occurred
                        </div>
                        <div style={ErrorStyles.outerWrapper}>
                            <div style={ErrorStyles.innerWrapper}>
                                <div style={ErrorStyles.errorTitle}>What you can do next</div>
                                <MenuDivider />
                                <div className="error-page-content">
                                    <div className="error-page-message" style={ErrorStyles.errorMessage}>
                                        <p style={ErrorStyles.errorMessageFirstLine}>
                                            Please check your network connection for stability and sufficient speed.
                                            You can reload the application with the button on this page, or try clearing
                                            your cache and recent browsing history before refreshing your browser.
                                        </p>
                                        <p>
                                            If the problem persists, please contact our support team directly through our official support channels,
                                            found on our homepage. Thank you for your continued support and loyalty!
                                        </p>
                                    </div>
                                    <Divider orientation="vertical" color={Colors.evenLighterGray} flexItem />
                                    <div className="error-buttons-grid">
                                        <a
                                            style={ErrorStyles.navButton}
                                            href="/chat"
                                            onClick={() => window.location.reload()}
                                        >
                                            <img style={ErrorStyles.icon} src={Images.logoSvg} alt="" />
                                            <span style={ErrorStyles.buttonText}>
                                                &#x21bb; Reload the platform
                                            </span>
                                        </a>
                                        <a
                                            style={ErrorStyles.navButton}
                                            href={process.env.REACT_APP_LAUNCH_FAQ_URL}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                style={ErrorStyles.icon}
                                                icon={faLightbulb}
                                                color={Colors.darkGrayLogo}
                                            />
                                            <span style={ErrorStyles.buttonText}>
                                                Visit our FAQ page &gt;
                                            </span>
                                        </a>
                                        <a
                                            style={ErrorStyles.navButton}
                                            href="mailto:success@omnilife.health"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                style={ErrorStyles.icon}
                                                icon={faEnvelope}
                                                color={Colors.darkGrayLogo}
                                            />
                                            <span style={ErrorStyles.buttonText}>
                                                Email&nbsp;success@omnilife.health
                                            </span>
                                        </a>
                                        <a
                                            style={ErrorStyles.navButton}
                                            href="tel:18003196185"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                style={ErrorStyles.icon}
                                                icon={faPhone}
                                                color={Colors.darkGrayLogo}
                                            />
                                            <span style={ErrorStyles.buttonText}>
                                                Call&nbsp;1-800-319-6185
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    activeChatId: state.chatList.activeChatId,
});

export default connect(mapStateToProps, {
    getApplicationData: _getApplicationData,
})(ErrorBoundary);
