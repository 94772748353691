// @flow

export const sexes = [
    {
        label: 'Male',
        value: 'Male',
    },
    {
        label: 'Female',
        value: 'Female',
    },
    {
        label: 'Unknown',
        value: 'Unknown',
    }
];
