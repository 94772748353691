/* eslint-disable global-require */
// Disabling the global require rule for this file since we have can't modify the names of the sound files.
export const sounds = {
    'a_tone_his_self_1266414414.wav': require('../Sounds/a_tone_his_self_1266414414.wav').default,
    'appear_kp_1137861048.wav': require('../Sounds/appear_kp_1137861048.wav').default,
    'bell_ringing_05.wav': require('../Sounds/bell_ringing_05.wav').default,
    'computer_magic_microsift_1901299923.wav': require('../Sounds/computer_magic_microsift_1901299923.wav').default,
    'computer_start_up_1280862923.wav': require('../Sounds/computer_start_up_1280862923.wav').default,
    'cow_and_bell_1243222141.wav': require('../Sounds/cow_and_bell_1243222141.wav').default,
    'cuckoo_clock_1776874523.wav': require('../Sounds/cuckoo_clock_1776874523.wav').default,
    'dream_harp_06.wav': require('../Sounds/dream_harp_06.wav').default,
    'electrical_sweep_sweeper_1760111493.wav': require('../Sounds/electrical_sweep_sweeper_1760111493.wav').default,
    'electronic_chime_kevangc_495939803.wav': require('../Sounds/electronic_chime_kevangc_495939803.wav').default,
    'fire_pager_jason_1283464858.wav': require('../Sounds/fire_pager_jason_1283464858.wav').default,
    'hermit_thrush_912282438.wav': require('../Sounds/hermit_thrush_912282438.wav').default,
    'imac_startup_chime_carlo__1849294605.wav': require('../Sounds/imac_startup_chime_carlo__1849294605.wav').default,
    'magic_chime_01.wav': require('../Sounds/magic_chime_01.wav').default,
    'mgf_99_ajvhs_20.wav': require('../Sounds/mgf_99_ajvhs_20.wav').default,
    'mgf_99_eglitch_17.wav': require('../Sounds/mgf_99_eglitch_17.wav').default,
    'music_box_big_daddy_1389738694.wav': require('../Sounds/music_box_big_daddy_1389738694.wav').default,
    'open_soda_can_kp_1219969174.wav': require('../Sounds/open_soda_can_kp_1219969174.wav').default,
    'pling_kevangc_1485374730.wav': require('../Sounds/pling_kevangc_1485374730.wav').default,
    'power_up_kp_1879176533.wav': require('../Sounds/power_up_kp_1879176533.wav').default,
    'stapler_374581609.wav': require('../Sounds/stapler_374581609.wav').default,
    'telephone_ring_03a.wav': require('../Sounds/telephone_ring_03a.wav').default,
    'tinkle_lisa_redfern_1916445296.wav': require('../Sounds/tinkle_lisa_redfern_1916445296.wav').default,
    'tx81z_papal_bell_c1.wav': require('../Sounds/tx81z_papal_bell_c1.wav').default,
    'tx81z_papal_bell_c5.wav': require('../Sounds/tx81z_papal_bell_c5.wav').default,
    'ufo_landing_1015457638.wav': require('../Sounds/ufo_landing_1015457638.wav').default,
};

export const NotificationSoundMap = Object.freeze({
    default: 'Default',
    'a_tone_his_self_1266414414.wav': 'A Tone',
    'appear_kp_1137861048.wav': 'Appear',
    'tx81z_papal_bell_c1.wav': 'Bell 1',
    'tx81z_papal_bell_c5.wav': 'Bell 2',
    'electronic_chime_kevangc_495939803.wav': 'Chime',
    'cow_and_bell_1243222141.wav': 'Cow and Bell',
    'cuckoo_clock_1776874523.wav': 'Cuckoo Clock',
    'mgf_99_eglitch_17.wav': 'Ding',
    'dream_harp_06.wav': 'Dream Harp',
    'fire_pager_jason_1283464858.wav': 'Fire Pager',
    'hermit_thrush_912282438.wav': 'Hermit Thrush',
    'computer_magic_microsift_1901299923.wav': 'Magic',
    'magic_chime_01.wav': 'Magic Chime',
    'music_box_big_daddy_1389738694.wav': 'Music Box',
    'open_soda_can_kp_1219969174.wav': 'Open Soda Can',
    'pling_kevangc_1485374730.wav': 'Pling',
    'mgf_99_ajvhs_20.wav': 'Power Down',
    'power_up_kp_1879176533.wav': 'Power Up',
    'bell_ringing_05.wav': 'Ringing',
    'stapler_374581609.wav': 'Stapler',
    'computer_start_up_1280862923.wav': 'Start Up 1',
    'imac_startup_chime_carlo__1849294605.wav': 'Start Up 2',
    'electrical_sweep_sweeper_1760111493.wav': 'Sweep',
    'telephone_ring_03a.wav': 'Telephone Ring',
    'tinkle_lisa_redfern_1916445296.wav': 'Twinkle',
    'ufo_landing_1015457638.wav': 'UFO Landing',
});
