// @flow
import React, { PureComponent } from 'react';
import LoadingIndicator from 'react-loading-indicator';
import ReactTooltip from 'react-tooltip';

import ChatListStyles from './Styles/ChatListStyles';
import TeamListItem from './TeamListItem';
import type {
    Team,
} from '../Utils/types';

type Props = {
    selectedTeamId: ?number,
    teamsLoading: boolean,
    teams: Array<Team>,
    onTeamSelect: (teamId: number) => *,
};

class TeamList extends PureComponent<Props> {
    renderTeams = () => {
        const {
            selectedTeamId,
            teams,
            onTeamSelect,
        } = this.props;
        //  Sort based off teamName
        teams.sort((a, b) => a.teamName.localeCompare(b.teamName));
        return (
            <div>
                {teams.map((team) => (
                    <TeamListItem
                        key={team.teamId}
                        team={team}
                        selected={selectedTeamId != null && selectedTeamId === team.teamId}
                        onClick={onTeamSelect}
                    />
                ))}
            </div>
        );
    }

    render() {
        const {
            teamsLoading,
        } = this.props;

        return (
            <div style={{ ...ChatListStyles.wrapper, ...ChatListStyles.borderRight, }}>
                {teamsLoading
                    ? (
                        <div style={ChatListStyles.loadingIndicator}>
                            <LoadingIndicator />
                        </div>
                    ) : (
                        <div>
                            <ReactTooltip />
                            {this.renderTeams()}
                        </div>
                    )}
            </div>
        );
    }
}

export default TeamList;
