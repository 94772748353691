// @flow
import Colors from '../../Themes/Colors';

const heightPadding = 100;

const MediaStyles = {
    closeButtonWrapper: {
        float: 'right',
        display: 'flex',
        alignItems: 'center',
    },
    closeButton: {
        border: 'none',
        backgroundColor: Colors.white,
        textAlign: 'center',
        cursor: 'pointer',
    },
    mediaWrapper: {
        height: window.innerHeight - heightPadding,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    displayMedia: {
        objectFit: 'contain',
        maxHeight: window.innerHeight - heightPadding,
        width: '100%',
    },
    displayApplicationMedia: {
        height: '100%',
        width: '100%',
        border: 'none',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    hideMedia: {
        objectFit: 'contain',
        maxHeight: window.innerHeight - heightPadding,
        width: '100%',
        display: 'none',
    },
    uploadMediaWrapper: {
        backgroundColor: Colors.lighterGray,
        border: `1px solid ${Colors.lightGray}`,
        borderRadius: 4,
        padding: 5,
        marginBottom: 10,
        marginTop: 20,
    },
    uploadMediaTitle: {
        color: '#404040',
        fontSize: 20,
        lineHeight: 2,
    },
    uploadMedia: {
        maxWidth: '50%',
        height: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    uploadApplicationMedia: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        border: 'none',
    },
};

export default MediaStyles;
