// @flow

/* eslint-disable no-template-curly-in-string */
import Router, { Resource, parseErrors } from 'tg-resources';
import { useSelector } from 'react-redux';

import isString from '../Utils/isString';

const api = new Router({
    txp: new Router({
        // API(s) that do not need access token
        chatrooms: new Resource('/chatrooms/for/me/v2'),
        chatroomMedia: new Resource('/chatrooms/${chatId}/media'),
        chatroomMembers: new Resource('/chatrooms/${chatId}/members'),
        chatroomMessages: new Resource('/chatrooms/${chatId}/messages/${limit}/${offset}'),
        chatroomMessageStatuses: new Resource('/chatrooms/${chatId}/messages/${messageIdList}/statuses/all'),
        updateChatroomMember: new Resource('/chatrooms/${chatId}/members/me'),

        search: new Resource('/chatrooms/messages/search'),

        getOrganizationMembers: new Resource('/organizations/${orgId}/members'),
        getContacts: new Resource('/user/contacts'),
        inviteNewUser: new Resource('/user/invite'),

        saveDeviceToken: new Resource('/user/devices/insert'),

        createActivationCode: new Resource('/auth/application/activation/request'),

        createOffer: new Resource('/offer/donor'),
        getOffers: new Resource('/offer/organization/donors'),
        getOffer: new Resource('/offer/donor/${donorId}/organ/${organId}'),
        getOrganizations: new Resource('/organization/all'),
        cases: new Resource('/cases'),
        tagFilters: new Resource('/cases/tags'),
        getDonor: new Resource('/donor/${donorId}'),
        updateDonorStatus: new Resource('/donor/${donorId}/status'),
        createCase: new Resource('/case'),
        updateDonor: new Resource('/donor/${donorId}'),
        donorFiles: new Resource('/donor/${donorId}/files'),
        uploadDonorFile: new Resource('/donor/${donorId}/file/${fileName}'),
        getPdfFields: new Resource('/donor/pdf_fields'),
        tasks: new Resource('/donors/tasks/${workflow}'),
        donorTask: new Resource('/donor/${donorId}/task/${taskId}'),
        updateNote: new Resource('/donor/${donorId}/task/${taskId}/note'),
        donorTasks: new Resource('/donor/${donorId}/tasks'),
        updateStatus: new Resource('/donor/${donorId}/task/${taskId}/status'),
        updateDonorTaskData: new Resource('/donor/${donorId}/task/${taskId}/data'),
        updateDonorTaskDueDate: new Resource('/donor/${donorId}/task/${taskId}/due'),
        donorFollowers: new Resource('/donor/${donorId}/followers'),
        caseFollowersDetailed: new Resource('/case/${caseId}/followers_detailed'),

        createCaseNote: new Resource('/case/${caseId}/notes'),

        follower: new Resource('/follower/${followerId}'),
        createFollowerGroup: new Resource('/follower'),
        updateFollowerName: new Resource('/follower/${followerId}/name'),
        updateFollowerTasks: new Resource('/follower/${followerId}/tasks'),
        updateFollowerUsers: new Resource('/follower/${followerId}/users'),
        updateFollowerPermissions: new Resource('/follower/${followerId}/permission'),
        updateFollowerRemoveUser: new Resource('/follower/${followerId}/users/${userId}'),

        notifiableEventGroup: new Resource('/notifications/group/${notifiableGroupId}'),

        addOffer: new Resource('/cds/offer'),
        addCandidates: new Resource('/cds/candidates'),

        permissions: new Resource('/permissions'),
        resourcePermissions: new Resource('/permissions/${entityType}/${entityId}'),
        addPermission: new Resource('/permission/v2'),
        updatePermission: new Resource('/permission/${permissionId}/v2'),

        workflowOptions: new Resource('/references/workflow/options'),
        workflowDefinition: new Resource('/reference/workflow/${key}/definition'),
        domainData: new Resource('/references/data/${domain}'),

        teams: new Resource('/teams'),
        team: new Resource('/team/${teamId}'),
        createTeam: new Resource('/team/v2'),
        updateTeam: new Resource('/team/${teamId}/v2'),

        users: new Resource('/users'),
    }, {
        apiRoot: (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, ''),
        parseErrors: (errorText, parentConfig) => {
            let error = null;

            if (isString(errorText)) {
                if (errorText) {
                    try {
                        error = JSON.parse(errorText);
                    } catch (e) {
                        // if json parsing fails, handle as text
                        error = errorText;
                    }
                } else {
                    error = '';
                }
            } else {
                error = errorText;
            }

            if (error && error.request_error) {
                const preparedError = {};

                (error.request_error || []).forEach((fieldError) => {
                    preparedError[fieldError.parameter_name] = preparedError[fieldError.parameter_name] || [];
                    (fieldError.violated_constraints || []).forEach((msg) => {
                        preparedError[fieldError.parameter_name].push(msg);
                    });
                });
                return parseErrors(preparedError, parentConfig);
            }
            if (error && error.error) {
                ({ error, } = error);

                // Hackish solution to be able to handle recovery endpoint errors
                //  the errors for it are a json string with prepended statuscode
                if (isString(error) && (error.startsWith('403 - {') || error.startsWith('400 - {'))) {
                    try {
                        error = JSON.parse(error.substring(6));

                        if (error && error.errorSummary) {
                            error = error.errorSummary;
                        }
                    } catch (er) {
                        // nothing to do
                    }
                }

                error = {
                    non_field_errors: error,
                };
            }

            return parseErrors(error, parentConfig);
        },
    }),
}, {
    headers: () => ({
        Accept: 'application/json',
        Apikey: process.env.REACT_APP_API_KEY || '',
    }),
    withCredentials: true,
});

type KwArgs = { [key: string]: mixed };
type Query = { [key: string]: mixed } | string;
type Data = { [key: string]: mixed } | string;
type Attachments = File[];
type SimpleRequest = {
    kwargs?: KwArgs;
    query?: Query;
};
type FetchRequest = SimpleRequest & {
    method?: "fetch";
};
type OptionsRequest = SimpleRequest & {
    method: "options";
};
type HeadRequest = SimpleRequest & {
    method: "head";
};
type BasePostRequest = {
    kwargs?: KwArgs;
    query?: Query;
    data?: Data;
    attachments?: Attachments;
}
type PostRequest = BasePostRequest & {
    method: "post";
};
type PutRequest = BasePostRequest & {
    method: "put";
};
type PatchRequest = BasePostRequest & {
    method: "patch";
};
type DeleteRequest = BasePostRequest & {
    method: "del";
};

type ResourceRequest = FetchRequest | OptionsRequest | HeadRequest | PostRequest | PutRequest | PatchRequest | DeleteRequest;

export function useApiFetch() {
    const token = useSelector((state) => state.auth.accessToken);

    return buildResourceFetch(token);
}

export function buildResourceFetch(token?: string) {
    return (resource: typeof Resource, {
        method = 'fetch',
        kwargs = null,
        query = null,
    }: ResourceRequest = {}) => {
        const headers: { [key: string]: mixed } = { Accept: 'application/json', };

        if (token && resource.config().withCredentials) {
            headers.Authorization = `Bearer ${token}`;
        }

        const requestConfig = { headers, };

        return resource[method](kwargs, query, requestConfig);
    };
}

export default api;
