// @flow
import Colors from '../../Themes/Colors';

const DonorStyles = {
    donorHeaderWrapper: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 15,
        marginBottom: 15,
    },
    donorHeaderRow: {
        display: 'flex',
    },
    donorHeaderId: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.black,
        marginBottom: '0.5em',
    },
    donorHeaderValue: {
        fontSize: 14,
        color: Colors.black,
    },
    donorHeaderLabel: {
        fontWeight: 'bold',
    },
    donorCreatedWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 5,
        marginBottom: 5,
    },
    donorCreatedText: {
        fontSize: 14,
        color: Colors.formLabel,
        fontStyle: 'italic',
    },
    donorHeaderButtonsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    donorHeaderButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    donorHeaderButtonText: {
        fontSize: 12,
        color: Colors.blue,
    },
    donorCardWrapper: {
        padding: 10,
        margin: 10,
        cursor: 'pointer',
        borderColor: Colors.chatListItemBorder,
        borderStyle: 'solid',
        borderWidth: 2,
    },
    donorTaskCard: {
        bottomPadding: 10,
    },
    donorCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    taskItemWrapper: {
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
    },
    bullseyeWrapper: {
        paddingTop: 5,
    },
    taskItemRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingRight: 10,
    },
    taskItemInnerRow: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '20px',
    },
    taskItemButton: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '20px',
    },
    taskItemTopRow: {
        display: 'flex',
        paddingTop: 5,
        alignItems: 'center',

    },
    taskItemDueDate: {
        paddingTop: 10,
        paddingBottom: 3,
        marginLeft: 'auto',
        color: Colors.blue,
        fontSize: 12,
        cursor: 'pointer',
    },
    iconPadding: {
        paddingLeft: 10,
    },
    taskDataWrapper: {
        marginLeft: 45,
    },
    taskDataText: {
        color: Colors.formLabel,
        fontSize: 12,
        lineHeight: 1.5,
    },
    detailsWrapper: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 15,
        marginBottom: 15,
    },
    detailsTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    detailsTitleText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: Colors.black,
    },
    detailsSubtitleText: {
        fontSize: 16,
        color: Colors.formLabel,
    },
    detailsInput: {
        marginTop: 10,
        marginBottom: 10,
    },
    detailsInputLabel: {
        marginTop: 10,
        marginBottom: 10,
        color: Colors.formLabel,
        fontSize: 14,
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
    },
    headerButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 12,
        paddingRight: 12,
    },
    saveButtonDisabled: {
        backgroundColor: Colors.white,
        color: Colors.linkTextDisabled,
        textTransform: 'none',
        fontSize: 16,
    },
    saveButton: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 16,
    },
    notifyButton: {
        backgroundColor: Colors.blue,
        color: Colors.white,
        textTransform: 'none',
        fontSize: 16,
        paddingLeft: 16,
        paddingRight: 16,
    },
    notifyButtonDisabled: {
        backgroundColor: Colors.buttonBackgroundDisabled,
        color: Colors.buttonTextDisabled,
        textTransform: 'none',
        fontSize: 16,
        paddingLeft: 16,
        paddingRight: 16,
    },
    historyView: {
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    textAlign: {
        textAlign: 'left',
    },
    historyText: {
        fontSize: 14,
        color: Colors.black,
    },
    historyName: {
        fontSize: 14,
        color: Colors.brandSecondary,
    },
    historyDate: {
        fontSize: 14,
        color: Colors.brandPrimary,
    },
    borderBottom: {
        borderBottomWidth: 2,
        borderBottomColor: Colors.chatListItemBorder,
    },
    loadingIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 999,
        top: 0,
        left: 0,
        backgroundColor: Colors.transparentWhite,
        height: '100%',
        width: '100%',
    },

    dueDate: {
        mainContainer: {
            padding: 10,
        },
        clearDueDateBtn: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 15,
            paddingBottom: 15,
            fontSize: 16,
            color: Colors.red,
            outline: 'none',
            backgroundColor: Colors.white,
            cursor: 'pointer',
            borderBottomWidth: '2px',
            borderBottomColor: Colors.chatListItemBorder,
            borderBottomStyle: 'solid',
            borderTopWidth: '2px',
            borderTopColor: Colors.chatListItemBorder,
            borderTopStyle: 'solid',
        },
        disabledClearDueDateBtn: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 15,
            paddingBottom: 15,
            fontSize: 16,
            color: Colors.lightGray,
            outline: 'none',
            backgroundColor: Colors.white,
            borderBottomWidth: '2px',
            borderBottomColor: Colors.chatListItemBorder,
            borderBottomStyle: 'solid',
            borderTopWidth: '2px',
            borderTopColor: Colors.chatListItemBorder,
            borderTopStyle: 'solid',
        },
        taskTitle: {
            paddingBottom: 20,
            paddingLeft: 10,
            paddingTop: 20,
            fontWeight: '700',
            fontSize: 16,
        },
        dividerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 20,
            paddingBottom: 20,
            fontWeight: '700',
        },
        selectionSection: {
            paddingTop: 20,
            paddingBottom: 20,
        },
        durationSelectContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            paddingBottom: 20,
        },
        durationInput: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 0,
            paddingRight: 15,
        },
        lastDurationInput: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 0,
        },
    },
    smallTopMargin: {
        marginTop: 10,
    },
    tags: {
        wrapper: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
        },
        spacing: {
            marginTop: '2px',
            marginRight: '2px',
        },
    },
};

export default DonorStyles;
