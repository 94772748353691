// @flow
import { TextField } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styles from './Styles/DonorStyles';
import {
    donorChanged as _donorChanged,
    saveTaskNote as _saveTaskNote,
} from '../Redux/DonorActions';
import GenericForm from './GenericForm';
import hasPermissions, {
    ENTITY_TYPE_DONOR,
    SUB_ENTITY_TYPE_TASK,
    NONE,
    UPDATE,
} from '../Utils/hasPermissions';
import type {
    UserPermission, FormDefData, DonorTask, Donor, StaticTask,
} from '../Utils/types';
import { getDonorFormAttributes } from '../Utils/TaskDetailsUtils';

type Props = {
    donor: Donor,
    donorTask: DonorTask,
    taskId: number,
    formId: string,
    editNoteContent: string,
    permissions: Array<UserPermission>,
    formDef: FormDefData,
    isConnected: boolean,
    workingFormData: any,
    workflowTaskDefs: Array<StaticTask>,

    donorChanged: (donorUpdated: boolean) => *,
    saveTaskNote: (noteContent: string) => *,
    onFieldUpdate: (formId: string, fieldId: number, data: any) => *,
};

class TaskDetailsBody extends PureComponent<Props> {
    setNoteContent(value: string) {
        const { donorChanged, saveTaskNote, } = this.props;
        donorChanged(true);
        saveTaskNote(value);
    }

    render() {
        const {
            donor,
            donorTask,
            formId,
            taskId,
            editNoteContent,
            permissions,
            formDef,
            isConnected,
            workingFormData,
            onFieldUpdate,
            workflowTaskDefs,
        } = this.props;

        if (!donor) return null;

        const updateDonorAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorTask.donorId, NONE, NONE, UPDATE);
        const updateTaskAllowed = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorTask.donorId, SUB_ENTITY_TYPE_TASK, taskId, UPDATE);
        const updateAllowed = updateDonorAllowed || updateTaskAllowed;

        // // lock when finalized checks notApplicable even though this may seem redundant given extant behavior
        // // Defensive measure to ensure that behavior is self contained within the lockWhenFinalized logic
        // //  - in case the general notApplicable rule changes
        const lockedBecauseFinalized = formDef.behaviors && formDef.behaviors.lockWhenFinalized && (donorTask.completed || donorTask.notApplicable);
        const formDisabled = donor.closed || donorTask.notApplicable || !updateAllowed || lockedBecauseFinalized || !isConnected;

        return (
            <div>
                {formId ? (
                    <GenericForm
                        formId={formId}
                        disabled={formDisabled}
                        formDefData={formDef}
                        onFieldUpdate={onFieldUpdate}
                        workingFormData={workingFormData}
                        donorTaskData={donor.taskData}
                        workflowTaskDefs={workflowTaskDefs}
                    />
                ) : (
                    <div style={styles.detailsInput}>
                        <TextField
                            variant="outlined"
                            autoCapitalize="sentences"
                            placeholder="Enter notes here..."
                            disabled={formDisabled}
                            fullWidth
                            color="primary"
                            label="Notes:"
                            type="text"
                            multiline
                            InputLabelProps={{ shrink: true, }}
                            value={editNoteContent}
                            onChange={(event) => this.setNoteContent(event.target.value)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        donor, donorTask, formId, workflowId,
    } = getDonorFormAttributes(state);
    const workflowTaskDefs = state.donor.workflowTasks && state.donor.workflowTasks[workflowId]
        ? state.donor.workflowTasks[workflowId].tasks : {};

    return {
        donor,
        donorTask,
        formId,
        workflowTaskDefs,
        editNoteContent: state.donor.editNoteContent,
        permissions: state.permission.permissions,
    };
};
export default connect(mapStateToProps, {
    donorChanged: _donorChanged,
    saveTaskNote: _saveTaskNote,
})(TaskDetailsBody);
