// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import IconButton from 'react-toolbox/lib/button/IconButton';
import FontIcon from 'react-toolbox/lib/font_icon';
import ReactTooltip from 'react-tooltip';
import {
    FormControlLabel,
    FormHelperText,
    FormControl,
    MenuItem,
    InputLabel,
    TextField,
    Checkbox,
    Grid,
} from '@material-ui/core';
import {
    Autocomplete,
} from '@mui/material';

import OrganItem from './OrganItem';
import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import AuthStyles from '../Containers/Styles/AuthStyles';
import {
    createDonor as _createDonor,
    setDateOfBirth as _setDateOfBirth,
    setDonorType as _setDonorType,
    setHospital as _setHospital,
    setOPODonorId as _setOPODonorId,
    setSelectedOrgans as _setSelectedOrgans,
    setSex as _setSex,
    setUnosId as _setUnosId,
    setWorkflowKey as _setWorkflowKey,
    setHighRisk as _setHighRisk,
    setTags as _setTags,
    resetDonorEditState as _resetDonorEditState,
    loadPdfFields as _loadPdfFields,
} from '../Redux/DonorActions';
import type { DonorErrors } from '../Redux/DonorActions';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import { IMPORT_DONOR_TYPE, LOCAL_DONOR_TYPE } from '../Utils/DonorTypes';
import { organsObject } from '../Utils/Organs';
import { sexes } from '../Utils/Sexes';
import hasPermissions, {
    LOCAL_DONOR,
    ENTITY_TYPE_UI,
    NONE,
    READ,
} from '../Utils/hasPermissions';
import type {
    Workflow, DonorType, Tag, DonorDataEntry,
} from '../Utils/types';
import { useWorkflowOptions } from '../Utils/hooks';
import ChatInputStyles from './Styles/ChatInputStyles';
import TagsAutocomplete from './TagsAutocomplete';
import FileDropForm from './FileDropForm';
import OfferImport from './OfferImport';

type Props = {
    opoDonorId: string,
    unosId: string,
    workflowKey: string,
    highRisk: boolean,
    tags: Array<Tag>,
    availableTags: Array<Tag>,
    dateOfBirth: string,
    hospital: string,
    sex: string,
    organs: Array<string>,
    isLocalOnly: boolean,
    donorType: ?DonorType,
    isLoading: boolean,
    donorErrors: ?DonorErrors,

    setUnosId: (unosId: string) => *,
    setOPODonorId: (opoDonorId: string) => *,
    setOrganType: (organType: string) => *,
    setSelectedOrgans: (organs: Array<string>) => *,
    setHospital: (hospital: string) => *,
    setDonorType: (donorType: string) => *,
    setDateOfBirth: (dateOfBirth: string) => *,
    setSex: (sex: string) => *,
    setWorkflowKey: (workflowKey: string) => *,
    setHighRisk: typeof _setHighRisk,
    setTags: typeof _setTags,
    createDonor: typeof _createDonor,
    onClose: () => *,
    resetDonorEditState: () => *,
    loadPdfFields: () => *,
};

const CreateDonor = (props: Props) => {
    const {
        opoDonorId, unosId, workflowKey, highRisk, tags, availableTags,
        dateOfBirth, hospital, sex, organs, isLocalOnly, donorType, isLoading, donorErrors,
        setUnosId, setOPODonorId, /* setOrganType, */ setSelectedOrgans, setHospital,
        setDonorType, setDateOfBirth, setSex, setWorkflowKey, setHighRisk,
        setTags, createDonor, onClose, resetDonorEditState, loadPdfFields,
    } = props;

    const [showFileUpload, setShowFileUpload] = useState(false);
    const [donorNetPdf, setDonorNetPdf] = useState<File | void>(undefined);
    const [showImportPreview, setShowImportPreview] = useState(false);
    const [importedDonorData, setImportedDonorData] = useState<DonorDataEntry[]>([]);
    const [importError, setImportError] = useState('');
    const { data: workflowOptions = [], } = useWorkflowOptions();

    useEffect(() => {
        setDonorType(LOCAL_DONOR_TYPE);
        loadPdfFields();
        return () => {
            resetDonorEditState();
        };
    }, [setDonorType, loadPdfFields, resetDonorEditState]);

    useEffect(() => {
        if (isLocalOnly && donorType !== LOCAL_DONOR_TYPE) {
            setDonorType(LOCAL_DONOR_TYPE);
        }
    }, [isLocalOnly, donorType, setDonorType]);

    useEffect(() => () => {
        resetDonorEditState();
    }, [resetDonorEditState]);

    const onSetUNOSId = (event: any) => setUnosId(event.target.value);
    const onSetOPODonorId = (event: any) => setOPODonorId(event.target.value);
    const onSetWorkflowKey = (event: any, workflow: Workflow) => {
        setWorkflowKey(workflow ? workflow.key : '');
    };
    const onSetSex = (event: any) => setSex(event.target.value);
    const onCheckHighRisk = (event) => setHighRisk(event.target.checked);
    const onSetTags = (event: any, newValue: Array<Tag>) => setTags(newValue);
    const onSetHospital = (event) => setHospital(event.target.value);
    const onSetDateOfBirth = (event) => setDateOfBirth(event.target.value);
    const onCloseClick = () => {
        if (!showImportPreview) {
            onClose();
        } else {
            setShowImportPreview(false);
        }
    };
    const onCreateClick = () => {
        createDonor(opoDonorId, donorType, highRisk, tags, workflowKey, hospital, sex, dateOfBirth, unosId, organs, importedDonorData, donorNetPdf);
    };
    const onAddRemoveOrgan = (organType: string) => {
        const tempOrgans = [...organs];
        const index = tempOrgans.indexOf(organType);
        if (index !== -1) {
            // deselect organ
            tempOrgans.splice(index, 1);
        } else {
            // select organ
            tempOrgans.push(organType);
        }
        setSelectedOrgans(tempOrgans);
    };
    const onClearFileUpload = () => {
        setDonorNetPdf(undefined);
        setImportedDonorData([]);
        setImportError('');
        onSetDateOfBirth({ target: { value: '', }, });
        onSetHospital({ target: { value: '', }, });
        onSetSex({ target: { value: '', }, });
    };
    const onImportError = (errorMsg: string) => {
        setImportError(errorMsg);
        setDonorNetPdf(undefined);
        setImportedDonorData([]);
    };
    const onImportedDonorData = (donorData: DonorDataEntry[], filename?: string) => {
        setImportedDonorData(donorData);
        setImportError('');
        // TODO room for improvement, change to some sort of async await to update as they come in?
        // populate fields if parsed from PDF
        const dateOfBirthEntry = donorData.find((entry) => entry.key === 'date_of_birth');
        // TODO change to location later, currently set to OPO
        const hospitalEntry = donorData.find((entry) => entry.key === 'donor_hospital');
        const sexEntry = donorData.find((entry) => entry.key === 'sex');

        // set each entry found
        if (dateOfBirthEntry) {
            // check if dateOfBirthEntry is in format YYYY-MM-DD
            const validatePattern = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/;
            if (validatePattern.test(dateOfBirthEntry.value)) {
                onSetDateOfBirth({ target: { value: dateOfBirthEntry.value, }, });
            } else {
                // change dateOfBirthEntry to be in format YYYY-MM-DD
                const dateOfBirthString = dateOfBirthEntry.value;
                const dateOfBirthSplit = dateOfBirthString.split('/');
                const dateOfBirthFormatted = `${dateOfBirthSplit[2]}-${dateOfBirthSplit[0]}-${dateOfBirthSplit[1]}`;
                dateOfBirthEntry.value = dateOfBirthFormatted;
                onSetDateOfBirth({ target: { value: dateOfBirthEntry.value, }, });
            }
        }
        if (hospitalEntry) {
            onSetHospital({ target: { value: hospitalEntry.value, }, });
        }
        if (sexEntry) {
            // change sexEntry to only have first letter capitalized, rest lowercase
            sexEntry.value = sexEntry.value.charAt(0).toUpperCase() + sexEntry.value.slice(1).toLowerCase();
            onSetSex({ target: { value: sexEntry.value, }, });
        }

        // Look at filename to try and determine organ tags that can be added
        if (filename && filename.length > 5) {
            const lowerCaseFilename = filename.toLowerCase();

            const foundTags = availableTags.filter((tag) => {
                if (!tag.category || !tag.category.toLowerCase().includes('organ')) {
                    return false;
                }

                // organ name to search for will be the last word in the label
                const organName = tag.label.toLowerCase().split(' ').reverse()[0];

                return lowerCaseFilename.indexOf(organName) !== -1;
            });

            // if there are 0 or multiple matches, don't attempt to add tags
            if (foundTags.length === 1) {
                setTags(foundTags);
            }
        }
    };
    const isSelected = (organType: string) => organs.includes(organType);
    // const isFileUploaded = () => donorNetPdf !== undefined;
    const toggleFileUpload = () => setShowFileUpload(!showFileUpload);
    const toggleImportPreview = () => setShowImportPreview(!showImportPreview);
    const uploadPDF = (file: File) => {
        setShowFileUpload(false);
        setDonorNetPdf(file);
    };
    const renderFieldError = (fieldName: string, allowNonField: boolean) => {
        if (!donorErrors) {
            return null;
        }
        let error = donorErrors[fieldName];
        if (!error && allowNonField) {
            error = donorErrors.nonFieldErrors;
        }
        if (!error) {
            return null;
        }
        return (
            <span style={{ ...AuthStyles.formError, ...{ display: 'block', }, }}>
                {error}
            </span>
        );
    };
    const renderCloseButton = () => (
        <div>
            <ReactTooltip id="cancel-tooltip" />
            <Button
                style={CreateChatroomStyles.closeButton}
                type="button"
                ripple={false}
                data-tip="Cancel"
                data-delay-show="250"
                data-effect="solid"
                data-place="bottom"
                data-for="cancel-tooltip"
                onClick={onCloseClick}
            >
                <FontIcon
                    value="close"
                    alt=""
                />
            </Button>
        </div>
    );
    const renderImportDonorFields = () => (
        <div>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        fullWidth
                        color="primary"
                        label="UNOS ID"
                        type="text"
                        value={unosId}
                        onChange={onSetUNOSId}
                    />
                </Grid>
            </Grid>
            <div style={{ height: 50, }} />
            <InputLabel>Select organs:</InputLabel>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <div
                        style={CreateChatroomStyles.organWrapper}
                    >
                        <div style={{ ...ChatroomDetailsStyles.organArea, }}>
                            {organsObject.map((organ: any) => (
                                <OrganItem
                                    key={organ.id}
                                    organ={organ}
                                    disabled={false}
                                    selected={isSelected(organ.value)}
                                    saved={false}
                                    hasChat={false}
                                    selectOrgan={onAddRemoveOrgan}
                                    createChat={() => {}}
                                    noChat
                                />
                            ))}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
    const renderLocalDonorFields = () => (
        <Grid container rowspacing={3} spacing={5} justifyContent="space-around">
            <Grid item xs={12} sm={12} md={4}>
                <TextField
                    fullWidth
                    select
                    color="primary"
                    label="Sex:"
                    type="text"
                    value={sex}
                    onChange={onSetSex}
                >
                    {sexes.map((sexOption: any) => (
                        <MenuItem key={sexOption.value} value={sexOption.value}>{sexOption.label}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <TextField
                    fullWidth
                    color="primary"
                    label="Date of birth:"
                    type="date"
                    InputLabelProps={{ shrink: true, }}
                    value={dateOfBirth}
                    onChange={onSetDateOfBirth}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <TextField
                    fullWidth
                    color="primary"
                    label="Hospital"
                    type="text"
                    value={hospital}
                    onChange={onSetHospital}
                />
            </Grid>
        </Grid>
    );
    const renderHelpIcon = () => (
        <div style={{ marginTop: 43, textAlign: 'center', }}>
            <ReactTooltip id="help-tooltip">
                <p style={{ margin: 0, }}>Contact your Account Manager to maximize your</p>
                <p style={{ margin: 0, }}>workflow for donor import capabilities</p>
            </ReactTooltip>
            <FontIcon
                value="help"
                style={CreateChatroomStyles.helpIcon}
                data-tip
                data-effect="solid"
                data-place="right"
                data-type="info"
                data-for="help-tooltip"
            />
        </div>
    );
    const createButtonDisabled = !opoDonorId || !donorType || !workflowKey || isLoading;
    const reviewButtonDisabled = !donorNetPdf || isLoading;
    const fileSelectedText = `Selected file: ${donorNetPdf ? donorNetPdf.name : ''}`;

    return (
        <div style={{ paddingTop: 65, paddingBottom: 30, }}>
            <FileDropForm
                visible={showFileUpload}
                disabled={false}
                onUploadFile={uploadPDF}
                onCancelFileUpload={toggleFileUpload}
                acceptedFileTypes=".pdf"
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                {renderCloseButton()}
            </div>
            <div style={{ paddingTop: 25, }}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={3}>
                        {renderFieldError('opoDonorId', true)}
                        {renderFieldError('unosId', false)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={CreateChatroomStyles.title}>
                            Create Case
                        </div>
                    </Grid>
                </Grid>
                <div style={{ margin: 'auto', width: '80%', }}>
                    {/*
                        NOTE: Hiding local vs import donor type option from users as part of #4185. Keeping related logic for now.
                              You may also notice some commented out imports and props related to this option throughout the file.
                        {!isLocalOnly ? (
                        <div style={CreateChatroomStyles.centerTabs}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    row
                                    aria-label="type"
                                    name="donorTypes"
                                    value={donorType}
                                    onChange={onSetDonorType}
                                >
                                    {donorTypes.map((option: any) => (
                                        <FormControlLabel
                                            key={option.value}
                                            value={option.value}
                                            control={<Radio color="primary" />}
                                            label={option.label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ) : null} */}
                    <Grid container spacing={5} justify-content="space-around">
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                color="primary"
                                label="Case ID"
                                type="text"
                                placeholder="ex: UNOSID, unique patient identifier, custom name, etc."
                                value={opoDonorId}
                                onChange={(text) => onSetOPODonorId(text)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                                variant="standard"
                                fullWidth
                            >
                                <Autocomplete
                                    onChange={onSetWorkflowKey}
                                    options={workflowOptions}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Workflow"
                                            placeholder="Workflow"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    {(donorType === IMPORT_DONOR_TYPE) ? renderImportDonorFields() : null}
                    {(donorType === LOCAL_DONOR_TYPE) ? renderLocalDonorFields() : null}
                    <Grid container spacing={5}>
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={highRisk}
                                            onChange={onCheckHighRisk}
                                            name="highRisk"
                                            color="secondary"
                                        />
                                    )}
                                    label="Risk Criteria"
                                />
                                <FormHelperText>Cases with risk criteria may have additional tasks that need to be completed</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl
                                variant="standard"
                                fullWidth
                            >
                                <TagsAutocomplete
                                    availableTags={availableTags}
                                    selectedTags={tags}
                                    onChange={onSetTags}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <div style={ChatInputStyles.createCaseButtonsContainer}>
                                <Button
                                    style={{
                                        ...(createButtonDisabled ? ChatInputStyles.chatInputButtonDisabled
                                            : { ...ChatInputStyles.chatInputButton, backgroundColor: '#1aad9e', }),
                                        ...{ marginTop: 40, },
                                    }}
                                    label="Create Case"
                                    ripple={false}
                                    icon="post_add"
                                    disabled={createButtonDisabled}
                                    data-for="chatInput"
                                    data-tip="Create Case"
                                    data-delay-show="250"
                                    data-effect="solid"
                                    onClick={onCreateClick}
                                />
                                <Button
                                    style={{
                                        ...(ChatInputStyles.chatInputButton),
                                        ...{ marginTop: 40, },
                                    }}
                                    label="Import PDF"
                                    icon="upload_file"
                                    ripple={false}
                                    data-for="chatInput"
                                    data-tip="Import PDF"
                                    data-delay-show="250"
                                    data-effect="solid"
                                    onClick={toggleFileUpload}
                                />
                                <Button
                                    style={{
                                        ...(reviewButtonDisabled ? ChatInputStyles.chatInputButtonDisabled : ChatInputStyles.chatInputButton),
                                        ...{ marginTop: 40, },
                                    }}
                                    label="Review"
                                    disabled={reviewButtonDisabled}
                                    ripple={false}
                                    icon="search"
                                    data-for="chatInput"
                                    data-tip="Review"
                                    data-delay-show="250"
                                    data-effect="solid"
                                    onClick={toggleImportPreview}
                                />
                                {renderHelpIcon()}
                            </div>
                            <div style={(!donorNetPdf) ? { display: 'none', } : {}}>
                                <div style={ChatInputStyles.importFileNameContainer}>
                                    <p style={ChatInputStyles.importFileName}>
                                        {fileSelectedText}
                                    </p>
                                    <IconButton
                                        style={{ ...ChatInputStyles.importClearButton, }}
                                        icon="close"
                                        accent
                                        ripple={false}
                                        onClick={onClearFileUpload}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={(!donorNetPdf || importError) ? { display: 'none', } : ChatInputStyles.importReviewWarning}>
                        <p style={{ fontWeight: 'bold', fontSize: '1.17em', }}>PDF Reader Upload</p>
                        <p>
                            Creating a case by importing a PDF will automatically insert the data into your Case
                            Workflow. You can review and check the data by pressing the
                            &#39;Review&#39; button. If you want to update any of the data, you can do this on the
                            same screen. When creating a case using the PDF upload feature, you acknowledge that you
                            have reviewed the data and the data is accurate.
                        </p>
                    </div>
                    <div style={importError ? ChatInputStyles.importError : { display: 'none', }}>
                        <FontIcon value="error" />
                        {importError}
                    </div>
                </div>
            </div>
            <div style={(!showImportPreview) ? { display: 'none', } : {}}>
                <OfferImport
                    showOfferImport={showImportPreview}
                    disabled={false}
                    importFile={donorNetPdf}
                    onToggleImportPreview={toggleImportPreview}
                    onSetImportedDonorData={onImportedDonorData}
                    onImportError={onImportError}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const permissions = state.permission.permissions || [];
    const isLocalOnly = hasPermissions(permissions, ENTITY_TYPE_UI, LOCAL_DONOR, NONE, NONE, READ);

    return {
        isLocalOnly,
        organs: state.donor.organs ? state.donor.organs : [],
        opoDonorId: state.donor.opoDonorId,
        unosId: state.donor.unosId,
        donorType: state.donor.donorType,
        workflowKey: state.donor.workflowKey,
        highRisk: state.donor.highRisk,
        tags: state.donor.tags || [],
        hospital: state.donor.hospital ? state.donor.hospital : '',
        sex: state.donor.sex ? state.donor.sex : '',
        dateOfBirth: state.donor.dateOfBirth || '',
        donorErrors: state.donor.errors,
        isLoading: state.loading.saveDonor,
        availableTags: state.donor.availableTags,
    };
};

export default connect(mapStateToProps, {
    createDonor: _createDonor,
    setUnosId: _setUnosId,
    setOPODonorId: _setOPODonorId,
    setDonorType: _setDonorType,
    setDateOfBirth: _setDateOfBirth,
    setHospital: _setHospital,
    setSex: _setSex,
    setSelectedOrgans: _setSelectedOrgans,
    setWorkflowKey: _setWorkflowKey,
    setHighRisk: _setHighRisk,
    setTags: _setTags,
    resetDonorEditState: _resetDonorEditState,
    loadPdfFields: _loadPdfFields,
})(CreateDonor);
