// @flow
import Colors from '../../Themes/Colors';
import Fonts from '../../Themes/Fonts';

const FormDropStyles = {
    dialogBackdrop: {
        backgroundColor: 'rgb(255,255,255,0.7)',
    },
    dialogPaper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    fileDropWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fileDropForm: {
        fontFamily: Fonts.style.base,
        fontSize: 18,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fileDropFormButton: {
        backgroundColor: Colors.buttonBackground,
        color: Colors.white,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
        marginBottom: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 4,
        cursor: 'pointer',
    },
    fileEscapeMessage: {
        fontSize: 14,
        color: Colors.gray,
        marginTop: 20,
    },
    cancelButton: {
        marginTop: 10,
        color: Colors.red,
        fontSize: 16,
        background: 'none',
        textTransform: 'none',
    },
    uploadIcon: {
        width: '100px',
        height: '100px',
    },
    instructions: {
        marginTop: '25px',
        textAlign: 'center',
    },
};

export default FormDropStyles;
