/* eslint-disable react/jsx-curly-brace-presence */
// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import FontIcon from 'react-toolbox/lib/font_icon';
import { Button } from 'react-toolbox/lib/button';
import { emailActivationDialog as _emailActivationDialog } from 'txp-core';

import Images from '../Themes/Images';
import Colors from '../Themes/Colors';
import { transformCaseName } from '../Utils/text';
import { areNotificationsEnabled } from '../Services/PushNotification';
import {
    CHAT_TAB,
    CASE_TAB,
    TEAMS_TAB,
    logFirebaseEvent as _logFirebaseEvent,
    setSagaMessage as _setSagaMessage,
} from '../Redux/ApplicationActions';
import type { EventName, UserPermission } from '../Utils/types';
import hasPermissions, {
    NONE, CHATROOM_CREATE, ENTITY_TYPE_UI, READ,
} from '../Utils/hasPermissions';
import MenuBar from './MenuBar';

type Props = {
    title: string,
    deviceType: string,
    selectedTab: string,

    isChatSelected: ?boolean,
    isLeftSidebarOpen: boolean,
    isRightUniversalSidebarOpen: boolean,
    showChatDetails: boolean,
    isConnected: boolean,
    isConnecting: boolean,
    isEmailVerified: boolean,
    emailUpdate: ?string,
    createInProgress: boolean,
    globalDoNotDisturbOn: boolean,
    permissions: Array<UserPermission>,

    notificationChange: () => *,
    createChatroomClick: () => *,
    createDonorClick: () => *,
    createTeamClick: () => *,
    onLeftSidebarOpen: () => *,
    onLeftSidebarClose: () => *,
    onReconnect: () => *,
    openMenu: () => *,
    showProfile: () => *,
    showHelp: () => *,
    showSettings: () => *,
    showUpload: () => *,
    showChatroomDetails: () => *,
    closeChatroomDetails: () => *,
    emailActivationDialog: (visible: boolean) => *,
    logFirebaseEvent: (name: EventName) => *,
    setSagaMessage: (heading: string, message: string, label: string, isDialog?: boolean) => *,
};

type State = {
    notification: boolean,
    arrow: boolean,
    create: boolean,
}

class Header extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            notification: false,
            arrow: false,
            create: false,
        };
    }

    onChatroomDetailsClick = () => {
        const {
            isChatSelected,
            showChatDetails,
            logFirebaseEvent,
            showChatroomDetails,
            closeChatroomDetails,
        } = this.props;

        if (isChatSelected) {
            logFirebaseEvent('chatroom-details-click');

            if (showChatDetails) {
                closeChatroomDetails();
            } else {
                showChatroomDetails();
            }
        }
    };

    onCreateChatroomClick = () => {
        const {
            isEmailVerified,
            emailUpdate,
            emailActivationDialog,
            logFirebaseEvent,
            createChatroomClick,
            setSagaMessage,
            permissions,
        } = this.props;

        const canCreateChatroom = hasPermissions(permissions, ENTITY_TYPE_UI, CHATROOM_CREATE, NONE, NONE, READ);

        if (!canCreateChatroom) {
            setSagaMessage('', 'Cannot create chatroom: Invalid permissions.', '');
            return;
        }

        logFirebaseEvent('open-create-chatroom-click');

        if (!isEmailVerified || emailUpdate !== null) {
            emailActivationDialog(true);
            return;
        }

        this.setState({
            create: true,
        });

        createChatroomClick();
    };

    onCreateDonorClick = () => {
        const {
            createDonorClick,
        } = this.props;

        createDonorClick();
    };

    onCreateTeamClick = () => {
        // TODO: Fill out this method
        const {
            createTeamClick,
        } = this.props;

        createTeamClick();
    }

    arrowClick = () => {
        const {
            isLeftSidebarOpen,
            onLeftSidebarClose,
            onLeftSidebarOpen,
            logFirebaseEvent,
        } = this.props;

        this.setState({
            arrow: true,
        });

        logFirebaseEvent('arrow-click');

        if (isLeftSidebarOpen) {
            onLeftSidebarClose();
        } else {
            onLeftSidebarOpen();
        }
    };

    notificationClick = () => {
        const {
            notificationChange,
            logFirebaseEvent,
        } = this.props;

        this.setState({
            notification: true,
        });

        logFirebaseEvent('bell-click');

        notificationChange();
    };

    animationEnd = () => {
        this.setState({
            notification: false,
            arrow: false,
            create: false,
        });
    };

    renderClear() {
        const {
            isConnected,
            isLeftSidebarOpen,
            selectedTab,
        } = this.props;
        if (!isLeftSidebarOpen || selectedTab === CASE_TAB) { return null; }

        const {
            notification,
        } = this.state;
        return (
            <div className="notificationContainer">
                <button
                    className="notificationButton"
                    type="button"
                    tabIndex="0"
                    disabled={!isConnected}
                    onAnimationEnd={this.animationEnd}
                    onClick={this.notificationClick}
                    data-for="header"
                    data-tip="Clear Notifications"
                    data-delay-show="250"
                    data-effect="solid"
                    data-place="bottom"
                >
                    <FontIcon
                        className={
                            isConnected
                                ? (notification ? 'headerButtonAnimate' : 'iconBlue24')
                                : 'iconDisabled24'
                        }
                        value="notifications_none"
                        alt="Notification Bell"
                    />
                </button>
            </div>
        );
    }

    renderAdd() {
        const {
            createInProgress,
            isLeftSidebarOpen,
            selectedTab,
            deviceType,
        } = this.props;
        if (!isLeftSidebarOpen) { return null; }

        let buttonText = 'Add Room';
        let buttonAction = this.onCreateChatroomClick;
        if (selectedTab === CASE_TAB) {
            buttonText = 'Add Case';
            buttonAction = this.onCreateDonorClick;
        } else if (selectedTab === TEAMS_TAB) {
            buttonText = 'Add Team';
            buttonAction = this.onCreateTeamClick;
        }

        if (deviceType === 'mobile') {
            buttonText = '';
        }

        const {
            create,
        } = this.state;
        return (
            <div className="plusContainer">
                <Button
                    icon="add"
                    style={{
                        color: Colors.blue,
                        fontSize: 16,
                        textTransform: 'none',
                    }}
                    label={buttonText}
                    className="iconBlue24"
                    onClick={buttonAction}
                    onAnimationEnd={create ? this.animationEnd : null}
                    disabled={createInProgress}
                />
            </div>
        );
    }

    renderLogo() {
        const { isLeftSidebarOpen, } = this.props;
        if (!isLeftSidebarOpen) { return null; }
        return (
            <div className="logoContainer">
                <img className="logo" src={Images.logoLarge} alt="Omnilife Logo" />
            </div>
        );
    }

    renderArrow() {
        const {
            isLeftSidebarOpen,
            isRightUniversalSidebarOpen,
            deviceType,
            selectedTab,
        } = this.props;

        const {
            arrow,
        } = this.state;
        let arrowIcon = isLeftSidebarOpen ? 'arrow_back_ios' : 'arrow_forward_ios';

        if (selectedTab === CASE_TAB) {
            return null;
        }

        // hide arrow button if the device is mobile and the either sidebar is open
        if (deviceType === 'mobile') {
            if ((isLeftSidebarOpen || isRightUniversalSidebarOpen)) {
                return null;
            }
            // flip arrow for mobile - it represents back
            arrowIcon = !isLeftSidebarOpen ? 'arrow_back_ios' : 'arrow_forward_ios';
        }

        return (
            <div className="arrowContainer">
                <button
                    className="arrowButton"
                    type="button"
                    tabIndex="0"
                    data-tip={!isLeftSidebarOpen ? 'Hide Room List' : 'Show Room List'}
                    data-delay-show="250"
                    data-effect="solid"
                    data-place="bottom"
                    onClick={this.arrowClick}
                    onAnimationEnd={this.animationEnd}
                >
                    <FontIcon
                        className={arrow ? 'headerButtonAnimate' : 'iconBlue24'}
                        value={arrowIcon}
                        alt="Arrow"
                    />
                </button>
            </div>
        );
    }

    renderNotifications() {
        const {
            globalDoNotDisturbOn,
        } = this.props;
        const notificationsEnabled = areNotificationsEnabled();
        return (
            <span>
                {
                    !notificationsEnabled
                        ? (
                            <FontIcon
                                className="globalDNDIndicator"
                                value="notifications_off"
                                alt=""
                                data-tip="Notification permission denied"
                                data-delay-show="250"
                                data-effect="solid"
                                data-place="bottom"
                            />
                        ) : null
                }
                {
                    notificationsEnabled && globalDoNotDisturbOn
                        ? (
                            <FontIcon
                                className="globalDNDIndicator"
                                value="notifications_paused"
                                alt=""
                                data-for="header"
                                data-tip="Global do not disturb is on"
                                data-delay-show="250"
                                data-effect="solid"
                                data-place="bottom"
                            />
                        ) : null
                }
            </span>
        );
    }

    renderChatInfo() {
        const {
            isChatSelected,
            selectedTab,
            showChatDetails,
        } = this.props;

        if (selectedTab !== CHAT_TAB) return null;

        return (
            <div className="logoutButtonContainer">
                {isChatSelected
                    ? (
                        <>
                            <button
                                className="notificationButton"
                                type="button"
                                tabIndex="0"
                                onClick={this.onChatroomDetailsClick}
                                data-tip={showChatDetails ? 'Hide Chat Details' : 'Show Chat Details'}
                                data-delay-show="250"
                                data-effect="solid"
                                data-place="bottom"
                            >
                                <FontIcon
                                    id="chatInfo"
                                    className={showChatDetails ? 'iconBlue24' : 'material-icons-outlined'}
                                    value="info"
                                    alt="Information"
                                />
                                <div className="menuLabel">Info</div>
                            </button>
                        </>
                    ) : <div />}
            </div>
        );
    }

    renderTitle() {
        const {
            title,
            isConnected,
            isConnecting,
            onReconnect,
        } = this.props;
        return (
            <div className="titleContainer">
                <span className="title">
                    {transformCaseName(title)}
                </span>
                {isConnected ? <span /> : isConnecting ? <span className="connecting-indicator" /> : (
                    <span
                        className="offlineIndicator"
                        data-for="header"
                        data-tip="Connection with the server has been lost. Click to attempt to reconnect."
                        data-delay-show="200"
                        data-effect="solid"
                        data-place="bottom"
                    >
                        <FontIcon value="wifi_off" alt="Not Connected" onClick={onReconnect} />
                    </span>
                )}
            </div>
        );
    }

    render() {
        const {
            deviceType,
            isLeftSidebarOpen,
            isRightUniversalSidebarOpen,
            openMenu,
            showProfile,
            showHelp,
            showSettings,
            showUpload,
        } = this.props;

        let showTitle = true;
        let showChatDetails = true;
        let spanHeader = 3;

        if (deviceType === 'tablet') {
            spanHeader = 4;
        }
        if (deviceType === 'mobile') {
            // hide the chatroom name if the device is mobile and either of the sidebars is open
            showTitle = !(isLeftSidebarOpen || isRightUniversalSidebarOpen);
            // hide chatroom details (and logout menu) button if the device is mobile and left sidebar is open
            showChatDetails = !(isLeftSidebarOpen);

            if (isLeftSidebarOpen) {
                spanHeader = 12;
            } else {
                spanHeader = 0;
            }
        }

        return (
            <div>
                <ReactTooltip
                    id="header"
                    multiline
                />
                <Row>
                    <div className="headerContainer">
                        <Col xs={spanHeader}>
                            <Row>
                                <Col xs={2}>{this.renderArrow()}</Col>
                                <Col xs={2}>{this.renderClear()}</Col>
                                <Col xs={4}>{this.renderLogo()}</Col>
                                <Col xs={4}>{this.renderAdd()}</Col>
                            </Row>
                        </Col>
                        <Col xs={12 - spanHeader}>
                            {showChatDetails
                                ? (
                                    <div className="logoutButtonContainer">
                                        {this.renderChatInfo()}
                                        {this.renderNotifications()}
                                        {isLeftSidebarOpen
                                            ? (
                                                <MenuBar
                                                    openMenu={openMenu}
                                                    showProfile={showProfile}
                                                    showHelp={showHelp}
                                                    showSettings={showSettings}
                                                    showUpload={showUpload}
                                                />
                                            ) : null}
                                    </div>
                                ) : <div />}
                            {showTitle
                                ? (
                                    <div>
                                        {this.renderTitle()}
                                    </div>
                                ) : <div />}
                        </Col>
                    </div>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isEmailVerified: state.auth.profile.isEmailVerified,
    emailUpdate: (state.auth.profile || {}).emailUpdate || null,
    selectedTab: state.application.selectedTab,
    isConnecting: state.chatList.socketStatus === 'connecting',
});
export default connect(mapStateToProps, {
    emailActivationDialog: _emailActivationDialog,
    logFirebaseEvent: _logFirebaseEvent,
    setSagaMessage: _setSagaMessage,
})(Header);
