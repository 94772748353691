// @flow
import React, { Component } from 'react';
import type { ElementRef } from 'react';
import Input from 'react-toolbox/lib/input';
import Button from 'react-toolbox/lib/button/Button';

import ApplicationStyles from '../Themes/ApplicationStyles';
import RegistrationStyles from '../Containers/Styles/RegistrationStyles';
import AuthStyles from '../Containers/Styles/AuthStyles';
import BackButton from './BackButton';

type Props = {
    errorMessage: string,
    phoneNumber?: string,
    canResend: boolean,
    canGoBack: boolean,
    verifying: boolean,
    showHeading: boolean, // true - display 'OmniLife Web' false - don't display

    submitPasscode: (passcode: string) => *,
    resendSMSCode?: (phone: string) => *,
    goBack?: () => *,
};

type State = {
    passcode: string[]
};

type RefNodes = ElementRef<*>[];

class VerificationCodeInput extends Component<Props, State> {
    nodes: RefNodes = [null, null, null, null, null, null];

    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            passcode: ['', '', '', '', '', ''],
        };
    }

    componentDidMount() {
        if (this.nodes[0] != null) {
            this.nodes[0].focus();
        }
    }

    onSubmitPasscode = () => {
        const {
            submitPasscode,
        } = this.props;

        const passcode = this.state.passcode.join('');

        submitPasscode(passcode);
    };

    onBackspace = (idx: number) => {
        const {
            passcode,
        } = this.state;

        passcode[idx] = '';
        if (idx !== 0) {
            this.nodes[idx - 1].focus();
        } else {
            this.nodes[idx].focus();
        }

        this.setState({
            passcode,
        });
    };

    setCode(idx: number, text: string) {
        const {
            passcode,
        } = this.state;

        if (text !== '') { // if backspace
            const value = passcode[idx];
            passcode[idx] = text.replace(value, '');
            if (idx + 1 < 6) {
                this.nodes[idx + 1].focus();
            } else {
                this.nodes[idx].blur();
            }
        }

        this.setState({
            passcode,
        });
    }

    setNodeRef(key: number, ref: ?ElementRef<*>) {
        this.nodes[key] = ref;
    }

    resetValue(key: number) {
        const input = this.nodes[key];
        if (input) {
            const { value, } = input;
            input.value = ''; // unset first
            input.value = value; // re-set next
        }
    }

    render() {
        const {
            phoneNumber,
            errorMessage,
            canResend,
            canGoBack,
            showHeading,
            verifying,
            resendSMSCode,
            goBack,
        } = this.props;

        const {
            passcode,
        } = this.state;

        const submitDisabled = !(passcode.join('').length === 6) || verifying;
        const errorDivStyle = showHeading ? { marginBottom: 10, } : { marginBottom: 10, textAlign: 'center', };

        return (
            <form>
                {canGoBack && goBack
                    ? (
                        <div style={{ float: 'left', }}>
                            <BackButton
                                label="Back"
                                goBack={goBack}
                            />
                        </div>
                    ) : null}
                {showHeading ? <h1 style={{ ...ApplicationStyles.loginLabel, ...{ marginTop: 50, }, }}>OmniLife Web</h1> : null}
                <span style={RegistrationStyles.formLabel}>
                    {'Please enter the passcode that was sent to '}
                    {phoneNumber}
                    {'.'}
                </span>
                {
                    canResend
                        ? (
                            <div>
                                <span style={AuthStyles.instructionsInline}>
                                    Didn&apos;t get it?
                                </span>
                                <Button
                                    style={
                                        ApplicationStyles.buttonInverse
                                    }
                                    type="button"
                                    label="Send again"
                                    flat
                                    ripple={false}
                                    onClick={resendSMSCode}
                                />
                            </div>
                        ) : <div />
                }
                <div style={RegistrationStyles.passcode}>
                    <div style={RegistrationStyles.box}>
                        <Input
                            type="text"
                            innerRef={(input) => this.setNodeRef(0, input)}
                            value={passcode[0]}
                            autoFocus
                            onFocus={this.resetValue(0)}
                            onChange={(text) => this.setCode(0, text)}
                            onKeyDown={(e) => (e.which === 8 ? this.onBackspace(0) : {})}
                            style={RegistrationStyles.boxText}
                        />
                    </div>
                    <div style={RegistrationStyles.box}>
                        <Input
                            type="text"
                            innerRef={(input) => this.setNodeRef(1, input)}
                            value={passcode[1]}
                            onFocus={this.resetValue(1)}
                            onChange={(text) => this.setCode(1, text)}
                            onKeyDown={(e) => (e.which === 8 ? this.onBackspace(1) : {})}
                            style={RegistrationStyles.boxText}
                        />
                    </div>
                    <div style={RegistrationStyles.box}>
                        <Input
                            type="text"
                            innerRef={(input) => this.setNodeRef(2, input)}
                            value={passcode[2]}
                            onFocus={this.resetValue(2)}
                            onChange={(text) => this.setCode(2, text)}
                            onKeyDown={(e) => (e.which === 8 ? this.onBackspace(2) : {})}
                            style={RegistrationStyles.boxText}
                        />
                    </div>
                    <div style={RegistrationStyles.box}>
                        <Input
                            type="text"
                            innerRef={(input) => this.setNodeRef(3, input)}
                            value={passcode[3]}
                            onFocus={this.resetValue(3)}
                            onChange={(text) => this.setCode(3, text)}
                            onKeyDown={(e) => (e.which === 8 ? this.onBackspace(3) : {})}
                            style={RegistrationStyles.boxText}
                        />
                    </div>
                    <div style={RegistrationStyles.box}>
                        <Input
                            type="text"
                            innerRef={(input) => this.setNodeRef(4, input)}
                            value={passcode[4]}
                            onFocus={this.resetValue(4)}
                            onChange={(text) => this.setCode(4, text)}
                            onKeyDown={(e) => (e.which === 8 ? this.onBackspace(4) : {})}
                            style={RegistrationStyles.boxText}
                        />
                    </div>
                    <div style={RegistrationStyles.box}>
                        <Input
                            type="text"
                            innerRef={(input) => this.setNodeRef(5, input)}
                            value={passcode[5]}
                            onFocus={this.resetValue(5)}
                            onChange={(text) => this.setCode(5, text)}
                            onKeyDown={(e) => (e.which === 8 ? this.onBackspace(5) : {})}
                            style={RegistrationStyles.boxText}
                        />
                    </div>
                </div>
                <div style={errorDivStyle}>
                    <span style={AuthStyles.formError}>
                        {errorMessage}
                    </span>
                </div>
                <div style={ApplicationStyles.horizontalCenter}>
                    <Button
                        style={
                            submitDisabled ? ApplicationStyles.buttonDisabled : ApplicationStyles.button
                        }
                        type="button"
                        label="Verify"
                        flat
                        disabled={submitDisabled}
                        ripple={false}
                        onClick={this.onSubmitPasscode}
                    />
                </div>
            </form>
        );
    }
}

// $FlowFixMe
VerificationCodeInput.defaultProps = {
    phoneNumber: 'your mobile phone',
    resendSMSCode: () => {},
    goBack: () => {},
};

export default VerificationCodeInput;
