// @flow
import Colors from '../../Themes/Colors';

const profileImageSize = 100;

const ProfileStyles = {
    marginTop10: {
        marginTop: 10,
    },
    marginTopBottom: {
        marginTop: 10,
        marginBottom: 30,
    },
    profileImageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 5,
        marginTop: 15,
    },
    profileImageBorder: {
        zIndex: -1,
        height: profileImageSize,
        width: profileImageSize,
        padding: '3px',
        borderRadius: profileImageSize / 1.5,
        background: 'linear-gradient(to right, #2E3192, #00A79D)',
    },
    profileImage: {
        zIndex: 10,
        borderRadius: profileImageSize / 2,
        height: profileImageSize,
        width: profileImageSize,
    },
    profileImageHidden: {
        borderRadius: profileImageSize / 2,
        height: profileImageSize,
        width: profileImageSize,
        display: 'none',
    },
    loadingIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: profileImageSize,
        width: profileImageSize,
    },
    loadingMessages: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        marginLeft: '20px',
    },
    infoWrapper: {
        marginLeft: '40px',
        marginRight: '40px',
        marginTop: '10px',
    },
    fieldContainerNoButton: {
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: 2,
        marginTop: 5,
    },
    fieldContainerWithButton: {
        display: 'flex',
        lineHeight: 2,
        marginTop: 5,
    },
    label: {
        color: Colors.lightGray,
        fontSize: 16,
    },
    smallLabel: {
        color: Colors.lightGray,
        fontSize: 14,
        marginRight: 5,
    },
    value: {
        textAlign: 'right',
        marginRight: 0,
        marginLeft: 'auto',
    },
    center: {
        textAlign: 'center',
    },
    error: {
        fontSize: 14,
        color: Colors.red,
    },
    saved: {
        fontSize: 14,
        color: Colors.blue,
    },
    rightButton: {
        float: 'right',
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 16,
    },
    rightButtonDisabled: {
        float: 'right',
        backgroundColor: Colors.white,
        color: Colors.buttonTextDisabled,
        textTransform: 'none',
        fontSize: 16,
        cursor: 'default',
    },
    nameInput: {
        fontSize: 16,
        color: Colors.black,
        outline: 'none',
        border: 0,
        textAlign: 'right',
        backgroundColor: Colors.white,
    },
    nameInputEdit: {
        fontSize: 16,
        color: Colors.black,
        outline: 'none',
        borderTopWidth: '0px',
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
        textAlign: 'right',
        width: '70%',
    },
    commPrefInput: {
        resize: 'none',
        outline: 'none',
        border: 0,
        fontSize: 16,
        color: Colors.black,
        textAlign: 'right',
        backgroundColor: Colors.white,
        fontFamily: 'Roboto',
    },
    commPrefInputEdit: {
        resize: 'none',
        borderRadius: 8,
        outline: 'none',
        borderColor: Colors.chatListItemBorder,
        borderWidth: '2px',
        fontSize: 16,
        fontFamily: 'Roboto',
    },
    textInput: {
        fontSize: 16,
        color: Colors.black,
        outline: 'none',
        borderTopWidth: '0px',
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
        paddingTop: 10,
    },
    centerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    changePicButton: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        cursor: 'pointer',
        fontSize: 14,
        marginBottom: 20,
    },
    resendButton: {
        backgroundColor: Colors.blue,
        color: Colors.white,
        textTransform: 'none',
    },
    cancelButton: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
    },
    updatePending: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        paddingRight: 0,
    },
    radioButtonContainer: {
        paddingTop: 15,
    },
    blankMessage: {
        fontSize: 16,
        color: Colors.lightGray,
        textAlign: 'center',
        marginTop: 15,
    },

    memberNameContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
    memberName: {
        textAlign: 'center',
        color: Colors.black,
        fontSize: 18,
    },
    memberInfoContainer: {
        width: '75%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 20,
    },
    contentContainer: {
        display: 'block',
        borderBottomWidth: 2,
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        paddingBottom: 10,
    },
    content: {
        textAlign: 'left',
        color: Colors.formLabel,
        fontSize: 14,
    },
    labelContainer: {
        display: 'block',
        marginTop: 3,
    },
    memberLabel: {
        textAlign: 'left',
        color: Colors.brandPrimary,
        fontSize: 14,
    },
    commPref: {
        resize: 'none',
        borderRadius: 8,
        padding: 5,
        outline: 'none',
        backgroundColor: Colors.chatAreaBackground,
        borderColor: Colors.chatListItemBorder,
        borderWidth: '2px',
        fontSize: 14,
        color: Colors.black,
    },
    messageCountWrapper: {
        textAlign: 'center',
        margin: 20,
        fontSize: 16,
        color: Colors.lightGray,
        bottom: 0,
        lineHeight: 2,
    },
    sentCount: {
        color: Colors.brandPrimary,
    },
    readCount: {
        color: Colors.brandSecondary,
    },
    accountDeletion: {
        display: 'flex',
        margin: 'auto',
        backgroundColor: Colors.white,
        color: Colors.red,
        textTransform: 'none',
        fontSize: 16,
    },
    muteButtons: {
        display: 'flex',
        gap: 20,
        margin: '0 10px',
        color: Colors.linkText,
    },
    muteButton: {
        padding: 10,
    },
};

export default ProfileStyles;
