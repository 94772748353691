/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
// @flow
import type { FormDefData } from '../../Utils/types';
import {
    STRING, UI_TYPE_TIME,
} from './FormTypes';

const formDef: FormDefData = {
    formId: 'AllocationComplete',
    behaviors: {
        lockWhenFinalized: true,
        saveOnComplete: true,
        clearOnIncomplete: true,
        clearOnNotApplicable: true,
    },
    sections: [
        {
            id: 1,
            title: '',
            fields: [
                { id: 1, fieldName: 'clinicalEventTime', displayAs: 'OR Time set for', title: 'OR Time', type: STRING, uiType: UI_TYPE_TIME, isTime: true, mandatory: true, tags: [], }
            ],
        }
    ],
};

export default formDef;
