const formIndexMap = {
    'Organ Allocated: Heart': 'OAHeart',
    'Organ Allocated: Intestine': 'OAIntestine',
    'Organ Allocated: Kidneys': 'OAKidneys',
    'Organ Allocated: Liver': 'OALiver',
    'Organ Allocated: Lungs': 'OALungs',
    'Organ Allocated: Pancreas': 'OAPancreas',
    'Organ Recovered: Heart': 'ORHeart',
    'Organ Recovered: Intestine': 'ORIntestine',
    'Organ Recovered: Kidneys': 'ORKidneys',
    'Organ Recovered: Liver': 'ORLiver',
    'Organ Recovered: Lungs': 'ORLungs',
    'Organ Recovered: Pancreas': 'ORPancreas',
    'Allocation Complete & OR time set': 'AllocationComplete',
    'Enter OR': 'EnterOR',
    'Heparin Administered': 'HeparinAdministered',
    Incision: 'Incision',
    'Flush/Crossclamp': 'FlushCrossclamp',
    'Organ Recovery/OR Complete': 'ORComplete',
    'Transport Drop-Off Complete': 'TransportDropoffComplete',
    'Blood Work Sent': 'BloodWorkSent',
    'Blood Work Result': 'BloodWorkResult',
    'Covid Test Result': 'CovidTestResult',
    'Pre-Screen': 'PreScreen',
    Embalming: 'Embalming',
    'Location Notification': 'LocationNotification',
};

export default formIndexMap;
