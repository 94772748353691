// @flow
import emojiRegex from 'emoji-regex';

const isEmojiMessage = (message: string): boolean => {
    // if no letters, numbers, or symbols in message and <= 3 emojis in message
    // eslint-disable-next-line no-useless-escape
    if (!/[a-zA-Z0-9 .,\\/#!$%@'"?^&*;:{}+=\-_`~()[\]|<>]/.test(message) && !/^$/.test(message)) {
        const regex = emojiRegex();
        let emojiCount = 0;

        while (regex.exec(message)) {
            emojiCount += 1;
        }

        if (emojiCount <= 3) {
            return true;
        }
    }
    return false;
};

export default isEmojiMessage;
