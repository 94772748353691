/* eslint-disable max-len */
// @flow

import type {
    ResourcePermission,
    UserPermission,
} from './types';

// Define const to lessen typo's
export const READ = 'READ';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const ENTITY_TYPE_UI = 'UI';
export const ENTITY_TYPE_DONOR = 'Donor';
export const ENTITY_TYPE_CHATROOM = 'Chatroom';
export const ENTITY_TYPE_OFFER = 'Offer';
export const ENTITY_TYPE_TEAM = 'Team';
export const ENTITY_TYPE_EXTERNAL = 'ExternalUser';
export const SUB_ENTITY_TYPE_MANAGER = 'Manager';
export const SUB_ENTITY_TYPE_TRACKER = 'Tracker';
export const SUB_ENTITY_TYPE_TRACKER_ACKS = 'TrackerAcks';
export const SUB_ENTITY_TYPE_TRACKER_PEOPLE = 'TrackerPeople';
export const SUB_ENTITY_TYPE_DETAILS = 'Details';
export const SUB_ENTITY_TYPE_TIMELINE = 'Timeline';
export const SUB_ENTITY_TYPE_FILES = 'Files';
export const SUB_ENTITY_TYPE_TASK = 'Task';
export const SUB_ENTITY_TYPE_DONOR_PERMISSIONS = 'Permissions';
export const NONE = null;
export const ANY = 'ANY'; // Designates permission to subEntity of any subEntityId

export const DONOR_CREATE: number = 2;
export const TEMPLATED_CASE_FEATURE: number = 3;
export const DEMO_DONOR_FEATURE: number = 6;
export const CHATROOM_CREATE: number = 7;
export const UPLOAD_OFFERS: number = 11;
export const LOCAL_DONOR: number = 12;
export const DONOR_VIEW: number = 13;
export const DONOR_WEB_BETA: number = 15;

const hasPermissions = (
    permissions: Array<UserPermission>,
    entityType: string,
    entityId: number,
    subEntityType: ?string,
    subEntityId: ?number | ?string,
    right: string
): boolean => {
    if (!permissions || permissions.length <= 0) {
        return false;
    }

    for (let i = 0; i < permissions.length; i += 1) {
        if (permissions[i].target && permissions[i].rights) {
            const permissionEntityType = permissions[i].target.entityType;
            const permissionEntityId = permissions[i].target.entityId;
            const permissionCascade = permissions[i].target.cascade;

            const permissionSubEntityType = permissions[i].target.subEntityType;
            const permissionSubEntityId = permissions[i].target.subEntityId;

            // Allow for direct match or top level match with cascade
            if (permissionEntityType === entityType && permissionEntityId === entityId) {
                if ((permissionCascade && permissionSubEntityType === null)
                    || (permissionSubEntityType === subEntityType && (permissionSubEntityId === subEntityId || subEntityId === ANY))) {
                    // Return true if permission granted. If not granted, carry on looking
                    if (right === READ && permissions[i].rights.read) {
                        return true;
                    }
                    if (right === UPDATE && permissions[i].rights.update) {
                        return true;
                    }
                    if (right === DELETE && permissions[i].rights.delete) {
                        return true;
                    }
                }
            }
        }
    }

    return false;
};

export const hasResourcePermissions = (
    resourcePermissions: Array<ResourcePermission>,
    entityType: string,
    entityId: number,
    subEntityType: ?string,
    subEntityId: ?number,
    right: string,
    authorizedId: number,
    authorizedType: string
): boolean => {
    if (!resourcePermissions || resourcePermissions.length <= 0) {
        return false;
    }

    for (let i = 0; i < resourcePermissions.length; i += 1) {
        if (resourcePermissions[i].target && resourcePermissions[i].rights && resourcePermissions[i].authorized) {
            const permissionEntityType = resourcePermissions[i].target.entityType;
            const permissionEntityId = resourcePermissions[i].target.entityId;
            const permissionCascade = resourcePermissions[i].target.cascade;

            const permissionSubEntityType = resourcePermissions[i].target.subEntityType;
            const permissionSubEntityId = resourcePermissions[i].target.subEntityId;

            const permissionAuthorizedId = resourcePermissions[i].authorized.authorizedId;
            const permissionAuthorizedType = resourcePermissions[i].authorized.authorizedType;

            if (permissionAuthorizedId === authorizedId && permissionAuthorizedType === authorizedType) {
                // Allow for direct match or top level match with cascade
                if (permissionEntityType === entityType && permissionEntityId === entityId) {
                    if ((permissionCascade && permissionSubEntityType === null) || (permissionSubEntityType === subEntityType && permissionSubEntityId === subEntityId)) {
                        // Return true if permission granted. If not granted, carry on looking
                        if (right === READ && resourcePermissions[i].rights.read) {
                            return true;
                        }
                        if (right === UPDATE && resourcePermissions[i].rights.update) {
                            return true;
                        }
                        if (right === DELETE && resourcePermissions[i].rights.delete) {
                            return true;
                        }
                    }
                }
            }
        }
    }

    return false;
};

// Add in any default permissions. Likely this will be for Basic Users as Orgs can use ClientPermissions
export const addDefaultClientPermissions = (
    accessLevel: string
): Array<UserPermission> => {
    const newPermissions = [];
    if (accessLevel === 'BasicUser') {
        const basicUserDemoPermission: UserPermission = {
            target: {
                entityType: ENTITY_TYPE_UI, entityId: DEMO_DONOR_FEATURE, subEntityType: NONE, subEntityId: NONE, cascade: false,
            },
            rights: { read: true, update: false, delete: false, },
        };
        newPermissions.push(basicUserDemoPermission);
    }
    return newPermissions;
};

export default hasPermissions;
