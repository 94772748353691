// Mobile up to 600px - Mimic the view in the App. Chatlist or Chat - not both
// Tablet - between the two. Sidebars can open and close, but are 35%
// Laptop above 1200px - Empirically derived through experimentation in dev tools
//     but certainly the iPadPro width of 1024 needs the wider sidebar

export const size = {
    mobile: '600px',
    laptop: '1300px',
};

export const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(min-width: ${size.mobile}) and (max-width: ${size.laptop})`,
    laptop: `(min-width: ${size.laptop})`,
};
