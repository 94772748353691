// @flow
import * as Sentry from '@sentry/browser';

import { maybeLogNetworkError } from '../Utils/flattenError';
import type { AppError, EventName } from '../Utils/types';
import createUuid from '../Utils/createUuid';

export const CHAT_TAB: string = 'chat';
export const CASE_TAB: string = 'case';
export const TEAMS_TAB: string = 'teams';

export const DONOR_STATUS_VIEW: string = 'status';
export const DONOR_TASK_VIEW: string = 'task';
export const DONOR_DETAILS_VIEW: string = 'details';
export const DONOR_TASK_DUE_VIEW: string = 'taskdue';
export const DONOR_ADD_PEOPLE_VIEW: string = 'people';
export const DONOR_ROOMS_VIEW: string = 'rooms';
export const DONOR_TRACKER_VIEW: string = 'tracker';
export const DONOR_FOLLOWER_GROUP_VIEW: string = 'follower';
export const DONOR_CASE_ADMIN_INFO_VIEW: string = 'admin-info';

type ApplicationState = {
    errors: Array<AppError>,
    deviceToken: ?string,
    sagaHeading: ?string,
    sagaMessage: ?string,
    sagaLabel: ?string,
    isDialog: ?boolean,
    reducerErrors: Array<any>,
    selectedTab: string,
    donorView: string,
    matchOrgan: string,
};

const initialState: ApplicationState = {
    errors: [],
    deviceToken: null,
    sagaHeading: '',
    sagaMessage: '',
    sagaLabel: '',
    isDialog: false,
    reducerErrors: [],
    selectedTab: 'chat',
    donorView: 'status',
    matchOrgan: '',
};

export type ReducerError = {
    uuid: string,
    source: string,
    action: any,
    error: any,
};

export const getReducerError = (uuid: string, source: string, action: any, error: any): ReducerError => ({
    uuid, source, action, error,
});

export type PushErrorAction = { type: 'Application/PUSH_ERROR', message: string, label: string };
export type PopErrorAction = { type: 'Application/POP_ERROR' };

// Device token maintenance
export type SetDeviceToken = { type: 'Application/SET_DEVICE_TOKEN', deviceToken: string };
export type StoreDeviceToken = { type: 'Application/STORE_DEVICE_TOKEN', deviceToken: string };

export type LogFirebaseEvent = { type: 'Application/LOG_EVENT', eventName: EventName };

export type ResetData = { type: 'Application/RESET_DATA' };

export type Reload = { type: 'App/RELOAD' };

export type SetSagaMessage = { type: 'Application/SET_SAGA_MESSAGE', heading: string, message: string, label: string, isDialog: boolean, isPriority: boolean, };
export type ReportError = { type: 'Application/REPORT_ERROR', reducerError: ReducerError };
export type ResetError = { type: 'Application/RESET_ERROR', uuid: string };

export type GetApplicationData = {
    type: 'Application/GET_APPLICATION_DATA',
    getOrganizations: boolean,
    getChatrooms: boolean,
    getChatroomMembers: boolean,
    getInstantMessages: boolean,
    chatroomIds: Array<number>,
}

export type SelectTab = { type: 'Application/SELECT_TAB', tabName: string };
export type SelectDonorView = { type: 'Application/SELECT_DONOR_VIEW', viewName: string, donorOrgan?: string };

type Action =
    | SetDeviceToken
    | PushErrorAction
    | PopErrorAction
    | StoreDeviceToken
    | LogFirebaseEvent
    | SetSagaMessage
    | GetApplicationData
    | Reload
    | ResetData
    | ReportError
    | ResetError
    | SelectTab
    | SelectDonorView;

export const storeDeviceToken = (deviceToken: string): StoreDeviceToken => ({
    type: 'Application/STORE_DEVICE_TOKEN',
    deviceToken,
});

export const setDeviceToken = (deviceToken: string): SetDeviceToken => ({
    type: 'Application/SET_DEVICE_TOKEN',
    deviceToken,
});

export const logFirebaseEvent = (eventName: EventName): LogFirebaseEvent => ({
    type: 'Application/LOG_EVENT',
    eventName,
});

export const setSagaMessage = (heading: string, message: string, label: string, isDialog?: boolean = false, isPriority?: boolean = false): SetSagaMessage => ({
    type: 'Application/SET_SAGA_MESSAGE',
    heading,
    message,
    label,
    isDialog,
    isPriority,
});

export const reportError = (reducerError: ReducerError): ReportError => ({
    type: 'Application/REPORT_ERROR',
    reducerError,
});

export const resetError = (uuid: string): ResetError => ({
    type: 'Application/RESET_ERROR',
    uuid,
});

export const resetErrorState = (state: any, action: ResetError): any => {
    // Deliberately not putting a try catch in here. If this fails there is no hope for the world :-(
    // an exception will be caught and added to reducer errors and fail again until the stack fills up
    // suppressing the error here still leaves the original error to loop and fill up the stack.
    // Simplest is therefore best. We can reconsider if we get contrary evidence (or a better suggestion)
    const reducerErrors = state.reducerErrors.slice();

    for (let i = 0; i < reducerErrors.length; i += 1) {
        if (reducerErrors[i].uuid === action.uuid) {
            reducerErrors.splice(i, 1);
            break;
        }
    }
    return {
        ...state,
        reducerErrors,
    };
};

export const getApplicationData = (
    getOrganizations: boolean,
    getChatrooms: boolean,
    getChatroomMembers: boolean,
    getInstantMessages: boolean,
    chatroomIds: Array<number>
): GetApplicationData => ({
    type: 'Application/GET_APPLICATION_DATA',
    getOrganizations,
    getChatrooms,
    getChatroomMembers,
    getInstantMessages,
    chatroomIds,
});

export const pushError = (message: string, error: ?Error, label?: string = 'Error'): PushErrorAction => {
    if (error) {
        // Ensure we log the error properly to sentry
        if (!maybeLogNetworkError(error)) {
            Sentry.captureException(JSON.stringify(error));
        }

        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Error occured', error);
        }
    }

    return {
        type: 'Application/PUSH_ERROR',
        message,
        label,
    };
};

export const popError = (): PopErrorAction => ({
    type: 'Application/POP_ERROR',
});

export const selectTab = (tabName: string): SelectTab => ({
    type: 'Application/SELECT_TAB',
    tabName,
});

export const selectDonorView = (viewName: string, donorOrgan?: string): SelectDonorView => ({
    type: 'Application/SELECT_DONOR_VIEW',
    viewName,
    donorOrgan,
});

export const reloadApp = (): Reload => ({
    type: 'App/RELOAD',
});

const reducer = (state: ApplicationState = initialState, action: Action): ApplicationState => {
    // wrap the whole reducer to catch any unexpected exceptions and record them
    // Another component will need to act upon these and potentially send them to Sentry
    try {
        return innerReducer(state, action);
    } catch (err) {
        const reducerErrors = state.reducerErrors.slice();
        const uuid = createUuid(0);
        reducerErrors.push(getReducerError(uuid, 'application', action, err));
        return {
            ...state,

            reducerErrors,
        };
    }
};

const innerReducer = (state: ApplicationState = initialState, action: Action): ApplicationState => {
    let errors;

    switch (action.type) {
        case 'Application/RESET_ERROR':
            return resetErrorState(state, action);

        case 'Application/SET_DEVICE_TOKEN':
            return {
                ...state,

                deviceToken: action.deviceToken,
            };

        case 'Application/SELECT_TAB':
            return {
                ...state,

                selectedTab: action.tabName,
            };

        case 'Application/SELECT_DONOR_VIEW':
            return {
                ...state,

                donorView: action.viewName,
                donorOrgan: action.donorOrgan || '',
            };

        case 'Application/PUSH_ERROR':
            errors = [{
                message: action.message,
                label: action.label,
            }].concat(state.errors.slice());

            return {
                ...state,

                errors,
            };

        case 'Application/POP_ERROR':
            return {
                ...state,

                errors: state.errors.slice(0, state.errors.length - 1),
            };

        case 'Application/SET_SAGA_MESSAGE':
            return {
                ...state,

                sagaHeading: action.heading,
                sagaMessage: action.message,
                sagaLabel: action.label,
                isDialog: action.isDialog,
                isPriority: action.isPriority,
            };

        default:
            return state;
    }
};

export default reducer;
