/* eslint-disable react/destructuring-assignment */
// @flow
import React from 'react';

import styles from './Styles/BannerStyles';
import Colors from '../Themes/Colors';

type Props = {
    entity: 'case' | 'team' | 'contact' | 'offer';
    message?: string,
};

const ReadOnlyBanner = (props: Props) => (
    <div
        style={{ ...styles.donorClosedOverlayMessageWrapper, ...{ backgroundColor: Colors.brandSecondary, }, }}
    >
        {props.message
            ? (
                <span
                    style={styles.overlayMessageText}
                >
                    {props.message}
                </span>
            ) : (
                <span
                    style={styles.overlayMessageText}
                >
                    This
                    {` ${props.entity} `}
                    is view only.
                </span>
            )}
    </div>
);

ReadOnlyBanner.defaultProps = {
    message: undefined,
};

export default ReadOnlyBanner;
