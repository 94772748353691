// @flow
import React, { PureComponent } from 'react';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import LoadingIndicator from 'react-loading-indicator';

import MediaStyles from './Styles/MediaStyles';
import type { RemoteMediaUri } from '../Redux/ChatMediaActions';
import images from '../Themes/Images';
import downloadFile from '../Utils/downloadFile';
import type { MediaType } from '../Utils/types';

type Props = {
    source: ?(RemoteMediaUri | string),
    isLocalSource: boolean,
    active: boolean,
    type: MediaType,

    onMediaClose: () => *
};

type State = {
    loaded: boolean,
    failed: boolean,
    source: ?RemoteMediaUri,
};

class MediaItem extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            failed: false,
            loaded: false,
            source: null,
        };
    }

    async componentDidMount() {
        const {
            source,
            isLocalSource,
        } = this.props;

        if (isLocalSource && typeof source === 'string') {
            this.setState({
                source: {
                    uri: source,
                },
                loaded: true,
            });
        } else if (source) {
            await this.downloadFile(source);
        }

        this._isMounted = true; // eslint-disable-line no-underscore-dangle
    }

    async componentWillUnmount() {
        this._isMounted = false; // eslint-disable-line no-underscore-dangle
    }

    onMediaLoad = () => {
        this.setState({
            loaded: true,
        });
    };

    _isMounted = true;

    async downloadFile(source: RemoteMediaUri | string) {
        this.setState({
            failed: false,
        });

        const { uri, headers, } = typeof source === 'string' ? { uri: source, headers: {}, } : source;

        const { result, error, } = await downloadFile({
            fromUrl: uri,
            headers,
            useLock: true,
        });

        if (this._isMounted) { // eslint-disable-line no-underscore-dangle
            if (!error) {
                this.setState({
                    source: {
                        uri: result.path,
                    },
                });
            } else {
                this.setState({
                    failed: true,
                });
            }
        }
    }

    render() {
        const {
            active,
            type,
            onMediaClose,
        } = this.props;

        const { failed, loaded, source, } = this.state;

        const isImage = (type === 'image');
        const isVideo = (type === 'video');
        const isApplication = (type === 'application');

        let blobUri = null;

        if (source && source.uri) {
            blobUri = source.uri;
        }

        const loadingIndicator = !loaded ? (
            <LoadingIndicator />
        ) : null;

        const videoStyle = loaded ? MediaStyles.displayMedia : MediaStyles.hideMedia;

        return (
            <Dialog
                active={active}
                type="fullscreen"
                onEscKeyDown={onMediaClose}
                onOverlayClick={onMediaClose}
            >
                <span style={MediaStyles.closeButtonWrapper}>
                    <button
                        type="button"
                        style={MediaStyles.closeButton}
                        onClick={onMediaClose}
                        className="closeButton"
                    >
                        <FontIcon
                            value="close"
                            alt=""
                        />
                    </button>
                </span>
                {failed ? (
                    <img
                        onLoad={onMediaClose}
                        src={images.brokenMedia}
                        alt=""
                    />
                ) : isImage ? (
                    <span style={MediaStyles.mediaWrapper}>
                        {loadingIndicator}
                        <img
                            style={MediaStyles.displayMedia}
                            onLoad={this.onMediaLoad}
                            src={blobUri}
                            alt=""
                        />
                    </span>
                ) : isVideo ? (
                    <span style={MediaStyles.mediaWrapper}>
                        {loadingIndicator}
                        <video // eslint-disable-line
                            style={videoStyle}
                            onLoadStart={this.onMediaLoad}
                            src={blobUri}
                            controls
                        />
                    </span>
                ) : isApplication ? (
                    <span style={MediaStyles.mediaWrapper}>
                        {loadingIndicator}
                        <iframe
                            style={MediaStyles.displayApplicationMedia}
                            onLoad={this.onMediaLoad}
                            src={blobUri}
                            title="media_item_iframe"
                        />
                    </span>
                ) : null}
            </Dialog>
        );
    }
}

export default MediaItem;
