// @flow
import type { FormDefData } from '../../Utils/types';
import {
    STRING, UI_TYPE_MULT_OPTION,
} from './FormTypes';

const formDef: FormDefData = {
    formId: 'LocationNotification',
    behaviors: {
        lockWhenFinalized: true,
        saveOnComplete: true,
        clearOnIncomplete: true,
        clearOnNotApplicable: true,
    },
    sections: [
        {
            id: 1,
            title: '',
            fields: [
                {
                    id: 1,
                    title: 'Select Location',
                    type: STRING,
                    uiType: UI_TYPE_MULT_OPTION,
                    responseOptions: [
                        'Taggart',
                        'Texland',
                        'Mobile Lab',
                        'Airport',
                        'Cooler B',
                        'Other'
                    ],
                    mandatory: true,
                    tags: [],
                }
            ],
        }
    ],
};

export default formDef;
