// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import Button from 'react-toolbox/lib/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown,
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import type { FollowerEditState } from '../Redux/FollowerEditActions';
import { createMultipleFollowerGroups } from '../Redux/FollowerEditActions';
import { resetCasePreferences } from '../Redux/DonorActions';
import styles from './Styles/CasePreferencesStyles';
import Colors from '../Themes/Colors';
import CasePreferencesFollower from './CasePreferencesFollower';
import Checkbox from './Checkbox';

type Props = {
    onClose: () => *,
}

const SUBTITLE_TEXT = 'Below are the Follower Groups that have been '
    + 'defined for this Workflow. You can add these groups now or create the Case '
    + 'at this time without adding them. Select the groups you would like to '
    + 'create and edit them as needed.';
const POSTAMBLE = 'If the Workflow does not have defined Follower '
    + 'Groups, you can create the same Follower Groups as you did with the '
    + 'most recent Case';

export default function CasePreferences(props: Props) {
    const { onClose, } = props;
    const dispatch = useDispatch();

    const followerGroupArray = useSelector((state) => state.donor.casePreferences);

    const newDonorId: number = useSelector((state) => state.donor.openDonorId || -1);

    const [followerGroups, setFollowerGroups] = useState<FollowerEditState[]>(followerGroupArray);
    const [openGroupIndices, setOpenGroupIndices] = useState<number[]>([]);
    const [selectedGroupIndices, setSelectedGroupIndices] = useState<number[]>([]);

    // When component unmounts, ensure we close and clear out the casePreferences on state
    useEffect(() => () => {
        onClose();
        dispatch(resetCasePreferences());
    }, []);

    const onUpdateFollowerGroups = (newGroup: FollowerEditState, groupIdx: number) => {
        const newGroups = [...followerGroups];
        newGroups[groupIdx] = newGroup;
        setFollowerGroups(newGroups);
    };

    const onToggleGroup = (groupIdx: number) => {
        const newIndices = [...openGroupIndices];
        if (openGroupIndices.includes(groupIdx)) {
            const idxToRemove = openGroupIndices.indexOf(groupIdx);
            newIndices.splice(idxToRemove, 1);
        } else {
            newIndices.push(groupIdx);
        }
        setOpenGroupIndices(newIndices);
    };

    const onCreateFollowerGroups = () => {
        const groupsToCreate = followerGroups.filter((group, idx) => selectedGroupIndices.includes(idx));
        dispatch(createMultipleFollowerGroups(newDonorId, groupsToCreate));
        // Close the view after creating the groups
        onClose();
    };

    const onCancel = () => {
        onClose();
    };

    const addFollowerGroup = (groupIdx: number) => {
        const newSelectedGroups = [...selectedGroupIndices];
        newSelectedGroups.push(groupIdx);
        setSelectedGroupIndices(newSelectedGroups);
    };

    const removeFollowerGroup = (groupIdx: number) => {
        // always close the accordian when disabling a group
        const newOpenIndices = [...openGroupIndices];
        const idxToRemove = openGroupIndices.indexOf(groupIdx);
        newOpenIndices.splice(idxToRemove, 1);
        const newSelectedGroups = [...selectedGroupIndices];
        const selectedToRemove = newSelectedGroups.indexOf(groupIdx);
        newSelectedGroups.splice(selectedToRemove, 1);
        setOpenGroupIndices(newOpenIndices);
        setSelectedGroupIndices(newSelectedGroups);
    };

    return (
        <div style={{ paddingTop: 65, alignContent: 'center', }}>
            <div style={styles.preferencesContainer}>
                <div style={styles.title}>
                    Default Follower Groups
                </div>
                <div style={styles.subTitle}>
                    {SUBTITLE_TEXT}
                </div>
                <div style={styles.subTitle}>
                    {POSTAMBLE}
                </div>
                {followerGroups.map((group, groupIdx) => (
                    <div
                        key={group.followerGroupId}
                        style={styles.accordionRow}
                    >
                        <Checkbox
                            disabled={false}
                            className={selectedGroupIndices.includes(groupIdx) ? 'iconBlue24' : 'iconDisabled24'}
                            iconName={selectedGroupIndices.includes(groupIdx) ? faCheckCircle : faCircle}
                            label=""
                            onClick={
                                selectedGroupIndices.includes(groupIdx) ? () => removeFollowerGroup(groupIdx) : () => addFollowerGroup(groupIdx)
                            }
                        />
                        <Accordion
                            disabled={!selectedGroupIndices.includes(groupIdx)}
                            sx={{ width: '100%', }}
                            expanded={openGroupIndices.includes(groupIdx)}
                            onChange={() => onToggleGroup(groupIdx)}
                        >
                            <AccordionSummary
                                expandIcon={(
                                    <FontAwesomeIcon
                                        color={Colors.blue}
                                        icon={faCaretDown}
                                        size="lg"
                                    />
                                )}
                            >
                                <div style={styles.accordionSummary}>
                                    { group.followerName }
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {openGroupIndices.includes(groupIdx)
                                    ? (
                                        <CasePreferencesFollower
                                            followerGroup={group}
                                            onUpdateGroup={(newGroup) => onUpdateFollowerGroups(newGroup, groupIdx)}
                                        />
                                    ) : null}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
                <div style={styles.actionsWrapper}>
                    <Button
                        disabled={selectedGroupIndices.length === 0}
                        style={selectedGroupIndices.length === 0 ? styles.disabledButton : styles.createButton}
                        label="Create Follower Groups"
                        onClick={onCreateFollowerGroups}
                        icon="groups"
                    />
                    <Button
                        style={styles.skipButton}
                        label="Skip Without Creating"
                        onClick={onCancel}
                        icon="skip_next"
                    />
                </div>
            </div>
        </div>
    );
}
