// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import sift from 'sift';
import LoadingIndicator from 'react-loading-indicator';
import $ from 'jquery';
import { selectProfileName } from 'txp-core';

import UserDetails from './UserDetails';
import Search from './Search';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import AddPeopleStyles from './Styles/AddPeopleStyles';
import ChatMessageStyles from './Styles/ChatMessageStyles';
import AuthStyles from '../Containers/Styles/AuthStyles';
import Images from '../Themes/Images';
import {
    filterOrgMembers as _filterOrgMembers,
    setSelectedOrgMembers as _setSelectedOrgMembers,
} from '../Redux/OfferActions';
import type {
    UserProfile,
    AvatarMap,
} from '../Utils/types';

type Props = {
    users: Array<UserProfile>,
    selectedUsers: Array<number>,
    memberId: number,
    avatars: AvatarMap,
    filter: string,
    chatInputHeight: number,
    isConnected: boolean,
    isLoading: boolean,

    filterOrgMembers: (filter: string) => *,
    setSelectedOrgMembers: (selectedOrgMembers: Array<number>) => *,
    uploadOffer: (selectedOrgMembers: Array<number>, orgMembers: Array<UserProfile>) => *,
};

type State = {
    scrollHeight: ?string,
};

class SelectUsers extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            scrollHeight: null,
        };
    }

    componentDidMount() {
        const {
            setSelectedOrgMembers,
        } = this.props;

        setSelectedOrgMembers([]);

        const containerHeight = $('#containerDiv').height() || 0;
        const userDetailsHeight = $('#userDetailsDiv').height() || 0;

        if (userDetailsHeight < containerHeight) {
            this.setState({
                scrollHeight: 'inherit',
            });
        }
    }

    onMemberClick = (userProfile: UserProfile) => {
        const {
            selectedUsers,
            setSelectedOrgMembers,
        } = this.props;

        const { userId, } = userProfile;

        const newSelection = selectedUsers;
        if (selectedUsers.includes(userId)) {
            const index = selectedUsers.indexOf(userId);
            if (index !== -1) {
                newSelection.splice(index, 1);
            }
        } else {
            newSelection.push(userId);
        }

        setSelectedOrgMembers(newSelection);
    };

    onUploadOffer = () => {
        const {
            users,
            selectedUsers,
            uploadOffer,
        } = this.props;

        uploadOffer(selectedUsers, users);
    };

    userAvailableForSelection = (userId: number) => {
        const {
            users,
            memberId,
        } = this.props;

        if (userId === null) {
            return false;
        }

        // Exclude if self
        const index = users.findIndex((x) => x.userId === userId);
        const user = index !== -1 ? users[index] : null;
        if (user && user.userId === memberId) {
            return ('');
        }

        return true;
    };

    renderFieldError = (errorMessage: string) => {
        if (!errorMessage) {
            return null;
        }

        return (
            <div style={CreateChatroomStyles.center}>
                <span
                    style={AuthStyles.formErrorAddNew}
                >
                    {errorMessage}
                </span>
            </div>
        );
    };

    renderUserDetails = (userId: number) => {
        const {
            users,
            selectedUsers,
            avatars,
            isConnected,
        } = this.props;

        const index = users.findIndex((x) => x.userId === userId);
        const user = users[index];
        const avatar = (avatars[userId] || Images.circledDefaultProfile);

        if (!this.userAvailableForSelection(userId)) {
            return ('');
        }

        const membershipStatus = selectedUsers.includes(userId) ? 'Pending' : 'NonMember';

        return (
            <UserDetails
                key={userId}
                membershipStatus={membershipStatus}
                startDate=""
                endDate=""
                userProfile={user}
                avatar={avatar}
                displayButton
                doNotDisturb={false} // we don't have doNotDisturb status from contacts, nor is it relevant to Add People
                displayEmail
                wrapEmail={false}
                isConnected={isConnected}
                onMemberClick={this.onMemberClick}
                addManager={() => {}}
                removeManager={() => {}}
                showMemberProfile={() => {}}
            />
        );
    };

    renderUpdateButton() {
        const {
            selectedUsers,
            isConnected,
        } = this.props;

        const selectedCount = selectedUsers.length;

        if (selectedCount === 0) {
            return (
                <div style={AddPeopleStyles.informationRightWrapper}>
                    <span style={AddPeopleStyles.informationRight}>
                        No selections
                    </span>
                </div>
            );
        }

        const personText = (selectedCount === 1) ? ' person' : ' people';

        return (
            <div style={AddPeopleStyles.addButton}>
                <button
                    style={isConnected ? AddPeopleStyles.addNumPeople : AddPeopleStyles.addNumPeopleDisabled}
                    type="button"
                    tabIndex="0"
                    disabled={!isConnected}
                    onClick={this.onUploadOffer}
                    data-tip="Select"
                    data-delay-show="500"
                    data-effect="solid"
                >
                    <span>
                        {`Add ${selectedCount}${personText}`}
                    </span>
                </button>
            </div>
        );
    }

    renderSelectedUserAvatars = (userId: number) => {
        const {
            users,
            selectedUsers,
            avatars,
        } = this.props;

        // defensive code against missing userid. Should not happen but has in guest processing
        if (!this.userAvailableForSelection(userId)) return ('');

        const avatar = avatars[userId] || '';

        const index = users.findIndex((x) => x.userId === userId);
        const user = index !== -1 ? users[index] : null;

        const profileName = user ? selectProfileName(user) : '';

        if (selectedUsers.includes(userId)) {
            return (
                <span
                    key={userId}
                    style={ChatroomDetailsStyles.profileImageSelectedWrapper}
                >
                    <ReactTooltip />
                    <img
                        style={ChatroomDetailsStyles.profileImage}
                        src={(avatar && avatar !== '') ? avatar : Images.circledDefaultProfile}
                        alt=""
                        data-tip={profileName}
                        data-delay-show="100"
                        data-effect="solid"
                    />
                </span>
            );
        }
        return (
            <span
                key={userId}
            />
        );
    };

    renderPeopleList() {
        const {
            users,
            filter,
            filterOrgMembers,
            chatInputHeight,
            isLoading,
        } = this.props;

        const {
            scrollHeight,
        } = this.state;

        const height = $('#selectedUsersDiv').height() || 0;

        if (!isLoading) {
            let availableUsers = 0;

            for (let i = 0; i < users.length; i += 1) {
                if (this.userAvailableForSelection(users[i].userId)) {
                    availableUsers += 1;
                }
            }

            if (availableUsers > 0) {
                const items = filter ? users.filter(
                    sift({
                        $where() {
                            if (this.firstName && this.lastName) {
                                return this.firstName.toUpperCase().includes(filter.toUpperCase())
                                    || this.lastName.toUpperCase().includes(filter.toUpperCase());
                            }
                            return false;
                        },
                    })
                ) : users;

                return (
                    <div
                        id="containerDiv"
                        style={{ ...CreateChatroomStyles.addPeople, ...{ height: `calc(60vh - ${chatInputHeight}px)`, }, }}
                    >
                        <div style={AddPeopleStyles.fixedSearchCreateChatroom}>
                            <Search
                                placeholder="Search people..."
                                filterContent={filterOrgMembers}
                                value={filter}
                            />
                        </div>
                        <div
                            id="userDetailsDiv"
                            // eslint-disable-next-line max-len
                            style={{ ...ChatroomDetailsStyles.userArea, ...{ height: scrollHeight, paddingBottom: height + 20, overflow: 'visible', }, }}
                        >
                            {items.map((item) => this.renderUserDetails(item.userId))}
                        </div>
                        <div
                            id="selectedUsersDiv"
                            style={ChatroomDetailsStyles.selectedUsersAreaCreate}
                        >
                            {users.map((item) => this.renderSelectedUserAvatars(item.userId))}
                            {this.renderUpdateButton()}
                        </div>
                    </div>
                );
            }
            return (
                <div style={{ textAlign: 'center', }}>
                    No users available
                </div>
            );
        }
        return (
            <div style={ChatMessageStyles.loadingIndicator}>
                <LoadingIndicator />
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderPeopleList()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.offer.orgMembers,
    selectedUsers: state.offer.selectedOrgMembers,
    avatars: state.chatList.avatars,
    memberId: (state.auth.profile && state.auth.profile.userId) ? state.auth.profile.userId : -1,
    filter: state.offer.memberFilter,
    isConnected: state.chatList.socketStatus === 'connected',
    isLoading: state.loading.orgMembers,
});

export default connect(mapStateToProps, {
    filterOrgMembers: _filterOrgMembers,
    setSelectedOrgMembers: _setSelectedOrgMembers,
})(SelectUsers);
