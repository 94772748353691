// @flow
import React, { PureComponent } from 'react';

import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import type { Organization } from '../Utils/types';

type Props = {
    organization: Organization,

    selectOrganization: (organization: Organization) => *,
    getSurgeons: () => *,
};

class OrganizationItem extends PureComponent<Props> {
    chooseOrganization = () => {
        const {
            organization,
            selectOrganization,
            getSurgeons,
        } = this.props;

        selectOrganization(organization);

        getSurgeons();
    };

    render() {
        const {
            organization,
        } = this.props;

        return (
            <div // eslint-disable-line
                style={CreateChatroomStyles.organizationContainer}
                role="button"
                tabIndex={0}
                onClick={this.chooseOrganization}
            >
                <span style={CreateChatroomStyles.mainText}>
                    {organization.name}
                </span>
                <span style={CreateChatroomStyles.subText}>
                    {organization.code}
                </span>
            </div>
        );
    }
}

export default OrganizationItem;
