// @flow
import type { Logout } from 'txp-core';

import type { AttachmentData, ChatTemporaryMessageMap } from '../Utils/types';
import type { ResetData, ResetError } from './ApplicationActions';
import { resetErrorState, getReducerError } from './ApplicationActions';

import createUuid from '../Utils/createUuid';

const initialState: ChatTemporaryMessageMap = { reducerErrors: [], };

export type SetMessageText = { type: 'ChatMessage/SET', chatId: number, text: string };
export type SetMessageMedia = { type: 'ChatMessage/SET_MEDIA', chatId: number, attachments: Array<AttachmentData> };
export type SetMessageReply = { type: 'ChatMessage/SET_REPLY', chatId: number, replyMessageId: ?number };
export type SetMentionedUsers = { type: 'ChatMessage/SET_MENTIONED_USERS', chatId: number, mentionedUsers: Array<number> };
export type SetMentionedNames = { type: 'ChatMessage/SET_MENTIONED_NAMES', chatId: number, mentionedNames: Array<string> };
export type SetFilteredNames = { type: 'ChatMessage/SET_FILTERED_NAMES', chatId: number, filteredNames: Array<string> };

export type SetChatInputHeight = { type: 'ChatMessage/SET_CHAT_INPUT_HEIGHT', chatId: number, chatInputHeight: number };
export type SetChatMediaHeight = { type: 'ChatMessage/SET_CHAT_MEDIA_HEIGHT', chatId: number, mediaHeight: number };

export type MediaUploadSuccess = { type: 'ChatMessage/MEDIA_UPLOAD_SUCCESS', chatId: number };

export type SubmitMessage = { type: 'ChatMessage/SUBMIT', chatId: number };

export type TriggerAskAlan = {
    type: 'ChatList/ASK_ALAN',
    chatId: number,
    orgId: number, // org id for user that's logged in
    targetOrgId: number, // org id for that is receiving the offer
    userId: number,
    donorId: ?number,
    organId: ?number
};

export type UploadXML = { type: 'ChatMessage/UPLOAD_XML', chatId: number, xml: ?AttachmentData };
export type UploadXMLSuccess = { type: 'ChatMessage/UPLOAD_XML_SUCCESS', chatId: number, donorId: number, organId: number };
export type UploadXMLFailed = { type: 'ChatMessage/UPLOAD_XML_FAILED', chatId: number, error: string };
export type ResetXMLError = { type: 'ChatMessage/RESET_XML_ERROR', chatId: number };

type Action =
    | SetMessageText
    | SetMessageMedia
    | SetMessageReply
    | SetMentionedUsers
    | SetMentionedNames
    | SetFilteredNames
    | SetChatInputHeight
    | SetChatMediaHeight
    | MediaUploadSuccess
    | SubmitMessage
    | TriggerAskAlan
    | UploadXML
    | UploadXMLSuccess
    | UploadXMLFailed
    | ResetXMLError
    | Logout
    | ResetData
    | ResetError;

export const setMessageText = (chatId: number, text: string): SetMessageText => ({
    type: 'ChatMessage/SET',
    chatId,
    text,
});

export const setMessageMedia = (chatId: number, attachments: Array<AttachmentData>): SetMessageMedia => ({
    type: 'ChatMessage/SET_MEDIA',
    chatId,
    attachments: attachments || [],
});

export const setMessageReply = (chatId: number, replyMessageId: ?number): SetMessageReply => ({
    type: 'ChatMessage/SET_REPLY',
    chatId,
    replyMessageId,
});

export const setMentionedUsers = (chatId: number, mentionedUsers: Array<number> = []): SetMentionedUsers => ({
    type: 'ChatMessage/SET_MENTIONED_USERS',
    chatId,
    mentionedUsers: mentionedUsers || [],
});

export const setMentionedNames = (chatId: number, mentionedNames: Array<string> = []): SetMentionedNames => ({
    type: 'ChatMessage/SET_MENTIONED_NAMES',
    chatId,
    mentionedNames: mentionedNames || [],
});

export const setFilteredNames = (chatId: number, filteredNames: Array<string> = []): SetFilteredNames => ({
    type: 'ChatMessage/SET_FILTERED_NAMES',
    chatId,
    filteredNames: filteredNames || [],
});

export const setChatInputHeight = (chatId: number, chatInputHeight: number): SetChatInputHeight => ({
    type: 'ChatMessage/SET_CHAT_INPUT_HEIGHT',
    chatId,
    chatInputHeight,
});

export const setChatMediaHeight = (chatId: number, mediaHeight: number): SetChatMediaHeight => ({
    type: 'ChatMessage/SET_CHAT_MEDIA_HEIGHT',
    chatId,
    mediaHeight,
});

export const mediaUploadSuccess = (chatId: number): MediaUploadSuccess => ({
    type: 'ChatMessage/MEDIA_UPLOAD_SUCCESS',
    chatId,
});

export const submitMessage = (chatId: number): SubmitMessage => ({
    type: 'ChatMessage/SUBMIT',
    chatId,
});

export const triggerAskAlan = (
    chatId: number,
    orgId: number,
    targetOrgId: number,
    userId: number,
    donorId: ?number,
    organId: ?number
): TriggerAskAlan => ({
    type: 'ChatList/ASK_ALAN',
    chatId,
    orgId,
    targetOrgId,
    userId,
    donorId,
    organId,
});

export const uploadXML = (chatId: number, xml: ?AttachmentData = null): UploadXML => ({
    type: 'ChatMessage/UPLOAD_XML',
    chatId,
    xml: xml || null,
});

export const uploadXMLSuccess = (chatId: number, donorId: number, organId: number): UploadXMLSuccess => ({
    type: 'ChatMessage/UPLOAD_XML_SUCCESS',
    chatId,
    donorId,
    organId,
});

export const uploadXMLFailed = (chatId: number, error: string): UploadXMLFailed => ({
    type: 'ChatMessage/UPLOAD_XML_FAILED',
    chatId,
    error,
});

export const resetXMLError = (chatId: number): ResetXMLError => ({
    type: 'ChatMessage/RESET_XML_ERROR',
    chatId,
});

const reducer = (state: ChatTemporaryMessageMap = initialState, action: Action): ChatTemporaryMessageMap => {
    // wrap the whole reducer to catch any unexpected exceptions and record them
    // Another component will need to act upon these and potentially send them to Sentry
    try {
        return innerReducer(state, action);
    } catch (err) {
        const reducerErrors = state.reducerErrors.slice();
        const uuid = createUuid(0);
        reducerErrors.push(getReducerError(uuid, 'chatMessage', action, err));
        return {
            ...state,

            reducerErrors,
        };
    }
};

const innerReducer = (state: ChatTemporaryMessageMap = initialState, action: Action): ChatTemporaryMessageMap => {
    switch (action.type) {
        case 'Application/RESET_ERROR':
            return resetErrorState(state, action);

        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            // Reset the store when logging out
            return {
                ...initialState,
            };

        case 'ChatMessage/SET':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    text: action.text,
                },
            };

        case 'ChatMessage/SET_MEDIA':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    attachments: action.attachments,
                },
            };

        case 'ChatMessage/SET_REPLY':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    replyMessageId: action.replyMessageId,
                },
            };

        case 'ChatMessage/SET_MENTIONED_USERS':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    mentionedUsers: action.mentionedUsers,
                },
            };

        case 'ChatMessage/SET_MENTIONED_NAMES':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    mentionedNames: action.mentionedNames,
                },
            };

        case 'ChatMessage/SET_FILTERED_NAMES':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    filteredNames: action.filteredNames,
                },
            };

        case 'ChatMessage/SET_CHAT_INPUT_HEIGHT':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    chatInputHeight: action.chatInputHeight,
                },
            };

        case 'ChatMessage/SET_CHAT_MEDIA_HEIGHT':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    mediaHeight: action.mediaHeight,
                },
            };

        case 'ChatMessage/MEDIA_UPLOAD_SUCCESS':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    localPath: null,
                },
            };

        case 'ChatMessage/UPLOAD_XML_SUCCESS':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    donorId: action.donorId,
                    organId: action.organId,
                },
            };

        case 'ChatMessage/UPLOAD_XML_FAILED':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    error: action.error,
                },
            };

        case 'ChatMessage/RESET_XML_ERROR':
            return {
                ...state,

                [`${action.chatId}`]: {
                    ...(state[`${action.chatId}`] || {}),

                    error: null,
                },
            };

        default:
            return state;
    }
};

export default reducer;
