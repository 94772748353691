// @flow
import * as Sentry from '@sentry/browser';

export type DownloadConfig = {
    fromUrl: string,
    headers: *,
    useLock?: boolean,
};

export type DownloadResult = {
    path: string,
    size: number,
    statusCode: number,
    type: string,
};

export async function downloadAvatar({ fromUrl, headers, }: DownloadConfig) {
    try {
        return await fetch(fromUrl, {
            method: 'GET',
            headers,
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                return null;
            })
            .then((result) => {
                if (result) {
                    return URL.createObjectURL(result);
                }
                return null;
            });
    } catch (error) {
        return null;
    }
}

export const downloadFileBlobStart = ({
    fromUrl,
    headers,
}: DownloadConfig): Promise<*> => fetch(fromUrl, {
    method: 'GET',
    headers,
}).then((response) => {
    if (response.ok) {
        return response.blob();
    }
    // throw an error so this is marked as failed.
    throw new Error(`download failed for:${fromUrl}`);
});

export const downloadFileBlobEnd = async (fromUrl: string, task: Promise<*>) => {
    let result: ?DownloadResult = null;
    let error = null;

    try {
        const blobBoy = await task;
        const path = URL.createObjectURL(blobBoy);
        // return the type to enable mime type validation
        result = {
            path,
            size: blobBoy.size,
            statusCode: 200,
            type: blobBoy.type,
        };
    } catch (e) {
        error = e;
        if (`${e}`.includes('cancelled')) {
            return {
                result,
                error,
                cancelled: true,
            };
        }

        Sentry.captureException(`Download file Failed:${JSON.stringify(e)}`);
        // return the error so that the item is marked as failed.
        return {
            result,
            error,
            cancelled: false,
        };
    }

    return {
        result,
        error,
        cancelled: false,
    };
};

const downloadFileBlob = async (
    {
        fromUrl,
        headers,
        useLock,
    }: DownloadConfig
): Promise<any> => {
    const task = downloadFileBlobStart({
        fromUrl,
        headers,
        useLock,
    });
    return downloadFileBlobEnd(fromUrl, task);
};

export default downloadFileBlob;

export const downloadFileAsAttachment = (blob: Blob, fileName: string) => {
    if (!document.body) {
        throw new Error('Page not loaded');
    }

    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Make Flow happy
    if (document.body) {
        // some browser needs the anchor to be in the doc
        document.body.append(link);
    }

    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};
