// @flow

export const organsObject = [
    {
        id: 'rKidney',
        value: 'Right Kidney',
    },
    {
        id: 'lKidney',
        value: 'Left Kidney',
    },
    {
        id: 'KidneysEnbloc',
        value: 'Kidneys Enbloc',
    },
    {
        id: 'liver',
        value: 'Liver',
    },
    {
        id: 'liverSplitLeft',
        value: 'Liver Split Left',
    },
    {
        id: 'liverSplitRight',
        value: 'Liver Split Right',
    },
    {
        id: 'pancreas',
        value: 'Pancreas',
    },
    {
        id: 'heart',
        value: 'Heart',
    },
    {
        id: 'lungs',
        value: 'Lungs Enbloc',
    },
    {
        id: 'leftlung',
        value: 'Left Lung',
    },
    {
        id: 'rightlung',
        value: 'Right Lung',
    },
    {
        id: 'intestine',
        value: 'Intestine',
    }
];
