// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import Fade from 'react-reveal';
import ReactTooltip from 'react-tooltip';
import { updateProfile as _updateProfile } from 'txp-core';

import { MobileDateTimePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import SidebarStyles from './Styles/SidebarStyles';
import { formatDateTime, isFutureDatetime, INFINITE_DND_CONSTANT } from '../Utils/time';

import type { UserProfile } from '../Utils/types';
import BackButton from './BackButton';

type Props = {
    profile: UserProfile,

    updateProfile: (user: UserProfile, doNotDisturbEndtime: Date) => *,
    closeSettings: () => *,
    setGlobalDoNotDisturbInterval: (ms: number) => *,
};

type State = {
    customDate: Date,
    customDndOpen: boolean
}
class Settings extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            customDate: new Date(),
            customDndOpen: false,
        };
    }

    onClose = () => {
        const {
            closeSettings,
        } = this.props;

        closeSettings();
    };

    setGlobalDoNotDisturb = (value: number, infiniteDndDuration: boolean = false) => {
        const {
            profile,
            updateProfile,
            setGlobalDoNotDisturbInterval,
        } = this.props;

        let doNotDisturbEndtime;

        // This is a special case where we set the global do not disturb to an infinite duration
        // This was done in lieu of a database change to allow for an infinite duration
        if (infiniteDndDuration) {
            doNotDisturbEndtime = new Date(INFINITE_DND_CONSTANT);
            updateProfile(profile, doNotDisturbEndtime);
            const distantEndTime = doNotDisturbEndtime.getTime() - Date.now();

            // eslint-disable-next-line no-console
            console.log('Setting global do not disturb to infinite', distantEndTime);
            setGlobalDoNotDisturbInterval(distantEndTime);
        } else {
            doNotDisturbEndtime = new Date(Date.now() + value * 60 * 60 * 1000);
            updateProfile(profile, doNotDisturbEndtime);
            // eslint-disable-next-line no-console
            console.log('Setting global do not disturb to', value, 'hours');
            setGlobalDoNotDisturbInterval(value * 60 * 60 * 1000);
        }
    };

    setGlobalDoNotDisturbCustom = (date: Date) => {
        const {
            profile,
            updateProfile,
            setGlobalDoNotDisturbInterval,
        } = this.props;

        if (isFutureDatetime(date.toISOString())) {
            updateProfile(profile, date);
            setGlobalDoNotDisturbInterval(date.getTime() - Date.now());
        }
    };

    setCustomDND = (date: Date) => {
        this.setState({ customDate: date, });
    }

    toggleDateTimePicker = (isOpen: boolean) => {
        this.setState({
            customDndOpen: isOpen,
        });
    }

    render() {
        const {
            profile,
        } = this.props;

        const {
            customDate,
            customDndOpen,
        } = this.state;

        const instructionText = isFutureDatetime(profile.globalDoNotDisturbEnd)
            ? 'Update global do not disturb option to...' : 'Turn on global do not disturb for...';

        const infiniteDndEnabled = profile.globalDoNotDisturbEnd === INFINITE_DND_CONSTANT;
        const buttonLabelText = infiniteDndEnabled ? 'Resume Notifications' : 'Enable Notifications';

        return (
            <div style={SidebarStyles.mainContainer}>
                <ReactTooltip />
                <BackButton
                    label="Close"
                    goBack={this.onClose}
                />
                <span style={SidebarStyles.titleText}>Settings</span>
                <div
                    style={SidebarStyles.helpTextContainer}
                >
                    <span style={SidebarStyles.infoText}>
                        Global do not disturb will mute notifications for all rooms except if another user
                        mentions you in a message or resends a notification.
                    </span>
                    <Fade collapse when={isFutureDatetime(profile.globalDoNotDisturbEnd)}>
                        <div style={SidebarStyles.centerContainer}>
                            {infiniteDndEnabled ? (
                                <>
                                    <span style={{ marginTop: 30, }} />
                                    <span style={SidebarStyles.suspendNotice}>
                                        Notifications manually suspended
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span style={{ marginTop: 30, }} />
                                    <span style={SidebarStyles.helpText}>
                                        You will not receive notifications for any rooms until:
                                    </span>
                                    <span style={SidebarStyles.muteNotice}>
                                        {formatDateTime(profile.globalDoNotDisturbEnd)}
                                    </span>
                                </>
                            )}
                        </div>
                        <div style={SidebarStyles.centerContainer}>
                            <Button
                                style={SidebarStyles.turnOffMuteButton}
                                label={buttonLabelText}
                                ripple={false}
                                onClick={() => this.setGlobalDoNotDisturb(0, false)}
                            />
                        </div>
                    </Fade>
                    <div style={{ marginTop: 30, }} />
                    <div style={SidebarStyles.borderTop} />
                    <div style={{ marginTop: 30, }} />
                    <div style={SidebarStyles.centerContainer}>
                        <span
                            style={SidebarStyles.helpText}
                        >
                            {instructionText}
                        </span>
                    </div>
                    <div style={{ marginTop: 15, }} />
                    <div style={SidebarStyles.centerContainer}>
                        <Button
                            style={SidebarStyles.muteTimeButton}
                            label="1 hour"
                            ripple={false}
                            onClick={() => this.setGlobalDoNotDisturb(1, false)}
                        />
                        <Button
                            style={SidebarStyles.muteTimeButton}
                            label="8 hours"
                            ripple={false}
                            onClick={() => this.setGlobalDoNotDisturb(8, false)}
                        />
                        <Button
                            style={SidebarStyles.muteTimeButton}
                            label="12 hours"
                            ripple={false}
                            onClick={() => this.setGlobalDoNotDisturb(12, false)}
                        />
                        <Button
                            style={SidebarStyles.muteTimeButton}
                            label="24 hours"
                            ripple={false}
                            onClick={() => this.setGlobalDoNotDisturb(24, false)}
                        />
                        <Button
                            style={SidebarStyles.muteTimeButton}
                            label="Until I turn it back on"
                            ripple={false}
                            onClick={() => this.setGlobalDoNotDisturb(0, true)}
                        />
                        <Button
                            style={SidebarStyles.muteTimeButton}
                            label="Custom"
                            ripple={false}
                            onClick={() => this.toggleDateTimePicker(true)}
                        />
                        <LocalizationProvider dateAdapter={DateAdapter}>
                            <MobileDateTimePicker
                                label="Do not disturb"
                                value={customDate}
                                onChange={(newValue) => this.setCustomDND(newValue)}
                                onAccept={(time) => this.setGlobalDoNotDisturbCustom(time)}
                                open={customDndOpen}
                                onClose={() => this.toggleDateTimePicker(false)}
                                renderInput={() => null}
                                minDateTime={Date.now()}
                                disableCloseOnSelect
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
});

export default connect(mapStateToProps, {
    updateProfile: _updateProfile,
})(Settings);
