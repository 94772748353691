/* eslint-disable camelcase */
// @flow
import React, { PureComponent } from 'react';
import { Row, Col } from 'react-flexbox-grid';

import ChatMessageStyles from './Styles/ChatMessageStyles';

type Props = {
    recipientContent: any,
    width: number,
};

class RecipientData extends PureComponent<Props> {
    render() {
        const {
            width,
        } = this.props;

        const {
            full_name,
            waitlist_number,
            sex,
            age,
            priority,
            cpra,
            mismatch_a,
            mismatch_b,
            mismatch_dr,
        } = this.props.recipientContent;

        // FIXME shouldn't have to explicity set width in order to prevent wrapping text
        return (
            <div style={{ width: width / 2, }}>
                <div style={ChatMessageStyles.offerTitle}>
                    <span style={ChatMessageStyles.offerTitleText}>Recipient Information</span>
                </div>
                <Row>
                    <Col xs={12}>
                        <span style={ChatMessageStyles.offerInfo}><b>Full Name: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{full_name}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <span style={ChatMessageStyles.offerInfo}><b>Age: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{age}</span>
                        <span style={ChatMessageStyles.offerInfo}> years</span>
                    </Col>
                    <Col xs={6}>
                        <span style={ChatMessageStyles.offerInfo}><b>Sex: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{sex}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <span style={ChatMessageStyles.offerInfo}><b>Priority: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{priority}</span>
                    </Col>
                    <Col xs={6}>
                        <span style={ChatMessageStyles.offerInfo}><b>Waitlist Number: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{waitlist_number}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <span style={ChatMessageStyles.offerInfo}><b>cPRA: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{cpra}</span>
                        <span style={ChatMessageStyles.offerInfo}>%</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Mismatch A: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{mismatch_a}</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Mismatch B: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{mismatch_b}</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Mismatch DR: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{mismatch_dr}</span>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default RecipientData;
