// @flow
import type { Logout } from 'txp-core';

import type { ResetData, ResetError } from './ApplicationActions';
import { resetErrorState, getReducerError } from './ApplicationActions';
import createUuid from '../Utils/createUuid';
import type { AttachmentData, UserProfile } from '../Utils/types';

type OfferState = {
    offerFile: ?AttachmentData,
    readOfferFile: string,
    candidateFile: ?AttachmentData,
    readCandidateFile: string,
    rooFile: ?AttachmentData,
    fileName: string,

    orgMembers: Array<UserProfile>,
    selectedOrgMembers: Array<number>,
    memberFilter: string,

    error: string,

    reducerErrors: Array<any>,
};

const initialState: OfferState = {
    offerFile: null,
    readOfferFile: '',
    candidateFile: null,
    readCandidateFile: '',
    rooFile: null,
    fileName: '',

    orgMembers: [],
    selectedOrgMembers: [],
    memberFilter: '',

    error: '',

    reducerErrors: [],
};

export type SetOfferFile = { type: 'Offer/SET_OFFER_FILE', offerFile: AttachmentData, readOfferFile: string };
export type UploadOfferFile = {
    type: 'Offer/UPLOAD_OFFER_FILE',
    offerFile: AttachmentData,
    readOfferFile: string,
    selectedOrgMembers: Array<number>,
    orgMembers: Array<UserProfile>,
};
export type SetCandidateFile = { type: 'Offer/SET_CANDIDATE_FILE', candidateFile: AttachmentData, readCandidateFile: string };
export type SetROOFile = { type: 'Offer/SET_ROO_FILE', rooFile: AttachmentData };
export type SetFileName = { type: 'Offer/SET_FILE_NAME', fileName: string };

export type UploadSuccess = { type: 'Offer/UPLOAD_SUCCESS' };
export type UploadFailed = { type: 'Offer/UPLOAD_FAILED', error: string };
export type ResetOfferError = { type: 'Offer/RESET' };

export type GetOrganizationMembers = { type: 'Offer/GET_ORG_MEMBERS' };
export type ReceiveOrgMembers = { type: 'Offer/RECEIVE_ORG_MEMBERS', orgMembers: Array<UserProfile> };

export type FilterOrgMembers = { type: 'Offer/FILTER', memberFilter: string };
export type SetSelectedOrgMembers = { type: 'Offer/SELECT_MEMBERS', selectedOrgMembers: Array<number> };
export type AddOrgMembersToChatroom = {
    type: 'Offer/ADD_ORG_MEMBERS',
    chatId: number,
    selectedOrgMembers: Array<number>,
    orgMembers: Array<UserProfile>,
};

type Action =
    | SetOfferFile
    | UploadOfferFile
    | SetCandidateFile
    | SetROOFile
    | SetFileName
    | UploadSuccess
    | UploadFailed
    | GetOrganizationMembers
    | ReceiveOrgMembers
    | FilterOrgMembers
    | SetSelectedOrgMembers
    | AddOrgMembersToChatroom
    | ResetOfferError
    | ResetError
    | Logout
    | ResetData;

export const setOfferFile = (offerFile: AttachmentData, readOfferFile: string): SetOfferFile => ({
    type: 'Offer/SET_OFFER_FILE',
    offerFile,
    readOfferFile,
});

export const uploadOfferFile = (
    offerFile: AttachmentData,
    readOfferFile: string,
    selectedOrgMembers: Array<number>,
    orgMembers: Array<UserProfile>
): UploadOfferFile => ({
    type: 'Offer/UPLOAD_OFFER_FILE',
    offerFile,
    readOfferFile,
    selectedOrgMembers,
    orgMembers,
});

export const setCandidateFile = (candidateFile: AttachmentData, readCandidateFile: string): SetCandidateFile => ({
    type: 'Offer/SET_CANDIDATE_FILE',
    candidateFile,
    readCandidateFile,
});

export const setROOFile = (rooFile: AttachmentData): SetROOFile => ({
    type: 'Offer/SET_ROO_FILE',
    rooFile,
});

export const setFileName = (fileName: string): SetFileName => ({
    type: 'Offer/SET_FILE_NAME',
    fileName,
});

export const uploadSuccess = (): UploadSuccess => ({
    type: 'Offer/UPLOAD_SUCCESS',
});

export const uploadFailed = (error: string): UploadFailed => ({
    type: 'Offer/UPLOAD_FAILED',
    error,
});

export const resetOfferError = (): ResetOfferError => ({
    type: 'Offer/RESET',
});

export const getOrganizationMembers = (): GetOrganizationMembers => ({
    type: 'Offer/GET_ORG_MEMBERS',
});

export const receiveOrgMembers = (orgMembers: Array<UserProfile>): ReceiveOrgMembers => ({
    type: 'Offer/RECEIVE_ORG_MEMBERS',
    orgMembers,
});

export const filterOrgMembers = (memberFilter: string): FilterOrgMembers => ({
    type: 'Offer/FILTER',
    memberFilter,
});

export const setSelectedOrgMembers = (selectedOrgMembers: Array<number>): SetSelectedOrgMembers => ({
    type: 'Offer/SELECT_MEMBERS',
    selectedOrgMembers,
});

export const addOrgMembersToChatroom = (
    chatId: number,
    selectedOrgMembers: Array<number>,
    orgMembers: Array<UserProfile>
): AddOrgMembersToChatroom => ({
    type: 'Offer/ADD_ORG_MEMBERS',
    chatId,
    selectedOrgMembers,
    orgMembers,
});

const reducer = (state: OfferState = initialState, action: Action): OfferState => {
    // wrap the whole reducer to catch any unexpected exceptions and record them
    // Another component will need to act upon these and potentially send them to Sentry
    try {
        return innerReducer(state, action);
    } catch (err) {
        const reducerErrors = state.reducerErrors.slice();
        const uuid = createUuid(0);
        reducerErrors.push(getReducerError(uuid, 'offer', action, err));
        return {
            ...state,

            reducerErrors,
        };
    }
};

const innerReducer = (state: OfferState = initialState, action: Action): OfferState => {
    switch (action.type) {
        case 'Application/RESET_ERROR':
            return resetErrorState(state, action);

        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            // Reset the store when logging out
            return {
                ...initialState,
            };

        case 'Offer/SET_OFFER_FILE':
            return {
                ...state,

                offerFile: action.offerFile,
                readOfferFile: action.readOfferFile,
            };

        case 'Offer/SET_FILE_NAME':
            return {
                ...state,

                fileName: action.fileName,
            };

        case 'Offer/SET_CANDIDATE_FILE':
            return {
                ...state,

                candidateFile: action.candidateFile,
                readCandidateFile: action.readCandidateFile,
            };

        case 'Offer/SET_ROO_FILE':
            return {
                ...state,

                rooFile: action.rooFile,
            };

        case 'Offer/UPLOAD_SUCCESS':
            return {
                ...state,

                offerFile: initialState.offerFile,
                readOfferFile: initialState.readOfferFile,
                candidateFile: initialState.candidateFile,
                readCandidateFile: initialState.readCandidateFile,
                rooFile: initialState.rooFile,
                error: initialState.error,
            };

        case 'Offer/UPLOAD_FAILED':
            return {
                ...state,

                error: action.error,
                offerFile: initialState.offerFile,
                readOfferFile: initialState.readOfferFile,
                candidateFile: initialState.candidateFile,
                readCandidateFile: initialState.readCandidateFile,
                rooFile: initialState.rooFile,
            };

        case 'Offer/RECEIVE_ORG_MEMBERS':
            return {
                ...state,

                orgMembers: action.orgMembers.slice(),
            };

        case 'Offer/FILTER':
            return {
                ...state,

                memberFilter: action.memberFilter,
            };

        case 'Offer/SELECT_MEMBERS':
            return {
                ...state,

                selectedOrgMembers: action.selectedOrgMembers.slice(),
            };

        case 'Offer/RESET':
            return {
                ...state,

                error: initialState.error,
            };

        default:
            return state;
    }
};

export default reducer;
