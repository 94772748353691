/* eslint-disable object-curly-newline */
// @flow
import {
    BOOLEAN, STRING, UI_TYPE_TEXT, UI_TYPE_SWITCH, KB_TYPE_NUMBER,
} from './FormTypes';

const formDef = {
    formId: 'ORIntestine',
    sections: [
        {
            id: 1,
            title: '',
            fields: [
                { id: 1, title: 'Not recovered', type: BOOLEAN, uiType: UI_TYPE_SWITCH, behavior: 'DISABLE-NOT-N', tags: ['N'], },
                { id: 2, title: 'Recovered, not for transplant', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 3, title: 'Recovery time', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_NUMBER, tags: [], },
                { id: 4, title: 'Anatomy obtained', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 5, title: 'Image of organ anatomy uploaded', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 6, title: 'Biopsy obtained', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 7, title: 'Image of biopsy uploaded', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 8, title: 'Biopsy report uploaded', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 9, title: 'Post-flush image uploaded', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 10, title: 'Back-table image uploaded', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 11, title: 'Transportation contacted', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 12, title: 'Organ enroute to accepting center', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: [], },
                { id: 13, title: 'Notes', type: STRING, uiType: UI_TYPE_TEXT, maxLines: 3, maxLength: 255, tags: ['N'], }
            ],
        }
    ],
    disableBehavior: {
        'DISABLE-NOT-N': 1,
    },
};

export default formDef;
