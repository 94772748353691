// @flow
import type { Saga } from 'redux-saga';
import {
    put,
    delay,
    select,
    call,
    all,
} from 'redux-saga/effects';
import {
    selectUserId,
    selectProfileName,
    selectProfileEmail,
} from 'txp-core';

import {
    inviteNewUserSuccess,
    inviteNewUserFailed,
    addSingleUserToChatroomFailed,
} from '../Redux/ChatroomActions';
import {
    apiFetch, apiPost, apiPut, apiDelete, apiCachedFetch,
} from './ApiSaga';
import { getPermissionsSaga } from './PermissionSaga';
import {
    DONOR_STATUS_VIEW, pushError, selectDonorView, setSagaMessage,
} from '../Redux/ApplicationActions';
import api from '../Services/Api';
import {
    finishLoading, finishLoadingEntity, startLoading, startLoadingEntity,
} from '../Redux/LoadingActions';
import { loadChatrooms, loadMessages } from '../Redux/ChatListActions';
import {
    createDonorFailed,
    receiveDonors,
    updateDonorSuccess,
    receiveDonorFiles,
    uploadDonorFileSuccess,
    uploadDonorFileFailed,
    deleteDonorFileSuccess,
    deleteDonorFileFailed,
    loadDonorFiles,
    resetDonorEditState,
    receiveOrganizationMembers,
    openFollowerGroup,
    receiveDonorFollowerGroups,
    receiveTasks,
    receiveSingleDonor,
    receiveDonorTask,
    getDonorTaskData,
    receiveDonorTaskData,
    createDonorSuccess,
    receiveCaseAdminUsers,
    receivePdfFields,
    updateFollowerGroupPermissions,
    updateFollowerGroupTasks,
    receiveCaseNotes,
    setCasePreferences,
    loadTasks,
    receiveTotalFilteredCases,
    setCaseDisplayOrder,
} from '../Redux/DonorActions';
import type {
    LoadDonorFiles,
    UploadDonorFile,
    DeleteDonorFile,
    CreateDonor,
    UpdateDonorStatus,
    UpdateDonor, LoadTasks, GetDonor, UpdateTaskStatus, GetDonorTask, GetDonorTaskData,
    UpdateTaskDueDate,
    UpdateTaskNote,
    UpdateDonorForm,
    DeleteFollowerGroup,
    UpdateFollowerGroupTasks,
    UpdateFollowerGroupPermissions,
    GetFollowerGroup,
    GetDonorFollowerGroups,
    GetCaseAdminUsers,
    SaveCaseNote,
    LoadCasePreferences,
    LoadCases,
} from '../Redux/DonorActions';
import {
    initFollowerEditData,
    resetFollowerEditData,
} from '../Redux/FollowerEditActions';
import {
    setSelectedUsers,
    setAddPeopleError,
} from '../Redux/AddPeopleActions';
import type {
    CreateFollowerGroup,
    CreateMultipleFollowerGroups,
    FollowerEditState, RemoveUserFromFollowers,
    UpdateFollowerGroup,
    InviteNewUser,
    JoinFollowerGroup,
} from '../Redux/FollowerEditActions';
import type {
    AddPeopleByEmail,
    AddPeopleByPhone,
    AddPeopleInvite,
} from '../Redux/AddPeopleActions';
import parseProfile, { parseExternalUser } from '../Utils/profile';
import { MessageTypes } from '../Utils/types';
import { keys, values } from '../Utils/Object';
import { ENTITY_TYPE_DONOR } from '../Utils/hasPermissions';
import { serializePhone } from '../Utils/serializePhone';
import type {
    DonorDataEntry,
    FollowerGroup,
    FormValueMap,
    StaticTask,
    Donor,
    RemoteMemberProfile,
    FormDefData,
    UserProfile,
} from '../Utils/types';
import hasValue from '../Utils/hasValue';
import formIndexMap from '../Redux/Forms/FormIndexMap';
import { formatDate, isoNowUTC, formatDateTimeToTZ } from '../Utils/time';
import { bulkReceiveFormData, setFormErrors } from '../Redux/FormActions';
import { parseError, singleErrorMessage, parseResponseTextError } from '../Utils/flattenError';
import { convertToTag } from '../Utils/tags';
import { convertToCaseNote } from '../Utils/caseNotes';

function* retrieveSingleDonorData(donorId: number) {
    const { result, error, } = yield apiFetch(api.txp.getDonor, {
        donorId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isInvalidResponseCode) {
            yield put(setSagaMessage('', parseResponseTextError(error.responseText), ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Loading donor failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Loading donor failed', 'Try again later', ''));
        }
        yield put(finishLoading('donor'));
    } else {
        const donId = result.donor_id;

        const organsArray = [];
        for (let i = 0; i < result.organs.length; i += 1) {
            organsArray.push({
                organId: result.organs[i].organ_id,
                organType: result.organs[i].organ_type,
                hasChatroom: result.organs[i].has_chatroom,
            });
        }

        const taskArray = [];
        for (let i = 0; i < result.tasks.length; i += 1) {
            taskArray.push({
                taskId: result.tasks[i].task_id,
                completed: result.tasks[i].completed,
                notApplicable: result.tasks[i].not_applicable,
                hasNoteContent: result.tasks[i].has_note_content,
                hasTaskData: result.tasks[i].has_task_data || false,
                lastModified: result.tasks[i].last_modified,
                lastModifiedBy: result.tasks[i].last_modified_by,
                dueDate: result.tasks[i].due_date,
                dueDateInterval: result.tasks[i].due_date_interval,
                previousTaskId: result.tasks[i].previous_task_id,
                userCanRead: result.tasks[i].user_can_read,
            });
        }
        let dob = '';
        if (result && result.date_of_birth && result.date_of_birth.length >= 10) {
            dob = result.date_of_birth.substring(0, 10);
        }

        const donorData = yield select((state) => state.donor.donors[donId]);
        const files = donorData && donorData.files ? donorData.files : {};
        const fileIds = donorData && donorData.fileIds ? donorData.fileIds : [];
        const pendingFiles = donorData && donorData.pendingFiles ? donorData.pendingFiles : {};

        const lastModifiedTaskId = donorData && donorData.lastModifiedTaskId ? donorData.lastModifiedTaskId : null;
        const lastModifiedTaskDate = donorData && donorData.lastModifiedTaskDate ? donorData.lastModifiedTaskDate : null;

        const donor = {
            ...donorData,
            donorId: donId,
            opoDonorId: result.opo_donor_id,
            highRisk: result.high_risk,
            donorType: result.donor_type,
            unosId: result.unos_id || '',
            userId: result.user_id,
            orgId: result.organization_id,
            createDate: result.create_date,
            closed: result.closed || false,
            organs: organsArray,
            currentLocation: result.current_location || '',
            dob,
            race: result.race || '',
            sex: result.sex || '',
            typeOfDeath: result.type_of_death || '',
            causeOfDeath: result.cause_of_death || '',
            height: hasValue(result.height) ? result.height.toString() : '',
            weight: hasValue(result.weight) ? result.weight.toString() : '',
            unetLink: result.unet_link || '',
            tasks: taskArray,
            lastModified: result.last_modified,
            lastModifiedBy: result.last_modified_by,
            workflow: result.workflow,
            lastModifiedTaskId,
            lastModifiedTaskDate,
            files,
            fileIds,
            pendingFiles,
            tags: result.tags ? result.tags.map((t) => convertToTag(t)) : [],
            notes: result.notes ? result.notes.map((note) => convertToCaseNote(note)) : [],
        };

        yield put(receiveSingleDonor(donId, donor));
    }
}

export function* loadCasesSaga(action: LoadCases): Saga<void> {
    const { params, } = action;
    yield put(startLoading('cases'));

    const { result, error, } = yield apiPost(api.txp.cases, {}, { ...params, });

    const currentDonors = yield select((state) => state.donor.donors);
    const openDonorId = yield select((state) => state.donor.openDonorId);

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Loading cases failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Loading cases failed', 'Try again later', ''));
        }
        yield put(finishLoading('cases'));
    } else {
        const casesArray = result.cases;
        const totalFilteredCases = parseInt(result.total_cases_count, 10);
        const caseDisplayOrder = [];

        // Retain currently open donor for instances when navigating to a donor
        // before donors have been loaded
        const cases = openDonorId ? { [openDonorId]: currentDonors[openDonorId], } : {};

        for (let i = 0; i < casesArray.length; i += 1) {
            caseDisplayOrder.push(casesArray[i].donor_id);
            const organsArray = casesArray[i].organs;
            const organs = [];
            const currentDonor = currentDonors[casesArray[i].donor_id] || {};

            for (let j = 0; j < organsArray.length; j += 1) {
                organs.push({
                    organId: organsArray[j].organ_id,
                    organType: organsArray[j].organ_type,
                });
            }
            let dob = '';
            if (casesArray[i].date_of_birth && casesArray[i].date_of_birth.length >= 10) {
                dob = casesArray[i].date_of_birth.substring(0, 10);
            }

            // Build our task objects
            const tasks = (casesArray[i].tasks || []).map((task) => ({
                completed: task.completed,
                description: task.description,
                dueDate: task.due_date,
                dueDateInterval: task.due_date_interval,
                hasNoteContent: task.has_note_content,
                hasTaskData: task.has_task_data,
                lastModified: task.last_modified,
                lastModifiedBy: task.last_modified_by,
                notApplicable: task.not_applicable,
                previousTaskId: task.previous_task_id,
                taskId: task.task_id,
                userCanRead: task.user_can_read,
            }));

            cases[casesArray[i].donor_id] = {
                ...currentDonor,
                donorId: casesArray[i].donor_id,
                opoDonorId: casesArray[i].opo_donor_id,
                highRisk: casesArray[i].high_risk,
                donorType: casesArray[i].donor_type,
                unosId: casesArray[i].unos_id,
                orgId: casesArray[i].organization_id,
                userId: casesArray[i].user_id,
                createDate: casesArray[i].create_date,
                closed: casesArray[i].closed,
                currentLocation: casesArray[i].current_location || '',
                dob,
                sex: casesArray[i].sex || '',
                organs,
                lastModified: casesArray[i].last_modified,
                lastModifiedBy: casesArray[i].last_modified_by,
                lastModifiedTaskDate: casesArray[i].last_modified_task_date,
                lastModifiedTaskId: casesArray[i].last_modified_task_id,
                workflow: casesArray[i].workflow,
                tags: casesArray[i].tags ? casesArray[i].tags.map((t) => convertToTag(t)) : [],
                notes: casesArray[i].notes ? casesArray[i].notes.map((note) => convertToCaseNote(note)) : [],
                tasks,
                isAdmin: casesArray[i].is_admin,
            };
        }

        yield put(receiveDonors(cases));
        yield put(receiveTotalFilteredCases(totalFilteredCases));
        yield put(setCaseDisplayOrder(caseDisplayOrder));
        yield put(finishLoading('cases'));
    }
}

export function* getDonorSaga(action: GetDonor): Saga<void> {
    const {
        donorId,
    } = action;

    yield put(startLoading('donor'));
    yield retrieveSingleDonorData(donorId);
    yield put(finishLoading('donor'));
}

export function* updateDonorStatusSaga(action: UpdateDonorStatus): Saga<void> {
    const {
        donorId,
        closed,
        lastModified,
    } = action;

    const {
        result,
        error,
    } = yield apiPut(api.txp.updateDonorStatus, {
        donorId,
    }, {
        closed,
        last_modified: lastModified,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isInvalidResponseCode) {
            yield put(pushError(parseResponseTextError(error.responseText)));
        } else if (error.isNetworkError) {
            yield put(pushError(closed ? 'Closing donor failed, are you online?' : 'Reopening donor failed, are you online?', error));
        } else {
            yield put(pushError(closed ? 'Closing donor failed, try again later' : 'Reopening donor failed, try again later', error));
        }
    } else {
        const currentDonors = yield select((state) => state.donor.donors || {});

        currentDonors[donorId] = {
            ...currentDonors[donorId],

            closed,
            lastModified: result.last_modified,
        };

        yield put(updateDonorSuccess(donorId, currentDonors[donorId]));

        const toastMessage = closed ? 'Donor closed!' : 'Donor reopened!';
        yield put(setSagaMessage('', toastMessage, ''));
    }
}

export function* createDonorSaga(action: CreateDonor): Saga<void> {
    const {
        opoDonorId,
        donorType,
        workflowKey,
        highRisk,
        tags,
        hospital,
        sex,
        dateOfBirth,
        organs,
        importedDonorData,
    } = action;
    let {
        unosId,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    //  https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('saveDonor'));

    let processedImportedDonorData: DonorDataEntry[] = [];
    let timeOfDeathString = '';
    let race = '';
    let bloodType = '';
    let height = null;
    let weight = null;
    let causeOfDeath = '';
    let typeOfDeath = '';

    // We Timezone and DST to properly construct timestamps for imported pdf data
    if (importedDonorData && importedDonorData.length) {
        const timezoneImport = importedDonorData.find((data) => data.key === 'time_zone');
        const dstImport = importedDonorData.find((data) => data.key === 'dst_observed');

        // Convert date fields into ISO strings if timezone and daylight savings information available
        if (timezoneImport && dstImport) {
            const tz = timezoneImport.value;
            const dst = dstImport.value.toLowerCase() === 'yes';

            processedImportedDonorData = importedDonorData.map((entry) => {
                if (entry.key.endsWith('_date')) {
                    if (entry.key === 'or_date') {
                        // OR Date can end with 'Scheduled' or 'Tentative' and cause the date to be invalid, try to trim those
                        entry.value = entry.value.replace(/scheduled|tentative/gi, '').trim();
                    }
                    entry.value = entry.value ? formatDateTimeToTZ(entry.value, tz, dst) : '';
                }

                return entry;
            });
        } else {
            processedImportedDonorData = importedDonorData;
        }

        const timeOfDeathImport = processedImportedDonorData.find((data) => data.key === 'death_date');
        if (timeOfDeathImport) {
            timeOfDeathString = timeOfDeathImport.value;
        }

        const unosImport = processedImportedDonorData.find((data) => data.key === 'unos_id');
        if (unosImport) {
            unosId = unosImport.value;
        }

        const raceImport = processedImportedDonorData.find((data) => data.key === 'race');
        if (raceImport) {
            race = raceImport.value;
        }

        const bloodTypeImport = processedImportedDonorData.find((data) => data.key === 'blood_type');
        if (bloodTypeImport) {
            bloodType = bloodTypeImport.value;
        }

        const heightImport = processedImportedDonorData.find((data) => data.key === 'height_cm');
        if (heightImport) {
            const heightVal = heightImport.value ? parseFloat(heightImport.value.split()[0]) : null;
            height = Number.isNaN(heightVal) ? null : heightVal;
        }

        const weightImport = processedImportedDonorData.find((data) => data.key === 'weight_kg');
        if (weightImport) {
            const weightVal = weightImport.value ? parseFloat(weightImport.value.split()[0]) : null;
            weight = Number.isNaN(weightVal) ? null : weightVal;
        }

        const codImport = processedImportedDonorData.find((data) => data.key === 'cause_of_death');
        if (codImport) {
            causeOfDeath = codImport.value;
        }

        // We store type of death as either DCD or DBD
        const dcdImport = processedImportedDonorData.find((data) => data.key === 'dcd');
        if (dcdImport) {
            typeOfDeath = dcdImport.value ? (dcdImport.value.toLowerCase() === 'yes' ? 'DCD' : 'BD') : 'Unknown';
        }
    }

    const { result, error, } = yield apiPost(api.txp.createCase, null, {
        opo_donor_id: opoDonorId,
        donor_type: donorType,
        current_location: hospital,
        sex: sex || null,
        date_of_birth: dateOfBirth !== '' ? formatDate(dateOfBirth) : null,
        unos_id: unosId,
        organs,
        workflow: workflowKey,
        high_risk: highRisk,
        tags: tags.map((t) => t.tagId),
        donor_data: processedImportedDonorData,
        donor_hospital: hospital,
        time_of_death: timeOfDeathString || null,
        race: race || null,
        blood_type: bloodType || null,
        height: height || null,
        weight: weight || null,
        cause_of_death: causeOfDeath || null,
        type_of_death: typeOfDeath || null,
    });

    if (error) {
        if (error.isInvalidResponseCode && error.statusCode === 409) {
            const errorMessage = 'A donor with this Ref. ID has already been created';
            yield put(createDonorFailed({
                nonFieldErrors: errorMessage,
            }));
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isNetworkError) {
            const errorMessage = 'Creating donor failed, are you online?';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else {
            const errorMessage = 'Creating donor failed, try again later';
            yield put(pushError(errorMessage, error));
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        }
        yield put(finishLoading('saveDonor'));
    } else {
        // As mentioned in Redmine issue 5558, as a way to ensure PHI is shared with the necessary people,
        // we removed attaching the PDF file that used to be performed here. This can return again if we
        // introduce a more concrete permissions structure
        const donorResult = result.case;
        const donorId = donorResult.donor_id;
        const currentDonors = yield select((state) => state.donor.donors);

        const organsArray = [];
        for (let i = 0; i < donorResult.organs.length; i += 1) {
            organsArray.push({
                organId: donorResult.organs[i].organ_id,
                organType: donorResult.organs[i].organ_type,
            });
        }

        currentDonors[donorId] = {
            donorId,
            opoDonorId: donorResult.opo_donor_id,
            highRisk: donorResult.high_risk,
            tags: donorResult.tags ? donorResult.tags.map((t) => convertToTag(t)) : [],
            unosId: donorResult.unos_id,
            userId: donorResult.user_id,
            createDate: donorResult.create_date,
            closed: false,
            organs: organsArray,
            workflow: workflowKey,
        };

        yield put(receiveDonors(currentDonors));
        yield put(resetDonorEditState());
        yield call(getPermissionsSaga);

        // NOTE: I'm just using the saga to avoid timing/race issues
        // This should wait to complete before setting createDonorSuccess
        yield loadCasePreferencesSaga({
            type: 'Donor/LOAD_CASE_PREFERENCES',
            workflow: workflowKey,
            caseId: donorId,
        });
        // we also need workflowTasks when displaying CasePreferences
        yield put(loadTasks(workflowKey, donorId));

        yield put(selectDonorView(DONOR_STATUS_VIEW));
        yield put(finishLoading('saveDonor'));
        yield put(createDonorSuccess(donorId));
    }
}

export function* updateDonorSaga(action: UpdateDonor): Saga<void> {
    const {
        donorId,
        lastModified,
        opoDonorId,
        highRisk,
        tags,
        unosId,
        currentLocation,
        dob,
        typeOfDeath,
        causeOfDeath,
        race,
        sex,
        weight,
        height,
        organs,
    } = action;

    yield put(startLoading('updateDonor'));
    const memberId = yield select((state) => selectUserId(state.auth));

    let isCaseNameEdited = false;
    const currentDonors = yield select((state) => state.donor.donors);
    if (currentDonors[donorId] && currentDonors[donorId].opoDonorId !== opoDonorId) {
        isCaseNameEdited = true;
    }

    const { result, error, } = yield apiPut(api.txp.updateDonor, {
        donorId,
    }, {
        last_modified: lastModified,
        opo_donor_id: opoDonorId,
        high_risk: highRisk,
        tags: tags.map((t) => t.tagId),
        unos_id: unosId,
        organs,
        current_location: currentLocation,
        date_of_birth: hasValue(dob) ? formatDate(dob) : null,
        type_of_death: typeOfDeath === '' ? null : typeOfDeath,
        cause_of_death: causeOfDeath === '' ? null : causeOfDeath,
        race: race !== '' ? race : null,
        sex: sex !== '' ? sex : null,
        height: height !== '' ? parseFloat(height) : null,
        weight: weight !== '' ? parseFloat(weight) : null,
        unet_link: '',
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isInvalidResponseCode) {
            yield put(setSagaMessage('', parseResponseTextError(error.responseText), ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Updating donor failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Updating donor failed', 'Try again later', ''));
        }
        yield put(finishLoading('updateDonor'));
    } else {
        const closed = yield select((state) => state.donor.donors[donorId].closed || false);
        const orgId = yield select((state) => state.donor.donors[donorId].orgId || -1);

        const resOrgans = result.organs;

        const organArray = [];
        for (let i = 0; i < resOrgans.length; i += 1) {
            organArray.push({
                organId: resOrgans[i].organ_id,
                organType: resOrgans[i].organ_type,
                hasChatroom: result.organs[i].has_chatroom,
            });
        }

        let incomingDOB = '';
        if (result && result.date_of_birth && result.date_of_birth.length > 10) {
            incomingDOB = result.date_of_birth.substring(0, 10);
        }

        const donorFiles = yield select((state) => state.donor.donors[donorId].files || {});
        const donorFileIds = yield select((state) => state.donor.donors[donorId].fileIds || []);

        const workflow = yield select((state) => state.donor.donors[donorId].workflow) || null;
        const lastModifiedTaskId = yield select((state) => state.donor.donors[donorId].lastModifiedTaskId) || null;
        const lastModifiedTaskDate = yield select((state) => state.donor.donors[donorId].lastModifiedTaskDate) || null;

        const updatedDonor = {
            donorId,
            donorType: result.donor_type,
            opoDonorId: result.opo_donor_id,
            highRisk: result.high_risk,
            tags: result.tags ? result.tags.map((t) => convertToTag(t)) : [],
            notes: result.notes ? result.notes.map((note) => convertToCaseNote(note)) : [],
            unosId: result.unos_id || '',
            userId: result.user_id,
            orgId,
            createDate: result.create_date,
            closed,
            organs: organArray,
            currentLocation: result.current_location || '',
            dob: incomingDOB,
            race: result.race || '',
            sex: result.sex || '',
            typeOfDeath: result.type_of_death || '',
            causeOfDeath: result.cause_of_death || '',
            height: hasValue(result.height) ? result.height.toString() : '',
            weight: hasValue(result.weight) ? result.weight.toString() : '',
            unetLink: result.unet_link || '',
            files: donorFiles,
            fileIds: donorFileIds,
            pendingFiles: {},
            lastModified: result.last_modified,
            lastModifiedBy: memberId,
            workflow,
            lastModifiedTaskId,
            lastModifiedTaskDate,
            isAdmin: true, //  only admins can update case info, meaning this flag should be true here
        };

        yield put(setSagaMessage('', 'Case updated!', ''));

        yield put(updateDonorSuccess(donorId, updatedDonor));

        if (isCaseNameEdited) {
            yield put(loadChatrooms());
        }

        yield put(finishLoading('updateDonor'));
    }
}

function getMandatoryFieldIds(formDef: any): Array<number> {
    const mandatoryFieldIds = [];
    if (!formDef) return mandatoryFieldIds;
    for (let i = 0; i < formDef.sections.length; i += 1) {
        const sectionDef = formDef.sections[i];
        // check each field
        for (let j = 0; j < sectionDef.fields.length; j += 1) {
            // to see if it is mandatory. if it is, add it to the list of mandatory values to check for
            if (sectionDef.fields[j].mandatory) {
                mandatoryFieldIds.push(sectionDef.fields[j].id);
            }
        }
    }
    return mandatoryFieldIds;
}

// function getPdfFields(action: ReceivePdfFields): Saga<void> {
export function* getPdfFieldsSaga(): Saga<void> {
    // yield put(startLoading('ReceivePdfFields'));
    const { result, error, } = yield apiFetch(api.txp.getPdfFields);

    // error handling
    if (error) {
        yield put(setSagaMessage('', 'Receive PDF Fields failed', ''));
    } else {
        yield put(receivePdfFields(result));
    }

    // yield put(finishLoading('ReceivePdfFields'));
}

function areMandatoryFieldsPresent(mandatoryFieldIds, formData: any): boolean {
    if (!formData) return false;

    for (let i = 0; i < mandatoryFieldIds.length; i += 1) {
        const mandatoryFieldId = mandatoryFieldIds[i];
        // check each field
        const thisField = formData[mandatoryFieldId];
        if (!thisField) return false;
        if (!thisField.value) return false;
        if (thisField.value.length === 0) return false;
    }
    return true;
}

function* isMandatoryDataMissing(donorId: number, taskId: number, fieldValueMap: ?FormValueMap, formDef: FormDefData) {
    const donorState = yield select((state) => state.donor);
    const donor = donorState.donors[donorId];
    const workflow = donor.workflow || 'LEGACY';
    const { tasks, } = donorState.workflowTasks[workflow];
    const taskIndex = tasks.findIndex((x) => x.taskId === taskId);
    const taskDescription = tasks.length > 0 && taskIndex !== -1 ? tasks[taskIndex].details.description : 'Could not find task';
    const tempFormId = taskIndex !== -1 ? tasks[taskIndex].formId : null;
    const formId = tempFormId || formIndexMap[taskDescription];
    // If there is no form associated with the task then just return false
    if (!formId) return false;

    const formDefRedux = yield select((state) => state.forms.formDef);
    /* eslint-disable-next-line no-unused-vars */
    const formDefRef = formDef || formDefRedux;
    const thisFormDef = formDefRef[formId];

    const thisFormData = fieldValueMap;
    let mandatoryFieldError = false;
    const mandatoryFieldIds = getMandatoryFieldIds(thisFormDef);
    if (mandatoryFieldIds.length > 0) {
        if (!mandatoryFieldError && !areMandatoryFieldsPresent(mandatoryFieldIds, thisFormData)) {
            mandatoryFieldError = true;
        }
    }
    return mandatoryFieldError;
}

export function* loadCasePreferencesSaga(action: LoadCasePreferences): Saga<void> {
    const {
        workflow,
        caseId,
    } = action;
    // First get all the donors
    const allDonors = yield select((state) => state.donor.donors);
    const allDonorsArray: Donor[] = values(allDonors);
    const allTeams = yield select((state) => state.team.teams);

    // Get the follower groups for the current case (if any)
    const currentCase: Donor = yield select((state) => state.donor.donors[caseId]);
    const currentFollowers = currentCase ? currentCase.followerGroups || {} : {};
    const followerKeys = Object.keys(currentFollowers);
    const currentFollowerNames = followerKeys.map((key) => currentFollowers[parseInt(key, 10)].name);
    const { result, } = yield apiCachedFetch(['workflowDefinition', workflow], api.txp.workflowDefinition, {
        key: workflow,
    });
    const currentWorkflow = result?.workflow;
    const casePreferences = currentWorkflow?.case_preferences;

    if (casePreferences?.preferences?.followers?.length) {
        const preferences: FollowerEditState[] = [];
        for (let fIdx = 0; fIdx < casePreferences.preferences.followers.length; fIdx += 1) {
            const followerPref = casePreferences.preferences.followers[fIdx];
            if (!currentFollowerNames.includes(followerPref.name)) {
                // We need to build up our pending users, check defined teams
                const prefUsers: UserProfile[] = [];
                followerPref.teams.forEach((teamId: number) => {
                    // Try to identify our team from state and load members from there
                    const team = allTeams.find((t) => t.teamId === teamId);

                    if (team) {
                        team.teamMembers.forEach((tm) => {
                            if (!prefUsers.some((u) => u.userId === tm.userId)) {
                                prefUsers.push(tm);
                            }
                        });
                    }
                });

                // NOTE: For now, there is no way to define individual members to be specified in a case
                //     : follower preference, so we will ignore that at this time until we need to adjust
                // Build our FollowerEditState objects for the follower preferences
                preferences.push({
                    followerGroupId: (fIdx + 1),
                    followerName: followerPref.name,
                    canUpdate: followerPref.canEdit,
                    taskIds: followerPref.tasks,
                    pendingUsers: prefUsers,
                    pendingRemovedUsers: [],
                    followerHasChanged: false,
                });
            }
        }

        yield put(setCasePreferences(preferences));
    } else {
        // The defined workflow does not have any case preference followers specified, so go
        // the old route of looking for the most recently created case of the same workflow
        // Then sort and filter the donors, to get the most recent donor for the workflow
        const sortedAndFilteredDonors = allDonorsArray.filter((donor) => donor.donorId !== caseId && donor.workflow === workflow)
            .sort((a, b) => (new Date(b.createDate)) - (new Date(a.createDate)));
        if (sortedAndFilteredDonors.length > 0) {
            // NOTE: currently just looking at the last 5 cases to get the case preferences
            // Eventually we'll just get the preferences from a workflow-defined object
            const mostRecentDonors = sortedAndFilteredDonors.slice(0, 5);
            const mostRecentIds = mostRecentDonors.map((donor) => donor.donorId);
            // Make the request to get all follower info for that donor
            yield getCasePreferencesFromCases(mostRecentIds, currentFollowerNames);
        }
    }
}

function* getCasePreferencesFromCases(caseIds: number[], currentFollowerNames: string[]): Saga<void> {
    // NOTE: For now, we'll just take the first case that has followers to get preferences/presets
    for (let caseIdx = 0; caseIdx < caseIds.length; caseIdx += 1) {
        const caseId = caseIds[caseIdx];
        const { result, } = yield apiFetch(api.txp.caseFollowersDetailed, { caseId, });
        const casePreferences: FollowerEditState[] = [];
        if (result && result.followers && result.followers.length > 0) {
            for (let followerIdx = 0; followerIdx < result.followers.length; followerIdx += 1) {
                const follower = result.followers[followerIdx];
                // Only add to the preferences if there isn't already a group with this name
                if (!currentFollowerNames.includes(follower.name)) {
                    casePreferences.push({
                        followerGroupId: follower.follower_id,
                        followerName: follower.name,
                        canUpdate: follower.can_update,
                        taskIds: follower.task_ids,
                        pendingUsers: follower.member_users.map((user) => parseProfile(user)),
                        pendingRemovedUsers: [], // NOTE: we won't need prendingRemovedUsers for editing case preferences
                        followerHasChanged: false,
                    });
                }
            }

            yield put(setCasePreferences(casePreferences));
            break;
        }
    }
}

export function* updateTaskNoteSaga(action: UpdateTaskNote): Saga<void> {
    const {
        donorId,
        taskId,
        noteContent,
        lastModified,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    //  https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);
    yield put(startLoading('donorTask'));
    const memberId = yield select((state) => selectUserId(state.auth));

    const { result, error, } = yield apiPut(api.txp.updateNote, {
        donorId,
        taskId,
    }, {
        note_content: noteContent,
        last_modified: lastModified !== '' ? lastModified : null,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isInvalidResponseCode) {
            yield put(pushError(parseResponseTextError(error.responseText)));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating task note failed, are you online?', error));
        } else {
            yield put(pushError('Updating task note failed, try again later', error));
        }
    } else {
        const historyArray = [];
        for (let i = result.history.length - 1; i >= 0; i -= 1) {
            historyArray.push({
                userId: result.history[i].user_id,
                updateTime: result.history[i].update_time,
                completed: result.history[i].completed,
                notApplicable: result.history[i].not_applicable,
                noteContent: result.history[i].note_content || '',
            });
        }

        const taskData = [];
        const taskDataKeys = result.task_data ? keys(result.task_data) : [];
        for (let i = 0; i < taskDataKeys.length; i += 1) {
            taskData.push({
                fieldId: taskDataKeys[i],
                data: result.task_data[taskDataKeys[i]],
            });
        }

        const donorTask = {
            donorId: result.donor_id,
            taskId: result.task_id,
            completed: result.completed,
            notApplicable: result.not_applicable,
            noteContent: result.note_content || '',
            previousTaskId: result.previous_task_id,
            dueDateInterval: result.due_date_interval,
            dueDate: result.due_date,
            history: historyArray,
            lastModified: result.last_modified,
            lastModifiedBy: memberId,
        };

        const donor = yield select((state) => state.donor.donors[donorId]);
        const workflow = donor.workflow || 'LEGACY';
        const allTasks = yield select((state) => state.donor.workflowTasks[workflow].tasks);
        const taskIndex = allTasks.findIndex((x) => x.taskId === taskId);
        const taskDescription = taskIndex !== -1 ? allTasks[taskIndex].details.description : null;
        const tempFormId = taskIndex !== -1 ? allTasks[taskIndex].formId : null;
        const formId = tempFormId || formIndexMap[taskDescription];

        const formUpdated = yield select((state) => state.forms.formUpdated);

        if (hasValue(formId) && !formUpdated) {
            yield put(bulkReceiveFormData(formId, taskData));
        }

        yield put(receiveDonorTask(donorTask));

        const currentDonors = yield select((state) => state.donor.donors || {});
        const oldTasks = yield select((state) => state.donor.donors[donorId].tasks || []);
        const tasks = oldTasks.slice(0);

        let index = tasks.findIndex((x) => x.taskId === taskId);
        if (index === -1) {
            tasks.push({
                taskId,
                completed: null,
                notApplicable: null,
            });
            index = tasks.length - 1;
        }

        tasks[index].hasNoteContent = true;
        tasks[index].lastModified = result.last_modified;

        currentDonors[donorId] = {
            ...currentDonors[donorId],

            tasks,
        };

        yield put(receiveDonors(currentDonors));
    }
    yield put(finishLoading('donorTask'));
}

export function* updateTaskStatusSaga(action: UpdateTaskStatus): Saga<void> {
    const {
        donorId,
        taskId,
        lastModified,
        fieldValueMap,
        completed,
        notApplicable,
        fromList,
        formDef,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    //  https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    // On task completion only, check for mandatory fields. Setting n/a or incomplete do not need to do this
    if (completed) {
        const mandatoryFieldError = yield isMandatoryDataMissing(donorId, taskId, fieldValueMap, formDef);
        if (mandatoryFieldError) {
            yield put(setSagaMessage('', 'Cannot mark this task as complete without first completing all mandatory fields', 'OK', true));
            return;
        }
    }

    const alreadyUpdating = yield select((state) => state.loading.taskStatus[`${taskId}`]);
    if (alreadyUpdating) {
        return;
    }

    const payload = {
        completed,
        not_applicable: notApplicable,
        last_modified: lastModified !== '' ? lastModified : null, // Send in null for tasks that have never been modified
        task_data: fieldValueMap,
    };

    // don't send in task_data at all if not provided (most tasks don't have a form)
    if (!fieldValueMap) delete payload.task_data;

    yield put(startLoadingEntity('taskStatus', taskId));
    const memberId = yield select((state) => selectUserId(state.auth));

    const { result, error, } = yield apiPut(api.txp.updateStatus, {
        donorId,
        taskId,
    }, payload);

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isInvalidResponseCode) {
            yield put(setSagaMessage('', parseResponseTextError(error.responseText), ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Updating task status failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Updating task status failed', 'Try again later', ''));
        }
        yield put(finishLoadingEntity('taskStatus', taskId));
    } else {
        if (!fromList) {
            const historyArray = [];
            for (let i = result.history.length - 1; i >= 0; i -= 1) {
                historyArray.push({
                    userId: result.history[i].user_id,
                    updateTime: result.history[i].update_time,
                    completed: result.history[i].completed,
                    notApplicable: result.history[i].not_applicable,
                    noteContent: result.history[i].note_content || '',
                });
            }

            const taskData = [];
            const taskDataKeys = result.task_data ? keys(result.task_data) : [];
            for (let i = 0; i < taskDataKeys.length; i += 1) {
                taskData.push({
                    fieldId: taskDataKeys[i],
                    data: result.task_data[taskDataKeys[i]],
                });
            }

            const donorTask = {
                donorId: result.donor_id,
                taskId: result.task_id,
                completed: result.completed,
                notApplicable: result.not_applicable,
                noteContent: result.note_content || '',
                previousTaskId: result.previous_task_id,
                dueDateInterval: result.due_date_interval,
                dueDate: result.due_date,
                history: historyArray,
                lastModified: result.last_modified,
                lastModifiedBy: memberId,
            };

            const donor = yield select((state) => state.donor.donors[donorId]);
            const workflow = donor.workflow || 'LEGACY';
            const tasks = yield select((state) => state.donor.workflowTasks[workflow].tasks);
            const index = tasks.findIndex((x) => x.taskId === taskId);
            const taskDescription = index !== -1 ? tasks[index].details.description : null;
            const tempFormId = index !== -1 ? tasks[index].formId : null;
            const formId = tempFormId || formIndexMap[taskDescription];

            if (hasValue(formId) && fieldValueMap) {
                yield put(bulkReceiveFormData(formId, taskData));
            }

            yield put(receiveDonorTask(donorTask));
        }

        const currentDonors = yield select((state) => state.donor.donors || {});
        const oldTasks = yield select((state) => state.donor.donors[donorId].tasks || []);
        const tasks = oldTasks.slice();

        const index = tasks.findIndex((x) => x.taskId === taskId);
        if (index !== -1) {
            tasks[index].completed = result.completed;
            tasks[index].notApplicable = result.not_applicable;
            tasks[index].lastModified = result.last_modified;
            tasks[index].hasTaskData = result.task_data ? keys(result.task_data).length > 0 : 0;
        } else {
            tasks.push({
                taskId,
                completed: result.completed,
                notApplicable: result.not_applicable,
                hasNoteContent: false,
                hasTaskData: fieldValueMap !== null,
                lastModified: result.last_modified,
                lastModifiedBy: memberId,
            });
        }

        if (completed) {
            currentDonors[donorId] = {
                ...currentDonors[donorId],

                lastModifiedTaskId: taskId,
                lastModifiedTaskDate: isoNowUTC(),
                tasks,
            };
        } else {
            currentDonors[donorId] = {
                ...currentDonors[donorId],

                tasks,
            };
        }

        yield put(receiveDonors(currentDonors));
        // NOTE: retrieve all data for this donor after updating the status, in case other task
        // due dates depend on this task that has been updated
        yield retrieveSingleDonorData(donorId);
        yield put(getDonorTaskData(donorId));

        let toastMessage = 'Status updated!';

        if (notApplicable !== null) {
            if (notApplicable) {
                toastMessage = 'Marked as not applicable!';
            } else {
                toastMessage = 'Marked as applicable!';
            }
        } else if (completed !== null) {
            if (completed) {
                toastMessage = 'Marked as complete!';
            } else {
                toastMessage = 'Marked as incomplete!';
            }
        }

        yield put(finishLoadingEntity('taskStatus', taskId));

        yield put(setSagaMessage('', toastMessage, ''));
    }
}

export function* updateTaskDueDateSaga(action: UpdateTaskDueDate): Saga<void> {
    const {
        donorId,
        taskId,
        previousTaskId,
        interval,
        lastModified,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    //  https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);
    yield put(startLoading('donorTask'));

    const memberId = yield select((state) => selectUserId(state.auth));

    const { result, error, } = yield apiPut(api.txp.updateDonorTaskDueDate, {
        donorId,
        taskId,
    }, {
        previous_task_id: previousTaskId,
        due_date_interval: interval,
        last_modified: lastModified !== '' ? lastModified : null,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isInvalidResponseCode) {
            yield put(pushError(parseResponseTextError(error.responseText)));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating form failed, are you online?', error));
        } else {
            yield put(pushError('Updating form failed, try again later', error));
        }
    } else {
        const historyArray = [];
        for (let i = result.history.length - 1; i >= 0; i -= 1) {
            historyArray.push({
                userId: result.history[i].user_id,
                updateTime: result.history[i].update_time,
                completed: result.history[i].completed,
                notApplicable: result.history[i].not_applicable,
                noteContent: result.history[i].note_content || '',
            });
        }

        const taskData = [];
        const taskDataKeys = result.task_data ? keys(result.task_data) : [];
        for (let i = 0; i < taskDataKeys.length; i += 1) {
            taskData.push({
                fieldId: taskDataKeys[i],
                data: result.task_data[taskDataKeys[i]],
            });
        }

        const donorTask = {
            donorId: result.donor_id,
            taskId: result.task_id,
            completed: result.completed,
            notApplicable: result.not_applicable,
            noteContent: result.note_content || '',
            previousTaskId: result.previous_task_id,
            dueDateInterval: result.due_date_interval,
            dueDate: result.due_date,
            history: historyArray,
            lastModified: result.last_modified,
            lastModifiedBy: memberId,
        };

        const donor = yield select((state) => state.donor.donors[donorId]);
        const workflow = donor.workflow || 'LEGACY';
        const allTasks = yield select((state) => state.donor.workflowTasks[workflow].tasks);
        const taskIndex = allTasks.findIndex((x) => x.taskId === taskId);
        const taskDescription = taskIndex !== -1 ? allTasks[taskIndex].details.description : null;
        const tempFormId = taskIndex !== -1 ? allTasks[taskIndex].formId : null;
        const formId = tempFormId || formIndexMap[taskDescription];

        if (hasValue(formId)) {
            yield put(bulkReceiveFormData(formId, taskData));
        }

        yield put(receiveDonorTask(donorTask));

        const currentDonors = yield select((state) => state.donor.donors || {});
        const oldTasks = yield select((state) => state.donor.donors[donorId].tasks || []);
        const tasks = oldTasks.slice(0);

        let index = tasks.findIndex((x) => x.taskId === taskId);
        if (index === -1) {
            tasks.push({
                taskId,
                completed: null,
                notApplicable: null,
                hasNoteContent: false,
                hasTaskData: true,
            });
            index = tasks.length - 1;
        }

        tasks[index].lastModified = result.last_modified;
        tasks[index].previousTaskId = result.previous_task_id;
        tasks[index].dueDateInterval = result.due_date_interval;
        tasks[index].dueDate = result.due_date;

        currentDonors[donorId] = {
            ...currentDonors[donorId],

            tasks,
        };

        yield put(getDonorTaskData(donorId));

        yield put(receiveDonors(currentDonors));
    }

    yield put(finishLoading('donorTask'));
}

export function* updateDonorTaskDataSaga(action: UpdateDonorForm): Saga<void> {
    const {
        donorId,
        taskId,
        formValueMap,
        lastModified,
        notify,
        notifyFullTask,
    } = action;

    yield put(startLoading('donorTask'));

    // Ensure takeLatest has time to handle doubleclicks
    //  https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const memberId = yield select((state) => selectUserId(state.auth));

    const { result, error, } = yield apiPut(api.txp.updateDonorTaskData, {
        donorId,
        taskId,
    }, {
        task_data: formValueMap,
        last_modified: lastModified !== '' ? lastModified : null,
        notify,
        send_full_task: notifyFullTask,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
            yield put(setSagaMessage('Update Error', `${errorMessage}`, ''));
        } else if (error.isInvalidResponseCode) {
            const errorMessage = parseResponseTextError(error.responseText);
            yield put(pushError(errorMessage));
            yield put(setSagaMessage('Update Error', `${errorMessage}`, ''));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating form failed, are you online?', error));
            yield put(setSagaMessage('', 'Updating form failed, are you online?', ''));
        } else {
            yield put(pushError('Updating form failed, try again later', error));
            yield put(setSagaMessage('', 'Updating form failed, try again later', ''));
        }
    } else {
        const historyArray = [];
        for (let i = result.history.length - 1; i >= 0; i -= 1) {
            historyArray.push({
                userId: result.history[i].user_id,
                updateTime: result.history[i].update_time,
                completed: result.history[i].completed,
                notApplicable: result.history[i].not_applicable,
                noteContent: result.history[i].note_content || '',
            });
        }

        const taskData = [];
        const taskDataKeys = result.task_data ? keys(result.task_data) : [];
        for (let i = 0; i < taskDataKeys.length; i += 1) {
            taskData.push({
                fieldId: taskDataKeys[i],
                data: result.task_data[taskDataKeys[i]],
            });
        }

        const donorTask = {
            donorId: result.donor_id,
            taskId: result.task_id,
            completed: result.completed,
            notApplicable: result.not_applicable,
            noteContent: result.note_content || '',
            previousTaskId: result.previous_task_id,
            dueDateInterval: result.due_date_interval,
            dueDate: result.due_date,
            history: historyArray,
            lastModified: result.last_modified,
            lastModifiedBy: memberId,
        };

        const donor = yield select((state) => state.donor.donors[donorId]);
        const workflow = donor.workflow || 'LEGACY';
        const allTasks = yield select((state) => state.donor.workflowTasks[workflow].tasks);
        const taskIndex = allTasks.findIndex((x) => x.taskId === taskId);
        const taskDescription = taskIndex !== -1 ? allTasks[taskIndex].details.description : null;
        const tempFormId = taskIndex !== -1 ? allTasks[taskIndex].formId : null;
        const formId = tempFormId || formIndexMap[taskDescription];

        if (hasValue(formId)) {
            yield put(bulkReceiveFormData(formId, taskData));
        }

        yield put(receiveDonorTask(donorTask));

        const currentDonors = yield select((state) => state.donor.donors || {});
        const oldTasks = yield select((state) => state.donor.donors[donorId].tasks || []);
        const tasks = oldTasks.slice(0);

        let index = tasks.findIndex((x) => x.taskId === taskId);
        if (index === -1) {
            tasks.push({
                taskId,
                completed: null,
                notApplicable: null,
                hasNoteContent: false,
                hasTaskData: true,
            });
            index = tasks.length - 1;
        }

        tasks[index].lastModified = result.last_modified;

        currentDonors[donorId] = {
            ...currentDonors[donorId],

            tasks,
        };

        yield put(getDonorTaskData(donorId));

        yield put(receiveDonors(currentDonors));
    }

    yield put(finishLoading('donorTask'));
}

export function* loadDonorFilesSaga(action: LoadDonorFiles): Saga<void> {
    const { donorId, } = action;

    const resArr = [];

    yield put(startLoadingEntity('donorFiles', donorId));

    const { result, error, } = yield apiFetch(api.txp.donorFiles, {
        donorId,
    });

    if (error || !result || !result.media_files || !Array.isArray(result.media_files)) {
        yield put(pushError('Loading donor files failed, try again later', error));
        return;
    }

    result.media_files.forEach((fileData) => {
        let messageType;
        switch (fileData.mime_type) {
            case 'image/jpeg':
                messageType = MessageTypes.jpeg;
                break;

            case 'image/jpg':
                messageType = MessageTypes.jpg;
                break;

            case 'image/png':
                messageType = MessageTypes.png;
                break;

            case 'image/gif':
                messageType = MessageTypes.gif;
                break;

            case 'video/mp4':
                messageType = MessageTypes.mp4;
                break;

            case 'video/quicktime':
                messageType = MessageTypes.mov;
                break;

            case 'application/pdf':
                messageType = MessageTypes.pdf;
                break;

            default:
                // if the file is unknown mime the upload will fail anyway
                //  so we also set the message type to 0 to ensure message sending fails as well
                messageType = MessageTypes.unknown;
        }

        resArr.push({
            id: fileData.media_file_id,
            fileName: fileData.file_path,
            displayName: fileData.display_name || fileData.file_path,
            mime: fileData.mime_type,
            memberId: fileData.user_id,
            sentTime: fileData.upload_date,
            size: fileData.file_size,
            messageType,
        });
    });

    const files = {};
    const fileIds = [];

    (resArr || []).forEach((file) => {
        files[`${file.id}`] = file;
        fileIds.push(file.id);
    });

    // simulating network latency
    yield delay(700);

    yield put(receiveDonorFiles(donorId, files, fileIds));

    yield put(finishLoadingEntity('donorFiles', donorId));
}

export function* uploadDonorFileSaga(action: UploadDonorFile): Saga<void> {
    const { donorId, attachments, isCaseAttachment, } = action;

    if (attachments && attachments.length > 0) {
        yield put(startLoading('media'));
        const token = yield select((state) => state.auth.accessToken);
        const apiRoot = (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, '');
        const uploadErrors = [];
        for (let i = 0; i < attachments.length; i += 1) {
            try {
                const encodedName = encodeURIComponent(attachments[i].fileName);
                const encodedMime = encodeURIComponent(attachments[i].mime);
                const apiPath = `${apiRoot}/donor/${donorId}/file/${encodedName}/${encodedMime}`;
                const form = new FormData();
                form.append('media_file', attachments[i].file);

                const headers = {
                    Apikey: process.env.REACT_APP_API_KEY || '',
                    Authorization: `Bearer ${token}`,
                };

                const options = {
                    method: 'POST',
                    headers,
                    body: form,
                };

                const result = yield fetch(apiPath, options).then((response) => response.json());

                if (result.error) {
                    uploadErrors.push(`Failed to upload file: ${attachments[i].fileName}`);
                }
            } catch (error) {
                if (!error.cancelled) {
                    yield put(pushError('Failed to upload media', error));
                } else {
                    yield put(pushError('Upload cancelled'));
                }
                yield put(uploadDonorFileFailed('failed'));
            }
        }

        if (uploadErrors.length === 1) {
            const message = uploadErrors[0] + (isCaseAttachment ? '. You can attach the PDF to this case in the Files tab.' : '');
            yield put(setSagaMessage('', message, ''));
        } else if (uploadErrors.length > 1) {
            yield put(setSagaMessage('', `Failed to upload ${uploadErrors.length} files`, ''));
        } else {
            yield put(uploadDonorFileSuccess());
        }

        if (attachments.length > uploadErrors.length) {
            // reload files for this donor so the new ones appear in the list
            yield put(loadDonorFiles(donorId));
        }

        yield put(finishLoading('media'));
    }
}

export function* deleteDonorFileSaga(action: DeleteDonorFile): Saga<void> {
    const { mediaFileId, donorId, } = action;

    yield put(startLoading('media'));
    const token = yield select((state) => state.auth.accessToken);
    const apiRoot = (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, '');
    const uploadErrors = [];

    try {
        const apiPath = `${apiRoot}/media/${mediaFileId}/delete`;

        const headers = {
            Apikey: process.env.REACT_APP_API_KEY || '',
            Authorization: `Bearer ${token}`,
        };

        const options = {
            method: 'PUT',
            headers,
        };

        const result = yield fetch(apiPath, options);
        if (result.error) {
            uploadErrors.push(`Failed to delete file: ${mediaFileId}`);
        }
    } catch (error) {
        if (!error.cancelled) {
            yield put(pushError('Failed to delete media', error));
        } else {
            yield put(pushError('Delete cancelled'));
        }
        yield put(deleteDonorFileFailed('failed'));
    }
    if (uploadErrors.length === 1) {
        yield put(setSagaMessage('', uploadErrors[0], ''));
    } else {
        yield put(deleteDonorFileSuccess());
    }

    yield put(finishLoading('media'));
    // now reload files for this donor
    yield put(loadDonorFiles(donorId));
}

export function* loadTasksSaga(action: LoadTasks): Saga<void> {
    const {
        workflow,
        donorId,
    } = action;

    yield put(startLoading('tasks'));

    const { result, error, } = yield apiFetch(api.txp.tasks, {
        workflow,
    }, {
        donor_id: donorId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Loading tasks failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Loading tasks failed', 'Try again later', ''));
        }
        yield put(finishLoading('tasks'));
    } else {
        // See how we can cast task from any to ApiResponseTask
        const tasks: StaticTask[] = Object.entries(result.tasks).map(([taskId, task]: [string, any]) => ({
            taskId: parseInt(taskId, 10),
            formId: task.form_id,
            details: {
                sectionPosition: task.section_position,
                section: task.section,
                description: task.description,
                highRisk: task.high_risk,
            },
        }));
        const sectionOrder = [...new Set(tasks.map((t) => t.details.section))];

        yield put(receiveTasks(workflow || 'LEGACY', tasks, sectionOrder));
        yield put(finishLoading('tasks'));
    }
}

export function* getDonorTaskSaga(action: GetDonorTask): Saga<void> {
    const {
        donorId,
        taskId,
    } = action;

    yield put(startLoading('donorTask'));

    const { result: resultRaw, error, } = yield apiFetch(api.txp.donorTask, {
        donorId,
        taskId,
    });

    let result = resultRaw;
    if (error && error.statusCode === 404) {
        // if not found, set default values
        result = {
            task_data: {},
            donor_id: donorId,
            task_id: taskId,
            completed: false,
            not_applicable: false,
            note_content: false,
            previous_task_id: null,
            due_date_interval: null,
            due_date: null,
            history: [],
            last_modified: '',
            last_modified_by: 0,
        };
    } else if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isInvalidResponseCode) {
            yield put(setSagaMessage('', parseResponseTextError(error.responseText), ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Loading task failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Loading task failed', 'Try again later', ''));
        }
        yield put(finishLoading('donorTask'));
        return;
    }
    const historyArray = [];
    for (let i = result.history.length - 1; i >= 0; i -= 1) {
        historyArray.push({
            userId: result.history[i].user_id,
            updateTime: result.history[i].update_time,
            completed: result.history[i].completed,
            notApplicable: result.history[i].not_applicable,
            noteContent: result.history[i].note_content || '',
        });
    }

    const taskData = [];
    const donor = yield select((state) => state.donor.donors[donorId]);
    const workflow = donor.workflow || 'LEGACY';
    const tasks = yield select((state) => state.donor.workflowTasks[workflow].tasks);
    const index = tasks.findIndex((x) => x.taskId === taskId);
    const taskDescription = index !== -1 ? tasks[index].details.description : null;
    const tempFormId = index !== -1 ? tasks[index].formId : null;
    const formId = tempFormId || formIndexMap[taskDescription];

    if (hasValue(formId)) {
        if (result.task_data) {
            const taskDataKeys = Object.keys(result.task_data);
            taskDataKeys.forEach((key) => {
                const data = result.task_data[key];
                taskData.push({
                    fieldId: key,
                    data,
                });
            });
        }
    }

    const donorTask = {
        donorId: result.donor_id,
        taskId: result.task_id,
        completed: result.completed,
        notApplicable: result.not_applicable,
        noteContent: result.note_content || '',
        previousTaskId: result.previous_task_id,
        dueDateInterval: result.due_date_interval,
        dueDate: result.due_date,
        history: historyArray,
        lastModified: result.last_modified,
        lastModifiedBy: result.last_modified_by,
    };

    const formUpdated = yield select((state) => state.forms.formUpdated);

    if (hasValue(formId) && !formUpdated) {
        yield put(setFormErrors(formId, {}));
        yield put(bulkReceiveFormData(formId, taskData));
    }

    yield put(receiveDonorTask(donorTask));
    yield put(finishLoading('donorTask'));
}

export function* getDonorTaskDataSaga(action: GetDonorTaskData): Saga<any> {
    const {
        donorId,
    } = action;

    yield put(startLoading('taskData'));

    const { result, error, } = yield apiFetch(api.txp.donorTasks, {
        donorId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isInvalidResponseCode) {
            yield put(setSagaMessage('', parseResponseTextError(error.responseText), ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Loading tasks failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Loading tasks failed', 'Try again later', ''));
        }
        yield put(finishLoading('taskData'));
    } else {
        const resultTasks = result.tasks || [];
        const taskData = {};
        for (let i = 0; i < resultTasks.length; i += 1) {
            const currentTask = resultTasks[i];
            taskData[currentTask.task_id] = currentTask.task_data;
        }

        yield put(receiveDonorTaskData(donorId, taskData));
        yield put(finishLoading('taskData'));
    }
}

export function* loadOrgMembersSaga(): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);
    yield put(startLoading('orgMembers'));

    const organizationId = yield select((state) => state.auth.profile.organizationId);

    const { result, error, } = yield apiFetch(api.txp.getOrganizationMembers, {
        orgId: organizationId,
    });

    if (error) {
        yield put(pushError('Failed to get organization members', error));
        yield put(finishLoading('orgMembers'));
    } else {
        const members = [];
        const memberArray = result.organization_members;

        for (let i = 0; i < memberArray.length; i += 1) {
            members.push(parseProfile(memberArray[i].user_information));
        }

        yield put(receiveOrganizationMembers(members));
        yield put(finishLoading('orgMembers'));
    }
}

export function* getCaseAdminUsersSaga(action: GetCaseAdminUsers): Saga<void> {
    const { donorId, } = action;
    const resourcePermissions = yield select((state) => state.permission.resourcePermissions);
    const allDonorPermissions = resourcePermissions ? resourcePermissions[ENTITY_TYPE_DONOR] : {};
    const donorPermissions = allDonorPermissions ? allDonorPermissions[donorId] : [];

    const userIds = donorPermissions
        .filter((permission) => permission.authorized.authorizedType === 'User')
        .map((permission) => permission.authorized.authorizedId);

    if (userIds.length > 0) {
        const { result, error, } = yield apiPost(api.txp.users, null, {
            user_ids: userIds,
        });

        if (error) {
            yield put(pushError('Failed to get case admin users', error));
        } else {
            const users = [];
            const usersArray = result.users;

            for (let i = 0; i < usersArray.length; i += 1) {
                users.push(parseProfile(usersArray[i]));
            }

            yield put(receiveCaseAdminUsers(users));
        }
    }
}

const parseFollower = (followerRes: any): FollowerGroup => {
    const follower = {
        followerGroupId: followerRes.follower_id,
        memberUsers: [],
        externalUsers: [],
        name: followerRes.name,
        tasks: followerRes.tasks,
        canUpdate: followerRes.can_update,
    };

    if (followerRes.external_users) {
        for (let i = 0; i < followerRes.external_users.length; i += 1) {
            follower.externalUsers.push(parseExternalUser(followerRes.external_users[i]));
        }
    }

    if (followerRes.member_users) {
        for (let i = 0; i < followerRes.member_users.length; i += 1) {
            follower.memberUsers.push(parseProfile(followerRes.member_users[i]));
        }
    }

    return follower;
};

export function* getDonorFollowerGroupsSaga(action: GetDonorFollowerGroups): Saga<any> {
    const {
        donorId,
    } = action;

    const { result, error, } = yield apiFetch(api.txp.donorFollowers, {
        donorId,
    });

    if (error) {
        yield put(pushError('Failed to get the follower group list', error));
    } else {
        const followers = result.followers || [];
        const followersMap = {};
        for (let i = 0; i < followers.length; i += 1) {
            followersMap[followers[i].follower_id] = parseFollower(followers[i]);
        }

        yield put(receiveDonorFollowerGroups(donorId, followersMap));
    }
}

export function* getFollowerGroupSaga(action: GetFollowerGroup): Saga<any> {
    const {
        donorId,
        followerGroupId,
    } = action;

    yield put(startLoading('follower'));

    const { result, error, } = yield apiFetch(api.txp.follower, {
        followerId: followerGroupId,
    });

    if (error) {
        yield put(pushError('Failed to get follower group', error));
        yield put(setSagaMessage('', 'Error loading follower data', ''));
    } else {
        const donorState = yield select((state) => state.donor.donors[donorId]);
        const donorFollowerGroups = donorState.followerGroups ? donorState.followerGroups : {};
        const followerGroup: FollowerGroup = {
            followerGroupId: result.follower.follower_id,
            name: result.follower.name,
            tasks: result.task_ids,
            memberUsers: result.member_users ? result.member_users.map((user) => parseProfile(user)) : [],
            externalUsers: result.external_users ? result.external_users.map((user) => parseExternalUser(user)) : [],
            canUpdate: result.follower.can_update,
        };
        const updatedFollowerGroupsMap = {
            ...donorFollowerGroups,
            [followerGroupId]: {
                ...followerGroup,
            },
        };

        yield put(receiveDonorFollowerGroups(donorId, updatedFollowerGroupsMap));
        yield put(initFollowerEditData(followerGroup));
    }

    yield put(finishLoading('follower'));
}

export function* createFollowerGroupSaga(action: CreateFollowerGroup): Saga<any> {
    const {
        donorId,
        followerGroup,
    } = action;

    yield put(startLoading('follower'));

    const result = ((yield createFollowerGroupGenerator(donorId, followerGroup)): CreateFollowerGroupResult);

    if (!result.groupCreationError) {
        // Follower group creation was successful - Reset follower edit state
        yield put(resetFollowerEditData());
        yield put(openFollowerGroup(result.followerGroupId));
        yield put(setSagaMessage('', `${result.followerName} created!`, ''));
    } else {
        yield put(setSagaMessage('', `Failed to create a case follower: ${result.followerName}`, ''));
    }

    // After updating everything, change the donor view the general status view
    // NOTE: we need to wait for the above to finish before navigating, because swithcing the donor
    // view will reload follower groups and could overwrite edited information
    yield put(selectDonorView(DONOR_STATUS_VIEW));
    yield put(finishLoading('follower'));
}

/**
 * This saga creates multiple follower groups in parallel. The creation results are returned from a helper
 * generator function and interpreted to determine what saga message should be set.
 */
export function* createMultipleFollowerGroupsSaga(action: CreateMultipleFollowerGroups): Saga<any> {
    const {
        donorId,
        followerGroups,
    } = action;

    yield put(startLoading('follower'));

    const results = ((yield all(followerGroups.map((f) => call(createFollowerGroupGenerator, donorId, f)))): CreateFollowerGroupResult[]);

    if (results.some((r) => r.groupCreationError)) {
        const failedFollowerGroupNames = results.filter((r) => r.groupCreationError).map((r) => r.followerName).join(', ');
        yield put(setSagaMessage('', `Failed to create follower group(s): ${failedFollowerGroupNames}`, ''));
    } else if (results.some((r) => r.memberUpdateError)) {
        const failedFollowerGroupNames = results.filter((r) => r.memberUpdateError).map((r) => r.followerName).join(', ');
        yield put(setSagaMessage('', `An error occurred while adding members to these follower groups: ${failedFollowerGroupNames}`, '', false, true));
    } else {
        // No errors, all follower groups created successfully
        const createdFollowerGroupNames = results.map((r) => r.followerName).join(', ');
        yield put(setSagaMessage('', `Created follower group(s): ${createdFollowerGroupNames}!`, '', false, true));
    }

    yield put(finishLoading('follower'));
}

type CreateFollowerGroupResult = {
    groupCreationError: boolean;
    memberUpdateError: boolean;
    followerName: string;
    followerGroupId: number | null;
};

/**
 * Helper generator function for creating a follower group.
 *
 * @returns follower group Id if successful, null if unsuccessful
 */
function* createFollowerGroupGenerator(donorId: number, followerGroup: FollowerEditState): Saga<CreateFollowerGroupResult> {
    const { result, error, } = yield apiPost(api.txp.createFollowerGroup, null, {
        donor_id: donorId,
        name: followerGroup.followerName,
    });

    const createResult = ({
        groupCreationError: false,
        memberUpdateError: false,
        followerName: followerGroup.followerName,
        followerGroupId: null,
    }: CreateFollowerGroupResult);

    if (error) {
        createResult.groupCreationError = true;
        yield put(pushError('Failed to create the follower group', error));
    } else {
        const followerGroupId = result.follower.follower_id;
        const donorState = yield select((state) => state.donor.donors[donorId]);
        const donorFollowerGroups = donorState.followerGroups ? donorState.followerGroups : {};

        const updatedFollowerGroupsMap = {
            ...donorFollowerGroups,
            [followerGroupId]: {
                followerGroupId,
                name: result.follower.name,
                tasks: result.task_ids,
                memberUsers: result.member_users ? result.member_users.map((user) => parseProfile(user)) : [],
                externalUsers: result.external_users ? result.external_users.map((user) => parseExternalUser(user)) : [],
                canUpdate: result.can_update,
            },
        };

        yield put(receiveDonorFollowerGroups(donorId, updatedFollowerGroupsMap));
        const { pendingUsers, } = followerGroup;
        const memberUsers = result.member_users ? result.member_users.map((user) => user.user_id) : [];// should initially include the creator
        const externalUsers = [];
        pendingUsers.forEach((user) => memberUsers.push(user.userId));

        const memberUpdateSucceeded = yield call(updateFollowerGroupMembersSaga, donorId, followerGroupId, memberUsers, externalUsers);
        createResult.memberUpdateError = !memberUpdateSucceeded;
        yield call(updateFollowerGroupTasksSaga, updateFollowerGroupTasks(donorId, followerGroupId, followerGroup.taskIds));

        if (followerGroup.canUpdate) {
            yield call(updateFollowerGroupPermissionsSaga, updateFollowerGroupPermissions(donorId, followerGroupId, followerGroup.canUpdate));
        }

        createResult.followerGroupId = followerGroupId;
    }

    return createResult;
}

export function* updateFollowerGroupSaga(action: UpdateFollowerGroup): Saga<any> {
    const {
        donorId,
        followerGroup,
    } = action;

    const {
        followerGroupId,
        followerName,
    } = followerGroup;

    yield put(startLoading('follower'));

    const { error, } = yield apiPut(
        api.txp.updateFollowerName, {
            followerId: followerGroupId,
        }, {
            name: followerName,
        }
    );

    if (error || !followerGroupId) {
        yield put(pushError('Failed to update the follower name', error));
        yield put(setSagaMessage('', 'Updating follower failed', ''));
    } else {
        const donorState = yield select((state) => state.donor.donors[donorId]);
        const donorFollowerGroups = donorState.followerGroups ? donorState.followerGroups : {};
        const donorFollowerGroup = followerGroupId ? donorFollowerGroups[followerGroupId] : {};
        const updatedFollowerGroupsMap = {
            ...donorFollowerGroups,
            [followerGroupId]: {
                ...donorFollowerGroup,
                name: followerName,
            },
        };

        yield put(receiveDonorFollowerGroups(donorId, updatedFollowerGroupsMap));

        // After receiving the group, do the other update tasks
        if (donorFollowerGroup.canUpdate !== followerGroup.canUpdate) {
            yield put(updateFollowerGroupPermissions(donorId, followerGroupId, followerGroup.canUpdate));
        }

        // Figure out what users to add/remove
        const { pendingUsers, pendingRemovedUsers, } = followerGroup;
        const memberUsers = (donorFollowerGroup.memberUsers || []).map((memberUser) => memberUser.userId);
        const externalUsers = (donorFollowerGroup.externalUsers || []).map((externalUser) => externalUser.userId);

        // Add users
        for (let i = 0; i < pendingUsers.length; i += 1) {
            const selectedContact = pendingUsers[i];
            if (!memberUsers.includes(selectedContact.userId)) {
                memberUsers.push(selectedContact.userId);
            }
        }

        // Remove users
        for (let i = 0; i < pendingRemovedUsers.length; i += 1) {
            const removedUser = pendingRemovedUsers[i];
            if (removedUser.isExternalUser) {
                const index = externalUsers.indexOf(removedUser.memberId);
                if (index !== -1) {
                    externalUsers.splice(index, 1);
                }
            } else {
                const index = memberUsers.indexOf(removedUser.memberId);
                if (index !== -1) {
                    memberUsers.splice(index, 1);
                }
            }
        }

        // Send request to update users
        yield updateFollowerGroupMembersSaga(donorId, followerGroupId, memberUsers, externalUsers);
        // Send request to update tasks
        yield put(updateFollowerGroupTasks(donorId, followerGroupId, followerGroup.taskIds));

        // Reset the follower edit state
        yield put(resetFollowerEditData());
        yield put(setSagaMessage('', 'Follower updated!', ''));
    }

    // After updating everything, change the donor view the general status view
    // NOTE: we need to wait for the above to finish before navigating, because switching the donor
    // view will reload follower groups and could overwrite edited information
    yield put(selectDonorView(DONOR_STATUS_VIEW));
    yield put(finishLoading('follower'));
}

// Join a follower group. If the group is already joined, the user leaves the group instead.
export function* joinFollowerGroupSaga(action: JoinFollowerGroup): Saga<any> {
    const {
        donorId,
        followerGroupId,
    } = action;
    yield put(startLoading('follower'));

    const getFollowerGroupAction: GetFollowerGroup = {
        type: 'Donor/GET_FOLLOWER_GROUP',
        donorId,
        followerGroupId,
    };

    // Get the follower groups
    yield getFollowerGroupSaga(getFollowerGroupAction);

    const profile = yield select((state) => state.auth.profile);
    const followerGroups = yield select((state) => state.donor.donors[donorId].followerGroups);
    const followerGroup = followerGroups[followerGroupId];
    if (!followerGroup) {
        yield put(setSagaMessage('', 'Joining the group failed', ''));
        yield put(finishLoading('follower'));
        return;
    }

    const memberUsers = (followerGroup.memberUsers || []).map((memberUser) => memberUser.userId);
    const externalUsers = (followerGroup.externalUsers || []).map((externalUser) => externalUser.userId);
    let isUserJoining = false;

    // Add yourself to the follower group, remove yourself if already there
    if (!memberUsers.includes(profile.userId)) {
        memberUsers.push(profile.userId);
        isUserJoining = true;
    } else {
        const index = memberUsers.indexOf(profile.userId);
        if (index !== -1) {
            memberUsers.splice(index, 1);
        }
    }

    // Send request to update users
    yield updateFollowerGroupMembersSaga(donorId, followerGroupId, memberUsers, externalUsers);

    // Reset the follower edit state
    yield put(resetFollowerEditData());
    yield put(setSagaMessage('', isUserJoining ? 'Joined Follower Group' : 'Left Follower Group', '', false, true));
    yield put(finishLoading('follower'));
}

export function* updateFollowerGroupTasksSaga(action: UpdateFollowerGroupTasks): Saga<any> {
    const {
        donorId,
        followerGroupId,
        taskIds,
    } = action;

    yield put(startLoading('followerTasks'));

    const { error, } = yield apiPut(
        api.txp.updateFollowerTasks, {
            followerId: followerGroupId,
        }, {
            task_ids: taskIds,
        }
    );

    if (error) {
        yield put(pushError('Failed to update the follower name', error));
        yield put(setSagaMessage('', 'Updating follower tasks failed', ''));
    } else {
        const donorState = yield select((state) => state.donor.donors[donorId]);
        const donorFollowerGroups = donorState.followerGroups ? donorState.followerGroups : {};
        const donorFollowerGroup = donorFollowerGroups[followerGroupId] || {};
        const updatedFollowerGroupsMap = {
            ...donorFollowerGroups,
            [followerGroupId]: {
                ...donorFollowerGroup,
                taskIds,
            },
        };

        yield put(receiveDonorFollowerGroups(donorId, updatedFollowerGroupsMap));
    }

    yield put(finishLoading('followerTasks'));
}

export function* updateFollowerGroupMembersSaga(donorId: number, followerGroupId: number, memberUserIds: number[], externalUserIds: number[]): Saga<any> {
    yield put(startLoading('followerPeople'));

    const { result, error, } = yield apiPut(
        api.txp.updateFollowerUsers, {
            followerId: followerGroupId,
        }, {
            user_ids: memberUserIds,
            external_user_ids: externalUserIds,
        }
    );

    if (error) {
        yield put(pushError('Failed to update the follower members', error));
        yield put(setSagaMessage('', 'Updating follower members failed', ''));
    } else {
        const donorState = yield select((state) => state.donor.donors[donorId]);
        const donorFollowerGroups = donorState.followerGroups ? donorState.followerGroups : {};
        const donorFollowerGroup = donorFollowerGroups[followerGroupId] || {};
        const updatedFollowerGroupsMap = {
            ...donorFollowerGroups,
            [followerGroupId]: {
                ...donorFollowerGroup,
                memberUsers: result.member_users ? result.member_users.map((user) => parseProfile(user)) : [],
                externalUsers: result.external_users ? result.external_users.map((user) => parseExternalUser(user)) : [],
            },
        };

        yield put(receiveDonorFollowerGroups(donorId, updatedFollowerGroupsMap));
        yield put(setSagaMessage('', `${donorFollowerGroup.name || 'Follower group'} users updated!`, ''));
    }

    yield put(finishLoading('followerPeople'));

    return error == null;
}

export function* updateFollowerGroupPermissionsSaga(action: UpdateFollowerGroupPermissions): Saga<any> {
    const {
        donorId,
        followerGroupId,
        canUpdate,
    } = action;

    const { result, error, } = yield apiPut(api.txp.updateFollowerPermissions, {
        followerId: followerGroupId,
    }, {
        can_update: canUpdate,
    });

    if (error) {
        yield put(pushError('Failed to update the follower group permissions', error));
        yield put(setSagaMessage('', 'Updating follower permissions failed', ''));
    } else {
        const donorState = yield select((state) => state.donor.donors[donorId]);
        const donorFollowerGroups = donorState.followerGroups ? donorState.followerGroups : {};
        const donorFollowerGroup = donorFollowerGroups[followerGroupId] || {};
        const updatedFollowerGroupsMap = {
            ...donorFollowerGroups,
            [followerGroupId]: {
                ...donorFollowerGroup,
                canUpdate: result.can_update,
            },
        };
        yield put(receiveDonorFollowerGroups(donorId, updatedFollowerGroupsMap));
        yield put(setSagaMessage('', `${donorFollowerGroup.name || 'Follower group'} permissions updated!`, ''));
    }
}

export function* deleteFollowerGroupSaga(action: DeleteFollowerGroup): Saga<any> {
    const {
        donorId,
        followerGroupId,
    } = action;

    yield put(startLoading('followers'));

    const { error, } = yield apiDelete(api.txp.follower, {
        followerId: followerGroupId,
    });

    if (error) {
        yield put(pushError(`Failed to delete follower group ${followerGroupId}:`, error));
        yield put(setSagaMessage('', 'Failed to delete follower', ''));
    } else {
        const donorState = yield select((state) => state.donor.donors[donorId]);
        const donorFollowerGroups = {
            ...donorState.followerGroups,
        };
        delete donorFollowerGroups[followerGroupId];

        yield put(receiveDonorFollowerGroups(donorId, donorFollowerGroups));
        yield put(setSagaMessage('', 'Follower deleted!', ''));
    }

    yield put(finishLoading('followers'));
}

export function* removeUserFromFollowerGroupSaga(action: RemoveUserFromFollowers): Saga<any> {
    const {
        donorId,
        followerId,
        memberId,
    } = action;

    yield put(startLoading('followerPeople'));

    const { result, error, } = yield apiDelete(api.txp.updateFollowerRemoveUser, {
        followerId,
        userId: memberId,
    });

    if (error) {
        yield put(pushError(`Failed to remove user ${memberId} from follower ${followerId}:`, error));
        yield put(setSagaMessage('', 'There was a problem removing yourself from the Follower Group', ''));
    } else {
        const donorState = yield select((state) => state.donor.donors[donorId]);
        const donorFollowerGroups = donorState.followerGroups ? donorState.followerGroups : {};
        const donorFollowerGroup = donorFollowerGroups[followerId] || {};
        const updatedFollowerGroupsMap = {
            ...donorFollowerGroups,
            [followerId]: {
                ...donorFollowerGroup,
                memberUsers: result.member_users ? result.member_users.map((user) => parseProfile(user)) : [],
                externalUsers: result.external_users ? result.external_users.map((user) => parseExternalUser(user)) : [],
            },
        };

        yield put(receiveDonorFollowerGroups(donorId, updatedFollowerGroupsMap));
        yield put(setSagaMessage('', `You left the Follower group ${donorFollowerGroup.name || 'Follower group'}`, ''));
    }

    yield put(finishLoading('followerPeople'));
}

export function* saveCaseNoteSaga(action: SaveCaseNote): Saga<any> {
    const {
        caseId,
        noteContent,
    } = action;

    yield put(startLoading('caseNotes'));

    const { error, result, } = yield apiPost(api.txp.createCaseNote, { caseId, }, { note: noteContent, });

    if (error) {
        yield put(pushError(`Failed to save note for case ${caseId}:`, error));
        yield put(setSagaMessage('', 'Failed to save case note', ''));
    } else {
        const notesArray = result.map((note) => convertToCaseNote(note));

        yield put(receiveCaseNotes(caseId, notesArray));
    }
    yield put(finishLoading('caseNotes'));
}

export function* checkContactIsTxpUserSaga(action: AddPeopleByEmail | AddPeopleByPhone): Saga<void> {
    const email = typeof action.email === 'string' ? action.email.toLowerCase() : undefined;
    const phone = typeof action.phone === 'string' ? serializePhone(action.phone) : undefined;

    yield put(startLoading('checkContact'));

    const { result, error, } = yield apiPost(api.txp.getContacts, null, {
        email_list: email ? [email] : [],
        phone_list: phone ? [phone] : [],
    });

    let user: ?RemoteMemberProfile;

    if (!error) {
        const localContacts: RemoteMemberProfile[] = result.local_contacts;
        const chatroomContacts: RemoteMemberProfile[] = result.chatroom_contacts;
        const donorContacts: RemoteMemberProfile[] = result.donor_contacts;
        const organizationContacts: RemoteMemberProfile[] = result.organization_contacts;
        // const directoryContacts = result.directory_contacts;
        // const excludedContacts = result.excluded_contacts;
        // const externalContacts = result.external_contacts;
        const publicContacts: RemoteMemberProfile[] = result.public_contacts;

        const finder = email
            ? (contact) => contact.email === email
            : (contact) => contact.phone === phone;

        user = [
            ...localContacts,
            ...chatroomContacts,
            ...donorContacts,
            ...organizationContacts,
            ...publicContacts
        ].find(finder);
    }

    if (error || !user) {
        const contactType = email ? 'email address' : 'phone number';
        const message = `The ${contactType} does not match a current Omnilife user`;

        yield put(pushError(`${message}:`, error));
        yield put(setSagaMessage('', message, ''));
        yield put(setAddPeopleError(message));
        yield put(addSingleUserToChatroomFailed('Not a registered user'));
    } else {
        const users = yield select((state) => state.addPeople.selectedUsers);

        yield put(setSelectedUsers([...users, {
            userId: user.user_id,
            email: user.email,
            phone: user.phone,
            firstName: user.first_name,
            lastName: user.last_name,
            profilePicture: user.profile_picture_url,
        }]));
    }

    yield put(finishLoading('checkContact'));
}

export function* inviteUserSaga(action: AddPeopleInvite): Saga<void> {
    const { email, phone, } = action;

    const payload = {};
    payload.sender_information = yield select((state) => selectProfileName(state.auth), (state) => selectProfileEmail(state.auth));
    payload.chatroom_id = undefined;

    if (email) {
        payload.email = email;
    } else {
        payload.phone = phone;
    }

    const { error, } = yield apiPost(api.txp.inviteNewUser, null, payload);

    if (error) {
        if (error.isValidationError) {
            yield put(inviteNewUserFailed('Validation failed while attempting to invite user.'));
        } else if (error.isNetworkError) {
            yield put(inviteNewUserFailed('Request failed - are you offline?'));
        } else {
            // Probably a malformed request in some way.
            yield put(inviteNewUserFailed('An unexpected error occurred.'));
        }
    } else {
        yield put(inviteNewUserSuccess());
        yield put(setSagaMessage('', 'New user invite sent!', '', undefined, true));
    }

    if (error) {
        return {
            success: false,
            error: {
                message: singleErrorMessage(error) || 'Failed to add',
            },
        };
    }

    return { success: true, };
}

export function* inviteNewUserSaga(action: InviteNewUser): Saga<void> {
    const {
        followerId,
        chatId,
        memberId,
        email,
        phone,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    // use an unsealed object to prevent flow errors in adding either phone or both
    const payload = {};
    payload.sender_information = yield select((state) => selectProfileName(state.auth), (state) => selectProfileEmail(state.auth));
    payload.follower_id = followerId;

    if (action.email.length > 0) {
        payload.email = email;
    } else {
        payload.phone = phone;
    }
    const { error, } = yield apiPost(api.txp.inviteNewUser, null, payload);

    if (error) {
        if (error.isValidationError) {
            yield put(inviteNewUserFailed(parseError(error)));
        } else if (error.isInvalidResponseCode) {
            yield put(inviteNewUserFailed(parseResponseTextError(error.responseText)));
        } else if (error.isNetworkError) {
            yield put(inviteNewUserFailed('Request failed - are you offline?'));
        } else {
            // Probably a malformed request in some way.
            yield put(inviteNewUserFailed('An unexpected error occurred.'));
        }
    } else {
        yield put(inviteNewUserSuccess());
        yield put(setSagaMessage('', 'New user invite sent!', ''));
        yield put(loadMessages(chatId, memberId, true, 0));
    }
}
