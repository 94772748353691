// @flow

// $FlowFixMe
import { createSelector } from 'reselect';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import type {
    CaseRow,
    WorkflowOption,
} from '../Utils/types';
import {
    parseDate,
    formatDate,
    formatDateTime,
    calculateAgeFromDOB,
} from '../Utils/time';

const getWorkflowOptions = (_state, workflowOptions: WorkflowOption[]) => workflowOptions;

const getDonors = (state) => state.donor.donors;
export const getDonorFilter = (state: any) => {
    if (state.auth.profile && state.auth.profile.donorFilter) {
        return { ...state.auth.profile.donorFilter, filterTags: state.donor.caseTagFilter, workflowTypes: state.donor.workflowTypes, };
    }

    return {
        status: 'All', donorType: 'All', filterTags: [], workflowTypes: [],
    };
};

const getCaseDisplayOrder = (state) => state.donor.caseDisplayOrder;

export const getTagFilter = (state: any) => {
    if (state.donor && state.donor.caseTagFilter) {
        return state.donor.caseTagFilter;
    }
    return null;
};

export const getCaseRows = createSelector([getDonors, getWorkflowOptions, getCaseDisplayOrder], (donors, workflowOptions, caseDisplayOrder): CaseRow[] => {
    const rows = caseDisplayOrder.map((id) => {
        const donor = donors[id];
        // Check donor is loaded before mapping
        if (!donor) {
            return {};
        }
        const {
            donorId,
            opoDonorId,
            unosId,
            currentLocation,
            sex,
            dob,
            tags,
            closed,
            highRisk,
            notes,
            isAdmin,
        } = donor;

        // NOTE: workflow builder adds versions with a '-v<d>' suffix, so we need to ignore the versions by removing from the key
        // when searching for a cases workflow
        const caseWorkflow = workflowOptions.find((workflow) => workflow.key.replace(/-v\d+$/, '') === donor.workflow.replace(/-v\d+$/, ''));
        const workflowName = caseWorkflow ? caseWorkflow.name : '';
        const createdOn = donor.createDate;
        const sexAbbreviation = sex === 'Male' ? 'M' : (sex === 'Female' ? 'F' : 'U');
        const age = dob ? calculateAgeFromDOB(dob) : undefined;

        // Task info
        const maxTasks = (donor.tasks) ? donor.tasks.length : -1;
        const completedTasks = (donor.tasks || []).reduce((completed, task) => {
            if (task.completed) {
                return completed + 1;
            }

            return completed;
        }, 0);

        // We need to determine our latest task activity, so look through all tasks that have a lastModifiedTaskDate and collect them
        // then sort them in descending order
        const taskActivity = (donor.tasks || []).filter((task) => task.lastModified).map((task) => task.lastModified).sort((t1, t2) => {
            const t1Date = parseDate(t1);
            const t2Date = parseDate(t2);

            if (t1Date === null && t2Date === null) return 0;
            if (t1Date === null) return 1;
            if (t2Date === null) return -1;

            return (t2Date.getTime() - t1Date.getTime());
        });

        // We also need to determine our next due date, if any, do a similar filter and sort them in ascending order according to
        // the time difference from now (soonest time first)
        const now = Date.now();
        const dueDates = (donor.tasks || []).filter((task) => (!task.completed && task.dueDate)).map((task) => task.dueDate).sort((t1, t2) => {
            const t1Date = parseDate(t1);
            const t2Date = parseDate(t2);

            if (t1Date === null && t2Date === null) return 0;
            if (t1Date === null) return 1;
            if (t2Date === null) return -1;

            const t1Diff = differenceInMilliseconds(t1Date, now);
            const t2Diff = differenceInMilliseconds(t2Date, now);

            return (t1Diff - t2Diff);
        });
        const nextDueDate = (dueDates.length) ? parseDate(dueDates[0]) : null;

        const lastActivity = taskActivity[0] || donor.lastModified;
        const lastNote = notes && notes.length > 0 ? notes[0] : null;

        return {
            donorId,
            unosId,
            name: opoDonorId ?? unosId,
            sex: sexAbbreviation,
            age,
            location: currentLocation ?? '',
            workflow: workflowName,
            createdOn: {
                value: formatDate(createdOn) ?? '',
                comp: new Date(createdOn ?? 0).valueOf(), // convert to number for easy comparison
            },
            lastActivity: {
                value: formatDateTime(lastActivity, '\' at \''),
                comp: new Date(lastActivity ?? 0).valueOf(), // convert to number for easy comparison
            },
            nextDueDate: {
                value: nextDueDate ? formatDateTime(nextDueDate.toISOString(), '\' at \'') : '',
                comp: nextDueDate ? new Date(nextDueDate).valueOf() : Number.MAX_SAFE_INTEGER, // convert to number for easy comparison
            },
            tasksCompleted: completedTasks,
            tasksTotal: maxTasks,
            tags,
            closed,
            highRisk,
            donor,
            lastNote,
            isAdmin,
        };
    });

    return rows;
});
