// @flow
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import {
    setEnvContext as _setEnvContext,
} from 'txp-core';
import type {
    EnvContext,
} from 'txp-core';

import LoginPage from '../Containers/LoginPage';
import ChatPage from '../Containers/ChatPage';
import RegistrationPage from '../Containers/RegistrationPage';
import SSOLoginHandler from '../Containers/SSOLoginHandler';
import ErrorBoundary from '../Containers/ErrorBoundary';
import ErrorLogger from '../Components/ErrorLogger';

type Props = {
    history: any,
    setEnvContext: (envContext: EnvContext) => *,
};
class Routes extends Component<Props> {
    constructor(props) {
        super(props);
        if (process.env.REACT_APP_SPA_CLIENT_ID) {
            this.oktaAuth = new OktaAuth({
                issuer: process.env.REACT_APP_SPA_DOMAIN || '',
                clientId: process.env.REACT_APP_SPA_CLIENT_ID || '',
                redirectUri: `${window.location.origin}/login/callback`,
            });
        }
    }

    componentDidMount() {
        const {
            setEnvContext,
        } = this.props;
        setEnvContext({
            API_DOMAIN: process.env.REACT_APP_API_DOMAIN || '',
            API_ROOT: process.env.REACT_APP_API_ROOT || '',
            API_KEY: process.env.REACT_APP_API_KEY || '',
            locale: { countryCode: 'US', languageTag: 'en-US', languageCode: 'en', },
        });
    }

    oktaAuth;

    async restoreOriginalUri(_oktaAuth, originalUri, history) {
        history.replace(toRelativeUrl(originalUri || '/login/sso-login', window.location.origin));
    }

    customAuthHandler(history) {
        history.push('/login');
    }

    render() {
        return (
            <ErrorBoundary>
                <ErrorLogger />
                {process.env.REACT_APP_SPA_CLIENT_ID ? (
                    <Security
                        oktaAuth={this.oktaAuth}
                        restoreOriginalUri={(_oktaAuth, originalUri) => { this.restoreOriginalUri(_oktaAuth, originalUri, this.props.history); }}
                        onAuthRequired={() => { this.customAuthHandler(this.props.history); }}
                    >
                        <Switch>
                            <Route path="/login/callback" component={LoginCallback} />
                            <Route path="/login/sso-login" component={SSOLoginHandler} />
                            <Route path="/login" component={LoginPage} />
                            <Route path="/register" component={RegistrationPage} />
                            <Route path="/chat" component={ChatPage} />
                            <Route path="*" component={LoginPage} />
                        </Switch>
                    </Security>
                ) : (
                    <Switch>
                        <Route path="/login/callback" component={LoginCallback} />
                        <Route path="/login/sso-login" component={SSOLoginHandler} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/register" component={RegistrationPage} />
                        <Route path="/chat" component={ChatPage} />
                        <Route path="*" component={LoginPage} />
                    </Switch>
                )}
            </ErrorBoundary>
        );
    }
}

export default withRouter(connect(null, {
    setEnvContext: _setEnvContext,
})(Routes));
