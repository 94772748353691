// @flow
import * as Sentry from '@sentry/browser';
import io from 'socket.io-client';

import { isObject } from '../../Utils/Object';

/**
 * Emit event with success/error handlers and cleanup afterwards.
 *
 * This helps us avoid memory leaks since `EventEmitter.once` only cleans up the listener
 *  once the event has been triggered.
 */
const emitWithHandlers = (
    socket: io,
    event: string,
    payload: *,
    successEvent: string,
    errorEvent: string,
    closeIfError: boolean
): Promise<any> => new Promise((resolve, reject) => {
    let onFailure;
    let timeout;

    // Note: This promise never resolves if the server does not give us a response
    const onSuccess = (data) => {
        if (timeout) {
            clearTimeout(timeout);
        }

        socket.off(errorEvent, onFailure);

        resolve(data);
    };

    onFailure = (e) => {
        if (timeout) {
            clearTimeout(timeout);
        }

        socket.off(successEvent, onSuccess);

        if (closeIfError) {
            socket.close();
        }

        const hasErrorData = e && (!isObject(e) || Object.keys(e).length > 0);
        const errorData = hasErrorData ? e : {
            error: 'Something went wrong',
            hint: 'empty error event',
            requestSocketEvent: undefined,
        };

        // Attach original command to the error so we can log better errors to sentry
        errorData.requestSocketEvent = event;

        Sentry.captureException(`onFailure.${JSON.stringify(errorData)}`);

        reject(errorData);
    };

    const cancel = () => {
        socket.off(errorEvent, onFailure);
        socket.off(successEvent, onSuccess);

        // Using object based error conforms to the format of socket event errors
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
            error: 'Timed out',
            event,
            details: `Did not receive ${successEvent} or ${errorEvent} within 10 seconds`,

            // Attach original command to the error so we can log better errors to sentry
            requestSocketEvent: event,
        });
    };

    // Timeout so the promise resolves even if we never receive any of the events
    timeout = setTimeout(cancel, 10000); // 10s (if you change this also change the rejection details above)

    socket.once(successEvent, onSuccess);
    socket.once(errorEvent, onFailure);

    socket.emit(event, payload);
});

export default emitWithHandlers;
