// @flow
import React, { useRef } from 'react';
import Button from 'react-toolbox/lib/button/Button';
import Dialog from '@material-ui/core/Dialog';

import Images from '../Themes/Images';

import FileDropFormStyles from './Styles/FileDropFormStyles';

type Props = {
    visible: boolean,
    disabled: boolean,
    acceptedFileTypes: string,

    onUploadFile: (file: any) => *,
    onCancelFileUpload: () => *,
};

export default function FileDropForm(props: Props) {
    const {
        acceptedFileTypes,
        visible,
        disabled,
        onUploadFile,
        onCancelFileUpload,
    } = props;

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const onCancelHandler = () => {
        if (onCancelFileUpload) {
            onCancelFileUpload();
        }
    };

    const handleDrag = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragIn = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragOut = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    // Event called when file is dropped in drag-and-drop zone
    const handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (onUploadFile && event.dataTransfer) {
            const { files, } = event.dataTransfer;
            for (let i = 0; i < files.length; i += 1) {
                const file = files[i];
                onUploadFile(file);
            }
        }
    };

    // Event called when file is selected from file list
    const handleFileSelect = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (onUploadFile && fileInputRef && fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
            const { files, } = fileInputRef.current;
            for (let i = 0; i < files.length; i += 1) {
                onUploadFile(files[i]);
            }
        }
    };

    return (
        <Dialog
            fullScreen
            onClose={onCancelHandler}
            open={visible}
            BackdropProps={{ style: FileDropFormStyles.dialogBackdrop, }}
            PaperProps={{ style: FileDropFormStyles.dialogPaper, }}
        >
            <div
                style={FileDropFormStyles.fileDropWrapper}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                onDragExit={handleDragOut}
                onDragEnter={handleDragIn}

            >
                <div style={FileDropFormStyles.fileDropForm}>
                    <img
                        style={FileDropFormStyles.uploadIcon}
                        src={Images.fileUpload}
                        alt="File Upload"
                    />
                    <label
                        style={FileDropFormStyles.fileDropFormButton}
                        className="btn"
                        htmlFor="fileDropInput"
                    >
                        Choose file from computer
                        <input
                            id="fileDropInput"
                            type="file"
                            accept={acceptedFileTypes}
                            multiple={false}
                            disabled={disabled}
                            ref={fileInputRef}
                            onChange={handleFileSelect}
                            style={{ display: 'none', }}
                        />
                    </label>
                    or drag and drop a file to upload
                    <div style={FileDropFormStyles.instructions}>
                        <h3>Note</h3>
                        <p>
                            File must be
                            <strong> SAVED </strong>
                            as a PDF,
                            <strong> NOT </strong>
                            printed as a PDF
                        </p>
                    </div>
                    <div>
                        <Button
                            style={FileDropFormStyles.cancelButton}
                            type="button"
                            flat
                            ripple={false}
                            onClick={onCancelHandler}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
