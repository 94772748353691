const logo = require('../Images/logo.png').default; // 42 x 42
const logoLarge = require('../Images/logoLarge.png').default; // 86 x 86
const logoSvg = require('../Images/icon-omnilife-health.svg').default;
const logoWhite = require('../Images/logoWhite.webp').default; // 600 x 600
const logoHeader = require('../Images/logoHeader.png').default;
const close = require('../Images/close.png').default;
const fileUpload = require('../Images/fileUpload.png').default;
const donorCreate = require('../Images/donorCreate.png').default; // https://fontawesome.com/license added '+' to heartbeat icon
const defaultProfile = require('../Images/defaultProfile.png').default;
const profileCircle = require('../Images/profileCircle.png').default;
const circledDefaultProfile = require('../Images/circledDefaultProfile.png').default;
const brokenMedia = require('../Images/brokenMedia.png').default;
const iconSearch = require('../Images/iconSearch.png').default;
const fileIcon = require('../Images/fileIcon.png').default;
const videoIcon = require('../Images/videoIcon.png').default;

const images = {
    logo,
    logoLarge,
    logoSvg,
    logoWhite,
    logoHeader,
    close,
    fileUpload,
    defaultProfile,
    profileCircle,
    circledDefaultProfile,
    brokenMedia,
    iconSearch,
    fileIcon,
    videoIcon,
    donorCreate,
};

export default images;
