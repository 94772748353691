// @flow
import type {
    ChatroomMessage, MessageType, UserProfile,
} from '../Utils/types';
import {
    ApplicationMessageTypes,
    ImageMessageTypes,
    VideoMessageTypes,
    MessageTypes,
} from '../Utils/types';

// URL to the media message download api
const mediaHost = process.env.REACT_APP_API_IMAGE_ROOT || '';
const apiKey = process.env.REACT_APP_API_KEY || '';

export type RemoteMediaUri = {
    uri: string,
    headers?: {
        Apikey: string,
        Authorization: string,
    },
};

const getMediaLocation = (chatId: number, pictureUri: string, accessToken: string): RemoteMediaUri => {
    const basePath = `chatrooms/${chatId}/messages/media/url/`;
    return {
        uri: `${mediaHost}${basePath}${encodeURIComponent(pictureUri)}`,
        headers: {
            Apikey: apiKey,
            Authorization: `Bearer ${accessToken}`,
        },
    };
};

const getDonorMediaLocation = (donorId: number, pictureUri: string, accessToken: string): RemoteMediaUri => {
    const basePath = `donor/${donorId}/file/`;

    return {
        uri: `${mediaHost}${basePath}${encodeURIComponent(pictureUri)}`,
        headers: {
            Apikey: apiKey,
            Authorization: `Bearer ${accessToken}`,
        },
    };
};

export type ChatMediaMessagePartial = {
    messageType: MessageType,
    fileName: string,
};

export const selectMediaMessageImage = (
    chatId: number,
    message: ChatroomMessage | ChatMediaMessagePartial,
    accessToken: string
): ?RemoteMediaUri => {
    let image = null;

    if (ImageMessageTypes.indexOf(message.messageType) !== -1 && message.fileName) {
        image = getMediaLocation(chatId, message.fileName, accessToken);
    }

    return image;
};

export const selectDonorMedia = (
    donorId: number,
    message: ChatMediaMessagePartial,
    accessToken: string
): ?RemoteMediaUri => {
    let image = null;

    if (message.messageType !== MessageTypes.unknown) {
        image = getDonorMediaLocation(donorId, message.fileName, accessToken);
    }

    return image;
};

export const selectDonorMediaVideo = (
    donorId: number,
    message: ChatMediaMessagePartial,
    accessToken: string
): ?RemoteMediaUri => {
    let video = null;

    if (VideoMessageTypes.indexOf(message.messageType) !== -1 && message.fileName) {
        video = getDonorMediaLocation(donorId, message.fileName, accessToken);
    }

    return video;
};

export const selectDonorMediaApplication = (
    donorId: number,
    message: ChatMediaMessagePartial,
    accessToken: string
): ?RemoteMediaUri => {
    let application = null;

    if (ApplicationMessageTypes.indexOf(message.messageType) !== -1 && message.fileName) {
        application = getDonorMediaLocation(donorId, message.fileName, accessToken);
    }

    return application;
};

export const selectMediaMessageVideo = (
    chatId: number,
    message: ChatroomMessage | ChatMediaMessagePartial,
    accessToken: string
): ?RemoteMediaUri => {
    let video = null;

    if (VideoMessageTypes.indexOf(message.messageType) !== -1 && message.fileName) {
        video = getMediaLocation(chatId, message.fileName, accessToken);
    }

    return video;
};

export const selectMediaMessageApplication = (
    chatId: number,
    message: ChatroomMessage | ChatMediaMessagePartial,
    accessToken: string
): ?RemoteMediaUri => {
    let application = null;

    if (ApplicationMessageTypes.indexOf(message.messageType) !== -1 && message.fileName) {
        application = getMediaLocation(chatId, message.fileName, accessToken);
    }

    return application;
};

const getPictureLocation = (pictureUri: string, accessToken: string, prefix: string = '') => {
    const basePath = 'user/profile/pic/';
    let finalUri = pictureUri;
    let cacheBuster = '';
    const pictureUriParts = pictureUri.split('&');

    if (pictureUriParts.length > 1) {
        [finalUri] = pictureUriParts;
        cacheBuster = `&${pictureUriParts.slice(1).join('&')}`;
    }

    return {
        uri: `${mediaHost}${basePath}${prefix}${encodeURIComponent(finalUri)}${cacheBuster}`,
        headers: {
            Apikey: apiKey,
            Authorization: `Bearer ${accessToken}`,
        },
    };
};

export const selectOtherProfileImage = (state: UserProfile, accessToken: string): * => {
    let image = '';
    if (state) {
        if (state.profilePicture) {
            image = getPictureLocation(state.profilePicture, accessToken);
        }
    }
    return image;
};
