// @flow
import type { Logout } from 'txp-core';

import type {
    NotifiableEvent,
} from '../Utils/types';
import type { ResetData } from './ApplicationActions';

export type NotificationState = {
    // Stand-alone array for now since we only have one notifiableGroupId.
    // In the future it could be a map that maps notifiableGroupId -> notifiableEvents
    notifiableEvents: Array<NotifiableEvent>,
};

const initialState: NotificationState = {
    notifiableEvents: [],
};

export type GetNotifiableEventGroup = { type: 'Notification/GET_NOTIFIABLE_EVENT_GROUP', notifiableGroupId: number };
export type ReceiveNotifiableEventGroup = { type: 'Notification/RECEIVE_NOTIFIABLE_EVENT_GROUP', notifiableEvents: Array<NotifiableEvent> };

export type Reset = { type: 'Notification/RESET' };

type Action =
    | GetNotifiableEventGroup
    | ReceiveNotifiableEventGroup
    | Reset
    | ResetData
    | Logout;

export const getNotifiableEventGroup = (notifiableGroupId: number) => ({
    type: 'Notification/GET_NOTIFIABLE_EVENT_GROUP',
    notifiableGroupId,
});

export const receiveNotifiableEventGroup = (notifiableEvents: Array<NotifiableEvent>): ReceiveNotifiableEventGroup => ({
    type: 'Notification/RECEIVE_NOTIFIABLE_EVENT_GROUP',
    notifiableEvents,
});

export const reset = (): Reset => ({
    type: 'Notification/RESET',
});

const reducer = (state: NotificationState = initialState, action: Action): NotificationState => {
    switch (action.type) {
        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
        case 'Notification/RESET':
            return {
                ...initialState,
            };

        case 'Notification/RECEIVE_NOTIFIABLE_EVENT_GROUP':
            return {
                ...state,

                notifiableEvents: action.notifiableEvents,
            };

        default:
            return state;
    }
};

export default reducer;
