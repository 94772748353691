// @flow
import type { Saga } from 'redux-saga';
import {
    put, select,
} from 'redux-saga/effects';
import { selectProfileOrganizationId } from 'txp-core';

import { apiFetch } from './ApiSaga';
import { setSagaMessage } from '../Redux/ApplicationActions';
import api from '../Services/Api';
import {
    finishLoading, startLoading,
} from '../Redux/LoadingActions';
import {
    receiveAllUsers,
} from '../Redux/NetworkActions';
import parseProfile from '../Utils/profile';

export function* getAllUsersSaga(): Saga<void> {
    yield put(startLoading('users'));

    const { result, error, } = yield apiFetch(api.txp.users);

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('', `${errorMessage}`, ''));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Loading users failed', 'Are you online?', ''));
        } else {
            yield put(setSagaMessage('Loading users failed', 'Try again later', ''));
        }
        yield put(finishLoading('users'));
    } else {
        const { users, } = result;

        const authState = yield select((state) => state.auth);
        const organizationId = selectProfileOrganizationId(authState);

        const organizationUsers = [];
        const otherUsers = [];
        for (let i = 0; i < users.length; i += 1) {
            const userProfile = parseProfile(users[i]);
            if (organizationId && users[i].organization_id === organizationId) {
                organizationUsers.push({ profile: userProfile, });
            } else {
                otherUsers.push({ profile: userProfile, });
            }
        }

        yield put(receiveAllUsers(organizationUsers, otherUsers));

        yield put(finishLoading('users'));
    }
}
