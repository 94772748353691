// @flow
import React from 'react';
import { Switch } from '@material-ui/core';

import styles from './Styles/GenericFormStyles';
import type { FormDefField } from '../Utils/types';
import FormLabel from './FormLabel';

type Props = {
    fieldDisabled: boolean,
    itemDef: FormDefField,
    itemData: any,
    onChange: (item: FormDefField, value: any) => *,
};

const FormSwitch = (props: Props) => {
    const {
        fieldDisabled,
        itemDef,
        itemData,
        onChange,
    } = props;

    if (!itemDef) return null;
    const value = itemData && itemData.value ? itemData.value : false;

    return (
        <div
            style={styles.formSwitchCheck}
        >
            <FormLabel item={itemDef} />
            <Switch
                disabled={fieldDisabled}
                checked={value}
                color="primary"
                onChange={(switchValue) => onChange(itemDef, switchValue.target.checked)}
            />
        </div>
    );
};

export default FormSwitch;
