// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import FontIcon from 'react-toolbox/lib/font_icon';
import { isAfter } from 'date-fns';
import ReactTooltip from 'react-tooltip';

import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../Themes/Colors';
import ChatListStyles from './Styles/ChatListStyles';
import CaseListStyles from './Styles/CaseListStyles';
import { donorChanged as _donorChanged, openDonor as _openDonor } from '../Redux/DonorActions';
import { formChanged as _formChanged } from '../Redux/FormActions';
import { getBadgeCount } from '../Utils/sortChatrooms';
import type { CaseRow, ChatroomInfo } from '../Utils/types';
import {
    DONOR_STATUS_VIEW,
    selectDonorView as _selectDonorView,
} from '../Redux/ApplicationActions';
import CaseTagDrawer from './CaseTagDrawer';

type Props = {
    row: CaseRow,
    formUpdated: boolean,
    donorUpdated: boolean,
    selected: boolean,
    muted: boolean,
    badgeCount: number,

    onShowDonorPanel: () => *,
    openDonor: (opoDonorId: number) => *,
    donorChanged: (status: boolean) => *,
    formChanged: (status: boolean) => *,
    selectDonorView: (view: string) => *,
};

class DonorListItem extends PureComponent<Props> {
    onOpenDonor = () => {
        const {
            row,
            onShowDonorPanel,
            openDonor,
            donorUpdated,
            formUpdated,
            donorChanged,
            formChanged,
            selectDonorView,
        } = this.props;

        if (formUpdated || donorUpdated) {
            const response = window.confirm('Unsaved changes\nAre you sure you want to discard your changes to this case?');
            if (response) {
                // clear some stuff down
                if (donorUpdated) donorChanged(false);
                if (formUpdated) formChanged(false);
            } else {
                return;
            }
        }
        selectDonorView(DONOR_STATUS_VIEW);
        openDonor(row.donorId);
        onShowDonorPanel();
    };

    onKeyDownOpenDonor = (e: { which: number }) => {
        const {
            row,
            onShowDonorPanel,
            openDonor,
        } = this.props;

        // Enter key pressed
        if (e.which === 13) {
            openDonor(row.donorId);
            onShowDonorPanel();
        }
    };

    render() {
        const {
            row,
            selected,
            muted,
            badgeCount,
        } = this.props;

        const {
            unosId,
            name,
            sex,
            age,
            createdOn,
            lastActivity,
            nextDueDate,
            tasksCompleted,
            tasksTotal,
            highRisk,
            tags,
            lastNote,
            workflow,
            isAdmin,
        } = row;

        return (
            <tr
                className="caseListRow"
                style={selected ? { ...CaseListStyles.caseRowSelected, } : { ...CaseListStyles.caseRow, }}
                role="button"
                tabIndex={0}
                onClick={this.onOpenDonor}
                onKeyPress={this.onKeyDownOpenDonor}
            >
                <td style={ChatListStyles.caseInfoColumn}>
                    <div style={ChatListStyles.caseTitle}>
                        {muted && (
                            <FontIcon
                                style={{ fontSize: '1rem', }}
                                value="notifications_off"
                                alt=""
                            />
                        )}
                        {name || 'Unnamed Case'}
                        {badgeCount > 0 ? (
                            <div style={ChatListStyles.countBadge}>
                                <span style={ChatListStyles.countBadgeText}>{badgeCount}</span>
                            </div>
                        ) : null}
                    </div>
                    <div style={ChatListStyles.caseInfo}>
                        {unosId ? `${name} ${unosId}` : null}
                    </div>
                    <div style={ChatListStyles.caseInfo}>
                        {workflow ? `Workflow: ${workflow}` : null}
                    </div>
                    <div style={ChatListStyles.caseInfo}>
                        {sex ? `Sex: ${sex}` : null}
                        {sex && age ? ' / ' : null}
                        {age ? `Age: ${age}` : null}
                    </div>
                </td>
                <td style={{ paddingLeft: '10px', }}>
                    {createdOn.value}
                </td>
                <td>
                    <div className="tag-column-container">
                        <div className="tag-column-boundary">
                            <CaseTagDrawer
                                highRisk={highRisk}
                                tags={tags || []}
                                entity="caseItem"
                            />
                        </div>
                    </div>
                </td>
                <td style={{ paddingLeft: '10px', }}>
                    {lastActivity.value.split(' at ').map((part, i) => (
                        <React.Fragment key={`part-${part}`}>
                            {i === 1 ? `at ${part}` : part}
                            {i === 0 && <br />}
                        </React.Fragment>
                    ))}
                </td>
                <td style={{ paddingLeft: '10px', }}>
                    {nextDueDate.value ? (
                        <span style={isAfter(Date.now(), nextDueDate.comp) ? { color: Colors.red, } : {}}>
                            {nextDueDate.value.split(' at ').map((part, i) => (
                                <React.Fragment key={`part-${part}`}>
                                    {i === 1 ? `at ${part}` : part}
                                    {i === 0 && <br />}
                                </React.Fragment>
                            ))}
                        </span>
                    ) : (
                        <span>None</span>
                    )}
                </td>
                <td style={{ paddingLeft: '10px', paddingRight: '10px', }}>
                    <ProgressBar mode="determinate" value={tasksCompleted} min={0} max={tasksTotal} className="roundedEdges" />
                    <div style={{ textAlign: 'right', fontSize: '0.75rem', color: '#999', }}>
                        <span>
                            {tasksCompleted}
                            &nbsp;/&nbsp;
                            {tasksTotal}
                        </span>
                    </div>
                </td>
                <td style={{ paddingLeft: '10px', }}>
                    <div className="case-last-note-container">
                        <span>
                            {lastNote ? lastNote.note : ''}
                        </span>
                    </div>
                </td>
                <td style={{ paddingLeft: '10px', }}>
                    {isAdmin && (
                        <>
                            <span data-tip="You have Admin access">
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faUserShield}
                                    color={Colors.brandPrimary}
                                />
                            </span>
                            <ReactTooltip />
                        </>
                    )}
                </td>
            </tr>
        );
    }
}

const mapStateToProps = (state, { row, }) => {
    const { donorId, } = row;
    const rooms: ChatroomInfo[] = (Object.values(state.chatList.chats): any);
    const caseRooms = rooms.filter((room) => room.donorId === donorId);
    const badgeCount = caseRooms.reduce((count, room) => count + (getBadgeCount(room) ?? 0), 0);

    return {
        formUpdated: state.forms.formUpdated,
        donorUpdated: state.donor.donorUpdated,
        // a case is considered muted if all rooms in the case are muted
        muted: caseRooms.length > 0 && caseRooms.every((room) => room.doNotDisturb),
        badgeCount,
    };
};

export default connect(mapStateToProps, {
    openDonor: _openDonor,
    donorChanged: _donorChanged,
    formChanged: _formChanged,
    selectDonorView: _selectDonorView,
})(DonorListItem);
