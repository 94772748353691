// @flow
import type { Saga } from 'redux-saga';
import { put, select, delay } from 'redux-saga/effects';
import { selectProfileOrganizationId } from 'txp-core';

import parseProfile from '../Utils/profile';
import api from '../Services/Api';
import { apiFetch, apiPost } from './ApiSaga';
import type { TriggerAskAlan } from '../Redux/ChatMessageActions';
import { pushError } from '../Redux/ApplicationActions';
import {
    receiveOffers,
    receiveOrganizations,
    getOrganizationsFailed,
    receiveSurgeons,
    receiveFullOffer,
    saveChat,
    saveChatFailed,
} from '../Redux/ChatEditActions';
import { startLoading, finishLoading } from '../Redux/LoadingActions';
import { keys } from '../Utils/Object';
import type { CreateOffer } from '../Redux/ChatEditActions';
import { singleErrorMessage } from '../Utils/flattenError';
import type { Organization } from '../Utils/types';
import { receiveAvailableTags } from '../Redux/DonorActions';
import { convertToOrganization } from '../Utils/organizations';

const askAlanRoute = `${process.env.REACT_APP_ASK_ALAN_BASE_URL || ''}?txpData=`;

type AskAlanPayload = {
    chatId: number,
    user: number,
    activationCode: string,
    orgId: number,
    targetOrgId: number,
    donorId: ?number,
    organId: ?number
};

const createAskAlanPayload = (
    chatId: number,
    user: number,
    activationCode: string,
    orgId: number,
    targetOrgId: number,
    donorId: ?number,
    organId: ?number
): AskAlanPayload => ({
    chatId,
    user,
    activationCode,
    orgId,
    targetOrgId,
    donorId,
    organId,
});

export function* triggerAskAlanSaga(action: TriggerAskAlan): Saga<void> {
    const {
        chatId,
        orgId,
        targetOrgId,
        userId,
        donorId,
        organId,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const refreshToken = yield select((state) => state.auth.refreshToken);

    const { result, error, } = yield apiPost(api.txp.createActivationCode, null, {
        refresh_token: refreshToken,
        chatroom_id: chatId,
    });

    let payload = null;
    if (error || !result) {
        yield put(pushError('Failed to get Activation Code', error));
        return;
    }
    payload = createAskAlanPayload(
        chatId,
        userId,
        result.activation_code,
        orgId,
        targetOrgId,
        donorId,
        organId
    );

    try {
        const jsonPayload = JSON.stringify(payload);
        const base64Payload = btoa(jsonPayload);

        window.open(askAlanRoute + base64Payload, 'AskAlan', `width=550,height=${window.innerHeight},left=0,menubar=0,status=0`);
    } catch (err) {
        yield put(pushError('Failed to start Snapshot', err));
    }
}

export function* createOfferSaga(action: CreateOffer): Saga<void> {
    const {
        unosId,
        matchId,
        organType,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const editData = yield select((state) => state.chatEdit);

    if (!unosId || !matchId || !organType) {
        const errors = {};

        if (!unosId) {
            errors.unosId = 'UNOS ID is required when creating a case.';
        }
        if (!matchId) {
            errors.matchId = 'Match ID is required when creating a case.';
        }
        if (!organType) {
            errors.organType = 'Organ type is required when creating a case.';
        }
        if (!editData.name) {
            errors.name = 'Room name is required.';
        }

        yield put(saveChatFailed(errors));

        return;
    }

    const orgId = yield select((state) => selectProfileOrganizationId(state.auth));

    const donorObject = {
        organization_id: orgId,
        identification: {
            unos_id: unosId,
            match_id: matchId,
            organ_type: organType,
        },
        demographics: {},
        admission: {},
        history: {},
        transplant: {},
        medications: {},
        labs: {},
        observations: {},
        misc: {},
    };

    const { result, error, } = yield apiPost(api.txp.createOffer, null, donorObject);

    if (error) {
        const errors = {};
        if (error.isValidationError) {
            errors.nonFieldError = singleErrorMessage(error);
            yield put(saveChatFailed(errors));
        } else if (error.isInvalidResponseCode) {
            errors.nonFieldErrors = 'Donor record has already been created.';
            yield put(saveChatFailed(errors));
        } else if (error.isNetworkError) {
            errors.nonFieldError = 'Creating room failed, are you online?';
            yield put(saveChatFailed(errors));
        } else {
            errors.nonFieldError = 'Creating room failed, try again later.';
            yield put(saveChatFailed(errors));
        }
    } else {
        const {
            id,
            name,
            description,
            message,
            mentionedPendingUsers,
            attachments,
            managed,
        } = editData;

        const pendingUsers = yield select((state) => state.chatEdit.pendingUsers);

        yield put(saveChat(
            id,
            name,
            pendingUsers,
            managed,
            message,
            mentionedPendingUsers,
            description,
            attachments,
            result.donor_id,
            result.organ_id,
            0,
            null,
            'Case'
        ));
    }
}

export function* loadOffersSaga(): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('offers'));

    const { result, error, } = yield apiFetch(api.txp.getOffers);

    if (error) {
        yield put(pushError('Failed to get offers', error));
        yield put(finishLoading('offers'));
    } else {
        const { donors, } = result;

        const offers = [];
        // $FlowFixMe
        let offer = {};

        const donorKeys = keys(donors);

        for (let i = 0; i < donorKeys.length; i += 1) {
            const donorInfo = donors[donorKeys[i]];
            for (let j = 0; j < donorInfo.organs.length; j += 1) {
                const organInfo = donorInfo.organs[j];

                offer = {};
                offer.donorId = parseInt(donorKeys[i], 10);
                offer.unosId = donorInfo.unos_id;
                offer.organId = organInfo.organ_id;
                offer.matchId = organInfo.match_id;
                offer.organType = organInfo.organ_type;

                offers.push(offer);
            }
        }

        offers.sort((a, b) => ((a.donorId < b.donorId) ? 1 : -1));

        yield put(receiveOffers(offers));

        yield put(finishLoading('offers'));
    }
}

export function* loadOrganizationsSaga(): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('organizations'));

    const { result, error, } = yield apiFetch(api.txp.getOrganizations);

    if (error) {
        yield put(pushError('Failed to get organizations', error));
        yield put(getOrganizationsFailed());
        yield put(finishLoading('organizations'));
    } else {
        const organizations: Organization[] = [];
        const orgArray = result.organizations;

        const orgId = yield select((state) => selectProfileOrganizationId(state.auth));

        for (let i = 0; i < orgArray.length; i += 1) {
            const singleOrg = convertToOrganization(orgArray[i]);
            organizations.push(singleOrg);

            if (singleOrg.id === orgId) {
                yield put(receiveAvailableTags(singleOrg.availableTags));
            }
        }

        organizations.sort(compare);

        yield put(receiveOrganizations(organizations));
        yield put(finishLoading('organizations'));
    }
}

export function* loadSurgeonsSaga(): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('surgeons'));

    const organizationId = yield select((state) => state.chatEdit.organization.id);

    const { result, error, } = yield apiFetch(api.txp.getOrganizationMembers, {
        orgId: organizationId,

    }, {
        organizational_role: 'Surgeon',
    });

    if (error) {
        yield put(pushError('Failed to get surgeons', error));
        yield put(finishLoading('surgeons'));
    } else {
        const surgeons = [];
        const surgeonsArray = result.organization_members;

        for (let i = 0; i < surgeonsArray.length; i += 1) {
            surgeons.push(parseProfile(surgeonsArray[i].user_information));
        }

        yield put(receiveSurgeons(surgeons));

        yield put(finishLoading('surgeons'));
    }
}

export function* getOfferSaga(): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const donorId = yield select((state) => state.chatEdit.offer.donorId);
    const organId = yield select((state) => state.chatEdit.offer.organId);

    const { result, error, } = yield apiFetch(api.txp.getOffer, {
        donorId,
        organId,
    });

    if (error) {
        yield put(pushError('Failed to get offer', error));
    } else {
        yield put(receiveFullOffer(result));
    }
}

// Sort array of organization objects by the organization name (A-Z)
// https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/
function compare(a, b) {
    const orgA = a.name.toUpperCase();
    const orgB = b.name.toUpperCase();

    let comparison = 0;

    if (orgA > orgB) {
        comparison = 1;
    } else if (orgA < orgB) {
        comparison = -1;
    }

    return comparison;
}
