// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    TextField,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Checkbox,
} from '@material-ui/core';

import { selectProfileName } from 'txp-core';
import LoadingIndicator from 'react-loading-indicator';

import {
    donorChanged as _donorChanged,
    updateDonor as _updateDonor,
    getDonor as _getDonor,
} from '../Redux/DonorActions';
import {
    selectDonorView as _selectDonorView,
    DONOR_STATUS_VIEW,
} from '../Redux/ApplicationActions';
import styles from './Styles/DonorStyles';
import { formatFullDateTime } from '../Utils/time';
import ChatListStyles from './Styles/ChatListStyles';
import hasValue from '../Utils/hasValue';
import type { Donor, Tag } from '../Utils/types';
import DonorPanelNavigation from './DonorPanelNavigation';
import { tagsAreEqual } from '../Utils/tags';
import TagsAutocomplete from './TagsAutocomplete';
import CaseNoteTimeline from './CaseNoteTimeline';

type Props = {
    donorId: number,
    donor: Donor,
    creatorName: string,
    donorUpdated: boolean,
    isConnected: boolean,
    savingDonor: boolean,
    loading: boolean,
    canUpdate: boolean,
    hideBackSaveButtons: boolean,
    availableTags: Array<Tag>,

    donorChanged: (donorUpdated: boolean) => *,
    updateDonor: typeof _updateDonor,
    getDonor: (donorId: number) => *,
    selectDonorView: (viewName: string) => *,
};

type State = {
    opoDonorIdInput: string,
    highRiskInput: boolean,
    tagsInput: Array<Tag>,
};

type DonorInput = {
    opoDonorIdInput?: string,
    highRiskInput?: boolean,
    tagsInput?: Array<Tag>,
};

class DonorDetails extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        const {
            donor: {
                opoDonorId,
                highRisk,
                tags,
            },
        } = this.props;

        this.state = {
            opoDonorIdInput: opoDonorId || '',
            highRiskInput: highRisk || false,
            tagsInput: tags || [],
        };
    }

    componentDidMount() {
        const {
            donor,
            donorId,
            donorChanged,
            getDonor,
        } = this.props;

        if (Object.keys(donor).length === 0) {
            getDonor(donorId);
        }

        donorChanged(false);
    }

    componentDidUpdate(prevProps: Props) {
        const {
            donorId: prevDonorId,
            isConnected: wasConnected,
        } = prevProps;

        const {
            donor: {
                opoDonorId,
                highRisk,
                tags,
            },
            donorId,
            isConnected,
            getDonor,
        } = this.props;

        if (donorId) {
            if ((prevDonorId !== donorId && isConnected) || (isConnected && (!wasConnected))) {
                getDonor(donorId);
            }
        }

        if (prevProps.donor.opoDonorId !== opoDonorId) {
            this.setNodeValue('opoDonorIdInput', opoDonorId || '');
        }

        if (prevProps.donor.highRisk !== highRisk) {
            this.setNodeValue('highRiskInput', highRisk || false);
        }

        if (!tagsAreEqual(prevProps.donor.tags || [], tags || [])) {
            this.setNodeValue('tagsInput', tags || []);
        }
    }

    componentWillUnmount() {
        const {
            donorChanged,
        } = this.props;

        donorChanged(false);
    }

    onBackClick = () => {
        const { selectDonorView, donorUpdated, } = this.props;
        if (donorUpdated) {
            const response = window.confirm('Unsaved changes\nAre you sure you want to discard your changes to this case?');
            if (response) {
                selectDonorView(DONOR_STATUS_VIEW);
            }
        } else {
            selectDonorView(DONOR_STATUS_VIEW);
        }
    }

    onSaveDonor = () => {
        const {
            donorId,
            donor,
            updateDonor,
        } = this.props;

        // TODO:
        // we no longer display/edit dob, typeOfDeath, causeOfDeath, race, sex
        // weight, height, etc., but the updateDonor action expects them. Until we know if we'll
        // use updateDonor to edit donor details in another place, I'll leave them here
        // We probably could just create a different update action without those parameters, too
        const {
            lastModified,
            unosId,
            dob,
            weight,
            height,
            currentLocation,
        } = donor;

        const {
            opoDonorIdInput,
            highRiskInput,
            tagsInput,
        } = this.state;

        updateDonor(
            donorId,
            lastModified,
            opoDonorIdInput,
            highRiskInput,
            tagsInput,
            unosId || null,
            currentLocation || '',
            dob || '',
            null,
            null,
            null,
            null,
            weight || '',
            height || '',
            []
        );
    }

    setNodeValue(
        // eslint-disable-next-line max-len
        key: 'opoDonorIdInput' | 'highRiskInput' | 'tagsInput',
        value: string | boolean | Array<Tag>
    ) {
        const {
            donor: {
                opoDonorId,
                highRisk,
                tags,
            },
            donorChanged,
        } = this.props;

        if (!hasValue(value)) {
            return;
        }

        this.setState((prevState: State) => {
            const newState: DonorInput = {};
            const mergedState: DonorInput = { ...prevState, };

            if (key === 'highRiskInput') {
                const booleanValue = !!value;
                newState.highRiskInput = booleanValue;
                mergedState.highRiskInput = booleanValue;
            } else if (key === 'tagsInput') {
                newState.tagsInput = Array.isArray(value) ? value : [];
                mergedState.tagsInput = Array.isArray(value) ? value : [];
            } else {
                const stringValue = String(value);
                newState[key] = stringValue;
                mergedState[key] = stringValue;
            }

            // If any form input values differ from the prop values, the form has been updated
            const updated = (mergedState.opoDonorIdInput !== opoDonorId && opoDonorId !== null && opoDonorId !== undefined)
                || mergedState.highRiskInput !== highRisk
                || !tagsAreEqual(mergedState.tagsInput ? mergedState.tagsInput : [], tags);

            donorChanged(updated);

            return newState;
        });
    }

    render() {
        const {
            canUpdate,
            donor,
            creatorName,
            // donorUpdated,
            loading,
            savingDonor,
            hideBackSaveButtons,
            availableTags,
            donorId,
        } = this.props;

        const {
            opoDonorId,
            createDate,
        } = donor;

        const {
            opoDonorIdInput,
            highRiskInput,
            tagsInput,
        } = this.state;

        const organsList = donor.organs ? donor.organs.map((organ: any) => organ.organType) : [];

        if (loading) {
            return (
                <div style={ChatListStyles.loadingIndicator}>
                    <LoadingIndicator />
                </div>
            );
        }

        // const saveDisabled = !donorUpdated || savingDonor;
        const inputDisabled = !canUpdate || savingDonor;

        return (
            <div>
                <DonorPanelNavigation title="Case Details" onSaveClick={this.onSaveDonor} hideBackSaveButtons={hideBackSaveButtons} hideSave={false} />
                <div style={styles.detailsWrapper}>
                    <div style={styles.detailsTitleWrapper}>
                        <span style={styles.detailsTitleText}>{opoDonorId}</span>
                        <span style={styles.detailsSubtitleText}>{`Created by ${creatorName}`}</span>
                        <span style={styles.detailsSubtitleText}>{`on ${formatFullDateTime(createDate)}`}</span>
                    </div>
                    <div style={styles.detailsInput}>
                        <TextField
                            disabled={inputDisabled}
                            fullWidth
                            color="primary"
                            label="Case ID:"
                            type="text"
                            InputLabelProps={{ shrink: true, }}
                            value={opoDonorIdInput}
                            onChange={(event) => this.setNodeValue('opoDonorIdInput', event.target.value)}
                        />
                    </div>
                    <div style={styles.detailsInput}>
                        <FormControl component="fieldset" disabled={inputDisabled}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={highRiskInput}
                                        onChange={(event) => this.setNodeValue('highRiskInput', event.target.checked)}
                                        name="highRisk"
                                        color="secondary"
                                    />
                                )}
                                label="Risk Criteria"
                            />
                            <FormHelperText>Cases with risk criteria may have additional tasks that need to be completed</FormHelperText>
                        </FormControl>
                    </div>
                    <div style={styles.detailsInput}>
                        <FormControl
                            variant="standard"
                            fullWidth
                        >
                            <TagsAutocomplete
                                disabled={inputDisabled}
                                availableTags={availableTags}
                                selectedTags={tagsInput}
                                onChange={(event, newTags) => this.setNodeValue('tagsInput', newTags)}
                            />
                        </FormControl>
                    </div>
                    {donor.donorType === 'Import'
                        ? (
                            <>
                                <div style={styles.detailsInputLabel}>
                                    Organs:
                                </div>
                                <div style={styles.detailsInput}>
                                    {organsList.length === 0 ? 'No organs for this donor' : organsList.join(', ')}
                                </div>
                            </>
                        ) : null}
                </div>
                <div style={styles.donorHeaderWrapper}>
                    <div style={styles.donorHeaderLabel}>
                        Case Notes
                    </div>
                    <CaseNoteTimeline notes={donor.notes} caseId={donorId} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const donorId = state.donor.openDonorId || -1;
    const donor = state.donor.donors && state.donor.donors[donorId] ? state.donor.donors[donorId] : {};
    const creatorId = donor && donor.userId ? donor.userId : -1;

    const allUsers = (state.network.organizationUsers || []).concat((state.network.otherUsers || []));
    const memberIndex = allUsers.findIndex((x) => x.profile.userId === creatorId);

    const creatorName = memberIndex !== -1 ? selectProfileName(allUsers[memberIndex].profile) : 'OmniLife User';

    return {
        donorId,
        donor,
        creatorName,
        savingDonor: state.loading.updateDonor,
        loading: state.loading.donor,
        donorUpdated: state.donor.donorUpdated,
        isConnected: state.chatList.socketStatus === 'connected',
        availableTags: state.donor.availableTags,
    };
};

export default connect(mapStateToProps, {
    donorChanged: _donorChanged,
    updateDonor: _updateDonor,
    getDonor: _getDonor,
    selectDonorView: _selectDonorView,
})(DonorDetails);
