// @flow
import React, {
    useState, useEffect, useMemo, useCallback,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSort,
    faSortDown,
    faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from 'react-loading-indicator';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash.debounce';
import {
    Checkbox, FormControl, Select, InputLabel, Chip, Box, TablePagination, MenuItem,
} from '@material-ui/core';
import {
    updateProfile as _updateProfile,
} from 'txp-core';

import Search from './Search';
import ChatListStyles from './Styles/ChatListStyles';
import CaseListStyles from './Styles/CaseListStyles';

import type {
    CaseRow,
    DonorFilter,
    DonorSortBy,
    UserProfile,
    LoadCasesParams,
} from '../Utils/types';
import {
    loadCases as _loadCases,
    updateTagFilter as _updateTagFilter,
    updateWorkflowTypeFilter as _updateWorkflowTypeFilter,
    setSort as _setSort,
} from '../Redux/DonorActions';
import { getCaseRows } from '../Selectors/Donor';
import DonorListItem from './DonorListItem';
import CaseTag from './CaseTag';
import { useWorkflowOptions, useTagFilters } from '../Utils/hooks';

const caseStatuses = [
    { value: 'All', label: 'All cases', },
    { value: 'Open', label: 'Open cases', },
    { value: 'Closed', label: 'Closed cases', }
];

const riskFilters = [
    { value: 'True', label: 'Risk Criteria', },
    { value: 'False', label: 'Normal-risk', },
    { value: 'All', label: 'All', }
];

type Props = {
    // Passed props
    onShowDonorPanel: () => *,

    // Connected props
    profile: UserProfile,
    donorFilter: DonorFilter,
    sort: { column: DonorSortBy, direction: SortDirection },
    loadingApplicationData: boolean,
    loadingSearch: boolean,
    loadingCases: boolean,
    openDonorId: ?number,
    filterTags: string[],
    workflowTypeFilters: string[],

    loadCases: (params: LoadCasesParams) => *,
    updateProfile: (user: UserProfile) => *,
    setSort: typeof _setSort,
    updateTagFilter: (tags: string[]) => *,
    updateWorkflowTypeFilter: (workflowTypes: string[]) => *,
};

const DonorList = ({
    profile,
    donorFilter,
    filterTags,
    workflowTypeFilters,
    loadCases,
    updateProfile,
    sort,
    setSort,
    updateTagFilter,
    updateWorkflowTypeFilter,
    openDonorId,
    onShowDonorPanel,
    loadingApplicationData,
    loadingSearch,
    loadingCases,
}: Props) => {
    const donorSortBy = sort.column;
    const donorSortDirection = sort.direction;
    const [donorFilterValue, setDonorFilterValue] = useState(donorFilter
        ? { ...donorFilter, filterTags, workflowTypeFilters, }
        : {
            status: 'Open', donorType: 'All', filterTags, workflowTypeFilters,
        });

    // State for filters
    const [caseStatusFilter, setCaseStatusFilter] = useState<string>(donorFilterValue.status);
    const [riskCriteriaFilter, setRiskCriteriaFilter] = useState<string>('All');
    const [filterTagsValue, setFilterTagsValue] = useState(filterTags);
    const [selectedFilterTagsValue, setSelectedFilterTagsValue] = useState(filterTags);
    const [workflowTypeFiltersValue, setWorkflowTypeFiltersValue] = useState(workflowTypeFilters);
    const [selectedWorkflowTypeFiltersValue, setSelectedWorkflowTypeFiltersValue] = useState(workflowTypeFilters);
    const [searchText, setSearchText] = useState<string>('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const { data: workflowOptions = [], } = useWorkflowOptions();
    const { data: tagFilters = [], } = useTagFilters();
    // Convert Tags into the filter objects
    const tagFilterOptions = tagFilters.map((tag) => ({ label: tag.label, value: tag.tag_id, }));
    const cases = useSelector((state) => getCaseRows(state, workflowOptions));
    const availableTags = useSelector((state) => state.donor.availableTags); // Get available tags for Tag rendering info
    const totalCasesCount = useSelector((state) => state.donor.totalFilteredCases);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            },
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
        },
    };

    const buildLoadCasesParams = () => {
        let riskCriteria = null;
        if (riskCriteriaFilter === 'True') {
            riskCriteria = true;
        }
        if (riskCriteriaFilter === 'False') {
            riskCriteria = false;
        }
        const tagIds = filterTagsValue.map((tag) => parseInt(tag, 10));
        const params: LoadCasesParams = {
            status: caseStatusFilter,
            tags: tagIds,
            workflows: workflowTypeFiltersValue,
            riskCriteria,
            search: searchText,
            page: page + 1, // NOTE: TablePagination has first page of 0, but request expects first page to be 1
            resultsPerPage: rowsPerPage,
            sortBy: donorSortBy,
            sortDirection: donorSortDirection,
        };
        return params;
    };

    useEffect(() => {
        const params = buildLoadCasesParams();
        onSelectStatusFilter(caseStatusFilter);
        loadCases(params);
    }, [
        caseStatusFilter,
        riskCriteriaFilter,
        filterTagsValue,
        workflowTypeFiltersValue,
        page,
        rowsPerPage,
        donorSortBy,
        donorSortDirection,
        loadCases
    ]);

    const workflowTypeFilter = useMemo(() => {
        // NOTE: Workflows are now built with the Workflow Builder, which allows for updating existing workflows. In those
        //     : cases, the Workflow key is automatically appending with a '-v<d>' suffix to indicate the current version
        //     : of the workflow and associate a lineage from the parent workflow. When we are filtering the cases by
        //     : Workflow, the user really only cares what the name of the workflow appears as - to achieve that we trim
        //     : the version identifier from the key and match on the non-versioned part of the key. Ideally here we could
        //     : just use the 'Workflow Name', however the Donor object only contains the key, so we perpetuate the trimmed
        //     : key throughout to filter on
        const unversionedWorkflowOptions = workflowOptions.map((workflow) => ({
            label: workflow.name,
            value: workflow.key.replace(/-v\d+$/, ''),
        }));
        return unversionedWorkflowOptions;
    }, [workflowOptions]);

    const onSelectDonorSortBy = (sortBy: DonorSortBy) => {
        const sortDirection = sortBy === donorSortBy
            ? (donorSortDirection === 'asc' ? 'desc' : 'asc')
            : 'desc';

        setSort(sortBy, sortDirection);
    };

    const onSelectStatusFilter = (statusFilter: string) => {
        // don't make updateProfile call if they chose the same sort
        if (statusFilter !== donorFilterValue.status) {
            const newDonorFilter = { status: statusFilter, donorType: donorFilterValue.donorType, };
            const newProfile = { ...profile, donorFilter: newDonorFilter, };
            updateProfile(newProfile);
            setDonorFilterValue(newDonorFilter);
        }
    };

    const onSelectTagFilter = () => {
        updateTagFilter(selectedFilterTagsValue);
        setFilterTagsValue(selectedFilterTagsValue);
        setPage(0);
    };

    const onSelectWorkflowFilter = () => {
        updateWorkflowTypeFilter(selectedWorkflowTypeFiltersValue);
        setWorkflowTypeFiltersValue(selectedWorkflowTypeFiltersValue);
        setPage(0);
    };

    const debouncedSearchTextInput = useCallback(
        debounce((value) => {
            setPage(0);
            const params = buildLoadCasesParams();
            params.search = value;
            loadCases(params);
        }, 500),
        [caseStatusFilter, filterTagsValue, workflowTypeFiltersValue, riskCriteriaFilter]
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderCases = () => {
        const noCasesFound = cases.length === 0;

        const getChipLabel = (options: {value: any, label: string}[], value: any) => {
            const option = options.find((opt) => opt.value === value);
            if (option) {
                return option.label;
            }
            return value;
        };

        return (
            <div>
                {(loadingSearch || loadingCases) && (
                    <div style={ChatListStyles.loadingIndicatorOverlay}>
                        <LoadingIndicator />
                        <div style={ChatListStyles.smallLeftMargin}>
                            Loading Cases...
                        </div>
                    </div>
                )}
                <div style={{ ...ChatListStyles.caseSearchBlock, zIndex: 0, }}>
                    <div style={ChatListStyles.searchRowWithSort}>
                        <Search
                            placeholder="Search cases..."
                            value={searchText}
                            filterContent={(value: string) => {
                                debouncedSearchTextInput(value);
                                setSearchText(value);
                            }}
                        />
                    </div>
                    <div style={CaseListStyles.paginationFlex}>
                        <FormControl style={CaseListStyles.selectFormControl}>
                            <InputLabel id="case-status-filter-label">Case Status</InputLabel>
                            <Select
                                labelId="case-status-filter-label"
                                id="case-status-filter"
                                fullWidth
                                onChange={(event) => setCaseStatusFilter(event.target.value)}
                                renderValue={(value) => getChipLabel(caseStatuses, value)}
                                value={caseStatusFilter}
                                MenuProps={MenuProps}
                            >
                                {caseStatuses.map((status) => (
                                    <MenuItem value={status.value} key={status.value}>{status.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div>
                            <FormControl style={CaseListStyles.selectFormControl}>
                                <InputLabel id="risk-criteria-filter-label">Risk Criteria</InputLabel>
                                <Select
                                    labelId="risk-criteria-filter-label"
                                    id="risk-criteria-filter"
                                    fullWidth
                                    onChange={(event) => setRiskCriteriaFilter(event.target.value)}
                                    renderValue={(value) => getChipLabel(riskFilters, value)}
                                    value={riskCriteriaFilter}
                                    MenuProps={MenuProps}
                                >
                                    {riskFilters.map((risk) => (
                                        <MenuItem value={risk.value} key={risk.value}>{risk.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl style={CaseListStyles.selectFormControl}>
                                <InputLabel id="tags-filter-label">Tags</InputLabel>
                                <Select
                                    labelId="tags-filter-label"
                                    id="tags-filter"
                                    fullWidth
                                    multiple
                                    onChange={(event) => setSelectedFilterTagsValue(event.target.value)}
                                    onClose={() => onSelectTagFilter()}
                                    renderValue={(selected) => (
                                        <Box style={CaseListStyles.selectChipBox}>
                                            {selected.map((value) => {
                                                const tag = availableTags.find((t) => t.tagId === value);
                                                if (tag) {
                                                    return <CaseTag tag={tag} />;
                                                }
                                                return null;
                                            })}
                                        </Box>
                                    )}
                                    value={selectedFilterTagsValue}
                                    MenuProps={MenuProps}
                                >
                                    {tagFilterOptions.map((tag) => (
                                        <MenuItem value={tag.value} key={tag.value}>
                                            <Checkbox checked={selectedFilterTagsValue.includes(tag.value)} />
                                            {tag.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl style={CaseListStyles.selectFormControl}>
                                <InputLabel id="workflow-filter-label">Workflow</InputLabel>
                                <Select
                                    labelId="workflow-filter-label"
                                    id="workflow-filter"
                                    fullWidth
                                    multiple
                                    onChange={(event) => setSelectedWorkflowTypeFiltersValue(event.target.value)}
                                    onClose={() => onSelectWorkflowFilter()}
                                    renderValue={(selected) => (
                                        <Box style={CaseListStyles.selectChipBox}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={getChipLabel(workflowTypeFilter, value)} />
                                            ))}
                                        </Box>
                                    )}
                                    value={selectedWorkflowTypeFiltersValue}
                                    MenuProps={MenuProps}
                                >
                                    {workflowTypeFilter.map((workflow) => (
                                        <MenuItem key={workflow.value} value={workflow.value}>
                                            <Checkbox checked={selectedWorkflowTypeFiltersValue.includes(workflow.value)} />
                                            {workflow.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingInline: '10px', }}>
                    <table className="case-table">
                        <thead>
                            <tr>
                                <TH
                                    sortable
                                    col="NAME"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('NAME')}
                                >
                                    Case
                                </TH>
                                <TH
                                    sortable
                                    col="CREATE_DATE"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('CREATE_DATE')}
                                >
                                    Created
                                </TH>
                                <th>Tags</th>
                                <TH
                                    sortable
                                    col="LAST_ACTIVITY"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('LAST_ACTIVITY')}
                                >
                                    Last Activity
                                </TH>
                                <TH
                                    sortable
                                    col="NEXT_DUE_DATE"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('NEXT_DUE_DATE')}
                                >
                                    Next Due Date
                                </TH>
                                <th>Case Progress</th>
                                <th>Case Note</th>
                                {/* eslint-disable-next-line */}
                                <th aria-label="" />
                            </tr>
                        </thead>
                        <tbody>
                            {cases.map((row: CaseRow) => (row && row.donorId
                                ? (
                                    <DonorListItem
                                        row={row}
                                        key={row.donorId}
                                        onShowDonorPanel={onShowDonorPanel}
                                        selected={row.donorId === openDonorId}
                                    />
                                ) : null))}
                            {noCasesFound
                                ? (
                                    <tr>
                                        <td
                                            colSpan={7}
                                            style={{ textAlign: 'center', }}
                                        >
                                            <span style={ChatListStyles.noChatroomsFound}>
                                                No donors found matching the query.
                                            </span>
                                        </td>
                                    </tr>
                                ) : null}
                        </tbody>
                    </table>
                    <TablePagination
                        component="div"
                        count={totalCasesCount}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                    />
                </div>
            </div>
        );
    };

    return (
        <div style={{ ...ChatListStyles.wrapper, ...ChatListStyles.borderRight, }}>
            {loadingApplicationData
                ? (
                    <div style={ChatListStyles.loadingIndicator}>
                        <LoadingIndicator />
                        <div style={ChatListStyles.smallLeftMargin}>
                            Loading Data...
                        </div>
                    </div>
                ) : (
                    <div>
                        <ReactTooltip />
                        {renderCases()}
                    </div>
                )}
        </div>
    );
};

const defaultSort = {
    column: 'CREATE_DATE',
    direction: 'desc',
};

const mapStateToProps = (state) => {
    const { profile, } = state.auth;

    return {
        profile,
        openDonorId: state.donor.openDonorId,
        sort: state.donor?.sort ?? defaultSort,
        donorFilter: profile.donorFilter,
        loadingApplicationData: state.loading.applicationData,
        loadingSearch: state.loading.search,
        loadingCases: state.loading.cases,
        filterTags: state.donor.caseTagFilter,
        workflowTypeFilters: state.donor.workflowTypes,
    };
};

export default connect(mapStateToProps, {
    updateProfile: _updateProfile,
    loadCases: _loadCases,
    updateTagFilter: _updateTagFilter,
    updateWorkflowTypeFilter: _updateWorkflowTypeFilter,
    setSort: _setSort,
})(DonorList);

type SortDirection = 'asc' | 'desc';

/* eslint-disable react/require-default-props */
type THProps = {
    children: React$Node;
    sortable: boolean;
    col?: string;
    sortKey?: string;
    sortDirection?: SortDirection;
    onClick?: () => *,
};

function TH({
    sortable = false,
    col = '',
    sortKey = '',
    sortDirection = 'desc',
    onClick,
    children,
}: THProps) {
    const activelySorting = sortable && col === sortKey;
    const classes = `${sortable ? 'sortable' : ''} ${activelySorting ? `sorted sorted-${sortDirection}` : ''}`;
    const faSortIcon = activelySorting ? (sortDirection === 'asc' ? faSortUp : faSortDown) : faSort;

    return (
        <th
            className={classes}
            onClick={onClick}
        >
            {children}
            {sortable ? <FontAwesomeIcon icon={faSortIcon} /> : null}
        </th>
    );
}
