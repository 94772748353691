// @flow
import React, { Component } from 'react';
import type { ElementRef } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fade from 'react-reveal/Fade';
import Input from 'react-toolbox/lib/input';
import Button from 'react-toolbox/lib/button/Button';
import Autocomplete from 'react-toolbox/lib/autocomplete';
import { MenuItem, TextField } from '@material-ui/core';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {
    resetData as _resetData,
    signup as _signup,
    sendSMSCode as _sendSMSCode,
    verifySMSCode as _verifySMSCode,
    checkRegistrationEmailVerification as _checkRegistrationEmailVerification,
    resendRegistrationEmail as _resendRegistrationEmail,
    updateRegistrationPhone as _updateRegistrationPhone,
    updateRegistrationEmail as _updateRegistrationEmail,
    joinOrganization as _joinOrganization,
} from 'txp-core';
import type { SignupError, SMSError } from 'txp-core';
import {
    getOrganizations as _getOrganizations,
} from '../Redux/ChatEditActions';

import ApplicationStyles from '../Themes/ApplicationStyles';
import AuthStyles from './Styles/AuthStyles';
import PhoneInputTextField from '../Components/PhoneInputTextField';
import VerificationCodeInput from '../Components/VerificationCodeInput';
import LogoSpin from '../Components/LogoSpin';
import RegistrationStyles from './Styles/RegistrationStyles';
import SagaMessage from '../Components/SagaMessage';
import { popError as _popError, setSagaMessage as _setSagaMessage } from '../Redux/ApplicationActions';
import PasswordRequirements from '../Components/PasswordRequirements';
import { formatPhone } from '../Utils/serializePhone';
import type { AppError, Organization } from '../Utils/types';
import roles from '../Utils/roles';
import RetryButton from '../Components/RetryButton';
import SkipButton from '../Components/SkipButton';

type Props = {
    applicationErrors: Array<AppError>,
    signupError: ?SignupError,
    smsError: ?SMSError,
    signupActive: boolean,
    SMSActive: boolean,
    updatePhoneActive: boolean,
    updateEmailActive: boolean,
    signedUp: boolean,
    phone: string,
    email: string,
    correlationId: string,
    verifyPhoneSuccess: boolean,
    verifyPhoneFailed: boolean,
    checkEmailSuccess: boolean,
    checkEmailFailed: boolean,
    profileLoaded: boolean,
    organizations: Array<Organization>,
    organizationId: number,
    loadOrgsFailed: boolean,
    isLoadingOrganizations: boolean,

    signup: (email: string, password: string, phone: string, application: string) => *,
    checkRegistrationEmailVerification: (email: string, correlationId: string) => *,
    resendRegistrationEmail: (email: string) => *,
    sendSMSCode: (phone: string) => *,
    verifySMSCode: (phone: string, passcode: string) => *,
    updateRegistrationPhone: (oldPhone: string, newPhone: string, password: string) => *,
    updateRegistrationEmail: (email: string, emailInput: string, passwordInput: string) => *,
    joinOrganization: (organizationId: number, organizationName: string, organizationRole: string) => *,
    setSagaMessage: (heading: string, message: string, label: string, isDialog?: boolean) => *,
    popError: () => *,
    resetData: () => *,
    getOrganizations: () => *,

    // Injected by withRouter(), I think we will need to use a tool like flow-typed to get flow definitions for react-router
    history: *
};

type State = {
    formValid: boolean,
    showPassword: boolean,
    showPasswordReq: boolean,
    updatingPhone: boolean,
    updatingEmail: boolean,
    isErrorVisible: boolean,
    emailInput: string,
    passwordInput: string,
    phoneInput: string,
    organizationInput: string,
    organizationRoleInput: string,
    organizationNames: { [number]: string },
    selectedOrgName: string,
    selectedOrgId: number,
    typeDropdownKey: string,
    showRetryButton: boolean,
    retryErrorMessage: string,
    disableSearch: boolean,
};

type RefNodes = {
    emailInput: ?ElementRef<*>,
    passwordInput: ?ElementRef<*>,
    phoneInput: ?ElementRef<*>,
};

const resendSMSToast: number = 10002;
const noCorrespondingAccountToast: number = 10003;

const alreadyHaveAnAccount = (
    <div style={{ paddingTop: 25, }}>
        <span style={AuthStyles.instructionsInline}>Already have an account? </span>
        <NavLink
            to="/login"
            style={AuthStyles.navLink}
        >
            Log in
        </NavLink>
    </div>
);

class RegistrationPage extends Component<Props, State> {
    nodes: RefNodes = {
        emailInput: null,
        passwordInput: null,
        phoneInput: null,
        organizationInput: null,
        organizationRoleInput: null,
    };

    constructor(props: Props, context: *) {
        super(props, context);

        const organizationNames = {};
        this.state = {
            organizationNames,
            formValid: false,
            showPassword: false,
            showPasswordReq: false,
            updatingPhone: false,
            updatingEmail: false,
            isErrorVisible: false,
            emailInput: '',
            passwordInput: '',
            phoneInput: '',
            organizationInput: '',
            organizationRoleInput: '',
            typeDropdownKey: 'typeKey0',
            selectedOrgName: '',
            selectedOrgId: 0,
            showRetryButton: false,
            retryErrorMessage: '',
            disableSearch: false,
        };
    }

    componentDidMount() {
        const {
            resetData,
        } = this.props;

        resetData();
    }

    componentDidUpdate(prevProps: Props) {
        const {
            signedUp,
            sendSMSCode,
            phone,
            applicationErrors,
            checkEmailSuccess,
            getOrganizations,
        } = this.props;

        // Phone verification
        if (signedUp && !prevProps.signedUp) {
            sendSMSCode(phone);
        } else if (signedUp && prevProps.phone !== phone) {
            sendSMSCode(phone);
        }

        // Process any errors that are pushed onto application state
        if (applicationErrors) {
            this.maybeDisplayError(applicationErrors);
        }

        // Get orgs after email verification
        if (!prevProps.checkEmailSuccess && checkEmailSuccess) {
            getOrganizations();
            return;
        }

        // Handle the result of getting orgs
        this.handleGetOrganizationsResult();
    }

    onKey = (e: { which: number }) => {
        if (e.which === 13) {
            this.onSignup();
        }
    };

    onKeyUpdatePhone = (e: { which: number }) => {
        if (e.which === 13) {
            this.onUpdatePhone();
        }
    };

    onKeyUpdateEmail = (e: { which: number }) => {
        if (e.which === 13) {
            this.onUpdateEmail();
        }
    };

    onSignup = () => {
        const {
            signup,
        } = this.props;

        const {
            emailInput,
            passwordInput,
            phoneInput,
            formValid,
        } = this.state;

        if (formValid) {
            signup(emailInput, passwordInput, phoneInput, 'TXP_CHAT_WEB');
        }
    };

    onVerifySMSCode = (passcode: string) => {
        const {
            phone,
            verifySMSCode,
        } = this.props;

        verifySMSCode(phone, passcode);
    };

    onSendSMSCode = () => {
        const {
            phone,
            sendSMSCode,
        } = this.props;

        sendSMSCode(phone);
    };

    onShowPhoneDialog = () => {
        this.setState({
            updatingPhone: true,
            phoneInput: '',
            passwordInput: '',
        });

        this.setNodeValue('phoneInput', '');
        this.setNodeValue('passwordInput', '');
    };

    onHidePhoneDialog = () => {
        this.setState({
            updatingPhone: false,
            phoneInput: '',
            passwordInput: '',
        });
    };

    onUpdatePhone = () => this.setState({
        updatingPhone: false,
    }, () => {
        const { phone, updateRegistrationPhone, } = this.props;
        const { phoneInput, passwordInput, } = this.state;

        // Only trigger update if value was changed
        if (phoneInput && phoneInput !== phone) {
            updateRegistrationPhone(phone, phoneInput, passwordInput);
        }
    });

    onShowEmailDialog = () => {
        this.setState({
            updatingEmail: true,
            emailInput: '',
            passwordInput: '',
        });

        this.setNodeValue('emailInput', '');
        this.setNodeValue('passwordInput', '');
    };

    onHideEmailDialog = () => {
        this.setState({
            updatingEmail: false,
            emailInput: '',
            passwordInput: '',
        });
    };

    onUpdateEmail = () => this.setState({
        updatingEmail: false,
    }, () => {
        const { email, updateRegistrationEmail, } = this.props;
        const { emailInput, passwordInput, } = this.state;

        // Only trigger update if value was changed
        if (emailInput && (emailInput !== email || '')) {
            updateRegistrationEmail(email, emailInput, passwordInput);
        }
    });

    onJoinOrganization = () => {
        const {
            joinOrganization,

        } = this.props;
        const {
            selectedOrgName,
            selectedOrgId,
            organizationRoleInput,
        } = this.state;

        joinOrganization(selectedOrgId, selectedOrgName, organizationRoleInput);
    }

    onSkipConfirm = () => {
        this.props.history.push('/login');
    }

    setNodeRef(key: string, ref: ?ElementRef<*>) {
        this.nodes[key] = ref;
    }

    setNodeValue(key: 'emailInput' | 'passwordInput' | 'phoneInput' | 'organizationInput' | 'organizationRoleInput', value: string) {
        this.setState((previousState) => {
            const email = key === 'emailInput' ? value : previousState.emailInput;
            const password = key === 'passwordInput' ? value : previousState.passwordInput;
            const phone = key === 'phoneInput' ? value : previousState.phoneInput;
            previousState[key] = value;
            previousState.formValid = email.length > 0 && password.length >= 8 && phone != null && isValidPhoneNumber(phone);
            return previousState;
        });
    }

    getSMSErrors(): string {
        const {
            smsError,
        } = this.props;

        if (smsError) {
            if (smsError.phone) {
                return smsError.phone;
            } if (smsError.passcode) {
                return smsError.passcode;
            } if (smsError.nonFieldError) {
                return smsError.nonFieldError;
            }
        }
        return '';
    }

    handleGetOrganizationsResult = () => {
        const {
            isLoadingOrganizations,
            organizations,
            loadOrgsFailed,
        } = this.props;

        const {
            showRetryButton,
        } = this.state;

        if (isLoadingOrganizations) {
            return;
        }

        if (!showRetryButton && loadOrgsFailed) {
            if (organizations.length === 0) {
                // Allow the user to retry loading organizations
                this.setState({
                    showRetryButton: true,
                    retryErrorMessage: 'Looks like there was a problem loading organizations. If this continues to happen, please contact support.',
                    disableSearch: true,
                });
            } else {
                // Current organization data might be stale...
                this.setState({
                    showRetryButton: true,
                    retryErrorMessage: 'Looks like there was a problem loading organizations. The current organizations list might not be up-to-date. '
                        + 'If this continues to happen, please contact support.',
                    disableSearch: false,
                });
            }
        } else if (showRetryButton && !loadOrgsFailed) {
            this.setState({
                showRetryButton: false,
                retryErrorMessage: '',
                disableSearch: false,
            });
        }
    }

    // We should implement this in its own component (see Messages.js in TXPChat).
    maybeDisplayError(errors) {
        const { setSagaMessage, } = this.props;
        const { isErrorVisible, } = this.state;

        if (!isErrorVisible && errors.length) {
            this.setState({ isErrorVisible: true, });

            const data = errors[errors.length - 1];

            const { label, message, } = typeof data === 'string' ? {
                label: '',
                message: data,
            } : data;

            setSagaMessage(label, message, '', false);

            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.warn('hidden error', errors[errors.length - 1]);
            }

            this.popError();
        }
    }

    popError = () => {
        const { applicationErrors, popError, } = this.props;

        popError();
        this.setState({ isErrorVisible: false, }, () => {
            this.maybeDisplayError(applicationErrors);
        });
    };

    checkRegistrationEmailVerification = () => {
        const {
            correlationId,
            email,
            checkRegistrationEmailVerification,
        } = this.props;
        checkRegistrationEmailVerification(email, correlationId);
    };

    handleOrgChange = (value) => {
        const {
            organizationNames,
        } = this.state;

        this.setState({
            selectedOrgName: organizationNames[value],
            selectedOrgId: Number(value),
        });

        this.handleTypeDropdownKey();
    };

    resendRegistrationEmail = () => {
        const {
            email,
            resendRegistrationEmail,
        } = this.props;
        resendRegistrationEmail(email);
    }

    toggleShowPassword = () => {
        this.setState((prevState: State) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    toggleShowPasswordReq = () => {
        this.setState((prevState: State) => ({
            showPasswordReq: !prevState.showPasswordReq,
        }));
    };

    hasFieldError(fieldName: string, allowNonField: boolean): boolean {
        const {
            signupError,
            smsError,
        } = this.props;
        if (signupError) {
            return !!signupError[fieldName] || !!(allowNonField && signupError.nonFieldError);
        } if (smsError) {
            return !!smsError[fieldName] || !!(allowNonField && smsError.nonFieldError);
        }

        return false;
    }

    static renderError(message: string) {
        return (
            <p style={AuthStyles.formError}>
                {message}
            </p>
        );
    }

    handleTypeDropdownKey = () => {
        this.setState((state) => {
            // FIXME When PR #1872 is published and released to npm, this can be removed
            const typeDropdownKey = (state.typeDropdownKey === 'typeKey0') ? 'typeKey1' : 'typeKey0';
            return {
                ...state,
                typeDropdownKey,
            };
        });
    };

    renderFieldError(fieldName: string, allowNonField: boolean) {
        const {
            signupError,
        } = this.props;

        if (!signupError) {
            return null;
        }

        let error = signupError[fieldName];

        if (!error && allowNonField) {
            error = signupError.nonFieldError;
        }

        if (!error) {
            return null;
        }

        return (
            <span
                style={AuthStyles.formError}
            >
                {error}
            </span>
        );
    }

    render() {
        const {
            email,
            phone,
            signupActive,
            SMSActive,
            updatePhoneActive,
            updateEmailActive,
            signedUp,
            verifyPhoneSuccess,
            verifyPhoneFailed,
            checkEmailSuccess,
            checkEmailFailed,
            profileLoaded,
            organizations,
            organizationId,
            isLoadingOrganizations,
            getOrganizations,
        } = this.props;

        const {
            formValid,
            showPassword,
            showPasswordReq,
            emailInput,
            passwordInput,
            phoneInput,
            updatingPhone,
            updatingEmail,
            organizationRoleInput,
            selectedOrgName,
            organizationNames,
            showRetryButton,
            retryErrorMessage,
            disableSearch,
        } = this.state;

        const submitDisabled = !formValid || signupActive;

        const errors = {
            email: this.hasFieldError('email', true),
            password: this.hasFieldError('password', false),
            phone: this.hasFieldError('phone', false),
            passcode: this.hasFieldError('passcode', true),
        };

        // Make views more explicit
        let currentView = '1-ENTER-DETAILS';
        if (!signedUp && signupActive) currentView = '2-SIGNING-UP';
        if (signedUp) currentView = '3-ENTER-OTP';
        if (!verifyPhoneSuccess && SMSActive) currentView = '4-ACTIVATING';
        if (verifyPhoneSuccess) currentView = '5-CHECK-EMAIL';
        if (checkEmailSuccess && !profileLoaded) currentView = '6-CHECKING-EMAIL';
        if (checkEmailSuccess && profileLoaded) currentView = '7-JOIN ORG';
        if (organizationId) currentView = '8-START';

        if (currentView === '1-ENTER-DETAILS') {
            return (
                <div>
                    <SagaMessage toastId={resendSMSToast} />
                    <ToastContainer
                        position="top-right"
                        autoClose={4000}
                        newestOnTop={false}
                        hideProgressBar
                        transition={Slide}
                    />
                    <form className="verticalCenter">
                        <h1 style={ApplicationStyles.loginLabel}>OmniLife Web</h1>
                        <span style={AuthStyles.instructions}>Please enter your email and create a password.</span>
                        <Input
                            style={
                                errors.email ? ApplicationStyles.textInputError : null
                            }
                            type="text"
                            label="Your email"
                            name="emailInput"
                            ref={(n) => this.setNodeRef('emailInput', n)}
                            onChange={(text) => this.setNodeValue('emailInput', text)}
                            value={emailInput}
                        />
                        {this.renderFieldError('email', true)}
                        <Input
                            style={
                                errors.password ? ApplicationStyles.textInputError : null
                            }
                            type={showPassword ? 'text' : 'password'}
                            label="Create a password"
                            name="passwordInput"
                            ref={(n) => this.setNodeRef('passwordInput', n)}
                            onChange={(text) => this.setNodeValue('passwordInput', text)}
                            value={passwordInput}
                        >
                            <Fade collapse when={passwordInput.length > 0}>
                                <Button
                                    style={AuthStyles.showPasswordButton}
                                    tabIndex={-1}
                                    type="button"
                                    label={showPassword ? 'Hide password' : 'Show password'}
                                    ripple={false}
                                    onClick={this.toggleShowPassword}
                                />
                            </Fade>
                        </Input>
                        {this.renderFieldError('password', false)}
                        <div style={{ textAlign: 'left', }}>
                            <Button
                                style={AuthStyles.passwordReqButton}
                                tabIndex={-1}
                                type="button"
                                label="Show Password Requirements"
                                ripple={false}
                                onClick={this.toggleShowPasswordReq}
                            />
                        </div>
                        <Fade collapse when={showPasswordReq}>
                            <PasswordRequirements />
                        </Fade>
                        <div style={{ marginTop: 25, }}>
                            <span style={AuthStyles.instructions}>
                                Please enter your phone number.
                            </span>
                            <span style={AuthStyles.instructions}>
                                We will send a verification code to use in the next step.
                            </span>
                        </div>
                        <div style={{ ...AuthStyles.phoneContainer, }}>
                            <PhoneInput
                                value={phoneInput}
                                onChange={(text) => this.setNodeValue('phoneInput', text)}
                                style={{
                                    ...errors.phone ? ApplicationStyles.textInputError : null,
                                    ...{ width: '100%', },
                                }}
                                defaultCountry="US"
                                label="Phone number"
                                name="phoneInput"
                                ref={(n) => this.setNodeRef('phoneInput', n)}
                                onKeyPress={this.onKey}
                                international
                                withCountryCallingCode
                                inputComponent={PhoneInputTextField}
                            />
                        </div>
                        {this.renderFieldError('phone', false)}
                        <div>
                            <Button
                                style={
                                    submitDisabled ? ApplicationStyles.buttonDisabled : ApplicationStyles.button
                                }
                                type="button"
                                disabled={submitDisabled}
                                label="Sign up"
                                flat
                                onClick={this.onSignup}
                            />
                        </div>
                        {alreadyHaveAnAccount}
                    </form>
                </div>
            );
        }
        if (currentView === '2-SIGNING-UP') {
            return (
                <div className="verticalCenter">
                    <h1 style={ApplicationStyles.loginLabel}>OmniLife Web</h1>
                    <h4 style={RegistrationStyles.formLabel}>Please wait while we create your account.</h4>
                    <LogoSpin />
                </div>
            );
        }
        if (currentView === '3-ENTER-OTP') {
            if (updatingPhone) {
                return (
                    <div className="verticalCenter">
                        <h1 style={{ ...ApplicationStyles.loginLabel, ...{ marginTop: 50, }, }}>OmniLife Web</h1>
                        <span style={AuthStyles.instructions}>Enter your new phone number</span>
                        <div style={{ ...AuthStyles.phoneContainer, }}>
                            <PhoneInput
                                value={phoneInput}
                                onChange={(text) => this.setNodeValue('phoneInput', text)}
                                style={{
                                    ...{ width: '100%', },
                                }}
                                defaultCountry="US"
                                label="Phone number"
                                name="phoneInput"
                                ref={(n) => this.setNodeRef('phoneInput', n)}
                                onKeyPress={this.onKey}
                                international
                                withCountryCallingCode
                                inputComponent={PhoneInputTextField}
                            />
                        </div>
                        <span style={AuthStyles.instructions}>
                            Just to be safe, please enter the password that you used to register.
                        </span>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            name="passwordInput"
                            ref={(n) => this.setNodeRef('passwordInput', n)}
                            onKeyPress={this.onKeyUpdatePhone}
                            onChange={(text) => this.setNodeValue('passwordInput', text)}
                            value={passwordInput}
                        >
                            <Fade collapse when={passwordInput.length > 0}>
                                <Button
                                    style={AuthStyles.showPasswordButton}
                                    tabIndex={-1}
                                    type="button"
                                    label={showPassword ? 'Hide password' : 'Show password'}
                                    ripple={false}
                                    onClick={this.toggleShowPassword}
                                />
                            </Fade>
                        </Input>
                        <Button
                            style={
                                updatePhoneActive ? ApplicationStyles.buttonInverseDisabled : ApplicationStyles.buttonInverse
                            }
                            type="button"
                            label="Update"
                            flat
                            disabled={updatePhoneActive}
                            ripple={false}
                            onClick={this.onUpdatePhone}
                        />
                        <Button
                            style={
                                updatePhoneActive ? ApplicationStyles.buttonInverseDisabled : ApplicationStyles.buttonInverseRed
                            }
                            type="button"
                            label="Cancel"
                            flat
                            disabled={updatePhoneActive}
                            ripple={false}
                            onClick={this.onHidePhoneDialog}
                        />
                    </div>
                );
            }
            return (
                <div>
                    <SagaMessage toastId={noCorrespondingAccountToast} />
                    <ToastContainer
                        position="top-right"
                        autoClose={4000}
                        newestOnTop={false}
                        hideProgressBar
                        transition={Slide}
                    />
                    <div className="verticalCenter">
                        <VerificationCodeInput
                            verifying={SMSActive}
                            phoneNumber={formatPhone(phone)}
                            canResend
                            canGoBack={false}
                            showHeading
                            errorMessage={errors.passcode || errors.phone ? this.getSMSErrors() : ''}
                            resendSMSCode={this.onSendSMSCode}
                            submitPasscode={this.onVerifySMSCode}
                        />
                        {verifyPhoneFailed ? RegistrationPage.renderError('Please enter a valid code') : null}
                        <div style={ApplicationStyles.margin20}>
                            <h3>
                                Wrong phone number?
                            </h3>
                            <div>
                                <span style={AuthStyles.instructionsCenter}>
                                    If you entered an incorrect phone number, try updating it, and we&#39;ll
                                    send a new verification text to the correct number.
                                </span>
                                <div style={ApplicationStyles.margin20} />
                                <Button
                                    style={SMSActive ? ApplicationStyles.buttonInverseDisabled : ApplicationStyles.buttonInverse}
                                    type="button"
                                    flat
                                    ripple={false}
                                    disabled={SMSActive}
                                    label="Update phone number"
                                    onClick={this.onShowPhoneDialog}
                                />
                            </div>
                        </div>
                        {alreadyHaveAnAccount}
                    </div>
                </div>
            );
        }
        if (currentView === '4-ACTIVATING') {
            return (
                <div className="verticalCenter">
                    <h1 style={ApplicationStyles.loginLabel}>OmniLife Web</h1>
                    <h4 style={RegistrationStyles.formLabel}>Please wait while we verify your passcode.</h4>
                    <LogoSpin />
                </div>
            );
        }
        if (currentView === '5-CHECK-EMAIL') {
            if (updatingEmail) {
                return (
                    <div className="verticalCenter">
                        <h1 style={{ ...ApplicationStyles.loginLabel, ...{ marginTop: 50, }, }}>OmniLife Web</h1>
                        <span style={AuthStyles.instructions}>Enter your new email</span>
                        <Input
                            type="text"
                            label="Your email"
                            name="emailInput"
                            ref={(n) => this.setNodeRef('emailInput', n)}
                            onChange={(text) => this.setNodeValue('emailInput', text)}
                            value={emailInput}
                        />
                        <span style={AuthStyles.instructions}>
                            Just to be safe, please enter the password that you used to register.
                        </span>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            name="passwordInput"
                            ref={(n) => this.setNodeRef('passwordInput', n)}
                            onKeyPress={this.onKeyUpdateEmail}
                            onChange={(text) => this.setNodeValue('passwordInput', text)}
                            value={passwordInput}
                        >
                            <Fade collapse when={passwordInput.length > 0}>
                                <Button
                                    style={AuthStyles.showPasswordButton}
                                    tabIndex={-1}
                                    type="button"
                                    label={showPassword ? 'Hide password' : 'Show password'}
                                    ripple={false}
                                    onClick={this.toggleShowPassword}
                                />
                            </Fade>
                        </Input>
                        <Button
                            style={
                                updateEmailActive ? ApplicationStyles.buttonInverseDisabled : ApplicationStyles.buttonInverse
                            }
                            type="button"
                            label="Update"
                            flat
                            disabled={updateEmailActive}
                            ripple={false}
                            onClick={this.onUpdateEmail}
                        />
                        <Button
                            style={
                                updateEmailActive ? ApplicationStyles.buttonInverseDisabled : ApplicationStyles.buttonInverseRed
                            }
                            type="button"
                            label="Cancel"
                            flat
                            disabled={updateEmailActive}
                            ripple={false}
                            onClick={this.onHideEmailDialog}
                        />
                    </div>
                );
            }
            return (
                <div>
                    <SagaMessage toastId={noCorrespondingAccountToast} />
                    <ToastContainer
                        position="top-right"
                        autoClose={4000}
                        newestOnTop={false}
                        hideProgressBar
                        transition={Slide}
                    />
                    <div className="verticalCenter">
                        <h1 style={ApplicationStyles.loginLabel}>OmniLife Web</h1>
                        <h3>
                            We sent an email to
                        </h3>
                        <h3>
                            {email}
                        </h3>
                        <span style={AuthStyles.instructionsCenter}>Click the link in the email to complete verification.</span>
                        <span style={AuthStyles.instructionsCenter}>
                            Already verified your email? Then click the link below to continue.
                        </span>
                        <Button
                            onClick={this.checkRegistrationEmailVerification}
                            style={{ ...ApplicationStyles.margin20, ...ApplicationStyles.button, }}
                        >
                            Continue to OmniLife Web
                        </Button>
                        {checkEmailFailed ? RegistrationPage.renderError('Sorry, your email has not yet been verified.') : null}
                        <div style={ApplicationStyles.margin20} />
                        <h3>Didn&#39;t receive an email?</h3>
                        <span style={AuthStyles.instructionsCenter}>
                            Please wait up to five minutes to receive the email. If you do not receive it, make sure
                            your email address is correct. If it is correct, try resending the verification email.
                            If it is incorrect, try updating it, and we&#39;ll send a new verification email to the
                            correct address.
                        </span>
                        <Button
                            style={ApplicationStyles.buttonInverse}
                            type="button"
                            label="Resend email"
                            flat
                            ripple={false}
                            onClick={this.resendRegistrationEmail}
                        />
                        <Button
                            style={ApplicationStyles.buttonInverse}
                            type="button"
                            label="Update email address"
                            flat
                            ripple={false}
                            onClick={this.onShowEmailDialog}
                        />
                        <div style={ApplicationStyles.margin20}>
                            <NavLink
                                style={AuthStyles.navLink}
                                to="/login"
                            >
                                Return to login
                            </NavLink>
                        </div>
                    </div>
                </div>
            );
        }
        if (currentView === '6-CHECKING-EMAIL') {
            return (
                <div className="verticalCenter">
                    <h1 style={ApplicationStyles.loginLabel}>OmniLife Web</h1>
                    <h4 style={RegistrationStyles.formLabel}>Please wait while we check your email status.</h4>
                    <LogoSpin />
                </div>
            );
        }
        if (currentView === '7-JOIN ORG') {
            const organizationsSorted = organizations.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
            for (let i = 0; i < organizationsSorted.length; i += 1) {
                organizationNames[organizationsSorted[i].id] = organizationsSorted[i].name;
            }

            const isJoinButtonDisabled = (!selectedOrgName || !organizationRoleInput);
            let joinButtonStyle = {
                ...ApplicationStyles.button,
                ...ApplicationStyles.margin20,
            };
            if (isJoinButtonDisabled) {
                joinButtonStyle = {
                    ...joinButtonStyle,
                    ...ApplicationStyles.buttonDisabled,
                };
            }

            const retryView = (
                <>
                    <h4 style={RegistrationStyles.formError}>{retryErrorMessage}</h4>
                    <RetryButton text="Reload Organizations" onClick={getOrganizations} isLoading={isLoadingOrganizations} />
                </>
            );

            return (
                <div className="verticalCenter">
                    <h1 style={ApplicationStyles.loginLabel}>Join An Organization</h1>
                    <div>
                        <Autocomplete
                            disabled={disableSearch}
                            direction="down"
                            onChange={(value) => this.handleOrgChange(value)}
                            label="Search for an Organization"
                            multiple={false}
                            suggestionMatch="anywhere"
                            source={organizationNames}
                            value={selectedOrgName}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            select
                            color="primary"
                            label="Select Role:"
                            type="text"
                            InputLabelProps={{ shrink: true, }}
                            value={organizationRoleInput}
                            onChange={(event) => this.setNodeValue('organizationRoleInput', event.target.value)}
                        >
                            {roles.map((role: any) => (
                                <MenuItem key={role.value} value={role.value}>{role.label}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        <Button
                            disabled={isJoinButtonDisabled}
                            onClick={this.onJoinOrganization}
                            style={joinButtonStyle}
                        >
                            Join Organization
                        </Button>
                        {(showRetryButton) ? retryView : <></>}
                    </div>
                    <div style={ApplicationStyles.margin20}>
                        <SkipButton
                            text="Skip Organization Selection"
                            dialogTitle="Are You Sure?"
                            dialogText={'Warning: If you do not join an organization, you will have limited features as a basic user. '
                                + 'Are you sure you want to skip this step?'}
                            onConfirm={this.onSkipConfirm}
                        />
                    </div>
                </div>
            );
        }

        return <Redirect to="/login" />;
    }
}

const mapStateToProps = (state) => ({
    signupError: state.registration.signupError,
    smsError: state.profile.smsError,
    signupActive: state.loading.signup,
    SMSActive: state.loading.activateSMS,
    updatePhoneActive: state.loading.updatePhone,
    updateEmailActive: state.loading.updateEmail,
    signedUp: state.registration.signedUp,
    verifyPhoneSuccess: state.registration.verifyPhoneSuccess,
    verifyPhoneFailed: state.registration.verifyPhoneFailed,
    checkEmailSuccess: state.registration.checkEmailSuccess,
    checkEmailFailed: state.registration.checkEmailFailed,
    profileLoaded: state.auth.profile && state.auth.profile.isEmailVerified,
    phone: state.registration.phone,
    email: state.registration.email,
    correlationId: state.registration.correlationId,
    applicationErrors: state.application.errors,
    organizations: state.chatEdit.organizations,
    organizationId: (state.auth.profile) ? state.auth.profile.organizationId : 0,
    loadOrgsFailed: state.chatEdit.isGetOrganizationsInFailState ?? false,
    isLoadingOrganizations: state.loading.organizations,
});

export default withRouter(connect(mapStateToProps, {
    signup: _signup,
    sendSMSCode: _sendSMSCode,
    verifySMSCode: _verifySMSCode,
    checkRegistrationEmailVerification: _checkRegistrationEmailVerification,
    resendRegistrationEmail: _resendRegistrationEmail,
    updateRegistrationPhone: _updateRegistrationPhone,
    updateRegistrationEmail: _updateRegistrationEmail,
    joinOrganization: _joinOrganization,
    setSagaMessage: _setSagaMessage,
    popError: _popError,
    resetData: _resetData,
    getOrganizations: _getOrganizations,
})(RegistrationPage));
