const Colors = {
    black: '#000000',
    white: '#FFFFFF',
    gray: '#646464',
    darkGray: '#474747',
    darkGrayLogo: '#231f20',
    lightGray: '#A9A9A9',
    lighterGray: '#F4F4F4',
    evenLighterGray: '#F9F9F9',
    lightRed: '#F692B1',
    lighterRed: '#FFD3D3',
    red: '#E21755',
    darkRed: '#FF3838',
    darkerRed: '#A31212',
    transparent: 'rgba(0, 0, 0, 0)',
    blue: '#0069FF',
    lightBlue: 'rgba(0, 105, 255, 0.2)',
    lighterBlue: 'rgba(0, 105, 255, 0.15)',
    toastBlue: '#3f5dbf',

    iosBlue: 'rgb(49, 118, 255)',

    // Note: If you change this, also change the UIColor inside AppDelegate.m (rootView.backgroundColor)
    mainBackground: '#FFFFFF',
    statusBar: 'rgba(255, 255, 255, 0.8)',

    tabBarBlue: '#007AFF',
    pendingYellow: '#FEDF00',

    green: '#4CD964',
    greenLight: '#E0F8E0',
    darkGreen: '#117000',

    bottomNavigationBorder: '#EBEBEB',
    linkText: '#0069FF',
    linkTextDisabled: '#C5C5C5',

    brandPrimary: '#2E3192',
    brandSecondary: '#00A79D',

    buttonBackground: '#0069FF',
    buttonBackgroundDisabled: '#F3F3F3',
    buttonText: '#FFFFFF',
    buttonTextDisabled: '#9B9B9B',

    caseListItemBorder: '#c2bcbc',

    chatListItemBackground: '#FFFFFF',
    chatListItemBorder: '#EBEBEB',
    chatListItemBorderLight: '#EFEFEF',

    chatAreaBackground: '#F1F1F1',
    chatItemBackground: '#FFFFFF',
    chatItemBackgroundSelf: '#0069FF',
    chatItemBorder: '#EBEBEB',
    chatItemBorderSelf: '#0069FF',

    lineButtonBackground: '#FFFFFF',
    lineButtonBorder: '#EBEBEB',

    contactListItemBackground: '#FFFFFF',

    walkthroughTitle: '#000000',
    walkthroughDescription: '#646464',

    formLabel: '#646464',
    formError: '#E21755',
    textInputText: '#000000',
    textInputTextDisabled: 'rgba(0, 0, 0, 0.4)',
    textInputPlaceholder: '#9B9B9B',
    textInputBorderBottom: '#9B9B9B',
    fakeTextInputBorderBottom: '#D7D7D7',

    text: 'rgb(3, 3, 3)',

    transparentBlack: 'rgba(0, 0, 0, 0.7)',
    transparentRed: 'rgba(255, 0, 0, 0.8)',
    transparentYellow: 'rgba(255, 192, 0, 0.8)',
    transparentWhite: 'rgba(255, 255, 255, 0.7)',
    transparentBlue: 'rgba(49, 118, 255, 0.4)',
};

export default Colors;
