import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';

import queryClient from './queryClient';
import Routes from './Navigation/Routes';
import assembleStore from './Redux';
import './App.css';

// create our store
const {
    store,
} = assembleStore();

class App extends PureComponent<Props> {
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    // Some keystrokes are undesireable for all users.
    // Turn off CTRL-S (windows/chrome=Save As)
    // Turn off CTRL-U (windows/chrome&firefox=View Source)(windows/Edge=Debugger mode)
    handleKeyDown = (event) => {
        const charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey && charCode === 'u') {
            event.preventDefault();
        } else if (event.ctrlKey && charCode === 's') {
            event.preventDefault();
        }

        // For MAC we can use metaKey to detect cmd key. More research needed. Included here as hint.
        // if(event.metaKey && charCode === 'c') {
        //   console.log("Cmd + C pressed");
        // }
    };

    render() {
        const {
            activationId,
            email,
        } = this.props;

        return (
            <div>
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <Router>
                            <Routes activationId={activationId} email={email} />
                        </Router>
                    </QueryClientProvider>
                </Provider>
            </div>
        );
    }
}

export default App;
