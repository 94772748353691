// @flow
import Colors from '../../Themes/Colors';
// import Fonts from '../../Themes/Fonts';

const OfferImportStyles = {
    dialogBackdrop: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
        zIndex: 99,
    },

    dialogPaper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        position: 'relative',
    },

    buttonContainer: {
        // position child buttons absolutely in bottom left corner
        display: 'flex',
        justifyContent: 'space-between',
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1em',
    },

    cancelButton: {
        margin: '1em',
        color: Colors.white,
        fontSize: 20,
        opacity: 0.9,
        background: Colors.red,
        textTransform: 'none',
        width: '30%',
    },

    confirmButton: {
        margin: '1em',
        color: Colors.white,
        fontSize: 20,
        background: Colors.green,
        textTransform: 'none',
        opacity: 0.9,
        width: '30%',
    },

    dataPreviewContainer: {
        display: 'flex',
        height: '100%',
        border: '2px solid #a9a9a9',
    },

    dataFieldsColumn: {
        flex: '35%',
        borderRight: '2px solid #a9a9a9',
        overflowY: 'scroll',
        background: Colors.white,
    },

    dataFieldsHeader: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
    },

    pdfPreviewColumn: {
        flex: '65%',
        overflowY: 'scroll',
        background: '#e9e9e9',
        paddingBottom: '1em',
    },

    pdfPage: {
        margin: '1.5em auto',
        width: '90%',
        overflow: 'scroll',
        background: Colors.white,
        border: '1px solid #a9a9a9',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
    },

    importPreviewTable: {
        margin: '1em',
    },

    dataTableBody: {
        textAlign: 'center',
    },

    dataEntryField: {
        paddingBottom: '0.5em',
        fontSize: 16,
    },
};

export default OfferImportStyles;
