// @flow
import React from 'react';
import FontIcon from 'react-toolbox/lib/font_icon';

import AuthStyles from '../Containers/Styles/AuthStyles';

const PasswordRequirements = () => (
    <div style={AuthStyles.passwordReqContainer}>
        <div>
            <FontIcon className="bullet" value="trip_origin" />
            <span style={AuthStyles.instructionsInline}>Minimum of 8 characters</span>
        </div>
        <div>
            <FontIcon className="bullet" value="trip_origin" />
            <span style={AuthStyles.instructionsInline}>Cannot contain any part of your email or name</span>
        </div>
        <div>
            <FontIcon className="bullet" value="trip_origin" />
            <span style={AuthStyles.instructionsInline}>Avoid using something easy like `password`</span>
        </div>
    </div>
);

export default PasswordRequirements;
