// @flow
import {
    envReducer, profileReducer, registrationReducer,
} from 'txp-core';

import allSagas from '../Saga';
import SagaManager from '../Saga/SagaManager';
import configureStore from './configureStore';
import applicationReducer from './ApplicationActions';
import authReducer from './AuthActions';
import chatroomReducer from './ChatroomActions';
import loadingReducer from './LoadingActions';
import chatListReducer from './ChatListActions';
import chatMessageReducer from './ChatMessageActions';
import chatEditReducer from './ChatEditActions';
import donorReducer from './DonorActions';
import permissionReducer from './PermissionActions';
import helpReducer from './HelpActions';
import offerReducer from './OfferActions';
import formReducer from './FormActions';
import notificationReducer from './NotificationActions';
import networkReducer from './NetworkActions';
import teamReducer from './TeamActions';
import followerEditReducer from './FollowerEditActions';
import addPeopleReducer from './AddPeopleActions';

export const reducers = {
    application: applicationReducer,
    auth: authReducer,
    profile: profileReducer,
    registration: registrationReducer,
    loading: loadingReducer,
    chatList: chatListReducer,
    chatMessage: chatMessageReducer,
    chatroom: chatroomReducer,
    chatEdit: chatEditReducer,
    donor: donorReducer,
    permission: permissionReducer,
    help: helpReducer,
    offer: offerReducer,
    forms: formReducer,
    notification: notificationReducer,
    network: networkReducer,
    team: teamReducer,
    env: envReducer,
    followerEdit: followerEditReducer,
    addPeople: addPeopleReducer,
};

const assembleStore = () => {
    const res = configureStore(reducers);
    const {
        store,
        sagaMiddleware,
        replaceReducer,
    } = res;

    // Kick off the root Saga
    SagaManager.run(sagaMiddleware, allSagas);

    if (module.hot) {
        module.hot.accept(() => {
            replaceReducer(reducers);

            const newYieldedSagas = require('../Saga').default; // eslint-disable-line global-require

            SagaManager.cancel(store);
            const newManager = require('../Saga/SagaManager').default; // eslint-disable-line global-require
            newManager.run(sagaMiddleware, newYieldedSagas, true);
        });
    }

    return {
        store,
    };
};

export default assembleStore;
