// @flow
import React, { useEffect, useState } from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import {
    Grid, Switch,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faChevronDown,
    faTimesCircle,
    faBuilding,
    faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
    getResourcePermissions,
    addPermission,
    removePermission,
    updatePermission,
} from '../Redux/PermissionActions';
import {
    deleteFollowerGroup,
    getDonorFollowerGroups,
    getFollowerGroup,
    openFollowerGroup,
    // getCaseAdminUsers,
    loadCasePreferences,
    resetCasePreferences,
} from '../Redux/DonorActions';
import hasPermissions, {
    ENTITY_TYPE_DONOR,
    SUB_ENTITY_TYPE_DETAILS,
    SUB_ENTITY_TYPE_FILES,
    SUB_ENTITY_TYPE_TRACKER,
    SUB_ENTITY_TYPE_TIMELINE,
    NONE,
    UPDATE,
} from '../Utils/hasPermissions';

import {
    joinFollowerGroup,
} from '../Redux/FollowerEditActions';

import type {
    ResourcePermission,
    AvatarMap,
} from '../Utils/types';
import FollowerGroupItem from './FollowerGroupItem';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import CasePermissionsStyles from './Styles/CasePermissionsStyles';
import Colors from '../Themes/Colors';
import Images from '../Themes/Images';

type Props = {
    donorId: number,
    donorCreatorId: number,
    avatars: AvatarMap,
    orgPermissions: ?ResourcePermission,
    workflowKey: string,
    onShowAddPeopleClicked: () => *,
    onShowAdminProfile: (number) => *,
    navigateToFollowerGroup: () => *,
    onOpenCasePreferences: () => *,
}

export default function CasePermissions(props: Props) {
    const {
        donorId,
        donorCreatorId,
        onShowAddPeopleClicked,
        navigateToFollowerGroup,
        orgPermissions,
        onOpenCasePreferences,
        workflowKey,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getResourcePermissions(ENTITY_TYPE_DONOR, donorId));
    }, [donorId]);

    useEffect(() => {
        dispatch(getDonorFollowerGroups(donorId));
        if (workflowKey) {
            dispatch(loadCasePreferences(workflowKey, donorId));
        }
    }, [donorId]);

    // When component unmounts, reset casePreferences
    useEffect(() => () => {
        dispatch(resetCasePreferences());
    }, []);

    const [visiblePermissionsMap, setVisiblePermissionsMap] = useState({});
    const [removeFollowerId, setRemoveFollowerId] = useState(0);

    const permissions = useSelector((state) => state.permission.permissions || []);
    const userAccessLevel = useSelector((state) => state.auth.accessLevel);
    const socketStatus = useSelector((state) => state.chatList.socketStatus);
    const allResourcePermissions = useSelector((state) => state.permission.resourcePermissions);
    const followersLoading = useSelector((state) => state.loading.followers);
    const casePreferences = useSelector((state) => state.donor.casePreferences);
    const donorFollowers = useSelector((state) => {
        if (state.donor.donors && state.donor.donors[donorId] && state.donor.donors[donorId].followerGroups) {
            return state.donor.donors[donorId].followerGroups;
        }
        return {};
    });
    const closed = useSelector((state) => state.donor.donors[donorId].closed);

    // useEffect(() => {
    //     dispatch(getCaseAdminUsers(donorId));
    // }, [allResourcePermissions]);

    // const caseAdminUsers = useSelector((state) => state.donor.caseAdminUsers);

    const isConnected = socketStatus === 'connected';
    let donorResourcePermissions = [];
    if (allResourcePermissions && allResourcePermissions[ENTITY_TYPE_DONOR] && allResourcePermissions[ENTITY_TYPE_DONOR][donorId]) {
        donorResourcePermissions = allResourcePermissions[ENTITY_TYPE_DONOR][donorId];
    }

    const canShareWithBasicUsers = userAccessLevel === 'Sales' || userAccessLevel === 'SysAdmin';
    const canUpdateDonor = hasPermissions(permissions, ENTITY_TYPE_DONOR, donorId, NONE, NONE, UPDATE);
    const addDisabled = !canUpdateDonor || !isConnected;
    const casesPreferencesDisabled = addDisabled || casePreferences.length === 0;

    const includesAccessLevel = donorResourcePermissions
        .map((item) => item.authorized.authorizedType)
        .includes('AccessLevel');

    // NOTE: this resourcePermissionMap only includes users/orgs who have read/write permission for
    // the whole donor. So it intentially skips any permissions granted to subEntityType of 'Task'
    const resourcePermissionsMap = {};
    for (let i = 0; i < donorResourcePermissions.length; i += 1) {
        if (donorResourcePermissions[i].target.subEntityType !== 'Task') {
            const { authorizedId, authorizedType, } = donorResourcePermissions[i].authorized;
            const permissionKey = `${authorizedType}-${authorizedId}`;
            if (authorizedType === 'User' && resourcePermissionsMap[permissionKey]) {
                resourcePermissionsMap[permissionKey].push(donorResourcePermissions[i]);
            } else {
                resourcePermissionsMap[permissionKey] = [donorResourcePermissions[i]];
            }
        }
    }

    const onFollowerGroupClick = (followerGroupId: number) => {
        dispatch(getFollowerGroup(donorId, followerGroupId));
        dispatch(openFollowerGroup(followerGroupId));
        navigateToFollowerGroup();
    };

    const generateEntityKey = (entityResourcePermission: ResourcePermission) => {
        const {
            authorized: {
                authorizedId,
                authorizedType,
            },
        } = entityResourcePermission;

        return `${authorizedType}-${authorizedId}`;
    };

    const orgReadStatus = () => (orgPermissions ? (orgPermissions.rights.read === true) : null);

    const createPermissionRightsString = (entityResourcePermission: ResourcePermission) => {
        const {
            rights,
            target,
            authorized: {
                authorizedType,
            },
        } = entityResourcePermission;

        let subEntityText = '';
        let managedBy = '';

        if (target.subEntityType === SUB_ENTITY_TYPE_TRACKER) {
            subEntityText = '\'s tracker';
            managedBy = '\nManaged by Tracker Subscriptions.';
        } else if (target.subEntityType === SUB_ENTITY_TYPE_FILES) {
            subEntityText = '\'s files';
            managedBy = '\nManaged by Chatroom Membership.';
        } else if (target.subEntityType === SUB_ENTITY_TYPE_TIMELINE) {
            subEntityText = '\'s timeline';
            managedBy = '\nManaged by Chatroom Membership.';
        } else if (target.subEntityType === SUB_ENTITY_TYPE_DETAILS) {
            subEntityText = '\'s details';
            managedBy = '\nManaged by Chatroom Membership.';
        }

        let type = 'user';
        if (authorizedType === 'Organization') {
            type = 'organization';
        } else if (authorizedType === 'AccessLevel') {
            type = 'access level';
        }

        let right = `This ${type} has no permissions for this donor${subEntityText}.${managedBy}`;
        if (rights.read) {
            right = `This ${type} has read permission for this donor${subEntityText}.${managedBy}`;
        }
        if (rights.update) {
            right = `This ${type} has read and update permissions for this donor${subEntityText}.${managedBy}`;
        }
        if (rights.delete) {
            right = `This ${type} has read, update, and delete permissions for this donor${subEntityText}.${managedBy}`;
        }

        return right;
    };

    const onShareWithBasicUsersClick = () => {
        dispatch(addPermission(ENTITY_TYPE_DONOR, donorId, NONE, NONE, true, true, false, false, 'AccessLevel', 3, 'BasicUser', true));
    };

    const onRemoveClick = (permissionId: number) => {
        dispatch(removePermission(ENTITY_TYPE_DONOR, donorId, permissionId));
    };

    const onToggleOrgReadClick = () => {
        if (orgPermissions) dispatch(updatePermission(ENTITY_TYPE_DONOR, donorId, orgPermissions.permissionId, !orgReadStatus(), false, false));
    };

    const renderRemoveButton = (entityResourcePermission: ResourcePermission) => {
        const {
            permissionId,
            authorized: {
                authorizedType,
                authorizedId,
                displayName,
            },
            target: {
                subEntityType,
            },
        } = entityResourcePermission;

        // Make sure that the button should be displayed.
        const isSubEntity = subEntityType != null;
        const isBasicUserPermission = authorizedType === 'AccessLevel' && displayName === 'BasicUser';
        const canRemoveBasicAccess = canShareWithBasicUsers;
        const isCreator = (authorizedType === 'User' && authorizedId === donorCreatorId);
        const isOrg = (authorizedType === 'Organization');
        const buttonEnabled = canUpdateDonor
            && (!isBasicUserPermission || (isBasicUserPermission && canRemoveBasicAccess))
            && !isSubEntity
            && isConnected
            && !isCreator
            && !isOrg;

        if (!buttonEnabled) { return null; }

        return (
            <button
                style={isConnected ? CasePermissionsStyles.memberButton : CasePermissionsStyles.memberButtonDisabled}
                type="button"
                disabled={!isConnected}
                onClick={() => onRemoveClick(permissionId)}
                data-tip="Remove donor permission from user"
                data-delay-show="500"
                data-effect="solid"
            >
                <FontAwesomeIcon
                    fixedWidth
                    color={Colors.lightGray}
                    icon={faTimesCircle}
                    size="lg"
                />
            </button>
        );
    };

    const togglePermissionVisibility = (entityKey) => {
        const newValue = !visiblePermissionsMap[entityKey];
        setVisiblePermissionsMap({
            ...visiblePermissionsMap,
            [entityKey]: newValue,
        });
    };

    const onAddFollowerGroup = () => {
        // Before navigating to follower group, clear out open follower id
        dispatch(openFollowerGroup(null));
        navigateToFollowerGroup();
    };

    const onConfirmDeleteFollower = (followerGroupId: number) => {
        dispatch(deleteFollowerGroup(donorId, followerGroupId));
        setRemoveFollowerId(0);
    };

    const onDeleteFollowerGroup = (followerGroupId: number) => {
        setRemoveFollowerId(followerGroupId);
    };

    const onJoinFollowerGroup = (followerGroupId: number) => {
        dispatch(joinFollowerGroup(donorId, followerGroupId));
    };

    const renderConfirmDeleteDialog = () => (
        <Dialog
            active={removeFollowerId !== 0}
            onEscKeyDown={() => setRemoveFollowerId(0)}
            onOverlayClick={() => setRemoveFollowerId(0)}
        >
            <div>
                <div style={CasePermissionsStyles.deleteConfirmTitleText}>
                    Delete Follower Group and Room?
                </div>
                <div>
                    This will completely remove the follower room, including messages and attachments, for you
                    and the whole organization. Are you sure you want to do this?
                </div>
                <div style={CasePermissionsStyles.modalActionsWrapper}>
                    <button
                        style={CasePermissionsStyles.cancelButton}
                        type="button"
                        onClick={() => setRemoveFollowerId(0)}
                    >
                        Cancel
                    </button>
                    <button
                        style={CasePermissionsStyles.confirmButton}
                        type="button"
                        onClick={() => onConfirmDeleteFollower(removeFollowerId)}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </Dialog>
    );

    const renderShowPermissionsIcon = (entityKey: string) => {
        const chevronIcon = visiblePermissionsMap[entityKey] ? faChevronDown : faChevronRight;

        return (
            <FontAwesomeIcon
                fixedWidth
                color={Colors.blue}
                icon={chevronIcon}
            />
        );
    };

    const renderPermissionDetails = (entityResourcePermission: ResourcePermission, showName: boolean, showPermissions: boolean) => {
        const {
            authorized: {
                displayName,
                authorizedType,
                authorizedId,
            },
        } = entityResourcePermission;

        const {
            avatars,
            onShowAdminProfile,
        } = props;

        const entityKey = generateEntityKey(entityResourcePermission);

        return (
            <div>
                <div style={CasePermissionsStyles.entityItem}>
                    <Grid container>
                        <Grid item xs={10}>
                            <div style={CasePermissionsStyles.permissionsRowItem}>
                                {authorizedType === 'User' ? (
                                    // eslint-disable-next-line
                                    <div
                                        style={CasePermissionsStyles.permissionsImageContainer}
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => onShowAdminProfile(authorizedId)}
                                    >
                                        <img
                                            style={CasePermissionsStyles.permissionsImageWrapper}
                                            src={avatars[authorizedId] || Images.circledDefaultProfile}
                                            alt=""
                                        />
                                    </div>
                                ) : renderPermissionItemAvatar(authorizedType)}
                                <div>
                                    {showName ? (
                                        <div style={CasePermissionsStyles.entityName}>
                                            {displayName}
                                        </div>
                                    ) : null}
                                    {showPermissions ? (
                                        <div style={CasePermissionsStyles.smallDetails}>
                                            {createPermissionRightsString(entityResourcePermission)}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2} style={CasePermissionsStyles.buttonRow}>
                            {showPermissions ? (
                                renderRemoveButton(entityResourcePermission)
                            ) : (
                                renderShowPermissionsIcon(entityKey)
                            )}
                        </Grid>
                    </Grid>
                </div>
                <div style={ChatroomDetailsStyles.border} />
            </div>
        );
    };

    // const renderAdminUser = (user: UserProfile) => {
    //     let permissionRemoveId = -1;
    //     Object.keys(resourcePermissionsMap).forEach((key) => {
    //         if (resourcePermissionsMap[key][0].authorized.authorizedId === user.userId
    //             && resourcePermissionsMap[key][0].authorized.authorizedType === 'User') {
    //             const { permissionId, } = resourcePermissionsMap[key][0];
    //             permissionRemoveId = permissionId;
    //         }
    //     });
    //     return (
    //         <UserDetails
    //             key={user.userId}
    //             membershipStatus="Present"
    //             startDate=""
    //             endDate=""
    //             userProfile={user}
    //             avatar={Images.circledDefaultProfile}
    //             displayButton={user.userId !== donorCreatorId}
    //             doNotDisturb={false} // we don't have doNotDisturb status from contacts, nor is it relevant to Add People
    //             displayEmail
    //             wrapEmail={false}
    //             isConnected={isConnected}
    //             onMemberClick={permissionRemoveId !== -1 ? () => onRemoveClick(permissionRemoveId) : () => {}}
    //             addManager={() => {}}
    //             removeManager={() => {}}
    //             showMemberProfile={() => {}}
    //             isTeamList /* Adding isTeamList to skip using start/end dates */
    //         />
    //     );
    // };

    const renderPermissionItem = (entityResourcePermissions) => {
        if (!entityResourcePermissions || entityResourcePermissions.length < 1) { return null; }

        const entityKey = generateEntityKey(entityResourcePermissions[0]);
        const multiplePermissions = entityResourcePermissions.length > 1;

        return (
            <div key={entityKey}>
                {multiplePermissions ? (
                    <div
                        role="button"
                        tabIndex="0"
                        onClick={() => togglePermissionVisibility(entityKey)}
                        onKeyDown={() => togglePermissionVisibility(entityKey)}
                    >
                        {renderPermissionDetails(entityResourcePermissions[0], true, false)}
                    </div>
                ) : null}

                {!multiplePermissions || visiblePermissionsMap[entityKey] ? (
                    entityResourcePermissions.map((resourcePermission) => (
                        <div key={resourcePermission.permissionId}>
                            {renderPermissionDetails(resourcePermission, !multiplePermissions, true)}
                        </div>
                    ))) : null}
            </div>
        );
    };

    const renderShareWithBasicUsersButton = () => (
        <div
            style={{
                ...(!addDisabled ? ChatroomDetailsStyles.commandArea : ChatroomDetailsStyles.commandAreaDisabled),
                textAlign: 'center',
            }}
            className="lineButtons"
            role="button"
            tabIndex="0"
            onKeyDown={!addDisabled ? onShareWithBasicUsersClick : null}
            onClick={!addDisabled ? onShareWithBasicUsersClick : null}
            data-tip="Share this donor with all Basic Users on the platform"
            data-delay-show="500"
            data-effect="solid"
        >
            Share this donor with Basic Users
        </div>
    );

    const renderSectionHeader = (title: string) => (
        <div>
            <div
                style={CasePermissionsStyles.sectionHeader}
            >
                <strong>{title}</strong>
            </div>
        </div>
    );

    const renderReadPermissionToggle = () => (
        <div>
            <Switch
                disabled={!canUpdateDonor}
                color="primary"
                checked={orgReadStatus()}
                onClick={() => onToggleOrgReadClick()}
            />
        </div>
    );

    function renderPermissionItemAvatar(authType: string) {
        switch (authType) {
            case 'Organization': return (
                <div>
                    <FontAwesomeIcon
                        style={CasePermissionsStyles.permissionsIconWrapper}
                        color={orgReadStatus() ? Colors.brandPrimary : Colors.gray}
                        icon={faBuilding}
                    />
                </div>
            );
            default: return (
                <FontAwesomeIcon
                    style={CasePermissionsStyles.permissionsIconWrapper}
                    color={Colors.gray}
                    icon={faUserCircle}
                />
            );
        }
    }

    // const renderCaseAdminUsers = () => (
    //     <div style={CasePermissionsStyles.section}>
    //         {renderSectionHeader('Case Admin')}
    //         {caseAdminUsers.map((user) => (renderAdminUser(user)))}
    //     </div>
    // );

    const renderPermissions = () => (
        <div style={CasePermissionsStyles.section}>
            {renderSectionHeader('Case Access')}
            {Object.keys(resourcePermissionsMap).map((entityKey) => (
                renderPermissionItem(resourcePermissionsMap[entityKey])
            ))}
        </div>
    );

    const renderFollowerGroups = () => (
        <div style={CasePermissionsStyles.section}>
            {renderSectionHeader('Case Followers')}

            {Object.keys(donorFollowers).length > 0 ? (
                <>
                    <div style={ChatroomDetailsStyles.border} />
                    {Object.keys(donorFollowers).map((donorFollowerId) => (
                        <FollowerGroupItem
                            key={`follower-${donorFollowerId}`}
                            canUpdate={canUpdateDonor}
                            isDisabled={followersLoading}
                            followerGroup={donorFollowers[donorFollowerId]}
                            onFollowerGroupClick={onFollowerGroupClick}
                            onDeleteFollowerGroup={onDeleteFollowerGroup}
                            onJoinFollowerGroup={onJoinFollowerGroup}
                        />
                    ))}
                </>
            ) : (
                <div style={CasePermissionsStyles.emptyList}>
                    No followers to display
                </div>
            )}
        </div>
    );

    return (
        <div>
            <div style={ChatroomDetailsStyles.actionArea}>
                {(canShareWithBasicUsers && canUpdateDonor && !includesAccessLevel) ? renderShareWithBasicUsersButton() : null}
                <div style={ChatroomDetailsStyles.border} />
                <div
                    style={!addDisabled && !closed ? ChatroomDetailsStyles.commandArea : ChatroomDetailsStyles.commandAreaDisabled}
                    className="lineButtons"
                    role="button"
                    tabIndex="0"
                    onKeyDown={!addDisabled && !closed ? onShowAddPeopleClicked : null}
                    onClick={!addDisabled && !closed ? onShowAddPeopleClicked : null}
                    data-tip="Add admins to this case"
                    data-delay-show="500"
                    data-effect="solid"
                >
                    Add admin
                </div>
                <div style={ChatroomDetailsStyles.border} />
                <div
                    style={!addDisabled && !closed ? ChatroomDetailsStyles.commandArea : ChatroomDetailsStyles.commandAreaDisabled}
                    className="lineButtons"
                    role="button"
                    tabIndex="0"
                    onKeyDown={!addDisabled && !closed ? onAddFollowerGroup : null}
                    onClick={!addDisabled && !closed ? onAddFollowerGroup : null}
                    data-tip="Add followers to this case"
                    data-delay-show="500"
                    data-effect="solid"
                >
                    Add followers
                </div>
                <div style={ChatroomDetailsStyles.border} />
                <div
                    style={!casesPreferencesDisabled && !closed ? ChatroomDetailsStyles.commandArea : ChatroomDetailsStyles.commandAreaDisabled}
                    className="lineButtons"
                    role="button"
                    tabIndex="0"
                    onKeyDown={!casesPreferencesDisabled && !closed ? onOpenCasePreferences : null}
                    onClick={!casesPreferencesDisabled && !closed ? onOpenCasePreferences : null}
                    data-tip="Add followers from last case"
                    data-delay-show="500"
                    data-effect="solid"
                >
                    Add default followers
                </div>
                <div style={ChatroomDetailsStyles.border} />
                <div
                    style={CasePermissionsStyles.fieldContainerWithButton}
                    data-tip="Toggle read permission for organization members"
                    data-delay-show="500"
                    data-effect="solid"
                >
                    Organization Read Access
                    {orgPermissions ? renderReadPermissionToggle() : 'Loading...'}
                </div>
                <div style={ChatroomDetailsStyles.border} />
                {/* {renderCaseAdminUsers()} */}
                {renderPermissions()}
                {renderFollowerGroups()}
            </div>
            {renderConfirmDeleteDialog()}
        </div>
    );
}
