// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    selectProfileName,
    selectProfileEmail,
    selectProfilePhone,
    selectProfileOrganizationName,
    selectProfileOrganizationRole,
    selectProfileCommunicationPref,
} from 'txp-core';

import { formatPhone } from '../Utils/serializePhone';
import BackButton from './BackButton';
import ProfileStyles from './Styles/ProfileStyles';
import Images from '../Themes/Images';
import type { AvatarMap, ChatroomMember } from '../Utils/types';

type Props = {
    member: ChatroomMember,
    avatars: AvatarMap,
    buttonLabel: string,

    closeMemberProfile: () => *,
};

class MemberProfile extends PureComponent<Props> {
    renderField = (label: string, content: ?string) => ((content && content.length > 0)
        ? (
            <div
                style={ProfileStyles.memberInfoContainer}
            >
                <div
                    style={ProfileStyles.contentContainer}
                >
                    <span
                        style={ProfileStyles.content}
                    >
                        {content}
                    </span>
                </div>
                <div
                    style={ProfileStyles.labelContainer}
                >
                    <span
                        style={ProfileStyles.memberLabel}
                    >
                        {label}
                    </span>
                </div>
            </div>
        ) : null
    );

    render() {
        const {
            member,
            avatars,
            buttonLabel,
            closeMemberProfile,
        } = this.props;

        const {
            userId,
        } = member.profile;

        const memberExists = member && member.profile;

        const fullName = selectProfileName(member.profile);
        const organizationName = memberExists && member.profile.organizationName !== 'No organization'
            ? selectProfileOrganizationName(member.profile) : '';
        const organizationRole = memberExists && member.profile.organizationalRole ? selectProfileOrganizationRole(member.profile) : '';
        const communicationPreferences = memberExists ? selectProfileCommunicationPref(member.profile) : '';

        const avatar = (avatars[userId] || Images.circledDefaultProfile);

        return (
            <div style={{ marginTop: 10, }}>
                <BackButton
                    label={buttonLabel}
                    goBack={closeMemberProfile}
                />
                <div style={ProfileStyles.profileImageWrapper}>
                    <img
                        style={ProfileStyles.profileImage}
                        src={avatar}
                        alt=""
                    />
                </div>
                <div
                    style={ProfileStyles.memberNameContainer}
                >
                    <span
                        style={ProfileStyles.memberName}
                    >
                        {fullName}
                    </span>
                </div>
                {this.renderField('Email', selectProfileEmail(member.profile))}
                {this.renderField('Phone', formatPhone(selectProfilePhone(member.profile)))}
                {this.renderField('Organization Name', organizationName)}
                {this.renderField('Organization Role', organizationRole)}
                {
                    communicationPreferences && communicationPreferences.length > 0
                        ? (
                            <div
                                style={ProfileStyles.memberInfoContainer}
                            >
                                <textarea
                                    style={ProfileStyles.commPref}
                                    cols={41}
                                    disabled
                                    value={communicationPreferences}
                                />
                            </div>
                        ) : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    member: state.chatroom.selectedChatroomMember,
    avatars: state.chatList.avatars || {},
});

export default connect(mapStateToProps, {})(MemberProfile);
