// @flow
import type { CaseNote } from './types';

export function convertToCaseNote(caseNoteResponseObject: any): CaseNote {
    return {
        noteId: caseNoteResponseObject.note_id,
        caseId: caseNoteResponseObject.case_id,
        userId: caseNoteResponseObject.user_id,
        createDate: caseNoteResponseObject.create_date,
        note: caseNoteResponseObject.note,
    };
}
