// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ChatroomDetailsStyles from '../Components/Styles/ChatroomDetailsStyles';
import Colors from '../Themes/Colors';
import styles from '../Components/Styles/DonorStyles';
import RetryButton from '../Components/RetryButton';

type Props = {
    children: any,
    donorLoading: boolean,

    onRetryDonor: () => *,
};

type State = {
    hasError: boolean,
};

class DonorPanelErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // eslint-disable-next-line no-console
        console.log(error);
        // eslint-disable-next-line no-console
        console.log(errorInfo);

        this.setState({
            hasError: true,

        });
    }

    render() {
        const {
            donorLoading,
            onRetryDonor,
        } = this.props;

        const {
            hasError,
        } = this.state;

        if (hasError) {
            return (
                <div style={ChatroomDetailsStyles.noFiles}>
                    <div>
                        <FontAwesomeIcon
                            color={Colors.pendingYellow}
                            icon={faExclamationTriangle}
                            size="lg"
                        />
                        No Case data could be loaded.
                    </div>
                    <div>
                        Is your network connection still active?
                        <div style={styles.smallTopMargin}>
                            You can try to reload the Case with the Try Again button below, or you can reload the page.
                        </div>
                        <div style={styles.smallTopMargin}>
                            <RetryButton text="Try Again" isLoading={donorLoading} onClick={() => onRetryDonor()} />
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    donorLoading: state.loading.donor,
});

export default connect(mapStateToProps, {})(DonorPanelErrorBoundary);
