// @flow
import React from 'react';
import FontIcon from 'react-toolbox/lib/font_icon';

import { formatPhone } from '../Utils/serializePhone';
import UserDetailsStyles from './Styles/UserDetailsStyles';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import Images from '../Themes/Images';

type Props = {
    externalUser: any,
    isLoading: boolean,
    canUpdate: boolean,
    onRemoveMember: (userId: number) => *,
};

export default function FollowerExternalMemberItem(props: Props) {
    const {
        externalUser,
        onRemoveMember,
        isLoading,
        canUpdate,
    } = props;

    const renderButtonArea = () => (
        <button
            style={isLoading ? UserDetailsStyles.memberButtonDisabled : UserDetailsStyles.memberButton}
            type="button"
            disabled={isLoading}
            onClick={() => onRemoveMember(externalUser.userId)}
            data-tip="Remove member from follower group"
            data-delay-show="500"
            data-effect="solid"
        >
            <FontIcon
                className={isLoading ? 'iconDisabled24' : 'iconGray'}
                value="cancel"
                alt=""
            />
        </button>
    );

    return (
        <div style={UserDetailsStyles.userRow}>
            <div style={UserDetailsStyles.userRowContent}>
                <div
                    style={UserDetailsStyles.profileImageWrapper}
                >
                    <img
                        style={ChatroomDetailsStyles.profileImage}
                        src={Images.circledDefaultProfile}
                        alt=""
                    />
                </div>
                <div style={UserDetailsStyles.userRowText}>
                    <div style={UserDetailsStyles.userName}>
                        <div style={UserDetailsStyles.userName}>
                            {externalUser.fullName || 'External User'}
                        </div>
                    </div>
                    {externalUser.phone ? (
                        <div style={UserDetailsStyles.smallDetails}>
                            {formatPhone(externalUser.phone)}
                        </div>
                    ) : null}

                    {externalUser.email ? (
                        <div
                            style={
                                { ...UserDetailsStyles.smallDetails, ...{ overflowWrap: 'anywhere', }, } // Alternatively overflowWrap: none
                            }
                        >
                            {externalUser.email}
                        </div>
                    ) : null}
                </div>
            </div>
            <div style={UserDetailsStyles.buttonRow}>
                {canUpdate ? renderButtonArea() : null}
            </div>
        </div>
    );
}
