// @flow
import Colors from '../../Themes/Colors';

const CasePreferencesStyles = {
    title: {
        textAlign: 'center',
        fontSize: 24,
        color: Colors.black,
        paddingBottom: 15,
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 16,
        color: Colors.black,
        paddingBottom: 15,
        width: '75%',
        margin: 'auto',
    },
    gridLabel: {
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
    },
    addPeople: {
        overflowY: 'scroll',
        maxHeight: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 8,
        borderColor: Colors.chatListItemBorder,
        borderWidth: '2px',
        borderStyle: 'solid',
        transform: 'translate(0, 0)',
    },
    addPeopleButton: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        color: Colors.blue,
        outline: 'none',
        backgroundColor: Colors.white,
        cursor: 'pointer',
    },
    followerMembersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.75em 1em',
    },
    groupWrapper: {
        padding: 10,
        margin: 10,
        borderColor: Colors.chatListItemBorder,
        borderStyle: 'solid',
        borderWidth: 2,
        width: '75%',
    },
    groupCard: {
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    preferencesContainer: {
        margin: 'auto',
        width: '75%',
        paddingTop: 20,
    },
    accordionSummary: {
        display: 'flex',
        paddingLeft: 16,
        paddingRight: 16,
    },
    accordionRow: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    createButton: {
        textTransform: 'none',
        color: Colors.white,
        backgroundColor: Colors.buttonBackground,
    },
    skipButton: {
        textTransform: 'none',
        color: Colors.white,
        backgroundColor: Colors.lightGray,
    },
    addButton: {
        textTransform: 'none',
        color: Colors.white,
        backgroundColor: Colors.green,
    },
    disabledButton: {
        textTransform: 'none',
        color: Colors.buttonTextDisabled,
        backgroundColor: Colors.buttonBackgroundDisabled,
    },
    actionsWrapper: {
        display: 'flex',
        paddingTop: 24,
        gap: 24,
    },
};

export default CasePreferencesStyles;
