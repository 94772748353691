// @flow

export const BOOLEAN = 'boolean';
export const STRING = 'string';

export const UI_TYPE_SWITCH = 'switch';
export const UI_TYPE_CHECK = 'check';
export const UI_TYPE_TEXT = 'text';
export const UI_TYPE_TXC_LIST = 'txclist';
export const UI_TYPE_SURGEON_LIST = 'surgeonlist';
export const UI_TYPE_TIME = 'timepicker';
export const UI_TYPE_DATE = 'datepicker';
export const UI_TYPE_BINARY = 'binary';
export const UI_TYPE_MULT_OPTION = 'multiple'; // deprecated - used for EXPA - do not resuse as badly named
export const UI_TYPE_PICKER_OPTION = 'picker';
export const UI_TYPE_SET_INPUT = 'set'; // Not actually supported yet, current renders an unsupported type field
export const UI_TYPE_SELECT_OPTION = 'select';
export const UI_TYPE_TELEPHONE = 'tel';
export const UI_TYPE_RADIO = 'radio';
export const UI_TYPE_REFERRING = 'referring';

export const KB_TYPE_PHONE = 'phone-pad';
export const KB_TYPE_NUMBER = 'number-pad';
export const KB_TYPE_EMAIL = 'email-address';
