// @flow
import Colors from '../../Themes/Colors';

const chatProfileImageSize = 50;
export const chatMemberProfileImageSize = 86 / 2;

export const veryLargeFontSize = 24;
export const mediumFontSize = 14;
export const smallFontSize = 12;

const ChatroomDetailsStyles = {
    profileImageSelectedWrapper: {
        marginRight: 30,
        minWidth: '200px',
    },
    profileImage: {
        backgroundColor: Colors.white,
        borderRadius: chatProfileImageSize / 1.5,
        height: chatProfileImageSize,
        width: chatProfileImageSize,
    },
    profileCircle: {
        marginLeft: -50,
        height: chatProfileImageSize,
        width: chatProfileImageSize,
    },
    mainContainer: {
        textAlign: 'center',
        backgroundColor: Colors.lighterGray,
        height: '100vh',
    },
    header: {
        color: Colors.white,
        backgroundImage: 'linear-gradient(to right, #2E3192, #00A79D)',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
    },
    headerPadding: {
        padding: 20,
    },
    headerPaddingSecondary: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        paddingTop: 50,
    },
    textWrapper: {
        width: '100%',
    },
    secondaryHeader: {
        backgroundColor: Colors.white,
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
    mediaUploadHeader: {
        backgroundColor: Colors.white,
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
    fullMediaViewControlsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
    },
    notificationSoundItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: Colors.gray,
        fontWeight: '600',
        fontSize: mediumFontSize,
    },
    descriptionArea: {
        color: Colors.gray,
        padding: 20,
    },
    leftAlign: {
        textAlign: 'left',
    },
    actionArea: {
        textAlign: 'left',
    },
    commandArea: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        color: Colors.blue,
        outline: 'none',
        backgroundColor: Colors.white,
        cursor: 'pointer',
    },
    notificationSoundCommandArea: {
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 16,
        outline: 'none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Colors.white,
        cursor: 'pointer',
    },
    notificationSoundTitleText: {
        color: Colors.blue,
    },
    notificationSoundNameText: {
        color: Colors.gray,
    },
    radioButtonWrapper: {
        marginRight: 15,
        paddingTop: 15,
    },
    muteCommandArea: {
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 16,
        color: Colors.blue,
        outline: 'none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        backgroundColor: Colors.white,
    },
    commandAreaLeave: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        color: Colors.red,
        outline: 'none',
        cursor: 'pointer',
        backgroundColor: Colors.white,
    },
    commandAreaDelete: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        color: Colors.red,
        outline: 'none',
        cursor: 'pointer',
        backgroundColor: Colors.white,
    },
    commandAreaDeleteDisabled: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        outline: 'none',
        backgroundColor: Colors.white,
        color: Colors.linkTextDisabled,
    },
    commandAreaDisabled: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        outline: 'none',
        backgroundColor: Colors.white,
        color: Colors.linkTextDisabled,
    },
    border: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
    },
    userArea: {
        color: Colors.gray,
        backgroundColor: Colors.lighterGray,
        textAlign: 'left',
        padding: 20,
    },
    organArea: {
        color: Colors.gray,
        backgroundColor: Colors.lighterGray,
        textAlign: 'left',
    },
    selectedUsersAreaCreate: {
        color: Colors.gray,
        backgroundColor: Colors.white,
        textAlign: 'left',
        padding: 10,
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'block',
        minHeight: '25px',
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderTopWidth: '2px',
        borderTopColor: Colors.chatListItemBorder,
        borderTopStyle: 'solid',
    },
    titleSmall: {
        fontSize: smallFontSize,
    },

    centeredTitleSmall: {
        fontSize: smallFontSize,
        textAlign: 'center',
        marginTop: 5,
        color: Colors.gray,
    },
    titleMedium: {
        fontSize: mediumFontSize,
    },
    centeredTitleLarge: {
        fontSize: 24,
        color: Colors.black,
        textAlign: 'center',
    },
    nameInput: {
        fontSize: veryLargeFontSize,
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        background: 'none',
        color: Colors.white,
        width: '90%',
    },
    textInput: {
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.lightGray,
        borderBottomStyle: 'solid',
        background: Colors.lighterGray,
        fontSize: 14,
        width: '100%',
    },
    updateButton: {
        background: 'none',
        outline: 'none',
        color: Colors.blue,
        border: 0,
        cursor: 'pointer',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 5,
        padding: 10,
        fontSize: 14,
    },
    saveButton: {
        background: 'none',
        outline: 'none',
        border: 0,
        color: Colors.blue,
        cursor: 'pointer',
        float: 'right',
        marginTop: 5,
        marginRight: 25,
        fontSize: 14,
    },
    saveButtonDisabled: {
        background: 'none',
        outline: 'none',
        border: 0,
        color: Colors.linkTextDisabled,
        cursor: 'default',
        float: 'right',
        marginTop: 5,
        marginRight: 25,
        fontSize: 14,
    },
    updateButtonDisabled: {
        background: 'none',
        outline: 'none',
        color: Colors.linkTextDisabled,
        border: 0,
        cursor: 'default',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 5,
        padding: 10,
        fontSize: 14,
    },
    playButton: {
        outline: 'none',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        marginLeft: 15,
    },
    backButton: {
        outline: 'none',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    fileContainer: {
        marginTop: 10,
        marginBottom: 10,
        cursor: 'pointer',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
    },
    fileImage: {
        borderRadius: chatMemberProfileImageSize / 2,
        height: chatMemberProfileImageSize,
        overlayColor: Colors.chatAreaBackground,
        width: chatMemberProfileImageSize,
    },
    fileImageFullSize: {
        width: '90%',
        height: 'auto',
        marginTop: 20,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    PDFFullSize: {
        width: '90%',
        height: 500,
        marginTop: 20,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    hiddenImage: {
        display: 'none',
    },
    loadingFullSizeWrapper: {
        width: 200,
        height: 200,
        marginTop: 20,
        textAlign: 'center',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: Colors.chatItemBackground,
    },
    chatFileWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: 16,
        paddingVertical: 8,
        marginBottom: 5,
        marginLeft: 10,
    },
    chatFileImageWrapper: {
        backgroundColor: Colors.blue,
        borderRadius: chatMemberProfileImageSize / 2,
        height: chatMemberProfileImageSize,
        marginRight: 16,
        width: chatMemberProfileImageSize,
    },
    chatFileInfoRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    chatFileTitleRow: {
        flexDirection: 'row',
        overflow: 'hidden',
    },
    chatFileSizeRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 10,
    },
    chatFileName: {
        color: Colors.black,
        fontSize: 14,
        lineHeight: 1.5,
        paddingRight: 8,
        textAlign: 'left',
    },
    chatFileExtraInfo: {
        color: Colors.gray,
        fontSize: 12,
        lineHeight: 1.5,
        textAlign: 'right',
    },
    chatFileTimeInfo: {
        color: Colors.gray,
        fontSize: 12,
        paddingTop: 4,
    },
    noFiles: {
        color: Colors.formLabel,
        fontSize: 14,
        textAlign: 'center',
        marginTop: 100,
    },
    organItemRow: {
        flexDirection: 'row',
        width: '100%',
        height: 30,
        paddingTop: 5,
        textAlign: 'center',
    },
    addPeopleDisabledTextWrapper: {
        backgroundColor: Colors.white,
    },
    addPeopleDisabledText: {
        color: Colors.formLabel,
        fontSize: 12,
        paddingLeft: 10,
        fontStyle: 'italic',
        userSelect: 'none',
        cursor: 'default',
    },
    deleteTitle: {
        fontSize: veryLargeFontSize,
        color: Colors.black,
        paddingBottom: 'inherit',
    },
    deleteButton: {
        color: Colors.red,
    },
    deleteFileButton: {
        color: Colors.red,
        marginRight: 10,
        cursor: 'pointer',
    },
    dialogCancelButton: {
        background: 'none',
        outline: 'none',
        border: 'none',
        color: Colors.black,
        fontSize: 16,
        cursor: 'pointer',
    },
    dialogDeleteButton: {
        background: 'none',
        outline: 'none',
        border: 'none',
        color: Colors.red,
        fontSize: 16,
        cursor: 'pointer',
        marginLeft: 10,
    },
    dialogActionsWrapper: {
        paddingTop: '2em',
        display: 'flex',
        justifyContent: 'flex-end',
    },
};

export default ChatroomDetailsStyles;
