// @flow
import React, { PureComponent } from 'react';
import { selectProfileName, selectProfileEmail } from 'txp-core';

import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import type { UserProfile } from '../Utils/types';

type Props = {
    surgeon: UserProfile,

    selectSurgeon: (surgeon: UserProfile) => *,
};

class SurgeonItem extends PureComponent<Props> {
    chooseSurgeon = () => {
        const {
            surgeon,
            selectSurgeon,
        } = this.props;

        selectSurgeon(surgeon);
    };

    render() {
        const {
            surgeon,
        } = this.props;

        return (
            <div // eslint-disable-line
                style={CreateChatroomStyles.offerContainer}
                role="button"
                tabIndex={0}
                onClick={this.chooseSurgeon}
            >
                <span style={CreateChatroomStyles.mainText}>
                    {selectProfileName(surgeon)}
                </span>
                <span style={CreateChatroomStyles.subText}>
                    {selectProfileEmail(surgeon)}
                </span>
            </div>
        );
    }
}

export default SurgeonItem;
