// @flow
import Colors from '../../Themes/Colors';

const CheckboxStyles = {
    checkboxWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: 10,
        marginBottom: 10,
    },
    checkboxWrapperDisabled: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'default',
        marginTop: 10,
        marginBottom: 10,
    },
    label: {
        fontSize: 16,
        marginLeft: 10,
        color: Colors.black,
    },
};

export default CheckboxStyles;
