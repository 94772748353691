import Colors from '../../Themes/Colors';

const GenericFormStyles = {
    padding10: {
        padding: 10,
    },
    marginVertical10: {
        marginVertical: 10,
    },
    formTitle: {
        fontSize: 16,
        color: Colors.black,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    formSwitchCheck: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 10,
    },
    labelText: {
        textAlign: 'left',
        fontSize: 14,
        color: Colors.formLabel,
        marginTop: 10,
        marginBottom: 5,
        fontWeight: '600',
        flex: 0.95,
        overflowWrap: 'anywhere',
        wordBreak: 'normal',
    },
    helperText: {
        marginLeft: 14,
        marginRight: 14,
        paddingBottom: 9,
        fontSize: '0.75em',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    noteText: {
        textAlign: 'left',
        fontSize: 10,
        color: Colors.formLabel,
        marginTop: 10,
        marginBottom: 5,
        fontWeight: '600',
    },
    valueText: {
        textAlign: 'left',
        fontSize: 17,
        marginLeft: 10,
        marginBottom: 5,
        color: Colors.black,
    },
    valueTextDisabled: {
        textAlign: 'left',
        fontSize: 17,
        marginLeft: 30,
        marginBottom: 5,
        color: Colors.textInputTextDisabled,
    },
    borderBottom: {
        borderBottomWidth: 2,
        borderBottomColor: Colors.chatListItemBorder,
    },
    collapsableTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    multilineNotes: {
        fontSize: 14,
        lineHeight: 18,
        height: 3 * 18,
        color: Colors.black,
        padding: 5,
        borderRadius: 8,
        borderWidth: 2,
        borderColor: Colors.chatListItemBorder,
    },
    binaryWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flexRow: {
        flexDirection: 'row',
    },
    flexStart: {
        alignItems: 'flex-start',
    },
    binaryResponseWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 5,
        marginLeft: 10,
    },
    binaryResponseText: {
        fontSize: 12,
        marginLeft: 5,
    },
    rowInputContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    rowInputWidth: {
        width: 75,
    },
    rowLabelText: {
        textAlign: 'center',
    },
    rowValueText: {
        textAlign: 'center',
        fontSize: 17,
        marginLeft: 10,
        marginBottom: 5,
        color: Colors.black,
    },
    sectionDirections: {
        paddingBottom: 20,
        marginLeft: 5,
        marginRight: 5,
    },
};
export default GenericFormStyles;
