// @flow
import React from 'react';

import IconMenu from 'react-toolbox/lib/menu/IconMenu';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import MenuItem from 'react-toolbox/lib/menu/MenuItem';
import { connect } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';

// explicitly import icons individually to keep bundle size down
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';

import type { Logout } from 'txp-core';
import {
    logout as _logout,
} from 'txp-core';

import { appConfiguration } from '../AppConfiguration';
import hasPermissions, {
    DEMO_DONOR_FEATURE,
    DONOR_CREATE,
    DONOR_VIEW,
    DONOR_WEB_BETA,
    ENTITY_TYPE_UI, NONE, READ, UPLOAD_OFFERS,
} from '../Utils/hasPermissions';
import type { UserPermission, EventName } from '../Utils/types';
import {
    CHAT_TAB,
    CASE_TAB,
    TEAMS_TAB,
    logFirebaseEvent as _logFirebaseEvent,
    selectTab as _selectTab,
} from '../Redux/ApplicationActions';
import ApplicationStyles from '../Themes/ApplicationStyles';
import AuthStyles from '../Containers/Styles/AuthStyles';

type Props = {
    permissions: Array<UserPermission>,
    accessToken: string,
    refreshToken: string,
    selectedTab: string,

    footer: boolean,

    openMenu: () => *,
    showProfile: () => *,
    showHelp: () => *,
    showSettings: () => *,
    showUpload: () => *,
    logFirebaseEvent: (name: EventName) => *,
    selectTab: (tabName: string) => *,
    onSubmitLogout: (accessToken: string, refreshToken: string, deviceToken: string, application: string) => Logout,
};

const MenuBar = (props: Props) => {
    const onLogout = async () => {
        const {
            accessToken,
            refreshToken,
            onSubmitLogout,
            logFirebaseEvent,
        } = props;

        logFirebaseEvent('logout-click');

        onSubmitLogout(accessToken, refreshToken, '', 'TXP_CHAT_WEB');
    };

    const onProfileClick = () => {
        const {
            showProfile,
            logFirebaseEvent,
        } = props;

        logFirebaseEvent('open-profile-click');

        showProfile();
    };

    const onHelpClick = () => {
        const {
            showHelp,
            logFirebaseEvent,
        } = props;

        logFirebaseEvent('open-help-click');

        showHelp();
    };

    const onFAQClick = () => {
        window.open('https://help.omnilife.health/en/', '_blank');
    };

    const onSettingsClick = () => {
        const {
            showSettings,
            logFirebaseEvent,
        } = props;

        logFirebaseEvent('open-settings-click');

        showSettings();
    };

    const onUploadClick = () => {
        const {
            showUpload,
            logFirebaseEvent,
        } = props;

        logFirebaseEvent('upload-offer-click');

        showUpload();
    };

    const onMenuClick = (event: any) => {
        const { openMenu, } = props;
        openMenu();
        event.stopPropagation();
    };

    const donorTabSelect = () => {
        const {
            selectTab,
        } = props;
        selectTab(CASE_TAB);
    };

    const teamsTabSelect = () => {
        const {
            selectTab,
        } = props;
        selectTab(TEAMS_TAB);
    };

    const roomTabSelect = () => {
        const {
            selectTab,
        } = props;
        selectTab(CHAT_TAB);
    };

    const { permissions, footer, selectedTab, } = props;
    const roomsSelected = (selectedTab === CHAT_TAB);
    const donorsSelected = (selectedTab === CASE_TAB);
    const moreSelected = (selectedTab === TEAMS_TAB);
    const buttonStyle = footer ? 'tabButtonFooter' : 'tabButton';
    const menuStyle = footer ? 'menuFooter' : null;
    // const menuLabelStyle = footer ? 'menuLabelFooter' : 'menuLabel';
    let roomsMenuLabelStyle = 'menuLabel';
    let donorsMenuLabelStyle = 'menuLabel';
    let moreMenuLabelStyle = 'menuLabel';
    if (footer) {
        roomsMenuLabelStyle = roomsSelected ? 'selectedMenuLabelFooter' : 'menuLabelFooter';
        donorsMenuLabelStyle = donorsSelected ? 'selectedMenuLabelFooter' : 'menuLabelFooter';
        moreMenuLabelStyle = moreSelected ? 'selectedMenuLabelFooter' : 'menuLabelFooter';
    } else {
        roomsMenuLabelStyle = roomsSelected ? 'selectedMenuLabel' : 'menuLabel';
        donorsMenuLabelStyle = donorsSelected ? 'selectedMenuLabel' : 'menuLabel';
        moreMenuLabelStyle = moreSelected ? 'selectedMenuLabel' : 'menuLabel';
    }
    const copyrightMessage = `${(new Date().getFullYear())} OmniLife Health`;
    const licenseLinkText = 'License and Attribution';
    const licenseLinkUrl = process.env.REACT_APP_LICENSE_ATTRIBUTION ?? '';
    const appVersionMessage = ` ${appConfiguration().version}`;
    const canUploadCDSOffer = hasPermissions(permissions, ENTITY_TYPE_UI, UPLOAD_OFFERS, NONE, NONE, READ);
    const canViewDonors = hasPermissions(permissions, ENTITY_TYPE_UI, DONOR_VIEW, NONE, NONE, READ);
    const canCreateDonors = hasPermissions(permissions, ENTITY_TYPE_UI, DONOR_CREATE, NONE, NONE, READ);
    const canViewDemoDonor = hasPermissions(permissions, ENTITY_TYPE_UI, DEMO_DONOR_FEATURE, NONE, NONE, READ);
    const canViewDonorWebBeta = hasPermissions(permissions, ENTITY_TYPE_UI, DONOR_WEB_BETA, NONE, NONE, READ);
    const columnWidth = (canViewDonorWebBeta && (canViewDonors || canCreateDonors || canViewDemoDonor)) ? 3 : 4;

    return (
        <div className="menuBarContainer">
            <Row>
                <Col xs={columnWidth}>
                    <button
                        className={buttonStyle}
                        type="button"
                        tabIndex="0"
                        onClick={roomTabSelect}
                    >
                        {roomsSelected ? (
                            <QuestionAnswerIcon color="primary" />
                        ) : (
                            <QuestionAnswerOutlinedIcon />
                        )}
                    </button>
                    <div className={roomsMenuLabelStyle}>Rooms</div>
                </Col>
                {canViewDonorWebBeta && (canViewDonors || canCreateDonors || canViewDemoDonor)
                    ? (
                        <Col xs={columnWidth}>
                            <button
                                className={buttonStyle}
                                type="button"
                                tabIndex="0"
                                onClick={donorTabSelect}
                            >
                                {donorsSelected ? (
                                    <FavoriteIcon color="primary" />
                                ) : (
                                    <FavoriteBorderOutlined />
                                )}
                            </button>
                            <div className={donorsMenuLabelStyle}>Cases</div>
                        </Col>
                    ) : null}
                <Col xs={columnWidth}>
                    <IconMenu
                        className={menuStyle}
                        icon="help_outline"
                        position="auto"
                        menuRipple={false}
                        onClick={(event) => onMenuClick(event)}
                    >
                        <MenuItem
                            id="help_info"
                            value="help_header"
                            icon="help"
                            caption="Help"
                            disabled
                            ripple={false}
                        />
                        <MenuDivider />
                        <MenuItem
                            id="help_phone_item"
                            value="help_phone"
                            icon="phone"
                            caption="1-800-319-6185"
                            ripple={false}
                        />
                        <MenuItem
                            id="help_email_item"
                            value="help_email"
                            icon="email"
                            caption="success@omnilife.health"
                            ripple={false}
                        />
                        <MenuItem
                            id="help_faq_item"
                            value="help_faq"
                            icon="article"
                            caption="FAQ"
                            ripple={false}
                            onClick={() => onFAQClick()}
                        />
                    </IconMenu>
                    <div className={moreMenuLabelStyle}>Help</div>
                </Col>
                <Col xs={columnWidth}>
                    <IconMenu
                        className={menuStyle}
                        icon="more_horiz"
                        position="auto"
                        menuRipple={false}
                        onClick={(event) => onMenuClick(event)}
                    >
                        <MenuItem
                            id="item"
                            value="my_profile"
                            icon="person"
                            caption="My Profile"
                            ripple={false}
                            onClick={() => onProfileClick()}
                        />
                        <MenuItem
                            id="item"
                            value="my_teams"
                            icon="groups"
                            caption="My Teams"
                            ripple={false}
                            onClick={() => teamsTabSelect()}
                        />
                        <MenuItem
                            value="settings"
                            icon="settings"
                            caption="Settings"
                            ripple={false}
                            onClick={() => onSettingsClick()}
                        />
                        {canUploadCDSOffer
                            ? (
                                <MenuItem
                                    value="upload"
                                    icon="publish"
                                    caption="Upload"
                                    ripple={false}
                                    onClick={() => onUploadClick()}
                                />
                            ) : null}
                        <MenuDivider />
                        <MenuItem
                            value="help"
                            icon="help"
                            caption="Help"
                            ripple={false}
                            disabled={false}
                            onClick={() => onHelpClick()}
                        />
                        <MenuItem
                            value="logout"
                            icon="exit_to_app"
                            caption="Logout"
                            ripple={false}
                            onClick={() => onLogout()}
                        />
                        <MenuDivider />
                        <div style={{ ...AuthStyles.version, ...ApplicationStyles.menuItemPadding, }}>
                            <div>
                                &copy;
                                {copyrightMessage}
                            </div>
                            <div>
                                All Rights Reserved
                            </div>
                            <a style={AuthStyles.version} href={licenseLinkUrl}>{licenseLinkText}</a>
                            <div>
                                {appVersionMessage}
                            </div>
                        </div>
                    </IconMenu>
                    <div className={moreMenuLabelStyle}>More</div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => ({
    permissions: state.permission.permissions,
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.refreshToken,
    selectedTab: state.application.selectedTab,
});

export default connect(mapStateToProps, {
    onSubmitLogout: _logout,
    logFirebaseEvent: _logFirebaseEvent,
    selectTab: _selectTab,
})(MenuBar);
