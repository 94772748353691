// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

// explicitly import icons individually to keep bundle size down
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import FontIcon from 'react-toolbox/lib/font_icon';
import sift from 'sift';
import LoadingIndicator from 'react-loading-indicator';
import Fade from 'react-reveal/Fade';
import { selectProfileName } from 'txp-core';
import debounce from 'lodash.debounce';
import UserDetails from './UserDetails';
import Search from './Search';
import TabSelector from './TabSelector';
import PhoneInputTextField from './PhoneInputTextField';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import CreateChatroomStyles from './Styles/CreateChatroomStyles';
import AddPeopleStyles from './Styles/AddPeopleStyles';
import ChatMessageStyles from './Styles/ChatMessageStyles';
import AuthStyles from '../Containers/Styles/AuthStyles';
import UserDetailsStyles from './Styles/UserDetailsStyles';
import TeamDetailsStyles from './Styles/TeamDetailsStyles';
import Images from '../Themes/Images';
import {
    setSelectedUsers as _setSelectedUsers,
    resetAddPeople as _resetAddPeople,
    addPeopleByEmail as _addPeopleByEmail,
    addPeopleByPhone as _addPeopleByPhone,
    setAddPeopleError as _setAddPeopleError,
    inviteNewUser as _addPeopleInvite,
} from '../Redux/AddPeopleActions';
import type {
    UserProfile,
    ChatroomMemberMap,
    ChatroomMember,
    AvatarMap,
    Team,
} from '../Utils/types';
import BackButton from './BackButton';

const TEAMS_TAB: string = 'teams';
const USERS_TAB: string = 'users';
const DEBOUNCE_MILLIS = 250;

type Props = {
    members: ChatroomMemberMap,
    memberId: number,
    avatars: AvatarMap,
    createChatroom: boolean,
    peopleFilter: string,
    chatInputHeight?: number,
    isConnected: boolean,
    teams: Array<Team>,
    teamsLoading: boolean,

    contactsOrder: Array<number>,
    contactsMap: ChatroomMemberMap,
    contactsAreLoaded: boolean,
    singleUserAdded: boolean,
    newUserInvited: boolean,
    addSingleUserError: string,
    inviteNewUserError: string,
    backButtonLabel?: string,
    hideTitle?: boolean,

    selectedUsers: Array<UserProfile>,
    addPeopleError?: string,
    checkingContact: boolean,

    onHideAddPeople: () => *,
    onAddUsers: (users: Array<UserProfile>) => *,
    onUpdateSelectedUsers?: (user: Array<UserProfile>) => *,
    inviteExistingUser?: (email: string, phone: string) => *,
    inviteNewUser?: (email: string, phone: string) => *,
    clearMessage: () => *,
    filterPeople: (filter: string) => *,

    setSelectedUsers: typeof _setSelectedUsers,
    resetAddPeople: typeof _resetAddPeople,
    addPeopleByEmail: typeof _addPeopleByEmail,
    addPeopleByPhone: typeof _addPeopleByPhone,
    setAddPeopleError: typeof _setAddPeopleError,
    addPeopleInvite: typeof _addPeopleInvite,
};

type State = {
    isDialogOpen: boolean,
    email: string,
    phone: string,
    allContacts: Array<any>,
    filteredContacts: Array<any>,
    addByEmail: boolean,
    addByPhone: boolean,
    showDifference: boolean,
    emailOrPhone: boolean,
    showInviteOptions: boolean,
    currentTab: string,
    selectedTeams: Array<number>,
    userTeamMap: {[number]: Array<number>},
    filteredTeams: Array<Team>,
    isFiltering: boolean,
};

class AddPeople extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            isDialogOpen: false,
            email: '',
            phone: '',
            allContacts: [],
            filteredContacts: [],
            addByEmail: false,
            addByPhone: false,
            showDifference: false,
            emailOrPhone: true,
            showInviteOptions: false,
            currentTab: USERS_TAB,
            selectedTeams: [],
            userTeamMap: {},
            filteredTeams: [],
            isFiltering: false,
        };
    }

    componentDidMount() {
        const {
            contactsOrder,
            contactsMap,
        } = this.props;

        const allContacts = [];
        contactsOrder.map((userId: number) => (
            allContacts.push(contactsMap[userId.toString()].profile)
        ));

        this.setState({
            allContacts,
        });
    }

    componentDidUpdate(prevProps: Props) {
        const {
            contactsMap,
            contactsOrder,
            addSingleUserError,
            inviteNewUserError,
            addPeopleError,
            selectedUsers,
            singleUserAdded,
            newUserInvited,
        } = this.props;

        if (prevProps.contactsOrder !== contactsOrder) {
            const allContacts = [];

            contactsOrder.map((userId: number) => (
                allContacts.push(contactsMap[userId.toString()].profile)
            ));

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                allContacts,
            });
        }

        const hasAddPeopleError = addPeopleError && addPeopleError !== 'User is already a member';
        if (addSingleUserError === 'Not a registered user' || inviteNewUserError !== '' || hasAddPeopleError) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                showInviteOptions: true,
            });
        } else {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                showInviteOptions: false,
            });
        }

        // close "Add by email/phone" dialog when user selection changes
        if (prevProps.selectedUsers !== selectedUsers || prevProps.singleUserAdded !== singleUserAdded || prevProps.newUserInvited !== newUserInvited) {
            this.onClearAndClose();
        }
    }

    // Clean up selected users
    componentWillUnmount() {
        const {
            resetAddPeople,
            selectedUsers,
            clearMessage,
        } = this.props;

        if (selectedUsers.length > 0) {
            resetAddPeople();
        }

        clearMessage();
    }

    onChangeTab = (selectedTab: string) => {
        const {
            peopleFilter,
        } = this.props;

        if (selectedTab === USERS_TAB) {
            const filteredContacts = this.filterContacts(peopleFilter);
            this.setState({
                filteredContacts,
                currentTab: selectedTab,
            });
        } else if (selectedTab === TEAMS_TAB) {
            const filteredTeams = this.filterTeams(peopleFilter);
            this.setState({
                filteredTeams,
                currentTab: selectedTab,
            });
        }
    }

    onTeamClick = (team: Team) => {
        const {
            selectedTeams,
        } = this.state;
        const selectedTeamIndex = selectedTeams.indexOf(team.teamId);
        if (selectedTeamIndex === -1) {
            this.selectTeam(team);
        } else {
            this.deselectTeam(team);
        }
    }

    onMemberClick = (userProfile: UserProfile) => {
        if (!this.isUserSelected(userProfile.userId)) {
            this.selectUser(userProfile, 0);
        } else {
            this.deselectUser(userProfile);
        }
    };

    onAddUsers = () => {
        const {
            onAddUsers,
            selectedUsers,
        } = this.props;

        onAddUsers(selectedUsers);
    };

    onInviteNewUser = () => {
        const {
            clearMessage,
            inviteNewUser,
        } = this.props;

        const {
            email,
            phone,
        } = this.state;

        clearMessage();

        // Use parent component-supplied function if provided
        if (inviteNewUser) {
            inviteNewUser(email, phone);
        } else {
            this.defaultInviteNewUser(email, phone);
        }
    };

    onInviteExistingUser = () => {
        const {
            clearMessage,
            inviteExistingUser,
        } = this.props;

        const {
            email,
            phone,
        } = this.state;

        clearMessage();

        // Use parent component-supplied function if provided
        if (inviteExistingUser) {
            inviteExistingUser(email, phone);
        } else {
            this.defaultInviteExistingUser(email, phone);
        }
    };

    onClearAndClose = () => {
        const {
            clearMessage,
        } = this.props;

        clearMessage();

        this.dialogClose();
    };

    onEmailClick = () => {
        this.setState({
            addByEmail: true,
            addByPhone: false,
            emailOrPhone: false,
        });
    };

    onPhoneClick = () => {
        this.setState({
            addByEmail: false,
            addByPhone: true,
            emailOrPhone: false,
        });
    };

    onCancelAdd = () => {
        this.setState({
            addByEmail: false,
            addByPhone: false,
            email: '',
            phone: '',
            emailOrPhone: true,
        });

        this.onClearAndClose();
    };

    onToggleDifference = () => {
        const {
            showDifference,
        } = this.state;

        this.setState({
            showDifference: !showDifference,
        });
    };

    onSearchTextChange = (query: string) => {
        // First, update query state before debounce occurs to prevent weird delay b/w input text update and debounced call
        const {
            filterPeople,
        } = this.props;
        filterPeople(query); // updates redux state of search filter string
        this.setState({
            isFiltering: true,
        });

        const {
            currentTab,
        } = this.state;

        if (currentTab === USERS_TAB) {
            this.debouncedFilterContacts(query);
        } else if (currentTab === TEAMS_TAB) {
            this.debouncedFilterTeams(query);
        }
    };

    setEmail = (email: any) => {
        this.setState({
            email: email.target.value,
        });
    };

    setPhone = (phone: any) => {
        this.setState({
            phone,
        });
    };

    defaultInviteNewUser = (email, phone) => {
        const {
            addPeopleInvite,
        } = this.props;

        addPeopleInvite(email, phone);
    };

    defaultInviteExistingUser = (email?: string, phone?: string) => {
        const {
            addPeopleByEmail,
            addPeopleByPhone,
            setAddPeopleError,
        } = this.props;

        const existingMember = this.findMemberByContactInfo(email, phone);

        if (existingMember && typeof existingMember.membershipStatus === 'string') {
            const status = existingMember.membershipStatus;

            if (status !== 'Removed' && status !== 'Left') {
                setAddPeopleError('User is already a member');
                return;
            }
        }

        if (email) {
            addPeopleByEmail(email);
        } else if (phone) {
            addPeopleByPhone(phone);
        }
    };

    dialogClose = () => {
        this.setState({
            isDialogOpen: false,
            email: '',
            phone: '',
            emailOrPhone: true,
            addByEmail: false,
            addByPhone: false,
            showDifference: false,
            showInviteOptions: false,
        });
    };

    dialogOpen = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    userAvailableForSelection = (userId: number) => {
        const {
            members,
            createChatroom,
            contactsMap,
            memberId,
        } = this.props;

        if (userId == null) {
            return false;
        }
        if (members && !createChatroom) {
            // Exclude if already in the chatroom
            const chatMember = members[userId.toString()]
                    && members[userId.toString()].membershipStatus !== 'Removed'
                    && members[userId.toString()].membershipStatus !== 'Left';
            if (chatMember) {
                return false;
            }
        }

        // Exclude if self
        const contact = contactsMap[userId.toString()];
        if (contact.profile.userId === memberId) {
            return ('');
        }

        return true;
    };

    findMemberByContactInfo = (email?: string, phone?: string): ?ChatroomMember | ?UserProfile => {
        const {
            members,
        } = this.props;

        if (!email && !phone) {
            return undefined;
        }

        const memberEntries = Object.values(members);
        if (!memberEntries.length) {
            return undefined;
        }

        const isUserProfileType = !Object.prototype.hasOwnProperty.call(memberEntries[0], 'profile');

        // TODO: Fix members type as not always ChatroomMemberMap
        return isUserProfileType
            // $FlowFixMe
            ? this.findInUserProfiles(email, phone, memberEntries)
            // $FlowFixMe
            : this.findInChatroomMembers(email, phone, memberEntries);
    }

    findInUserProfiles = (email?: string, phone?: string, members: UserProfile[]): ?UserProfile => {
        const normalizedEmail = email && email.toLowerCase();
        const finder = email
            ? ((member) => member.email?.toLowerCase() === normalizedEmail)
            : ((member) => member.phone === phone);

        return members.find(finder);
    }

    findInChatroomMembers = (email?: string, phone?: string, members: ChatroomMember[]): ?ChatroomMember => {
        const normalizedEmail = email && email.toLowerCase();
        const finder = email
            ? ((member) => member.profile.email?.toLowerCase() === normalizedEmail)
            : ((member) => member.profile.phone === phone);

        return members.find(finder);
    }

    isUserSelected = (userId: number) => {
        const {
            userTeamMap,
        } = this.state;
        return userTeamMap[userId] != null;
    }

    isTeamSelected = (teamId: number) => {
        const {
            selectedTeams,
        } = this.state;
        return selectedTeams.some((selectedTeamId) => (selectedTeamId === teamId));
    }

    // Method that we can call to update
    //  state with our users array and
    //  also call the component prop onUpdateSelectedUsers
    //  when the parent needs to know there is a change.
    updateSelectedUsers = (selectedUsers: Array<UserProfile>) => {
        const {
            onUpdateSelectedUsers,
            setSelectedUsers,
        } = this.props;

        setSelectedUsers(selectedUsers);

        if (onUpdateSelectedUsers) {
            onUpdateSelectedUsers(selectedUsers);
        }
    }

    deselectUser = (user: any) => {
        const {
            userTeamMap,
        } = this.state;

        const { selectedUsers, } = this.props;

        const newUserTeamMap = { ...userTeamMap, };

        // Remove the user from the selectedUsers array
        const userIndex = selectedUsers.findIndex((selectedUser) => selectedUser.userId === user.userId);
        const newSelectedUsers = selectedUsers.slice();
        if (userIndex !== -1) {
            newSelectedUsers.splice(userIndex, 1);
        }

        // Remove the user id key from the userTeamMap
        delete newUserTeamMap[user.userId];

        this.updateSelectedUsers(newSelectedUsers);
        this.setState({
            userTeamMap: newUserTeamMap,
        });
    }

    selectUser = (user: UserProfile, teamId: number) => {
        const {
            userTeamMap,
        } = this.state;

        const { selectedUsers, } = this.props;

        const isUserSelected = this.isUserSelected(user.userId);

        if (!isUserSelected) {
            this.updateSelectedUsers([...selectedUsers, user]);
            this.setState({
                userTeamMap: {
                    ...userTeamMap,
                    [user.userId]: [teamId],
                },
            });
        } else {
            const userTeamArray = [...userTeamMap[user.userId]];
            this.setState({
                userTeamMap: {
                    ...userTeamMap,
                    [user.userId]: [...userTeamArray, teamId],
                },
            });
        }
    }

    deselectTeam = (team: any) => {
        const {
            selectedTeams,
            userTeamMap,
        } = this.state;

        const { selectedUsers, } = this.props;

        const newUserTeamMap = {
            ...userTeamMap,
        };
        const newSelectedUsers = selectedUsers.slice();
        const newSelectedTeams = selectedTeams.slice();

        // Remove the team from the selectedTeams array
        const teamIndex = selectedTeams.findIndex((selectedTeamId) => selectedTeamId === team.teamId);
        if (teamIndex !== -1) {
            newSelectedTeams.splice(teamIndex, 1);
        }

        // Iterate backwards through selectedUsers array so we can remove elements as wel go
        for (let i = newSelectedUsers.length - 1; i >= 0; i -= 1) {
            const user = newSelectedUsers[i];
            if (newUserTeamMap[user.userId]) {
                const userTeamsArray = (newUserTeamMap[user.userId] || []).slice();
                const userTeamIndex = userTeamsArray.indexOf(team.teamId);
                if (userTeamIndex !== -1) {
                    // If it does exist, splice out the index
                    userTeamsArray.splice(userTeamIndex, 1);
                }
                // Set the map with the updated teams array
                newUserTeamMap[user.userId] = userTeamsArray;

                // Remove if the teams list is empty then remove the user.
                if (userTeamsArray.length === 0) {
                    delete newUserTeamMap[user.userId];
                    newSelectedUsers.splice(i, 1);
                }
            }
        }

        this.updateSelectedUsers(newSelectedUsers);

        this.setState({
            selectedTeams: newSelectedTeams,
            userTeamMap: newUserTeamMap,
        });
    }

    selectTeam = (team: any) => {
        const {
            members: currentMembers,
            selectedUsers,
        } = this.props;

        const {
            selectedTeams,
            userTeamMap,
        } = this.state;

        if (this.isTeamSelected(team.teamId)) { return; }

        // const newUserTeamMap = { ...userTeamMap };
        const newUserTeamMap = {};

        // Iterate through the team members and add the user to the selectedUsers
        const teamMembers = team.teamMembers || [];
        const newSelectedUsers = [];
        for (let i = 0; i < teamMembers.length; i += 1) {
            const teamMember = teamMembers[i];
            const teamMemberId = teamMember.userId;

            // Ignore if the team member is already in the chatroom.
            if (currentMembers[teamMemberId.toString()] == null) {
                // We need to update state and selectedUsers at the same time.
                let userTeams = [];
                if (this.isUserSelected(teamMemberId)) {
                    userTeams = userTeamMap[teamMemberId] || [];
                } else {
                    newSelectedUsers.push(teamMember);
                }

                // Double check that the team isn't already in the array
                if (!userTeams.includes(team.teamId)) {
                    newUserTeamMap[teamMemberId] = [...userTeams, team.teamId];
                }
            }
        }

        this.updateSelectedUsers([...selectedUsers, ...newSelectedUsers]);
        this.setState({
            selectedTeams: [...selectedTeams, team.teamId],
            userTeamMap: {
                ...userTeamMap,
                ...newUserTeamMap,
            },
        });
    }

    filterContacts = (query: string) => {
        const {
            allContacts,
        } = this.state;

        const filteredContacts = query ? allContacts.filter(
            sift({
                $where() {
                    if (this.firstName && this.lastName) {
                        const fullName = `${this.firstName} ${this.lastName}`;
                        return fullName.toUpperCase().includes(query.toUpperCase());
                    }
                    return false;
                },
            })
        ) : allContacts;

        return filteredContacts;
    };

    filterTeams = (query: string) => {
        const {
            teams,
        } = this.props;

        const filteredTeams = query ? teams.filter(sift({
            $where() {
                return this.teamName.toUpperCase().includes(query.toUpperCase());
            },
        })) : teams;

        return filteredTeams;
    };

    debouncedFilterContacts = debounce((query: string) => {
        const filteredContacts = this.filterContacts(query);
        this.setState({
            filteredContacts,
            isFiltering: false,
        });
    }, DEBOUNCE_MILLIS);

    debouncedFilterTeams = debounce((query: string) => {
        const filteredTeams = this.filterTeams(query);
        this.setState({
            filteredTeams,
            isFiltering: false,
        });
    }, DEBOUNCE_MILLIS);

    renderFieldError = (errorMessage?: string) => {
        if (!errorMessage) {
            return null;
        }

        return (
            <div style={CreateChatroomStyles.center}>
                <span
                    style={AuthStyles.formErrorAddNew}
                >
                    {errorMessage}
                </span>
            </div>
        );
    };

    renderTeamDetails = (team: Team) => {
        const {
            isConnected,
        } = this.props;

        const iconClass = isConnected ? 'iconBlue24' : 'iconDisabled24';
        const isTeamSelected = this.isTeamSelected(team.teamId);

        let icon = 'add';
        let dataTip = 'Select team';
        if (isTeamSelected) {
            icon = 'check_circle';
            dataTip = 'Deselect team';
        }

        return (
            <Grid key={team.teamId} container spacing={2}>
                <Grid item xs={10}>
                    <div style={TeamDetailsStyles.teamContents}>
                        <div style={TeamDetailsStyles.teamTitle}>
                            {team.teamName}
                        </div>
                        <div style={TeamDetailsStyles.teamCount}>
                            {team.teamMembers.length !== 1 ? `${team.teamMembers.length} members` : `${team.teamMembers.length} member`}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <button
                        style={isConnected ? UserDetailsStyles.memberButton : UserDetailsStyles.memberButtonDisabled}
                        type="button"
                        disabled={!isConnected}
                        onClick={() => this.onTeamClick(team)}
                        data-tip={dataTip}
                        data-delay-show="500"
                        data-effect="solid"
                    >
                        <FontIcon
                            className={iconClass}
                            value={icon}
                            alt=""
                        />
                    </button>
                </Grid>
            </Grid>
        );
    };

    renderUserDetails = (userId: number) => {
        const {
            contactsMap,
            avatars,
            isConnected,
            createChatroom,
        } = this.props;

        const contact = contactsMap[userId.toString()];
        const avatar = (avatars[userId] || Images.circledDefaultProfile);

        if (!this.userAvailableForSelection(userId)) {
            return ('');
        }

        const membershipStatus = this.isUserSelected(userId) ? 'Pending' : 'NonMember';

        return (
            <UserDetails
                key={userId}
                membershipStatus={membershipStatus}
                startDate={contact.startDate}
                endDate={contact.endDate}
                userProfile={contact.profile}
                avatar={avatar}
                displayButton
                doNotDisturb={false} // we don't have doNotDisturb status from contacts, nor is it relevant to Add People
                displayEmail
                wrapEmail={!createChatroom}
                isConnected={isConnected}
                onMemberClick={this.onMemberClick}
                addManager={() => {}}
                removeManager={() => {}}
                showMemberProfile={() => {}}
            />
        );
    };

    renderDifference() {
        const {
            email,
        } = this.state;

        const firstBullet = email ? 'Emails user a link to download the OmniLife App' : 'Texts user a link to download the OmniLife App';

        return (
            <div style={AddPeopleStyles.differenctFontSize}>
                <div style={AddPeopleStyles.differenceBulletContainer}>
                    <FontIcon className="bullet" value="trip_origin" />
                    <span style={AddPeopleStyles.differenceBulletText}>{firstBullet}</span>
                </div>
                <div style={AddPeopleStyles.differenceBulletContainer}>
                    <FontIcon className="bullet" value="trip_origin" />
                    <span style={AddPeopleStyles.differenceBulletText}>After registering, the user is added to the room</span>
                </div>
            </div>
        );
    }

    renderNewUserPopup() {
        const {
            addSingleUserError,
            inviteNewUserError,
            addPeopleError,
            checkingContact,
        } = this.props;

        const {
            isDialogOpen,
            email,
            phone,
            addByEmail,
            addByPhone,
            emailOrPhone,
            showDifference,
            showInviteOptions,
        } = this.state;

        const addDisabled = ((!email.includes('@')) && !isValidPhoneNumber(phone || '')) || checkingContact;

        const inviteInstructions = email
            ? 'Would you like to email them a download link?'
            : 'Would you like to Text them a download link?';
        const inviteButtonLabel = email ? 'Email download link' : 'Text download link';

        return (
            <Dialog
                active={isDialogOpen}
                type="small"
                onEscKeyDown={this.onClearAndClose}
                onOverlayClick={this.onClearAndClose}
            >
                <div style={CreateChatroomStyles.center}>
                    {emailOrPhone && !showInviteOptions
                        ? (
                            <div>
                                <span style={AddPeopleStyles.titleText}>Add by</span>
                                <div>
                                    <Button variant="contained" style={AddPeopleStyles.addBy} tabIndex="0" onClick={this.onEmailClick}>
                                        Email
                                    </Button>
                                    <Button variant="contained" style={AddPeopleStyles.addBy} tabIndex="0" onClick={this.onPhoneClick}>
                                        Phone
                                    </Button>
                                </div>
                            </div>
                        ) : <div />}
                    {addByEmail && !showInviteOptions
                        ? (
                            <div>
                                <div>
                                    <span style={AddPeopleStyles.titleText}>Add by Email</span>
                                </div>
                                <div style={AddPeopleStyles.inputWrapper}>
                                    <input
                                        style={AddPeopleStyles.textInputEmail}
                                        type="text"
                                        placeholder="Email"
                                        maxLength={255}
                                        value={email}
                                        onChange={(emailInput) => this.setEmail(emailInput)}
                                    />
                                </div>
                                {this.renderFieldError(addSingleUserError || addPeopleError)}
                                <div style={CreateChatroomStyles.center}>
                                    <button
                                        style={AddPeopleStyles.cancelButton}
                                        type="button"
                                        tabIndex="0"
                                        onClick={this.onCancelAdd}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        style={addDisabled ? AddPeopleStyles.confirmButtonDisabled : AddPeopleStyles.confirmButton}
                                        type="button"
                                        disabled={addDisabled}
                                        tabIndex="0"
                                        onClick={this.onInviteExistingUser}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        ) : <div />}
                    {addByPhone && !showInviteOptions
                        ? (
                            <div>
                                <div>
                                    <span style={AddPeopleStyles.titleText}>Add by Phone</span>
                                </div>
                                <div style={AddPeopleStyles.inputWrapper}>
                                    <PhoneInput
                                        international
                                        withCountryCallingCode
                                        value={phone}
                                        onChange={(phoneInput) => this.setPhone(phoneInput)}
                                        style={AddPeopleStyles.textInputPhone}
                                        name="phoneInput"
                                        label="Phone Number"
                                        defaultCountry="US"
                                        inputComponent={PhoneInputTextField}
                                    />
                                </div>
                                {this.renderFieldError(addSingleUserError || addPeopleError)}
                                <div style={CreateChatroomStyles.center}>
                                    <button
                                        style={AddPeopleStyles.cancelButton}
                                        type="button"
                                        tabIndex="0"
                                        onClick={this.onCancelAdd}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        style={addDisabled ? AddPeopleStyles.confirmButtonDisabled : AddPeopleStyles.confirmButton}
                                        type="button"
                                        disabled={addDisabled}
                                        tabIndex="0"
                                        onClick={this.onInviteExistingUser}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        ) : <div />}
                    {showInviteOptions
                        ? (
                            <div>
                                <p
                                    style={AddPeopleStyles.titleText}
                                >
                                    {email || phone}
                                </p>
                                <p
                                    style={AddPeopleStyles.titleText}
                                >
                                    is not an OmniLife user.
                                </p>
                                <p
                                    style={AddPeopleStyles.infoText}
                                >
                                    {inviteInstructions}
                                </p>
                                <div style={AddPeopleStyles.marginFiveTop}>
                                    <Button variant="contained" style={AddPeopleStyles.inviteInverse} tabIndex="0" onClick={this.onInviteNewUser}>
                                        {inviteButtonLabel}
                                    </Button>
                                </div>
                                {this.renderFieldError(inviteNewUserError)}
                                <div>
                                    <Button style={AddPeopleStyles.whatButton} tabIndex="0" onClick={this.onToggleDifference}>
                                        What does this do?
                                        {showDifference ? (
                                            <ExpandLessIcon color="primary" />
                                        ) : (
                                            <ExpandMoreIcon color="primary" />
                                        )}
                                    </Button>
                                    <Fade collapse when={showDifference}>
                                        {this.renderDifference()}
                                    </Fade>
                                </div>
                            </div>
                        ) : <div />}
                </div>
            </Dialog>
        );
    }

    renderSelectedUserArea() {
        const {
            createChatroom,
            chatInputHeight,
        } = this.props;

        return (
            <div
                id="selectedUsersDiv"
                style={createChatroom
                    ? { ...AddPeopleStyles.createChatroomSelectedUsersArea, paddingBottom: `${chatInputHeight || 0}px`, }
                    : { ...AddPeopleStyles.selectedUsersArea, }}
            >
                {this.renderSelectedUserAvatars()}
                {this.renderUpdateButton()}
            </div>
        );
    }

    renderUpdateButton() {
        const {
            createChatroom,
            isConnected,
            selectedUsers,
        } = this.props;

        const selectedCount = selectedUsers.length;
        if (selectedCount === 0) {
            return (
                <div style={AddPeopleStyles.informationRightWrapper}>
                    <span style={AddPeopleStyles.informationRight}>
                        No selections
                    </span>
                </div>
            );
        }

        const personText = (selectedCount === 1) ? ' person' : ' people';

        if (!createChatroom) {
            return (
                <div style={AddPeopleStyles.addNumPeopleContainer}>
                    <button
                        style={isConnected ? AddPeopleStyles.addNumPeople : AddPeopleStyles.addNumPeopleDisabled}
                        type="button"
                        tabIndex="0"
                        disabled={!isConnected}
                        onClick={this.onAddUsers}
                        data-tip="Select"
                        data-delay-show="500"
                        data-effect="solid"
                    >
                        <FontIcon
                            value="add"
                            alt="add people"
                        />
                        <span>
                            {` Add ${selectedCount}${personText}`}
                        </span>
                    </button>
                </div>

            );
        }
        return (
            <div style={AddPeopleStyles.rightButtonDisabled}>
                <span>
                    {selectedCount + personText}
                </span>
            </div>
        );
    }

    renderSelectedUserAvatar = (user: UserProfile) => {
        const {
            avatars,
        } = this.props;

        const {
            userId,
        } = user;

        // defensive code against missing userid. Should not happen but has in guest processing
        if (!userId) return ('');

        const avatar = avatars[userId] || Images.circledDefaultProfile;
        return (
            <span
                key={userId}
                style={ChatroomDetailsStyles.profileImageSelectedWrapper}
            >
                <ReactTooltip />
                <img
                    style={ChatroomDetailsStyles.profileImage}
                    src={avatar}
                    alt=""
                    data-tip={selectProfileName(user)}
                    data-delay-show="100"
                    data-effect="solid"
                />
            </span>
        );
    };

    renderSelectedUserAvatars = () => {
        const {
            selectedUsers,
        } = this.props;

        // Only show the first 10 since it can get out of hand quickly
        const maxDisplaySize = 10;
        const displayedUsers = selectedUsers.slice(0, maxDisplaySize);

        return (
            <div>
                { displayedUsers.map((selectedUser) => this.renderSelectedUserAvatar(selectedUser)) }
                {(selectedUsers.length > maxDisplaySize)
                    ? (
                        <div
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                            data-tip={selectedUsers.map((selectedUser) => selectProfileName(selectedUser)).join(', ')}
                        >
                            <FontIcon
                                className="iconBlue24"
                                value="more_horiz"
                                alt=""
                            />
                        </div>
                    )
                    : null}
            </div>
        );
    };

    renderTeamsList() {
        const {
            peopleFilter,
            teamsLoading,
            teams,
            createChatroom,
        } = this.props;

        const {
            filteredTeams: preFilteredTeams,
        } = this.state;

        const filteredTeams = (!peopleFilter) ? teams : preFilteredTeams;
        if (teamsLoading) {
            return (
                <div style={ChatMessageStyles.loadingIndicator}>
                    <LoadingIndicator />
                </div>
            );
        }
        if (!filteredTeams || filteredTeams.length === 0) {
            return (
                <div style={{ textAlign: 'center', }}>
                    No teams to display
                </div>
            );
        }

        return (
            <div style={createChatroom ? { ...AddPeopleStyles.userArea, ...AddPeopleStyles.createChatroomUserAreaFlex, } : AddPeopleStyles.userArea}>
                {filteredTeams.map((team) => this.renderTeamDetails(team))}
            </div>
        );
    }

    renderPeopleList() {
        const {
            peopleFilter,
            contactsOrder,
            contactsAreLoaded,
            createChatroom,
        } = this.props;

        const {
            allContacts,
            filteredContacts,
            isFiltering,
        } = this.state;

        if (contactsAreLoaded && !isFiltering) {
            let areUsersAvailable = false;

            for (let i = 0; i < contactsOrder.length; i += 1) {
                if (this.userAvailableForSelection(contactsOrder[i])) {
                    areUsersAvailable = true;
                    break;
                }
            }

            const items = (!peopleFilter) ? allContacts : filteredContacts;
            if (areUsersAvailable && items.length > 0) {
                return (
                    <div style={createChatroom
                        ? { ...AddPeopleStyles.userArea, ...AddPeopleStyles.createChatroomUserAreaFlex, }
                        : AddPeopleStyles.userArea}
                    >
                        {items.map((item) => this.renderUserDetails(item.userId))}
                    </div>
                );
            }

            // No users found after filtering...
            if (areUsersAvailable) {
                return (
                    <div style={{ textAlign: 'center', }}>
                        No users found
                    </div>
                );
            }

            // No users available to select
            return (
                <div style={{ textAlign: 'center', }}>
                    No users available
                </div>
            );
        }

        // Contacts not loaded yet or currently filtering contacts
        return (
            <div style={ChatMessageStyles.loadingIndicator}>
                <LoadingIndicator />
            </div>
        );
    }

    render() {
        const {
            peopleFilter,
            createChatroom,
            isConnected,
            onHideAddPeople,
            backButtonLabel,
            hideTitle,
        } = this.props;

        const {
            currentTab,
        } = this.state;

        return (
            <div>
                <div style={!createChatroom ? { ...AddPeopleStyles.header, ...AddPeopleStyles.headerBorders, } : { ...AddPeopleStyles.header, }}>
                    <ReactTooltip />
                    <div style={!createChatroom ? { ...AddPeopleStyles.headerTextContainer, } : { ...AddPeopleStyles.headerTextContainerShortWidth, }}>
                        {!createChatroom
                            ? (
                                <>
                                    <div style={{ marginTop: 10, float: 'left', }}>
                                        <BackButton
                                            label={backButtonLabel || 'Back'}
                                            goBack={onHideAddPeople}
                                        />
                                    </div>
                                    {!hideTitle
                                        ? (
                                            <div style={ChatroomDetailsStyles.headerPaddingSecondary}>
                                                <span style={ChatroomDetailsStyles.centeredTitleLarge}>
                                                    Add People
                                                </span>
                                                <button
                                                    style={isConnected ? AddPeopleStyles.addExternalButton : AddPeopleStyles.addExternalButtonDisabled}
                                                    type="button"
                                                    tabIndex="0"
                                                    disabled={!isConnected}
                                                    onClick={this.dialogOpen}
                                                    data-tip="Add people outside of current list"
                                                    data-delay-show="500"
                                                    data-effect="solid"
                                                >
                                                    <FontIcon
                                                        className={isConnected ? 'iconBlue24' : 'iconDisabled24'}
                                                        value="person_add"
                                                        alt=""
                                                    />
                                                </button>
                                            </div>
                                        ) : null}
                                </>
                            )
                            : null}
                        <TabSelector
                            labels={['Users', 'Teams']}
                            selected={[(currentTab === USERS_TAB), (currentTab === TEAMS_TAB)]}
                            width={100}
                            borderRadius={false}
                            clickEvents={[() => this.onChangeTab(USERS_TAB), () => this.onChangeTab(TEAMS_TAB)]}
                        />
                        <div style={!createChatroom ? AddPeopleStyles.fixedSearchAddPeople : null}>
                            <Search
                                placeholder="Search people..."
                                filterContent={this.onSearchTextChange}
                                value={peopleFilter}
                            />
                        </div>
                    </div>
                </div>
                {this.renderNewUserPopup()}
                <div style={createChatroom ? { ...AddPeopleStyles.createChatroomPeopleListContainer, } : null}>
                    {currentTab === USERS_TAB ? this.renderPeopleList() : null}
                    {currentTab === TEAMS_TAB ? this.renderTeamsList() : null}
                    {this.renderSelectedUserArea()}
                </div>
            </div>
        );
    }
}

// $FlowFixMe
AddPeople.defaultProps = {
    chatInputHeight: 0,
    onUpdateSelectedUsers: undefined,
    backButtonLabel: 'Back',
    hideTitle: false,
    addPeopleError: undefined,
    inviteExistingUser: undefined,
    inviteNewUser: undefined,
};

const mapStateToProps = (state) => ({
    selectedUsers: state.addPeople.selectedUsers,
    addPeopleError: state.addPeople.error,
    checkingContact: state.loading.checkContact,
});

export default connect(mapStateToProps, {
    setSelectedUsers: _setSelectedUsers,
    resetAddPeople: _resetAddPeople,
    addPeopleByEmail: _addPeopleByEmail,
    addPeopleByPhone: _addPeopleByPhone,
    setAddPeopleError: _setAddPeopleError,
    addPeopleInvite: _addPeopleInvite,
})(AddPeople);
