// @flow
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Styles/CheckboxStyles';

type Props = {
    className: string,
    disabled: boolean,
    label: string,
    iconName: string,

    onClick: () => *,
};

class Checkbox extends PureComponent<Props> {
    render() {
        const {
            disabled,
            label,
            className,
            iconName,
            onClick,
        } = this.props;

        return (
            <div
                style={disabled ? styles.checkboxWrapperDisabled : styles.checkboxWrapper}
                role="button"
                tabIndex={0}
                onClick={disabled ? null : onClick}
                onKeyDown={disabled ? null : onClick}
            >
                <FontAwesomeIcon
                    className={className}
                    icon={iconName}
                    size="lg"
                />
                <span
                    style={styles.label}
                >
                    {label}
                </span>
            </div>
        );
    }
}

export default Checkbox;
