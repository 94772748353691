// @flow
import React from 'react';
import {
    Autocomplete,
} from '@mui/material';
import { TextField } from '@material-ui/core';
import type { Tag } from '../Utils/types';
import CaseTagDrawer from './CaseTagDrawer';
import { groupTagsByCategory } from '../Utils/tags';

// NOTE: eslint disables due to https://github.com/facebook/flow/issues/6644

type Props = {
    availableTags: Array<Tag>,
    selectedTags: Array<Tag>,
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean,
    onChange: (event: any, newTags: Array<Tag>) => any,
};

const TagsAutocomplete = ({
    availableTags, selectedTags, disabled = false, onChange,
}: Props) => {
    const groupedTags = groupTagsByCategory(availableTags);

    return (
        <Autocomplete
            multiple
            disabled={disabled}
            options={groupedTags}
            value={selectedTags}
            disableCloseOnSelect
            isOptionEqualToValue={(option: Tag, value: Tag) => option.tagId === value.tagId}
            getOptionLabel={(option) => option.label}
            groupBy={(option) => option.category}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Tags"
                    placeholder="Tags"
                />
            )}
            onChange={onChange}
            renderTags={(values: Tag[]) => (
                <CaseTagDrawer
                    highRisk={false}
                    tags={values}
                    entity="case"
                />
            )}
        />
    );
};

export default TagsAutocomplete;
