import {
    all, fork, takeLatest, take, takeEvery,
} from 'redux-saga/effects';
import { rootAuthSaga, rootPreAuthSaga, rootAuthenticatedSaga as rootCoreAuthenticatedSaga } from 'txp-core';

import storeDeviceToken from './DeviceSaga';
import {
    getApplicationDataSaga,
    clearChatroomNotificationsSaga,
    openChatroomSaga,
    loadChatMembersSaga,
    loadChatroomsSaga,
    searchChatSaga,
    loadChatroomMessagesManager,
    handleAddedToChatroomSaga,
    handleRemovedFromChatroomSaga,
    addChatMessageWatcher,
    markMessagesReadSaga,
    acknowledgeMessageSaga,
    loadMessageStatusesSaga,
    saveChatSaga,
    uploadXMLSaga,
    startTypingSaga,
    stopTypingSaga,
    pinChatroomSaga,
    unpinChatroomSaga,
    loadChatFilesSaga,
    downloadAvatarSaga,
    syncProfileInChatroomsSaga,
    redactMessageSaga,
    unredactMessageSaga,
    deleteFileMessageSaga,
} from './ChatSaga';
import {
    getContactsSaga,
    addUsersToChatroom,
    addSingleUserToChatroom,
    removeUserFromChatroom,
    deleteChatroom,
    inviteNewUser,
    toggleChatroomMute,
    muteCase,
    setNotificationSound,
    resendNotification,
} from './ChatroomSaga';
import {
    triggerAskAlanSaga,
    loadOffersSaga,
    loadOrganizationsSaga,
    loadSurgeonsSaga,
    getOfferSaga,
    createOfferSaga,
} from './AskAlanSaga';
import {
    loadCasesSaga,
    getDonorSaga,
    updateDonorStatusSaga,
    createDonorSaga,
    updateDonorSaga,
    loadDonorFilesSaga,
    uploadDonorFileSaga,
    deleteDonorFileSaga,
    loadOrgMembersSaga,
    getCaseAdminUsersSaga,
    loadTasksSaga,
    updateTaskStatusSaga,
    getDonorTaskSaga,
    getDonorTaskDataSaga,
    updateTaskNoteSaga,
    updateDonorTaskDataSaga,
    updateTaskDueDateSaga,
    getDonorFollowerGroupsSaga,
    getFollowerGroupSaga,
    joinFollowerGroupSaga,
    createFollowerGroupSaga,
    createMultipleFollowerGroupsSaga,
    updateFollowerGroupSaga,
    updateFollowerGroupTasksSaga,
    deleteFollowerGroupSaga,
    updateFollowerGroupMembersSaga,
    updateFollowerGroupPermissionsSaga,
    removeUserFromFollowerGroupSaga,
    getPdfFieldsSaga,
    saveCaseNoteSaga,
    loadCasePreferencesSaga,
    checkContactIsTxpUserSaga,
    inviteNewUserSaga,
    inviteUserSaga,
} from './DonorSaga';
import {
    addOrgMembersToChatroomSaga,
    getOrganizationMembersSaga,
    uploadCDSCandidateSaga,
    uploadCDSOfferSaga,
    uploadROOSaga,
} from './OfferSaga';
import logEventSaga from './AnalyticsSaga';
import reportErrorSaga from './ErrorReportingSaga';
import getHelpVideosSaga from './HelpSaga';
import {
    getPermissionsSaga,
    getResourcePermissionsSaga,
    addPermissionSaga,
    addPermissionToAuthorizedEntitiesSaga,
    removePermissionSaga,
    updatePermissionSaga,
} from './PermissionSaga';
import { loadAuthSaga, persistAuthSaga, deleteAuthSaga } from './AuthSaga';
import {
    getTeamsSaga,
    createTeamSaga,
    deleteTeamSaga,
    updateTeamSaga,
} from './TeamSaga';
import socketManager from './Socket';
import { getNotifiableEventGroupSaga } from './NotificationSaga';
import { getAllUsersSaga } from './NetworkSaga';

function* startRootAuthSaga() {
    // Ensure any persisted JWT has been loaded into state in order to make requests
    // with credentials to avoid having to login again after page reload
    yield loadAuthSaga();

    yield all([
        takeLatest('Auth/SYNC_PROFILE_IN_CHATROOMS', syncProfileInChatroomsSaga),
        takeLatest('Auth/LOGIN_SUCCESS', persistAuthSaga),
        takeLatest('Auth/REFRESH_SUCCESS', persistAuthSaga),
        takeLatest('Auth/LOGOUT_SUCCESS', deleteAuthSaga),
        takeLatest('Auth/TOKEN_EXPIRED', deleteAuthSaga),
        takeLatest('Auth/FORCE_RELOGIN', deleteAuthSaga),

        takeLatest('Application/STORE_DEVICE_TOKEN', storeDeviceToken),
        takeLatest('Application/GET_APPLICATION_DATA', getApplicationDataSaga),
        takeEvery('Application/REPORT_ERROR', reportErrorSaga),

        takeLatest('ChatList/LOAD', loadChatroomsSaga),
        takeEvery('ChatList/LOAD_MEMBER_AVATARS', downloadAvatarSaga),
        takeLatest('ChatList/OPEN_CHATROOM', openChatroomSaga),
        takeLatest('ChatList/SEARCH', searchChatSaga),
        takeLatest('ChatList/LOAD_MEMBERS', loadChatMembersSaga),
        takeLatest('ChatEdit/SAVE', saveChatSaga),

        takeLatest('Chatroom/TOGGLE_MUTE', toggleChatroomMute),
        takeLatest('Chatroom/MUTE_CASE', muteCase),
        takeLatest('Chatroom/SET_NOTIFICATION_SOUND', setNotificationSound),
        takeLatest('ChatList/LOAD_FILES', loadChatFilesSaga),
        takeLatest('ChatList/CLEAR_NOTIFICATIONS', clearChatroomNotificationsSaga),
        takeEvery('ChatList/MARK_MESSAGES_READ', markMessagesReadSaga),
        takeLatest('ChatList/ACK_MESSAGE', acknowledgeMessageSaga),
        takeEvery('ChatList/START_TYPING', startTypingSaga),
        takeEvery('ChatList/STOP_TYPING', stopTypingSaga),
        takeLatest('ChatList/PIN_CHATROOM', pinChatroomSaga),
        takeLatest('ChatList/UNPIN_CHATROOM', unpinChatroomSaga),
        takeLatest('ChatList/REDACT_MESSAGE', redactMessageSaga),
        takeLatest('ChatList/DELETE_FILE_MESSAGE', deleteFileMessageSaga),

        takeLatest('ChatList/UNREDACT_MESSAGE', unredactMessageSaga),

        takeLatest('ChatList/LOAD_MSG_STATUSES', loadMessageStatusesSaga),

        takeLatest('ChatMessage/UPLOAD_XML', uploadXMLSaga),

        fork(loadChatroomMessagesManager),
        fork(socketManager),
        fork(addChatMessageWatcher),

        // Reload all chatrooms if we receive added/removed events
        takeEvery('ChatList/ADDED_TO_CHATROOM', handleAddedToChatroomSaga),
        takeLatest('ChatList/REMOVED_FROM_CHATROOM', handleRemovedFromChatroomSaga),

        takeLatest('Chatroom/GET_CONTACTS', getContactsSaga),
        takeEvery('Chatroom/ADD_USERS_TO_CHATROOM', addUsersToChatroom),
        takeEvery('Chatroom/ADD_SINGLE_USER_TO_CHATROOM', addSingleUserToChatroom),
        takeEvery('Chatroom/REMOVE_USER_FROM_CHATROOM', removeUserFromChatroom),
        takeEvery('Chatroom/DELETE_CHATROOM', deleteChatroom),

        takeLatest('Help/GET_HELP_VIDEOS', getHelpVideosSaga),

        takeLatest('Chatroom/RESEND_NOTIFICATION', resendNotification),

        takeEvery('Chatroom/INVITE_NEW_USER', inviteNewUser),

        // Invite Snapshot
        takeLatest('ChatList/ASK_ALAN', triggerAskAlanSaga),

        takeLatest('ChatEdit/CREATE_OFFER', createOfferSaga),
        takeLatest('ChatEdit/GET_OFFERS', loadOffersSaga),
        takeLatest('ChatEdit/GET_ORGANIZATIONS', loadOrganizationsSaga),
        takeLatest('ChatEdit/GET_SURGEONS', loadSurgeonsSaga),
        takeLatest('ChatEdit/GET_OFFER', getOfferSaga),

        // Donor actions
        takeLatest('Donor/LOAD_CASES', loadCasesSaga),
        takeLatest('Donor/GET_DONOR', getDonorSaga),
        takeLatest('Donor/UPDATE_DONOR_STATUS', updateDonorStatusSaga),
        takeLatest('Donor/CREATE', createDonorSaga),
        takeLatest('Donor/UPDATE', updateDonorSaga),
        takeLatest('Donor/LOAD_FILES', loadDonorFilesSaga),
        takeLatest('Donor/UPLOAD_DONOR_FILE', uploadDonorFileSaga),
        takeLatest('Donor/DELETE_DONOR_FILE', deleteDonorFileSaga),
        takeLatest('Donor/LOAD_TASKS', loadTasksSaga),
        takeLatest('Donor/GET_DONOR_TASK', getDonorTaskSaga),
        takeLatest('Donor/GET_DONOR_TASK_DATA', getDonorTaskDataSaga),
        takeEvery('Donor/UPDATE_TASK_STATUS', updateTaskStatusSaga),
        takeLatest('Donor/GET_ORG_MEMBERS', loadOrgMembersSaga),
        takeLatest('Donor/GET_CASE_ADMIN_USERS', getCaseAdminUsersSaga),
        takeLatest('Donor/UPDATE_TASK_NOTE', updateTaskNoteSaga),
        takeLatest('Donor/UPDATE_TASK_DUE_DATE', updateTaskDueDateSaga),
        takeLatest('Form/UPDATE_DONOR_FORM', updateDonorTaskDataSaga),
        takeLatest('FollowerEdit/CREATE_FOLLOWER_GROUP', createFollowerGroupSaga),
        takeLatest('FollowerEdit/CREATE_MULTIPLE_FOLLOWER_GROUPS', createMultipleFollowerGroupsSaga),
        takeLatest('FollowerEdit/UPDATE_FOLLOWER_GROUP', updateFollowerGroupSaga),
        takeLatest('FollowerEdit/REMOVE_USER_FROM_FOLLOWERS', removeUserFromFollowerGroupSaga),
        takeEvery('FollowerEdit/INVITE_NEW_USER', inviteNewUserSaga),
        takeLatest('AddPeople/ADD_PEOPLE_BY_EMAIL', checkContactIsTxpUserSaga),
        takeLatest('AddPeople/ADD_PEOPLE_BY_PHONE', checkContactIsTxpUserSaga),
        takeLatest('AddPeople/INVITE_NEW_USER', inviteUserSaga),
        takeLatest('Donor/UPDATE_FOLLOWER_GROUP_TASKS', updateFollowerGroupTasksSaga),
        takeLatest('Donor/DELETE_FOLLOWER_GROUP', deleteFollowerGroupSaga),
        takeLatest('Donor/UPDATE_FOLLOWER_GROUP_MEMBERS', updateFollowerGroupMembersSaga),
        takeLatest('Donor/UPDATE_FOLLOWER_GROUP_PERMISSIONS', updateFollowerGroupPermissionsSaga),
        takeLatest('Donor/GET_DONOR_FOLLOWER_GROUPS', getDonorFollowerGroupsSaga),
        takeEvery('Donor/GET_FOLLOWER_GROUP', getFollowerGroupSaga),
        takeLatest('FollowerEdit/JOIN_FOLLOWER_GROUP', joinFollowerGroupSaga),
        takeLatest('Donor/LOAD_PDF_FIELDS', getPdfFieldsSaga),
        takeLatest('Donor/SAVE_CASE_NOTE', saveCaseNoteSaga),
        takeLatest('Donor/LOAD_CASE_PREFERENCES', loadCasePreferencesSaga),

        takeEvery('Notification/GET_NOTIFIABLE_EVENT_GROUP', getNotifiableEventGroupSaga),

        takeLatest('Offer/UPLOAD_OFFER_FILE', uploadCDSOfferSaga),
        takeLatest('Offer/SET_CANDIDATE_FILE', uploadCDSCandidateSaga),
        takeLatest('Offer/GET_ORG_MEMBERS', getOrganizationMembersSaga),
        takeLatest('Offer/ADD_ORG_MEMBERS', addOrgMembersToChatroomSaga),
        takeLatest('Offer/SET_ROO_FILE', uploadROOSaga),

        takeEvery('Permission/GET_PERMISSIONS', getPermissionsSaga),
        takeEvery('Permission/GET_RESOURCE_PERMISSIONS', getResourcePermissionsSaga),
        takeEvery('Permission/ADD', addPermissionSaga),
        takeEvery('Permission/ADD_TO_AUTHORIZED_ENTITIES', addPermissionToAuthorizedEntitiesSaga),
        takeEvery('Permission/REMOVE', removePermissionSaga),
        takeEvery('Permission/UPDATE', updatePermissionSaga),

        takeLatest('Team/GET_TEAMS', getTeamsSaga),
        takeLatest('Team/CREATE_TEAM', createTeamSaga),
        takeLatest('Team/DELETE_TEAM', deleteTeamSaga),
        takeLatest('Team/UPDATE', updateTeamSaga),

        takeEvery('Network/GET_ALL_USERS', getAllUsersSaga),

        takeEvery('Application/LOG_EVENT', logEventSaga)
    ]);
}

export function* rootAuthenticatedSaga() {
    while (true) {
        const task = yield fork(startRootAuthSaga);

        // Previously we waited for the 'Auth/LOGOUT_SUCCESS', 'Auth/TOKEN_EXPIRED',
        // and 'Auth/FORCE_RELOGIN' actions. However, since we've changed the mechanism
        // for persisting credentials (a JWT), we listen for those events in the root saga
        // so that we can remove the credentials from storage and then we reload the page
        // to finish the logout process
        yield take(['App/RELOAD']);

        // and cancel the task
        if (task.isRunning()) {
            task.cancel();
        }

        // Now we're logged out (or even if we tried and failed), reload the dom to clear cached images
        window.location.reload();
    }
}

const allSagas = [rootAuthenticatedSaga, rootAuthSaga, rootPreAuthSaga, rootCoreAuthenticatedSaga];
export default allSagas;
