// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from 'react-loading-indicator';

import ChatMessageStyles from './Styles/ChatMessageStyles';
import ProfileStyles from './Styles/ProfileStyles';
import type { RemoteMediaUri } from '../Redux/ChatMediaActions';
import Images from '../Themes/Images';
import downloadFile from '../Utils/downloadFile';
import type {
    MediaType,
    MediaDisplayType,
} from '../Utils/types';
import {
    ImageMessageStrings,
    VideoMessageStrings,
    ApplicationMessageStrings,
} from '../Utils/types';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import { setLocalPath as _setLocalPath } from '../Redux/ChatListActions';
import { setDonorLocalPath as _setDonorLocalPath } from '../Redux/DonorActions';

type Props = {
    source: ?(RemoteMediaUri | string),
    type: MediaType,
    display: MediaDisplayType,
    isLocalSource: boolean,
    textContent: ?string,
    chatId: ?number,
    messageId: ?number,
    isVisible: boolean,
    preLoad: boolean,
    donor: boolean,
    maxWidth?: number,

    onMediaClick: () => *,
    setLocalPath: (chatId: number, messageId: number, localPath: string) => *,
    setDonorLocalPath: (id: number, messageId: number, localPath: string) => *,
};

type State = {
    failed: boolean,
    loaded: boolean,
    loading: boolean,
};

/**
 * Downloads media files for display in chatrooms
 */
class DownloadingMedia extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            failed: false,
            loaded: false,
            loading: false,
        };
    }

    async componentDidMount() {
        const {
            source,
            isLocalSource,
        } = this.props;

        if (source === undefined || source === null) {
            this.setState({
                failed: true,
            });
            return;
        }

        // Following weird check to keep flow happy. If source as undefined we don't get this far
        if (!isLocalSource && source) {
            await this.maybeDownloadFile(source);
        }
    }

    async componentDidUpdate() {
        const {
            source,
            isVisible,
            preLoad,
            isLocalSource,
        } = this.props;
        const {
            failed,
            loading,
        } = this.state;

        if (source && (isVisible || preLoad) && (!isLocalSource) && (!failed) && (!loading)) {
            await this.maybeDownloadFile(source);
        }
    }

    componentWillUnmount() {
        this.isUnmounting = true;
    }

    onMediaLoad = () => {
        this.setState({
            loaded: true,
        });
    };

    isUnmounting: boolean = false;

    async maybeDownloadFile(source: RemoteMediaUri | string) {
        if (this.isUnmounting) {
            return;
        }

        // If not visible or a pre-load then stop now.
        const { isVisible: isVisibleInitially, isLocalSource, preLoad, } = this.props;
        if (!(isVisibleInitially || preLoad)) return;

        // If it is visible, Wait for 200ms for the ChatHistory to finish rendering - this may start out true and turn false
        setTimeout(() => {
            const { isVisible: isVisibleNow, } = this.props;
            if (!(isVisibleNow || preLoad)) {
                return;
            }
            if (source && (!isLocalSource)) {
                this.actuallyDownloadFile(source);
            }
        }, 200);
    }

    async actuallyDownloadFile(source: RemoteMediaUri | string) {
        const {
            chatId,
            messageId,
            setLocalPath,
            donor,
            setDonorLocalPath,
        } = this.props;
        const { loading, } = this.state;

        // If this file is already loading then skip. Can happen because of many re-renders
        if (loading) {
            return;
        }

        const { uri, headers, } = typeof source === 'string' ? { uri: source, headers: {}, } : source;

        this.setState({ loading: true, });

        const { result, error, } = await downloadFile({
            fromUrl: uri,
            headers,
            useLock: true,
        });

        // Check that the download type is as expected as an invalid blob will not be rendered
        let validDownloadType = false;
        if (!error) {
            const downloadType = result.type;
            if (ImageMessageStrings.includes(downloadType)
            || VideoMessageStrings.includes(downloadType)
            || ApplicationMessageStrings.includes(downloadType)) {
                validDownloadType = true;
            }
        }

        if (!this.isUnmounting) {
            if (!error && validDownloadType) {
                if (donor) {
                    setDonorLocalPath(chatId || 0, messageId || 0, result.path);
                }
                if (chatId && messageId) {
                    setLocalPath(chatId, messageId, result.path);
                }
            } else {
                this.setState({
                    failed: true,
                });
            }
        }
    }

    render() {
        const {
            type,
            display,
            onMediaClick,
            textContent,
            source,
            isLocalSource,
            maxWidth,
        } = this.props;

        const {
            failed,
            loaded,
        } = this.state;

        const isImage = (type === 'image');
        const isVideo = (type === 'video');
        const isApplication = (type === 'application');

        const profilePicture = display === 'profilePicture';
        const chatItem = display === 'chatItem';
        const fileList = display === 'fileList';
        const fileListFullSize = display === 'fileListFullSize';

        const isAvatar = (type === 'avatar');
        let blobUri = null;
        let style = profilePicture ? null : { display: 'inline', };
        let imgStyle = ChatMessageStyles.chatMessageMedia;

        if ((failed || !loaded) && !profilePicture) {
            if (textContent) {
                style = ChatMessageStyles.failedMediaWrapper;
            } else if (fileListFullSize) {
                style = ChatroomDetailsStyles.loadingFullSizeWrapper;
            } else {
                style = ChatMessageStyles.loadingMediaWrapper;
            }
        }
        if (isLocalSource && source) {
            style = textContent ? { display: 'flex', } : { display: 'inline', };
            blobUri = source;
        }

        if (chatItem) {
            imgStyle = loaded ? ChatMessageStyles.chatMessageMedia : ChatMessageStyles.chatMessageHiddenMedia;
        } else if (fileList) {
            imgStyle = loaded ? ChatroomDetailsStyles.fileImage : {};
        } else if (fileListFullSize) {
            if (isApplication) {
                imgStyle = loaded ? ChatroomDetailsStyles.PDFFullSize : ChatroomDetailsStyles.hiddenImage;
            } else {
                imgStyle = loaded ? ChatroomDetailsStyles.fileImageFullSize : ChatroomDetailsStyles.hiddenImage;
            }
        } else if (profilePicture) {
            imgStyle = loaded ? ProfileStyles.profileImage : ProfileStyles.profileImageHidden;
        }

        const loadingIndicator = !loaded ? (
            <LoadingIndicator />
        ) : null;

        if (fileList && !loaded) {
            return loadingIndicator;
        }

        return (
            // Non-interactive elements with onClick require keyboard listener, which is not needed here
            <div style={style} onClick={onMediaClick}> {/* eslint-disable-line */}
                {failed ? (
                    <img
                        style={isAvatar ? ChatMessageStyles.profileImage : ChatMessageStyles.chatMessageMedia}
                        onLoad={this.onMediaLoad}
                        src={isAvatar ? Images.defaultProfile : Images.brokenMedia}
                        alt=""
                    />
                ) : isImage ? (
                    <span>
                        {loadingIndicator}
                        <img
                            style={{ ...imgStyle, ...{ maxWidth: maxWidth || 'none', }, }}
                            onLoad={this.onMediaLoad}
                            src={blobUri}
                            alt=""
                        />
                    </span>
                ) : isVideo ? (
                    <span>
                        {loadingIndicator}
                        <video // eslint-disable-line
                            style={{ ...imgStyle, ...{ maxWidth: maxWidth || 'none', }, }}
                            onLoadStart={this.onMediaLoad}
                            src={blobUri}
                            controls
                        />
                    </span>
                ) : isApplication ? (
                    <span>
                        {loadingIndicator}
                        <div style={ChatMessageStyles.chatMessageApplicationClick} />
                        <iframe
                            style={fileListFullSize ? imgStyle : { ...imgStyle, ...ChatMessageStyles.chatMessageApplicationMedia, }}
                            onLoad={this.onMediaLoad}
                            src={blobUri}
                            title="downloading_media_iframe"
                        />
                    </span>
                ) : null}
            </div>
        );
    }
}

// $FlowFixMe
DownloadingMedia.defaultProps = {
    maxWidth: null,
};

export default connect(null, {
    setLocalPath: _setLocalPath,
    setDonorLocalPath: _setDonorLocalPath,
})(DownloadingMedia);
