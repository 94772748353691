// @flow
import Colors from '../../Themes/Colors';

// Note: Pixel values are scaled based on dimension resize constants
const AuthStyles = {
    formLabel: {
        color: Colors.formLabel,
        fontSize: 17,
        lineHeight: 2,
    },
    formLabelStacked: {
        color: Colors.formLabel,
        fontSize: 17,
    },
    formError: {
        color: Colors.formError,
        fontSize: 11,
        marginTop: 2,
    },
    formErrorAddNew: {
        color: Colors.formError,
        fontSize: 12,
    },
    formErrorChatroomDetails: {
        color: Colors.pendingYellow,
        fontSize: 12,
    },
    version: {
        color: Colors.lightGray,
    },
    instructions: {
        color: Colors.formLabel,
        fontSize: 14,
        display: 'block',
        textAlign: 'left',
    },
    instructionsInline: {
        color: Colors.formLabel,
        fontSize: 14,
        textAlign: 'left',
    },
    instructionsCenter: {
        color: Colors.formLabel,
        fontSize: 14,
        display: 'block',
        textAlign: 'center',
    },
    passwordReqContainer: {
        textAlign: 'left',
        marginLeft: 10,
    },
    passwordReqButton: {
        color: Colors.blue,
        backgroundColor: Colors.white,
        textTransform: 'none',
        padding: 0,
    },
    showPasswordButton: {
        color: Colors.blue,
        backgroundColor: Colors.white,
        textTransform: 'none',
        padding: 0,
    },
    navLink: {
        fontSize: 14,
        textDecoration: 'none',
        color: Colors.blue,
    },
    phoneContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px 0',
    },
    plus1: {
        fontSize: 14,
        color: Colors.formLabel,
        display: 'flex',
        alignItems: 'center',
        marginRight: 5,
    },
    backButton: {
        display: 'inline-block',
        float: 'left',
    },
};

export default AuthStyles;
