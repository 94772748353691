// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FontIcon from 'react-toolbox/lib/font_icon';
import LoadingIndicator from 'react-loading-indicator';
import ReactTooltip from 'react-tooltip';

import BackButton from './BackButton';
import SidebarStyles from './Styles/SidebarStyles';
import {
    getHelpVideos as _getHelpVideos,
} from '../Redux/HelpActions';
import truncate from '../Utils/text';

type Props = {
    videos: Array<any>,
    loadingVideos: boolean,

    closeHelp: () => *,
    getHelpVideos: () => *,
};

type State = {
    showHelpVideos: boolean,
};

class Help extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            showHelpVideos: false,
        };
    }

    onClose = () => {
        const {
            closeHelp,
        } = this.props;

        closeHelp();
    };

    onBack = () => {
        this.setState({
            showHelpVideos: false,
        });
    };

    onGetHelpVideos = () => {
        const {
            getHelpVideos,
        } = this.props;

        getHelpVideos();

        this.setState({
            showHelpVideos: true,
        });
    };

    onAsk = () => {
        const email = 'help@omnilife.health';
        window.open(`mailto:${email}`, '_self');
    };

    onHelpCenter = () => {
        window.open('https://help.omnilife.health/en/', '_blank');
    };

    onVideoClick = (video: any) => {
        window.open(`https://www.youtube.com/watch?v=${video.id.videoId}`, '_blank');
    };

    renderHelpVideos() {
        const {
            videos,
            loadingVideos,
        } = this.props;

        return (
            <div style={SidebarStyles.mainContainer}>
                <BackButton
                    label="Back"
                    goBack={this.onBack}
                />
                <div>
                    {loadingVideos
                        ? <LoadingIndicator style={SidebarStyles.loadingIndicator} />
                        : (
                            <div>
                                {videos.length > 0
                                    ? (
                                        <div>
                                            <span style={SidebarStyles.titleText}>Help Videos</span>
                                            <div
                                                style={SidebarStyles.borderTop}
                                            >
                                                {videos.map((video: any) => (
                                                    <div
                                                        key={videos.indexOf(video)}
                                                    >
                                                        <div // eslint-disable-line
                                                            style={SidebarStyles.buttonContainer}
                                                            role="button"
                                                            onClick={() => this.onVideoClick(video)}
                                                        >
                                                            <span
                                                                style={SidebarStyles.lineButton}
                                                            >
                                                                {truncate(video.snippet.title, 45)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div>
                                            <span style={SidebarStyles.noVideos}>No videos to display</span>
                                        </div>
                                    )}
                            </div>
                        )}
                </div>
            </div>
        );
    }

    render() {
        const {
            showHelpVideos,
        } = this.state;

        if (showHelpVideos) {
            return (
                this.renderHelpVideos()
            );
        }
        return (
            <div style={SidebarStyles.mainContainer}>
                <ReactTooltip />
                <BackButton
                    label="Close"
                    goBack={this.onClose}
                />
                <div // eslint-disable-line
                    style={{ ...SidebarStyles.buttonContainer, ...SidebarStyles.borderTop, }}
                    role="button"
                    onClick={this.onAsk}
                >
                    <span
                        style={SidebarStyles.lineButton}
                    >
                        Ask Us a Question
                    </span>
                    <FontIcon
                        className="helpVideoArrow"
                        value="arrow_forward_ios"
                        alt="Arrow"
                    />
                </div>
                <div style={{ marginTop: 35, }} />
                <div // eslint-disable-line
                    style={{ ...SidebarStyles.buttonContainer, ...SidebarStyles.borderTop, }}
                    role="button"
                    onClick={this.onHelpCenter}
                >
                    <span
                        style={SidebarStyles.lineButton}
                    >
                        Help Center
                    </span>
                    <FontIcon
                        className="helpVideoArrow"
                        value="arrow_forward_ios"
                        alt="Arrow"
                    />
                </div>
                {/* <div // eslint-disable-line
                    style={SidebarStyles.buttonContainer}
                    role="button"
                    onClick={this.onGetHelpVideos}
                >
                    <span
                        style={SidebarStyles.lineButton}
                    >
                        Help Videos
                    </span>
                    <FontIcon
                        className="helpVideoArrow"
                        value="arrow_forward_ios"
                        alt="Arrow"
                    />
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    videos: state.help.videos,
    loadingVideos: state.loading.videos,
});

export default connect(mapStateToProps, {
    getHelpVideos: _getHelpVideos,
})(Help);
