// @flow
import type { Saga } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setHelpVideos } from '../Redux/HelpActions';
import { finishLoading, startLoading } from '../Redux/LoadingActions';
import { setSagaMessage } from '../Redux/ApplicationActions';

export default function* getHelpVideosSaga(): Saga<void> {
    yield put(startLoading('videos'));

    const youtubeAPIKey = process.env.REACT_APP_YOUTUBE_API_KEY || '';

    const response = yield call(fetch, 'https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCMCTUT8npRAuQbmrdm67ANQ&'
        + `maxResults=25&order=date&key=${youtubeAPIKey}`);

    if (response.status !== 200) {
        yield put(finishLoading('videos'));

        yield put(setHelpVideos([]));

        yield put(setSagaMessage('Failed to load help videos', '', ''));
    } else {
        const json = yield call([response, response.json]);

        const videosArr = [];
        json.items.forEach((item: any) => {
            if (item.id.videoId) {
                videosArr.push(item);
            }
        });

        yield put(finishLoading('videos'));

        yield put(setHelpVideos(videosArr));
    }
}
