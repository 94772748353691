// @flow

export const causesOfDeath = [
    {
        label: 'Anoxia',
        value: 'Anoxia',
    },
    {
        label: 'Cerebrovascular/Stroke',
        value: 'Cerebrovascular/Stroke',
    },
    {
        label: 'CNS Tumor',
        value: 'CNS Tumor',
    },
    {
        label: 'Head Trauma',
        value: 'Head Trauma',
    },
    {
        label: 'Other',
        value: 'Other',
    },
    {
        label: 'Unknown',
        value: 'Unknown',
    }
];

export const causesOfDeathMatchers = [
    {
        regex: /anoxia/i,
        value: 'Anoxia',
    },
    {
        regex: /stroke/i,
        value: 'Cerebrovascular/Stroke',
    },
    {
        regex: /cns tumor/i,
        value: 'CNS Tumor',
    },
    {
        regex: /head trauma/i,
        value: 'Head Trauma',
    },
    {
        regex: /other/i,
        value: 'Other',
    },
    {
        regex: /unknown/i,
        value: 'Unknown',
    }
];
