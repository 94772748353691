// @flow
import * as Sentry from '@sentry/browser';

import isString from './isString';
import { isObject } from './Object';

export const ucfirst = (value: string): string => `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;

const flattenError = (
    error: *,
    fields: ?Array<string>,
    mutateFieldName: ?(key: string) => string,
    defaultErrorText: string = 'Something went wrong'
) => {
    let hasFieldErrors = false;
    const storeErrors = {};

    error.errors.forEach((fieldError) => {
        hasFieldErrors = true;

        const fieldName = mutateFieldName ? mutateFieldName(fieldError.fieldName) : fieldError.fieldName;

        // Put the error under nonfieldErrors If known fields is provided and it does not contain error field
        if (fields && fields.indexOf(fieldName) === -1) {
            storeErrors.nonFieldError = ucfirst(`${fieldError}`);
        } else {
            storeErrors[fieldName] = ucfirst(`${fieldError}`);
        }
    });

    if (error.errors.nonFieldError) {
        storeErrors.nonFieldError = ucfirst(`${error.errors.nonFieldError}`);
    } else if (!hasFieldErrors && !storeErrors.nonFieldError) {
        storeErrors.nonFieldError = defaultErrorText;
    }

    return storeErrors;
};

export function parseError(error: any) {
    // The error may be in many forms and we don't want to expose too much information for failed authentication
    const flatError: any = flattenError(error);
    let errorMessage = '';
    // Non field errors should be ok to display
    if (flatError.nonFieldError) errorMessage = flatError.nonFieldError;
    // Mask the field errors
    if (flatError.email || flatError.activationId) errorMessage = 'Verification failed. This token is not valid.';

    return errorMessage;
}

export const customSingleError = (error: string, field: string) => {
    const custom = {};
    custom[field] = error;
    return custom;
};

export const singleErrorMessage = (error: any): string => {
    if (isString(error)) {
        return error.toString();
    }

    if (error.isValidationError) {
        return `${error.errors.firstError(true)}`;
    } if (error.isNetworkError) {
        return 'Connection failed, are you online?';
    }

    // If it is a socket error
    if (!error.statusCode) {
        if (error.error && isString(error.error)) {
            return error.error;
        } if (error.invalid_request && Array.isArray(error.invalid_request) && error.invalid_request.length > 0) {
            if (Array.isArray(error.invalid_request[0].violated_constraints)) {
                return error.invalid_request[0].violated_constraints.join(' ');
            }
        }
    }

    return 'Try again later';
};

export const parseResponseTextError = (responseText: string): string => {
    if (!isString(responseText)) {
        return 'Conflict, try again later';
    }

    const colonIndex = responseText.indexOf(':');
    const responseTextSubstr = responseText.substring(colonIndex + 1);

    const quoteIndex1 = responseTextSubstr.indexOf('"');
    const removeQuote1 = responseTextSubstr.substring(quoteIndex1 + 1);
    const quoteIndex2 = removeQuote1.indexOf('"');

    return removeQuote1.substring(0, quoteIndex2);
};

/**
 * Log the network error (tg-resource error types) to sentry as breadcrumbs
 *
 * @param {InvalidResponseCode | NetworkError | RequestValidationError} error
 */
export const maybeLogNetworkError = (error: any): boolean => {
    if (error) {
        let breadcrumb;

        if (error.isValidationError) {
            breadcrumb = {
                isValidationError: true,
                statusCode: error.statusCode,
                responseText: error.responseText,
                errors: `${error.errors}`,
            };
        } else if (error.isNetworkError) {
            breadcrumb = {
                isNetworkError: true,
                error: `${error}`,
            };
        } else if (error.isInvalidResponseCode) {
            breadcrumb = {
                isInvalidResponseCode: true,
                statusCode: error.statusCode,
                responseText: error.responseText,
            };
        } else if (isObject(error) && (error.error || error.invalid_request)) {
            breadcrumb = {
                isSocketError: true,
                error,
            };
        }

        if (breadcrumb) {
            Sentry.addBreadcrumb({
                type: 'api-result',
                data: breadcrumb,
            });

            Sentry.captureException(new Error('A network request error occured. See breadcrumbs for more information'));
            return true;
        }
    }

    return false;
};

export default flattenError;
