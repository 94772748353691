// @flow
import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

import styles from './Styles/GenericFormStyles';
import type { FormDefField } from '../Utils/types';
import { getFormLabel } from './FormLabel';

type Props = {
    fieldDisabled: boolean,
    itemDef: FormDefField,
    itemData: any,
    onChange: (item: FormDefField, value: any) => *,
};

const FormPicker = (props: Props) => {
    const {
        fieldDisabled,
        itemDef,
        itemData,
        onChange,
    } = props;

    if (!itemDef) return null;

    const value = itemData && itemData.value ? itemData.value : '';
    const responseOptions = itemDef.responseOptions || [];

    return (
        <div style={styles.padding10}>
            <TextField
                disabled={fieldDisabled}
                variant="outlined"
                fullWidth
                select
                color="primary"
                label={getFormLabel(itemDef.title, itemDef.mandatory)}
                type="text"
                InputLabelProps={{ shrink: true, }}
                value={value}
                helperText={itemDef.helperText}
                onChange={(event) => onChange(itemDef, event.target.value)}
            >
                {responseOptions.map((option: any) => (
                    (option.display && option.value)
                        ? (
                            <MenuItem key={option.display + option.value} value={option.value}>{option.display}</MenuItem>
                        )
                        : (<MenuItem key={option} value={option}>{option}</MenuItem>)
                ))}
            </TextField>
        </div>
    );
};

export default FormPicker;
