// @flow
import Colors from '../../Themes/Colors';

const BannerStyles = {
    donorClosedOverlayMessageWrapper: {
        left: 0,
        paddingRight: 8,
        paddingLeft: 8,
        paddingTop: 4,
        paddingBottom: 4,
        right: 0,
        top: 0,
        zIndex: 1,
    },
    overlayMessageText: {
        color: Colors.white,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default BannerStyles;
