// @flow
import type { Saga } from 'redux-saga';
import { put, delay, select } from 'redux-saga/effects';
import { selectUserId } from 'txp-core';

import { apiPut } from './ApiSaga';
import api from '../Services/Api';
import createUuid from '../Utils/createUuid';
import { setDeviceToken } from '../Redux/ApplicationActions';
import type { StoreDeviceToken } from '../Redux/ApplicationActions';

export default function* storeDeviceToken(action: StoreDeviceToken): Saga<void> {
    const memberId = yield select((state) => selectUserId(state.auth));
    const deviceId = createUuid(memberId);
    yield delay(200);
    yield apiPut(api.txp.saveDeviceToken, null, {
        fcm_registration_token: action.deviceToken,
        device_os: 'web',
        device_id: deviceId,
    });

    yield put(setDeviceToken(action.deviceToken));
}
