// @flow
import React, { Component } from 'react';
import type { ElementRef } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import Input from 'react-toolbox/lib/input';
import Button from 'react-toolbox/lib/button/Button';
import Fade from 'react-reveal/Fade';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { withOktaAuth } from '@okta/okta-react';
import {
    login,
    forgotPassword as _forgotPassword,
    requestSMSFactor as _requestSMSFactor,
    verifySMSFactor as _verifySMSFactor,
    resetPassword as _resetPassword,
    forceLogin as _forceLogin,
} from 'txp-core';
import type {
    Login,
    LoginError,
    ForgotPassword,
    RequestSMSFactor,
    SMSError,
    VerifySMSFactor,
    ResetPassword,
    ForceLogin,
} from 'txp-core';

import Images from '../Themes/Images';
import AuthStyles from './Styles/AuthStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import { appConfiguration } from '../AppConfiguration';
import SagaMessage from '../Components/SagaMessage';
import VerificationCodeInput from '../Components/VerificationCodeInput';
import PasswordRequirements from '../Components/PasswordRequirements';
import Colors from '../Themes/Colors';

type Props = {
    loginError: ?LoginError,
    smsError: ?SMSError,
    loginRequestActive: boolean,
    isAuthenticated: boolean,
    forgot: boolean, // forgot password button is clicked
    verifyFactor: boolean, // verifying SMS factor sent to user's phone
    reset: boolean, // step where password is reset
    email: string,
    oktaAuth: ?any,

    onSubmitLogin: (email: string, password: string, application: string, requiredLicense: string, requiredAccessLevel: string) => Login,
    forgotPassword: () => ForgotPassword,
    requestSMSFactor: (email: string) => RequestSMSFactor,
    verifySMSFactor: (email: string, passcode: string) => VerifySMSFactor,
    resetPassword: (email: string, password: string) => ResetPassword,
    forceLogin: () => ForceLogin,
};

type State = {
    formValid: boolean,

    emailInput: string,
    passwordInput: string,

    showPassword: boolean,
    showPasswordReq: boolean,
};

type RefNodes = {
    emailInput: ?ElementRef<*>,
    passwordInput: ?ElementRef<*>,
};

const loginToast: number = 10010;

class LoginPage extends Component<Props, State> {
    nodes: RefNodes = {
        emailInput: null,
        passwordInput: null,
    };

    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            formValid: false,

            emailInput: this.props.email,
            passwordInput: '',

            showPassword: false,
            showPasswordReq: false,
        };
    }

    onSubmitLogin = () => {
        const {
            loginRequestActive,
            onSubmitLogin,
        } = this.props;

        const {
            formValid,
            emailInput,
            passwordInput,
        } = this.state;

        if (!formValid || loginRequestActive) {
            return;
        }
        onSubmitLogin(emailInput, passwordInput, 'TXP_CHAT_WEB', 'Standard', 'BasicUser');
    };

    onSSOLogin = async () => {
        const {
            oktaAuth,
        } = this.props;

        if (oktaAuth) {
            await oktaAuth.signInWithRedirect({
                scopes: ['openid', 'profile', 'offline_access'],
            });
        }
    };

    onForgotPassword = () => {
        const {
            forgotPassword,
        } = this.props;

        forgotPassword();
    };

    onRequestSMS = () => {
        const {
            requestSMSFactor,
        } = this.props;

        const {
            emailInput,
        } = this.state;

        requestSMSFactor(emailInput);
    };

    onVerifySMS = (passcode: string) => {
        const {
            verifySMSFactor,
        } = this.props;

        const {
            emailInput,
        } = this.state;

        verifySMSFactor(emailInput, passcode);
    };

    onResetPassword = () => {
        const {
            resetPassword,
        } = this.props;

        const {
            emailInput,
            passwordInput,
        } = this.state;

        resetPassword(emailInput, passwordInput);

        // reset state and node values
        this.setNodeValue('passwordInput', '');
        this.setNodeRef('passwordInput', null);
    };

    onGoToLogin = () => {
        const {
            forceLogin,
        } = this.props;

        forceLogin();

        // reset state and node values
        this.setNodeValue('emailInput', '');
        this.setNodeValue('passwordInput', '');
        this.setNodeRef('emailInput', null);
        this.setNodeRef('passwordInput', null);
    };

    onKeyLogin = (e: { which: number }) => {
        if (e.which === 13) {
            this.onSubmitLogin();
        }
    };

    onKeyEmail = (e: { which: number }) => {
        if (e.which === 13) {
            this.onRequestSMS();
        }
    };

    onKeyPassword = (e: { which: number }) => {
        if (e.which === 13) {
            this.onResetPassword();
        }
    };

    setNodeValue(key: 'emailInput' | 'passwordInput', value: string) {
        this.setState((previousState) => {
            const email = key === 'emailInput' ? value : previousState.emailInput;
            const password = key === 'passwordInput' ? value : previousState.passwordInput;
            previousState[key] = value;
            previousState.formValid = email.length > 0 && password.length >= 8;
            return previousState;
        });
    }

    setNodeRef(key: string, ref: ?ElementRef<*>) {
        this.nodes[key] = ref;
    }

    toggleShowPassword = () => {
        this.setState((prevState: State) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    toggleShowPasswordReq = () => {
        this.setState((prevState: State) => ({
            showPasswordReq: !prevState.showPasswordReq,
        }));
    };

    hasFieldError(fieldName: string, allowNonField: boolean): boolean {
        const {
            loginError,
            smsError,
        } = this.props;

        if (loginError) {
            return !!loginError[fieldName] || !!(allowNonField && loginError.nonFieldError);
        } if (smsError) {
            return !!smsError[fieldName] || !!(allowNonField && smsError.nonFieldError);
        }
        return false;
    }

    renderFieldError(fieldName: string, allowNonField: boolean) {
        const {
            loginError,
            smsError,
        } = this.props;

        let error = null;
        if (loginError) {
            error = loginError[fieldName];

            if (!error && allowNonField) {
                error = loginError.nonFieldError;
            }

            if (!error) {
                return null;
            }
        } else if (smsError) {
            error = smsError[fieldName];

            if (!error && allowNonField) {
                error = smsError.nonFieldError;
            }

            if (!error) {
                return null;
            }
        }

        return (
            <span
                style={AuthStyles.formError}
            >
                {error}
            </span>
        );
    }

    renderSubmitEmail() {
        const {
            emailInput,
        } = this.state;

        const errors = {
            email: this.hasFieldError('email', true),
            password: this.hasFieldError('password', false),
        };

        const submitEmailDisabled = !emailInput.includes('@');

        return (
            <div className="verticalCenter">
                <div style={AuthStyles.backButton}>
                    <Button
                        style={ApplicationStyles.buttonInverse}
                        type="button"
                        label="Go back"
                        flat
                        ripple={false}
                        onClick={this.onGoToLogin}
                    />
                </div>
                <h1 style={{ ...ApplicationStyles.loginLabel, ...{ marginTop: 50, }, }}>OmniLife Web</h1>
                <span
                    style={AuthStyles.formLabelStacked}
                >
                    Did you forget your password?
                </span>
                <span
                    style={{ ...AuthStyles.formLabelStacked, ...{ marginBottom: 5, display: 'block', }, }}
                >
                    No worries, enter your email and we&apos;ll send an SMS code to the phone number associated that account.
                </span>
                <Input
                    style={
                        errors.email ? ApplicationStyles.textInputError : null
                    }
                    type="text"
                    label="Your email"
                    name="emailInput"
                    ref={(n) => this.setNodeRef('emailInput', n)}
                    onChange={(text) => this.setNodeValue('emailInput', text)}
                    onKeyPress={this.onKeyEmail}
                    value={emailInput}
                />
                {this.renderFieldError('email', true)}
                <div>
                    <Button
                        style={
                            submitEmailDisabled ? ApplicationStyles.buttonDisabled : ApplicationStyles.button
                        }
                        type="button"
                        disabled={submitEmailDisabled}
                        label="Submit"
                        flat
                        onClick={this.onRequestSMS}
                    />
                </div>
            </div>
        );
    }

    renderSMSInput() {
        const {
            smsError,
        } = this.props;

        return (
            <div>
                <SagaMessage toastId={loginToast} />
                <ToastContainer
                    position="top-right"
                    autoClose={4000}
                    newestOnTop={false}
                    hideProgressBar
                    transition={Slide}
                />
                <div className="verticalCenter">
                    <VerificationCodeInput
                        errorMessage={smsError && smsError.nonFieldError ? smsError.nonFieldError : ''}
                        verifying={false}
                        canResend={false}
                        showHeading
                        canGoBack
                        submitPasscode={this.onVerifySMS}
                        goBack={this.onGoToLogin}
                    />
                </div>
            </div>
        );
    }

    renderResetPassword() {
        const {
            passwordInput,
            showPassword,
            showPasswordReq,
        } = this.state;

        const errors = {
            password: this.hasFieldError('password', true),
        };

        const submitDisabled = passwordInput.length < 8;

        return (
            <div>
                <div className="verticalCenter">
                    <div style={AuthStyles.backButton}>
                        <Button
                            style={ApplicationStyles.buttonInverse}
                            type="button"
                            label="Go back"
                            flat
                            ripple={false}
                            onClick={this.onGoToLogin}
                        />
                    </div>
                    <h1 style={{ ...ApplicationStyles.loginLabel, ...{ marginTop: 50, }, }}>OmniLife Web</h1>
                    <span style={AuthStyles.formLabelStacked}>Please enter your new password.</span>
                    <Input
                        style={
                            errors.password ? ApplicationStyles.textInputError : null
                        }
                        type={showPassword ? 'text' : 'password'}
                        label="New password"
                        name="passwordInput"
                        ref={(n) => this.setNodeRef('passwordInput', n)}
                        onChange={(text) => this.setNodeValue('passwordInput', text)}
                        onKeyPress={this.onKeyPassword}
                        value={passwordInput}
                    >
                        <Fade collapse when={passwordInput.length > 0}>
                            <Button
                                style={AuthStyles.showPasswordButton}
                                tabIndex={-1}
                                type="button"
                                label={showPassword ? 'Hide password' : 'Show password'}
                                ripple={false}
                                onClick={this.toggleShowPassword}
                            />
                        </Fade>
                    </Input>
                    {this.renderFieldError('password', true)}
                    <div style={{ marginBottom: 20, }}>
                        <div style={{ textAlign: 'left', }}>
                            <Button
                                style={AuthStyles.passwordReqButton}
                                tabIndex={-1}
                                type="button"
                                label="Show Password Requirements"
                                ripple={false}
                                onClick={this.toggleShowPasswordReq}
                            />
                        </div>
                        <Fade collapse when={showPasswordReq}>
                            <PasswordRequirements />
                        </Fade>
                    </div>
                    <div>
                        <Button
                            style={
                                submitDisabled ? ApplicationStyles.buttonDisabled : ApplicationStyles.button
                            }
                            type="button"
                            disabled={submitDisabled}
                            label="Reset password"
                            flat
                            onClick={this.onResetPassword}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderLoginPage() {
        const {
            loginRequestActive,
        } = this.props;

        const {
            formValid,
            emailInput,
            passwordInput,
        } = this.state;

        const errors = {
            email: this.hasFieldError('email', true),
            password: this.hasFieldError('password', false),
        };

        const submitDisabled = !formValid || loginRequestActive;

        const copyrightMessage = `${(new Date().getFullYear())} OmniLife Health. All Rights Reserved. `;
        const licenseLinkText = 'License & Attribution';
        const licenseLinkUrl = process.env.REACT_APP_LICENSE_ATTRIBUTION ?? '';
        const appVersionMessage = `OmniLife Web ${appConfiguration().version}`;

        const ssoEnabled = !!process.env.REACT_APP_SPA_CLIENT_ID;

        return (
            <div>
                <SagaMessage toastId={loginToast} />
                <ToastContainer
                    position="top-right"
                    autoClose={4000}
                    newestOnTop={false}
                    hideProgressBar
                    transition={Slide}
                />
                <form className="verticalCenter">
                    <div>
                        <img height={120} width={400} src={Images.logoHeader} alt="OmniLife Health Logo" />
                    </div>
                    <h1 style={ApplicationStyles.loginLabel}>OmniLife Web</h1>
                    <span style={AuthStyles.formLabel}>Enter your email and password to log in.</span>
                    <Input
                        style={
                            errors.email ? ApplicationStyles.textInputError : null
                        }
                        type="text"
                        label="Your email"
                        name="emailInput"
                        ref={(n) => this.setNodeRef('emailInput', n)}
                        onChange={(text) => this.setNodeValue('emailInput', text)}
                        value={emailInput}
                    />
                    {this.renderFieldError('email', true)}
                    <Input
                        style={
                            errors.password ? ApplicationStyles.textInputError : null
                        }
                        type="password"
                        label="Your password"
                        name="passwordInput"
                        ref={(n) => this.setNodeRef('passwordInput', n)}
                        onChange={(text) => this.setNodeValue('passwordInput', text)}
                        onKeyPress={this.onKeyLogin}
                        value={passwordInput}
                    >
                        <div style={{ textAlign: 'right', }}>
                            <Button
                                style={ApplicationStyles.buttonInverse}
                                type="button"
                                label="Forgot?"
                                flat
                                ripple={false}
                                onClick={this.onForgotPassword}
                            />
                        </div>
                    </Input>
                    {this.renderFieldError('password', false)}
                    <div>
                        <Button
                            style={
                                submitDisabled ? ApplicationStyles.buttonDisabled : ApplicationStyles.button
                            }
                            type="button"
                            disabled={submitDisabled}
                            label="Log in"
                            flat
                            onClick={this.onSubmitLogin}
                        />
                    </div>
                    {ssoEnabled
                        ? (
                            <div>
                                <Divider style={{ ...AuthStyles.instructionsInline, marginTop: '20px', }}>Or sign in with</Divider>
                                <div
                                    style={{
                                        marginTop: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        aria-label="sso"
                                        style={{ borderRadius: '10px', backgroundColor: Colors.lighterGray, padding: '10px', }}
                                        onClick={this.onSSOLogin}
                                    >
                                        <FontAwesomeIcon icon={faKey} size="sm" />
                                    </IconButton>
                                    <div style={{ color: Colors.gray, fontSize: '0.95rem', }}>SSO</div>
                                </div>
                            </div>
                        ) : null}
                    <div style={{ paddingTop: 25, }}>
                        <span style={AuthStyles.instructionsInline}>No account yet? </span>
                        <NavLink
                            style={AuthStyles.navLink}
                            to="/register"
                        >
                            Sign Up
                        </NavLink>
                    </div>
                    <div style={{ paddingTop: 25, }}>
                        <span style={AuthStyles.version}>
                            &copy;
                            {copyrightMessage}
                            <a style={AuthStyles.version} href={licenseLinkUrl}>{licenseLinkText}</a>
                        </span>
                    </div>
                    <div>
                        <span style={AuthStyles.version}>
                            {appVersionMessage}
                        </span>
                    </div>
                </form>
            </div>
        );
    }

    render() {
        const {
            isAuthenticated,
            forgot,
            verifyFactor,
            reset,
        } = this.props;

        if (!isAuthenticated) {
            if (forgot) {
                return (
                    this.renderSubmitEmail()
                );
            } if (verifyFactor) {
                return (
                    this.renderSMSInput()
                );
            } if (reset) {
                return (
                    this.renderResetPassword()
                );
            }
            return (
                this.renderLoginPage()
            );
        }
        return <Redirect to="/chat" />;
    }
}

const mapStateToProps = (state) => ({
    loginError: state.auth.loginError,
    smsError: state.auth.smsError,
    loginRequestActive: state.loading.login,
    isAuthenticated: state.auth.authenticated,
    forgot: state.auth.forgotPassword,
    verifyFactor: state.auth.verifyFactor,
    reset: state.auth.resetPassword,
    email: state.registration.email,
});

export default withOktaAuth(connect(mapStateToProps, {
    onSubmitLogin: login,
    forgotPassword: _forgotPassword,
    requestSMSFactor: _requestSMSFactor,
    verifySMSFactor: _verifySMSFactor,
    resetPassword: _resetPassword,
    forceLogin: _forceLogin,
})(LoginPage));
