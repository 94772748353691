// @flow
import React, { PureComponent } from 'react';
import LoadingIndicator from 'react-loading-indicator';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon';
import ApplicationStyles from '../Themes/ApplicationStyles';

type Props = {
    text: string,
    onClick: () => *,
    isLoading: boolean,
};

class RetryButton extends PureComponent<Props> {
    render() {
        const {
            text,
            onClick,
            isLoading,
        } = this.props;

        let retryButtonStyle = {
            ...ApplicationStyles.button,
        };

        if (isLoading) {
            retryButtonStyle = {
                ...retryButtonStyle,
                ...ApplicationStyles.buttonDisabled,
            };
        }

        let buttonContents;
        if (isLoading) {
            buttonContents = (
                <>
                    <LoadingIndicator segmentLength={1} style={{ height: 'initial', verticalAlign: 'initial', }} />
                    {text}
                </>
            );
        } else {
            buttonContents = (
                <>
                    <FontIcon
                        value="autorenew"
                        alt=""
                    />
                    {text}
                </>
            );
        }

        return (
            <Button
                style={retryButtonStyle}
                disabled={isLoading}
                type="button"
                ripple={false}
                onClick={onClick}
                className="retry-btn"
            >
                {buttonContents}
            </Button>
        );
    }
}

export default RetryButton;
