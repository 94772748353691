/* eslint-disable object-curly-newline */
// @flow
import {
    BOOLEAN, STRING,
    UI_TYPE_TEXT, UI_TYPE_SWITCH, UI_TYPE_CHECK, UI_TYPE_TXC_LIST, UI_TYPE_SURGEON_LIST,
    KB_TYPE_NUMBER, KB_TYPE_PHONE, KB_TYPE_EMAIL,
} from './FormTypes';

const formDef = {
    formId: 'OAHeart',
    sections: [
        {
            id: 1,
            title: '',
            fields: [
                { id: 1, title: 'No intended recovery', type: BOOLEAN, uiType: UI_TYPE_SWITCH, behavior: 'DISABLE-NOT-N', tags: ['N'], },
                { id: 2, title: 'Recovering, not for transplant', type: BOOLEAN, uiType: UI_TYPE_SWITCH, behavior: 'DISABLE-A', tags: [], },
                { id: 3, title: 'Recovery protocols received', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: ['A'], },
                { id: 4, title: 'Notes', type: STRING, uiType: UI_TYPE_TEXT, maxLines: 3, maxLength: 255, tags: ['N'], }
            ],
        },
        {
            id: 2,
            title: 'Primary Acceptance',
            fields: [
                { id: 5, title: 'Tx Ctr', type: STRING, uiType: UI_TYPE_TXC_LIST, tags: ['A'], },
                { id: 6, title: 'Tx Ctr Contact', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_EMAIL, tags: ['A'], },
                { id: 7, title: 'Phone', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_PHONE, tags: ['A'], },
                { id: 8, title: 'Tail #', type: STRING, uiType: UI_TYPE_TEXT, tags: ['A'], displayOnItem: true, },
                { id: 9, title: 'ETA', type: STRING, uiType: UI_TYPE_TEXT, tags: ['A'], },
                { id: 10, title: 'Recovering MD', type: STRING, uiType: UI_TYPE_SURGEON_LIST, tags: ['A'], },
                { id: 11, title: 'Number in recovery team', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_NUMBER, tags: ['A'], },
                { id: 12, title: 'ACIN Verified', type: BOOLEAN, uiType: UI_TYPE_CHECK, tags: ['A'], },
                { id: 13, title: 'Special Request', type: STRING, uiType: UI_TYPE_TEXT, tags: ['A'], },
                { id: 14, title: 'Transport Arranged', type: BOOLEAN, uiType: UI_TYPE_CHECK, tags: ['A'], },
                { id: 15, title: 'Transport Notes', type: STRING, uiType: UI_TYPE_TEXT, maxLines: 3, tags: ['A'], }
            ],
        },
        {
            id: 3,
            title: 'Backup Acceptance',
            fields: [
                { id: 16, title: 'Tx Ctr', type: STRING, uiType: UI_TYPE_TXC_LIST, tags: ['A'], },
                { id: 17, title: 'Tx Ctr Contact', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_EMAIL, tags: ['A'], },
                { id: 18, title: 'Phone', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_PHONE, tags: ['A'], }
            ],
        },
        {
            id: 4,
            title: 'Research Center',
            fields: [
                { id: 19, title: 'Research Center', type: STRING, uiType: UI_TYPE_TEXT, tags: [], },
                { id: 20, title: 'Contact', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_EMAIL, tags: [], },
                { id: 21, title: 'Phone', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_PHONE, tags: [], }
            ],
        }
    ],
    disableBehavior: {
        'DISABLE-NOT-N': 1,
        'DISABLE-A': 2,
    },
};

export default formDef;
