// @flow
import React from 'react';
import { Button } from '@material-ui/core';
import BackButton from './BackButton';

import styles from './Styles/PanelNavigationStyles';

type Props = {
    title: string,
    saveDisabled: boolean,
    hideSave: boolean,
    onBackClick: () => *,
    onSaveClick: () => *,
};

function PanelNavigation(props: Props) {
    const {
        title,
        saveDisabled,
        hideSave,
        onBackClick,
        onSaveClick,
    } = props;

    const onBackClickHandler = () => {
        onBackClick();
    };

    const onSaveClickHandler = () => {
        onSaveClick();
    };

    return (
        <div style={styles.buttonsWrapper}>
            <BackButton
                label="Back"
                goBack={onBackClickHandler}
            />
            {title}
            <Button
                style={saveDisabled ? styles.saveButtonDisabled : styles.saveButton}
                component="button"
                disabled={saveDisabled || hideSave}
                disableRipple
                onClick={onSaveClickHandler}
            >
                {hideSave ? '' : 'Save'}
            </Button>
        </div>
    );
}

export default PanelNavigation;
