// @flow
import React, { PureComponent } from 'react';
import FontIcon from 'react-toolbox/lib/font_icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import {
    selectProfileEmail, selectProfileName, selectProfileOrganizationName, selectProfilePhone,
} from 'txp-core';

import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import UserDetailsStyles from './Styles/UserDetailsStyles';
import type {
    UserProfile,
    ChatroomMember,
    ChatMembershipStatus,
} from '../Utils/types';
import { formatPhone } from '../Utils/serializePhone';
import { formatFullDateTime } from '../Utils/time';
import Colors from '../Themes/Colors';

type Props = {
    membershipStatus: ChatMembershipStatus,
    endDate: string,
    startDate: string,
    userProfile: UserProfile,
    displayButton: boolean,
    avatar: string,
    doNotDisturb: boolean,
    displayEmail: boolean,
    isConnected: boolean,
    isManager?: boolean, // is the current user we're looking at a manager
    isCurrentUserManager?: boolean, // is the current user logged in a manager
    wrapEmail?: boolean,
    isTeamList?: boolean,

    onMemberClick: (user: UserProfile) => *,
    addManager: (user: UserProfile) => *,
    removeManager: (user: UserProfile) => *,
    showMemberProfile: (selectedChatroomMember: ChatroomMember) => *,
};

class UserDetails extends PureComponent<Props> {
    onMemberClick = () => {
        const {
            userProfile,
            onMemberClick,
        } = this.props;

        onMemberClick(userProfile);
    };

    renderButtonArea() {
        const {
            membershipStatus,
            displayButton,
            isConnected,
        } = this.props;

        if (membershipStatus === 'Archived') {
            return null;
        }

        if (displayButton) {
            let value;
            let className;
            let dataTip;

            if (membershipStatus === 'NonMember') {
                value = 'add';
                className = isConnected ? 'iconBlue24' : 'iconDisabled24';
                dataTip = 'Select user';
            } else if (membershipStatus === 'Pending') {
                value = 'check_circle';
                className = isConnected ? 'iconBlue24' : 'iconDisabled24';
                dataTip = 'Deselect user';
            } else {
                value = 'cancel';
                className = isConnected ? 'iconGray' : 'iconDisabled24';
                dataTip = 'Remove member from room';
            }

            return (
                <button
                    style={isConnected ? UserDetailsStyles.memberButton : UserDetailsStyles.memberButtonDisabled}
                    type="button"
                    disabled={!isConnected}
                    onClick={this.onMemberClick}
                    data-tip={dataTip}
                    data-delay-show="500"
                    data-effect="solid"
                >
                    <FontIcon
                        className={className}
                        value={value}
                        alt=""
                    />
                </button>
            );
        }
        return null;
    }

    renderManagerButtonArea() {
        const {
            userProfile,
            isCurrentUserManager,
            isManager,
            membershipStatus,
            isConnected,
            addManager,
            removeManager,
        } = this.props;

        if (membershipStatus !== 'Present') {
            return null;
        }

        if (isCurrentUserManager) {
            let icon;
            let color;
            let dataTip;

            if (isManager) {
                icon = faUserTimes;
                dataTip = 'Remove manager';
                color = Colors.red;
            } else {
                icon = faUserCheck;
                dataTip = 'Add manager';
                color = Colors.green;
            }

            const action = isManager ? removeManager : addManager;

            return (
                <button
                    style={isConnected ? UserDetailsStyles.memberButton : UserDetailsStyles.memberButtonDisabled}
                    type="button"
                    disabled={!isConnected}
                    onClick={() => action(userProfile)}
                    data-tip={dataTip}
                    data-delay-show="500"
                    data-effect="solid"
                >
                    <FontAwesomeIcon
                        color={color}
                        icon={icon}
                        size="lg"
                    />
                </button>
            );
        }
        return null;
    }

    renderStatus() {
        const {
            userProfile,
            membershipStatus,
            startDate,
            endDate,
        } = this.props;

        const organizationName = selectProfileOrganizationName(userProfile, '');
        const organizationNameDisplay = organizationName.length ? (
            <div style={UserDetailsStyles.smallDetails}>{organizationName}</div>
        ) : null;

        switch (membershipStatus) {
            case 'Present':
                return (
                    <div>
                        {organizationNameDisplay}
                        <div style={UserDetailsStyles.smallDetails}>
                            {`Added on ${formatFullDateTime(startDate)}`}
                        </div>
                    </div>
                );

            case 'Left':
                if (endDate) {
                    return (
                        <div>
                            {organizationNameDisplay}
                            <div style={UserDetailsStyles.smallDetails}>
                                {`Left on ${formatFullDateTime(endDate)}`}
                            </div>
                        </div>
                    );
                }
                return (
                    <div>
                        {organizationNameDisplay}
                        <div style={UserDetailsStyles.smallDetails}>
                            Left just now
                        </div>
                    </div>
                );

            case 'Removed':
                if (endDate) {
                    return (
                        <div>
                            {organizationNameDisplay}
                            <div style={UserDetailsStyles.smallDetails}>
                                {`Removed on ${formatFullDateTime(endDate)}`}
                            </div>
                        </div>
                    );
                }
                return (
                    <div>
                        {organizationNameDisplay}
                        <div style={UserDetailsStyles.smallDetails}>
                            Removed just now
                        </div>
                    </div>
                );

            case 'Invited':
                return (
                    <div>
                        <div style={UserDetailsStyles.smallDetails}>
                            {formatPhone(selectProfilePhone(userProfile))}
                        </div>
                        <div style={UserDetailsStyles.smallDetails}>
                            {`Invited on  ${formatFullDateTime(startDate)}`}
                        </div>
                    </div>
                );

            case 'Expired':
                return (
                    <div style={UserDetailsStyles.smallDetails}>
                        {`Expired on  ${formatFullDateTime(startDate)}`}
                    </div>
                );

            case 'Archived':
                return (
                    <div style={UserDetailsStyles.smallDetails}>
                        {`Archived on  ${formatFullDateTime(endDate)}`}
                    </div>
                );

            default:
                return null;
        }
    }

    render() {
        const {
            userProfile,
            isManager,
            avatar,
            membershipStatus,
            startDate,
            endDate,
            doNotDisturb,
            displayEmail,
            wrapEmail,
            isTeamList,
            showMemberProfile,
        } = this.props;

        return (
            <div style={UserDetailsStyles.userRow}>
                <div style={UserDetailsStyles.userRowContent}>
                    <div // eslint-disable-line
                        style={UserDetailsStyles.profileImageWrapper}
                        role="button"
                        onClick={() => showMemberProfile({
                            profile: userProfile,
                            membershipStatus,
                            doNotDisturb,
                            startDate,
                            endDate,
                            notificationSound: '',
                        })}
                    >
                        <img
                            style={ChatroomDetailsStyles.profileImage}
                            src={avatar}
                            alt=""
                        />
                    </div>
                    <div style={UserDetailsStyles.userRowText}>
                        <div style={UserDetailsStyles.userName}>
                            {doNotDisturb
                                ? (
                                    <FontIcon
                                        style={UserDetailsStyles.doNotDisturbIcon}
                                        value="notifications_off"
                                        alt=""
                                    />
                                ) : null}
                            <div style={UserDetailsStyles.userName}>
                                {selectProfileName(userProfile, userProfile.email)}
                                {isManager ? ' (Room Manager)' : ''}
                            </div>
                        </div>
                        {!isTeamList ? this.renderStatus() : null}
                        {displayEmail
                            ? (
                                <div>
                                    <div style={UserDetailsStyles.smallDetails}>
                                        {formatPhone(selectProfilePhone(userProfile))}
                                    </div>
                                    <div
                                        style={
                                            { ...UserDetailsStyles.smallDetails, ...{ overflowWrap: wrapEmail ? 'anywhere' : 'none', }, }
                                        }
                                    >
                                        {selectProfileEmail(userProfile)}
                                    </div>
                                </div>
                            ) : <div />}
                    </div>
                </div>
                <div style={UserDetailsStyles.buttonRow}>
                    {this.renderManagerButtonArea()}
                    {this.renderButtonArea()}
                </div>
            </div>
        );
    }
}

// $FlowFixMe
UserDetails.defaultProps = {
    wrapEmail: true,
    isManager: false,
    isCurrentUserManager: false,
    isTeamList: false,
};

export default UserDetails;
