// @flow
import { parsePhoneNumber } from 'react-phone-number-input';

/**
 * Formats a phone number to the national normal formatting
 *   given the basic number string.
 * @param {string} number
 * @returns Formatted phone number +{CountryCode} {NationalFormattedPhone}
 *   (ie '+1 (319) 431-8574')
 */
export const formatPhone = (number: string): string => {
    const phoneNumber = parsePhoneNumber(number);
    if (!phoneNumber) {
        return '';
    }

    return `+${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}`;
};

/**
 * Prepare phone number for api submission
 *
 * @param {string} number
 * @param {number} country Code of the country (Assumes US if no value is passed in)
 */
export const serializePhone = (number: string, country: string = 'US'): string => {
    if (!number || !country) {
        return '';
    }

    const phoneNumber = parsePhoneNumber(number, country);
    return (phoneNumber ? phoneNumber.number : '');
};
