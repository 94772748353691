// @flow
import React, { PureComponent } from 'react';
import Highlighter from 'react-highlight-words';
import Fade from 'react-reveal/Fade';

import ChatListStyles from './Styles/ChatListStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import truncate from '../Utils/text';
import type { ChatroomInfo, ChatroomMessage, SearchResult } from '../Utils/types';
import { MessageTypes } from '../Utils/types';

type Props = {
    room: ChatroomInfo,
    result: Array<SearchResult>,
    deepSearchFilter: string,

    goToMessage: (chatId: number, selectedResult: SearchResult) => *,
};

type State = {
    showResults: boolean,
};

class SearchResultItem extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            showResults: false,
        };
    }

    onItemClick = () => {
        const {
            showResults,
        } = this.state;

        this.setState({
            showResults: !showResults,
        });
    };

    onMessageClick = (single: SearchResult) => {
        const {
            room,
            goToMessage,
        } = this.props;

        goToMessage(room.id, single);
    };

    textToHighlight = (message: ChatroomMessage) => {
        const {
            deepSearchFilter,
        } = this.props;

        if (message.dataContent) {
            if (message.messageType === MessageTypes.askAlanDonor) {
                return `${deepSearchFilter} found in Organ Offer`;
            }
            if (message.messageType === MessageTypes.askAlanRecipient) {
                return `${deepSearchFilter} found in Recipient Information`;
            }
            if (message.messageType === MessageTypes.askAlanCandidate) {
                return `${deepSearchFilter} found in Candidate Information`;
            }
            if (message.messageType === MessageTypes.askAlanVoid) {
                return `${deepSearchFilter} found in Superseded Offer`;
            }
        }

        return message.textContent;
    };

    render() {
        const {
            room: {
                name,
            },
            result,
            deepSearchFilter,
        } = this.props;

        const {
            showResults,
        } = this.state;

        const resultWording = result.length > 1 ? 'results' : 'result';

        return (
            <div>
                <div // eslint-disable-line
                    className="chatlistRow"
                    style={ChatListStyles.searchResultRow}
                    onClick={this.onItemClick}
                >
                    <div
                        style={ChatListStyles.searchResultPadding}
                        role="button"
                        tabIndex={0}
                    >
                        <div style={ChatListStyles.innerRow}>
                            <span
                                style={{ ...ChatListStyles.rowTitle, ...{ marginRight: 10, }, }}
                            >
                                {truncate(name, 30, false)}
                            </span>
                            <div style={ApplicationStyles.dot} />
                            <span
                                style={{ ...ChatListStyles.rowTitleGray, ...{ marginLeft: 10, }, }}
                            >
                                {`${result.length} ${resultWording}`}
                            </span>
                        </div>
                    </div>
                </div>
                <Fade collapse when={showResults}>
                    <div
                        style={{
                            maxHeight: 200,
                            overflowY: 'scroll',
                        }}
                    >
                        {result.map((matchedMessage: SearchResult) => (
                            <div // eslint-disable-line
                                key={result.indexOf(matchedMessage)}
                                style={ChatListStyles.resultItems}
                                onClick={() => this.onMessageClick(matchedMessage)}
                            >
                                <span
                                    style={ChatListStyles.resultItemsText}
                                >
                                    <Highlighter
                                        highlightClassName="highlightClass"
                                        searchWords={[deepSearchFilter]}
                                        autoEscape
                                        textToHighlight={this.textToHighlight(matchedMessage.instantMessage)}
                                    />
                                </span>
                            </div>
                        ))}
                    </div>
                </Fade>
            </div>
        );
    }
}

export default SearchResultItem;
