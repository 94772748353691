// @flow

// Extends the txp-core auth reducer to allow us
// to "rehydrate" the state from a stored version

import { authReducer } from 'txp-core';
import type { AuthState } from 'txp-core';

export type ReplaceAuth = { type: 'Auth/REPLACE', auth: AuthState };

type Action =
    | ReplaceAuth
    ;

export const replaceAuth = (auth: AuthState): ReplaceAuth => ({
    type: 'Auth/REPLACE',
    auth,
});

// We've removed redux-persist due to performance concerns but
// would still like to "rehydrate" our auth redux state
const reducer = (state: AuthState, action: Action) => {
    if (action.type === 'Auth/REPLACE') {
        return {
            ...state,
            ...action.auth,
        };
    }

    return authReducer(state, action);
};

export default reducer;
