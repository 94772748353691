// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FontIcon from 'react-toolbox/lib/font_icon';

import {
    search as _search,
} from '../Redux/ChatListActions';
import {
    setSagaMessage as _setSagaMessage,
    logFirebaseEvent as _logFirebaseEvent,
} from '../Redux/ApplicationActions';
import ChatListStyles from './Styles/ChatListStyles';
import Images from '../Themes/Images';
import type { EventName } from '../Utils/types';

type Props = {
    placeholder?: string,
    sort?: boolean,
    disabled?: boolean,
    value: string,
    deepSearch?: boolean,

    search: (text: string) => *,
    filterContent: (input: string) => *,
    setSagaMessage: (heading: string, message: string, label: string, isDialog?: boolean) => *,
    logFirebaseEvent: (name: EventName) => *,
};

class Search extends PureComponent<Props> {
    onSearchFocus = () => {
        const {
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('search-focus');
    };

    onKey = (event) => {
        const {
            value,
            search,
            setSagaMessage,
        } = this.props;

        // NOTE: updated this to use the event target to get the input element
        // instead of getting the input element with jquery and the id (to avoid duplicate id confusion)
        if (event.target.matches(':focus')) {
            if (event.which === 13) {
                if (value.length >= 3) {
                    search(value);
                } else {
                    setSagaMessage(' ', 'Enter 3 or more characters to search message content', '');
                }
            }
        }
    };

    onClearSearch = () => {
        const {
            filterContent,
        } = this.props;

        filterContent('');
    };

    renderClearButton() {
        const {
            disabled,
        } = this.props;
        return (
            <button
                type="button"
                disabled={disabled}
                style={ChatListStyles.clearSearchButton}
                onClick={this.onClearSearch}
            >
                <FontIcon className="iconGray16" value="cancel" />
            </button>
        );
    }

    render() {
        const {
            placeholder,
            sort,
            value,
            deepSearch,
            filterContent,
            disabled,
        } = this.props;

        return (
            <div style={!sort ? ChatListStyles.searchRow : null}>
                <div style={{ ...ChatListStyles.searchContainer, ...{ width: !sort ? '100%' : '18vw', }, }}>
                    <img
                        style={ChatListStyles.iconSearch}
                        src={Images.iconSearch}
                        alt=""
                    />
                    <input
                        style={ChatListStyles.searchInput}
                        type="text"
                        disabled={disabled}
                        placeholder={placeholder}
                        value={value}
                        onFocus={this.onSearchFocus}
                        onChange={(text) => filterContent(text.target.value)}
                        onKeyPress={deepSearch ? (event) => this.onKey(event) : () => {}}
                    />
                    {value !== '' ? this.renderClearButton() : null}
                </div>
            </div>
        );
    }
}

// $FlowFixMe
Search.defaultProps = {
    placeholder: 'Search...',
    sort: false,
    disabled: false,
    deepSearch: false,
};

export default connect(null, {
    search: _search,
    setSagaMessage: _setSagaMessage,
    logFirebaseEvent: _logFirebaseEvent,
})(Search);
