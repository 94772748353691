// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { formatFullDateTime } from '../Utils/time';
import type { DonorTask, History, MemberProfile } from '../Utils/types';
import styles from './Styles/DonorStyles';

type Props = {
    task: DonorTask,
    allUsers: Array<MemberProfile>,
};
const getAction = (item: History) => {
    const {
        completed,
        notApplicable,
    } = item;

    if (completed === null) {
        if (notApplicable === null) {
            return 'Note updated';
        }
        if (notApplicable) {
            return 'Marked as not applicable';
        }
        if (!notApplicable) {
            return 'Marked as applicable';
        }
    }
    if (completed) {
        return 'Marked as complete';
    }
    if (!completed) {
        return 'Marked as incomplete';
    }

    return '';
};

class TaskDetailsHistory extends PureComponent<Props> {
    getUserName = (item: History) => {
        const {
            allUsers,
        } = this.props;
        const member = allUsers.find((obj) => obj.profile.userId === item.userId);
        let name = '';

        if (member && member.profile) {
            name = [member.profile.firstName, member.profile.lastName].filter((x) => !!x).join(' ');
        }
        return name || 'OmniLife User';
    }

    render() {
        const {
            task,
        } = this.props;
        if (!task || !task.history) return null;

        return (
            <div>
                <h4>Task History:</h4>
                {task.history.map((element, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} style={styles.historyView}>
                        <div style={{ ...styles.textAlign, ...{ paddingTop: 10, }, }}>
                            <span style={styles.historyText}>
                                {`${getAction(element)} by `}
                            </span>
                            <span style={styles.historyName}>
                                {`${this.getUserName(element)} `}
                            </span>
                        </div>
                        <div style={styles.textAlign}>
                            <span style={styles.historyText}>
                                at
                            </span>
                            <span style={styles.historyDate}>
                                {` ${formatFullDateTime(element.updateTime)}`}
                            </span>
                        </div>
                        <div style={styles.borderBottom} />
                        <hr />
                    </div>
                ))}
            </div>
        );
    }
}
const defaultEmptyArray = [];
const mapStateToProps = (state) => ({
    task: state.donor && state.donor.donorTask ? state.donor.donorTask : null,
    allUsers: (state.network.organizationUsers || defaultEmptyArray).concat(state.network.otherUsers || defaultEmptyArray),
});
export default connect(mapStateToProps, {
})(TaskDetailsHistory);
