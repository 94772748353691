// @flow
import Colors from '../../Themes/Colors';

const PanelNavigationStyles = {
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
    },
    saveButtonDisabled: {
        backgroundColor: Colors.white,
        color: Colors.linkTextDisabled,
        textTransform: 'none',
        fontSize: 16,
    },
    saveButton: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 16,
    },
};

export default PanelNavigationStyles;
