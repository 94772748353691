// @flow
import Colors from '../../Themes/Colors';

const CreateChatroomStyles = {
    title: {
        textAlign: 'center',
        fontSize: 24,
        color: Colors.black,
        paddingBottom: 15,
    },
    titleMedium: {
        textAlign: 'center',
        fontSize: 20,
        color: Colors.black,
        paddingBottom: 15,
    },
    subtitle: {
        textAlign: 'left',
        fontSize: 20,
        color: Colors.black,
        paddingBottom: 15,
        width: '75%',
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    textInput: {
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.lightGray,
        borderBottomStyle: 'solid',
        fontSize: 16,
        width: '45%',
        paddingBottoms: 3,
        lineHeight: 2,
    },
    organMultiSelect: {
        width: '45%',
        textAlign: 'left',
    },
    textInputCase: {
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.lightGray,
        borderBottomStyle: 'solid',
        fontSize: 16,
        width: '30%',
        paddingBottom: 3,
        lineHeight: 2,
    },
    dropdown: {
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.lightGray,
        borderBottomStyle: 'solid',
        fontSize: 16,
        width: '30%',
        paddingBottom: 3,
        lineHeight: 2,
        backgroundColor: Colors.white,
    },
    labeledDropdown: {
        outline: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottomColor: Colors.lightGray,
        borderBottomStyle: 'solid',
        textAlign: 'left',
        fontSize: 16,
        width: '30%',
        paddingBottom: 3,
        lineHeight: 2,
        backgroundColor: Colors.white,
    },
    textInputWrapper: {
        paddingBottom: 15,
        display: 'flex',
        justifyContent: 'space-between',
        width: '75%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    descriptionText: {
        textAlign: 'left',
        fontSize: '0.85em',
        color: 'rgb(0,0,0,.75)',
    },
    addPeople: {
        width: '75%',
        overflow: 'scroll',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 8,
        borderColor: Colors.chatListItemBorder,
        borderWidth: '2px',
        borderStyle: 'solid',
        transform: 'translate(0, 0)',
    },
    organWrapper: {
        width: '33%',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 8,
        borderColor: Colors.chatListItemBorder,
        borderWidth: '2px',
        borderStyle: 'solid',
        transform: 'translate(0, 0)',
    },
    createButton: {
        backgroundColor: Colors.blue,
        color: Colors.white,
        textTransform: 'none',
        fontSize: 16,
        marginTop: 10,
    },
    createButtonDisabled: {
        backgroundColor: Colors.white,
        color: Colors.buttonTextDisabled,
        textTransform: 'none',
        fontSize: 16,
        marginTop: 10,
    },
    cancelButton: {
        backgroundColor: Colors.white,
        color: Colors.red,
        textTransform: 'none',
        fontSize: 16,
        marginTop: 10,
    },
    chatroomButton: {
        backgroundColor: Colors.blue,
        color: Colors.white,
        textTransform: 'none',
        fontSize: 20,
        marginTop: 10,
    },
    offerButton: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 20,
        marginTop: 10,
    },
    mainCancelButton: {
        backgroundColor: Colors.white,
        color: Colors.red,
        textTransform: 'none',
        fontSize: 20,
        marginTop: 10,
    },
    backButton: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 16,
        marginLeft: 0,
    },
    backButtonLight: {
        backgroundColor: Colors.transparent,
        color: Colors.white,
        textTransform: 'none',
        fontSize: 16,
        marginLeft: 0,
    },
    newChatroomButton: {
        backgroundColor: Colors.transparent,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 16,
        marginLeft: 0,
    },
    closeButton: {
        backgroundColor: Colors.white,
    },
    whiteBackground: {
        backgroundColor: Colors.white,
        paddingBottom: 65,
        overFlow: 'hidden',
    },
    mainContainer: {
        textAlign: 'center',
        width: '100%',
        paddingTop: 25,
        transform: 'translate(0, 0)',
    },
    center: {
        textAlign: 'center',
    },
    centerContainer: {
        width: '75%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    scrollableContainer: {
        maxHeight: '500px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        borderWidth: '2px',
        borderColor: Colors.chatListItemBorder,
        borderStyle: 'solid',
        borderRadius: 8,
        marginTop: 10,
    },
    noItemsContainer: {
        marginTop: 10,
    },
    offerContainer: {
        cursor: 'pointer',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        outline: 'none',
    },
    mainText: {
        color: Colors.blue,
        fontSize: 16,
        marginLeft: 20,
        display: 'block',
        textAlign: 'left',
    },
    subText: {
        color: Colors.gray,
        fontSize: 14,
        marginLeft: 20,
        display: 'block',
        textAlign: 'left',
    },
    createOfferButton: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        marginTop: 10,
    },
    organizationContainer: {
        cursor: 'pointer',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        outline: 'none',
    },
    outerContainer: {
        width: '100%',
        marginBottom: 20,
    },
    permissionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%',
        marginTop: 20,
        marginBottom: 20,
    },
    permissionCheckboxContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    leftContainer: {
        width: '50%',
        display: 'inline-block',
        textAlign: 'left',
    },
    rightContainer: {
        marginLeft: 'auto',
        width: '50%',
        display: 'inline-block',
        textAlign: 'right',
        color: Colors.black,
    },
    offerView: {
        borderColor: Colors.chatListItemBorder,
        borderRadius: 8,
        borderStyle: 'solid',
        borderWidth: '2px',
        maxHeight: '350px',
        overflowY: 'scroll',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '20px',
        paddingBottom: '20px',
        color: 'black',
        fontSize: 14,
    },
    label: {
        color: Colors.lightGray,
    },
    centerTabs: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    managedHelpText: {
        fontSize: 12,
        color: Colors.formLabel,
    },
    notice: {
        textAlign: 'left',
        margin: '2.5em auto',
        padding: '0 1.5em',
        backgroundColor: '#fffaf3',
        borderRadius: 4,
        borderColor: '#c9ba9b',
        borderWidth: 1,
        borderStyle: 'solid',
        color: '#573a08',
        width: '75%',
    },
    hidden: {
        visibility: 'hidden',
    },
    helpIcon: {
        color: Colors.buttonBackground,
        marginRight: '1em',
        fontSize: 28,
    },
};

export default CreateChatroomStyles;
