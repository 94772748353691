// @flow
import * as firebase from 'firebase/app';
import 'firebase/analytics';

import type { LogFirebaseEvent } from '../Redux/ApplicationActions';

export default function logEventSaga(action: LogFirebaseEvent): void {
    const {
        eventName,
    } = action;

    firebase.analytics().logEvent(eventName);
}
