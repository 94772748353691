// @flow
import type { Logout } from 'txp-core';

import type {
    ChatroomMemberMap,
    ChatroomMember,
    ResendNotificationCache,
    UserProfile,
} from '../Utils/types';
import type { ResetData, ResetError } from './ApplicationActions';
import { resetErrorState, getReducerError } from './ApplicationActions';
import createUuid from '../Utils/createUuid';

export type ChatroomState = {
    contactsAreLoaded: boolean,
    contactsMap: ChatroomMemberMap,
    contactsOrder: Array<number>,
    singleUserAdded: boolean,
    newUserInvited: boolean,
    addSingleUserError: string,
    inviteNewUserError: string,
    resendNotificationError: string,
    resendNotificationCache: ResendNotificationCache,
    selectedChatroomMember: ChatroomMember,
    reducerErrors: Array<any>,
};

const initialState: ChatroomState = {
    contactsAreLoaded: false,
    contactsMap: {},
    contactsOrder: [],
    singleUserAdded: false,
    newUserInvited: false,
    addSingleUserError: '',
    inviteNewUserError: '',
    resendNotificationError: '',
    resendNotificationCache: {},
    selectedChatroomMember: {},
    reducerErrors: [],
};

export type GetContacts = { type: 'Chatroom/GET_CONTACTS' };
export type SetContacts = { type: 'Chatroom/SET_CONTACTS', contactsMap: ChatroomMemberMap, contactsOrder: Array<number> };
export type ResetContactsStatuses = { type: 'Chatroom/RESET_CONTACTS_STATUSES' };
export type AddUsersToChatroom = {
    type: 'Chatroom/ADD_USERS_TO_CHATROOM',
    chatId: number,
    memberId: number,
    users: Array<UserProfile>,
};
export type ToggleMute = { type: 'Chatroom/TOGGLE_MUTE', chatId: number };
export type MuteCase = { type: 'Chatroom/MUTE_CASE', caseId: number, mute: boolean };
export type SetNotificationSound = { type: 'Chatroom/SET_NOTIFICATION_SOUND', chatId: number, notificationSound: string };
export type ResendNotification = { type: 'Chatroom/RESEND_NOTIFICATION', chatId: number, userId: number, messageId: number };
export type ResendNotificationSuccess = { type: 'Chatroom/RESEND_NOTIFICATION_SUCCESS', userId: number, messageId: number };
export type ResendNotificationFailed = { type: 'Chatroom/RESEND_NOTIFICATION_FAILED', message: string };
export type AddSingleUserToChatroom = {
    type: 'Chatroom/ADD_SINGLE_USER_TO_CHATROOM',
    chatId: number,
    memberId: number,
    email: string,
    phone: string,
};
export type AddSingleUserToChatroomSuccess = { type: 'Chatroom/ADD_SINGLE_USER_TO_CHATROOM_SUCCESS' };
export type AddSingleUserToChatroomFailed = { type: 'Chatroom/ADD_SINGLE_USER_TO_CHATROOM_FAILED', message: string };
export type RemoveUserFromChatroom = {
    type: 'Chatroom/REMOVE_USER_FROM_CHATROOM',
    chatId: number,
    memberId: number,
    email: string,
    self: boolean,
};

export type InviteNewUser = {
    type: 'Chatroom/INVITE_NEW_USER',
    chatId: number,
    memberId: number,
    email: string,
    phone: string,
};
export type InviteNewUserSuccess = { type: 'Chatroom/INVITE_NEW_USER_SUCCESS' };
export type InviteNewUserFailed = { type: 'Chatroom/INVITE_NEW_USER_FAILED', message: string };
export type ResetChatroomDetails = { type: 'Chatroom/RESET_CHATROOM_DETAILS' };
export type SetSelectedChatroomMember = {
    type: 'Chatroom/SET_SELECTED_MEMBER',
    selectedChatroomMember: ChatroomMember,
};
export type DeleteChatroom = { type: 'Chatroom/DELETE_CHATROOM', chatId: number}

type Action =
    | SetNotificationSound
    | ToggleMute
    | MuteCase
    | GetContacts
    | SetContacts
    | ResetContactsStatuses
    | AddUsersToChatroom
    | AddSingleUserToChatroom
    | AddSingleUserToChatroomSuccess
    | AddSingleUserToChatroomFailed
    | RemoveUserFromChatroom
    | DeleteChatroom
    | InviteNewUser
    | InviteNewUserSuccess
    | InviteNewUserFailed
    | ResetChatroomDetails
    | ResetData
    | ResendNotification
    | ResendNotificationSuccess
    | ResendNotificationFailed
    | SetSelectedChatroomMember
    | Logout
    | ResetError;

export const getContacts = (): GetContacts => ({
    type: 'Chatroom/GET_CONTACTS',
});

export const setContacts = (contactsMap: ChatroomMemberMap, contactsOrder: Array<number>): SetContacts => ({
    type: 'Chatroom/SET_CONTACTS',
    contactsMap,
    contactsOrder,
});

export const resetContactsStatuses = (): ResetContactsStatuses => ({
    type: 'Chatroom/RESET_CONTACTS_STATUSES',
});

export const toggleMute = (chatId: number): ToggleMute => ({
    type: 'Chatroom/TOGGLE_MUTE',
    chatId,
});

export const muteCase = (caseId: number, mute: boolean): MuteCase => ({
    type: 'Chatroom/MUTE_CASE',
    caseId,
    mute,
});

export const setNotificationSound = (chatId: number, notificationSound: string): SetNotificationSound => ({
    type: 'Chatroom/SET_NOTIFICATION_SOUND',
    chatId,
    notificationSound,
});

export const resendNotification = (chatId: number, userId: number, messageId: number): ResendNotification => ({
    type: 'Chatroom/RESEND_NOTIFICATION',
    chatId,
    userId,
    messageId,
});

export const resendNotificationSuccess = (userId: number, messageId: number): ResendNotificationSuccess => ({
    type: 'Chatroom/RESEND_NOTIFICATION_SUCCESS',
    userId,
    messageId,
});

export const resendNotificationFailed = (message: string): ResendNotificationFailed => ({
    type: 'Chatroom/RESEND_NOTIFICATION_FAILED',
    message,
});

export const addUsersToChatroom = (
    chatId: number,
    memberId: number,
    users: Array<UserProfile>
): AddUsersToChatroom => ({
    type: 'Chatroom/ADD_USERS_TO_CHATROOM',
    chatId,
    memberId,
    users,
});

export const addSingleUserToChatroom = (chatId: number, memberId: number, email: string, phone: string): AddSingleUserToChatroom => ({
    type: 'Chatroom/ADD_SINGLE_USER_TO_CHATROOM',
    chatId,
    memberId,
    email,
    phone,
});

export const addSingleUserToChatroomSuccess = (): AddSingleUserToChatroomSuccess => ({
    type: 'Chatroom/ADD_SINGLE_USER_TO_CHATROOM_SUCCESS',
});

export const addSingleUserToChatroomFailed = (message: string): AddSingleUserToChatroomFailed => ({
    type: 'Chatroom/ADD_SINGLE_USER_TO_CHATROOM_FAILED',
    message,
});

export const removeUserFromChatroom = (chatId: number, memberId: number, email: string, self: boolean): RemoveUserFromChatroom => ({
    type: 'Chatroom/REMOVE_USER_FROM_CHATROOM',
    chatId,
    memberId,
    email,
    self,
});

export const deleteChatroom = (chatId: number): DeleteChatroom => ({
    type: 'Chatroom/DELETE_CHATROOM',
    chatId,
});

export const inviteNewUser = (chatId: number, memberId: number, email: string, phone: string): InviteNewUser => ({
    type: 'Chatroom/INVITE_NEW_USER',
    chatId,
    memberId,
    email,
    phone,
});

export const inviteNewUserSuccess = (): InviteNewUserSuccess => ({
    type: 'Chatroom/INVITE_NEW_USER_SUCCESS',
});

export const inviteNewUserFailed = (message: string): InviteNewUserFailed => ({
    type: 'Chatroom/INVITE_NEW_USER_FAILED',
    message,
});

export const resetChatroomDetails = (): ResetChatroomDetails => ({
    type: 'Chatroom/RESET_CHATROOM_DETAILS',
});

export const setSelectedChatroomMember = (selectedChatroomMember: ChatroomMember): SetSelectedChatroomMember => ({
    type: 'Chatroom/SET_SELECTED_MEMBER',
    selectedChatroomMember,
});

const reducer = (state: ChatroomState = initialState, action: Action): ChatroomState => {
    // wrap the whole reducer to catch any unexpected exceptions and record them
    // Another component will need to act upon these and potentially send them to Sentry
    try {
        return innerReducer(state, action);
    } catch (err) {
        const reducerErrors = state.reducerErrors.slice();
        const uuid = createUuid(0);
        reducerErrors.push(getReducerError(uuid, 'chatroom', action, err));
        return {
            ...state,

            reducerErrors,
        };
    }
};

const innerReducer = (state: ChatroomState, action: Action): ChatroomState => {
    switch (action.type) {
        case 'Application/RESET_ERROR':
            return resetErrorState(state, action);

        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            // Reset the store when logging out
            return {
                ...initialState,
            };

        case 'Chatroom/GET_CONTACTS':
            return {
                ...state,

                contactsAreLoaded: false,
            };

        case 'Chatroom/RESET_CONTACTS_STATUSES':
            return {
                ...state,

                contactsAreLoaded: false,
                contactsMap: initialState.contactsMap,
                contactsOrder: initialState.contactsOrder,
            };

        case 'Chatroom/SET_CONTACTS':
            return {
                ...state,

                contactsAreLoaded: true,
                contactsMap: action.contactsMap,
                contactsOrder: action.contactsOrder,
            };

        case 'Chatroom/ADD_SINGLE_USER_TO_CHATROOM_SUCCESS':
            return {
                ...state,

                singleUserAdded: true,
                addSingleUserError: '',
            };

        case 'Chatroom/ADD_SINGLE_USER_TO_CHATROOM_FAILED':
            return {
                ...state,

                singleUserAdded: false,
                addSingleUserError: action.message,
            };

        case 'Chatroom/INVITE_NEW_USER_SUCCESS':
            return {
                ...state,

                newUserInvited: true,
                inviteNewUserError: '',
            };

        case 'Chatroom/INVITE_NEW_USER_FAILED':
            return {
                ...state,

                newUserInvited: false,
                inviteNewUserError: action.message,
            };

        case 'Chatroom/RESET_CHATROOM_DETAILS':
            return {
                ...state,

                singleUserAdded: false,
                newUserInvited: false,

                addSingleUserError: '',
                inviteNewUserError: '',
            };

        case 'Chatroom/RESEND_NOTIFICATION_SUCCESS': {
            const { resendNotificationCache, } = state;

            const newCache = {};

            Object.assign(newCache, resendNotificationCache);

            if (!newCache[action.messageId]) {
                newCache[action.messageId] = [];
            }

            newCache[action.messageId].push(action.userId);

            return {
                ...state,

                resendNotificationCache: newCache,
            };
        }

        case 'Chatroom/RESEND_NOTIFICATION_FAILED':
            return {
                ...state,

                resendNotificationError: action.message,
            };

        case 'Chatroom/SET_SELECTED_MEMBER':
            return {
                ...state,

                selectedChatroomMember: action.selectedChatroomMember,
            };

        default:
            return state;
    }
};

export default reducer;
