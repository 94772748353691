// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import {
    resendActivationEmail as _resendActivationEmail,
    emailActivationDialog as _emailActivationDialog,
    resendEmailUpdate as _resendEmailUpdate,
    cancelEmailUpdate as _cancelEmailUpdate,
} from 'txp-core';

import ApplicationStyles from '../Themes/ApplicationStyles';

type Props = {
    emailActivationDialogVisible: boolean,
    email: string,
    emailUpdate: ?string,

    resendActivationEmail: (email: string) => *,
    emailActivationDialog: (visible: boolean) => *,
    resendEmailUpdate: () => *,
    cancelEmailUpdate: () => *,
};

class EmailNotVerified extends PureComponent<Props> {
    onClose = () => {
        const {
            emailActivationDialog,
        } = this.props;

        emailActivationDialog(false);
    };

    onSend = () => {
        const {
            resendActivationEmail,
            email,
        } = this.props;

        resendActivationEmail(email);
        this.onClose();
    };

    onResendEmailUpdate = () => {
        const {
            resendEmailUpdate,
        } = this.props;

        resendEmailUpdate();
        this.onClose();
    };

    onCancelEmailUpdate = () => {
        const {
            cancelEmailUpdate,
        } = this.props;

        cancelEmailUpdate();
        this.onClose();
    };

    render() {
        const {
            emailActivationDialogVisible,
            emailUpdate,
        } = this.props;

        if (emailUpdate !== null) {
            return (
                <Dialog
                    active={emailActivationDialogVisible}
                    type="small"
                    onEscKeyDown={this.onClose}
                    onOverlayClick={this.onClose}
                >
                    <div style={ApplicationStyles.horizontalCenter}>
                        <h3>Email update pending</h3>
                        <p>
                            {'If you have not received a verification email, try resending. '
                            + 'Otherwise, cancel your request to continue sending messages with your previous email.'}
                        </p>
                        <div>
                            <Button
                                style={{ ...ApplicationStyles.margin20, ...ApplicationStyles.button, }}
                                label="Resend"
                                flat
                                ripple={false}
                                onClick={this.onResendEmailUpdate}
                            />
                            <Button
                                style={{ ...ApplicationStyles.margin20, ...ApplicationStyles.buttonInverse, }}
                                label="Cancel update"
                                flat
                                ripple={false}
                                onClick={this.onCancelEmailUpdate}
                            />
                        </div>
                        <p style={ApplicationStyles.margin20}>Press ESC to close</p>
                    </div>
                </Dialog>
            );
        }
        return (
            <Dialog
                active={emailActivationDialogVisible}
                type="small"
                onEscKeyDown={this.onClose}
            >
                <div style={ApplicationStyles.horizontalCenter}>
                    <h3>Your email isn&apos;t verified!</h3>
                    <p>
                        Before you can send messages you need to verify your email.
                    </p>
                    <p>
                        Check your email for the verification link we sent you,
                        or press RESEND to request a new email.
                    </p>
                    <Button
                        style={{ ...ApplicationStyles.margin20, ...ApplicationStyles.button, }}
                        label="Resend"
                        flat
                        ripple={false}
                        onClick={this.onSend}
                    />
                    <Button
                        style={{ ...ApplicationStyles.margin20, ...ApplicationStyles.buttonInverse, }}
                        label="OK"
                        flat
                        ripple={false}
                        onClick={this.onClose}
                    />
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    emailActivationDialogVisible: state.profile.emailActivationDialogVisible || false,
    email: state.auth.profile.email,
    emailUpdate: (state.auth.profile || {}).emailUpdate || null,
});
export default connect(mapStateToProps, {
    resendActivationEmail: _resendActivationEmail,
    emailActivationDialog: _emailActivationDialog,
    resendEmailUpdate: _resendEmailUpdate,
    cancelEmailUpdate: _cancelEmailUpdate,
})(EmailNotVerified);
