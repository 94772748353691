// @flow
import type { Tag } from './types';

export function convertToTag(tagResponseObject: any): Tag {
    return {
        tagId: tagResponseObject.tag_id,
        category: tagResponseObject.category,
        label: tagResponseObject.label,
        displayColors: tagResponseObject.display_colors,
        organizationId: tagResponseObject.organization_id,
    };
}

/**
 * Checks to see if tag arrays are "equal" to each other. Equality is determined by whether
 * or not the arrays are comprised of tags with the same IDs.
 *
 * @param {Array<Tag>} tagArr1
 * @param {Array<Tag>} tagArr2
 * @returns true if both tag arrays are "equal"
 */
export function tagsAreEqual(tagsArr1: Array<Tag>, tagsArr2: Array<Tag>): boolean {
    if (tagsArr1.length !== tagsArr2.length) {
        return false;
    }

    const tagArr1Ids = tagsArr1.map((t) => t.tagId).sort((id1, id2) => id1 - id2);
    const tagArr2Ids = tagsArr2.map((t) => t.tagId).sort((id1, id2) => id1 - id2);

    return tagArr1Ids.every((tagId, index) => tagId === tagArr2Ids[index]);
}

export function getTextColorBasedOnBgColor(bgColor: string, lightColor: string, darkColor: string) {
    const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;

    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186)
        ? darkColor : lightColor;
}

export const groupTagsByCategory = (tags: Array<Tag>): Array<Tag> => (
    tags.sort((a, b) => a.category.localeCompare(b.category))
);
