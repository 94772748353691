// @flow
import React from 'react';

import RegistrationStyles from '../Containers/Styles/RegistrationStyles';
import Images from '../Themes/Images';

const LogoSpin = () => (
    <img id="loading" style={RegistrationStyles.loading} src={Images.logoLarge} alt="" />
);

export default LogoSpin;
