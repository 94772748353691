// @flow
import React, { PureComponent } from 'react';
import Button from 'react-toolbox/lib/button/Button';

import ChatMessageStyles from './Styles/ChatMessageStyles';

type Props = {
    labels: Array<string>,
    selected: Array<boolean>,
    width: number,
    borderRadius: boolean,

    clickEvents: Array<any>,
};

class TabSelector extends PureComponent<Props> {
    render() {
        const {
            labels,
            selected,
            width,
            borderRadius,
            clickEvents,
        } = this.props;

        return (
            <div style={{ ...ChatMessageStyles.tabs, ...{ width: `${width}%`, borderRadius: borderRadius ? 4 : 0, }, }}>
                {labels.map((label: string) => (
                    <Button
                        key={label}
                        style={selected[labels.indexOf(label)] ? ChatMessageStyles.selectedTab : ChatMessageStyles.unselectedTab}
                        type="button"
                        label={label}
                        ripple={false}
                        onClick={clickEvents[labels.indexOf(label)]}
                    />
                ))}
            </div>
        );
    }
}

export default TabSelector;
