// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faProjectDiagram,
    faCaretDown,
    faCaretRight,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from 'react-loading-indicator';

import type { StaticTask, Task } from '../Utils/types';
import Colors from '../Themes/Colors';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import TaskItem from './TaskItem';
import styles from './Styles/DonorStyles';
import ChatListStyles from './Styles/ChatListStyles';
import RetryButton from './RetryButton';

type Props = {
    donorId: number,
    highRisk: boolean,
    sectionOrder: Array<string>,
    allTasks: Array<StaticTask>,
    tasks: Array<Task>,
    donorLoading: boolean,
    canViewTracker: boolean,
    isSocketConnected: boolean,

    onTaskPress: (taskId: number) => *,
    onRetryDonor: () => *,
};

type State = {
    visibleSections: any,
};

const defaultEmptyArray = [];
const defaultEmptyObject = {};

class DonorTaskCardView extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        const tempVisibility = {};
        const sections = props.sectionOrder;
        for (let i = 0; i < sections.length; i += 1) {
            tempVisibility[sections[i]] = false;
        }

        this.state = {
            visibleSections: tempVisibility,
        };
    }

    onToggleSections = (section: string) => {
        const {
            visibleSections,
        } = this.state;

        const newVisibility = {
            ...visibleSections,

            [section]: !visibleSections[section],
        };

        this.setState({
            visibleSections: newVisibility,
        });
    };

    onGetSectionVisibility = (section: string) => {
        const {
            visibleSections,
        } = this.state;

        if (visibleSections[section]) {
            return visibleSections[section];
        }

        return false;
    };

    getIconColor = (completed: boolean, partial: boolean) => {
        if (completed) {
            return Colors.green;
        }

        if (partial) {
            return Colors.pendingYellow;
        }

        return Colors.linkTextDisabled;
    };

    isTaskCompleted = (taskId: number) => {
        const {
            tasks,
        } = this.props;

        if (tasks && tasks.length > 0) {
            const index = tasks.findIndex((x) => x.taskId === taskId);
            if (index !== -1) {
                return tasks[index].completed;
            }
        }

        return false;
    };

    isTaskNotApplicable = (taskId: number) => {
        const {
            tasks,
        } = this.props;

        if (tasks && tasks.length > 0) {
            const index = tasks.findIndex((x) => x.taskId === taskId);
            if (index !== -1) {
                return tasks[index].notApplicable;
            }
        }

        return false;
    };

    checkForCompletion = (tasks: Array<Task>) => {
        for (let i = 0; i < tasks.length; i += 1) {
            if (!this.isTaskCompleted(tasks[i].taskId) && !this.isTaskNotApplicable(tasks[i].taskId)) {
                return false;
            }
        }
        return true;
    };

    checkForPartial = (tasks: Array<Task>) => {
        for (let i = 0; i < tasks.length; i += 1) {
            if (this.isTaskCompleted(tasks[i].taskId) || this.isTaskNotApplicable(tasks[i].taskId)) {
                return true;
            }
        }
        return false;
    };

    render() {
        const {
            donorId,
            highRisk,
            allTasks,
            tasks,
            sectionOrder,
            donorLoading,
            canViewTracker,
            isSocketConnected,
            onTaskPress,
            onRetryDonor,
        } = this.props;

        // if we weren't able to load any task data, let the user know
        if (!isSocketConnected || (!donorLoading && allTasks.length === 0)) {
            return (
                <div style={ChatroomDetailsStyles.noFiles}>
                    <div>
                        <FontAwesomeIcon
                            color={Colors.pendingYellow}
                            icon={faExclamationTriangle}
                            size="lg"
                        />
                        No Case Tasks could be loaded.
                    </div>
                    <div>
                        Is your network connection still active?
                        <div style={styles.smallTopMargin}>
                            <RetryButton text="Try Again" isLoading={donorLoading} onClick={() => onRetryDonor()} />
                        </div>
                    </div>
                </div>
            );
        }

        const userTaskIds = tasks.filter((task) => task.userCanRead).map((task) => task.taskId);
        // Filter for highRisk tasks, and for tasks that user can see
        const tasksList = !highRisk ? allTasks.filter((task) => !task.details.highRisk && userTaskIds.includes(task.taskId))
            : allTasks.filter((task) => userTaskIds.includes(task.taskId));
        const sectionHeaders = [...new Set(sectionOrder)];
        const sectionedTasks = {};
        const sectionsCompleted = {};
        const sectionsPartial = {};

        for (let i = 0; i < sectionHeaders.length; i += 1) {
            sectionedTasks[sectionHeaders[i]] = [];
        }

        // Add all tasks to appropriate section
        for (let i = 0; i < tasks.length; i += 1) {
            const task = tasksList[i];
            const section = task && task.details && task.details.section;

            if (section) {
                sectionedTasks[section].push(task);
            }
        }

        // Sort each section by sectionPosition
        for (let i = 0; i < sectionHeaders.length; i += 1) {
            sectionedTasks[sectionHeaders[i]].sort((a, b) => ((a.details.sectionPosition > b.details.sectionPosition) ? 1 : -1));
        }

        // Mark fully completed sections
        for (let i = 0; i < sectionHeaders.length; i += 1) {
            sectionsCompleted[sectionHeaders[i]] = this.checkForCompletion(sectionedTasks[sectionHeaders[i]]);
        }

        // Mark partially completed sections
        for (let i = 0; i < sectionHeaders.length; i += 1) {
            sectionsPartial[sectionHeaders[i]] = this.checkForPartial(sectionedTasks[sectionHeaders[i]]);
        }

        const singleList = sectionOrder.length === 1 && sectionHeaders.length === 1;

        if (donorLoading) {
            return (
                <div style={ChatListStyles.loadingIndicator}>
                    <LoadingIndicator />
                    <div style={ChatListStyles.smallLeftMargin}>
                        Loading Case Tasks and Data...
                    </div>
                </div>
            );
        }

        return (
            <div style={styles.donorTaskCard}>
                {singleList
                    ? (
                        sectionedTasks[sectionHeaders[0]].map((task) => (
                            <TaskItem
                                key={task.taskId}
                                donorId={donorId}
                                task={task}
                                onTaskPress={onTaskPress}
                            />
                        ))
                    ) : (
                        sectionHeaders.map((headerName: string) => (
                            <div
                                style={styles.donorCardWrapper}
                                key={headerName}
                            >
                                <div
                                    style={styles.donorCard}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => this.onToggleSections(headerName)}
                                    onKeyPress={() => {}}
                                >
                                    <FontAwesomeIcon
                                        color={this.getIconColor(sectionsCompleted[headerName], sectionsPartial[headerName])}
                                        icon={faProjectDiagram}
                                        size="lg"
                                    />
                                    <span style={styles.sectionTitle}>
                                        {headerName}
                                    </span>
                                    <FontAwesomeIcon
                                        color={Colors.blue}
                                        icon={this.onGetSectionVisibility(headerName) ? faCaretDown : faCaretRight}
                                        size="lg"
                                    />
                                </div>
                                {
                                    this.onGetSectionVisibility(headerName)
                                        ? (
                                            sectionedTasks[headerName].map((task) => (
                                                <TaskItem
                                                    key={task.taskId}
                                                    donorId={donorId}
                                                    task={task}
                                                    canViewTracker={canViewTracker}
                                                    onTaskPress={onTaskPress}
                                                />
                                            ))
                                        ) : null
                                }
                            </div>
                        ))
                    )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { donorId, } = props;
    const donor = state && state.donor && state.donor.donors && donorId && donorId !== -1
        ? state.donor.donors[donorId] : defaultEmptyObject;

    let allTasks = defaultEmptyArray;
    let sectionOrder = defaultEmptyArray;
    if (state.donor) {
        const workflow = (donor && donor.workflow ? donor.workflow : 'LEGACY') || 'LEGACY';

        if (state.donor.workflowTasks && state.donor.workflowTasks[workflow] && state.donor.workflowTasks[workflow].tasks) {
            allTasks = state.donor.workflowTasks[workflow].tasks;
        }
        if (state.donor.workflowTasks && state.donor.workflowTasks[workflow] && state.donor.workflowTasks[workflow].sectionOrder) {
            // eslint-disable-next-line prefer-destructuring
            sectionOrder = state.donor.workflowTasks[workflow].sectionOrder;
        }
    }

    return {
        donorId,
        highRisk: donor && donor.highRisk,
        allTasks,
        sectionOrder,
        tasks: donor && donor.tasks ? donor.tasks : defaultEmptyArray,
        donorLoading: state.loading.donor,
        isSocketConnected: state.chatList.socketStatus === 'connected',
    };
};

export default connect(mapStateToProps, {})(DonorTaskCardView);
