// @flow
export const LOCAL_DONOR_TYPE = 'Local';
export const IMPORT_DONOR_TYPE = 'Import';

export const donorTypes = [
    {
        label: LOCAL_DONOR_TYPE,
        value: LOCAL_DONOR_TYPE,
    },
    {
        label: IMPORT_DONOR_TYPE,
        value: IMPORT_DONOR_TYPE,
    }
];
