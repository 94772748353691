// @flow
import { bulkUpdateMessageStatus } from '../Redux/ChatListActions';
import type { BulkUpdateMessageStatus } from '../Redux/ChatListActions';

import type { ReadStatusMap, RemoteMessageStatus } from './types';

export const parseMessageStatuses = (memberId: number, chatId: number, data: *, replace: boolean): ?BulkUpdateMessageStatus => {
    if (!data || !(Array.isArray(data.instant_message_id_list) || Array.isArray(data.instant_message_status_list))) {
        return null;
    }

    // supports both payloads for instant message status api and the new-message-status event
    const items = data.instant_message_status_list || data.instant_message_id_list;
    const statuses: { [string]: ReadStatusMap } = {};

    // Collect all the statuses
    for (let i = 0; i < items.length; i += 1) {
        const messageStatus: RemoteMessageStatus = items[i];
        const chatroomId = messageStatus.chatroom_id;

        if (!statuses[`${chatroomId}`]) {
            statuses[`${chatroomId}`] = {};
        }

        if (!statuses[`${chatroomId}`][`${messageStatus.instant_message_id}`]) {
            statuses[`${chatroomId}`][`${messageStatus.instant_message_id}`] = {};
        }

        statuses[`${chatroomId}`][`${messageStatus.instant_message_id}`][`${messageStatus.member_id}`] = messageStatus.read_time;
    }

    return bulkUpdateMessageStatus(chatId, memberId, statuses, replace ? 'replace' : 'extend', 0, 0);
};

// This function is for setting the statuses even if there are no read statuses returned from the api call
// (i.e., no one has read the message) in ChatSaga. Without this I couldn't determine if a message status
// hadn't come in yet or if there was no message status.
export const parseEmptyMessageStatus = (
    memberId: number,
    chatroomId: number,
    messageId: number,
    replace: boolean
): ?BulkUpdateMessageStatus => {
    const statuses: { [string]: ReadStatusMap } = {};

    if (!statuses[`${chatroomId}`]) {
        statuses[`${chatroomId}`] = {};
    }

    if (!statuses[`${chatroomId}`][`${messageId}`]) {
        statuses[`${chatroomId}`][`${messageId}`] = {};
    }

    return bulkUpdateMessageStatus(chatroomId, memberId, statuses, replace ? 'replace' : 'extend', 0, 0);
};
